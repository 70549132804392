<template>
  <div
    class="widget padding-x uk-margin-small-top claim-widget"
    ref="mainContainer"
  >
    <div class="uk-grid">
      <div class="uk-width-1-3@l">
        <div class="widget-title" v-if="!isComplete">
          <h4 class="f-s-14">{{ "claim_title" | lang }}</h4>
          <h6 class="f-s-090">{{ "claim_subtitle" | lang }}</h6>
        </div>
        <div class="widget-title" v-if="isComplete">
          <div class="success-icon">
            <i class="fas fa-check-circle"></i>
          </div>
          <h4 class="f-s-14">{{ tit }}</h4>
          <h6 class="f-s-090">{{ msg }}</h6>
        </div>
        <div class="widget-content claim-widget" v-if="!isComplete">
          <div class="uk-margin-top ninp-par">
            <label class="subtitle f-s-080">Search for a profile</label>
            <vow-select
              :fields="['username']"
              itemText="username"
              itemValue="username"
              :url="`/search/users`"
              @upd="changeSelectUser"
            />
          </div>
          <div
            v-for="(field, i) in fields"
            :key="i"
            class="uk-margin-top ninp-par"
            :class="{
              err: valid[field.name] === false,
              suc: valid[field.name] === true,
            }"
          >
            <label class="subtitle f-s-080" :for="field.name + 'Input'">
              {{ (field.name + "_claim") | lang }}
              <span class="uk-margin-left show-hide-err">
                {{ (field.placeholder + "_error_claim") | lang }}
              </span>
            </label>
            <input
              class="ninp"
              :class="{
                err: valid[field.name] === false,
                suc: valid[field.name] === true,
              }"
              @focus="focused(field)"
              @focusout="focusout(field)"
              v-if="field.type === undefined"
              :id="field.name + 'Input'"
              type="text"
              :placeholder="(field.placeholder + '_placeholder_claim') | lang"
              v-model="field.value"
            />
            <textarea
              rows="4"
              class="ninp"
              v-if="field.type === 'textarea'"
              :id="field.name + 'Input'"
              type="text"
              v-model="field.value"
              :placeholder="(field.placeholder + '_placeholder_claim') | lang"
            ></textarea>
          </div>
          <div>
            <DragAndDrop
              @files="filesChanged"
              :upload-url="uploadUrl"
              :select-text="''"
              :text="'attach_title_claim' | lang"
            />
            <progress
              id="js-progressbar"
              class="uk-progress"
              value="0"
              max="100"
              hidden
            ></progress>
          </div>
          <div class="uk-margin-top">
            <button
              type="button"
              class="uk-button uk-button-default"
              @click="submit"
              :disabled="isLoading"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DragAndDrop from "@/components/Tools/DragAndDrop";
import PButton from "@/components/Tools/PButton";
import axios from "axios";
import VowSelect from "../Tools/VowSelect.vue";
export default {
  components: { DragAndDrop, PButton, VowSelect },
  data() {
    return {
      tit: null,
      msg: null,
      isComplete: false,
      isLoading: false,
      uploadUrl: API.v2.claim + "/upload",
      files: [],
      valid: {
        email: null,
      },
      users: [],
      fields: [
        {
          name: "name",
          placeholder: "name",
          value: "",
        },
        {
          name: "email",
          placeholder: "email",
          value: "",
        },
        {
          name: "mobile",
          placeholder: "mobile",
          value: "",
        },
        {
          name: "instagram",
          placeholder: "instagram",
          value: "",
        },
        {
          name: "explanation",
          placeholder: "explanation",
          type: "textarea",
          value: "",
        },
      ],
    };
  },
  computed: {},
  methods: {
    filesChanged(files) {
      this.files = files;
    },
    changeSelectUser(a) {
      this.users = a;
    },
    submit() {
      this.isLoading = true;
      const f = {};
      let l = this.fields.length - 1;
      for (; l >= 0; l--) {
        // console.log('l', l)
        f[this.fields[l].name] = this.fields[l].value;
      }
      f.users = this.users;
      if(this.files.length) {
        f['attachments'] = this.files.map(e => e.id)
      }
      axios
        .post(API.v2.claim, f)
        .then((resp) => {
          this.isComplete = true
          this.tit = resp.data.title
          this.msg = resp.data.message
        })
        .catch((resp) => {
          if (resp.response.status === 422) {
            bus.$emit("error", {
              title: "",
              message:
                resp.response.data.errors[
                  Object.keys(resp.response.data.errors).shift()
                ][0],
            });
          }
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    validateEmail(email) {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    },
    focused(field) {
      if (field.name === "mobile" && field.value === "") {
        field.value = "+";
      }
    },
    focusout(field) {
      if (field.name === "mobile" && field.value === "+") {
        field.value = "";
      } else if (field.name === "email") {
        if (field.value === "") {
          this.valid.email = null;
        } else if (!this.validateEmail(field.value)) {
          this.valid.email = false;
        } else {
          this.valid.email = true;
        }
      }
    },
  },
};
</script>