var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "container", staticClass: "waveform-container" }, [
    _c("canvas", { key: _vm.key, ref: "canvas", attrs: { height: "100" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }