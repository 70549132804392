<template>
    <div class="footer">
        <div>
            Copyright © {{year}} <router-link :to="'/'">VOWAVE LTD</router-link>. All rights reserved.
        </div>
        <div class="footer-links">
            <router-link :to="'/terms'">Terms</router-link>
            <router-link :to="'/privacy'">Privacy</router-link>
            <router-link :to="'/about-us'">About Us</router-link>
            <router-link :to="'/contact-us'">Contact Us</router-link>
            <router-link :to="'/claim'">Claim Profile</router-link>
        </div>
        <div class="uk-clearfix"></div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                year: 2020
            }
        },
        beforeMount() {
            this.year = new Date().getFullYear()
        }
    }
</script>