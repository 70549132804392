var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mainContainer",
      staticClass: "widget padding-x uk-margin-small-top",
    },
    [
      _c("div", [
        _c("div", { staticClass: "widget-title" }, [
          _c("h4", { staticClass: "f-s-13" }, [
            _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
          ]),
          _vm._v(" "),
          _c("h6", { staticClass: "f-s-090" }, [
            _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content user-slider-widget" }, [
          _c(
            "div",
            [
              _c("label", { staticClass: "selectpage-label" }, [
                _vm._v("\n                    Record Labels\n                "),
              ]),
              _vm._v(" "),
              _c("v-selectpage", {
                ref: "recordLabels",
                attrs: {
                  data: " ",
                  "result-format": _vm.typedValueResultFormat,
                  params: { type: "recordLabels", selected: _vm.recordLabels },
                  multiple: false,
                  language: "en",
                  placeholder: "Select Record Label",
                  title: "Record Labels",
                  "max-select-limit": 1,
                },
                model: {
                  value: _vm.recordLabels,
                  callback: function ($$v) {
                    _vm.recordLabels = $$v
                  },
                  expression: "recordLabels",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "uk-margin-small-top" },
            [
              _c("label", { staticClass: "selectpage-label" }, [
                _vm._v("\n                    Hashtags\n                "),
              ]),
              _vm._v(" "),
              _c("v-selectpage", {
                ref: "hashtags",
                attrs: {
                  data: " ",
                  "result-format": _vm.typedValueResultFormat,
                  params: { type: "tags", selected: _vm.hashtags },
                  multiple: true,
                  language: "en",
                  "search-field": "name",
                  "show-field": _vm.renderHashtagsRow,
                  placeholder: "Select hashtags",
                  title: "Hashtags",
                  "key-field": "slug",
                  "max-select-limit": 10,
                },
                model: {
                  value: _vm.hashtags,
                  callback: function ($$v) {
                    _vm.hashtags = $$v
                  },
                  expression: "hashtags",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }