<template>
    <div class="user-without-buttons" :class="options.subclass" v-if="item.id!=0 || auth.username!=undefined">
        <router-link :to="'/' + item.username" v-if="item.id>0">
            <div class="image">
                <div class="png-ico verified small" v-if="item.is.verified"></div>
                <!--                <img :src="item.photos.avatar | attach('s')"/>-->
                <ImagePlaceholder width="100%" radius="50%" :data-src="item.photos.avatar | attach('s')"/>
            </div>
            <div class="sub uk-text-center uk-text-truncate f-s-075">
                {{item.fullname}}
            </div>
        </router-link>
        <a href="javascript:void(0)" @click="promoteNow" v-if="item.id===0">
            <div class="image promote">
                <ImagePlaceholder radius="50%" :data-src="auth.photos.avatar | attach('s')"/>
                <i class="fal fa-plus"></i>
            </div>
            <div class="sub uk-text-truncate uk-text-center f-s-075">
                {{item.fullname}}
            </div>
        </a>
        <a href="javascript:void(0)" v-if="item.id===undefined">
            <div class="image">
                <ImagePlaceholder radius="50%"/>
            </div>
            <div class="sub uk-text-truncate uk-text-center f-s-075">
                <TextSkeleton :style="{margin: '0 auto'}" width="80%" height="10px"/>
            </div>
        </a>
    </div>
</template>
<script>
    import PlusCircle from '../../assets/img/icons/plus-circle.png'
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import TextSkeleton from "@/components/Tools/TextSkeleton";

    export default {
        props: {
            item: {
                type: Object,
            },
            index: {
                type: Number
            },
            subclass: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
                default: () => {
                    return {
                        subclass: ''
                    }
                }
            }
        },
        data() {
            return {
                PlusCircle: PlusCircle
            }
        },
        components: {
            ImagePlaceholder, TextSkeleton
        },
        methods: {
            promoteNow() {
                bus.$emit('promotedUserModal')
            }
        },
        computed: {
            auth: function () {
                return this.$store.getters.getProfile
            }
        }
    }
</script>