<template>
  <div
    class="filters"
    :class="{ active: active != null }"
    :style="{ backgroundImage: 'url(' + bg + ')' }"
  >
    <button
      type="button"
      v-for="filter in filters"
      @click="toggleFilter(filter)"
      class="filter-button"
    >
      <span>
        <i :class="filter.icon"></i>
        {{ filter.label }}
      </span>
    </button>
    <div>
      <div class="filters-titles" v-if="$route.name !== 'search'">
        <h2 class="f-f-light" v-if="labels.length">{{ labels[0] }}</h2>
        <h4 class="f-f-light" v-if="labels.length > 1">{{ labels[1] }}</h4>
      </div>
    <div class="filters-titles" v-else>
        <h2 class="f-f-light">{{'search_page_title' | lang({q: $route.query.q})}}</h2>
      </div>
      <div class="filters-tabs">
        <horizontal-menu-circle
          v-if="currentTab != null"
          @tabChanged="setTab"
          :current="currentTab"
          :tabs="tabs"
        ></horizontal-menu-circle>
      </div>
    </div>
    <div >
    </div>
    <div v-if="$route.name !== 'search' && active != null" class="filter-options">
      <div>
        <button
          type="button"
          @click="toggleFilter(active, '')"
          class="filter-button"
        >
          <span>
            <i :class="active.icon"></i>
            {{ active.defaultLabel }}
          </span>
        </button>
        <button
          type="button"
          v-for="item in active.items"
          @click="toggleFilter(active, item.id)"
          class="filter-button child"
          :class="{ active: values[active.attr] === item.id }"
        >
          <span>
            {{ item.label }}
          </span>
        </button>
      </div>
    </div>
    <!--        <div v-if="isLoading" class="loading">-->
    <!--            <i uk-spinner></i>-->
    <!--        </div>-->
  </div>
</template>
<script>
import filterBg from "@/assets/img/filter.jpg";
import searchBg from "@/assets/img/filter-two.jpg";
import HorizontalMenuCircle from "@/components/Tools/HorizontalMenuCircle";

export default {
  components: { HorizontalMenuCircle },
  data() {
    return {
      currentTab: null,
      isLoading: false,
      filterBg: filterBg,
      searchBg: searchBg,
      bg: null,
      active: null,
      lastAdded: "",
      values: {
        genre: "",
        mood: "",
        language: "",
      },
      setData: [],
      types: [],
      labels: [],
      filters: [],
    };
  },
  props: {
    button: {
      type: Object,
      default: () => {
        return {
          label: "Button",
          icon: "fal fa-times",
        };
      },
    },
  },
  watch: {
    $route: function (newVal, oldVal) {
      oldVal = oldVal || null;
      newVal = newVal || null;
      this.isLoading = true;
      if (newVal.name === "search") {
        this.filters = [];
        this.types = [];
        this.labels = [];
        this.generatePage();
      } else {
        // this.filters = [];
        // this.types = [];
      }
      //   if (newVal !== null && oldVal !== null && (oldVal.name === newVal.name || (oldVal.query.q))) {
      //   } else {
      //     this.filters = [];
      //     this.types = [];
      //   }
    },
  },
  methods: {
    generatePage() {
      const routeName = this.$route.name;
      let urlPrefix = "/explore";
      let urlSuffix = "";
      let types;
      if (routeName === "search") {
        this.bg = this.searchBg;
        urlPrefix = "/search";
        // urlSuffix = this.$route.params.type
        //   ? "/" + this.$route.params.type
        //   : "";
        urlSuffix = "?q=" + this.$route.query.q;
        types = [
          {
            label: "all",
            name: "/all",
          },
          {
            label: "users",
            name: "/users",
          },

          {
            label: "sounds",
            name: "/sounds",
          },
          {
            label: "videos",
            name: "/videos",
          },
          {
            label: "albums",
            name: "/albums",
          },
          {
            label: "playlists",
            name: "/playlists",
          },
        ];
      }
      if (routeName === "exploreFilter") {
        types = [
          {
            label: "all",
            name: "/all",
          },
          {
            label: "sounds",
            name: "/sounds",
          },
          {
            label: "videos",
            name: "/videos",
          },
          {
            label: "albums",
            name: "/albums",
          },
          {
            label: "playlists",
            name: "/playlists",
          },
          {
            label: "users",
            name: "/users",
          },
        ];

        this.bg = this.filterBg;
        this.filters = [
          {
            label: "All Genres",
            defaultLabel: "All Genres",
            icon: "fal fa-drum",
            attr: "genre",
            items: [],
          },
          {
            label: "All Moods",
            defaultLabel: "All Moods",
            icon: "fal fa-heart-broken",
            attr: "mood",
            items: [],
          },
          {
            label: "All Languages",
            defaultLabel: "All Languages",
            icon: "fal fa-language",
            attr: "language",
            items: [],
          },
        ];
      }
      this.types = types.map((e) => {
        e.name = urlPrefix + e.name + urlSuffix;
        e.label = this.$options.filters.lang(e.label);
        return e;
      });
      this.setDefaultTab();
      let data = {
        mood: this.settings.moods.map((e) => {
          return { id: e.id, label: e.name };
        }),
        genre: this.settings.genres.map((e) => {
          return { id: e.id, label: e.name };
        }),
        language: this.settings.postLanguages.map((e) => {
          return { id: e.id, label: e.name };
        }),
      };
      this.filters = this.filters.map((e) => {
        e.items = data[e.attr];
        return e;
      });
      bus.$on("seeAllLoaded", () => {
        this.isLoading = false;
      });
      this.setDefaultValues();
    },
    setTab(index) {
      this.currentTab = index;
      this.$router.push(this.tabsUrl[index]);
    },
    setDefaultTab() {
      this.currentTab = -1;
      let index = 0;
      if (this.$route.params.type !== undefined) {
        let lowerCaseTabs = this.tabs.map((e) => {
          return e.toLowerCase();
        });
        if (lowerCaseTabs.indexOf(this.$route.params.type) !== -1) {
          index = lowerCaseTabs.indexOf(this.$route.params.type);
        }
      }
      setTimeout(() => {
        this.currentTab = index;
      }, 100);
    },
    setDefaultValues() {
      let queries = this.$route.query;
      let keys = Object.keys(queries);
      let filter;
      let nm;
      let val;
      if (keys.length) {
        for (let i = 0; i < keys.length; i++) {
          nm = keys[i].substr(0, keys[i].length - 1);
          if (nm === "tag" || nm === "recordlabel") {
            continue;
          }
          val = parseInt(queries[keys[i]], 10);
          this.values[nm] = val;
          if (this.filters) {
            filter = this.filters.filter((e) => {
              return e.attr === nm;
            })[0];
            this.toggleFilter(filter, undefined, false);
            this.toggleFilter(filter, val, false);
          }
          // this.setData.push({
          //     nm: nm,
          //     val: val
          // })
          // this.toggleFilter(nm, val)
        }
        // console.log('this.setData', this.setData)
      }
    },
    toggleFilter(filter, setValue, openPage) {
      try {
        openPage = openPage === undefined ? true : openPage;
        let dontSetLastAdd = false;
        if (setValue != undefined) {
          let oldValObj = filter.items.filter((e) => {
            return e.id === this.values[filter.attr];
          });
          this.values[filter.attr] = setValue;
          let findLabel = filter.items.filter((e) => {
            return e.id === setValue;
          });
          if (setValue === "") {
            filter.label = filter.defaultLabel;
          } else {
            filter.label = findLabel[0].label;
          }
          if (findLabel.length) {
            if (this.lastAdded === filter.attr) {
              this.labels.splice(0, 1);
              this.labels.unshift(findLabel[0].label);
            } else {
              this.labels.unshift(findLabel[0].label);
              if (this.labels.length > 2) {
                this.labels.splice(2, 1);
              }
            }
          } else {
            if (
              oldValObj.length &&
              this.labels.indexOf(oldValObj[0].label) != -1
            ) {
              this.labels.splice(this.labels.indexOf(oldValObj[0].label), 1);
              this.lastAdded = "";
              dontSetLastAdd = true;
            }
          }
          if (!dontSetLastAdd) this.lastAdded = filter.attr;
          if (openPage) {
            this.$router.push(this.$route.path + this.dynamicLink);
          }
        }
        this.active = this.active === null ? filter : null;
      } catch (e) {}
    },
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    dynamicLink() {
      let queries = { ...this.$route.query };
      let values = { ...this.values };
      let keys = Object.keys(values);
      let params = [];
      keys.map((e) => {
        if (this.values[e] != null && this.values[e] != "") {
          params.push(e + "s=" + this.values[e]);
        }
      });
      Object.keys(queries).map((e) => {
        if (e === "tags" || e === "recordlabels") {
          params.push(e + "=" + queries[e]);
        }
      });
      if (params.length) {
        return "?" + params.join("&");
      }
      return "";
    },
    type() {
      return this.$route.params.type;
    },
    tabs() {
      return this.types.map((e) => {
        return e.label;
      });
    },
    tabsUrl() {
      return this.types.map((e) => {
        return e.name;
      });
    },
  },
  mounted() {
    this.generatePage();
  },
};
</script>