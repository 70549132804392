<template>
    <modal @close="confirmCancel()" ref="confirmModal" v-model="show">
          <h3 slot="header">{{ title | lang }}</h3>
          <div slot="body">{{desc | lang}}</div>
          <div slot="footer">
            <button @click="confirmAction" class="uk-button uk-button-default">
              {{ btn | lang }}
            </button>
          </div>
      </modal>
</template>
<script>

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
        title: {
            type: String,
            default: ""
        },
        desc: {
            type: String,
            default: ""
        },
        btn: {
            type: String,
            default: "Delete"
        },
        action: {
            type: Object,
            default: () => {}
        }
    },
    data() {
        return {
        }
    },
    methods: {
        confirmAction() {
            this.$emit('close')
            const {name, a0, a1, a2, a3} = this.action
            if(name !== undefined) {
                bus.$emit(name, a0, a1, a2, a3)
            }
        },
        confirmCancel() {
            this.$emit('close')
        },
    }
}
</script>
