<template>
    <div>
        <VueApexCharts height="250" :key="parentKey" type="bar" :options="options" :series="series"></VueApexCharts>
    </div>
</template>
<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {
        components: {
            VueApexCharts
        },
        props: {
            data: {
                type: Array,
            }
        },
        watch: {
            data(data, oldData) {
                let count = this.$_.pluck(data, 'cnt')
                // let dates = this.$_.pluck(data, 'date')
                let i = 0
                let tmpData = count.map((e) => {
                    return parseInt(e, 10)
                })
                if(tmpData[0]!=this.series[0].data[0]) {
                    this.series = [
                        {
                            data: tmpData,
                            name: 'Views'
                        }
                    ];
                    let categories = this.$_.pluck(data, 'country')
                    this.options.xaxis.categories = categories
                    this.parentKey = Math.random()
                }
            },
        },
        data() {
            return {
                parentKey: 'first',
                options: {
                    plotOptions: {
                        bar: {
                            horizontal: true,
                        }
                    },
                    dataLabels: {
                        enabled: false
                    },
                    grid: {
                        show: true,
                        borderColor: '#e0e0e05c'
                    },
                    xaxis: {
                        labels: {
                            show: false,
                        },
                        tooltip: {
                            enabled: true,
                            offsetY: 0,
                            style: {
                                fontSize: 0,
                                fontFamily: 0,
                            },
                        },
                        categories: [''],
                        axisTicks: {
                            show: false,
                        },
                        axisBorder: {
                            show: false
                        }
                    }
                },
                series: [{
                    name: 'Views',
                    data: [0],
                }],
            }
        },
    }
</script>