var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.value
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask", on: { click: _vm.doClose } }, [
          _c("div", { staticClass: "modal-wrapper" }, [
            _c("div", { staticClass: "modal-container" }, [
              _c("div", { staticClass: "close", on: { click: _vm.close } }, [
                _c("i", { staticClass: "fal fa-times modal-close-icon" }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _vm._t("header", function () {
                    return [_vm._v(" default header ")]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._t("body", function () {
                    return [_vm._v(" default body ")]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer", function () {
                    return [_vm._v("\n            default footer\n          ")]
                  }),
                ],
                2
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }