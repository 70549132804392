var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          { staticClass: "modal-mask", on: { click: _vm.clickOnMask } },
          [
            _c("div", { staticClass: "modal-wrapper tabbed" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", {
                  staticClass: "png-ico close small",
                  on: { click: _vm.close },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "modal-tab-titles" },
                  _vm._l(_vm.tabsArr, function (index) {
                    return _c(
                      "div",
                      {
                        staticClass: "modal-tab-title f-s-085",
                        on: {
                          click: function ($event) {
                            return _vm.setCurrentTab(index)
                          },
                        },
                      },
                      [_vm._t("title" + index)],
                      2
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c("div", {
                  ref: "modalTabBorder",
                  staticClass: "modal-tab-title-border",
                  style: { width: _vm.tabBorderWidth },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-tabs" }, [
                  _c(
                    "div",
                    {
                      ref: "containerTabsInner",
                      staticClass: "modal-tabs-inner",
                      style: { width: _vm.containerWidth },
                    },
                    _vm._l(_vm.tabsArr, function (index) {
                      return _c("div", { staticClass: "modal-tab" }, [
                        _c(
                          "div",
                          { staticClass: "modal-header" },
                          [_vm._t("header" + index)],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-body flex-h-100" },
                          [_vm._t("body" + index)],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-footer" },
                          [_vm._t("footer" + index)],
                          2
                        ),
                      ])
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }