import {
    USER_REQUEST,
    USER_ERROR,
    USER_SUCCESS,
    USER_PLAYER_ID_SUCCESS,
    USER_PLAYER_MESSAGING
} from '@/store/actions/user'
import axios from 'axios'
import Vue from 'vue'
import {AUTH_LOGOUT} from '../actions/auth'

const firebaseConfig = require("@/firebase.json")
import firebase from "firebase/compat/app"

const state = {status: '', profile: {}, messaging: null}

const getters = {
    getProfile: state => state.profile,
    getProfileStatus: state => state.status,
    isProfileLoaded: state => !!state.profile.username,
}

const actions = {
    [USER_REQUEST]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            commit(USER_REQUEST)
            axios({
                url: API.me, headers: {
                    'Cache-Control': 'private'
                }
            })
                .then(resp => {
                    commit(USER_SUCCESS, resp)
                    bus.$emit('me', resp.data)
                    axios.get(API.mySets)
                        .then((res) => {
                            bus.$emit('mySets', res.data)
                            commit(USER_PLAYER_MESSAGING)
                            resolve(true)
                        })
                })
                .catch(err => {
                    commit('userLogout')
                    commit(AUTH_LOGOUT)
                    // resolve(true)
                    // if (err.message !== 'Network Error') {
                    // }
                })
        })
    },
}

const mutations = {
    userLogout: (state) => {
        state.status = ''
        state.profile = {}
    },
    [USER_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [USER_SUCCESS]: (state, resp) => {
        state.status = 'success'
        // state.profile = resp.data

        localStorage.setItem('user', JSON.stringify(resp.data))
        Vue.set(state, 'profile', resp.data)
    },
    [USER_ERROR]: (state) => {
        state.status = 'error'
    },
    [USER_PLAYER_ID_SUCCESS]: (state, token) => {

    },
    [USER_PLAYER_MESSAGING]: (state) => {
        // let playerIds = state.profile.playerIds || []
        // let playerId = localStorage.getItem('playerId')
        // if (playerId === null || playerIds.indexOf(playerId) === -1) {
        //     return new Promise((resolve, reject) => {
        //         try {
        //           if(state.messaging===null) {
        //             firebase.initializeApp(firebaseConfig)
        //             const messaging = firebase.messaging()
        //             messaging.usePublicVapidKey("BNJwz3HFXmAkn_wygc8owR6GLhbMB8IITd3Qzm1xMWMeAm_OQAfzQLaoCUpN5BfZ5nUGw4wKE3JAsA5sd13wdf8")
        //             state.messaging = messaging
        //           }
        //         } catch (e) {
        //
        //         }
        //         state.messaging.requestPermission().then(() => {
        //             console.log('Notification permission granted.');
        //             state.messaging.getToken().then((token) => {
        //                 axios.post(API.playerId, {
        //                     playerId: token,
        //                     oldPlayerId: playerId
        //                 })
        //                     .then(res => {
        //                         localStorage.setItem('playerId', token)
        //                     })
        //                     .catch(e => {
        //
        //                     })
        //             })
        //         }).catch((err) => {
        //             console.log('Unable to get permission to notify.', err);
        //         })
        //     })
        // }
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
