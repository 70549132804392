var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("VueApexCharts", {
        key: _vm.parentKey,
        attrs: { type: "bar", options: _vm.options, series: _vm.series },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }