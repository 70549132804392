<template>
    <div class="widget padding-x">
        <div class="widget-title">
            <h4 class="f-s-13"><i class="fal fa-chart-bar"></i> {{ 'filter'  | lang}}</h4>
        </div>
        <div class="widget-content">
            <div class="chart-filters">
                <div class="part">
                    <input type="text" class="uk-input" placeholder="Name" v-model="request.search">
                </div>
                <div class="part">
                    <v-select :clearable="false" :searchable="true" v-model="request.countries"
                              index="code" label="name" :reduce="country => country.code"
                              :options="countries"></v-select>
                </div>
                <div class="part">
                    <v-select :clearable="true" placeholder="Select language" :searchable="true"
                              v-model="request.languages"
                              index="id" label="name" :reduce="country => country.id" :options="languages"></v-select>
                </div>
                <div class="part">
                    <v-select :clearable="true" placeholder="Select genre" :searchable="true" v-model="request.genres"
                              index="id" label="name" :reduce="country => country.id" :options="genres"></v-select>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        data() {
            return {
                request: {
                    genres: '',
                    languages: '',
                    countries: 'ALL',
                    search: ''
                },
                cntrs: []
            }
        },
        watch: {
            'request.genres': function() {
                this.modifyChanges()
            },
            'request.languages': function() {
                this.modifyChanges()
            },
            'request.countries': function() {
                this.modifyChanges()
            },
            'request.search': function() {
                this.modifyChanges()
            },
        },
        computed: {
            settings() {
                return this.$store.getters.settings
            },
            countries() {
                return this.cntrs
            },
            languages() {
                if (this.settings.postLanguages != undefined)
                    return this.settings.postLanguages
            },
            genres() {
                if (this.settings.genres != undefined)
                    return this.settings.genres
            }
        },
        methods: {
            modifyChanges() {
                bus.$emit('discoverFilter', this.request)
            },
            getCountries() {
                axios.get(API.getUserBasedCountries)
                    .then((resp) => {
                        this.cntrs = [{code: 'ALL', name: 'All Countries'}];
                        this.cntrs = this.cntrs.concat(resp.data)
                    })
            }
        },
        mounted() {
            this.getCountries();
        }
    }
</script>