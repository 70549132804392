var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.post === null
      ? _c("div", { staticClass: "uk-text-center uk-margin-top" }, [
          _c("i", { attrs: { "uk-spinner": "ratio: 2" } }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.post !== null
      ? _c(
          "div",
          { staticClass: "padding-x-15" },
          [
            _c("my-upload", {
              ref: "imgUpload",
              attrs: {
                field: "img",
                width: 600,
                height: 600,
                fullsize: 1200,
                url: _vm.imgUpload.url,
                langType: "en",
                params: _vm.imgUpload.params,
                headers: _vm.imgUpload.headers,
                "no-circle": "",
                "img-format": "jpg",
              },
              on: {
                "crop-success": function ($event) {
                  return _vm.cropSuccess($event)
                },
                "crop-upload-success": function ($event) {
                  return _vm.cropUploadSuccess($event)
                },
                "crop-upload-fail": function ($event) {
                  return _vm.cropUploadFail($event)
                },
              },
              model: {
                value: _vm.imgUpload.show,
                callback: function ($$v) {
                  _vm.$set(_vm.imgUpload, "show", $$v)
                },
                expression: "imgUpload.show",
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "uploading-file" }, [
              _c("div", { staticClass: "file-progress-bar" }, [
                _c("div", {
                  staticClass: "file-progress-bar-val",
                  style: { width: 100 + "%" },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "file-ellapsed" }, [
                _c("div", [
                  _vm._v(
                    "\n          " +
                      _vm._s(_vm.post.title || "Untitled Post") +
                      "\n        "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "file-collapsed submit-post" },
                [
                  _vm.saving
                    ? _c("div", { staticClass: "processing" }, [_vm._m(0)])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "uk-flex uk-flex-middle",
                      attrs: { "uk-grid": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-width-1-1 uk-width-1-4@m" },
                        [
                          _c("div", { staticClass: "post-cover" }, [
                            _c("img", {
                              attrs: {
                                src:
                                  _vm.post.base64Cover !== null
                                    ? _vm.post.base64Cover
                                    : _vm.defaultImage,
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "post-cover-container" }, [
                              _vm.post.base64Cover !== null
                                ? _c(
                                    "a",
                                    {
                                      staticClass:
                                        "uk-button uk-button-default button-top-menu button-bg",
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleCoverUploader(true)
                                        },
                                      },
                                    },
                                    [_c("i", { staticClass: "fal fa-pen" })]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "a",
                                {
                                  staticClass:
                                    "uk-button uk-button-default button-top-menu button-bg",
                                  on: {
                                    click: function ($event) {
                                      return _vm.toggleCoverUploader(false)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fal fa-cloud-upload",
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uk-width-1-1 uk-width-3-4@m" },
                        [
                          _c("div", { staticClass: "uk-margin" }, [
                            _c("label", { staticClass: "label" }, [
                              _vm._v(" Title "),
                            ]),
                            _vm._v(" "),
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.post.title,
                                  expression: "post.title",
                                },
                              ],
                              staticClass:
                                "uk-input uk-form-width-medium uk-form-small title",
                              attrs: { type: "text", placeholder: "Title" },
                              domProps: { value: _vm.post.title },
                              on: {
                                keyup: function ($event) {
                                  return _vm.onKeyUpTitle()
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.post,
                                    "title",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-grid-none uk-flex uk-flex-middle uk-margin-small-top f-s-080",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c("div", { staticClass: "uk-width-auto" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(_vm.host) +
                                      "/" +
                                      _vm._s(_vm.auth.username) +
                                      "/\n                "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "uk-width-expand" }, [
                                  _c(
                                    "div",
                                    { staticClass: "uk-position-relative" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.post.slug,
                                            expression: "post.slug",
                                          },
                                        ],
                                        staticClass: "slug-input",
                                        attrs: {
                                          type: "text",
                                          readonly: _vm.readOnlySlug,
                                          placeholder: "Title",
                                        },
                                        domProps: { value: _vm.post.slug },
                                        on: {
                                          change: function ($event) {
                                            return _vm.onKeyUpTitle(1)
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.post,
                                              "slug",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm.readOnlySlug
                                        ? _c(
                                            "div",
                                            { staticClass: "slug-loading" },
                                            [
                                              _c("div", {
                                                attrs: { "uk-spinner": "" },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-margin" }, [
                            _c("div", { attrs: { "uk-grid": "" } }, [
                              _c(
                                "div",
                                { staticClass: "uk-width-1-1 uk-width-1-2@m" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selectpage-label" },
                                    [_vm._v("Post Languages *")]
                                  ),
                                  _vm._v(" "),
                                  _vm.postLanguagesArr.length
                                    ? _c("v-selectpage", {
                                        ref: "languages",
                                        attrs: {
                                          data: _vm.postLanguagesArr,
                                          multiple: true,
                                          language: "en",
                                          placeholder: "Select language",
                                          title: "Languages",
                                          "max-select-limit": 3,
                                        },
                                        model: {
                                          value: _vm.post.languages,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.post, "languages", $$v)
                                          },
                                          expression: "post.languages",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "uk-width-1-1 uk-width-1-2@m" },
                                [
                                  _c(
                                    "label",
                                    { staticClass: "selectpage-label" },
                                    [_vm._v(" Record Labels ")]
                                  ),
                                  _vm._v(" "),
                                  _c("v-selectpage", {
                                    attrs: {
                                      data: " ",
                                      "result-format":
                                        _vm.typedValueResultFormat,
                                      params: { type: "recordLabels" },
                                      multiple: true,
                                      language: "en",
                                      placeholder: "Select Record Labels",
                                      title: "Record Labels",
                                      "max-select-limit": 2,
                                    },
                                    model: {
                                      value: _vm.post.recordLabels,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.post, "recordLabels", $$v)
                                      },
                                      expression: "post.recordLabels",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "uk-margin" },
                            [
                              _c("label", { staticClass: "selectpage-label" }, [
                                _vm._v(" Hashtags "),
                              ]),
                              _vm._v(" "),
                              _c("v-selectpage", {
                                ref: "hashtags",
                                attrs: {
                                  data: " ",
                                  "result-format": _vm.typedValueResultFormat,
                                  params: { type: "tags" },
                                  multiple: true,
                                  language: "en",
                                  "search-field": "name",
                                  "show-field": _vm.renderHashtagsRow,
                                  placeholder: "Select hashtags",
                                  title: "Hashtags",
                                  "max-select-limit": 10,
                                },
                                model: {
                                  value: _vm.post.hashtags,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.post, "hashtags", $$v)
                                  },
                                  expression: "post.hashtags",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-margin" }, [
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1" },
                              [
                                _c(
                                  "label",
                                  { staticClass: "selectpage-label" },
                                  [_vm._v(" Genres * ")]
                                ),
                                _vm._v(" "),
                                _c("v-selectpage", {
                                  ref: "genres",
                                  attrs: {
                                    data: " ",
                                    "result-format": _vm.tagsResultFormat,
                                    params: {
                                      type: "genres",
                                      selected: _vm.post.genres,
                                    },
                                    multiple: true,
                                    language: "en",
                                    placeholder: "Select genres",
                                    title: "Genres",
                                    "max-select-limit": 3,
                                  },
                                  on: { eAjaxSuccess: _vm.genresAjaxSuccess },
                                  model: {
                                    value: _vm.post.genres,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "genres", $$v)
                                    },
                                    expression: "post.genres",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.post.screenshotsUri != undefined
                    ? _c("screenshot-scrapper", {
                        attrs: { static: _vm.post.screenshotsUri },
                        on: { pass: _vm.setCover },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c("ul", { attrs: { "uk-accordion": "collapsible: true" } }, [
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "uk-accordion-title",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.clickUkTab($event)
                            },
                          },
                        },
                        [_vm._v("Description & Lyrics")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-accordion-content" }, [
                        _c("div", { staticClass: "uk-margin" }, [
                          _c(
                            "div",
                            { staticClass: "big-placeholder-container" },
                            [
                              _c("label", { attrs: { for: "dDescription" } }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm._f("lang")("description_lable")
                                    ) +
                                    "\n                  "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.post.description,
                                    expression: "post.description",
                                  },
                                ],
                                staticClass:
                                  "uk-textarea uk-form-small uk-text-center",
                                attrs: {
                                  rows: "4",
                                  id: "dDescription",
                                  placeholder: _vm._f("lang")(
                                    "description_placeholder"
                                  ),
                                },
                                domProps: { value: _vm.post.description },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.post,
                                      "description",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-margin" }, [
                          _c(
                            "div",
                            {
                              key: _vm.showLyricsPlaceholder,
                              staticClass: "big-placeholder-container",
                            },
                            [
                              _c("label", { attrs: { for: "tLyrics" } }, [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm._f("lang")("lyrics_lable")) +
                                    "\n                  "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.post.lyrics,
                                    expression: "post.lyrics",
                                  },
                                ],
                                staticClass:
                                  "uk-textarea uk-form-small uk-text-center",
                                attrs: {
                                  rows: "10",
                                  id: "tLyrics",
                                  placeholder:
                                    _vm._f("lang")("lyrics_placeholder"),
                                },
                                domProps: { value: _vm.post.lyrics },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.post,
                                      "lyrics",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("li", [
                      _c(
                        "a",
                        {
                          staticClass: "uk-accordion-title",
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.clickUkTab($event, 1)
                            },
                          },
                        },
                        [_vm._v("Privacy & Metadata")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-accordion-content" }, [
                        _c("div", { staticClass: "uk-margin" }, [
                          _c("div", { attrs: { "uk-grid": "" } }, [
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-3@m" },
                              [
                                _c("label", [_vm._v("Privacy Settings")]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    searchable: false,
                                    reduce: (pub) => pub.value,
                                    options: [
                                      { label: "Public", value: 1 },
                                      { label: "Private", value: 0 },
                                    ],
                                  },
                                  model: {
                                    value: _vm.post.public,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "public", $$v)
                                    },
                                    expression: "post.public",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-3@m" },
                              [
                                _c("label", [_vm._v("Download")]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    searchable: false,
                                    reduce: (download) => download.value,
                                    options: [
                                      { label: "Allow", value: 1 },
                                      { label: "Not Allow", value: 0 },
                                    ],
                                  },
                                  model: {
                                    value: _vm.post.download,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "download", $$v)
                                    },
                                    expression: "post.download",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-3@m" },
                              [
                                _c("label", [_vm._v("Comments")]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    searchable: false,
                                    reduce: (comment) => comment.value,
                                    options: [
                                      { label: "Allow", value: 1 },
                                      { label: "Not Allow", value: 0 },
                                    ],
                                  },
                                  model: {
                                    value: _vm.post.comment,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "comment", $$v)
                                    },
                                    expression: "post.comment",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-margin" }, [
                          _c("div", { attrs: { "uk-grid": "" } }, [
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-3@m" },
                              [
                                _c("label", [_vm._v("Type")]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    searchable: false,
                                    reduce: (releaseType) => releaseType.value,
                                    options: _vm.releaseTypesArr,
                                  },
                                  model: {
                                    value: _vm.post.releaseTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "releaseTypeId", $$v)
                                    },
                                    expression: "post.releaseTypeId",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-3@m" },
                              [
                                _c("label", [_vm._v("Explicit 🅴")]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    searchable: false,
                                    reduce: (explicit) => explicit.value,
                                    options: [
                                      { label: "Yes", value: 1 },
                                      { label: "No", value: 0 },
                                    ],
                                  },
                                  model: {
                                    value: _vm.post.explicit,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "explicit", $$v)
                                    },
                                    expression: "post.explicit",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-3@m" },
                              [
                                _c("label", [_vm._v("License")]),
                                _vm._v(" "),
                                _c("v-select", {
                                  attrs: {
                                    clearable: false,
                                    searchable: false,
                                    reduce: (licenseType) => licenseType.value,
                                    options: _vm.licenseTypesArr,
                                  },
                                  model: {
                                    value: _vm.post.licenseTypeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "licenseTypeId", $$v)
                                    },
                                    expression: "post.licenseTypeId",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-margin" }, [
                          _c("div", { attrs: { "uk-grid": "" } }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-width-1-1 uk-width-1-2@m select-teammates-container",
                              },
                              [
                                _c("label", [_vm._v(" ")]),
                                _vm._v(" "),
                                _c("v-selectpage", {
                                  ref: "users",
                                  attrs: {
                                    data: " ",
                                    "result-format": _vm.usersResultFormat,
                                    params: {
                                      type: "users",
                                      selected: _vm.post.users,
                                    },
                                    multiple: true,
                                    language: "en",
                                    "search-field": "username",
                                    "show-field": _vm.renderUsersRow,
                                    placeholder: "Select Teammates",
                                    title: "Users",
                                    "max-select-limit": 5,
                                  },
                                  model: {
                                    value: _vm.post.users,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "users", $$v)
                                    },
                                    expression: "post.users",
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    "\n                      You can use ‘teammate’ to add other artists who have\n                      collabrated with you to your post.\n                    "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1 uk-width-1-2@m" },
                              [
                                _c("label", [_vm._v(" ")]),
                                _vm._v(" "),
                                _c("VueCtkDateTimePicker", {
                                  attrs: {
                                    locale: "en",
                                    dark: true,
                                    format: "YYYY-MM-DD HH:mm",
                                  },
                                  model: {
                                    value: _vm.post.releaseDate,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.post, "releaseDate", $$v)
                                    },
                                    expression: "post.releaseDate",
                                  },
                                }),
                                _vm._v(" "),
                                _c("small", [
                                  _vm._v(
                                    "\n                      If you select time from future this post will be\n                      scheduled and will get automatically posted at that\n                      time.\n                    "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "button",
                      {
                        staticClass: "uk-button uk-button-default",
                        on: { click: _vm.save },
                      },
                      [_vm._v("\n            SAVE\n          ")]
                    ),
                  ]),
                ],
                1
              ),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { attrs: { "uk-spinner": "ratio: 3" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }