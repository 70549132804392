<template>
  <div class="padding-x-15 settings uk-margin-top">
    <h2>Edit Settings</h2>
    <div uk-grid class="settings">
      <div class="uk-width-1-1 uk-width-auto@m">
        <SwitchTab
          padding="12px 10px"
          :currentTab="currentTab"
          @setTab="tabChanged"
          :tabs="tabs"
          :icons="tabIcons"
          activeMode="bordered"
          :vertical="true"
        />
      </div>
      <div class="uk-width-1-1 uk-width-expand@m">
        <div class="uk-position-relative">
          <SwitchContent
            :current-content="currentTab"
            :contents-arr="tabIndexes"
          >
            <!--Acc Settings-->
            <div slot="content0">
              <div v-for="data in accountSettingsData">
                <div>
                  <div class="widget-title">
                    <h4 class="f-s-1">{{ data.label | lang }}</h4>
                  </div>
                </div>
                <div class="setting-parent">
                  <div
                    class="setting f-s-080"
                    v-for="item in data.items"
                    :key="item.label"
                  >
                    <div class="uk-flex uk-flex-middle" uk-grid>
                      <div class="uk-width-expand">
                        {{ item.label }}
                      </div>
                      <div class="uk-width-auto">
                        <div class="uk-position-relative">
                          <div class="settings-input-input-with-ico">
                            <input
                              type="text"
                              @keyup="onKeyUpAccount(item)"
                              :placeholder="item.label"
                              class="settings-input"
                              v-model="item.value"
                            />
                            <div class="ico" v-if="available[item.key] != null">
                              <i
                                v-if="available[item.key] === -1"
                                uk-spinner="ratio: 0.5"
                              ></i>
                              <i
                                v-if="available[item.key] === false"
                                class="fa fa-times"
                              ></i>
                              <i
                                v-if="available[item.key] === true"
                                class="fa fa-check"
                              ></i>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-margin-top uk-flex">
                <button
                  @click="save(accountSettingsData, 'accountSettings')"
                  :disabled="
                    inProcessTab.accountSettings || !canSaveAccountSettings
                  "
                  class="
                    save-btn
                    uk-button uk-button-default
                    button-top-menu button-bg
                    flex-right
                  "
                >
                  <span v-if="!inProcessTab.accountSettings"
                    ><i class="fal fa-check"></i> Save</span
                  >
                  <div
                    v-if="inProcessTab.accountSettings"
                    uk-spinner="ratio: 0.8"
                  ></div>
                </button>
              </div>
            </div>

            <!--Edit Profile-->
            <div slot="content1">
              <div v-for="data in editProfileData">
                <div>
                  <div class="widget-title">
                    <h4 class="f-s-1">{{ data.label }}</h4>
                  </div>
                </div>
                <div class="setting-parent">
                  <div
                    class="setting f-s-080"
                    v-for="item in data.items"
                    :key="item.key"
                  >
                    <div class="uk-flex uk-flex-middle" uk-grid>
                      <div class="uk-width-expand">
                        {{ item.label }}
                      </div>
                      <div class="uk-width-auto">
                        <div class="uk-position-relative">
                          <textarea
                            v-if="
                              item.isTextarea != undefined && item.isTextarea
                            "
                            rows="5"
                            class="settings-input"
                            v-model="item.value"
                          ></textarea>
                          <input
                            v-if="item.isInput != undefined && item.isInput"
                            @keyup="keyUpInput(item)"
                            @focusin="focusIn(item)"
                            @focusout="focusOut(item)"
                            type="text"
                            :placeholder="item.label"
                            class="settings-input"
                            v-model="item.value"
                          />
                          <div
                            class="ajax-dropdown"
                            v-if="item.label === 'Location' && locations.show"
                          >
                            <div
                              class="uk-text-center"
                              v-if="locations.isLoading"
                            >
                              <i uk-spinner="ratio: 0.5"></i>
                            </div>
                            <ul v-if="locations.items.length">
                              <li
                                :class="{
                                  active: loc.id === locations.selectedId,
                                }"
                                @click="selectLocation(loc, item)"
                                v-for="loc in locations.items"
                              >
                                {{ loc.name }}
                              </li>
                            </ul>
                            <div
                              v-if="
                                item.value === null || item.value.length < 2
                              "
                            >
                              Type to search...
                            </div>
                            <div v-if="locations.notFound">Not found.</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-margin-top uk-flex">
                <button
                  @click="save(editProfileData, 'editProfile')"
                  :disabled="inProcessTab.editProfile"
                  class="
                    save-btn
                    uk-button uk-button-default
                    button-top-menu button-bg
                    flex-right
                  "
                >
                  <span v-if="!inProcessTab.editProfile"
                    ><i class="fal fa-check"></i> Save</span
                  >
                  <div
                    v-if="inProcessTab.editProfile"
                    uk-spinner="ratio: 0.8"
                  ></div>
                </button>
              </div>
            </div>

            <!--Change Pass-->
            <div slot="content2">
              <div>
                <div>
                  <div class="widget-title">
                    <h4 class="f-s-1">Change Password</h4>
                    <h6 class="f-s-085">
                      <ul>
                        <li>
                          If you have registered vie Social Networks please
                          remember to enter a valid email in your profile and
                          then request for new password with Forget Password
                          link. Please remind this after changing password all
                          other devices will get logged out.
                        </li>
                        <li>
                          Password must be at least 6 characters and it may not
                          be greater than 32 characters.
                        </li>
                        <li class="important">
                          Please Remember after changing your password all
                          devices will get <strong>logged out</strong>
                        </li>
                      </ul>
                    </h6>
                  </div>
                </div>
                <div class="setting-parent">
                  <div class="setting f-s-080">
                    <div class="uk-flex uk-flex-middle" uk-grid>
                      <div class="uk-width-expand">Current Password</div>
                      <div class="uk-width-auto">
                        <div class="password-with-show-btn">
                          <div
                            @click="clickShowPassBtn('currentPassword')"
                            v-if="passwords.currentPassword != ''"
                            class="password-show-btn"
                          >
                            {{
                              passwordTypes.currentPassword === "text"
                                ? "Hide"
                                : "Show"
                            }}
                          </div>
                          <input
                            :type="passwordTypes.currentPassword"
                            v-model="passwords.currentPassword"
                            placeholder="Current Password"
                            class="settings-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="setting f-s-080">
                    <div class="uk-flex uk-flex-middle" uk-grid>
                      <div class="uk-width-expand">New Password</div>
                      <div class="uk-width-auto">
                        <div class="password-with-show-btn">
                          <div
                            @click="clickShowPassBtn('password')"
                            v-if="passwords.password != ''"
                            class="password-show-btn"
                          >
                            {{
                              passwordTypes.password === "text"
                                ? "Hide"
                                : "Show"
                            }}
                          </div>
                          <input
                            :type="passwordTypes.password"
                            v-model="passwords.password"
                            placeholder="New Password"
                            class="settings-input"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="uk-margin-top uk-flex">
                <button
                  @click="save(passwords, 'changePassword')"
                  :disabled="inProcessTab.changePassword"
                  class="
                    save-btn
                    uk-button uk-button-default
                    button-top-menu button-bg
                    flex-right
                  "
                >
                  <span v-if="!inProcessTab.changePassword"
                    ><i class="fal fa-check"></i> Save</span
                  >
                  <div
                    v-if="inProcessTab.changePassword"
                    uk-spinner="ratio: 0.8"
                  ></div>
                </button>
              </div>
            </div>

            <!--Social Links-->
            <div slot="content3">
              <div>
                <div class="widget-title">
                  <h4 class="f-s-1">Social Connect</h4>
                  <h6 class="f-s-085">Social connect description.</h6>
                </div>
              </div>
              <div class="setting-parent uk-margin-bottom">
                <div class="setting">
                  <div class="uk-flex uk-flex-middle uk-grid-10" uk-grid>
                    <div
                      class="uk-width-expand"
                      v-for="provider in providers"
                      @click="clickOnProvider(provider)"
                    >
                      <div
                        class="provider bg with-hover color-white"
                        :class="[
                          provider.name,
                          {
                            active:
                              connectedSocials.indexOf(provider.name) != -1,
                          },
                          {
                            processing:
                              inProcessConnect.indexOf(provider.name) != -1,
                          },
                        ]"
                      >
                        <div class="provider-content">
                          <div class="f-s-15">
                            <i :class="socialIcons[provider.name]"></i>
                          </div>
                          <div>{{ provider.label }}</div>
                          <div class="provider-connect">
                            {{
                              connectedSocials.indexOf(provider.name) != -1
                                ? "Disconnect"
                                : "Connect"
                            }}
                          </div>
                        </div>
                        <div class="loading">
                          <i uk-spinner></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div class="widget-title">
                  <h4 class="f-s-1">Social Links</h4>
                  <h6 class="f-s-085">
                    Add links to your website and social network profiles to
                    help your audience find you wherever you are.
                  </h6>
                </div>
              </div>
              <div class="setting-parent">
                <div class="setting" v-for="social in socials">
                  <div class="uk-flex uk-flex-middle uk-grid-10" uk-grid>
                    <div class="uk-width-auto">
                      <div class="social-placeholder">
                        <i
                          :class="
                            socialIcons[social.type] !== undefined
                              ? socialIcons[social.type]
                              : social.href === ''
                              ? ''
                              : socialIcons.official
                          "
                        ></i>
                      </div>
                    </div>
                    <div class="uk-width-expand">
                      <input
                        type="text"
                        @keyup="onKeyUpSocial(social)"
                        v-model="social.href"
                        class="settings-input social-input"
                      />
                    </div>
                    <div class="uk-width-auto">
                      <button
                        @click="deleteSocial(social)"
                        class="social-modify-btn trash-btn"
                      >
                        <i class="fal fa-trash"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="uk-margin-small-top">
                  <button @click="addSocial" class="social-modify-btn">
                    Add link
                  </button>
                </div>
                <div class="uk-margin-top uk-flex">
                  <button
                    @click="save(socials, 'socialLinks')"
                    :disabled="inProcessTab.socialLinks"
                    class="
                      save-btn
                      uk-button uk-button-default
                      button-top-menu button-bg
                      flex-right
                    "
                  >
                    <span v-if="!inProcessTab.socialLinks"
                      ><i class="fal fa-check"></i> Save</span
                    >
                    <div
                      v-if="inProcessTab.socialLinks"
                      uk-spinner="ratio: 0.8"
                    ></div>
                  </button>
                </div>
              </div>
            </div>

            <!--Notifications-->
            <div class="settings-parent" slot="content4">
              <div>
                <div class="widget-title">
                  <h4 class="f-s-1">Push Notifications</h4>
                  <h6 class="f-s-085">
                    Mute the notifications that you dont want to recieve.
                  </h6>
                </div>
              </div>
              <div class="setting-parent">
                <div
                  class="setting f-s-080"
                  v-for="setting in settings"
                  :key="setting.value"
                >
                  <div class="uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">
                      {{ setting.title }}
                    </div>
                    <div class="uk-width-auto">
                      <span
                        v-if="mySettings[setting.value] == false"
                        class="f-s-070"
                        >Muted&nbsp;</span
                      >
                      <p-check
                        class="p-switch p-fill"
                        ref="playlist-public"
                        v-model="mySettings[setting.value]"
                        color="primary"
                      ></p-check>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin-top uk-flex">
                <button
                  @click="save(mySettings, 'notifications')"
                  :disabled="inProcessTab.notifications"
                  class="
                    save-btn
                    uk-button uk-button-default
                    button-top-menu button-bg
                    flex-right
                  "
                >
                  <span v-if="!inProcessTab.notifications"
                    ><i class="fal fa-check"></i> Save</span
                  >
                  <div
                    v-if="inProcessTab.notifications"
                    uk-spinner="ratio: 0.8"
                  ></div>
                </button>
              </div>
            </div>

            <!--Bank Accounts-->
            <div class="settings-parent" slot="content5">
              <div>
                <div class="widget-title">
                  <h4 class="f-s-1">Bank Accounts</h4>
                  <h6 class="f-s-085">
                    You need to have a verified account to start earning from
                    your account. We will use your bank accounts information to
                    pay your earnings from your sounds and videos. So please
                    provide correct information to prevent happening any
                    mistakes. Please check your information and make sure your
                    full name is same on your card or paypal account.
                  </h6>
                </div>
              </div>
              <div class="setting-parent">
                <div class="setting f-s-080">
                  <div class="uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">Paypal</div>
                    <div class="uk-width-auto">
                      <input
                        type="text"
                        placeholder="Paypal"
                        v-model="bankAccounts.paypal"
                        class="settings-input"
                      />
                    </div>
                  </div>
                </div>
                <div class="setting f-s-080">
                  <div class="uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">Shetab</div>
                    <div class="uk-width-auto">
                      <input
                        ref="shetab"
                        @paste="doPaste"
                        type="text"
                        v-for="(v, index) in bankAccounts.shetabTmp"
                        @focusin="onFocusShetab(index)"
                        @keydown="onKeyDownShetab(index, $event)"
                        @keyup="onKeyUpShetab(index)"
                        maxlength="4"
                        :placeholder="index === 0 ? 'Shetab' : ''"
                        v-model="bankAccounts.shetabTmp[index]"
                        class="settings-input shetab"
                      />
                    </div>
                  </div>
                </div>
                <div style="direction: rtl">
                  <img class="shetab-img" :src="shetab" />
                </div>
              </div>
              <div class="uk-margin-top uk-flex">
                <button
                  @click="save(bankAccounts, 'bankAccounts')"
                  :disabled="inProcessTab.bankAccounts"
                  class="
                    save-btn
                    uk-button uk-button-default
                    button-top-menu button-bg
                    flex-right
                  "
                >
                  <span v-if="!inProcessTab.bankAccounts"
                    ><i class="fal fa-check"></i> Save</span
                  >
                  <div
                    v-if="inProcessTab.bankAccounts"
                    uk-spinner="ratio: 0.8"
                  ></div>
                </button>
              </div>
            </div>

            <!--Verification Request-->
            <div
              v-if="profile.is != undefined && profile.is.verified"
              slot="content6"
            >
              <div class="uk-text-center uk-margin-top">
                <div>
                  <i class="fal fa-check-circle f-s-4"></i>
                </div>
                <div class="f-f-reg uk-margin-small-top">
                  Your profile is already verified.
                </div>
              </div>
            </div>
            <div
              v-if="profile.is != undefined && !profile.is.verified"
              slot="content6"
            >
              <div>
                <div class="widget-title">
                  <h4 class="f-s-1">Verification</h4>
                  <h6 class="f-s-085">
                    To get your account verified you must send us an image of
                    your ID card like driving license, passport copy or any
                    other ID card which informs us about your name and your
                    photo. The image has to be clear and bright.<br />

                    Before sending request make sure you have connected your
                    Instagram account to your profile.
                  </h6>
                </div>
              </div>
              <div class="setting-parent">
                <div class="setting f-s-080">
                  <div class="uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">Description</div>
                    <div class="uk-width-auto">
                      <textarea
                        class="settings-input"
                        rows="5"
                        v-model="verificationRequest.description"
                        placeholder="Description"
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="setting f-s-080">
                  <div class="uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">
                      <DragAndDrop
                        @upload-complete="verificationRequestUploadComplete"
                        :upload-url="uploadVerificationUrl"
                        select-text="selecting one"
                        text="Attach your ID Card by dropping it here or "
                      />
                      <progress
                        id="js-progressbar"
                        class="uk-progress"
                        value="0"
                        max="100"
                        hidden
                      ></progress>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin-top uk-flex">
                <button
                  @click="save(verificationRequest, 'verificationRequest')"
                  :disabled="inProcessTab.verificationRequest"
                  class="
                    save-btn
                    uk-button uk-button-default
                    button-top-menu button-bg
                    flex-right
                  "
                >
                  <span v-if="!inProcessTab.verificationRequest"
                    ><i class="fal fa-check"></i> Save</span
                  >
                  <div
                    v-if="inProcessTab.verificationRequest"
                    uk-spinner="ratio: 0.8"
                  ></div>
                </button>
              </div>
            </div>

            <div slot="content7">
              <div>
                <div class="widget-title">
                  <h4 class="f-s-1">{{ "disable_user_title" | lang }}</h4>
                  <h6 class="f-s-085" v-html="$options.filters.lang('disable_user_desc', undefined)">
                  </h6>
                </div>
                <div class="setting-parent">
                  <div class="setting f-s-080">
                    <div class="uk-margin-top uk-flex">
                      <button
                        @click="disableAccount()"
                        :disabled="inProcessTab.disableAccount"
                        class="
                          save-btn
                          uk-button uk-button-default
                          button-top-menu button-bg
                          flex-right
                        "
                      >
                        <span v-if="!inProcessTab.disableAccount">
                          <i class="fal fa-ban"></i>
                          <span v-html="$options.filters.lang('disable_user_btn', undefined)"></span>
                        </span>
                        <div
                          v-if="inProcessTab.disableAccount"
                          uk-spinner="ratio: 0.8"
                        ></div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </SwitchContent>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
.social-input {
  min-width: 100% !important;
}
</style>
<script>
import SwitchContent from "@/components/Tools/SwitchContent";
import SwitchTab from "@/components/SwitchTab";
import axios from "axios";
import { USER_REQUEST } from "@/store/actions/user";
import shetab from "@/assets/img/payments-with-shetab.png";
import DragAndDrop from "@/components/Tools/DragAndDrop";
export default {
  components: {
    SwitchTab,
    SwitchContent,
    DragAndDrop,
  },
  data() {
    return {
      uploadVerificationUrl: API.baseURL + API.verification + "/upload",
      inProcess: false,
      shetab: shetab,
      bankAccounts: {
        shetab: "",
        paypal: "",
        shetabTmp: ["", "", "", ""],
      },

      tabs: [
        "Account Settings",
        "Edit Profile",
        "Change Password",
        "Social Links",
        "Notifications",
        "Bank Accounts",
        "Verification Request",
        "Disable Account",
        // 'Clear Cache'
      ],

      locations: {
        isLoading: false,
        items: [],
        show: false,
        notFound: false,
        selectedId: null,
      },
      socialIcons: {
        official: "fas fa-globe-europe",
        facebook: "fab fa-facebook-f",
        twitter: "fab fa-twitter",
        youtube: "fab fa-youtube",
        telegram: "fab fa-telegram-plane",
        instagram: "fab fa-instagram",
        soundcloud: "fab fa-soundcloud",
        google: "fab fa-google",
        apple: "fab fa-apple",
        whatsapp: "fab fa-whatsapp",
      },

      tabIcons: [
        "fal fa-cog",
        "fal fa-user-edit",
        "fal fa-lock",
        "fal fa-share-alt",
        "fal fa-bell",
        "fal fa-university",
        "fal fa-badge-check",
        "fal fa-user-alt-slash",
      ],
      currentTab: -1,

      passwords: {
        currentPassword: "",
        password: "",
      },
      passwordTypes: {
        currentPassword: "password",
        password: "password",
      },
      inProcessConnect: [],
      editProfileData: [],
      accountSettingsData: [],
      verificationRequest: {
        description: "",
        attach_id: null,
      },
      socials: [],
      available: {
        email: null,
        username: null,
      },
      disableAccountSettingsButton: false,
      inProcessTab: {
        accountSettings: false,
        editProfile: false,
        changePassword: false,
        socialLinks: false,
        notifications: false,
        bankAccounts: false,
        verificationRequest: false,
        clearCache: false,
        disableAccount: false,
      },
    };
  },
  methods: {
    disableAccount() {
      this.inProcessTab.disableAccount = true;
      axios.delete(`${API.v2.me}`)
      .then((res) => {
        this.inProcessTab.disableAccount = false;
        this.$store.dispatch(USER_REQUEST)
      })
    },
    verificationRequestUploadComplete(res) {
      this.verificationRequest.attach_id = res.attachmentId;
    },
    doPaste(e) {
      // console.log(e);
    },
    doFocus(el) {
      let val = el.value;
      el.value = "";
      el.value = val;
      el.focus();
    },
    onFocusShetab(index) {
      if (index === 0) return;
      // console.log(
      //   "this.bankAccounts.shetabTmp[index-1].length",
      //   this.bankAccounts.shetabTmp[index - 1].length
      // );
      if (this.bankAccounts.shetabTmp[index - 1].length === 0) {
        this.doFocus(this.$refs.shetab[index - 1]);
      }
    },
    onKeyDownShetab(index, e) {
      if (e.keyCode === 8) {
        if (index !== 0 && this.bankAccounts.shetabTmp[index].length === 0) {
          this.doFocus(this.$refs.shetab[index - 1]);
        }
      } else {
        if (index != 3 && this.bankAccounts.shetabTmp[index].length === 4) {
          this.doFocus(this.$refs.shetab[index + 1]);
        }
      }
    },
    clickOnProvider(provider) {
      if (this.connectedSocials.indexOf(provider.name) === -1) {
        bus.$emit("socialConnect", provider.name);
      } else {
        if (this.inProcessConnect.indexOf(provider.name) === -1) {
          this.inProcessConnect.push(provider.name);
          axios.delete(API.baseURL + "/social/" + provider.name).then(() => {
            this.$store.dispatch(USER_REQUEST);
          });
        }
      }
    },
    onKeyUpAccount(item) {
      this.disableAccountSettingsButton = true;
      let searchKey = item.key;
      this.available[searchKey] = -1;
      let windowKey = "getAvailable" + item.key + "RequestManager";
      if (window[windowKey] != null) window[windowKey].cancel();
      let CancelToken = axios.CancelToken;
      window[windowKey] = CancelToken.source();
      axios
        .get(API.available + "?" + searchKey + "=" + item.value, {
          cancelToken: window[windowKey].token,
        })
        .then((resp) => {
          this.disableAccountSettingsButton = false;
          if (resp.data.success) {
            this.available[searchKey] = true;
          } else {
            this.available[searchKey] = false;
          }
        });
    },
    selectLocation(loc, item) {
      this.locations.selectedId = loc.id;
      item.value = loc.name;
      // console.log('clickonloc', item)
    },
    keyUpInput(item) {
      if (item.label === "Location") {
        if (item.value.length > 1) {
          this.getLocations(item.value);
        } else {
          this.locations.items = [];
        }
      }
    },
    getLocations(query) {
      this.locations.notFound = false;
      this.locations.items = [];
      if (!this.locations.show) this.locations.show = true;
      this.locations.isLoading = true;
      if (window.getLocationsRequestManager != null)
        window.getLocationsRequestManager.cancel();
      let CancelToken = axios.CancelToken;
      window.getLocationsRequestManager = CancelToken.source();
      axios
        .get(API.getLocations + "&count=5&q=" + query, {
          cancelToken: window.getLocationsRequestManager.token,
        })
        .then((resp) => {
          this.locations.isLoading = false;
          this.locations.items = resp.data;
          if (!resp.data.length) {
            this.locations.notFound = true;
          }
        });
    },
    focusIn(item) {
      if (item.key === "location") {
        window.currentLocationSelected = {
          id: this.locations.selectedId,
          value: item.value,
        };
        this.locations.show = true;
      }
    },
    focusOut(item) {
      if (item.label === "Location") {
        setTimeout(() => {
          this.locations.show = false;
          if (
            this.locations.selectedId === null &&
            item.value != this.profile.location
          ) {
            item.value = "";
            this.locations.notFound = false;
          } else {
            if (
              window.currentLocationSelected.id === this.locations.selectedId
            ) {
              item.value = window.currentLocationSelected.value;
            }
          }
        }, 100);
      }
    },
    deleteSocial(social) {
      this.socials.splice(this.socials.indexOf(social), 1);
    },
    addSocial() {
      this.socials.push({
        href: "",
        type: "",
      });
    },
    onKeyUpSocial(social) {
      let tests = {
        youtube: /^(https?\:\/\/)?((www\.)?youtube\.com|youtu\.?be)\/.+$/,
        instagram: /^(https?\:\/\/)?((www\.)?instagram\.com)\/.+$/,
        telegram:
          /^(https?\:\/\/)?((www\.)?telegram\.org|t\.?me|tlgrm\.?me)\/.+$/,
        twitter: /^(https?\:\/\/)?((www\.)?twitter\.com|t\.?co)\/.+$/,
        soundcloud: /^(https?\:\/\/)?((www\.)?soundcloud\.com)\/.+$'/,
        facebook: /^(https?\:\/\/)?((www\.)?facebook\.com|fb\.?com)\/.+$/,
        whatsapp: /^(https?\:\/\/)?((www\.)?whatsapp\.com|wa\.?me)\/.+$/,
      };
      if (social.href === "") {
        social.type = "";
      } else {
        let finded = false;
        Object.keys(tests).map((k) => {
          // console.log(
          //   "social.href.match(tests[k])",
          //   k,
          //   social.href.match(tests[k]),
          //   social.href,
          //   tests[k]
          // );
          if (social.href.match(tests[k])) {
            finded = true;
            social.type = k;
          }
        });
        if (!finded) {
          social.type = "global";
        }
      }
    },
    clickShowPassBtn(field) {
      this.passwordTypes[field] =
        this.passwordTypes[field] === "text" ? "password" : "text";
    },
    save(data, name) {
      let requestData = {};
      if (data.length && data[0].items != undefined) {
        data[0].items.map((e) => {
          if (e.key === "location") {
            requestData["cityId"] = this.locations.selectedId;
          } else {
            requestData[e.key] = e.value;
          }
        });
      } else if (name === "socialLinks") {
        requestData = { socials: [] };
        data.map((e) => {
          requestData.socials.push(e.href);
        });
      } else {
        requestData = data;
      }
      if (requestData.shetabTmp != undefined) {
        requestData.shetab = requestData.shetabTmp.join("");
      }
      // console.log("requestData", requestData);
      this.inProcessTab[name] = true;
      if (name === "verificationRequest") {
        axios
          .post(API.verification, requestData)
          .then((resp) => {
            this.inProcessTab[name] = false;
            this.verificationRequest.attach_id = null;
            this.verificationRequest.description = "";
            bus.$emit("success", {
              title: "",
              message:
                "Verification request has been submitted successfully and we will review it as soon as possible.",
            });
          })
          .catch((e) => {
            let msg = "";
            this.inProcessTab[name] = false;
            if (e.response.status === 400) {
              msg =
                "You have already submitted a verification request and its under review, you must wait for its result before sending new one.";
            } else {
              msg =
                "Please fill the fields before submitting this form. All fields are required.";
            }
            bus.$emit("error", {
              title: "",
              message: msg,
            });
          });
      } else {
        axios
          .put(API.me, requestData)
          .then((resp) => {
            this.$store.dispatch(USER_REQUEST);
            this.inProcessTab[name] = false;
            bus.$emit("success", {
              title: "",
              message: "Saved successfully",
            });
          })
          .catch((e) => {
            this.inProcessTab[name] = false;
            bus.$emit("error", {
              title: "",
              message:
                e.response.data.errors[
                  Object.keys(e.response.data.errors)[0]
                ][0],
            });
            let resp = e.response;
          });
      }
    },
    tabChanged(currentTab) {
      //this.currentTab = currentTab
      this.$router.push("/settings/" + this.tabSlugs[currentTab]);
    },
    autoSetTab() {
      let currentTab = 0;
      // console.log(
      //   "this.tabSlugs.indexOf(this.$route.params.type)",
      //   this.tabSlugs.indexOf(this.$route.params.type)
      // );
      if (!!this.$route.params.type) {
        currentTab =
          this.tabSlugs.indexOf(this.$route.params.type) === -1
            ? 0
            : this.tabSlugs.indexOf(this.$route.params.type);
      }
      // console.log("currentTab", currentTab);
      this.currentTab = currentTab;
    },
    getData() {
      this.inProcessConnect = [];
      this.getAccountSettingsData();
      this.getEditProfileData();
      this.getSocialsData();
      this.getBankAccounts();
    },
    getBankAccounts() {
      if (this.profile.metas === undefined) return;
      if (this.profile.metas.shetab != undefined) {
        this.bankAccounts.shetabTmp = this.profile.metas.shetab.match(/.{4}/g);
      }
      if (this.profile.metas.paypal != undefined) {
        this.bankAccounts.paypal = this.profile.metas.paypal;
      }
    },
    getAccountSettingsData() {
      if (this.profile.email === undefined) return;
      this.accountSettingsData = [
        {
          label: "Core Settings",
          items: [
            {
              label: "Username",
              value: this.profile.username,
              key: "username",
            },
            {
              label: "Email",
              value: this.profile.email,
              key: "email",
            },
          ],
        },
      ];
    },
    getEditProfileData() {
      if (this.profile.email === undefined) return;
      this.editProfileData = [
        {
          label: "Edit Profile",
          items: [
            {
              label: "Full Name",
              value: this.profile.fullname,
              key: "fullname",
              isInput: true,
            },
            {
              label: "Mobile",
              value: this.profile.metas.mobile,
              key: "mobile",
              isInput: true,
            },
            {
              label: "Location",
              key: "location",
              value:
                this.profile.location === undefined
                  ? ""
                  : this.profile.location,
              isInput: true,
            },
            {
              label: "Age",
              key: "age",
              value: this.profile.metas.age,
              isInput: true,
            },
            {
              label: "About",
              key: "about",
              value: this.profile.metas.about,
              isTextarea: true,
            },
          ],
        },
      ];
      if (
        this.profile.location !== undefined &&
        this.profile.location != "" &&
        this.profile.location !== null
      ) {
        this.locations.items = [
          {
            name: this.profile.location,
            id: this.profile.locationId,
          },
        ];
        this.locations.selectedId = this.profile.locationId;
      }
    },
    getSocialsData() {
      if (this.profile.metas === undefined) return [];
      this.socials = this.profile.metas.socials;
    },
  },
  computed: {
    providers() {
      let m = [
        {
          label: "Facebook",
          name: "facebook",
        },
        {
          label: "Apple",
          name: "apple",
        },
        {
          label: "Instagram",
          name: "instagram",
        },
        {
          label: "Google",
          name: "google",
        },
        {
          label: "twitter",
          name: "twitter",
        },
      ];
      return m.filter((e) => this.ms.activeSocials.indexOf(e.name) !== -1);
    },
    canSaveAccountSettings() {
      let values = [this.available.email, this.available.username];
      let r = values.filter((val) => {
        return val === true || val === null;
      });
      if (r.length === values.length) return true;
      return false;
    },
    tabIndexes() {
      return this.tabs.map(function (el, index) {
        return index;
      });
    },
    tabSlugs() {
      return this.tabs.map(function (el, index) {
        return el.replace(/ /g, "-").toLowerCase();
      });
    },
    ms() {
      return this.$store.getters.settings;
    },
    settings() {
      if (this.$store.getters.settings.notificationTypes === undefined)
        return [];
      return this.$store.getters.settings.notificationTypes;
    },
    profile() {
      return this.$store.getters.getProfile;
    },
    connectedSocials() {
      if (
        this.profile.connectedSocials === undefined ||
        !this.profile.connectedSocials.length
      )
        return [];
      return this.profile.connectedSocials.map((e) => {
        return e.provider;
      });
    },
    mySettings() {
      if (this.profile.notificationSettings === undefined) return {};
      return this.profile.notificationSettings;
    },
  },
  watch: {
    $route: function (newVal) {
      this.autoSetTab();
    },
    profile: function () {
      this.getData();
    },
  },
  mounted() {
    this.autoSetTab();
    this.getData();
  },
};
</script>