<template>
    <div class="widget padding-x">
        <div class="widget-title">
            <h4 class="f-s-13"><i class="fal fa-chart-bar"></i> {{ 'filter_chart'  | lang}}</h4>
        </div>
        <div class="widget-content">
            <div class="chart-filters">
                <div class="part f-f-reg part-a">
                    <div class="uk-text-center uk-grid-none" uk-grid>
                        <div class="uk-width-1-2">
                            <div @click="setPostType('SND')" class="chart-filter f-s-12" :class="{active: request.postType==='SND'}">
                                Sound
                            </div>
                        </div>
                        <div class="uk-width-1-2">
                            <div @click="setPostType('VID')" class="chart-filter f-s-12" :class="{active: request.postType==='VID'}">
                                Video
                            </div>
                        </div>
                        <div ref="postTypeBorder" class="post-type-border-filter"></div>
                    </div>
                </div>
                <div class="part f-f-reg part-b">
                    <div class="uk-text-center uk-grid-none" uk-grid>
                        <div v-for="(v,k) in periods" :key="k" class="uk-width-1-3">
                            <div @click="setPeriod(v)" class="chart-filter f-s-080" :class="{active: request.period===v}">
                                {{k}}
                            </div>
                        </div>
                        <div ref="periodBorder" class="post-type-border-filter period"></div>
                    </div>
                </div>
                <div class="part">
                    <v-select :clearable="false" :searchable="true" v-model="request.country"
                              index="code" label="name" :reduce="country => country.code"
                              :options="countries"></v-select>
                </div>
                <div class="part">
                    <v-select :clearable="true" placeholder="Select language" :searchable="true"
                              v-model="request.language"
                              index="id" label="name" :reduce="country => country.id" :options="languages"></v-select>
                </div>
                <div class="part">
                    <v-select :clearable="true" placeholder="Select genre" :searchable="true" v-model="request.genre"
                              index="id" label="name" :reduce="country => country.id" :options="genres"></v-select>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        computed: {
            settings() {
                return this.$store.getters.settings
            },
            countries() {
                if (this.settings.countries != undefined)
                    return this.settings.countries
            },
            languages() {
                if (this.settings.postLanguages != undefined)
                    return this.settings.postLanguages
            },
            genres() {
                if (this.settings.genres != undefined)
                    return this.settings.genres
            }
        },
        data() {
            return {
                periods: {
                    'Today': 'DAY',
                    'This Week': 'WEEK',
                    'This Month': 'MONTH',
                },
                request: {
                    postType: 'SND',
                    country: 'ALL',
                    language: '',
                    period: '',
                },
                items: []
            }
        },
        methods: {
            setPeriod(period) {
                this.request.period = period
                this.setPeriodBorder()
            },
            setPostType(pt) {
                this.request.postType = pt
                let type = (pt==='SND') ? 'sound' : 'video'
                this.$router.push('/charts/' + type)
            },
            setPostTypeBorder() {
                let postTypes = {
                    SND: 0,
                    VID: 1
                }
                let pt = this.request.postType
                let translate = postTypes[pt] * 100
                this.$refs.postTypeBorder.style.transform = 'translateX(' + translate + '%)'
            },
            setPeriodBorder() {
                let periods = {
                    DAY: 0,
                    WEEK: 1,
                    MONTH: 2
                }
                let p = this.request.period
                let translate = periods[p] * 100
                this.$refs.periodBorder.style.transform = 'translateX(' + translate + '%)'
            }
        },
        watch: {
            'request': {
                handler: function (newVal) {
                    // console.log('newVal', newVal)
                    bus.$emit('chartFilter', newVal)
                },
                deep: true
            },
            'request.postType': function() {
                this.setPostTypeBorder()
            },
            '$route.params.type': function(newVal) {
                this.request.postType = (newVal==='video') ? 'VID' : 'SND'
            }
        },
        mounted() {
            let pt = 'SND'
            if
            (this.$route.params.type != undefined) {
                pt = (this.$route.params.type === 'video') ? 'VID' : 'SND'
            }
            this.setPostType(pt)
            this.setPeriod('MONTH')
        }
    }
</script>