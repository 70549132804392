<template>
  <div>
    <div
      class="sound-large-player-cover-container"
      :class="{ 'coming-soon': comingSoon }"
      :style="{ 'background-image': 'url(' + blur + ')' }"
    >
      <div
        class="sound-large-player-cover"
        :style="{ 'background-color': background }"
      >
        <div class="sound-large-player-cover-content">
          <div class="uk-grid-10" uk-grid>
            <div class="uk-width-1-1"></div>
            <div class="uk-width-1-1 uk-width-auto@m">
              <div @click="playerAction()" class="sound-image">
                <ImagePlaceholder
                  radius="0.2rem"
                  width="100%"
                  :data-src="cover | attach('m')"
                />
                <div
                  v-if="!comingSoon && sound.realId != undefined"
                  class="play-action-container"
                >
                  <div class="play-action"></div>
                  <i
                    v-if="
                      current != null &&
                      current.realId == sound.realId &&
                      status === 'play'
                    "
                    class="fas fa-grip-lines-vertical"
                  ></i>
                  <i
                    v-if="
                      current != null &&
                      current.realId == sound.realId &&
                      status === 'wait'
                    "
                    class="fas fa-spinner-third fa-spin"
                  ></i>
                  <i
                    v-if="
                      current === null ||
                      current.realId !== sound.realId ||
                      (current.realId === sound.realId && status === 'pause')
                    "
                    class="fas fa-play"
                  ></i>
                </div>
                <div class="coming-soon-container f-f-reg" v-if="comingSoon">
                  <div class="coming-soon-main">
                    <div
                      class="uk-grid-none uk-child-width-auto uk-text-center"
                      uk-grid
                      :uk-countdown="'date: ' + sound.releaseDate"
                    >
                      <div>
                        <div
                          class="uk-countdown-number uk-countdown-days"
                        ></div>
                        <div
                          class="
                            uk-countdown-label uk-margin-small uk-text-center
                          "
                        >
                          Days
                        </div>
                      </div>
                      <div class="uk-countdown-separator">:</div>
                      <div>
                        <div
                          class="uk-countdown-number uk-countdown-hours"
                        ></div>
                        <div
                          class="
                            uk-countdown-label uk-margin-small uk-text-center
                          "
                        >
                          Hours
                        </div>
                      </div>
                      <div class="uk-countdown-separator">:</div>
                      <div>
                        <div
                          class="uk-countdown-number uk-countdown-minutes"
                        ></div>
                        <div
                          class="
                            uk-countdown-label uk-margin-small uk-text-center
                          "
                        >
                          Minutes
                        </div>
                      </div>
                      <div class="uk-countdown-separator">:</div>
                      <div>
                        <div
                          class="uk-countdown-number uk-countdown-seconds"
                        ></div>
                        <div
                          class="
                            uk-countdown-label uk-margin-small uk-text-center
                          "
                        >
                          Seconds
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="coming-soon-text">UNTIL RELEASE</div>
                </div>
              </div>
            </div>
            <div class="uk-width-expand">
              <div class="sound-content">
                <div class="sound-content-top">
                  <div class="uk-grid-10 uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">
                      <div
                        v-if="sound.realId === undefined"
                        class="sound-title f-s-2 uk-text-truncate"
                      >
                        <TextSkeleton
                          :style="{ margin: '10px 3px' }"
                          height="20px"
                          width="100px"
                        />
                      </div>
                      <div
                        v-if="sound.realId === undefined"
                        class="artist-name f-s-2 uk-text-truncate"
                      >
                        <TextSkeleton
                          :style="{ margin: '10px 3px' }"
                          height="12px"
                          width="200px"
                        />
                      </div>

                      <div
                        v-if="sound.realId != undefined"
                        class="sound-title f-s-2 uk-text-truncate"
                        v-is-visibility="
                          (isVisible, entry) =>
                            helper.isVisible(isVisible, entry, sound)
                        "
                      >
                        <router-link
                          :to="'/' + sound.user.username + '/' + sound.slug"
                        >
                          {{ sound.title }}
                        </router-link>
                      </div>
                      <div
                        v-if="sound.realId != undefined"
                        class="artist-name f-s-10 uk-text-truncate"
                      >
                        <router-link
                          class="artist teammate"
                          :to="'/' + sound.user.username"
                        >
                          {{ sound.user.fullname }}
                        </router-link>
                        <router-link
                          class="artist teammate"
                          v-for="(teammate, index) in sound.users"
                          :key="index"
                          :to="'/' + teammate.username"
                          >, {{ teammate.fullname }}
                        </router-link>
                      </div>
                    </div>
                    <div
                      v-if="sound.realId != undefined"
                      class="uk-width-auto uk-text-right f-s-090"
                    >
                      <div class="genres">
                        <router-link
                          v-for="(genre, index) in sound.genres"
                          :key="index"
                          :to="'/genre/' + genre.slug"
                        >
                          {{ genre.name }}
                        </router-link>
                      </div>
                      <div class="time f-s-080">
                        {{ sound.metas.attachment.duration | minutes }}
                      </div>
                    </div>
                    <div v-if="sound.realId != undefined" class="uk-width-1-1">
                      <div
                        v-if="$route.name !== 'sound'"
                        class="playlist-name f-s-090 uk-text-truncate"
                      >
                        <i class="fal fa-compact-disc"></i>
                        <router-link :to="helper.permalink(playlist)"
                          >{{ playlist.title }}
                        </router-link>
                      </div>
                      <div
                        v-if="$route.name !== 'sound'"
                        class="playlist-tracks-count f-s-090 uk-text-truncate"
                      >
                        <i class="fal fa-stream"></i>
                        {{ playlist.tracks.length }} Tracks
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  v-if="sound.realId != undefined"
                  class="sound-content-bottom"
                >
                  <div class="f-s-090">
                    <button class="btn-action btn-trans">
                      <i
                        @click="like(sound)"
                        class="fa-heart"
                        :class="{
                          far: !sound.is.liked,
                          'fas active': sound.is.liked,
                        }"
                      ></i>
                      <span v-if="sound.stats.likes === 0"> Like </span>
                      <span v-if="sound.stats.likes != 0">
                        {{ sound.stats.likes | numformat }}
                      </span>
                    </button>
                    <button
                      @click="repost(sound)"
                      class="btn-action btn-trans"
                      :class="{ 'active active-gold': sound.is.reposted }"
                    >
                      <i class="far fa-retweet"></i>
                      {{ sound.stats.reposts | numformat }}
                    </button>
                    <button class="btn-action btn-trans" @click="copyLink">
                      <i class="far fa-link"></i>
                      <span>{{ "copy_link" | lang }}</span>
                    </button>
                    <button
                      @click="buy"
                      class="btn-action btn-trans"
                      v-if="sound.metas.sale && !sound.is.bought"
                    >
                      <i class="far fa-cloud-download"></i> <span>Buy</span>
                    </button>
                    <button @click="moreMenu" class="btn-action btn-trans">
                      <i class="far fa-ellipsis-h"></i> <span>More</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="sound.id !== undefined"
              class="uk-width-1-1 canvas-container"
            >
              <Waveform :sound="sound" :key="'waveform_' + sound.id"></Waveform>
            </div>
            <div v-if="sound.id != undefined" class="uk-width-1-1">
              <div class="new-comment-container">
                <div v-if="!disableCm" class="uk-grid-5" uk-grid>
                  <div class="uk-width-auto">
                    <div class="left">
                      <i class="fas fa-quote-right"></i>
                    </div>
                  </div>
                  <div class="uk-width-expand">
                    <div class="right">
                      <input
                        type="text"
                        @click="$parent.showLogin = true"
                        readonly="true"
                        v-if="auth.id === undefined"
                        ref="comment-txt"
                        :placeholder="'comment_placeholder_guest' | lang"
                        class="new-comment-text f-s-085"
                      />
                      <input
                        v-model="comment"
                        type="text"
                        @keyup="keyupComment"
                        @focus="
                          commentAt = minutesDur;
                          commentAtSec = duration;
                        "
                        @focusout="
                          commentAt = null;
                          commentAtSec = null;
                        "
                        v-if="auth.id != undefined && duration === 0"
                        ref="comment-txt"
                        :placeholder="'comment_placeholder_user' | lang"
                        class="new-comment-text f-s-085"
                      />
                      <input
                        v-model="comment"
                        type="text"
                        @keyup="keyupComment"
                        @focus="
                          commentAt = minutesDur;
                          commentAtSec = duration;
                        "
                        @focusout="
                          commentAt = null;
                          commentAtSec = null;
                        "
                        v-if="auth.id != undefined && duration > 0"
                        ref="comment-txt"
                        :placeholder="
                          'comment_placeholder_at_time'
                            | lang({
                              time: commentAt != null ? commentAt : minutesDur,
                            })
                        "
                        class="new-comment-text f-s-085"
                      />
                    </div>
                  </div>
                  <div class="uk-width-auto uk-flex uk-flex-middle f-s-1">
                    <button class="send-btn" @click="sendComment">
                      {{ "submit" | lang }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="sound.id != undefined" class="uk-width-1-1">
              <div class="uk-flex">
                <div class="f-s-080 flex-right">
                  <div class="info-box">
                    <i class="far fa-eye"></i>
                    {{
                      (sound.stats.views +
                        sound.stats.downloads +
                        sound.stats.plays)
                        | numformat
                    }}
                  </div>
                  <div class="info-box" v-if="helper.can('play', sound)">
                    <i class="far fa-play"></i>
                    {{ sound.stats.plays | numformat }}
                  </div>
                  <div class="info-box" v-if="helper.can('download', sound)">
                    <i class="far fa-cloud-download"></i>
                    {{ sound.stats.downloads | numformat }}
                  </div>
                  <div class="info-box">
                    <i class="far fa-clock"></i> {{ sound.releaseDateTimeago }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import helper from "../../helpers/helper";
import WVF from "../../helpers/GenerateWaveform";
import Waveform from "../Waveform";
import ColorThief from "colorthief";
import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
import TextSkeleton from "@/components/Tools/TextSkeleton";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import API from '@/api'
export default {
  data() {
    return {
      sound: {},
      playlist: {},
      helper: helper,
      background: "rgba(0, 0, 0, 0)",
      commentAt: null,
      newComments: [],
      commentAtSec: null,
      comment: "",
      disableCm: false,
      tracks: [],
      dur: 0,
    };
  },
  components: {
    Waveform,
    ImagePlaceholder,
    TextSkeleton,
    myUpload,
  },
  props: ["value", "player"],
  methods: {
        copySuccessMessage() {
      bus.$emit("success", {
        title: "",
        message: this.$options.filters.lang("copy_success_msg"),
      });
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.copySuccessMessage();
        }
      } catch (err) {}

      document.body.removeChild(textArea);
    },
    copyLink() {
      let id = this.sound.realId
      if(this.playlist && this.playlist.id) {
        id = this.playlist.id
      }
      let text = "https://vow.im/" + id;
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
         () => {
          this.copySuccessMessage();
        },
        (err) => {
        }
      );
    },
    playerAction() {
      if (
        this.current != null &&
        this.current.realId == this.sound.realId &&
        this.status === "play"
      ) {
        this.pause();
      } else if (
        this.current != null &&
        this.current.realId == this.sound.realId &&
        this.status === "wait"
      ) {
      } else if (
        this.current != null &&
        this.current.realId == this.sound.realId &&
        this.status === "pause"
      ) {
        this.play();
      } else {
        this.$store.dispatch("setCurrent", this.sound);
        setTimeout(() => {
          this.$store.dispatch("setPlaylist", this.tracks);
        }, 300);
      }
    },
    play() {
      bus.$emit("playerPlay");
    },
    pause() {
      bus.$emit("playerPause");
    },
    moreMenu(e) {
      bus.$emit("clickMoreMenu", this.sound, e);
    },
    buy() {
      bus.$emit("buyPostModal", this.sound);
    },
    like(item) {
      let res = this.helper.like(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    repost(item) {
      let res = this.helper.repost(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    parseData() {
      let postType = { sound: "SND", album: "ALB", playlist: "PLY" };
      let vm = this;
      vm.playlist = {};
      vm.sound = {};
      //this.$emit('update', {})
      axios
        .get(
          API.posts +
            "?postType=" +
            postType[this.$route.name] +
            "&slug=" +
            this.$route.params.username +
            "|" +
            this.$route.params.slug
        )
        .then((res) => {
          bus.$on("updatePost_" + res.data.id, () => {
            this.parseData();
          });
          switch (this.$route.name) {
            case "album":
            case "playlist":
              vm.playlist = res.data;
              vm.playlist.currentTrack = 0;
              vm.sound = vm.playlist.tracks[vm.playlist.currentTrack];
              vm.tracks = vm.playlist.tracks;
              bus.$emit("trackList", vm.tracks);
              //vm.$emit('update', vm.playlist)

              break;
            case "sound":
              vm.sound = res.data;
              vm.tracks = [vm.sound];
              vm.$emit("update", vm.sound);
              break;
          }
        })
        .catch((e) => {
          try {
            switch (e.response.status) {
              case 404:
                this.$router.push("/404");
            }
          } catch (e) {
            setTimeout(() => {
              this.parseData();
            }, 2000);
          }
        });
    },
    coverLoaded() {
      const colorThief = new ColorThief();
      const result = colorThief.getColor(this.$refs.soundCover);
      this.background =
        "rgba(" + result[0] + ", " + result[1] + ", " + result[2] + ", 0.9)";
    },
    sendComment() {
      if (this.auth.id === undefined) {
        this.$parent.showLogin = true;
        return;
      }
      if (this.comment === "" || this.comment.trim() === "") return;
      if (this.disableCm) return;
      this.$refs["comment-txt"].blur();
      let comment = {
        position: this.commentAtSec === null ? 0 : this.commentAtSec,
        postId: this.sound.realId,
        comment: this.comment,
      };
      axios.post(API.comments, comment).then((res) => {
        bus.$emit("commentSent" + this.sound.realId, res.data);
      });
      bus.$emit("submitComment" + this.sound.realId, comment);
      this.comment = "";
      this.commentAt = null;
      this.commentAtSec = null;

      this.sound.stats.comments += 1;
      this.sound.stats.commentThreads += 1;
    },
    keyupComment(e) {
      if (e.keyCode === 13) {
        this.sendComment();
      }
    },
    getMinutes(dur) {
      let value = parseFloat(dur);
      if (!value || typeof value !== "number") return "00:00";
      let min = parseInt(value / 60),
        sec = parseInt(value % 60);
      min = min < 10 ? "0" + min : min;
      sec = sec < 10 ? "0" + sec : sec;
      value = min + ":" + sec;
      return value;
    },
  },
  mounted() {
    this.parseData();
    bus.$on("playerTimeUpdate", (duration) => {
      this.dur = Math.floor(duration);
    });
  },
  beforeDestroy() {
    // console.log("destroyed");
  },
  watch: {
    $route: function (route) {
      if (
        route.name === "sound" ||
        route.name === "album" ||
        route.name === "playlist"
      ) {
        this.parseData();
      } else {
        this.sound = {};
        this.playlist = {};
      }
    },
    duration: function (pos) {},
    auth: function () {
      this.parseData();
    },
    value: {
      handler: function () {
        if (
          this.current === null &&
          this.playlist.tracks != undefined &&
          this.playlist.tracks.length
        )
          this.sound = this.playlist.tracks[0];
      },
      deep: true,
    },
    current: {
      handler: function (cur) {
        if (this.playlist !== undefined) {
          if (this.tracks.indexOf(cur) !== -1) {
            this.sound = {...cur};
          }
        }
      },
      deep: true,
    },
    sound(sound) {
      let data = {};
      if (sound.realId != undefined) {
        data = {
          commentsCnt: sound.stats.comments,
          realId: sound.realId,
          releaseDate: sound.releaseDate,
          canComment: sound.metas.comment,
          mainCommentsCnt: sound.stats.commentThreads,
          tags: sound.tags || [],
          releaseTypeId: sound.metas.releaseTypeId,
          licenseTypeId: sound.metas.licenseTypeId,
          languages: sound.languages || [],
          recordLabels: sound.recordLabels || [],
          postType: sound.postType,
        };
        if (this.playlist.realId != undefined) {
          data.userId = this.playlist.user.id;
          data.setId = this.playlist.realId;
          data.setType = this.playlist.postType;
          data.setTitle = this.playlist.title;
          data.setDescription = null;
          data.setCover = this.$options.filters.attach(
            this.playlist.metas.cover,
            "m"
          );
        } else {
          data.userId = sound.user.id;
          data.setId = null;
          data.setType = null;
          data.setTitle = null;
          data.setDescription = null;
          data.setCover = null;
        }
      }
      bus.$emit("soundTabData", data);
    },
  },
  computed: {
    comingSoon() {
      return (
        this.sound != undefined &&
        this.sound.realId != undefined &&
        this.sound.releaseDateTimeago.indexOf("from now") != -1
      );
    },
    downloadUri() {
      let dlUri = "";
      // if (this.sound.metas != undefined) {
      //     let origDlUri = this.sound.metas.attachment.download
      //     if (this.auth.username != undefined) {
      //         return origDlUri + (origDlUri.indexOf('?') === -1 ? '?' : '&') + '_token=' + localStorage.getItem('user-token')
      //     }
      //     return origDlUri
      // }
    },
    cover() {
      if (this.sound.metas === undefined) return "";
      return this.sound.metas.cover;
    },
    blur() {
      if (this.sound.metas === undefined) return "";
      return this.$options.filters.attach(this.sound.metas.cover, "blur");
    },
    auth() {
      return this.$store.getters.getProfile;
    },
    duration() {
      if (this.current !== null && this.current.realId === this.sound.realId) {
        return this.$store.getters.getDuration;
      }
      return 0;
    },
    current() {
      return this.$store.getters.getCurrent;
    },
    status() {
      return this.$store.getters.playerStatus;
    },
    minutesDur() {
      return this.getMinutes(this.dur);
    },
  },
};
</script>