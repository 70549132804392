<template>
    <div>
        <div v-if="active" class="mobile-player">
            <div class="uk-grid uk-grid-5">
                <div class="uk-width-auto">
                    <image-placeholder width="60px" :data-src="current.metas.cover | attach('xs')" radius="0"/>
                </div>
                <div class="uk-width-expand uk-flex uk-flex-middle">
                    <div class="mobile-player-info uk-text-truncate">
                        <router-link class="title uk-text-truncate f-s-085" :to="helper.permalink(current)">
                            {{current.title}}
                        </router-link>
                        <div class="uk-clearfix"></div>
                        <router-link class="artist uk-text-truncate f-s-075" :to="helper.permalink(current.user)">
                            {{current.user.fullname}}
                        </router-link>
                    </div>
                </div>
                <div class="uk-width-auto uk-flex uk-flex-middle">
                    <div class="mobile-player-actions">
                        <span>
                            <i class="fas fa-backward" @click="prev()"></i>
                        </span>
                        <span>
                            <i v-if="playerStatus==='pause'" @click="play()" class="fas fa-play"></i>
                            <i v-if="playerStatus==='play'" @click="pause()" class="fas fa-pause"></i>
                            <i v-if="playerStatus==='wait'" class="fas fa-spinner-third fa-spin"></i>
                        </span>
                        <span>
                            <i class="fas fa-forward" @click="next()"></i>
                        </span>
                        <span>
                            <i class="fas fa-bars" @click="fullPlayer=true"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="full-mobile-player-layer" @click="fullPlayer = false">
            <span class="f-s-25 f-f-reg">﹀</span>
        </div>

        <div v-if="fullPlayer" :class="{active: fullPlayer}" ref="fmp" class="full-mobile-player">
            <perfect-scrollbar ref="scrollbar" id="fmpc" class="full-mobile-player-container">
                <ul class="vertical-audio-list" ref="nextUpUl" v-if="playlist.length">
                    <li @click="playPlaylist(item)" v-for="item in playlist" :data-id="item.realId"
                        v-bind:key="item.realId"
                        :data-active="item.realId===current.realId ? '1' : '0'">
                        <div class="uk-grid-small uk-flex uk-flex-middle vertical-audio-list-li" uk-grid>
                            <div class="uk-width-auto">
                                <div class="vertical-audio-img">
                                    <img :src="item.metas.cover | attach('xs')">
                                </div>
                            </div>
                            <div class="uk-width-expand vertical-audio-txt">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-expand">
                                        <div class="vertical-audio-top-txt f-s-075 uk-text-truncate">
                                            {{item.title}}
                                        </div>
                                        <div class="vertical-audio-btm-txt f-s-075 uk-text-truncate">
                                            {{item.user.fullname}}
                                        </div>
                                    </div>
                                    <div class="uk-width-auto">
                                        <div class="vertical-audio-more">
                                            <span class="vertical-audio-more-item vertical-audio-more-item-duration f-s-070">
                                                {{item.metas.attachment.duration | minutes}}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </perfect-scrollbar>
        </div>
    </div>

</template>

<script>
    import {DO_SET_PLAYER_STATUS, DO_SET_TRACK} from "@/store/actions/player";
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import helper from "@/helpers/helper";

    export default {
        props: ['controls'],
        data() {
            return {
                active: false,
                fullPlayer: false,
                dragging: false,
                helper: helper,
                touch: {
                    top: 0,
                    start: -1,
                    end: 0,
                    move: -1,
                    pos: -1
                }
            }
        },
        components: {
            ImagePlaceholder
        },
        methods: {
            play() {
                bus.$emit('playerPlay', item)
            },
            playPlaylist(item) {
                this.fullPlayer = false
                this.$store.dispatch('play', item)
            },
            next() {
                this.$store.dispatch('playNext')
            },
            prev() {
                this.$store.dispatch('playPrev')
            },
            pause() {
                bus.$emit('playerPause')
            },
            touchmove(e) {
                this.touch.move = e.touches[0].clientY
                if(this.touch.top===0 && this.touch.start===-1) {
                    this.touch.start = e.touches[0].clientY
                } else if(this.touch.top===0) {
                    let pos = this.touch.move - this.touch.start;
                    if(pos<0) pos = 0;
                    this.touch.pos = pos;
                    // requestAnimationFrame(() => {
                    if(pos>0) {
                        this.$refs.fmp.classList.add('scrolling');
                    }
                    this.$refs.fmp.style.transition = '0s transform';
                    this.$refs.fmp.style.transform = 'translateY(' + pos*0.7 + 'px)'
                    // })
                }
            },
            touchend(e) {
                this.touch.start = -1;
                this.$refs.fmp.classList.remove('scrolling')
                if(this.touch.pos>100) {
                    this.fullPlayer = false
                }
                this.$refs.fmp.style = ''
                // this.touch.end = e.touches[0].clientY
            },
            touchstart(e) {
                // this.touch.start = e.touches[0].clientY
            },
            scroll(e) {
                this.touch.top = document.getElementById('fmpc').scrollTop
            }
        },
        watch: {
            fullPlayer(newVal) {
                if(newVal) {
                    document.body.setAttribute('mobile-playlist', '1')
                } else {
                    document.body.removeAttribute('mobile-playlist')
                }
            },
            current: function (newVal, oldVal) {
                this.ct = newVal
                newVal = newVal || undefined
                oldVal = oldVal || undefined
                // this.$store.dispatch(SET_CURRENT_TRACK, newVal)
                // this.$store.commit(DO_SET_TRACK, newVal)
                if (newVal.realId === undefined) return
                this.texts = {
                    lyrics: null,
                    description: null
                }
                if (this.active === false) this.active = true
                document.body.setAttribute('mobile-player-active', '1')
                // if (newVal.realId != oldVal.realId) {
                    //this.scrollToCurrent()
                // }
                if (oldVal === undefined ||
                    newVal === undefined ||
                    oldVal.metas === undefined ||
                    newVal.metas === undefined ||
                    newVal.metas.cover.uri != oldVal.metas.cover.uri) {
                    window.dispatchEvent(new Event('resize'))
                    this.coverIsLoaded = false
                }
            },
            playerStatus: function (newVal) {
                // this.$store.commit(DO_SET_PLAYER_STATUS, newVal)
            },
        },
        computed: {
            player() {
                return this.controls.Player
            },
            playlist() {
                return this.$store.getters.getPlaylist
            },
            current() {
                return this.$store.getters.getCurrent
            },
            playerStatus() {
                return (this.$store.getters['playerStatus'] != undefined ? this.$store.getters['playerStatus'] : null)
            },
        }
    }
</script>