var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-post-med-item" }, [
    _vm.item.repostedBy != undefined && _vm.item.repostedBy.length
      ? _c("div", [
          _c(
            "div",
            { staticClass: "reposted-by-box f-f-reg f-s-080" },
            [
              _vm._l(_vm.item.repostedBy, function (user, index) {
                return _c(
                  "span",
                  [
                    _vm._v(
                      _vm._s(
                        index !== 0
                          ? index === _vm.item.repostedBy.length - 1 &&
                            _vm.item.stats.reposts ===
                              _vm.item.repostedBy.length
                            ? " & "
                            : " ,"
                          : ""
                      )
                    ),
                    _c("router-link", { attrs: { to: "/" + user.username } }, [
                      _vm._v(_vm._s(user.username)),
                    ]),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.item.stats.reposts != _vm.item.repostedBy.length
                ? _c("span", [
                    _vm._v(
                      "\n                and " +
                        _vm._s(
                          _vm.item.stats.reposts - _vm.item.repostedBy.length
                        ) +
                        " other" +
                        _vm._s(
                          _vm.item.stats.reposts -
                            _vm.item.repostedBy.length ===
                            1
                            ? ""
                            : "s"
                        ) +
                        "\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("span", [
                _vm._v("\n                reposted this post.\n            "),
              ]),
            ],
            2
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-flex uk-flex-middle uk-grid-20",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c(
            "div",
            {
              staticClass: "image",
              on: {
                click: function ($event) {
                  return _vm.$parent.play(_vm.item)
                },
              },
            },
            [
              _c("ImagePlaceholder", {
                attrs: {
                  width: "80px",
                  height: "0",
                  radius: "3px",
                  "data-src": _vm._f("attach")(_vm.item.metas.cover, "s"),
                },
              }),
              _vm._v(" "),
              _c("div", { staticClass: "v-post-med-item-actions" }, [
                _vm.currentTrack != null &&
                _vm.currentTrack == _vm.item.realId &&
                _vm.playerStatus === "play"
                  ? _c("i", { staticClass: "fas fa-grip-lines-vertical" })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentTrack != null &&
                _vm.currentTrack == _vm.item.realId &&
                _vm.playerStatus === "wait"
                  ? _c("i", { attrs: { "uk-spinner": "" } })
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentTrack === null ||
                _vm.currentTrack !== _vm.item.realId ||
                (_vm.currentTrack === _vm.item.realId &&
                  _vm.playerStatus === "pause")
                  ? _c("i", { staticClass: "fas fa-play" })
                  : _vm._e(),
              ]),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand main-col" }, [
          _c(
            "div",
            { staticClass: "title f-s-090" },
            [
              _c(
                "router-link",
                {
                  staticClass: "display-flex",
                  attrs: { to: _vm.helper.permalink(_vm.item) },
                },
                [
                  _c("span", { staticClass: "flex-truncate" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.item.title) +
                        "\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "artists uk-flex-middle f-s-080" }, [
            _c(
              "div",
              [
                _c(
                  "router-link",
                  {
                    staticClass: "display-flex",
                    attrs: { to: "/" + _vm.item.user.username },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: _vm._f("attach")(
                          _vm.item.user.photos.avatar,
                          "xs"
                        ),
                      },
                    }),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "names display-flex flex-truncate" },
              [
                _c(
                  "router-link",
                  { attrs: { to: "/" + _vm.item.user.username } },
                  [_vm._v(_vm._s(_vm.item.user.username))]
                ),
                _vm._v(" "),
                _vm._l(_vm.item.users, function (teammate) {
                  return _c(
                    "span",
                    [
                      _vm._v(", "),
                      _c(
                        "router-link",
                        { attrs: { to: "/" + teammate.username } },
                        [_vm._v(_vm._s(teammate.username))]
                      ),
                    ],
                    1
                  )
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "counters f-s-075" }, [
            _c("div", { staticClass: "counter mobile-hidden" }, [
              _c("i", { staticClass: "fas fa-heart" }),
              _vm._v(
                " " +
                  _vm._s(_vm._f("numformat")(_vm.item.stats.likes)) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "counter mobile-hidden" }, [
              _c("i", { staticClass: "fas fa-comment" }),
              _vm._v(
                " " +
                  _vm._s(_vm._f("numformat")(_vm.item.stats.comments)) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "counter mobile-hidden" }, [
              _c("i", { staticClass: "fas fa-retweet" }),
              _vm._v(
                " " +
                  _vm._s(_vm._f("numformat")(_vm.item.stats.reposts)) +
                  "\n                "
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "counter" }, [
              _c("i", { staticClass: "fas fa-eye" }),
              _vm._v(
                " " +
                  _vm._s(_vm._f("numformat")(_vm.item.stats.views)) +
                  "\n                "
              ),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "last-col" }, [
            _c("div", { staticClass: "genre-and-time f-s-075" }, [
              _vm.item.genres != undefined && _vm.item.genres.length
                ? _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "display-flex",
                          attrs: { to: "/genre/" + _vm.item.genres[0].id },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.item.genres[0].name) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.item.releaseDateTimeago) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions" }, [
              _c(
                "span",
                {
                  staticClass: "v-post-item-heart disable-click f-s-1",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.like(_vm.item)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-heart disable-click",
                    class: {
                      far: !_vm.item.is.liked,
                      "fas active": _vm.item.is.liked,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "v-post-item-retweet disable-click f-s-1",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.repost(_vm.item)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-retweet disable-click",
                    class: { "active active-gold": _vm.item.is.reposted },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "v-post-item-ellipsis disable-click f-s-2 disable-click more-menu-parent",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.moreMenu(_vm.item, $event)
                    },
                  },
                },
                [_c("i", { staticClass: "fal fa-ellipsis-v disable-click" })]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }