<template>
    <div class="widget padding-x uk-margin-top">
        <div class="widget-content">
            <div class="payments">

                <div class="overview">
                    <h2 class="title">
                        <i class="fal fa-wallet"></i> Unpaid Earnings
                    </h2>
                    <div uk-grid class="uk-flex uk-flex-bottom">
                        <div class="uk-width-1-1 uk-width-expand@s">
                            <div class="balances">
                                <div class="dollars">
                                    <label class="f-s-080">USD</label>
                                    <span class="f-s-15">${{dollars}}</span>
                                </div>
                                <div class="tomans">
                                    <label class="f-s-080">Tomans</label>
                                    <span class="f-s-15">{{tomans | numformat}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-width-1-1 uk-width-auto@s">
                            <button @click="checkout" class="uk-button uk-button-default uk-button-small min-width-100"
                                    :class="{'in-process': inProcess}" :disabled="inProcess">
                                <span>
                                    <i class="fas fa-hand-holding-usd"></i> Request
                                </span>
                                <span>
                                    <i uk-spinner="ratio: 0.5"></i>
                                </span>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="uk-flex uk-flex-row uk-flex-middle">
                    <div class="f-f-light">
                        <SwitchTab @setTab="tabChanged" :current-tab="currentTab" :tabs="leftTabs"/>
                    </div>
                    <div v-if="currentTab===1" class="flex-right f-s-070 f-f-tahoma payment-filters">
                        <SwitchTab :current-tab="currentTabB" @setTab="tabChangedB"
                                   class="uk-animation-slide-right-medium" padding="2px 8px" :tabs="rightTabs"/>
                    </div>
                </div>

                <div class="uk-margin-small-top">
                    <table class="uk-table uk-table-small uk-table-striped">
                        <thead>
                        <tr>
                            <th class="">Type</th>
                            <th class="">Price</th>
                            <th class=" uk-text-center">Transaction Id</th>
                            <th>Date</th>
                        </tr>
                        </thead>
                        <tbody>
                            <tr class="f-s-085" v-for="row in rows">
                                <td>{{row.reason}}</td>
                                <td><span :class="'payment-' + row.type.toLowerCase()">{{row.type==='IN' ? '+' : '-'}}</span> {{row.price}}</td>
                                <td class="uk-text-center">{{row.gatewayTransactionId===null ? '–' : row.gatewayTransactionId}}</td>
                                <td class="" :title="row.createdAt">{{row.createdAtAgo}}</td>
                            </tr>
                            <tr class="f-s-085" v-if="!rows.length && end">
                                <td colspan="4">No transaction found in this section.</td>
                            </tr>
                        </tbody>
                    </table>
                    <div v-if="rows.length>0 && !end" class="uk-text-center">
                        <i v-is-visibility="(visible) => loadMore(visible)" uk-spinner></i>
                    </div>
                    <div v-if="" class="uk-text-center">

                    </div>
                </div>
            </div>


        </div>

    </div>

</template>
<script>
    import SwitchTab from "@/components/SwitchTab";
    import axios from "axios";
    import {USER_REQUEST} from "@/store/actions/user";

    export default {
        components: {
            SwitchTab
        },
        data() {
            return {
                loading: false,
                inProcess: false,
                leftTabs: [
                    'Payments', 'Revenues'
                ],
                currentTab: 0,
                currentTabB: 0,
                filters: {
                    type: 'payments',
                    subtype: 'all',
                    count: 30,
                    skip: 0
                },
                end: false,
                rows: [],
                updatingBalance: false,
            }
        },
        mounted() {
            this.getData();
        },
        methods: {
            checkout() {
                let vm = this
                this.inProcess = true
                axios.post(API.payment.checkout)
                    .then((res) => {
                        this.inProcess = false
                        this.$store.dispatch(USER_REQUEST)
                        bus.$emit('success', {
                            title: 'Success',
                            message: res.data.message,
                        })
                    })
                    .catch(e => {
                        this.inProcess = false
                        let res = e.response
                        bus.$emit('error', {
                            title: 'Error',
                            message: res.data.message,
                        })
                    })
            },
            tabChanged(index) {
                this.currentTab = index
                this.filters.type = this.leftTabs[index].toLowerCase()
            },
            tabChangedB(index) {
                this.currentTabB = index
                this.filters.subtype = this.rightTabs[index].toLowerCase()
            },
            loadMore(visible) {
                if(visible) {
                    this.getData()
                }
            },
            getData(clear) {
                if(clear!==undefined && clear) {
                    this.rows = []
                    this.end = false
                    this.filters.skip = 0
                } else {
                    this.filters.skip = this.rows.length
                }
                if(window['paymentRequest']!=null)
                    window['paymentRequest'].cancel()
                let CancelToken = axios.CancelToken
                window['paymentRequest'] = CancelToken.source()

                axios.get(API.payment.get, {
                    params: this.filters,
                    cancelToken: window['paymentRequest'].token
                })
                    .then((resp) => {
                        this.loading = false
                        this.rows = this.rows.concat(resp.data)
                        if(resp.data.length<this.filters.count) {
                            this.end = true
                        }
                    })
            }
        },
        watch: {
            'filters.type': function() {
                this.getData(true)
            },
            'filters.subtype': function() {
                this.getData(true)
            },
            userStatus(newVal) {
                switch (newVal) {
                    case 'loading':
                        this.updatingBalance = true
                        break
                    default:
                        this.updatingBalance = false
                        break
                }
            },
        },
        computed: {
            auth() {
                return this.$store.getters.getProfile
            },
            userStatus() {
                return this.$store.getters.getProfileStatus
            },
            dollars() {
                if (this.updatingBalance) return '...'
                if (this.auth === undefined || this.auth.stats === undefined) return 0
                let dollars = parseFloat(this.auth.stats.balance.dollars)
                return dollars.toFixed(2)
            },
            tomans() {
                if (this.updatingBalance) return '...'
                if (this.auth === undefined || this.auth.stats === undefined) return 0
                return this.auth.stats.balance.tomans
            },
            rightTabs() {
                let tabs = ['All', 'Sales', 'Streams', 'Gifts', 'TopUp']
                if (this.auth != undefined && this.auth.role != undefined && this.auth.role != 'user') {
                    tabs.push('Salary')
                }
                return tabs
            }
        }
    }
</script>