import { SET_CURRENT_TRACK, SET_PLAYER_STATUS, DO_SET_PLAYER_STATUS, DO_SET_TRACK } from '../actions/player'
import {DO_SET_VIDEO, DO_SET_VIDEO_STATUS, SET_CURRENT_VIDEO, SET_VIDEO_STATUS} from "@/store/actions/player";
const state = { 
  currentTrack: null, 
  currentVideo: null, 
  status: '', 
  videoStatus: '', 
  hasLoadedOnce: false, 
  current: null, 
  playlist: null, 
  playlistType: null,
  shufflePlaylist: null,
  repeat: false,
  shuffle: false,
  duration: 0
}

const getters = {
  getCurrent: state => state.current,
  getCurrentTrack: state => state.currentTrack,
  playerStatus: state => state.status,
  videoStatus: state => state.videoStatus,
  getCurrentVideo: state => state.currentVideo,
  getPlaylist: state => (state.shufflePlaylist || state.playlist),
  getRepeat: state => state.repeat,
  getShuffle: state => state.shufflePlaylist !== null,
  getShufflePlaylist: state => state.shufflePlaylist,
  getDuration: state => state.duration,
  getPlaylistItemsIds(state, getters) {
    const p = getters.getPlaylist
    const x = p !== null && p.length && p.map(e => {
      return e.realId
    })
    return x || []
  },
  getPlaylistType: state => state.playlistType,
  getNext(state, getters) {
    // const repeatStatus = getters.getRepeat
    // if(repeatStatus === 1) {
    //   return getters.getCurrent
    // }
    const playlist = getters.getPlaylist
    let cI = 0;
    let pL = playlist.length;
    let lpL = pL-1
    if(pL) {
      for(const plIndex in playlist) {
        const cP = playlist[plIndex]
        if(cP.realId === state.current.realId) {
          cI = parseInt(plIndex) + 1
        }
      }
    }
    if(cI > lpL) {
      cI = 0
    }
    // if (cI === 0 && repeatStatus === false) {
    //   return null
    // }
    return playlist[cI]
  },
  getPrev(state, getters) {
    const playlist = getters.getPlaylist
    let pL = playlist.length;
    let lpL = pL-1
    let cI = lpL;
    if(pL) {
      for(const plIndex in playlist) {
        const cP = playlist[plIndex]
        if(cP.realId === state.current.realId) {
          cI = parseInt(plIndex) - 1
        }
      }
    }
    if(cI < 0) {
      cI = lpL
    }
    return playlist[cI]
  }
};

const actions = {
  updateTime: ({commit}, time) => {
    commit('updateTime', time)
  },
  shuffle: ({commit, getters}) => {
    let nShuffle = null
    const currentShuffle = getters.getShufflePlaylist
    if(currentShuffle === null) {
      nShuffle = Object.values(getters.getPlaylist)
      nShuffle.sort(() => Math.random() - 0.5);
    }
    commit('setShuffle', nShuffle)
  },
  repeat: ({commit}, repeat) => {
    commit('setRepeat', repeat)
  },
  setCurrent: ({commit}, current) => {
    commit('setCurrent', current)
  },
  play: ({commit}, current) => {
    commit('setCurrent', current)
  },
  setPlaylist: ({commit}, playlist) => {
    commit('setPlaylist', Object.values(playlist || []))
  },
  playNext: ({commit, getters}) => {
    const next = getters.getNext
    commit('setCurrent', next)
  },
  playPrev: ({commit, getters}) => {
    const prev = getters.getPrev
    commit('setCurrent', prev)
  },
  [SET_CURRENT_TRACK]: ({commit, dispatch}, track) => {
    commit(DO_SET_TRACK, track)
  },
  setPlayerStatus: ({commit, dispatch}, status) => {
    commit(DO_SET_PLAYER_STATUS, status)
  },
  [SET_CURRENT_VIDEO]: ({commit, dispatch}, track) => {
    commit(DO_SET_VIDEO, track)
  },
  [SET_VIDEO_STATUS]: ({commit, dispatch}, status) => {
    commit(DO_SET_VIDEO_STATUS, status)
  }
}

const mutations = {
  updateTime: (state, time) => {
    state.duration = time
  },
  setPlaylistType: (state, type) => {
    state.playlistType = type
  },
  setRepeat: (state, repeat) => {
    state.repeat = repeat
  },
  setShuffle: (state, shuffle) => {
    state.shufflePlaylist = shuffle
  },
  playNext: (state) => {
    const {playlist} = state
    let cI;
    let pL = playlist.length;
    let lpL = pL-1
    if(pL) {
      for(const plIndex in playlist) {
        const cP = playlist[plIndex]
        if(cP.realId === state.current.realId) {
          cI = plIndex
        }
      }
    }
  },
  playPrev: (state) => {

  },
  setPlaylist: (state, playlist) => {
    state.playlist = playlist
  },
  setCurrent: (state, current) => {
    state.current = current
  },
  [DO_SET_PLAYER_STATUS]: (state, status) => {
    state.status = status
  },
  [DO_SET_TRACK]: (state, track) => {
    state.currentTrack = track
  },
  [DO_SET_VIDEO_STATUS]: (state, status) => {
    state.videoStatus = status
  },
  [DO_SET_VIDEO]: (state, track) => {
    state.currentVideo = track
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
}
