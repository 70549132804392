var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "widget padding-x" },
    [
      _vm.images.length
        ? _c("div", { staticClass: "uk-animation-fade uk-animation-10" }, [
            _c("div", { staticClass: "widget-title" }, [
              _c("h4", { staticClass: "f-s-13" }, [
                _c("i", { staticClass: "fab fa-instagram" }),
                _vm._v(" " + _vm._s(_vm._f("lang")(_vm.options.title))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "widget-content user-image-gallery-widget k-w-pe",
              },
              [
                _c(
                  "div",
                  { staticClass: "uk-grid-4", attrs: { "uk-grid": "" } },
                  _vm._l(_vm.images, function (image, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "uk-width-1-4" },
                      [
                        _c("img", {
                          attrs: { src: image.thumb },
                          on: {
                            click: function ($event) {
                              return _vm.showImage(index + 1)
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _vm.isMe && !_vm.images.length && !_vm.showConnectInstagramBtn
                  ? _c("div", { staticClass: "no-photos-yet" }, [
                      _vm._v(
                        "\n                It seems you don’t have any photos yet.\n            "
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("span", {
        staticClass: "uk-hidden",
        attrs: { id: "openGalleryImportant" },
        on: {
          click: function ($event) {
            return _vm.showImage(0)
          },
        },
      }),
      _vm._v(" "),
      _c("LightBox", {
        ref: "lightbox",
        attrs: {
          id: "imageGallery",
          disableScroll: true,
          startAt: 0,
          showLightBox: false,
          images: _vm.allImages,
        },
      }),
      _vm._v(" "),
      _vm.showConnectInstagramBtn
        ? _c("div", { staticClass: "connect-to-instagram-brn-container" }, [
            _c(
              "div",
              {
                staticClass: "login-with-social instagram",
                on: {
                  click: function ($event) {
                    return _vm.connectInstagram()
                  },
                },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c("span", { staticClass: "f-s-085" }, [
                  _vm._v(
                    "\n                Connect With Instagram\n            "
                  ),
                ]),
              ]
            ),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "ico" }, [
      _c("i", { staticClass: "fab fa-instagram" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }