var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: _vm.options.subclass }, [
    _c(
      "div",
      {
        staticClass: "capsule-item",
        class: { loaded: _vm.item.id != undefined },
        on: { click: _vm.open },
      },
      [
        _c("div", { staticClass: "bg" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "main uk-grid uk-flex uk-flex-middle uk-grid-10" },
          [
            _c("div", { staticClass: "uk-width-auto" }, [
              _c(
                "div",
                { staticClass: "image" },
                [
                  _c("ImagePlaceholder", {
                    attrs: {
                      "data-src": _vm._f("attach")(_vm.cover, "xs"),
                      width: "75px",
                      height: "75px",
                      radius: "50%",
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "uk-width-expand" }, [
              _c(
                "div",
                { staticClass: "title" },
                [
                  _vm.item.title === undefined
                    ? _c("TextSkeleton", {
                        attrs: { height: "20px", width: "60px" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.title != undefined
                    ? _c("span", { staticClass: "f-s-095" }, [
                        _vm._v(_vm._s(_vm.item.title)),
                      ])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "information" },
                [
                  _vm.item.user === undefined
                    ? _c("TextSkeleton", {
                        attrs: { height: "12px", width: "120px" },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.item.title != undefined
                    ? _c("span", { staticClass: "f-s-085" }, [
                        _vm.moods != null
                          ? _c("span", [_vm._v(_vm._s(_vm.moods) + " - ")])
                          : _vm._e(),
                        _c("span", { staticClass: "dur" }, [
                          _vm._v(
                            _vm._s(_vm._f("minutes")(_vm.item.tracksDuration))
                          ),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }