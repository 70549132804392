<template>
    <div class="widget padding-x">
        <div class="widget-title">
            <h4 class="f-s-13">{{options.title | lang}}</h4>
            <h6 class="f-s-090">{{options.subtitle | lang}}</h6>
        </div>
        <div class="widget-content capsule-slider-widget">
            <div class="capsule-slider">
                <div class="capsule-slider-items">
                    <flicking
              :options="{
                renderOnlyVisible: false,
                align: 'prev',
                preventClickOnDrag: true,
                bound: true,
                circularFallback: 'bound',
              }"
            >
              <div
                class="flicking-panel"
                v-for="(item, index) in items"
                :key="index"
              >
                <CapsuleItem
                  @dop="dop"
                  :item="item"
                  @moreMenu="moreMenu"
                  :options="{ subclass: 'capsule-slider-item' }"
                />
              </div>
            </flicking>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Slider from "@/components/Tools/Slider";
    import CapsuleItem from "@/components/Items/CapsuleItem"
    export default {
        components: {
            Slider,
            CapsuleItem
        },
        data() {
            return {
                breakpoints: {
                    320: {
                        slidesPerView: 1.1,
                        spaceBetween: 10
                    },
                    480: {
                        slidesPerView: 1.3,
                        spaceBetween: 5
                    },
                    640: {
                        slidesPerView: 1.5,
                        spaceBetween: 10
                    },
                    1400: {
                        slidesPerView: 2.5,
                        spaceBetween: 10
                    },
                    2000: {
                        slidesPerView: 3.5,
                        spaceBetween: 10
                    },
                    10000: {
                        slidesPerView: 5.5,
                        spaceBetween: 20
                    }
                }
            }
        },
        props: {
            items: {
                type: Array,
                default: () => {
                    return []
                }
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
        },
        watch: {
        },
        methods: {
            getData() {
                axios.get(this.options.url)
                    .then(resp => {
                        this.items = resp.data
                    })
            }
        },
        mounted() {
            // let tmp = []
            // for(let i = 0; i<10; i++) {
            //     tmp.push({})
            // }
            // this.items = tmp
            this.getData()
        }
    }


</script>