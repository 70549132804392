<template>
    <div>
        <div v-if="users.length" class="mini-chat-title">
            <h5>Contacts</h5>
        </div>
        <div class="chat-users-list">
            <div v-for="user in users" class="chat-user">
                <router-link :to="'/chat/'+user.username">
                    <div class="uk-grid-5 uk-flex uk-flex-middle" uk-grid>
                        <div class="uk-width-auto">
                            <img width="40" height="40" :src="user.profile_uri==='' ? 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHUcNmf-luvb2nFRc4TIG8iT1oaqtygWWgPqxs5LOxO-Htc7-6' : (user.profile_uri + '_150.jpg')"/>
                        </div>
                        <div class="uk-width-expand">
                            <div class="chat-name">
                                {{user.fullname}}
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    props: ['users'],
    data() {
      return {
        chats: []
      }
    }
  }
</script>