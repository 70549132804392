<template>
    <div class="widget padding-x uk-margin-small-top contact-us-widget settings" ref="mainContainer">
        <div>
            <div class="widget-title">
                <h4 class="f-s-13">Contact Us</h4>
                <h6 class="f-s-090">contact</h6>
            </div>
            <div class="widget-content user-slider-widget">
                <div>
                    <div v-if="auth.id===undefined" class="setting">
                        <div class="uk-grid">
                            <div class="uk-width-expand">
                                <label :class="labelClass" for="name">Name</label>
                            </div>
                            <div class="uk-width-auto">
                                <input type="text" id="name" placeholder="" v-model="form.name" class="settings-input" />
                            </div>
                        </div>
                    </div>
                    <div v-if="auth.id===undefined" class="setting">
                        <div class="uk-grid">
                            <div class="uk-width-expand">
                                <label :class="labelClass" for="email">E-Mail</label>
                            </div>
                            <div class="uk-width-auto">
                                <input type="email" id="email" v-model="form.email" placeholder="" class="settings-input" />
                            </div>
                        </div>
                    </div>
                    <div class="setting">
                        <div class="uk-grid">
                            <div class="uk-width-expand">
                                <label :class="labelClass" for="department">Department</label>
                            </div>
                            <div class="uk-width-auto">
                                <select id="department" class="settings-input" v-model="form.department">
                                    <option v-for="department in departments" v-key="department.id" :value="department.id">
                                        {{department.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="setting">
                        <div class="uk-grid">
                            <div class="uk-width-expand">
                                <label :class="labelClass" for="subject">Subject</label>
                            </div>
                            <div class="uk-width-auto">
                                <input id="subject" type="text" class="settings-input" v-model="form.subject"/>
                            </div>
                        </div>
                    </div>
                    <div class="setting">
                        <drag-and-drop @upload-complete="complete" :upload-url="uploadUri"></drag-and-drop>
                    </div>
                    <div class="setting">
                        <div class="uk-grid">
                            <div class="uk-width-expand">
                                <label :class="labelClass" for="description">Description</label>
                            </div>
                            <div class="uk-width-auto">
                                <textarea id="description" v-model="form.description" class="settings-input" rows="5" placeholder="Description"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="setting display-flex">
                        <PButton @clicked="save" class="flex-right" text="Save" :in-process="inProcess"></PButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import DragAndDrop from "@/components/Tools/DragAndDrop";
    import PButton from "@/components/Tools/PButton";
    import axios from 'axios'
    export default {
        components: {DragAndDrop, PButton},
        data() {
            return {
                form: {
                    name: '',
                    email: '',
                    department: '',
                    attachment: '',
                    subject: '',
                    description: '',
                },
                labelClass: 'f-s-080',
                uploadUri: API.baseURL + API.contact.upload,
                inProcess: false
            }
        },
        computed: {
            settings() {
                return this.$store.getters.settings
            },
            departments() {
                if(this.settings===null || this.settings.departments===undefined) return []
                return this.settings.departments
            },
            auth() {
                return this.$store.getters.getProfile
            }
        },
        methods: {
            complete(data) {
                this.form.attachment = data.id
            },
            save() {
                this.inProcess = true
                axios.post(API.contact.store, this.form)
                    .then(() => {
                        this.inProcess = false
                        this.form = {
                            name: '',
                            email: '',
                            department: '',
                            attachment: '',
                            subject: '',
                            description: ''
                        }
                        bus.$emit('success', {
                            title: '',
                            message: 'Message success contact'
                        })
                    })
                    .catch((resp) => {
                        this.inProcess = false
                        if(resp.response.status===422) {
                            bus.$emit('error', {
                                title: '',
                                message: resp.response.data.errors[Object.keys(resp.response.data.errors).shift()][0]
                            })
                        }
                    })
            }
        }
    }
</script>