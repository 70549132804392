<template>
    <div class="plus-minus">
        <div class="uk-grid-5 uk-text-center uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-auto">
                <div ref="minus" class="minus">
                    <i class="fal fa-minus-circle"></i>
                </div>
            </div>
            <div class="uk-width-expand">
                <div class="value uk-flex uk-flex-middle">
                    <span :style="{'font-size': fontSize + 'px'}">{{value | numformat}}</span>
                </div>
            </div>
            <div class="uk-width-auto">
                <div ref="plus" class="plus">
                    <i class="fal fa-plus-circle"></i>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {

      }
    },
    props: {
      min: {
        type: Number,
        default: 0
      },
      max: {
        type: Number,
        default: 10
      },
      range: {
        type: Number,
        default: 1
      },
      value: {
        type: Number,
        default: 5
      },
      fs: {
        type: Number,
        default: 60
      },
      fsRange: {
        type: Number,
        default: 5
      },
      text: {
        type: String,
        default: ''
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      isTouch() {
        var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
        var mq = function(query) {
          return window.matchMedia(query).matches;
        }

        if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
          return true;
        }

        // include the 'heartz' as a way to have a non matching MQ to help terminate the join
        // https://git.io/vznFH
        var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
        return mq(query);
      },
      fontSize() {
        let val = this.value.toString()
        let len = val.length
        let fs = this.fs
        let minus = (len-1) * this.fsRange
        let tot = fs-minus
        if(tot<20) return 20
        return tot
      }
    },
    methods: {
      plus() {
        if(this.disabled) return
        if(this.value===this.max) return
        let newValue = this.value + this.range
        if(newValue>this.max) {
          this.value = this.max
        } else {
          this.value = newValue
        }
        this.setValue()
      },
      minus() {
        if(this.disabled) return
        if(this.value===this.min) return
        let newValue = this.value - this.range
        if(newValue<this.min) {
          this.value = this.min
        } else {
          this.value = newValue
        }
        this.setValue()
      },
      setValue() {
        this.$emit('update', this.value)
      },
      startEvents() {
        this.$refs.plus.addEventListener((this.isTouch ? 'touchstart' : 'mousedown'), this.plusDown)
        this.$refs.minus.addEventListener((this.isTouch ? 'touchstart' : 'mousedown'), this.minusDown)
      },
      endEvents() {
        this.$refs.plus.removeEventListener((this.isTouch ? 'touchstart' : 'mousedown'), this.plusDown)
        this.$refs.minus.removeEventListener((this.isTouch ? 'touchstart' : 'mousedown'), this.minusDown)
      },
      plusDown() {
        this.plus()
        window.plusDownInterval = setInterval(() => {
          this.plus()
        }, 150)
        window.addEventListener((this.isTouch ? 'touchend' : 'mouseup'), this.plusUp)
      },
      minusDown() {
        this.minus()
        window.minusDownInterval = setInterval(() => {
          this.minus()
        }, 150)
        window.addEventListener((this.isTouch ? 'touchend' : 'mouseup'), this.minusUp)
      },
      plusUp() {
        clearInterval(window.plusDownInterval)
      },
      minusUp() {
        clearInterval(window.minusDownInterval)
      }
    },
    mounted() {
      this.startEvents()
    },
    beforeDestroy() {
      this.endEvents()
    }
  }
</script>