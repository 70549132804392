const base = 'https://core.vowave.com';
let API = {
  v2: {
    posts: base + '/api/v2/posts',
    me: base + '/api/v1/me',
    claim: base + '/api/v2/claim'
  },
  base: 'https://core.vowave.com',
  baseURL: 'https://core.vowave.com/api/v1',
  playerId: 'player-id',
  contact: {
    store: '/contact',
    upload: '/contact/upload',
  },
  page: '/page',
  me: '/me',
  liveStats: '/me/stats/live',
  meUploadImage: '/me/upload/image',
  notifications: '/me/notifications',
  notificationsCount: '/me/notifications-count',
  verification: '/me/verification',
  mySets: '/me/sets',
  setTrack: '/me/sets/track',


  getSettings: '/preget',
  getUserBasedCountries: '/user-based-countries',

  register: '/register',
  login: '/login',
  logout: '/logout',
  forgotPassword: {
    request: '/forgot-password/request',
    check: '/forgot-password/check',
    changePassword: '/forgot-password/change-password'
  },

  screenshot: {
    get: '/screenshot/get'
  },

  users: '/users',
  getFollowers: '/users/followers',
  getFollowing: '/users/following',
  getLikes: '/posts/likes',
  getReposts: '/posts/reposts',
  getLocations: '/search?type=location',
  sounds: '/sounds',
  posts: '/posts',
  slugify: '/posts/slugify',
  postTexts: '/posts/texts',
  editPost: '/posts/edit',
  action: {
    follow: '/action/follow/',
    unfollow: '/action/unfollow/',
    like: '/action/like/',
    unlike: '/action/unlike/',
    repost: '/action/repost/',
    unrepost: '/action/unrepost/',
    teammateAccept: '/action/accept-teammate/',
    teammateReject: '/action/decline-teammate/'
  },
  userLikes: '/likes',
  comments: '/comments',
  events: '/events',
  available: '/available',
  activation: '/activation',
  files: '/files',
  remoteUpload: '/upload/remote',
  search: '/search',
  searchSuggestion: '/search/suggestion',
  payment: {
    get: '/me/payments',
    request: '/pprocessor',
    settings: '/pprocessor/settings',
    proPackages: '/pprocessor/pro-packages',
    checkout: '/checkout'
  },
  report: '/report',
  charts: '/charts'
}
export default API
