var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "placeholder-parent", class: { set: _vm.set } },
    [
      !_vm.loaded
        ? _c("div", {
            staticClass: "canvas-placeholer",
            class: { loaded: _vm.loaded },
            style: {
              borderRadius: _vm.radius,
              width: _vm.width,
              height: _vm.height,
              paddingBottom: _vm.height == "0" ? "100%" : "0",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("img", {
        staticClass: "image-with-placeholder",
        class: { loaded: _vm.loaded },
        style: { borderRadius: _vm.radius, width: _vm.width },
        attrs: { src: _vm.dataSrc },
        on: {
          load: function ($event) {
            _vm.loaded = true
          },
          error: function ($event) {
            _vm.loaded = false
          },
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }