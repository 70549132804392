<template>
    <div class="widget padding-x">
        <div class="widget-title">
            <h4 v-if="options.title!=null">{{options.title | lang}}</h4>
            <h6 v-if="options.subtitle!=null">{{options.subtitle | lang}}</h6>
        </div>
        <div class="widget-content">
            <Items type="VPostMedItem" v-if="items.length" :items="items"/>
            <div v-if="!end" class="uk-margin-top uk-text-center">
                <i v-is-visibility="(isVisible) => visibleMore(isVisible)" uk-spinner></i>
            </div>
            <div v-if="end && !items.length">
                <Empty/>
            </div>
        </div>
    </div>
</template>
<script>
    import Items from "@/components/Items/Items";
    import axios from 'axios'
    import Empty from "@/components/Tools/Empty";
    export default {
        data() {
            return {
                items: [],
                end: false,
                inProcess: false,
                count: 20
            }
        },
        components: {
            Items, Empty
        },
        props: {
            options: {
                type: Object,
                default: () => {
                    return {
                        title: null,
                        subtitle: null,
                        url: null
                    }
                }
            }
        },
        watch: {
            '$route.name': function() {
                this.items = []
                this.end = false
            }
        },
        methods: {
            visibleMore(isVisible) {
                if(isVisible) {
                    this.getData()
                }
            },
            getData() {
                if(this.options.url===null) return
                this.inProcess = true
                if (window['mediumListRequest'] != null)
                    window['mediumListRequest'].cancel()
                let CancelToken = axios.CancelToken
                window['mediumListRequest'] = CancelToken.source()
                axios.get(this.options.url + '?count=' + this.count + ('&skip=' + this.items.length), {
                    params: this.request,
                    cancelToken: window['mediumListRequest'].token
                })
                    .then((res) => {
                        this.inProcess = false
                        let items = this.items
                        items = items.concat(res.data)
                        this.items = items
                        if(res.data.length < this.count) {
                            this.end = true
                        }
                    })
                    .catch((e) => {
                        this.inProcess = false
                        let response = e.response
                    })
            }
        }
    }
</script>