<template>
    <div class="v-post-item">
        <div class="v-post-move">
        </div>
        <div class="uk-grid-none uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-auto">
                <div class="v-post-track-num f-s-1 f-f-light">
                    {{index+1+(options.startIndex!=undefined?options.startIndex:0)}}
                </div>
            </div>
            <div class="uk-width-auto" v-if="item.id === undefined">
                <div class="v-post-item-cover-inner">
                    <ImagePlaceholder width="45px" height="0" radius="0"/>
                </div>
            </div>
            <div class="uk-width-expand" v-if="item.id === undefined">
                <div class="v-post-item-container">
                    <div class="v-post-item-title"><text-skeleton/></div>
                    <div class="v-post-item-artist mt-1"><text-skeleton/></div>
                </div>
            </div>
            <div class="v-post-item-cover uk-width-auto" v-if="item.id !== undefined">
                <div class="v-post-item-cover-inner" @click="play">
<!--                    <img uk-img :src="item.metas.cover.uri | img('s')"/>-->
                    <ImagePlaceholder width="45px" height="0" radius="0" :data-src="item.metas.cover | attach('s')"/>
                    <div class="v-post-item-player-actions">
                        <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='play'"
                           class="fas fa-grip-lines-vertical"></i>
                        <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='wait'"
                           class="fas fa-spinner-third fa-spin"></i>
                        <i v-if="currentTrack===null || currentTrack!==item.realId || (currentTrack===item.realId && playerStatus==='pause')"
                           class="fas fa-play"></i>
                    </div>
                </div>
            </div>
            <div class="uk-width-expand" v-if="item.id !== undefined">
                <div class="v-post-item-container">
                    <div @click="closeSearch()" class="v-post-item-title f-s-090 uk-text-truncate">
                        <router-link :to="helper.permalink(item)">{{item.title}}</router-link>
                        <span class="explicit" v-if="item.metas.explicit">🅴</span>
                    </div>
                    <div @click="closeSearch()" class="v-post-item-artist f-s-080 uk-text-truncate">
                        <span class="artist"><router-link :to="'/' + item.user.username" class="disable-click">{{item.user.fullname}}</router-link></span>
                        <span class="teammate" v-for="teammate in item.users">, <router-link class="disable-click"
                                                                                             :to="'/' + teammate.username">{{teammate.fullname}}</router-link></span>
                    </div>
                </div>
            </div>
            <div class="uk-width-auto" v-if="item.id !== undefined">
                <div class="v-post-delete" @click="removeFromSet">
                    <i class="fas fa-trash-alt"></i>
                </div>
                <div class="v-post-views f-s-075 uk-flex uk-flex-middle disable-click">
                    <span v-if="options.showViews!=undefined&&options.showViews" class="v-post-item-views">
                        <i class="far fa-eye"></i>
                        {{item.stats.views | numformat}}
                    </span>
                    <span class="v-post-item-heart disable-click f-s-1" @click="like(item)">
                        <i class="far fa-heart disable-click"
                           :class="{'far' : !item.is.liked, 'fas active': item.is.liked}"></i>
                    </span>
                    <span class="v-post-item-retweet disable-click f-s-1" @click="repost(item)">
                        <i class="far fa-retweet disable-click" :class="{'active active-gold': item.is.reposted}"></i>
                    </span>
                    <span @click="$parent.moreMenu(item, $event)"
                          class="v-post-item-ellipsis disable-click f-s-15 disable-click more-menu-parent">
                        <i class="fal fa-ellipsis-h disable-click"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import helper from '../../helpers/helper'
    import MoreMenu from '../MoreMenu'
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
import TextSkeleton from '../Tools/TextSkeleton.vue';
    export default {
        data() {
            return {
                helper: helper,
                morekey: Math.random()
            }
        },
        components: {MoreMenu, ImagePlaceholder, TextSkeleton},
        props: ['item', 'index', 'manageMode', 'options'],
        computed: {
            currentTrack() {
                return this.$store.getters.getCurrent && this.$store.getters.getCurrent.realId
            },
            playerStatus() {
                return this.$store.getters.playerStatus
            }
        },
        methods: {
            removeFromSet() {
                this.$parent.$emit('doRemoveItemFromSet', this.item.realId, this.index)
            },
            closeSearch() {
                bus.$emit('closeSearch')
            },
            showMoreMenu() {
                console.log('show-more-item')
                this.item.showMore = ((this.item.showMore === undefined) ? true : !this.item.showMore);
                this.morekey = Math.random()
            },
            like(item) {
                this.$parent.like(item)
            },
            repost(item) {
                this.$parent.repost(item)
            },
            updateItem(item) {
                console.log('do update')
                this.item = item
            },
            play() {
                this.$parent.play(this.item)
            }
        },
        watch: {
            item: function (newVal) {
            }

        }
    }
</script>