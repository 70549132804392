<template>
    <div class="v-post-med-item">
        <div v-if="item.repostedBy!=undefined && item.repostedBy.length">
            <div class="reposted-by-box f-f-reg f-s-080">
                <span v-for="(user, index) in item.repostedBy">{{index!==0 ? (((index===item.repostedBy.length-1) && item.stats.reposts===item.repostedBy.length) ? ' & ' : ' ,') : ''}}<router-link :to="'/' + user.username">{{user.username}}</router-link></span>
                <span v-if="item.stats.reposts!=item.repostedBy.length">
                    and {{item.stats.reposts - item.repostedBy.length}} other{{(item.stats.reposts - item.repostedBy.length)===1 ? '' : 's'}}
                </span>
                <span>
                    reposted this post.
                </span>
            </div>
        </div>
        <div class="uk-flex uk-flex-middle uk-grid-20" uk-grid>
            <div class="uk-width-auto">
                <div class="image" @click="$parent.play(item)">
                    <!--                    <img :src="item.metas.cover | attach('s')"/>-->
                    <ImagePlaceholder width="80px" height="0" radius="3px" :data-src="item.metas.cover | attach('s')"/>
                    <div class="v-post-med-item-actions">
                        <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='play'"
                           class="fas fa-grip-lines-vertical"></i>
                        <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='wait'"
                           uk-spinner></i>
                        <i v-if="currentTrack===null || currentTrack!==item.realId || (currentTrack===item.realId && playerStatus==='pause')"
                           class="fas fa-play"></i>
                    </div>
                </div>
            </div>
            <div class="uk-width-expand main-col">
                <div class="title f-s-090">
                    <router-link class="display-flex" :to="helper.permalink(item)">
                        <span class="flex-truncate">
                            {{item.title}}
                        </span>
                    </router-link>
                </div>
                <div class="artists uk-flex-middle f-s-080">
                    <div>
                        <router-link class="display-flex" :to="'/' + item.user.username">
                            <img :src="item.user.photos.avatar | attach('xs')"/>
                        </router-link>
                    </div>
                    <div class="names display-flex flex-truncate">
                        <router-link :to="'/' + item.user.username">{{item.user.username}}</router-link>
                        <span v-for="teammate in item.users">, <router-link
                                :to="'/' + teammate.username">{{teammate.username}}</router-link></span>
                    </div>
                </div>
                <div class="counters f-s-075">
                    <div class="counter mobile-hidden">
                        <i class="fas fa-heart"></i> {{item.stats.likes | numformat}}
                    </div>
                    <div class="counter mobile-hidden">
                        <i class="fas fa-comment"></i> {{item.stats.comments | numformat}}
                    </div>
                    <div class="counter mobile-hidden">
                        <i class="fas fa-retweet"></i> {{item.stats.reposts | numformat}}
                    </div>
                    <div class="counter">
                        <i class="fas fa-eye"></i> {{item.stats.views | numformat}}
                    </div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="last-col">
                    <div class="genre-and-time f-s-075">
                        <div v-if="item.genres!=undefined && item.genres.length">
                            <router-link class="display-flex" :to="'/genre/' + item.genres[0].id">
                                {{item.genres[0].name}}
                            </router-link>
                        </div>
                        <div>
                            {{item.releaseDateTimeago}}
                        </div>
                    </div>
                    <div class="actions">
                        <span class="v-post-item-heart disable-click f-s-1" @click="$parent.like(item)">
                            <i class="far fa-heart disable-click"
                               :class="{'far' : !item.is.liked, 'fas active': item.is.liked}"></i>
                        </span>
                        <span class="v-post-item-retweet disable-click f-s-1" @click="$parent.repost(item)">
                            <i class="far fa-retweet disable-click"
                               :class="{'active active-gold': item.is.reposted}"></i>
                        </span>
                    </div>
                    <div>
                        <span @click="$parent.moreMenu(item, $event)"
                              class="v-post-item-ellipsis disable-click f-s-2 disable-click more-menu-parent">
                            <i class="fal fa-ellipsis-v disable-click"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import helper from '../../helpers/helper'
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";

    export default {
        data() {
            return {
                helper: helper
            }
        },
        components: {
            ImagePlaceholder
        },
        props: {
            item: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            index: {
                type: Number,
                default: 0
            }
        },
        methods: {},
        computed: {
            currentTrack() {
                return (this.$store.getters.getCurrent!=undefined&&this.$store.getters.getCurrent.realId!=undefined) ? this.$store.getters.getCurrent.realId : null
            },
            playerStatus() {
                return this.$store.getters.playerStatus
            }
        }
    }
</script>