<template>
    <div v-if="items.length" class="widget padding-x">
        <div class="widget-title">
            <h4 class="f-s-1"><i class="far fa-exchange-alt"></i> {{options.title | lang}}</h4>
        </div>
        <div class="widget-content">
            <div class="v-post-items uk-animation-fade uk-animation-10">
                <Items :items="items" :type="'VPostItem'"/>
            </div>
        </div>
    </div>
</template>
<script>
    import Items from '../Items/Items'

    export default {
        props: ['value', 'options'],
        data() {
            return {
                items: []
            }
        },
        watch: {
            '$route': function() {
                this.items = []
            }
        },
        components: {Items},
        methods: {},
        mounted() {
            bus.$off('grecentReposts')
            bus.$on('grecentReposts', (data) => {
                this.items = data
            })
        }
    }
</script>