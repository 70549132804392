<template>
    <div class="v-user-item">
        <div class="uk-flex uk-flex-middle uk-grid-5" uk-grid>
            <div class="uk-width-auto">
                <div class="v-user-image-container">
                    <img :src="item.photos.avatar | attach('xs')"/>
                </div>
            </div>
            <div class="uk-width-expand">
                <div class="v-user-fullname f-s-085">
                    <router-link class="a-with-png-ico" :to="'/' + item.username">
                        {{item.fullname}}
                        <span v-if="item.is.verified" class="png-ico verified small"></span>
                    </router-link>
                </div>
                <div class="v-user-username f-s-075">
                    @{{item.username}}
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="v-user-actions" v-if="auth.id!==undefined && auth.id !== item.id">
                    <button @click="$parent.follow(item)" v-if="!item.is.following" class="app-button f-s-080">{{'follow' |
                        lang}}
                    </button>
                    <button @click="$parent.follow(item)" v-if="item.is.following" class="app-button active f-s-080">
                        {{'unfollow' | lang}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['item', 'index'],
        data() {
            return {}
        },
        computed: {
            auth() {
                return this.$store.getters.getProfile
            }
        }
    }
</script>