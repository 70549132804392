<template>
    <div class="widget padding-x uk-animation-fade uk-animation-10"
         v-if="metas!=null && metas!=undefined && (metas.about!=null || metas.socials.length)">
        <div class="widget-title">
            <h4 class="f-s-13"><i class="fal fa-info-circle"></i> {{options.title | lang}}</h4>
        </div>
        <div class="widget-content">
            <div class="user-about-text" v-if="metas.about!=undefined"
                 :class="{expand: expandAbout, 'disable-expand': expandDisable}" ref="about" v-resize="onResize">
                <div v-html="bio"></div>
                <div @click="toggleAbout" class="show-more">
                    <div v-if="!expandAbout"><i class="fa fa-angle-down"></i> Show More</div>
                    <div v-if="expandAbout"><i class="fa fa-angle-up"></i> Show Less</div>
                </div>
            </div>
            <div class="user-social-items" v-if="metas.socials!==null">
                <div class="uk-grid-5" uk-grid>
                    <div class="uk-width-1-5" v-for="(social, index) in metas.socials" :key="index">
                        <a v-if="index<5" class="user-social-item" :href="social.href" target="_blank">
                            <i :class="(socialIcons[social.type]!==undefined) ? socialIcons[social.type] : socialIcons.official"></i>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import resize from 'vue-resize-directive'
    import axios from 'axios'

    export default {
        data() {
            return {
                socialIcons: {
                    official: 'fas fa-globe-europe',
                    facebook: 'fab fa-facebook-f',
                    twitter: 'fab fa-twitter',
                    youtube: 'fab fa-youtube',
                    telegram: 'fab fa-telegram-plane',
                    instagram: 'fab fa-instagram',
                    soundcloud: 'fab fa-soundcloud',
                },
                metas: null,
                expandAbout: false,
                expandDisable: false
            }
        },
        directives: {
            resize
        },
        props: ['value', 'options'],
        watch: {
            value: function () {
                // this.metas = null
            },
            $route(newVal, oldVal) {
                try {
                    if(newVal.params.username!==oldVal.params.username) {
                        this.metas = null
                    }
                } catch (e) {
                }
            }
        },
        computed: {
            bio() {
                let value = (this.metas!=undefined&&this.metas.about!=undefined&&this.metas.about!=null) ? this.metas.about : ''
                let breakTag = '<br />';
                value = (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
                return this.$options.filters.link(value)
            },
        },
        methods: {
            onResize() {
                let aboutSize = this.$refs.about.getBoundingClientRect()
                if (aboutSize.height < 130) {
                    this.expandDisable = true
                } else {
                    this.expandDisable = false
                }
            },
            toggleAbout() {
                this.expandAbout = !this.expandAbout
            },
        },
        mounted() {
            bus.$off('gmetas')
            bus.$on('gmetas', (data) => {
                this.metas = data
            })
        }
    }
</script>