var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uk-animation-fade uk-animation-10" }, [
    _c("div", { class: "splide slider-" + _vm.name }, [
      _c(
        "div",
        { staticClass: "splide__track" },
        [
          _c(_vm.type.indexOf("Ads") !== -1 ? "AdsItems" : "Items", {
            tag: "component",
            staticClass: "splide__list",
            attrs: {
              tagName: "ul",
              type: _vm.type,
              options: _vm.options,
              items: _vm.items,
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "uk-clearfix" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }