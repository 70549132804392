var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "v-track-item",
      class: { active: _vm.currentTrack == _vm.track.realId },
    },
    [
      _c("div", { staticClass: "uk-grid uk-grid-10 uk-flex uk-flex-middle" }, [
        _c("div", { staticClass: "uk-width-auto", on: { click: _vm.player } }, [
          _c(
            "div",
            { staticClass: "v-track-item-image" },
            [
              _c("image-placeholder", {
                attrs: {
                  width: "30px",
                  radius: "3px",
                  "data-src": _vm._f("attach")(_vm.track.metas.cover, "xs"),
                },
              }),
              _vm._v(" "),
              _vm.inited
                ? _c("div", { staticClass: "v-track-item-player-actions" }, [
                    _vm.currentTrack != null &&
                    _vm.currentTrack == _vm.track.realId &&
                    _vm.playerStatus === "play"
                      ? _c("i", { staticClass: "fas fa-grip-lines-vertical" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentTrack != null &&
                    _vm.currentTrack == _vm.track.realId &&
                    _vm.playerStatus === "wait"
                      ? _c("i", { attrs: { "uk-spinner": "" } })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentTrack === null ||
                    _vm.currentTrack !== _vm.track.realId ||
                    (_vm.currentTrack === _vm.track.realId &&
                      _vm.playerStatus === "pause")
                      ? _c("i", { staticClass: "fas fa-play" })
                      : _vm._e(),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "uk-width-expand", on: { click: _vm.player } },
          [
            _c("div", { staticClass: "information" }, [
              _c("div", { staticClass: "title f-f-reg f-s-085" }, [
                _vm._v(_vm._s(_vm.track.title)),
              ]),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-auto" }, [
          _c(
            "div",
            { staticClass: "v-track-item-actions uk-flex uk-flex-middle" },
            [
              _c(
                "span",
                {
                  staticClass: "v-post-item-heart disable-click f-s-090",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.$parent.like(_vm.track)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-heart disable-click",
                    class: {
                      far: !_vm.track.is.liked,
                      "fas active": _vm.track.is.liked,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "v-post-item-retweet disable-click f-s-090",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.$parent.repost(_vm.track)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-retweet disable-click",
                    class: { "active active-gold": _vm.track.is.reposted },
                  }),
                ]
              ),
              _vm._v(" "),
              _c("span", { staticClass: "duration f-f-reg f-s-075" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(
                      _vm._f("minutes")(_vm.track.metas.attachment.duration)
                    ) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass:
                    "v-post-item-ellipsis disable-click f-s-15 disable-click more-menu-parent",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.$parent.moreMenu(_vm.track, $event)
                    },
                  },
                },
                [_c("i", { staticClass: "fal fa-ellipsis-h disable-click" })]
              ),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }