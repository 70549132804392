var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "parent" }, [
    _c(
      "div",
      {
        ref: "container",
        staticClass: "switch-contents",
        style: { width: _vm.contents * 100 + "%" },
      },
      _vm._l(_vm.contentsArr, function (index) {
        return _c(
          "div",
          { staticClass: "switch-content" },
          [
            _c("transition", { attrs: { name: "fade", duration: 200 } }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: index === _vm.currentContent,
                      expression: "index===currentContent",
                    },
                  ],
                  staticClass: "content-body",
                },
                [_vm._t("content" + index)],
                2
              ),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }