<template>
    <button @click="clicked" class="vo-p-btn" :disabled="inProcess" :class="{processing: inProcess}">
        <span class="btn-text">{{text}}</span>
        <span class="abs-center btn-spinner">
            <i uk-spinner="ratio: 0.6"></i>
        </span>
    </button>
</template>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'Save'
            },
            inProcess: {
                type: Boolean,
                default: true
            }
        },
        methods: {
            clicked() {
                this.$emit('clicked')
            }
        }
    }
</script>