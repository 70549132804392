var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length
    ? _c("div", { ref: "mainContainer", staticClass: "widget padding-x" }, [
        _c("div", [
          _vm.options.title != undefined
            ? _c("div", { staticClass: "widget-title" }, [
                _c(
                  "div",
                  {
                    staticClass: "uk-flex uk-flex-middle",
                    attrs: { "uk-grid": "" },
                  },
                  [
                    _c("div", { staticClass: "uk-width-expand" }, [
                      _c("h4", { staticClass: "f-s-13" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("lang")(_vm.options.title, {
                              artist: _vm.value && _vm.value.fullname,
                            })
                          )
                        ),
                      ]),
                      _vm._v(" "),
                      _c("h6", { staticClass: "f-s-090" }, [
                        _vm._v(
                          _vm._s(
                            _vm._f("lang")(_vm.options.subtitle, {
                              artist: _vm.value && _vm.value.fullname,
                            })
                          )
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-width-auto" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "see-all-right",
                            attrs: { to: _vm.seeAllUrl },
                          },
                          [_vm._v("See all")]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "widget-content video-slider-widget" }, [
            _c(
              "div",
              { staticClass: "video-slider" },
              [
                _c(
                  "flicking",
                  {
                    attrs: {
                      options: {
                        renderOnlyVisible: false,
                        align: "prev",
                        preventClickOnDrag: true,
                        bound: true,
                        circularFallback: "bound",
                      },
                    },
                  },
                  _vm._l(_vm.items, function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "flicking-panel" },
                      [
                        _c("VideoSliderItem", {
                          attrs: { options: { subclass: "" }, item: item },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }