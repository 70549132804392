<template>
    <div class="widget padding-x uk-margin-small-top" ref="mainContainer">
        <div>
            <div class="widget-title">
                <h4 class="f-s-13">{{options.title | lang}}</h4>
                <h6 class="f-s-090">{{options.subtitle | lang}}</h6>
            </div>
            <div class="widget-content user-slider-widget">
                <div>
                    <label class="selectpage-label">
                        Record Labels
                    </label>
                    <v-selectpage
                            :data="' '"
                            :result-format="typedValueResultFormat"
                            :params="{type: 'recordLabels', selected: recordLabels}"
                            :multiple="false"
                            :language="'en'"
                            :placeholder="'Select Record Label'"
                            :title="'Record Labels'"
                            ref="recordLabels"
                            :max-select-limit="1"
                            v-model="recordLabels">
                    </v-selectpage>
                </div>
                <div class="uk-margin-small-top">
                    <label class="selectpage-label">
                        Hashtags
                    </label>
                    <v-selectpage
                            :data="' '"
                            :result-format="typedValueResultFormat"
                            :params="{type: 'tags', selected: hashtags}"
                            :multiple="true"
                            :language="'en'"
                            search-field="name"
                            :show-field="renderHashtagsRow"
                            :placeholder="'Select hashtags'"
                            :title="'Hashtags'"
                            key-field="slug"
                            :max-select-limit="10"
                            ref="hashtags"
                            v-model="hashtags">
                    </v-selectpage>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['options'],
        data() {
            return {
                recordLabels: '',
                hashtags: '',
                typedValueResultFormat: (res) => {
                    let json = JSON.parse(res.config.data)
                    let response = res.data.data
                    return {
                        totalRow: res.data.meta.total,
                        list: response
                    }
                },
                renderHashtagsRow: (row) => {
                    let result = '';
                    if (row && Object.keys(row).length)
                        result = `#${row.name}`;
                    return result;
                },
            }
        },
        methods: {
            changeHashtags(hashtags) {
                let route = {...this.$route};
                route.query.tags = hashtags;
                this.goTo(route)
            },
            changeRecordLabels(recordLabels) {
                let route = {...this.$route};
                route.query.recordlabels = recordLabels;
                this.goTo(route)
            },
            goTo(route) {
                let url = '';
                let queryUrlArr = [];
                Object.keys(route.query).map((k) => {
                    if(route.query[k].length) {
                        queryUrlArr.push(k + '=' + route.query[k]);
                    }
                });
                if(queryUrlArr.length) {
                    url = '?' + queryUrlArr.join('&');
                }
                this.$router.push(route.path);
                this.$router.push(route.path + url);
            },
            init() {
                let q = this.$route.query;
                if (q.recordlabels != undefined) {
                    this.recordLabels = q.recordlabels;
                }
                if (q.tags != undefined) {
                    this.hashtags = q.tags;
                }
                bus.$on('ajaxComplete', (name, data) => {
                    switch (name) {
                        case 'recordLabels':
                            // if(q.recordlabels != undefined) {
                            //     this.$refs.recordLabels.selectItem(data.data[0]);
                            // }
                            break;
                        case 'tags':
                            // if(q.tags != undefined) {
                            //     this.$refs.hashtags.selectItem(data.data[0]);
                            // }
                            break;
                    }
                });

            }
        },
        watch: {
            hashtags(hashtags) {
                this.changeHashtags(hashtags)
            },
            recordLabels(recordLabels) {
                this.changeRecordLabels(recordLabels)
            }
        },
        computed: {},
        mounted() {
            this.init()
        }
    }
</script>