<template v-if="isMe">
    <div class="widget padding-x">
        <div v-if="images.length" class="uk-animation-fade uk-animation-10">
            <div class="widget-title">
                <h4 class="f-s-13"><i class="fab fa-instagram"></i> {{options.title | lang}}</h4>
            </div>
            <div class="widget-content user-image-gallery-widget k-w-pe">
                <div class="uk-grid-4" uk-grid>
                    <div v-for="(image, index) in images" v-bind:key="index" class="uk-width-1-4">
                        <img @click="showImage(index+1)" :src="image.thumb"/>
                    </div>
                </div>
                <div class="no-photos-yet" v-if="isMe && !images.length && !showConnectInstagramBtn">
                    It seems you don’t have any photos yet.
                </div>
            </div>
        </div>
        <span class="uk-hidden" id="openGalleryImportant" @click="showImage(0)"></span>
        <LightBox id="imageGallery" :disableScroll="true" ref="lightbox" :startAt="0" :showLightBox="false"
                  :images="allImages"></LightBox>
        <div class="connect-to-instagram-brn-container" v-if="showConnectInstagramBtn">
            <div @click="connectInstagram()" class="login-with-social instagram">
                <span class="ico">
                    <i class="fab fa-instagram"></i>
                </span>
                <span class="f-s-085">
                    Connect With Instagram
                </span>
            </div>
        </div>
    </div>
</template>
<script>
    import LightBox from 'vue-image-lightbox'
    import helper from '../../helpers/helper'

    require('vue-image-lightbox/dist/vue-image-lightbox.min.css')
    export default {
        data() {
            return {
                images: [],
                allImages: [],
                startAt: 0,
                photos: [],
                show: false,
                isMe: false,
                showConnectInstagramBtn: false
            }
        },
        props: ['options', 'value', 'auth'],
        components: {
            LightBox
        },
        watch: {
            '$route': function (newVal, oldVal) {
                this.showConnectInstagramBtn = false
                this.photos = []
                this.images = []
            }
        },
        created() {
            this.images = []
            let vm = this
        },
        methods: {
            onChange() {
                if(this.value===null) return;
                let newVal = this.value
                this.showConnectInstagramBtn = false
                this.isMe = false
                this.images = []
                this.allImages = []
                if (newVal.id != undefined) {
                    if (newVal.id === this.auth.id) {
                        this.isMe = true
                        if (this.auth.connectedSocials === undefined) {
                            this.showConnectInstagramBtn = true
                        } else {
                            let hasInsta = false
                            for (let i = 0; i < this.auth.connectedSocials.length; i++) {
                                if (this.auth.connectedSocials[i].provider === 'instagram') {
                                    hasInsta = true
                                    break
                                }
                            }
                            this.showConnectInstagramBtn = !hasInsta
                        }
                    }
                }
                if (this.photos != undefined && this.photos.all != undefined) {
                    this.allImages.push({
                        thumb: this.$options.filters.attach(this.photos.avatar, 'xs'),
                        src: this.$options.filters.attach(this.photos.avatar, 'l'),
                    })
                    for (var i = 0; i < this.photos.all.length; i++) {
                        this.allImages.push({
                            thumb: this.$options.filters.attach(this.photos.all[i], 'xs'),
                            src: this.$options.filters.attach(this.photos.all[i], 'l'),
                        })
                        if (this.photos.all[i].uri === this.photos.avatar.uri && this.photos.all.length === 1) continue
                        this.images.push({
                            thumb: this.$options.filters.attach(this.photos.all[i], 'xs'),
                            src: this.$options.filters.attach(this.photos.all[i], 'l'),
                        })
                    }
                }
            },
            showImage(index) {
                this.$refs.lightbox.showImage(index)
            },
            connectInstagram() {
                bus.$emit('socialConnect', 'instagram')
            }
        },
        mounted() {
            bus.$on('me', (res) => {
                if (this.value === null) return
                if (res.id === this.value.id) {
                    this.photos = res.photos
                    this.auth = res
                    this.onChange()
                }
            })
            bus.$on('gphotos', (data) => {
                this.photos = data
                this.onChange()
            })
        },
        beforeDestroy() {
        }
    }
</script>
