<template>
  <transition name="modal" v-if="value">
    <div class="modal-mask" @click="doClose">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div @click="close" class="close">
            <i class="fal fa-times modal-close-icon"></i>
          </div>
          <div class="modal-header">
            <slot name="header"> default header </slot>
          </div>

          <div class="modal-body">
            <slot name="body"> default body </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              default footer
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value: function (newVal) {
      if (newVal) {
        document.body.setAttribute("data-modal", "1");
      } else {
        document.body.removeAttribute("data-modal");
      }
    },
  },
  methods: {
    doClose(e) {
      if (e.target.className === "modal-wrapper") {
        //this.close()
      }
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>