var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "notifications" }, [
    _vm.header
      ? _c("div", { staticClass: "header f-s-080" }, [
          _c("div", [_vm._v("\n            Notifications\n        ")]),
          _vm._v(" "),
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  return _vm.close()
                },
              },
            },
            [
              _c("router-link", { attrs: { to: "/settings/notifications" } }, [
                _vm._v("Settings"),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "contents" }, [
      _vm.end && !_vm.notifications.length
        ? _c("div", { staticClass: "empty" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "f-s-075 uk-margin-small-top" }, [
              _vm._v(
                "\n                You didn't receive any notifications yet.\n            "
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          {
            staticClass: "scrollbar uk-nav uk-dropdown-nav",
            style: {
              maxHeight:
                _vm.maxHeight === "auto" ? "inherit" : _vm.maxHeight + "px",
              height: _vm.maxHeight === "auto" ? "auto" : _vm.maxHeight + "px",
            },
          },
          [
            _vm._l(_vm.notifications, function (notification, index) {
              return _c("li", { staticClass: "notification" }, [
                _c(
                  "div",
                  {
                    staticClass: "uk-grid-10 uk-flex uk-flex-middle",
                    attrs: { "uk-grid": "" },
                  },
                  [
                    _c("div", { staticClass: "uk-width-auto" }, [
                      _c("div", { staticClass: "uk-position-relative" }, [
                        notification.isNew
                          ? _c("span", { staticClass: "is-new" }, [
                              _c("i", { staticClass: "fas fa-circle" }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("img", {
                          staticClass: "profile-image",
                          attrs: { src: notification.photos.user.uri },
                          on: {
                            click: function ($event) {
                              return _vm.goTo(notification.photos.user)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-expand" }, [
                      _c(
                        "div",
                        { staticClass: "description f-s-085" },
                        [
                          _vm._l(
                            notification.description,
                            function (description, index) {
                              return _c(
                                "span",
                                {
                                  class: {
                                    "is-dot": description.text === ".",
                                    "is-link": description.id != null,
                                    "is-bold": description.bold,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.goTo(description)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      index != 0 && description.text != "."
                                        ? " "
                                        : ""
                                    ) + _vm._s(description.text)
                                  ),
                                ]
                              )
                            }
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "timeago f-s-075" }, [
                            _vm._v(_vm._s(notification.createdAtAgo)),
                          ]),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    notification.photos.post != undefined &&
                    notification.photos.post.uri != undefined
                      ? _c("div", { staticClass: "uk-width-auto" }, [
                          _c("img", {
                            attrs: { src: notification.photos.post.uri },
                            on: {
                              click: function ($event) {
                                return _vm.goTo(notification.photos.post)
                              },
                            },
                          }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    notification.type === "TEAMMATE_REQUEST"
                      ? _c("div", { staticClass: "uk-width-auto" }, [
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.teammateAccept(notification)
                                },
                              },
                            },
                            [_vm._v("ACCEPT")]
                          ),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.teammateReject(notification)
                                },
                              },
                            },
                            [_vm._v("REJECT")]
                          ),
                        ])
                      : _vm._e(),
                  ]
                ),
              ])
            }),
            _vm._v(" "),
            !_vm.end
              ? _c("div", { staticClass: "loading" }, [
                  _c("i", {
                    directives: [
                      {
                        name: "is-visibility",
                        rawName: "v-is-visibility",
                        value: (isVisible) => _vm.loadingVisible(isVisible),
                        expression: "(isVisible) => loadingVisible(isVisible)",
                      },
                    ],
                    attrs: { "uk-spinner": "ratio: 1" },
                  }),
                ])
              : _vm._e(),
          ],
          2
        ),
      ]),
    ]),
    _vm._v(" "),
    !_vm.end && _vm.maxHeight != "auto"
      ? _c("div", { staticClass: "notifications-footer f-s-080" }, [
          _c(
            "div",
            { on: { click: _vm.close } },
            [
              _c("router-link", { attrs: { to: "/notifications" } }, [
                _vm._v("\n                Show All\n            "),
              ]),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { staticClass: "fal fa-bell-slash f-s-25" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }