<template>
    <div ref="set" :id="'set' + item.id" class="v-post-med-item set">
        <div class="uk-flex uk-flex-middle uk-grid-20" uk-grid>
            <div class="uk-width-auto">
                <div class="image" @click="$parent.play(item)">

                    <!--                    <img :src="item.metas.cover | attach('s')"/>-->
                    <ImagePlaceholder :set="true" width="80px" height="0" radius="3px"
                                      :data-src="item.metas.cover | attach('s')"/>
                    <div class="v-post-med-item-actions">
                        <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='play'"
                           class="fas fa-grip-lines-vertical"></i>
                        <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='wait'"
                           uk-spinner></i>
                        <i v-if="currentTrack===null || currentTrack!==item.realId || (currentTrack===item.realId && playerStatus==='pause')"
                           class="fas fa-play"></i>
                    </div>
                </div>
            </div>
            <div class="uk-width-expand main-col">
                <div class="v-post-item-title f-s-090">
                    <router-link class="display-flex" :to="helper.permalink(item)">
                        <span class="flex-truncate">
                            {{item.title}}
                        </span>
                    </router-link>
                </div>
                <div class="artists uk-flex-middle f-s-085">
                    <div>
                        <router-link class="display-flex" :to="'/' + item.user.username">
                            <img :src="item.user.photos.avatar | attach('xs')"/>
                        </router-link>
                    </div>
                    <div class="names display-flex flex-truncate">
                        <router-link :to="'/' + item.user.username">{{item.user.username}}</router-link>
                        <span v-for="teammate in item.users">, <router-link
                                :to="'/' + teammate.username">{{teammate.username}}</router-link></span>
                    </div>
                </div>
                <div class="counters f-s-080">
                    <div class="counter">
                        <i class="fas fa-clock"></i> {{item.tracksDuration | minutes}}
                    </div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="last-col">
                    <div class="genre-and-time f-s-075">
                        <div v-if="item.genres!=undefined && item.genres.length">
                            <router-link class="display-flex" :to="'/genre/' + item.genres[0].id">
                                {{item.genres[0].name}}
                            </router-link>
                        </div>
                        <div>
                            {{item.releaseDateTimeago}}
                        </div>
                    </div>
                    <!--                    <div class="actions">-->
                    <!--                        <span class="v-post-item-heart disable-click f-s-1" @click="$parent.like(item)">-->
                    <!--                            <i class="far fa-heart disable-click"-->
                    <!--                               :class="{'far' : !item.is.liked, 'fas active': item.is.liked}"></i>-->
                    <!--                        </span>-->
                    <!--                        <span class="v-post-item-retweet disable-click f-s-1" @click="$parent.repost(item)">-->
                    <!--                            <i class="far fa-retweet disable-click"-->
                    <!--                               :class="{'active active-gold': item.is.reposted}"></i>-->
                    <!--                        </span>-->
                    <!--                    </div>-->
                    <div>
                        <span @click="$parent.moreMenu(item, $event)"
                              class="v-post-item-ellipsis disable-click f-s-2 disable-click more-menu-parent">
                            <i class="fal fa-ellipsis-v disable-click"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="track-list" ref="trackList" :class="{all: showAll}">
            <VTrackItem v-for="(track, index) in item.tracks" :track="track" :key="track.realId"
                        v-if="showAll || index < 3"/>
            <button @click="showAllToggle" class="show-all-btn" v-if="item.tracks.length>2">
                <i class="fa fa-angle-down"></i>
                <span>{{showAll ? 'Show Less' : 'Show More...'}}</span>
            </button>
        </div>
    </div>
</template>
<script>
    import helper from '../../helpers/helper'
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import VTrackItem from "@/components/Items/VTrackItem";

    export default {
        data() {
            return {
                helper: helper,
                showAll: false
            }
        },
        components: {
            ImagePlaceholder, VTrackItem
        },
        props: {
            item: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            index: {
                type: Number,
                default: 0
            }
        },
        methods: {
            play(track) {
                this.$parent.play(track, this.item.tracks)
            },
            showAllToggle() {
                this.showAll = !this.showAll
            }
        },
        watch: {
            showAll(showAll) {
                if (showAll) {
                    bus.$emit('activeShowMoreSet', this.item.realId)
                }
                let elementTransition = 'height 0.2s cubic-bezier(0, 1, 0, 1)'
                let sHeight = (showAll ? this.fullHeight : 100)
                let fHeight = (showAll ? 100 : this.fullHeight)
                requestAnimationFrame(() => {
                    this.$refs.trackList.style.height = fHeight + 'px';
                    this.$refs.trackList.style.transition = elementTransition;
                    requestAnimationFrame(() => {
                        this.$refs.trackList.style.height = sHeight + 'px';
                    });
                });
            }
        }
        ,
        computed: {
            fullHeight() {
                try {
                    return (this.item.tracks.length * 44)
                } catch (e) {
                    return 0
                }
            }
            ,
            currentTrack() {
                return (this.$store.getters.getCurrent!=null && this.$store.getters.getCurrent.realId != undefined) ? this.$store.getters.getCurrent.realId : null
            }
            ,
            playerStatus() {
                return this.$store.getters.playerStatus
            }
        }
        ,
        mounted() {
            bus.$on('activeShowMoreSet', (id) => {
                if (this.showAll && id !== this.item.realId) {
                    this.showAll = false
                }
            })
        }
    }
</script>