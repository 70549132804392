var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TabbedModal",
    {
      attrs: { tabs: _vm.tabs, show: _vm.show },
      on: { setTab: _vm.setTab, close: _vm.close },
    },
    [
      _vm.data.likes != undefined
        ? _c("div", { attrs: { slot: "title0" }, slot: "title0" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("numformat")(_vm.data.likes)) +
                " Likes\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.data.reposts != undefined
        ? _c("div", { attrs: { slot: "title1" }, slot: "title1" }, [
            _vm._v(
              "\n        " +
                _vm._s(_vm._f("numformat")(_vm.data.reposts)) +
                " Reposts\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-h-100", attrs: { slot: "body0" }, slot: "body0" },
        [
          _c(
            "div",
            {
              ref: "likesRef",
              staticClass: "flex-h-100 uk-animation-fade flex-direction-column",
              attrs: { id: "container-of-likes" },
            },
            [
              _vm.likes.items.length
                ? _c("Items", {
                    attrs: { type: "VUserItem", items: _vm.likes.items },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.likes.hasMore && this.current === 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "is-visibility",
                          rawName: "v-is-visibility",
                          value: _vm.visibleLikes,
                          expression: "visibleLikes",
                        },
                      ],
                      staticClass: "uk-text-center flex-1-1",
                    },
                    [_c("i", { attrs: { "uk-spinner": "" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.likes.hasMore && !_vm.likes.items.length
                ? _c("div", { staticClass: "no-likes-parent" }, [
                    _c("div", { staticClass: "no-likes" }, [
                      _c("div", [
                        _c("i", { staticClass: "fal fa-heart-broken" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "f-s-080" }, [
                          _vm._v(
                            "\n                            No one has liked this post yet.\n                        "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-1-1", attrs: { slot: "body1" }, slot: "body1" },
        [
          _c(
            "div",
            {
              ref: "repostsRef",
              staticClass: "flex-h-100 uk-animation-fade flex-direction-column",
              attrs: { id: "container-of-reposts" },
            },
            [
              _vm.reposts.items.length
                ? _c("Items", {
                    attrs: { type: "VUserItem", items: _vm.reposts.items },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.reposts.hasMore && this.current === 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "is-visibility",
                          rawName: "v-is-visibility",
                          value: _vm.visibleReposts,
                          expression: "visibleReposts",
                        },
                      ],
                      staticClass: "uk-text-center flex-1-1",
                    },
                    [_c("i", { attrs: { "uk-spinner": "" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.reposts.hasMore && !_vm.reposts.items.length
                ? _c("div", { staticClass: "no-likes-parent" }, [
                    _c("div", { staticClass: "no-likes" }, [
                      _c("div", [
                        _c("i", { staticClass: "fal fa-retweet" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "f-s-080" }, [
                          _vm._v(
                            "\n                            No one has reposted this post yet.\n                        "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }