<template>
    <div class="v-track-item" :class="{active: currentTrack==track.realId}">
        <div class="uk-grid uk-grid-10 uk-flex uk-flex-middle">
            <div @click="player" class="uk-width-auto">
                <div class="v-track-item-image">
                    <image-placeholder width="30px" radius="3px"
                                       :data-src="track.metas.cover | attach('xs')"></image-placeholder>
                    <div v-if="inited" class="v-track-item-player-actions">
                        <i v-if="currentTrack!=null && currentTrack==track.realId && playerStatus==='play'"
                           class="fas fa-grip-lines-vertical"></i>
                        <i v-if="currentTrack!=null && currentTrack==track.realId && playerStatus==='wait'"
                           uk-spinner></i>
                        <i v-if="currentTrack===null || currentTrack!==track.realId || (currentTrack===track.realId && playerStatus==='pause')"
                           class="fas fa-play"></i>
                    </div>
                </div>
            </div>
            <div @click="player" class="uk-width-expand">
                <div class="information">
                    <div class="title f-f-reg f-s-085">{{track.title}}</div>
                </div>
            </div>
            <div class="uk-width-auto">
                <div class="v-track-item-actions uk-flex uk-flex-middle">
                    <span class="v-post-item-heart disable-click f-s-090" @click="$parent.$parent.like(track)">
                        <i class="far fa-heart disable-click"
                           :class="{'far' : !track.is.liked, 'fas active': track.is.liked}"></i>
                    </span>
                    <span class="v-post-item-retweet disable-click f-s-090" @click="$parent.$parent.repost(track)">
                        <i class="far fa-retweet disable-click"
                           :class="{'active active-gold': track.is.reposted}"></i>
                    </span>
                    <span class="duration f-f-reg f-s-075">
                        {{track.metas.attachment.duration | minutes}}
                    </span>
                    <span @click="$parent.$parent.moreMenu(track, $event)"
                          class="v-post-item-ellipsis disable-click f-s-15 disable-click more-menu-parent">
                        <i class="fal fa-ellipsis-h disable-click"></i>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";

    export default {
        components: {
            ImagePlaceholder
        },
        data() {
            return {
                inited: false
            }
        },
        props: {
            track: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        methods: {
            player() {
                this.$parent.play(this.track)
            },
        },
        computed: {
            currentTrack() {
                return (this.$store.getters.getCurrent!=null && this.$store.getters.getCurrent.realId != undefined) ? this.$store.getters.getCurrent.realId : null
            },
            playerStatus() {
                return this.$store.getters.playerStatus
            }
        },
        mounted() {
            setTimeout(() => {
                this.inited = true
            }, (Math.random()*1000))
        }
    }
</script>