var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length !== 0
    ? _c("div", { staticClass: "widget padding-x" }, [
        _c("div", { staticClass: "widget-title" }, [
          _c("h4", { staticClass: "f-s-1" }, [
            _c("i", { staticClass: "fal fa-heart" }),
            _vm._v(" " + _vm._s(_vm._f("lang")(_vm.options.title))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content" }, [
          _c(
            "div",
            { staticClass: "v-post-items uk-animation-fade uk-animation-10" },
            [_c("Items", { attrs: { items: _vm.items, type: "VPostItem" } })],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }