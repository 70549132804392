var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget padding-x" }, [
    _c("div", { staticClass: "widget-content" }, [
      _c(
        "div",
        [
          _c("Items", { attrs: { items: _vm.items, type: "VPostMedItem" } }),
          _vm._v(" "),
          !_vm.end
            ? _c("div", { staticClass: "uk-margin-top uk-text-center" }, [
                _c("i", {
                  directives: [
                    {
                      name: "is-visibility",
                      rawName: "v-is-visibility",
                      value: (isVisible) => _vm.visibleMore(isVisible),
                      expression: "(isVisible) => visibleMore(isVisible)",
                    },
                  ],
                  attrs: { "uk-spinner": "" },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.end && !_vm.items.length ? _c("Empty") : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }