var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-post-med-item video" }, [
    _c(
      "div",
      {
        staticClass: "uk-flex uk-flex-middle uk-grid-20",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-expand main-col" }, [
          _c(
            "div",
            { staticClass: "artists uk-flex-middle f-s-085 flex-truncate" },
            [
              _c(
                "div",
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "display-flex",
                      attrs: { to: "/" + _vm.item.user.username },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm._f("attach")(
                            _vm.item.user.photos.avatar,
                            "xs"
                          ),
                        },
                      }),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "title-and-sub" }, [
                _c(
                  "div",
                  { staticClass: "title f-s-090" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "display-flex video-title",
                        attrs: { to: _vm.helper.permalink(_vm.item) },
                      },
                      [
                        _c("span", { staticClass: "flex-truncate" }, [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.item.title) +
                              "\n                            "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "names display-flex flex-truncate f-s-080" },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: "/" + _vm.item.user.username } },
                      [_vm._v(_vm._s(_vm.item.user.username))]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.item.users, function (teammate) {
                      return _c(
                        "span",
                        { key: teammate.id },
                        [
                          _vm._v(", "),
                          _c(
                            "router-link",
                            { attrs: { to: "/" + teammate.username } },
                            [_vm._v(_vm._s(teammate.username))]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "last-col" }, [
            _c("div", { staticClass: "genre-and-time f-s-075" }, [
              _vm.item.genres != undefined && _vm.item.genres.length
                ? _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "display-flex",
                          attrs: { to: "/genre/" + _vm.item.genres[0].id },
                        },
                        [_vm._v(_vm._s(_vm.item.genres[0].name))]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.item.releaseDateTimeago) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "actions" }, [
              _c(
                "span",
                {
                  staticClass: "v-post-item-heart disable-click f-s-1",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.like(_vm.item)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-heart disable-click",
                    class: {
                      far: !_vm.item.is.liked,
                      "fas active": _vm.item.is.liked,
                    },
                  }),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "v-post-item-retweet disable-click f-s-1",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.repost(_vm.item)
                    },
                  },
                },
                [
                  _c("i", {
                    staticClass: "far fa-retweet disable-click",
                    class: { "active active-gold": _vm.item.is.reposted },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "span",
                {
                  staticClass:
                    "v-post-item-ellipsis disable-click f-s-2 disable-click more-menu-parent",
                  on: {
                    click: function ($event) {
                      return _vm.$parent.moreMenu(_vm.item, $event)
                    },
                  },
                },
                [_c("i", { staticClass: "fal fa-ellipsis-v disable-click" })]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "screen-shots" }, [
      _c("div", { staticClass: "uk-grid-10", attrs: { "uk-grid": "" } }, [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c(
            "div",
            { staticClass: "image" },
            [
              _c(
                "router-link",
                { attrs: { to: _vm.helper.permalink(_vm.item) } },
                [
                  _c("img", {
                    attrs: { src: _vm._f("attach")(_vm.item.metas.cover, "s") },
                  }),
                ]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "uk-width-expand" },
          [
            _vm.screenshots.length
              ? _c("ScreenshotsSlider", {
                  staticClass: "screenshots-con",
                  attrs: {
                    items: _vm.screenshots,
                    localkey: "screenshots" + _vm.item.realId,
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "counters f-s-075" }, [
      _c("div", { staticClass: "counter" }, [
        _c("i", { staticClass: "fas fa-heart" }),
        _vm._v(
          " " + _vm._s(_vm._f("numformat")(_vm.item.stats.likes)) + "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "counter" }, [
        _c("i", { staticClass: "fas fa-comment" }),
        _vm._v(
          " " +
            _vm._s(_vm._f("numformat")(_vm.item.stats.comments)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "counter" }, [
        _c("i", { staticClass: "fas fa-retweet" }),
        _vm._v(
          " " +
            _vm._s(_vm._f("numformat")(_vm.item.stats.reposts)) +
            "\n        "
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "counter" }, [
        _c("i", { staticClass: "fas fa-eye" }),
        _vm._v(
          " " + _vm._s(_vm._f("numformat")(_vm.item.stats.views)) + "\n        "
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }