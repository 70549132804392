// import * as OfflinePluginRuntime from 'offline-plugin/runtime';
// OfflinePluginRuntime.install();

const l = window.location.href.split('/stk/')
if(l.length > 1) {
    localStorage.removeItem('user')
    localStorage.setItem('user-token', l[1])
    window.location.href = '/'
}

import Vue from 'vue'
import axios from 'axios'

import App from './App'
import MoreMenuPopup from './components/MoreMenuPopup'
import Upload from './components/Upload'
import router from './router'
import store from './store'
import Modal from '@/components/Modal'
import PromotedUsers from '@/components/Widgets/PromotedUsers'
import VueIziToast from 'vue-izitoast'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import anchorme from "anchorme"
import VueLazyLoad from 'vue-lazyload'
import {ObserveVisibility} from 'vue-observe-visibility'
import PrettyCheckbox from 'pretty-checkbox-vue';
import underscore from 'vue-underscore'
import Toast from '@/components/Toast'
import vSelectPage from 'v-selectpage'
import vSelect from 'vue-select'
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';
import SmoothPicker from 'vue-smooth-picker'
import Ads from 'vue-google-adsense'
import Vuebar from 'vuebar';
import { Flicking } from "@egjs/vue-flicking";







import 'vue-smooth-picker/dist/css/style.css'
import 'vue-select/dist/vue-select.css'

import UIkit from 'uikit';
import './assets/js/uikit-icons.js'
import './assets/css/uikit.css'
import 'izitoast/dist/css/iziToast.css'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import './assets/css/app.css'
import './assets/css/fa.min.css'
import 'vue2-animate/dist/vue2-animate.min.css'
import * as d3 from "d3";
import En from '../langs/en.js'
import API from './api/index.js'

window.UIkit = UIkit
window.API = API
window.lang = En
window.d3 = d3

Vue.config.productionTip = true
Vue.config.silent = true
Vue.filter('minutes', (value) => {
    try {
        value = parseInt(value)
        if (!value || typeof value !== "number") return "00:00"
        let min = parseInt(value / 60),
            sec = parseInt(value % 60)
        min = min < 10 ? "0" + min : min
        sec = sec < 10 ? "0" + sec : sec
        value = min + ":" + sec
        return value
    } catch(e) {
        // console.log('err in minutes')
        return "00:00"
    }
})

Vue.filter('lang', function (value, replaces, br) {
    if(window.langx === undefined && store.getters.lango !== null) {
    }
    const f = (store.getters.lango && store.getters.lango[value]) || null
    if(f === null) {
        if(store.getters.lango && store.getters.lango[value] && store.getters.lango[value] !== undefined) {
            return ''
        }
        return value
    }
    value = f
    if (replaces !== undefined && replaces !== null) {
        Object.keys(replaces).forEach(function (k) {
            value = value.replace('{' + k + '}', replaces[k])
        })
    }
    if(br !== undefined && br) {
        if (typeof value === 'undefined' || value === null) {
            return '';
        }
        var breakTag = (typeof is_xhtml === 'undefined') ? '<br />' : '<br>';
        return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    }
    return value
})
Vue.filter('ucfirst', function (value) {
    if (!value) return ''
    value = value.toString()
    return value.charAt(0).toUpperCase() + value.slice(1)
})
Vue.filter('br', function (value, is_xhtml) {
    is_xhtml = is_xhtml || true
    if (typeof value === 'undefined' || value === null) {
        return '';
    }
    var breakTag = is_xhtml ? '<br />' : '<br>';
    return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
})
const symbols = [
    {value: 1, symbol: ''},
    {value: 1e3, symbol: 'k'},
    {value: 1e6, symbol: 'M'},
    {value: 1e9, symbol: 'G'},
    {value: 1e12, symbol: 'T'},
    {value: 1e15, symbol: 'P'},
    {value: 1e18, symbol: 'E'}
]
const postTypes = {
    SND: 'Sound',
    VID: 'Video',
    ALB: 'Album',
    PLY: 'Playlist'
}
Vue.filter('postType', (ptSlug) => {
    return postTypes[ptSlug]
})
Vue.filter('numberFormat', (num, digits) => {
    if (digits === undefined) digits = 1
    const numToCheck = Math.abs(num);
    for (let i = symbols.length - 1; i >= 0; i--) {
        if (numToCheck >= symbols[i].value) {
            const newNumber = ((num / symbols[i].value).toFixed(digits)).replace('.0', '').replace('.0', '');
            return `${newNumber}${symbols[i].symbol}`;
        }
    }
    return '0';
})
Vue.filter('numformat', function (num) {
    try {
        return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    } catch(e) {
        return num
    }
})
Vue.filter('postType', function (postType, toLower) {
    let pt = postTypes[postType]
    if(pt===undefined) return ''
    if (toLower !== undefined && toLower === true) {
        pt = pt.toLowerCase()
    }
    return pt
})
Vue.filter('img', function (url, size, disableCache) {
    if (url.indexOf('.jpg') !== -1) {
        return API.base + url
    }
    url = url + '_' + size + '.jpg' + (disableCache !== undefined ? '?rand=' + Math.random() : '')
    return url
})
var imagesSize = null
Vue.filter('attach', function (attach, size, type) {
    try {
        if(attach===undefined) return ''
        type = type || 'default'
        imagesSize = imagesSize || store.getters.settings.imagesSize
        let sz = imagesSize[type][size] || {w:'blur'}
        let url = attach.uri.replace('{w}', sz.w)
        return url
    } catch(e) {
        return ''
    }
})
Vue.filter('screenshot', function (attachment, index, size) {
    return ''
    // imagesSize = imagesSize || store.getters.settings.imagesSize
    // let sz = imagesSize['screenshot'][size]
    // return attachment.screenshotUri.replace('{scidx}', index).replace('{w}', sz.w)
})
Vue.filter('chat', function (message) {
    if (message === undefined || message === null) return ''
    return anchorme(message, {
        attributes: [
            {
                name: "class",
                value: "chat-link"
            },
            function (urlObj) {
                if (urlObj.reason === "ip") return {name: "class", value: "ip-link"};
            },
            function (urlObj) {
                if (urlObj.protocol !== "mailto:") return {name: "target", value: "blank"};
            }
        ]
    })
})
Vue.filter('link', function (message) {
    if (message === undefined || message === null) return ''
    let tmp = anchorme(message, {
        attributes: [
            {
                name: "class",
                value: "text-link"
            },
            {
                name: "rel",
                value: "nofollow"
            },
            function (urlObj) {
                if (urlObj.reason === "ip") return {name: "class", value: "ip-link"};
            },
            function (urlObj) {
                if (urlObj.protocol !== "mailto:") return {name: "target", value: "blank"};
            }
        ]
    })
    let checkItems = [
        {
            char: '@',
            prefix: '/',
            ex: '#',
            class: 'at-link'
        },
        {
            char: '#',
            prefix: '/explore/sounds/?tags=',
            ex: '@',
            class: 'hashtag-link'
        }
    ];
    let lines = tmp.split('<br />')
    if(lines.length < 2) {
        lines = lines.join('<br />').split('\n')
    }
    for(let x = 0; x<checkItems.length; x++) {
        let ci = checkItems[x];
        for(let i = 0; i<lines.length; i++) {
            let tmpLine = ' ' + lines[i].replace(/\n/g, '')
            let ex = tmpLine.split(` ${ci.char}`)
            if(ex.length > 0) {
                for(let o = 1; o<ex.length; o++) {
                    if(tmpLine.indexOf(` ${ci.char}` + ex[o])>-1) {
                        let ex2 = ex[o].split(' ')
                        if(ci.ex === undefined || ex2[0].indexOf(ci.ex) === -1) {
                            lines[i] = ` ${lines[i].replace('\n', '')}`.replace(` ${ci.char}` + ex2[0], ` <a target="_blank" class="${ci.class}" href="${ci.prefix}${ex2[0]}">${ci.char}${ex2[0]}</a>`)
                        }
                    }
                }
            }
            // tmpLines.push(lines[i]);
        }
        // lines = tmpLines;
    }
    return lines.join('<br />');
})
Vue.filter('numbers', (value) => {
    let number = value + 1
    if (number < 10) {
        return "0" + number + "."
    }
    return number + "."
})


Vue.component('flicking', Flicking)
Vue.component('modal', Modal)
Vue.component('v-select', vSelect)
Vue.use(Vuebar);
Vue.use(VueIziToast)
Vue.use(VueLazyLoad)
Vue.use(PerfectScrollbar)
Vue.use(underscore)
Vue.directive('is-visibility', ObserveVisibility)
Vue.use(PrettyCheckbox)
// Vue.component('Flicking', require("@egjs/vue-flicking").default)
Vue.component('VueCtkDateTimePicker', VueCtkDateTimePicker);
Vue.use(SmoothPicker)
Vue.use(require('vue-script2'))
Vue.use(Ads.Adsense)
Vue.use(Ads.InArticleAdsense)
Vue.use(Ads.InFeedAdsense)

Vue.use(vSelectPage, {
    // server side data loader
    dataLoad: function (vue, data, params) {
        let url = 'search'
        return new Promise((resolve, reject) => {
            if (window['search' + params.type] != null)
                window['search' + params.type].cancel()
            let CancelToken = axios.CancelToken
            window['search' + params.type] = CancelToken.source()
            axios.post(url, params, {
                cancelToken: window['search' + params.type].token,
                headers: {
                    Authorization: localStorage.getItem('user-token')
                }
            }).then(resp => {
                bus.$emit('ajaxComplete', params.type, resp.data)
                resolve(resp)
            })
                .catch(resp => reject(resp))
        })
    }
})


window.bus = new Vue()

/* eslint-disable no-new */




window.main = new Vue({
    data() {
        return {
            moreMenuPopup: {
                data: null,
                e: null,
            },
            darkMode: (localStorage.getItem('darkMode')===undefined ? true : (localStorage.getItem('darkMode')==='false' ? false : true))
        }
    },
    el: '#app',
    router,
    store,
    components: {App, PromotedUsers, MoreMenuPopup, Toast, Upload, 'v-selectpage': vSelectPage},
    template: `<div :class="className" id="app" ref="app"><Upload/><App/><MoreMenuPopup :data="moreMenuPopup"/><Toast/></div>`,
    methods: {
        showMoreMenu(data, e) {
            this.moreMenuPopup = {
                data: data,
                e: e
            }
        },
        darkModeToggle() {
            this.darkMode = !this.darkMode
            localStorage.setItem('darkMode', this.darkMode)
        },
        message_broadcast(message)
        {
            localStorage.setItem('message',JSON.stringify(message));
            localStorage.removeItem('message');
        },
        message_receive(ev)
        {
            if (ev.key!='message') return; // ignore other keys
            var message=JSON.parse(ev.newValue);
            if (!message) return; // ignore empty msg or msg reset
            if (message.command == 'pause') bus.$emit('pause');
        }
    },
    computed: {
        className() {
            return this.darkMode ? 'dark uk-light' : ''
        }
    },
    mounted() {
        window.addEventListener("storage", this.message_receive);
    }
})
bus.$on('darkModeToggle', () => {
    main.darkModeToggle()
})
bus.$on('clickMoreMenu', function (data, e) {
    main.showMoreMenu(data, e)
})

self.addEventListener('notificationclick', function(event) {
    // console.log('On notification click: ', event.notification.tag);
    // Android doesn't close the notification when you click on it
    // See: http://crbug.com/463146
    event.notification.close();

    // This looks to see if the current is already open and
    // focuses if it is
    event.waitUntil(
        clients.matchAll({
            type: "window"
        })
            .then(function(clientList) {
                for (var i = 0; i < clientList.length; i++) {
                    var client = clientList[i];
                    if (client.url == '/' && 'focus' in client)
                        return client.focus();
                }
                if (clients.openWindow) {
                    return clients.openWindow('/');
                }
            })
    );
});