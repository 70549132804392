<template>
  <div :class="mainClassName">
    <div class="site-main-layout" :class="{ hmbrgr: hmbrgr }">
      <div class="h100vh sidebar-container-flex">
        <div class="sidebar-container-parent">
          <Sidebar />
        </div>
      </div>

      <div id="site-main-col" class="site-main-col uk-overflow-hidden" :class="`r-${$route.name}`">
        <div class="top-menu-container">
          <div class="top-menu" :class="{ 'search-opened': showSearch }">
            <div uk-grid class="uk-grid-10 uk-flex uk-flex-middle">
              <div v-if="!showSearch" class="mobile-only">
                <div
                  id="hmbrgr"
                  @click="toggleHmbrgr()"
                  :class="{ open: hmbrgr }"
                >
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
              </div>
              <div v-if="!showSearch" class="mobile-only uk-width-expand">
                <router-link to="/">
                  <div class="just-logo"></div>
                </router-link>
              </div>
              <div class="top-menu-search uk-width-expand mobile-hidden">
                <i v-if="!searchLoading" class="fas fa-search"></i>
                <i v-if="searchLoading" uk-spinner="ratio: 0.65"></i>
                <input
                  class="f-s-090"
                  v-model="search"
                  ref="desktopSearch"
                  type="text"
                  v-on:focus="focusSearch(1)"
                  v-on:focusout="focusSearch(0)"
                  v-on:keyup="onKeyUpSearch"
                  :placeholder="'search_hint' | lang"
                />
              </div>
              <div class="mobile-only mobile-search" v-if="showSearch">
                <i v-if="!searchLoading" class="fas fa-search"></i>
                <i v-if="searchLoading" uk-spinner="ratio: 0.65"></i>
                <input
                  class="f-s-090"
                  ref="mobileSearchInput"
                  v-model="search"
                  type="text"
                  v-on:focus="focusSearch(1)"
                  v-on:focusout="focusSearch(0)"
                  v-on:keyup="onKeyUpSearch"
                  :placeholder="'search_hint' | lang"
                />
              </div>
              <div
                class="uk-width-auto uk-flex uk-flex-middle f-s-1"
                uk-height-match="target: .top-menu-item"
                v-if="isAuthenticated"
              >
                <router-link
                  class="top-menu-item mobile-hidden"
                  :to="{ name: 'upload' }"
                >
                  <i class="far fa-cloud-upload"></i>
                </router-link>
                <span @click="mobileSearch" class="top-menu-item mobile-only">
                  <i class="far fa-search"></i>
                </span>
                <button
                  class="
                    top-menu-item
                    uk-flex uk-flex-middle
                    btn-transparent
                    f-s-1
                  "
                >
                  <i class="far fa-bell"></i>
                  <span class="has-new-notification" v-if="hasNewNotification"
                    ><i class="fas fa-circle"></i
                  ></span>
                </button>
                <div
                  ref="notificationsDropdown"
                  id="notificationsDropdown"
                  class="notification-dropdown uk-width-large"
                  uk-dropdown="pos: bottom-right; mode: click; animation: uk-animation-slide-bottom-medium; duration: 150; toggle: - *; delay-hide: 0"
                >
                  <NotificationsDropdown
                    v-if="isProfileLoaded"
                    @setBadge="
                      (cnt) => {
                        this.hasNewNotification = !!cnt;
                      }
                    "
                    @close="closeNotificationsDropdown"
                  />
                </div>
                <router-link class="top-menu-item mobile-hidden" to="/chat">
                  <i class="far fa-envelope"></i>
                </router-link>
                <button
                  class="
                    top-menu-item
                    f-s-080
                    uk-flex uk-flex-middle
                    btn-transparent
                  "
                >
                  <i class="far fa-ellipsis-h"></i>
                </button>
                <div
                  ref="moreDropDown"
                  id="moreDropDown"
                  uk-dropdown="pos: bottom-right; mode: click; animation: uk-animation-slide-bottom-medium; duration: 150; toggle: - *; delay-hide: 0"
                >
                  <ul @click="closeUserMenu" class="uk-nav uk-dropdown-nav">
                    <li class="uk-hidden@m">
                      <router-link :to="'/chat'"
                        ><i class="far fa-envelope"></i> Messages</router-link
                      >
                    </li>
                    <li class="uk-hidden@m">
                      <router-link to="/upload"
                        ><i class="far fa-cloud-upload"></i> Upload</router-link
                      >
                    </li>
                    <li class="uk-nav-divider uk-hidden@m"></li>
                    <li>
                      <router-link to="/terms"
                        ><i class="fal fa-file-alt"></i> Terms</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/privacy"
                        ><i class="fal fa-shield-check"></i>
                        Privacy</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/about-us"
                        ><i class="fal fa-users"></i> About Us</router-link
                      >
                    </li>
                    <li>
                      <router-link to="/contact-us"
                        ><i class="fal fa-envelope-square"></i> Contact
                        Us</router-link
                      >
                    </li>
                  </ul>
                </div>
                <span v-if="!isProfileLoaded">
                  <div uk-spinner></div>
                </span>
                <button
                  class="
                    top-menu-item
                    f-s-080
                    uk-flex uk-flex-middle
                    btn-transparent
                    avatar-menu
                  "
                  v-if="isProfileLoaded"
                >
                  <span class="user-avatar">
                    <image-placeholder
                      :width="35"
                      :height="35"
                      v-if="getProfile.photos.avatar != null"
                      :dataSrc="getProfile.photos.avatar | attach('s')"
                      radius="50%"
                    />
                  </span>
                  <i class="fas fa-chevron-down"></i>
                </button>
                <div
                  ref="dropDown"
                  uk-dropdown="pos: bottom-right; mode: click; animation: uk-animation-slide-bottom-medium; duration: 150; toggle: - *; delay-hide: 0"
                  v-if="isProfileLoaded"
                >
                  <ul @click="closeUserMenu" class="uk-nav uk-dropdown-nav">
                    <li>
                      <router-link :to="'/' + getProfile.username"
                        ><i class="fal fa-user"></i> Profile</router-link
                      >
                    </li>
                    <li>
                      <router-link :to="'/settings'"
                        ><i class="fal fa-cogs"></i> Settings</router-link
                      >
                    </li>
                    <li class="uk-nav-header">Financial</li>
                    <li>
                      <a @click="buyProModal()">
                        <i class="fal fa-gem"></i> Go Pro
                      </a>
                    </li>
                    <li>
                      <router-link to="/payments"
                        ><i class="fal fa-credit-card"></i>
                        Payments</router-link
                      >
                    </li>
                    <li>
                      <a @click="TopUpBalanceModal">
                        <i class="fal fa-wallet"></i> Charge Account
                      </a>
                    </li>
                    <li class="uk-nav-divider"></li>
                    <li>
                      <a @click="logout"
                        ><i class="fal fa-sign-out-alt"></i> Logout</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="uk-width-auto uk-flex uk-flex-middle"
                v-if="!isAuthenticated"
              >
                <div class="uk-margin-right">
                  <button
                    @click="openModal('login')"
                    :class="{ 'uk-position-absolute': isAuthenticated }"
                    class="
                      f-s-080
                      uk-button uk-button-default
                      button-top-menu button-transparent
                    "
                  >
                    {{ 'login' | lang }}
                  </button>
                  <button
                    class="
                      uk-button uk-button-default
                      button-top-menu button-bg
                    "
                    @click="openModal('register')"
                  >
                    {{ 'register' | lang }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <UploadStatusBar></UploadStatusBar>
        </div>
        <div class="main-content scrollbar" id="main-scrollbar">
          <div class="middle-side">
            <div class="search-results-container" v-if="showSearch">
              <h3 class="uk-text-truncate" v-if="search.trim() === ''">
                {{ 'search_top_header_empty_field' | lang }}
              </h3>
              <h3 class="uk-text-truncate" v-else>
                {{ 'search_top_header' | lang({q: search.trim()}) }}
              </h3>
              <i class="fal fa-times close-search" @click="closeSearch()"></i>
              <!--<i class="png-ico close medium close-search"></i>-->
              <div
                class="search-no-result"
                v-if="
                  searchResultsCount === 0 &&
                  !searchLoading &&
                  search.trim() != ''
                "
              >
                No results found! Try another one..
              </div>
              <div
                class="search-suggestion-items f-s-14"
                v-if="searchSuggestions.length && searchResultsCount === 0"
              >
                <div class="f-s-1">Popular Searches</div>
                <a
                  @click="clickOnSuggestion(searchSuggestion.query)"
                  href="javascript:void(0)"
                  v-for="(searchSuggestion, i) in searchSuggestions"
                  :key="i"
                >
                  {{ searchSuggestion.query }}
                </a>
              </div>
              <div class="search-results" v-if="searchResultsCount > 0">
                <div
                  class="search-results-sub"
                  v-if="searchResults.users.length"
                >
                  <h4>{{ 'users' | lang }}</h4>
                  <UserSuggest
                    :static="true"
                    localkey="search"
                    :type="'CircleUserItem'"
                    class="user-list-items"
                    :static-items="searchResults.users"
                  />
                </div>
                <div
                  class="search-results-sub"
                  v-if="searchResults.sounds.length"
                >
                  <h4>{{ 'sounds' | lang }}</h4>
                  <Items
                    :type="'VPostItem'"
                    class="v-post-items"
                    :items="searchResults.sounds"
                  />
                </div>
                <div
                  class="search-results-sub"
                  v-if="searchResults.videos.length"
                >
                  <h4>{{ 'videos' | lang }}</h4>
                  <VideoSlider
                    :options="{ subclass: 'uk-width-1-4' }"
                    :staticItems="searchResults.videos"
                  />
                </div>
                <div
                  class="search-results-sub"
                  v-if="searchResults.albums.length"
                >
                  <h4>{{ 'albums' | lang }}</h4>
                  <Items
                    class="uk-grid-12 hb-post-items"
                    :options="{
                      showTracks: true,
                      subclass: 'uk-width-1-3 uk-width-1-6@m',
                    }"
                    uk-grid
                    :type="'GridPostItem'"
                    :items="searchResults.albums"
                  />
                </div>
                <div
                  class="search-results-sub"
                  v-if="searchResults.playlists.length"
                >
                  <h4>{{ 'playlists' | lang }}</h4>
                  <Items
                    class="uk-grid-12 hb-post-items"
                    :options="{
                      showTracks: true,
                      showDur: true,
                      subclass: 'uk-width-1-3 uk-width-1-6@m',
                    }"
                    uk-grid
                    :type="'GridPostItem'"
                    :items="searchResults.playlists"
                  />
                </div>
              </div>
            </div>
            <div v-if="currentRouteName === 'chat'">
              <div class="uk-width-1-1">
                <component :user.sync="getProfile" v-bind:is="'Chat'" />
              </div>
            </div>
            <div
              v-show="
                settingsLoaded &&
                settings.widgets[currentRouteName] != undefined
              "
              :class="{ 'hidden-height': showSearch }"
            >
              <div class="uk-grid-small" uk-grid>
                <div class="uk-width-1-1">
                  <component
                    :auth.sync="getProfile"
                    :moods="settings && settings.moods"
                    :releaseTypes="settings && settings.releaseTypes"
                    :pricings="settings && settings.pricings"
                    :licenseTypes="settings && settings.licenseTypes"
                    :postLanguages="settings && settings.allPostLanguages"
                    v-show="currentRouteName === 'upload'"
                    v-bind:is="'LargeUploader'"
                  />
                  <component
                    v-show="currentRouteName === 'video'"
                    v-model="cachedData['video']"
                    @update="updateCachedData"
                    :player.sync="playerControls"
                    :auth.sync="getProfile"
                    v-bind:is="'VideoLargePlayer'"
                  />
                </div>
              </div>
            </div>
            <div
              v-if="
                settingsLoaded &&
                settings.widgets[currentRouteName] != undefined
              "
              :class="{ 'hidden-height': showSearch }"
            >
              <div class="uk-grid-small" uk-grid>
                <div class="uk-width-1-1">
                  <component
                    v-if="currentRouteName === 'settings'"
                    v-bind:is="'Settings'"
                  />
                </div>
                <div
                  class="uk-width-1-1"
                  v-if="settings.widgets[currentRouteName].main != undefined"
                >
                  <component
                    v-for="(widget, index) in settings.widgets[currentRouteName]
                      .main"
                    v-model="cachedData[currentRouteName]"
                    @update="updateCachedData"
                    :player.sync="playerControls"
                    :auth.sync="getProfile"
                    :key="index"
                    :options="widget.options"
                    v-bind:is="widget.template"
                  />
                </div>

                <div
                  class="uk-width-expand inner-main-col"
                  v-if="settings.widgets[currentRouteName].left != undefined"
                >
                  <component
                    v-for="(widget, index) in settings.widgets[currentRouteName]
                      .left"
                    v-model="cachedData[currentRouteName]"
                    @update="updateCachedData"
                    :player.sync="playerControls"
                    :auth.sync="getProfile"
                    :key="index"
                    :options="widget.options"
                    v-bind:is="widget.template"
                  />
                </div>
                <div
                  class="uk-width-1-4 sidebar-width inner-siderbar"
                  v-if="settings.widgets[currentRouteName].right != undefined"
                >
                  <div
                    class="right-sidebar-section"
                    v-if="settings.widgets[currentRouteName].right.length"
                  >
                    <component
                      v-for="(widget, index) in settings.widgets[
                        currentRouteName
                      ].right"
                      :key="index"
                      :auth.sync="getProfile"
                      v-model="cachedData[currentRouteName]"
                      :options="widget.options"
                      v-bind:is="widget.template"
                    />
                  </div>
                </div>

                <div
                  class="uk-width-1-1"
                  v-if="settings.widgets[currentRouteName].bottom != undefined"
                >
                  <component
                    v-for="(widget, index) in settings.widgets[currentRouteName]
                      .bottom"
                    :key="index"
                    v-model="cachedData[currentRouteName]"
                    :options="widget.options"
                    v-bind:is="widget.template"
                  />
                </div>
              </div>
            </div>
            <div :class="{ 'hidden-height': showSearch }">
              <router-view />
            </div>
          </div>
          <Footer />
        </div>

        <!--                    </VuePerfectScrollbar>-->
      </div>

      <div class="site-right-col" v-if="screenWidth > 1024">
        <!-- <div class="scrollbar">
                    <LiveStats></LiveStats>
                </div> -->
        <DesktopPlayer :key="playerKey" :chatUsers="chatUsers" />
      </div>
      <mobile-player
        v-if="screenWidth <= 1024"
        :controls.sync="playerControls"
      />
    </div>
    <modal
      v-model="showLogin"
      :isLoading="authLoading"
      @close="showLogin = false"
    >
      <h3 v-if="modalType === 'login'" slot="header">{{ 'login' | lang }}</h3>
      <h3 v-if="modalType === 'active'" slot="header">
        <i
          v-if="modalStep === 2 && activationCode.length > 0"
          @click="
            activationCode = [];
            modalStep = 1;
          "
          class="fal fa-arrow-left"
        ></i>
        {{ 'account_activation' | lang }}
      </h3>
      <h3 v-if="modalType === 'register'" slot="header">
        <i
          v-if="modalStep === 2"
          @click="modalStep = 1"
          class="fal fa-arrow-left"
        ></i>
        {{ 'register' | lang }}
      </h3>
      <h3 v-if="modalType === 'forgot'" slot="header">
        <i
          @click="modalType = 'login'"
          v-if="modalStep === 1"
          class="fal fa-arrow-left"
        ></i>
        <i
          @click="modalStep = 1"
          v-if="modalStep === 2"
          class="fal fa-arrow-left"
        ></i>
        <span v-if="forgotPassword.status != 'SUCCESS'">{{ 'forgot_password' | lang }}</span>
        <span v-if="forgotPassword.status === 'SUCCESS'">{{ 'change_password' | lang }}</span>
      </h3>
      <div v-if="modalType === 'forgot'" slot="body">
        <form v-if="modalStep === 1">
          <fieldset class="uk-fieldset">
            <p class="f-f-reg f-s-085">
              {{ 'forgot_password_desc' | lang }}
            </p>
            <div class="form-margin">
              <div class="uk-inline min-width-100">
                <span class="uk-form-icon" uk-icon="icon: user">
                  <i class="fal fa-user"></i>
                </span>
                <input
                  @keyup="onKeyupForgot"
                  class="uk-input min-width-100"
                  type="text"
                  :placeholder="'email_or_username' | lang"
                  v-model="forgotPasswordForm.username"
                />
              </div>
            </div>
          </fieldset>
        </form>
        <div v-if="modalStep === 2">
          <div
            v-if="forgotPassword.status === 'LOADING'"
            class="uk-text-center"
          >
            <i uk-spinner></i>
          </div>
          <div v-if="forgotPassword.status === 'SUCCESS'">
            <form>
              <fieldset class="uk-fieldset">
                <div class="form-margin">
                  <div class="uk-inline min-width-100">
                    <span class="uk-form-icon">
                      <i class="fal fa-lock-alt"></i>
                    </span>
                    <input
                      @keyup="onKeyupReset"
                      class="uk-input min-width-100 p-r-50"
                      :type="resetPasswordType ? 'password' : 'text'"
                      placeholder="Enter new password"
                      v-model="forgotPassword.change.password"
                    />
                    <div
                      @click="toggleResetPasswordType"
                      class="show-password f-f-reg"
                    >
                      {{ resetPasswordType ? "Show" : "Hide" }}
                    </div>
                  </div>
                  <p class="f-f-reg f-s-080">
                    Notice : After changing your password all of your devices
                    will get logged out and you need to login again.
                  </p>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
      <div v-if="modalType === 'login'" slot="body">
        <form>
          <fieldset class="uk-fieldset">
            <div class="form-margin">
              <div class="uk-inline min-width-100">
                <span class="uk-form-icon" uk-icon="icon: user">
                  <i class="fal fa-user"></i>
                </span>
                <input
                  @keyup="onKeyupLogin"
                  class="uk-input min-width-100"
                  type="text"
                  :disabled="authLoading"
                  :placeholder="'email_or_username' | lang"
                  v-model="loginForm.username"
                />
              </div>
            </div>
            <div class="form-margin">
              <div class="uk-inline min-width-100">
                <span class="uk-form-icon" uk-icon="icon: user">
                  <i class="fal fa-lock-alt"></i>
                </span>
                <input
                  @keyup="onKeyupLogin"
                  class="uk-input min-width-100"
                  type="password"
                  :disabled="authLoading"
                  :placeholder="'password' | lang"
                  v-model="loginForm.password"
                />
              </div>
            </div>
            <div class="form-margin uk-margin-top f-s-090">
              <div uk-grid>
                <div class="uk-width-1-2@m">
                  <label
                    ><input
                      class="uk-checkbox"
                      type="checkbox"
                      :disabled="true"
                      checked
                    />
                    {{ 'remember_me' | lang }}</label
                  >
                </div>
                <div class="uk-width-1-2@m uk-text-right@m">
                  <a @click="openModal('forgot')">{{ 'forgot_password' | lang }}</a>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div v-if="modalType === 'register'" slot="body">
        <form autocomplete="nope" v-if="modalStep === 1">
          <fieldset class="uk-fieldset">
            <div class="form-margin">
              <div class="uk-inline min-width-100 field-with-status-ico">
                <span class="uk-form-icon">
                  <i class="fal fa-user"></i>
                </span>
                <input
                  class="uk-input min-width-100"
                  type="text"
                  @keyup="checkAvailable('username')"
                  :disabled="registerLoading"
                  :placeholder="'username' | lang"
                  v-model="registerForm.username"
                />
                <i
                  class="status-ico fas fa-circle error"
                  v-if="registerValidate.username === 'empty'"
                ></i>
                <i
                  class="status-ico fas fa-times-circle"
                  v-if="registerValidate.username === false"
                ></i>
                <i
                  class="status-ico fas fa-check-circle"
                  v-if="registerValidate.username === true"
                ></i>
              </div>
            </div>
            <div class="form-margin">
              <div class="uk-inline min-width-100 field-with-status-ico">
                <span class="uk-form-icon">
                  <i class="fal fa-signature"></i>
                </span>
                <input
                  class="uk-input min-width-100"
                  type="text"
                  @keyup="checkAvailable('fullname', false)"
                  :disabled="registerLoading"
                  :placeholder="'fullname' | lang"
                  v-model="registerForm.fullname"
                />
                <i
                  class="status-ico fas fa-circle error"
                  v-if="registerValidate.fullname === 'empty'"
                ></i>
                <i
                  class="status-ico fas fa-times-circle"
                  v-if="registerValidate.fullname === false"
                ></i>
                <i
                  class="status-ico fas fa-check-circle"
                  v-if="registerValidate.fullname === true"
                ></i>
              </div>
            </div>
          </fieldset>
        </form>
        <form autocomplete="off" v-if="modalStep === 2">
          <fieldset class="uk-fieldset">
            <div class="fake-feilds">
              <input
                type="text"
                :placeholder="'fake email'"
                aria-hidden="true"
              />
              <input type="password" :placeholder="'fake password'" />
            </div>
            <div class="form-margin">
              <div class="uk-inline min-width-100 field-with-status-ico">
                <span class="uk-form-icon">
                  <i class="fal fa-at"></i>
                </span>
                <input
                  class="uk-input min-width-100"
                  @keyup="checkAvailable('email')"
                  type="text"
                  :disabled="registerLoading"
                  :placeholder="'email' | lang"
                  v-model="registerForm.email"
                />
                <i
                  class="status-ico fas fa-circle error"
                  v-if="registerValidate.email === 'empty'"
                ></i>
                <i
                  class="status-ico fas fa-times-circle"
                  v-if="registerValidate.email === false"
                ></i>
                <i
                  class="status-ico fas fa-check-circle"
                  v-if="registerValidate.email === true"
                ></i>
              </div>
            </div>
            <div class="form-margin">
              <div class="uk-inline min-width-100 field-with-status-ico">
                <span class="uk-form-icon">
                  <i class="fal fa-lock-alt"></i>
                </span>
                <input
                  class="uk-input min-width-100"
                  @keyup="checkAvailable('password', false)"
                  type="password"
                  :disabled="registerLoading"
                  :placeholder="'password' | lang"
                  v-model="registerForm.password"
                />
                <i
                  class="status-ico fas fa-circle error"
                  v-if="registerValidate.password === 'empty'"
                ></i>
              </div>
            </div>
          </fieldset>
        </form>
      </div>
      <div v-if="modalType === 'active'" slot="body">
        <form autocomplete="nope" v-if="modalStep === 1">
          <div>
            {{ 'verification_sent_success' | lang }}
          </div>
          <br />
          <fieldset class="uk-fieldset">
            <div class="form-margin">
              <div class="uk-grid-5" uk-grid>
                <div class="uk-width-1-5" v-for="index in [0, 1, 2, 3, 4]" :key="index">
                  <div class="uk-inline min-width-100">
                    <input
                      type="text"
                      maxlength="1"
                      @keyup="activationCodeKeyUp"
                      :data-index="index"
                      class="uk-input activation-code-input f-s-15"
                      placeholder="_"
                      ref="activationCode"
                      v-model="activationCode[index]"
                    />
                  </div>
                </div>
              </div>
            </div>
          </fieldset>
        </form>
        <form autocomplete="off" v-if="modalStep === 2">
          <div class="uk-text-center" v-if="activationStatus === 'LOADING'">
            <div uk-spinner></div>
          </div>
          <div
            class="status-error-underline"
            v-if="activationStatus === 'INVALID_ACTIVATION_CODE'"
          >
            {{ 'invalid_activation_code' | lang }}
          </div>
          <div
            class="status-success-underline"
            v-if="activationStatus === 'ACTIVATED'"
          >
            {{ 'verification_success' | lang({ email: loginForm.username }) }}
          </div>
          <div
            class="status-error-underline"
            v-if="activationStatus === 'ALREADY_ACTIVATED'"
          >
            {{ 'account_already_activated' | lang }}
          </div>
          <div
            class="status-error-underline"
            v-if="activationStatus === 'INVALID_USERNAME'"
          >
            {{ 'invlid_username' | lang }}
          </div>
        </form>
      </div>
      <div v-if="modalType === 'forgot'" slot="footer">
        <div v-if="modalStep === 1">
          <div class="uk-flex uk-flex-middle">
            <button
              @click="resetPasswordRequest"
              class="
                uk-button uk-button-default
                button-top-menu button-bg
                flex-right
              "
            >
              {{ 'change_password' | lang }}
            </button>
          </div>
        </div>
        <div v-if="modalStep === 2 && forgotPassword.status === 'SUCCESS'">
          <div class="uk-flex uk-flex-middle">
            <button
              @click="changePasswordRequest"
              :disabled="forgotPassword.processing"
              class="
                uk-button uk-button-default
                button-top-menu button-bg
                flex-right
                button-spinner
                min-width-100
              "
            >
              <span v-if="!forgotPassword.processing">{{ 'reset_password' | lang }}</span>
              <div
                v-if="forgotPassword.processing"
                uk-spinner="ratio: 0.8"
              ></div>
            </button>
          </div>
        </div>
      </div>
      <div v-if="modalType === 'active'" slot="footer">
        <div v-if="modalStep === 2">
          <div
            class="uk-flex uk-flex-middle"
            v-if="activationStatus != 'LOADING'"
          >
            <a @click="openModal('forgot')">{{ 'forgot_password' | lang }}</a>
            <button
              @click="openModal('login')"
              class="
                uk-button uk-button-default
                button-top-menu button-bg
                flex-right
              "
            >
              {{ 'login' | lang }}
            </button>
          </div>
        </div>
        <div v-if="modalStep === 1">
          <div class="uk-flex">
            <button
              :disabled="activationCode.length < 5"
              @click="checkActivate(registerForm.username, null)"
              class="
                uk-button uk-button-default
                button-top-menu button-bg
                flex-right
              "
            >
              {{ 'submit' | lang }}
            </button>
          </div>
        </div>
      </div>
      <div
        v-if="modalType === 'login' || modalType === 'register'"
        slot="footer"
      >
        <button
          v-if="modalType === 'login'"
          @click="login"
          :disabled="authLoading"
          class="uk-button uk-button-primary button-spinner min-width-100"
        >
          <span v-if="!authLoading">{{ 'login' | lang }}</span>
          <div v-if="authLoading" uk-spinner></div>
        </button>
        <button
          v-if="modalType === 'register'"
          @click="register"
          :disabled="authLoading"
          class="
            uk-button uk-button-primary
            button-spinner
            min-width-100
            button-ico-right
          "
        >
          <span v-if="!registerLoading && modalStep !== 1">{{
            'register' | lang
          }}</span>
          <span v-if="!registerLoading && modalStep === 1"
            >{{ 'continue' | lang }} <i class="fal fa-chevron-right"></i
          ></span>
          <div v-if="registerLoading" uk-spinner></div>
        </button>
        <div class="devider-text-or uk-divider-icon">{{ 'or' | lang }}</div>
        <div class="uk-text-center f-s-085" v-if="settings !== null">
          <div
            @click="socialConnect('apple')"
            class="login-with-social apple"
            v-if="settings.activeSocials.indexOf('apple') !== -1"
          >
            <span class="ico">
              <i class="fab fa-apple"></i>
            </span>
            {{ modalType | lang }} {{ 'with' | lang}} Apple
          </div>
          <div
            @click="socialConnect('instagram')"
            class="login-with-social instagram"
            v-if="settings.activeSocials.indexOf('instagram') !== -1"
          >
            <span class="ico">
              <i class="fab fa-instagram"></i>
            </span>
            {{ modalType | lang }} {{ 'with' | lang}} Instagram
          </div>
          <div
            @click="socialConnect('google')"
            class="login-with-social google"
            v-if="settings.activeSocials.indexOf('google') !== -1"
          >
            <span class="ico">
              <i class="fab fa-google"></i>
            </span>
            {{ modalType | lang }} {{ 'with' | lang}} Google
          </div>
          <div
            @click="socialConnect('facebook')"
            class="login-with-social facebook"
            v-if="settings.activeSocials.indexOf('facebook') !== -1"
          >
            <span class="ico">
              <i class="fab fa-facebook-f"></i>
            </span>
            {{ modalType | lang }} {{ 'with' | lang}} Facebook
          </div>
          <div
            @click="socialConnect('twitter')"
            class="login-with-social twitter"
            v-if="settings.activeSocials.indexOf('twitter') !== -1"
          >
            <span class="ico">
              <i class="fab fa-twitter"></i>
            </span>
            {{ modalType | lang }} {{ 'with' | lang}} Twitter
          </div>
        </div>
      </div>
    </modal>
    <PromotePostModal
      @close="doCloseModal('promotedPost')"
      :data="modals.promotedPost.data"
      :current="0"
      :show="modals.promotedPost.show"
    />
    <BuyPostModal
      @close="doCloseModal('buyPost')"
      :data="modals.buyPost.data"
      :show="modals.buyPost.show"
    />
    <PromoteUserModal
      @close="doCloseModal('promotedUser')"
      :current="0"
      :show="modals.promotedUser.show"
    />
    <TopUpBalanceModal
      @close="doCloseModal('topUpBalance')"
      :current="0"
      :show="modals.topUpBalance.show"
    />
    <ReportModal
      @close="doCloseModal('report')"
      :currentStep="0"
      :data="modals.report.data"
      :show="modals.report.show"
    />
    <BuyProModal
      @close="doCloseModal('buyPro')"
      :currentStep="0"
      :show="modals.buyPro.show"
    />
    <LikesModal
      @close="doCloseModal('likes')"
      :tabs="2"
      :data="modals.likes.data"
      :show="modals.likes.show"
    />
    <confirm-modal :show="modals.confirm.show" :title="modals.confirm.title" :desc="modals.confirm.desc" :btn="modals.confirm.btn" :action="modals.confirm.action" @close="doCloseModal('confirm')"/>
  </div>
</template>

<script>
/* eslint-disable */
// import { initializeApp } from "firebase/app";
// import { getDatabase } from "firebase/database";
// import { getAnalytics } from "firebase/analytics";
import Footer from "@/components/Footer";
import { USER_REQUEST, USER_PLAYER_MESSAGING } from "./store/actions/user";
import { AUTH_REQUEST } from "./store/actions/auth";
import { SETTINGS_REQUEST } from "./store/actions/settings";
import axios from "axios";
import DesktopPlayer from "./components/DesktopPlayer";
import MiniChat from "./components/MiniChat";
import Chat from "./components/Chat";
import PromotedUsers from "./components/Widgets/PromotedUsers";
import Banner from "./components/Widgets/Banner";
import AppStore from "./components/Widgets/AppStore";
import ProfileTopBanner from "./components/Widgets/ProfileTopBanner";
import TileSlider from "./components/Widgets/TileSlider";
import SoundSlider from "./components/Widgets/SoundSlider";
import UserImageGallery from "./components/Widgets/UserImageGallery";
import SoundLargePlayer from "./components/Widgets/SoundLargePlayer";
import SidebarAds from "./components/Widgets/SidebarAds";
import SoundTabs from "./components/Widgets/SoundTabs";
import UserInfo from "./components/Widgets/UserInfo";
import RecentLikes from "./components/Widgets/RecentLikes";
import VerticalPostList2 from "./components/Widgets/VerticalPostList2";
import VideoLargePlayer from "./components/Widgets/VideoLargePlayer";
import VideoTabs from "./components/Widgets/VideoTabs";
import VideoSlider from "./components/Widgets/VideoSlider";
import ProfileAlbums from "./components/Widgets/ProfileAlbums";
import UserSuggest from "./components/Widgets/UserSuggest";
import LargeUploader from "./components/Widgets/LargeUploader";
import UploadStatusBar from "./components/UploadStatusBar";
import Items from "./components/Items/Items";
import PromotePostModal from "./components/Modals/PromotePostModal";
import PromoteUserModal from "./components/Modals/PromoteUserModal";
import TopUpBalanceModal from "./components/Modals/TopUpBalanceModal";
import BuyPostModal from "./components/Modals/BuyPostModal";
import ReportModal from "./components/Modals/ReportModal";
import BuyProModal from "./components/Modals/BuyProModal";
import ChartFilter from "./components/Widgets/ChartFilter";
import Chart from "./components/Widgets/Chart";
import Payments from "./components/Widgets/Payments";
import NotificationsDropdown from "@/components/DropDown/NotificationsDropdown";
import Notifications from "@/components/Widgets/Notifications";
import Settings from "@/components/Settings";
import Sidebar from "@/components/Sidebar";
import MusicAdsSlider from "@/components/Widgets/MusicAdsSlider";
import ChartOverview from "@/components/Widgets/ChartOverview";
import NewReleases from "@/components/Widgets/NewReleases";
import Filters from "@/components/Filters";
import FilteredItems from "@/components/FilteredItems";
import LatestPlaylists from "@/components/Widgets/LatestPlaylists";
import { mapGetters, mapState } from "vuex";
import { AUTH_LOGOUT, AUTH_SUCCESS } from "./store/actions/auth";
import helper from "./helpers/helper";
// test
import * as animationData from "./assets/animations/lf20_kknBFb.json";
// import Player from './helpers/Player'
import "./helpers/GenerateWaveform";
import MediumList from "@/components/Widgets/MediumList";
import LikesModal from "@/components/Modals/LikesModal";
import PlaylistCapsuleSlider from "@/components/Tools/CapsuleSlider";
import LiveStats from "@/components/Widgets/LiveStats";
import SalesAndPromotions from "@/components/Widgets/SalesAndPromotions";
import MobilePlayer from "@/components/MobilePlayer";
import NoPosts from "@/components/Widgets/NoPosts";
import Discover from "@/components/Widgets/Discover";
import DiscoverFilters from "@/components/Widgets/DiscoverFilters";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import FiltersSidebar from "@/components/FiltersSidebar";
import { getAuth, signInWithCustomToken } from "firebase/auth";
import ImagePlaceholder from "./components/Tools/ImagePlaceholder.vue";
import ConfirmModal from './components/Modals/ConfirmModal.vue';

export default {
  name: "App",
  components: {
    Footer,
    PromoteUserModal,
    PromotePostModal,
    TopUpBalanceModal,
    LikesModal,
    BuyProModal,
    BuyPostModal,
    ReportModal,
    DesktopPlayer,
    PromotedUsers,
    Banner,
    ProfileTopBanner,
    TileSlider,
    SoundSlider,
    UserImageGallery,
    SoundLargePlayer,
    SoundTabs,
    SidebarAds,
    MiniChat,
    Chat,
    UserInfo,
    RecentLikes,
    VideoLargePlayer,
    VideoTabs,
    VideoSlider,
    VerticalPostList2,
    ProfileAlbums,
    LargeUploader,
    UploadStatusBar,
    Items,
    UserSuggest,
    ChartFilter,
    Chart,
    Payments,
    NotificationsDropdown,
    Notifications,
    Settings,
    Sidebar,
    MusicAdsSlider,
    ChartOverview,
    NewReleases,
    Filters,
    FilteredItems,
    FiltersSidebar,
    LatestPlaylists,
    MediumList,
    PlaylistCapsuleSlider,
    LiveStats,
    SalesAndPromotions,
    MobilePlayer,
    NoPosts,
    Discover,
    DiscoverFilters,
    VuePerfectScrollbar,
    ImagePlaceholder,
    AppStore,
    ConfirmModal,
  },
  data() {
    return {
      lastSearchVal: '',
      autoInitializePlayer: false,
      screenWidth: 1024,
      dropDowns: ["notificationsDropdown", "dropDown", "moreDropDown"],
      hmbrgr: false,
      hasNewNotification: false,
      withoutWidgetRoutes: ["settings"],
      modals: {
        confirm: {
          show: false,
          title: '',
          desc: '',
          btn: '',
          action: null
        },
        buyPro: {
          show: false,
        },
        promotedPost: {
          data: {},
          show: false,
        },
        promotedUser: {
          show: false,
        },
        topUpBalance: {
          show: false,
        },
        buyPost: {
          show: false,
          data: {},
        },
        report: {
          show: false,
          data: {},
        },
        likes: {
          show: false,
          data: {},
        },
      },
      animationOptions: {
        defaultOptions: { animationData: animationData },
        animationSpeed: 1,
      },
      cachedData: {
        profileArtist: null,
        profileSeeAll: null,
        explore: null,
        video: null,
        sound: null,
        playlist: null,
        home: null,
        charts: null,
        settings: null,
        album: null,
        exploreFilter: null,
        discover: null,
      },
      activationStatus: null,
      activationCode: [],
      showLogin: false,
      modalType: "login",
      searchResults: [],
      searchResultsCount: 0,
      modalStep: 1,
      settingsLoaded: false,
      darkMode: true,
      chatUsers: [],
      loginForm: {
        username: "",
        password: "",
      },
      registerForm: {
        username: "",
        fullname: "",
        email: "",
        password: "",
        clientId: "1",
        clientSecret: "LbfOe737WAXRw5uxOaX91Nk1Hr6blChPqZZ38h8K",
        grantType: "password",
      },
      forgotPasswordForm: {
        username: "",
      },
      resetPasswordForm: {
        password: "",
      },
      registerValidate: {
        username: null,
        email: null,
        fullname: null,
        password: null,
      },
      widgets: [
        {
          name: "PromotedUsers",
          options: { url: "test" },
        },
      ],
      search: "",
      searchLoading: false,
      searchSuggestions: [],
      options: {
        showMenu: false,
      },
      socket: null,
      isMobile: false,
      showSearch: false,
      conversations: [],
      playerKey: Math.random(),
      registerLoading: false,
      notificationSystem: {
        options: {
          show: {
            theme: "dark",
            icon: "icon-person",
            position: "topCenter",
            progressBarColor: "rgb(0, 255, 184)",
            buttons: [
              [
                "<button>Ok</button>",
                function (instance, toast) {
                  alert("Hello world!");
                },
                true,
              ],
              [
                "<button>Close</button>",
                function (instance, toast) {
                  instance.hide(
                    {
                      transitionOut: "fadeOutUp",
                      onClosing: function (instance, toast, closedBy) {},
                    },
                    toast,
                    "buttonName"
                  );
                },
              ],
            ],
            onOpening: function (instance, toast) {},
            onClosing: function (instance, toast, closedBy) {},
          },
          ballon: {
            balloon: true,
            position: "bottomCenter",
          },
          info: {
            position: "bottomLeft",
          },
          success: {
            position: "bottomRight",
          },
          warning: {
            position: "topLeft",
          },
          error: {
            position: "topRight",
          },
          question: {
            timeout: 20000,
            close: false,
            overlay: true,
            toastOnce: true,
            id: "question",
            zindex: 99999999,
            position: "center",
            buttons: [
              [
                "<button><b>YES</b></button>",
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "button");
                },
                true,
              ],
              [
                "<button>NO</button>",
                function (instance, toast) {
                  instance.hide({ transitionOut: "fadeOut" }, toast, "button");
                },
              ],
            ],
            onClosing: function (instance, toast, closedBy) {},
            onClosed: function (instance, toast, closedBy) {},
          },
        },
      },
      playerControls: {
        current: { id: 123 },
        playlist: [],
        Player: {
          duration: 0,
        },
      },
      forgotPassword: {
        status: "",
        processing: false,
        change: {
          password: "",
          token: "",
        },
      },
      resetPasswordType: true,
    };
  },
  created() {
    this.getSettings();
    // bus.$on('baseGetted', () => {
    //     if (this.$store.getters.isAuthenticated) {
    //         axios.defaults.headers.common['Authorization'] = localStorage.getItem('user-token')
    //         axios.defaults.crossDomain = false
    //         this.$store.dispatch(USER_REQUEST).catch(err => {
    //             if (err.message === 'Network Error') {
    //                 this.$toast.warning('Connection lost', '', {
    //                     balloon: false,
    //                     position: 'topCenter',
    //                     timeout: 10000,
    //                     width: 1000
    //                 })
    //             }
    //         })
    //     }
    //     this.$store.commit(USER_PLAYER_MESSAGING)
    // })

    let vm = this;
  },
  computed: {
    playerStatus() {
      return this.$store.getters.playerStatus;
    },
    currentId() {
      return this.current && this.current.realId;
    },
    current() {
      return this.$store.getters.getCurrent;
    },
    mainClassName() {
      return this.currentRouteName.toLowerCase() + "-page";
    },
    noNeedWidgets() {
      if (this.withoutWidgetRoutes.indexOf(this.currentRouteName) != -1) {
        return true;
      }
      // if(this.settings.widgets===null || settings.widgets===undefined) {
      //   return false
      // }
    },
    //      settings() {
    //        return this.$store.getters.getSettings
    //      },
    ...mapGetters([
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "settings",
    ]),
    ...mapState({
      authLoading: (state) => state.auth.status === "loading",
      name: (state) => `${state.user.profile.fullname}`,
    }),
    currentRouteName() {
      return this.$route.name;
    },
    userStorage() {
      return localStorage.getItem("user") === null
        ? null
        : JSON.parse(localStorage.getItem("user"));
    },
  },
  watch: {
    showSearch(mode) {
      if (mode) {
        document.getElementById("site-main-col").scrollTop = 0;
      }
    },
    screenWidth(newVal) {},
    activationCode: function (newVal) {
      // console.log("activationCode", this.$refs.activationCode, newVal);
    },
    isAuthenticated: function (newVal) {
      if (!newVal) {
        if (
          this.$route.meta !== undefined &&
          this.$route.meta.requiresAuth !== undefined &&
          this.$route.meta.requiresAuth
        ) {
          this.$router.push("/login");
        }
      }
    },
    isProfileLoaded: function (newVal) {},
    getProfile: function (newVal) {},
    settings: function (newVal) {
      // console.log("settings", newVal);
    },
    $route: function (newVal, oldVal) {
      this.checkUrl();
      this.hmbrgr = false;
      // if(newVal.name !== undefined && oldVal.name !== undefined) {
      //
      // }
      document.getElementById("main-scrollbar").scrollTop = 0;
      this.showSearch = false;
      for (let i = 0; i < this.dropDowns.length; i++) {
        setTimeout(() => {
          UIkit.dropdown(this.$refs[this.dropDowns[i]]).hide();
        }, 50 * i);
      }
    },
  },
  methods: {
    closeMoreMenuE() {
      bus.$emit("closeMoreMenu", true);
    },
    checkUrl() {
      if (window.location.href.indexOf("/activate/") != -1) {
        this.modalType = "active";
        this.checkActivate(
          this.$route.params.username,
          this.$route.params.code
        );
        this.modalStep = 2;
        this.showLogin = true;
      }
      if (window.location.href.indexOf("/forgot-password") != -1) {
        this.modalType = "forgot";
        this.showLogin = true;
      }
      if (window.location.href.indexOf("/reset-password") != -1) {
        this.modalType = "forgot";
        this.modalStep = 2;
        this.showLogin = true;
        this.checkResetPassword();
      }
      if (window.location.href.indexOf("/set-token") != -1) {
        localStorage.setItem("user-token", this.$route.params.token);
        axios.defaults.headers.common["Authorization"] =
          this.$route.params.token;
        this.$store.commit(AUTH_SUCCESS, {
          data: { access_token: this.$route.params.token },
        });
        this.$store.dispatch(USER_REQUEST);
      }
      if (window.location.href.indexOf("/login") != -1) {
        this.modalType = "login";
        this.modalStep = 1;
        this.showLogin = true;
      }
      if (window.location.href.indexOf("/register") != -1) {
        this.modalType = "register";
        this.modalStep = 1;
        this.showLogin = true;
      }
    },
    setMetaTags(tags) {
      // tags = [
      //     {
      //         name: 'title',
      //         innerHTML: 'Test Set Tag'
      //     },
      //     {
      //         name: 'test',
      //         innerHTML: 'test'
      //     }
      // ]
      tags.map((tag) => {
        let created = false;
        let tg = null;
        tg = document.querySelector(
          tag.name +
            (tag.where !== undefined
              ? "[" + tag.where.key + '="' + tag.where.value + '"]'
              : "")
        );
        if (tg === null) {
          created = true;
          tg = document.createElement(tag.name);
        }
        if (tag.attributes !== undefined) {
          tag.attributes.map((attr) => {
            tg.setAttribute(attr.key, attr.value);
          });
        }
        if (tag.innerHTML != undefined) {
          tg.innerHTML = tag.innerHTML;
        }
        if (created) {
          document.getElementsByTagName("head")[0].appendChild(tg);
        }
      });
    },
    toggleHmbrgr() {
      this.hmbrgr = !this.hmbrgr;
    },
    mobileSearch() {
      this.showSearch = !this.showSearch;
      setTimeout(() => {
        this.$refs.mobileSearchInput.focus();
      }, 200);
    },
    changePasswordRequest() {
      this.forgotPassword.processing = true;
      axios
        .post(API.forgotPassword.changePassword, this.forgotPassword.change)
        .then((resp) => {
          this.forgotPassword.processing = false;
          this.showLogin = false;
          bus.$emit("success", {
            title: "",
            message: resp.data.message,
          });
          setTimeout(() => {
            this.openModal("login");
          }, 300);
        })
        .catch((e) => {
          this.forgotPassword.processing = false;
          bus.$emit("error", {
            title: "",
            message: e.response.data.message,
          });
        });
    },
    toggleResetPasswordType() {
      this.resetPasswordType = !this.resetPasswordType;
    },
    checkResetPassword() {
      this.forgotPassword.status = "LOADING";
      let token = this.$route.params.token;
      axios
        .post(API.forgotPassword.check + "/" + token)
        .then(() => {
          this.forgotPassword.change.token = token;
          this.forgotPassword.status = "SUCCESS";
        })
        .catch((e) => {
          // console.log("e.response", e.response);
          this.showLogin = false;
          bus.$emit("error", {
            title: "",
            message: e.response.data.message,
          });
        });
    },
    onKeyupReset(e) {
      if (e.keyCode === 13) {
        this.changePasswordRequest();
      }
    },
    onKeyupForgot(e) {
      e.preventDefault();
      if (e.keyCode === 13) {
        this.resetPasswordRequest();
      }
    },
    resetPasswordRequest() {
      this.showLogin = false;
      axios
        .post(API.forgotPassword.request, this.forgotPasswordForm)
        .then((resp) => {
          bus.$emit("success", {
            title: "",
            message: resp.data.message,
          });
        })
        .catch((e) => {
          try {
            bus.$emit("error", {
              title: "",
              message: e.response.message,
            });
          } catch (e) {}
        });
    },
    closeNotificationsDropdown() {
      if (this.$refs.notificationsDropdown != undefined)
        UIkit.dropdown(this.$refs.notificationsDropdown).hide();
    },
    closeUserMenu() {
      if (this.$refs.dropDown != undefined) {
        UIkit.dropdown(this.$refs.dropDown).hide();
      }
    },
    closeMoreMenu() {
      if (this.$refs.moreDropDown != undefined) {
        UIkit.dropdown(this.$refs.moreDropDown).hide();
      }
    },
    buyProModal() {
      this.modals.buyPro.show = true;
    },
    TopUpBalanceModal() {
      this.modals.topUpBalance.show = true;
    },
    doCloseModal(modalName) {
      this.modals[modalName].show = false;
    },
    clickOnSuggestion(query) {
      this.search = query;
      this.onKeyUpSearch();
    },
    onKeyupLogin(e) {
      if (e.keyCode === 13) {
        this.login();
      }
    },
    closeSearch() {
      this.showSearch = false;
      //        this.search = ''
    },
    onKeyUpSearch(e) {
      if(e.keyCode === 13 && this.search.trim() !== "") {
        this.$router.push('/search/all?q=' + this.search)
        this.closeSearch()
        return;
      }
      if (window["mainSearch"] != null) window["mainSearch"].cancel();
      let CancelToken = axios.CancelToken;
      window["mainSearch"] = CancelToken.source();
      const lastSearch = this.search.trim()
      if (this.search.trim() === "" && this.lastSearchVal === "") {
        this.searchResults = [];
        this.searchLoading = false;
        this.searchResultsCount = 0;
        if (e.keyCode === 8) this.showSearch = false;
        return;
      }
      this.searchLoading = true;
      this.searchResultsCount = 0;
      if (window.searchTimeout) {
        clearTimeout(window.searchTimeout);
        window.searchTimeout = null;
      }
      window.searchTimeout = setTimeout(() => {
        axios
          .get(
            API.search +
              "?count[ALB]=6&count[USR]=10&count[SND]=5&count[VID]=6&count[PLY]=6&q=" +
              this.search,
            {
              cancelToken: window["mainSearch"].token,
            }
          )
          .then((res) => {
            this.searchLoading = false;
            this.searchResults = res.data;
            let keys = Object.keys(this.searchResults);
            for (let i = 0; i < keys.length; i++) {
              this.searchResultsCount += res.data[keys[i]].length;
            }
            if (!this.searchResultsCount) {
            }
            //              this.searchResultsCount = (this.searchResultsCount===0) ? false : this.searchResultsCount
          })
          .catch((e) => {
            // console.log("e", e);
          });
      }, 300);
      this.lastSearchVal = lastSearch;
    },
    activationCodeKeyDown(e) {
      if (
        e.code.indexOf("Numpad") === -1 &&
        e.code.indexOf("Digit") === -1 &&
        e.code.indexOf("Backspace") === -1
      ) {
        e.preventDefault();
        return;
      }

      //        let charCode = e.keyCode
      //        console.log('e', e.code)
      //        return
      //        if (charCode > 31 && (charCode < 47 || charCode > 57)) {
      //          e.preventDefault()
      //          return false;
      //        }
      //        if (e.shiftKey) return false;
      //        return true;
    },

    activationCodeKeyUp(e) {
      // backspace 8
      let length = 5;
      let currentRef = null;
      let index = parseInt(e.target.getAttribute("data-index"), 10);
      if (this.activationCode[index] === undefined) return;
      let targetValue = parseInt(this.activationCode[index], 10);
      let focusIndex = null;
      if (e.keyCode === 8) {
        if (index !== 0) {
          focusIndex = index - 1;
        }
      } else {
        if (index < length - 1) {
          focusIndex = index + 1;
        }
      }
      if (focusIndex != null) {
        this.$refs.activationCode[focusIndex].focus();
      }
      //        if(this.$refs.activationCode.indexOf(e.target)) {
      //          console.log('this.$refs.activationCode.indexOf(e.target)', this.$refs.activationCode.indexOf(e.target))
      //        }
      //        console.log('e', e)
    },
    checkActivate(username, code) {
      if (code === null) code = this.activationCode.join("");
      let vm = this;
      this.activationStatus = "LOADING";
      this.modalStep = 2;
      axios
        .post(API.activation, {
          username: username,
          code: code,
        })
        .then((res) => {
          vm.loginForm.username = res.data.email;
          if (vm.activationCode.length) {
            vm.loginForm.password = vm.registerForm.password;
            vm.login();
          } else {
            vm.activationStatus = "ACTIVATED";
          }
        })
        .catch((e) => {
          if (e.response === undefined) return;
          if (e.response.data.message === "INVALID_ACTIVATION_CODE") {
            vm.activationStatus = "INVALID_ACTIVATION_CODE";
          } else if (e.response.data.message === "ALREADY_ACTIVATED") {
            vm.activationStatus = "ALREADY_ACTIVATED";
          } else if (
            e.response.data.errors.username != undefined &&
            e.response.data.errors.username[0] === "INVALID_USERNAME"
          ) {
            vm.activationStatus = "INVALID_USERNAME";
          }
        });
    },
    checkAvailable(field, fromServer, message) {
      let vm = this;
      if (fromServer === undefined) fromServer = true;
      let windowName = "checkAvailable_" + field;
      if (window[windowName] != null) window[windowName].cancel();
      let CancelToken = axios.CancelToken;
      window[windowName] = CancelToken.source();
      if (this.registerForm[field] === "") {
        this.registerValidate[field] = "empty";
      } else {
        if (fromServer) {
          if (message != undefined) vm.registerLoading = true;
          axios
            .get(API.available + "?" + field + "=" + this.registerForm[field], {
              cancelToken: window[windowName].token,
            })
            .then((res) => {
              if (message != undefined) vm.registerLoading = false;
              if (res.data.success) {
                this.registerValidate[field] = true;
              } else {
                this.registerValidate[field] = false;
              }
            });
        } else {
          this.registerValidate[field] = true;
          return;
        }
      }
    },
    fieldNameGenerator() {
      return "field" + Math.floor(Math.random() * 1000000);
    },
    register() {
      let vm = this;
      if (this.modalStep === 1) {
        if (this.registerForm.username === "") {
          this.registerValidate.username = "empty";
        }
        if (this.registerForm.fullname === "") {
          this.registerValidate.fullname = "empty";
        }
        if (
          this.registerValidate.fullname === "empty" ||
          this.registerValidate.username === "empty"
        ) {
          return;
        }
        if (this.registerValidate.username !== true) {
          this.checkAvailable("username", true, "Force message");
          return;
        }
        this.modalStep = 2;
        return;
      } else {
        if (this.registerForm.email === "") {
          this.registerValidate.email = "empty";
        }
        if (this.registerForm.password === "") {
          this.registerValidate.password = "empty";
        }
        if (
          this.registerValidate.password === "empty" ||
          this.registerValidate.email === "empty"
        ) {
          return;
        }
        if (this.registerValidate.email !== true) {
          this.checkAvailable("email", true, "Force message");
          return;
        }
        this.registerLoading = true;
        axios
          .post(API.register, this.registerForm)
          .then((res) => {
            let setting = this.$store.getters.settings.settings;
            console.debug("setting", setting);
            vm.registerLoading = false;
            if (!setting.forceActivation) {
              this.showLogin = false;
              this.loginForm.username = this.registerForm.username;
              this.loginForm.password = this.registerForm.password;
              this.login();
            } else {
              this.modalStep = 1;
              this.modalType = "active";
            }
          })
          .catch((e) => {
            vm.registerLoading = false;
            let err = e.response.data.errors;
            if (Object.keys(e.response.data.errors).length) {
              vm.$toast.warning(
                err[Object.keys(e.response.data.errors)[0]][0],
                "",
                {
                  balloon: false,
                  position: "topCenter",
                  timeout: 5000,
                  width: 1000,
                }
              );
            }
          });
      }
    },
    socialConnect(provider) {
      let w = 400;
      let h = 500;
      let left = screen.width / 2 - w / 2;
      let top = screen.height / 2 - h / 2;
      window.childPage = window.open(
        API.baseURL +
          "/social/" +
          provider +
          (this.isAuthenticated
            ? "?_clientId=1&_bearer=" + localStorage.getItem("user-token")
            : ""),
        provider,
        "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=" +
          w +
          ",left=" +
          left +
          ",height=" +
          h +
          ",top=" +
          top
      );
      window.removeEventListener("message", this.receiveMessage);
      window.addEventListener("message", this.receiveMessage);
      window.popupInterval = setInterval(function () {
        try {
          window.childPage.postMessage({ message: "requestResult" }, "*");
        } catch (e) {
          if (window.childPage.closed) {
            clearInterval(window.popupInterval);
            alert("closed");
            return;
          }
        }
      }, 500);
      if (window.childPage.closed) {
        clearInterval(window.popupInterval);
        alert("closed");
        return;
      }
    },
    receiveMessage(ev) {
      // console.log("ev.data", ev.data);
      if (window.popupInterval != undefined) {
        clearInterval(window.popupInterval);
      }
      if (ev.data.accessToken != undefined) {
        localStorage.setItem("user-token", ev.data.accessToken.access_token);
        axios.defaults.headers.common["Authorization"] =
          ev.data.accessToken.access_token;
        this.$store.commit(AUTH_SUCCESS, {
          data: { access_token: ev.data.accessToken.access_token },
        });
        this.$store.dispatch(USER_REQUEST);
        this.showLogin = false;
      } else if (ev.data.result === true) {
        let provider = ev.data.provider;
        this.$store.dispatch(USER_REQUEST);
        bus.$emit("success", {
          title: "Success",
          message: provider + " has been connected successfully.",
        });
      }
      window.childPage.close();
    },
    popupCallback(str) {
      // console.log("popupCallback", str);
    },
    openModal(type) {
      this.modalStep = 1;
      this.modalType = type;
      this.showLogin = true;
    },
    updateCachedData(data) {
      // console.log(this.currentRouteName, data);
      this.cachedData[this.currentRouteName] = data;
    },
    play(item, setItems) {
      if (item.realId === this.currentId) {
        if (this.playerStatus === "play") {
          bus.$emit("playerPause");
        } else {
          bus.$emit("playerPlay");
        }
        return;
      }

      const getCurrentPlaylistIds = this.$store.getters.getPlaylistItemsIds;
      if (
        item.tracks === undefined &&
        getCurrentPlaylistIds.length &&
        getCurrentPlaylistIds.indexOf(item.realId) > -1
      ) {
        this.$store.dispatch("setCurrent", item);
      }
      let items = [];
      if (setItems === undefined) {
        items = [item];
      } else {
        items = setItems;
      }

      let sI = null,
        sP = null;
      let cnt = 20;
      if (item.tracks != undefined && item.tracks.length) {
        sI = item.tracks[0];
        sP = item.tracks;
      } else {
        let realItems = [];
        items = items.filter((e) => {
          return ["SND", "VID"].indexOf(e.postType) > -1;
        });
        let itemsIds = this.$_.pluck(items, "realId");
        let itemIndex = itemsIds.indexOf(item.realId);
        for (let i = itemIndex; i < items.length; i++) {
          realItems.push(items[i]);
          if (realItems.length >= cnt) break;
        }
        if (realItems.length < cnt) {
          for (let i = itemIndex - 1; i >= 0; i--) {
            realItems.unshift(items[i]);
            if (realItems.length >= cnt) break;
          }
        }
        sI = item;
        sP = realItems;
      }
      this.$store.dispatch("setCurrent", sI);
      setTimeout(() => {
        this.$store.dispatch("setPlaylist", sP);
      }, 300);
    },
    login: function () {
      this.$store
        .dispatch(AUTH_REQUEST, this.loginForm)
        .then((res) => {
          this.showLogin = false;
        })
        .catch((res) => {
          let msg = "";
          if (res.message === "Network Error") {
            msg = "Connection lost";
          } else {
            switch (res.response.status) {
              case 400:
                msg = this.$parent.$options.filters.lang('fill_all_fields');
                break;
              case 401:
                msg = msg = this.$parent.$options.filters.lang('invalid_credentials');
                break;
            }
          }

          this.$toast.warning(msg, "", {
            balloon: false,
            position: "topCenter",
            timeout: 5000,
            width: 1000,
          });
        });
    },
    logout() {
      this.$store.dispatch(AUTH_LOGOUT);
    },
    clearNextUpItems() {
      this.nextUpItems = [];
    },
    toggleMenu() {
      this.options.showMenu = !this.options.showMenu;
    },
    reCheckSticky() {
      let placeholders = document.querySelectorAll(".uk-sticky-placeholder");
      if (placeholders != null && placeholders.length) {
        for (let i = 0; i < placeholders.length; i++) {
          let instance = placeholders[i];
          let mainElement = instance.previousSibling;
          instance.style.height =
            mainElement.getBoundingClientRect().height + "px";
        }
      }
    },
    onResize() {
      this.isMobile = window.innerWidth < 640;
      this.reCheckSticky();
      this.screenWidth = document.body.clientWidth;
    },
    getSuggestion() {
      if (window.searchSuggestion === undefined) {
        axios.get(API.searchSuggestion).then((res) => {
          window.searchSuggestion = true;
          this.searchSuggestions = res.data;
        });
      }
    },
    focusSearch(type) {
      this.getSuggestion();
      if (type) {
        this.showSearch = true;
        setTimeout(() => {
          this.$refs.desktopSearch.focus();
        }, 100);
      }
      //        } else if (this.search === '' && !Object.keys(this.searchResults).length) {
      //          this.showSearch = false
      //        }
    },
    getSettings() {
      let vm = this;
      this.$store.dispatch(SETTINGS_REQUEST).then(() => {
        this.$store.commit(USER_PLAYER_MESSAGING);
      });
      bus.$on("settingsGetted", () => {
        vm.settingsLoaded = true;
      });
    },
  },
  beforeDestroy() {
    if (typeof window !== "undefined") {
      window.removeEventListener("resize", this.onResize, { passive: true });
    }
  },
  mounted: function () {
    //	<meta name="keywords" content="">
    this.setMetaTags([
      {
        name: "title",
        innerHTML: "VOWAVE - Sound &amp; Video Sharing Service",
      },
      {
        name: "meta",
        where: {
          key: "name",
          value: "keywords",
        },
        attributes: [
          {
            key: "name",
            value: "keywords",
          },
          {
            key: "content",
            value:
              "Free Music Upload, Video Upload, Free Music, Unlimited Space, Soundcloud alternative, Mixcloud Alternative",
          },
        ],
      },
      {
        name: "meta",
        where: {
          key: "property",
          value: "og:title",
        },
        attributes: [
          {
            key: "property",
            value: "og:title",
          },
          {
            key: "content",
            value: "VOWAVE - Sound & Video Sharing Service",
          },
        ],
      },
      {
        name: "meta",
        where: {
          key: "property",
          value: "og:description",
        },
        attributes: [
          {
            key: "property",
            value: "og:description",
          },
          {
            key: "content",
            value:
              "Start sharing your music & videos and start getting revenue from them. VOWAVE gives unlimited space, to all users. You Share, We Care.",
          },
        ],
      },
      {
        name: "link",
        where: {
          key: "rel",
          value: "image_src",
        },
        attributes: [
          {
            key: "rel",
            value: "image_src",
          },
          {
            key: "href",
            value: "https://www.vowave.com/assets/images/vowave-com.jpg",
          },
        ],
      },
      {
        name: "meta",
        where: {
          key: "property",
          value: "og:image",
        },
        attributes: [
          {
            key: "property",
            value: "og:image",
          },
          {
            key: "content",
            value: "https://www.vowave.com/assets/images/vowave-com.jpg",
          },
        ],
      },
      {
        name: "meta",
        where: {
          key: "name",
          value: "description",
        },
        attributes: [
          {
            key: "name",
            value: "description",
          },
          {
            key: "content",
            value:
              "Start sharing your music &amp; videos and start getting revenue from them. VOWAVE gives unlimited space, to all users. You Share, We Care.",
          },
        ],
      },
    ]);
    bus.$on("setTag", (tags) => {
      this.setMetaTags(tags);
    });
    localStorage.setItem("live_stats_available", "0");
    this.screenWidth = document.body.clientWidth;
    this.checkUrl();
    let vm = this;

    // firebase
    // const firebaseConfig = {
    //     apiKey: "AIzaSyCjuPH01q2ytKAWJRuVnBhxIApR_uvYyso",
    //     authDomain: "vowave-16ccd.firebaseapp.com",
    //     databaseURL: "https://vowave-16ccd.firebaseio.com",
    //     projectId: "vowave-16ccd",
    //     storageBucket: "vowave-16ccd.appspot.com",
    //     messagingSenderId: "502097526569",
    //     appId: "1:502097526569:web:010ad06f1dd9d3a8e1c138",
    //     measurementId: "G-7Q301WRV71"
    // };
    // const app = initializeApp(firebaseConfig);
    // const database = getDatabase(app);
    // window.db = database
    // console.log('database', database)
    // const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1aWQiOiIxIiwiaWF0IjoiMTY1NjEwMTM4NSIsImlzcyI6ImZpcmViYXNlLWFkbWluc2RrLWR2MW0xQHZvd2F2ZS0xNmNjZC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsImF1ZCI6Imh0dHBzOlwvXC9pZGVudGl0eXRvb2xraXQuZ29vZ2xlYXBpcy5jb21cL2dvb2dsZS5pZGVudGl0eS5pZGVudGl0eXRvb2xraXQudjEuSWRlbnRpdHlUb29sa2l0IiwiZXhwIjoiMTY1NjEwNDk4NSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLWR2MW0xQHZvd2F2ZS0xNmNjZC5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSJ9.uT_fgxnr4KQhmUNsFBE39FS3Ky6NIwIsFuQsVK_Xk8n0m9jZkLk7UBVrxcXs1RXJ0YcXlW8Ih4EJxkDPGauG-PE2MjHxx2BWbVDsR2HQzxcvFj5ObSwiJ8BUNB-HAU8SBpQpIx_4VgfFHWxu5lZ3IAN89-0WkQ4f4HwumI84lKJtP6cgg-6k-gWxaIkvacNffeLmv25Ld7OC5r1Gj6sVwgewX-6NFnK3Y4TW6DjQneNGj75HMZNcpuStUmKKIjrbIVEiOkG3YoRpkLT88x89NPB98fiA8F3yylkcy4t_eUP323tbBzIXZTR512R87vPr02xGiILZO1ENNRxuS1kmjg'
    // const auth = getAuth();
    // signInWithCustomToken(auth, token)
    // .then((userCredential) => {
    //     // Signed in
    //     const user = userCredential.user;
    //     console.log({userCredential})
    //     // ...
    // })
    // .catch((error) => {
    //     console.log({error})
    //     const errorCode = error.code;
    //     const errorMessage = error.message;
    //     // ...
    // });

    // vm.player = new Player(vm.$refs.canvas);
    // vm.playerControls.Player = vm.player;
    //      if(window.viewerInterval!=undefined) clearInterval(window.viewerInterval)
    //      window.viewerInterval = setInterval(function () {
    //        (async () => {
    //          if(document.querySelector('.viewer')!==null) {
    //            let targetElement = document.querySelector('.viewer')
    //            let entry = await elementInViewport(targetElement, 0)
    //            console.log(`Intersection in Viewport Detected at IntersectionRatio ${entry.intersectionRatio}`)
    //          }
    //        })()
    //      }, 200)
    bus.$on("routerPush", (url) => {
      this.$router.push(url);
    });
    bus.$on('confirmReq', (args) => {
      args.show = true
      this.modals.confirm = {...args}
    })
    bus.$on("showLogin", () => {
      if (!this.showLogin) {
        this.openModal("login");
      }
    });
    bus.$on("topUpBalance", () => {
      if (this.getProfile.id === undefined) {
        this.openModal("login");
        return;
      }
      this.modals.topUpBalance.show = true;
    });
    bus.$on("promotedPostModal", (data) => {
      if (this.getProfile.id === undefined) {
        this.openModal("login");
        return;
      }
      this.modals.promotedPost.show = true;
      this.modals.promotedPost.data = data;
    });
    bus.$on("ReportModal", (data) => {
      if (this.getProfile.id === undefined) {
        this.openModal("login");
        return;
      }
      this.modals.report.show = true;
      this.modals.report.data = data;
    });
    bus.$on("buyPostModal", (data) => {
      if (this.getProfile.id === undefined) {
        this.openModal("login");
        return;
      }
      this.modals.buyPost.show = true;
      this.modals.buyPost.data = data;
    });
    bus.$on("promotedUserModal", () => {
      if (this.getProfile.id === undefined) {
        this.openModal("login");
        return;
      }
      this.modals.promotedUser.show = true;
    });
    bus.$on("likesModal", (data) => {
      this.modals.likes.data = data;
      this.modals.likes.show = true;
    });
    bus.$on("closeSearch", () => {
      this.showSearch = false;
      this.search = "";
    });
    bus.$on("socialConnect", (provider) => {
      this.socialConnect(provider);
    });
    bus.$on("play", (item, playlist) => {
      this.play(item, playlist);
    });
    bus.$on("autoInitializePlayer", () => {
      this.autoInitializePlayer = true;
      localStorage.setItem("autoInitializePlayer", Math.random() * 1000);
    });
    this.onResize();
    window.addEventListener("resize", this.onResize);
  },
};
</script>
