var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          { staticClass: "more-menu-bg", on: { click: _vm.clickOnBg } },
          [
            _vm.show
              ? _c(
                  "div",
                  {
                    staticClass: "more-menu-container",
                    class: this.class,
                    style: _vm.position,
                    attrs: { data: _vm.data },
                  },
                  [
                    _vm.item.username != undefined
                      ? _c(
                          "div",
                          {
                            ref: "tdiv",
                            style: {
                              height: _vm.tdivHeight,
                              width: _vm.tdivWidth,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "home",
                                staticClass: "home",
                                style: { position: _vm.innerPosition },
                                attrs: { hidden: _vm.currentPage !== "home" },
                              },
                              [
                                _c("div", { attrs: { role: "menu" } }, [
                                  this.auth.id === undefined ||
                                  this.auth.id != this.item.id
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showReportModal()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-flag",
                                            }),
                                            _vm._v(
                                              _vm._s(_vm._f("lang")("reports"))
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.auth.id != undefined &&
                                  (this.auth.id === this.item.id ||
                                    _vm.canChangeProfileRoles.indexOf(
                                      this.auth.role
                                    ) !== -1)
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showProfileModal(
                                                "profile"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "fal fa-image-polaroid",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")("change_avatar")
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.auth.id != undefined &&
                                  (this.auth.id === this.item.id ||
                                    _vm.canChangeProfileRoles.indexOf(
                                      this.auth.role
                                    ) !== -1)
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showProfileModal(
                                                "cover"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-image",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")("change_cover")
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  this.auth.id != undefined &&
                                  this.auth.id === this.item.id
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: { click: _vm.logout },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-sign-out",
                                            }),
                                            _vm._v(
                                              _vm._s(_vm._f("lang")("logout"))
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.files != undefined
                      ? _c(
                          "div",
                          {
                            ref: "tdiv",
                            style: {
                              height: _vm.tdivHeight,
                              width: _vm.tdivWidth,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "home",
                                staticClass: "home",
                                style: { position: _vm.innerPosition },
                                attrs: { hidden: _vm.currentPage !== "home" },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { role: "menu" } },
                                  _vm._l(_vm.item.files, function (file, i) {
                                    return _c(
                                      "button",
                                      {
                                        key: i,
                                        attrs: {
                                          type: "button",
                                          role: "menuitem",
                                          "aria-haspopup": "true",
                                          disabled: !!(
                                            _vm.qualities[file.label].forPro &&
                                            !_vm.auth.is.pro
                                          ),
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.doDownload(file.url)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass: "fal fa-circle",
                                          }),
                                          _vm._v(
                                            " " +
                                              _vm._s(file.label) +
                                              "\n                "
                                          ),
                                          _vm.qualities[file.label].forPro
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                  - For Pro"
                                                ),
                                              ])
                                            : _vm._e(),
                                        ]),
                                      ]
                                    )
                                  }),
                                  0
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.item.username === undefined &&
                    _vm.item.files === undefined
                      ? _c(
                          "div",
                          {
                            ref: "tdiv",
                            style: {
                              height: _vm.tdivHeight,
                              width: _vm.tdivWidth,
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                ref: "home",
                                staticClass: "home",
                                style: { position: _vm.innerPosition },
                                attrs: { hidden: _vm.currentPage !== "home" },
                              },
                              [
                                _c("div", { attrs: { role: "menu" } }, [
                                  _vm.auth != null &&
                                  _vm.item.user.id === _vm.auth.id &&
                                  _vm.item.postType === "SND"
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setPage(
                                                $event,
                                                "album"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass:
                                                "fal fa-album-collection",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")("add_to_album")
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.item.postType === "SND"
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setPage(
                                                $event,
                                                "playlist"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-list-music",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")(
                                                  "add_to_playlist"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.item.postType === "SND" ||
                                  _vm.item.postType === "VID"
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: { click: _vm.likesModal },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-heartbeat",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")(
                                                  "likes_and_reposts"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.auth != null &&
                                  _vm.item.user.id === _vm.auth.id
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: { click: _vm.doEdit },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-edit",
                                            }),
                                            _vm._v(
                                              _vm._s(_vm._f("lang")("edit"))
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.removeFrom.PLY.length
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setPage(
                                                $event,
                                                "removeFromPlaylist"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-layer-minus",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")(
                                                  "remove_from_playlist"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.auth != null &&
                                  ["PLY", "ALB"].indexOf(_vm.item.postType) ===
                                    -1 &&
                                  _vm.item.user.id === _vm.auth.id &&
                                  _vm.removeFrom.ALB.length
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setPage(
                                                $event,
                                                "removeFromAlbum"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-album",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")(
                                                  "remove_from_album"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.auth != null &&
                                  _vm.item.user.id === _vm.auth.id
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletePost()
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-trash-alt",
                                            }),
                                            _vm._v(
                                              _vm._s(_vm._f("lang")("delete"))
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.auth != null &&
                                  _vm.item.user.id === _vm.auth.id
                                    ? _c(
                                        "button",
                                        {
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: { click: _vm.promotePost },
                                        },
                                        [
                                          _c("span", [
                                            _c("i", {
                                              staticClass: "fal fa-rocket",
                                            }),
                                            _vm._v(
                                              _vm._s(
                                                _vm._f("lang")(
                                                  "promote_this_post"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      attrs: {
                                        type: "button",
                                        role: "menuitem",
                                        "aria-haspopup": "true",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.showReportModal("post")
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _c("i", { staticClass: "fal fa-flag" }),
                                        _vm._v(
                                          _vm._s(_vm._f("lang")("report"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "album",
                                staticClass: "album",
                                style: { position: _vm.innerPosition },
                                attrs: { hidden: _vm.currentPage !== "album" },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { role: "menu" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "back-menu-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setPage($event, "home")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-long-arrow-left",
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm._f("lang")("back"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "create-button",
                                        attrs: {
                                          type: "button",
                                          role: "menuitem",
                                          "aria-haspopup": "true",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setPage(
                                              $event,
                                              "newAlbum"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-album-collection",
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("lang")("create_new_album")
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.sets.ALB, function (album, i) {
                                      return _c(
                                        "button",
                                        {
                                          key: i,
                                          attrs: {
                                            type: "button",
                                            role: "menuitem",
                                            "aria-haspopup": "true",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addToSet(album, "ALB")
                                            },
                                          },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(_vm._s(album.title)),
                                          ]),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "newAlbum",
                                staticClass: "new-album",
                                style: { position: _vm.innerPosition },
                                attrs: {
                                  hidden: _vm.currentPage !== "newAlbum",
                                },
                              },
                              [
                                _c("div", { attrs: { role: "menu" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "back-menu-item",
                                      on: {
                                        click: function ($event) {
                                          return _vm.setPage($event, "album")
                                        },
                                      },
                                    },
                                    [
                                      _c("span", [
                                        _c("i", {
                                          staticClass: "fal fa-long-arrow-left",
                                        }),
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm._f("lang")("back"))
                                        ),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.album.title,
                                          expression: "album.title",
                                        },
                                      ],
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          _vm._f("lang")("album_name"),
                                      },
                                      domProps: { value: _vm.album.title },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.album,
                                            "title",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "uk-text-right" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "uk-button uk-button-default button-top-menu button-bg",
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveSet("ALB")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm._f("lang")("save")) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "playlist",
                                staticClass: "playlist",
                                style: { position: _vm.innerPosition },
                                attrs: {
                                  hidden: _vm.currentPage !== "playlist",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { role: "menu" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "back-menu-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setPage($event, "home")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-long-arrow-left",
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm._f("lang")("back"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "button",
                                      {
                                        staticClass: "create-button",
                                        attrs: {
                                          type: "button",
                                          role: "menuitem",
                                          "aria-haspopup": "true",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setPage(
                                              $event,
                                              "newPlaylist"
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass: "fal fa-list-music",
                                          }),
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("lang")(
                                                "create_new_playlist"
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.sets.PLY,
                                      function (playlist, i) {
                                        return _vm.addTo.PLY.indexOf(
                                          playlist.realId
                                        ) !== -1
                                          ? _c(
                                              "button",
                                              {
                                                key: i,
                                                attrs: {
                                                  type: "button",
                                                  role: "menuitem",
                                                  "aria-haspopup": "true",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addToSet(
                                                      playlist,
                                                      "PLY"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(playlist.title)
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "newPlaylist",
                                staticClass: "new-playlist",
                                style: { position: _vm.innerPosition },
                                attrs: {
                                  hidden: _vm.currentPage !== "newPlaylist",
                                },
                              },
                              [
                                _c("div", { attrs: { role: "menu" } }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "uk-flex uk-flex-middle",
                                      attrs: { "uk-grid": "" },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "uk-width-expand" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "back-menu-item",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setPage(
                                                    $event,
                                                    "playlist"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _c("i", {
                                                  staticClass:
                                                    "fal fa-long-arrow-left",
                                                }),
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("lang")("back")
                                                    )
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "uk-width-auto" },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass:
                                                "switch-title f-s-075",
                                              on: {
                                                click: function ($event) {
                                                  _vm.playlist.public =
                                                    !_vm.playlist.public
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm._f("lang")("public")
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("p-check", {
                                            ref: "playlist-public",
                                            staticClass: "p-switch p-fill",
                                            attrs: { color: "success" },
                                            model: {
                                              value: _vm.playlist.public,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.playlist,
                                                  "public",
                                                  $$v
                                                )
                                              },
                                              expression: "playlist.public",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.playlist.title,
                                          expression: "playlist.title",
                                        },
                                      ],
                                      attrs: {
                                        type: "text",
                                        placeholder:
                                          _vm._f("lang")("playlist_name"),
                                      },
                                      domProps: { value: _vm.playlist.title },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.playlist,
                                            "title",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _vm.moods.length
                                    ? _c("div", [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "uk-grid-none",
                                            attrs: { "uk-grid": "" },
                                          },
                                          _vm._l(_vm.moods, function (mood, i) {
                                            return _c(
                                              "div",
                                              {
                                                key: i,
                                                staticClass: "uk-width-1-4",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "mood",
                                                    class: {
                                                      active:
                                                        _vm.playlist.moods.indexOf(
                                                          mood.id
                                                        ) !== -1,
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.selectMood(
                                                          mood
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      { staticClass: "img" },
                                                      [
                                                        _c("img", {
                                                          attrs: {
                                                            src: _vm._f(
                                                              "attach"
                                                            )(
                                                              mood.attach,
                                                              "xs",
                                                              "assets"
                                                            ),
                                                          },
                                                        }),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "uk-text-truncate f-s-080",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                      " +
                                                            _vm._s(mood.name) +
                                                            "\n                    "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            )
                                          }),
                                          0
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "uk-text-right" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass:
                                          "uk-button uk-button-default button-top-menu button-bg",
                                        attrs: {
                                          disabled: _vm.playlist.title === "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveSet("PLY")
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm._f("lang")("save")) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "removeFromPlaylist",
                                staticClass: "remove-from-playlist",
                                style: { position: _vm.innerPosition },
                                attrs: {
                                  hidden:
                                    _vm.currentPage !== "removeFromPlaylist",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { role: "menu" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "back-menu-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setPage($event, "home")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-long-arrow-left",
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm._f("lang")("back"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.sets.PLY,
                                      function (playlist, index) {
                                        return _vm.removeFrom.PLY.indexOf(
                                          playlist.realId
                                        ) !== -1
                                          ? _c(
                                              "button",
                                              {
                                                key: index,
                                                attrs: {
                                                  type: "button",
                                                  role: "menuitem",
                                                  "aria-haspopup": "true",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.removeFromSet(
                                                      playlist,
                                                      "PLY"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(playlist.title)
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _vm._e()
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                ref: "removeFromAlbum",
                                staticClass: "remove-from-album",
                                style: { position: _vm.innerPosition },
                                attrs: {
                                  hidden: _vm.currentPage !== "removeFromAlbum",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { attrs: { role: "menu" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "back-menu-item",
                                        on: {
                                          click: function ($event) {
                                            return _vm.setPage($event, "home")
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("i", {
                                            staticClass:
                                              "fal fa-long-arrow-left",
                                          }),
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(_vm._f("lang")("back"))
                                          ),
                                        ]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(_vm.sets.ALB, function (album, i) {
                                      return _vm.removeFrom.ALB.indexOf(
                                        album.realId
                                      ) !== -1
                                        ? _c(
                                            "button",
                                            {
                                              key: i,
                                              attrs: {
                                                type: "button",
                                                role: "menuitem",
                                                "aria-haspopup": "true",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removeFromSet(
                                                    album,
                                                    "ALB"
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("span", [
                                                _vm._v(_vm._s(album.title)),
                                              ]),
                                            ]
                                          )
                                        : _vm._e()
                                    }),
                                  ],
                                  2
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", {
                      ref: "triangle",
                      staticClass: "triangle",
                      style: _vm.triangleStyle,
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "modal",
              {
                ref: "confirmModal",
                on: {
                  close: function ($event) {
                    return _vm.closeConfirm()
                  },
                },
                model: {
                  value: _vm.confirmModal,
                  callback: function ($$v) {
                    _vm.confirmModal = $$v
                  },
                  expression: "confirmModal",
                },
              },
              [
                _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                  _vm._v(_vm._s(_vm._f("lang")("delete"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    attrs: { slot: "body" },
                    domProps: { innerHTML: _vm._s(_vm.confirmText) },
                    slot: "body",
                  },
                  [_vm._v(_vm._s(_vm.confirmText))]
                ),
                _vm._v(" "),
                _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "uk-button uk-button-default",
                      on: { click: _vm.deleteAction },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm._f("lang")("delete")) +
                          "\n        "
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }