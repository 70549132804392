<template>
    <transition name="modal" v-if="show">
        <div class="modal-mask" @click="doClose">
            <div class="modal-wrapper stepped">
                <div class="modal-container">
                    <div @click="close" class="png-ico close small">
                    </div>
                    <div class="modal-header">
                        <slot name="header">
                            default header
                        </slot>
                    </div>

                    <div class="modal-body">
                        <slot name="body">
                            default body
                        </slot>
                    </div>

                    <div class="modal-footer">
                        <slot name="footer">
                            default footer
                            <button class="modal-default-button" @click="$emit('close')">
                                OK
                            </button>
                        </slot>
                    </div>
                    <div v-if="background!==''" class="modal-background" :style="{backgroundImage: 'url(' + background + ')'}"></div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
      props: {
        show: {
          type: Boolean,
          default: false
        },
        background: {
          type: String,
          default: ''
        },
      },
      watch: {
        value: function (newVal) {
          if(newVal) {
            document.body.setAttribute('data-modal', '1')
          } else {
            document.body.removeAttribute('data-modal')
          }
        }
      },
      methods: {
        doClose(e) {
          if(e.target.className==="modal-wrapper") {
            this.close()
          }
        },
        close() {
          this.show = false
          this.$emit('close')
        }
      }
    }
</script>