var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.vars.realId != null
    ? _c(
        "div",
        { ref: "soundTabs", staticClass: "widget" },
        [
          _c("div", { staticClass: "post-tabs-container" }, [
            _c(
              "div",
              { staticClass: "center-tabs" },
              _vm._l(_vm.tabs, function (tab, index) {
                return _c(
                  "a",
                  {
                    key: "tab_" + index,
                    class: { selected: _vm.currentTab === tab },
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.setTab(index)
                      },
                    },
                  },
                  [
                    tab != "comments"
                      ? _c("span", [
                          _vm._v(_vm._s(_vm._f("ucfirst")(_vm.tabnames[tab]))),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    tab === "comments"
                      ? _c("span", [
                          _c("i", { staticClass: "fal fa-comment-alt-lines" }),
                          _vm._v(
                            _vm._s(
                              _vm.vars.commentsCnt === "0"
                                ? ""
                                : "  " + _vm.vars.commentsCnt
                            )
                          ),
                        ])
                      : _vm._e(),
                  ]
                )
              }),
              0
            ),
            _vm._v(" "),
            _c("div", { staticClass: "center-tabs-contents" }, [
              _vm.currentTab === "info"
                ? _c("div", [
                    _c("div", { staticClass: "info-tab-box f-s-085" }, [
                      _c("div", { staticClass: "info-tab-box-item" }, [
                        _c("span", { staticClass: "tit" }, [
                          _vm._v("Release Type:"),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm.settings.releaseTypes[_vm.vars.releaseType]
                            ) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.vars.languages.length
                        ? _c(
                            "div",
                            { staticClass: "info-tab-box-item" },
                            [
                              _c("span", { staticClass: "tit" }, [
                                _vm._v("Language(s):"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.vars.languages,
                                function (language, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _vm._v(_vm._s(index !== 0 ? ", " : "")),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/explore/" +
                                              _vm.postTypeFull +
                                              "/?languages=" +
                                              language.id,
                                          },
                                        },
                                        [_vm._v(_vm._s(language.name))]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.vars.recordLabels.length
                        ? _c(
                            "div",
                            { staticClass: "info-tab-box-item" },
                            [
                              _c("span", { staticClass: "tit" }, [
                                _vm._v("RecordLabel(s):"),
                              ]),
                              _vm._v(" "),
                              _vm._l(
                                _vm.vars.recordLabels,
                                function (label, index) {
                                  return _c(
                                    "span",
                                    { key: index },
                                    [
                                      _vm._v(_vm._s(index !== 0 ? ", " : "")),
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/explore/" +
                                              _vm.postTypeFull +
                                              "/?recordlabels=" +
                                              label.id,
                                          },
                                        },
                                        [_vm._v(_vm._s(label.name))]
                                      ),
                                    ],
                                    1
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.vars.tags.length
                        ? _c(
                            "div",
                            { staticClass: "info-tab-box-item" },
                            [
                              _c("span", { staticClass: "tit" }, [
                                _vm._v("Tag(s):"),
                              ]),
                              _vm._v(" "),
                              _vm._l(_vm.vars.tags, function (tag, index) {
                                return _c(
                                  "span",
                                  { key: index },
                                  [
                                    _vm._v(_vm._s(index !== 0 ? ", " : "")),
                                    _c(
                                      "router-link",
                                      {
                                        attrs: {
                                          to:
                                            "/explore/" +
                                            _vm.postTypeFull +
                                            "/?tags=" +
                                            tag.slug,
                                        },
                                      },
                                      [_vm._v(_vm._s(tag.name))]
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("div", { staticClass: "info-tab-box-item" }, [
                        _c("span", { staticClass: "tit" }, [
                          _vm._v("Release Date:"),
                        ]),
                        _vm._v(
                          "\n            " +
                            _vm._s(_vm.releaseDate) +
                            "\n          "
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.vars.licenseType != null
                        ? _c("div", { staticClass: "info-tab-box-item" }, [
                            _c("span", { staticClass: "tit" }, [
                              _vm._v("License:"),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$parent.settings.licenseTypes[
                                    _vm.vars.licenseType
                                  ]
                                ) +
                                "\n          "
                            ),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "post-description f-s-085" }, [
                      _c(
                        "div",
                        {
                          staticClass: "uk-grid-10 uk-flex uk-flex-middle",
                          attrs: { "uk-grid": "" },
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "is-visibility",
                                  rawName: "v-is-visibility",
                                  value: (visible) =>
                                    _vm.visibleDescription(visible),
                                  expression:
                                    "(visible) => visibleDescription(visible)",
                                },
                              ],
                              staticClass: "uk-width-expand",
                            },
                            [
                              _vm.vars.description === null
                                ? _c(
                                    "div",
                                    [
                                      _c("text-skeleton", {
                                        attrs: {
                                          width: "200px",
                                          height: "10px",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("text-skeleton", {
                                        style: { marginTop: "5px" },
                                        attrs: {
                                          width: "120px",
                                          height: "10px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.vars.description === ""
                                ? _c("div", [_vm._v("No description!")])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "whitespace-pre",
                                domProps: {
                                  innerHTML: _vm._s(_vm.vars.description),
                                },
                              }),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTab === "lyrics"
                ? _c("div", [
                    _vm.vars.lyrics === null
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "is-visibility",
                                rawName: "v-is-visibility",
                                value: (visible) =>
                                  _vm.visibleDescription(visible),
                                expression:
                                  "(visible) => visibleDescription(visible)",
                              },
                            ],
                            staticClass: "uk-text-center info-tab-box",
                          },
                          _vm._l(_vm.lyricsSkeleton, function (index) {
                            return _c("text-skeleton-shimmer", {
                              key: index,
                              style: { margin: "10px auto" },
                              attrs: {
                                width: _vm.generateRandomWidth() + "%",
                                height: "10px",
                              },
                            })
                          }),
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.vars.lyrics === ""
                      ? _c(
                          "div",
                          { staticClass: "f-s-085 uk-margin-small-top" },
                          [_vm._m(1)]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.vars.lyrics !== null && _vm.vars.lyrics !== ""
                      ? _c(
                          "div",
                          { staticClass: "info-tab-box f-s-090 lyrics" },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.vars.lyrics) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTab === "comments"
                ? _c(
                    "div",
                    { staticClass: "f-s-085" },
                    [
                      _c("Comments", {
                        attrs: {
                          "can-comment": _vm.vars.canComment,
                          "comments-cnt": _vm.vars.commentsCnt,
                          "real-comments-cnt": _vm.vars.mainCommentsCnt,
                          "real-id": _vm.vars.realId,
                        },
                        on: { cmAdded: _vm.cmAdded },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.currentTab === "tracks"
                ? _c("div", { staticClass: "f-s-085" }, [
                    _vm.auth.id === _vm.vars.userId
                      ? _c(
                          "div",
                          { ref: "manageMode", staticClass: "manage-playlist" },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-text-right uk-position-relative",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "manage-playlist-btn",
                                    class: { active: _vm.manageMode },
                                    on: {
                                      click: function ($event) {
                                        _vm.manageMode = !_vm.manageMode
                                      },
                                    },
                                  },
                                  [
                                    !_vm.manageMode
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("manage"))
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.manageMode
                                      ? _c("span", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("save"))
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "manage-playlist-btn other-opts btn-a",
                                    class: { active: _vm.manageMode },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteSet(0)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm._f("lang")("delete_album")) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "button",
                                  {
                                    staticClass:
                                      "manage-playlist-btn other-opts btn-b",
                                    class: { active: _vm.manageMode },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteSet(1)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("lang")(
                                            "delete_album_with_tracks"
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.manageMode
                      ? _c("div", { staticClass: "uk-margin" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-grid-10 uk-flex align-items-center",
                            },
                            [
                              _c("div", { staticClass: "uk-width-auto" }, [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select-image-full-parent",
                                      on: {
                                        click: function ($event) {
                                          _vm.modals.cover.show = true
                                        },
                                      },
                                    },
                                    [
                                      _c("image-placeholder", {
                                        attrs: {
                                          width: "150px",
                                          height: "150px",
                                          "data-src": _vm.vars.setCover,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _vm._m(2),
                                    ],
                                    1
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "uk-width-expand" }, [
                                _c("div", { staticClass: "uk-margin-small" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.vars.setTitle,
                                        expression: "vars.setTitle",
                                      },
                                    ],
                                    staticClass: "uk-input",
                                    attrs: {
                                      type: "text",
                                      placeholder: "Title",
                                    },
                                    domProps: { value: _vm.vars.setTitle },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.vars,
                                          "setTitle",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", {}, [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.vars.setDescription,
                                        expression: "vars.setDescription",
                                      },
                                    ],
                                    staticClass: "uk-textarea",
                                    attrs: {
                                      type: "text",
                                      rows: "4",
                                      placeholder: "Description",
                                    },
                                    domProps: {
                                      value: _vm.vars.setDescription,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.vars,
                                          "setDescription",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.items.length
                      ? _c(
                          "div",
                          {
                            staticClass: "v-post-items display-track-num",
                            class: { "manage-mode": _vm.manageMode },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "v-post-items-container" },
                              [
                                _vm.rItems
                                  ? _c("Items", {
                                      attrs: {
                                        items: _vm.items,
                                        type: "VPostItem",
                                        enableSort: _vm.manageMode,
                                        sort: _vm.sort,
                                      },
                                      on: {
                                        updateItems: _vm.updateItems,
                                        doRemoveItemFromSet:
                                          _vm.doRemoveItemFromSet,
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm._v(" "),
          _vm.manageMode
            ? _c("my-upload", {
                ref: "coverUpload",
                attrs: {
                  field: "cover",
                  width: 600,
                  height: 600,
                  fullsize: 1200,
                  method: "POST",
                  url: _vm.modals.cover.url,
                  langType: "en",
                  params: _vm.modals.cover.params,
                  headers: _vm.modals.cover.headers,
                  "no-square": "",
                  "img-format": "jpg",
                },
                on: {
                  "crop-upload-success": function ($event) {
                    return _vm.cropUploadSuccess("cover", $event)
                  },
                  "crop-upload-fail": function ($event) {
                    return _vm.cropUploadFail("cover", $event)
                  },
                },
                model: {
                  value: _vm.modals.cover.show,
                  callback: function ($$v) {
                    _vm.$set(_vm.modals.cover, "show", $$v)
                  },
                  expression: "modals.cover.show",
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-width-auto" }, [
      _c("div", { staticClass: "width-30 uk-text-center" }, [
        _c("i", { staticClass: "fal fa-info" }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "uk-grid-10 uk-flex uk-flex-middle",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "width-30 uk-text-center" }, [
            _c("i", { staticClass: "fal fa-align-center" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand" }, [
          _c("div", [_vm._v("No lyrics found for this post!")]),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "select-image-full" }, [
      _c("i", { staticClass: "fal fa-camera" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }