<template>
    <Modal :background="cover | attach('blur')" @close="close()" :show="show">
        <div slot="header">
            <div class="buy-post-header">
                <div>
                    {{data.title}}
                </div>
                <div class="users" v-if="data.users != undefined && data.users.length">
                    <small>
                        <span>{{data.user.fullname}}</span> <span v-for="(teammate, index) in data.users">, {{teammate.fullname}}</span>
                    </small>
                </div>
            </div>
        </div>
        <div slot="body" class="promote-post-body">
            <div class="uk-text-center">
                <img v-if="data.metas!=undefined && data.metas.cover!=undefined" :src="data.metas.cover | attach('m')"/>
            </div>
            <div v-if="data.metas!=undefined" class="select-payment-method">
                <h5>
                    Pay With Your Account's Balance
                </h5>
                <div class="f-s-085">
                    <label><input class="uk-radio" type="radio" value="dollars" v-model="currency">
                        ${{data.metas.price.dollars}} USD</label>
                </div>
                <div class="f-s-085">
                    <label><input class="uk-radio" type="radio" value="tomans" v-model="currency">
                        {{data.metas.price.tomans | numformat}} Tomans</label>
                </div>
            </div>
        </div>
        <div slot="footer" class="uk-text-center">
            <button @click="buy" class="uk-button button-top-menu button-bg uk-button-default" :disabled="inProcess">
                <span v-if="!inProcess">
                    {{currency === 'tomans' ? 'پرداخت با اعتبار اکانت' : 'Pay Using Your Balance'}}
                </span>
                <span v-if="inProcess">
                    <i uk-spinner="ratio: 0.5"></i>
                </span>
            </button>
        </div>
    </Modal>
</template>
<script>
    import Modal from './Modal'
    import axios from 'axios'

    export default {
        components: {
            Modal
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                currency: '',
                inProcess: false
            }
        },
        methods: {
            buy() {
                this.inProcess = true
                axios.post(API.payment.request, {
                    type: 'buyPost',
                    postId: this.data.id,
                    currency: this.currency
                })
                    .then((res) => {
                        this.inProcess = false
                        this.close()
                        bus.$emit('updatePost_' + this.data.id)
                        let txt = (this.currency === 'tomans') ?
                            ['تبریک', 'خرید شما با موفقیت انجام شد.'] :
                            ['Congratulations', 'Your purchase was successful.']
                        bus.$emit('success', {
                            title: txt[0],
                            message: txt[1]
                        })
                    })
                    .catch((e) => {
                        let errTxt
                        if (e.response.status === 400) {
                            errTxt = (this.currency === 'tomans') ?
                                ['خطا', 'اعتبار حساب شما کافی نیست. لطفا جهت ادامه حساب خود را شارژ کنید.'] :
                                ['Error', 'You have insufficient balance, Please recharge to continue.']
                            bus.$emit('error', {
                                title: errTxt[0],
                                message: errTxt[1]
                            })
                            this.close()
                            setTimeout(() => {
                                bus.$emit('topUpBalance')
                            }, 200)
                        } else {
                            errTxt = (this.currency === 'tomans') ?
                                ['خطا', 'در خرید شما مشکلی به وجود آمده. لطفا مجدد تلاش کنید.'] :
                                ['Error', 'Something went wrong with your purchase. Please try again.']
                            bus.$emit('error', {
                                title: errTxt[0],
                                message: errTxt[1]
                            })
                        }

                        this.inProcess = false
                    })

            },
            close() {
                this.show = false
                this.$emit('close')
            },
            setDefaultCurrency() {
                if (this.auth.geo != undefined) {
                    this.currency = this.auth.geo === 'IR' ? 'tomans' : 'dollars'
                }
            }
        },
        computed: {
            cover() {
                if(this.data.metas!=undefined && this.data.metas.cover!=undefined)
                    return this.data.metas.cover
                return ''
            },
            auth() {
                return this.$store.getters.getProfile
            },
            price() {
                return this.data.metas.price[this.currency]
            }
        },
        watch: {
            auth() {
                if (window.currencySettedBuyPost === undefined) {
                    window.currencySettedBuyPost = true
                    this.setDefaultCurrency()
                }
            }
        },
        mounted() {
            this.setDefaultCurrency()
        }
    }
</script>