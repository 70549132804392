<template>
    <component ref="parent" :is="tagName">
        <component :options="options" :data-id="item.id" v-for="(item, index) in items" :subclass="subclass"
                   :item.sync="item" :index="index"
                   v-bind:is="(
               (item.postType!=undefined&&type==='VPostMedItem'&&item.postType==='VID') ? 'VVideoMedItem' :
                (
                    (item.postType!=undefined&&type==='VPostMedItem'&&(item.postType==='ALB' || item.postType==='PLY')) ? 'VSetMedItem' : type
                )
               )" :key="type + '_' + item.id"
                   :class="{active: (item.realId!=undefined&&(activeItem===item.realId || (item.postType==='SND' && currentTrack!=null && currentTrack==item.realId) || (item.postType==='VID' && currentVideo!=null && currentVideo==item.realId))), deleting: deletings.indexOf(item.id)!==-1}"
                   v-is-visibility="(isVisible, entry) => helper.isVisible(isVisible, entry, item)" @clickMoreMenu="moreMenu" @dop="dop"></component>
    </component>
</template>
<script>
    import SoundSliderItem from './SoundSliderItem'
    import GridPostItem from './GridPostItem'
    import VPostItem from './VPostItem'
    import CircleUserItem from './CircleUserItem'
    import VUserItem from './VUserItem'
    import VPostMedItem from './VPostMedItem'
    import VVideoMedItem from './VVideoMedItem'
    import UserWithoutButtonItem from './UserWithoutButtonItem'
    import VideoSliderItem from "@/components/Items/VideoSliderItem";
    import helper from '../../helpers/helper'
    import Sortable, {AutoScroll} from 'sortablejs/modular/sortable.core.esm.js'
    import ScreenshotItem from './ScreenshotItem'
    import GridUserItem from "@/components/Items/GridUserItem";
    import VSetMedItem from "@/components/Items/VSetMedItem";
    import CapsuleItem from "@/components/Items/CapsuleItem";
    import {SET_CURRENT_VIDEO, SET_VIDEO_STATUS} from "@/store/actions/player";

    export default {
        data() {
            return {
                helper: helper,
                deletings: [],
                dragabled: false
            }
        },
        computed: {
            currentTrack() {
                try {
                    return this.$store.getters.getCurrent.realId != undefined ? this.$store.getters.getCurrent.realId : null
                } catch (e) {

                }
                return null
            },
            currentVideo() {
                try {
                    return this.currentTrack
                } catch (e) {

                }
                return null
            },
            playerStatus() {
                return this.$store.getters.playerStatus
            },
            videoStatus() {
                return this.playerStatus
            },
            activeItem() {
                let ai = this.$store.getters.getActiveItem
                if (ai != null && ai.indexOf(this.type + '_') === 0) {
                    return parseInt(this.$store.getters.getActiveItem.replace(this.type + '_', ''), 10)
                }
                return null
            }
        },
        components: {
            SoundSliderItem,
            GridPostItem,
            VPostItem,
            CircleUserItem,
            VUserItem,
            UserWithoutButtonItem,
            VPostMedItem,
            VVideoMedItem,
            ScreenshotItem,
            VideoSliderItem,
            GridUserItem,
            VSetMedItem,
            CapsuleItem
        },
        watch: {
            enableSort(val) {
                this.initSortable()
            }
        },
        props: {
            tagName: {
                type: String,
                default: 'div' 
            },
            type: {
                type: String
            },
            items: {
                type: Array
            },
            sort: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            enableSort: {
                type: Boolean,
                default: false
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            subclass: {
                type: String,
                default: ''
            }
        },
        methods: {
            dop(e) {
                bus.$emit('play', e, this.items)
            },
            play(item, setItems) {
                if(item.realId === this.currentTrack) {
                    if(this.playerStatus === 'play') {
                        bus.$emit('playerPause')
                    } else {
                        bus.$emit('playerPlay')
                    }
                    return;
                }
                let items = []
                if(setItems===undefined) {
                    items = this.$parent.itemsTmp != undefined ? this.$parent.itemsTmp : this.items;
                } else {
                    items = setItems;
                }

                let cnt = 20
                let sI = null, sP = null;

                if (item.tracks != undefined && item.tracks.length) {
                    sI = item.tracks[0]
                    sP = item.tracks
                } else {
                    let realItems = [];
                    items = items.filter(e => {return ['SND','VID'].indexOf(e.postType)>-1})
                    let itemsIds = this.$_.pluck(items, 'realId');
                    let itemIndex = itemsIds.indexOf(item.realId);
                    for(let i = itemIndex; i<items.length; i++) {
                        realItems.push(items[i]);
                        if(realItems.length>=cnt) break;
                    }
                    if(realItems.length<cnt) {
                        for(let i = itemIndex-1; i>=0; i--) {
                            realItems.unshift(items[i]);
                            if(realItems.length>=cnt) break;
                        }
                    }
                    sI = item
                    sP = realItems
                }
                this.$store.dispatch('setCurrent', sI)
                setTimeout(() => {
                    this.$store.dispatch('setPlaylist', sP)
                }, 300)
            },
            like(item) {
                let res = this.helper.like(item)
                if (res === null) {
                    bus.$emit('showLogin')
                }
            },
            repost(item) {
                let res = this.helper.repost(item)
                if (res === null) {
                    bus.$emit('showLogin')
                }
            },
            follow(user) {
                this.helper.follow(user)
            },
            initSortable() {
                if (this.dragabled === true) return
                this.dragabled = true
                Sortable.mount(new AutoScroll())
                let vm = this
                let el = this.$refs.parent
                let sort = this.sort

                sort.store = {
//          get: function (sortable) {
//            var order = localStorage.getItem(sortable.options.group.name);
//            return order ? order.split('|') : [];
//          },
                    set: function (sortable) {
                        let tmpArr = []
                        var order = sortable.toArray()
                        if (order.length) {
                            for (let i = 0; i < order.length; i++) {
                                for (let o = 0; o < vm.items.length; o++) {
                                    if (vm.items[o].id === parseInt(order[i], 10)) {
                                        tmpArr.push(vm.items[o])
                                        break
                                    }
                                }
                            }
                        }
                        vm.$emit('updateItems', tmpArr)
                    }
                }
                var sortable = new Sortable(el, sort)
            },
            clickOnItem(item) {
            },
            moreMenu(item, e) {
                bus.$emit('clickMoreMenu', item, e, this.type)
            },
            closeSearch() {
                bus.$emit('closeSearch')
            },
        },
        mounted() {
//      bus.$on('activeState', (res) => {
//        this.activeItem = null
//        if (res.indexOf(this.type + '_') !== -1) {
//          this.activeItem = parseInt(res.split('_')[1], 10)
//        }
//      })
//      bus.$on('outSideMoremenuClicked', () => {
//        this.activeItem = null
//      })
            bus.$on('deletePost', (id) => {
                let ids = this.$_.pluck(this.items, 'id')
                let index = ids.indexOf(id)
                if (index !== -1) {
                    this.items.splice(index, 1)
//          this.$parent.$emit('refreshItems', this.items)
                }
            })
            bus.$on('deletingPost', (id) => {
                let index = this.deletings.indexOf(id)
                if (index === -1) {
                    this.deletings.push(id)
                }
            })
            bus.$on('disableDeletingPost', (id) => {
                let index = this.deletings.indexOf(id)
                if (index !== -1) {
                    this.deletings.splice(index, 1)
                }
            })
        }
    }
</script>
