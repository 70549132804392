var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mainContainer",
      staticClass:
        "widget padding-x uk-margin-small-top contact-us-widget settings",
    },
    [
      _c("div", [
        _vm._m(0),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content user-slider-widget" }, [
          _c("div", [
            _vm.auth.id === undefined
              ? _c("div", { staticClass: "setting" }, [
                  _c("div", { staticClass: "uk-grid" }, [
                    _c("div", { staticClass: "uk-width-expand" }, [
                      _c(
                        "label",
                        { class: _vm.labelClass, attrs: { for: "name" } },
                        [_vm._v("Name")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-auto" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.name,
                            expression: "form.name",
                          },
                        ],
                        staticClass: "settings-input",
                        attrs: { type: "text", id: "name", placeholder: "" },
                        domProps: { value: _vm.form.name },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "name", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.auth.id === undefined
              ? _c("div", { staticClass: "setting" }, [
                  _c("div", { staticClass: "uk-grid" }, [
                    _c("div", { staticClass: "uk-width-expand" }, [
                      _c(
                        "label",
                        { class: _vm.labelClass, attrs: { for: "email" } },
                        [_vm._v("E-Mail")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-auto" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.form.email,
                            expression: "form.email",
                          },
                        ],
                        staticClass: "settings-input",
                        attrs: { type: "email", id: "email", placeholder: "" },
                        domProps: { value: _vm.form.email },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(_vm.form, "email", $event.target.value)
                          },
                        },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "setting" }, [
              _c("div", { staticClass: "uk-grid" }, [
                _c("div", { staticClass: "uk-width-expand" }, [
                  _c(
                    "label",
                    { class: _vm.labelClass, attrs: { for: "department" } },
                    [_vm._v("Department")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-auto" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.form.department,
                          expression: "form.department",
                        },
                      ],
                      staticClass: "settings-input",
                      attrs: { id: "department" },
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.form,
                            "department",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    _vm._l(_vm.departments, function (department) {
                      return _c(
                        "option",
                        {
                          directives: [
                            {
                              name: "key",
                              rawName: "v-key",
                              value: department.id,
                              expression: "department.id",
                            },
                          ],
                          domProps: { value: department.id },
                        },
                        [
                          _vm._v(
                            "\n                                    " +
                              _vm._s(department.name) +
                              "\n                                "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "setting" }, [
              _c("div", { staticClass: "uk-grid" }, [
                _c("div", { staticClass: "uk-width-expand" }, [
                  _c(
                    "label",
                    { class: _vm.labelClass, attrs: { for: "subject" } },
                    [_vm._v("Subject")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-auto" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.subject,
                        expression: "form.subject",
                      },
                    ],
                    staticClass: "settings-input",
                    attrs: { id: "subject", type: "text" },
                    domProps: { value: _vm.form.subject },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "subject", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "setting" },
              [
                _c("drag-and-drop", {
                  attrs: { "upload-url": _vm.uploadUri },
                  on: { "upload-complete": _vm.complete },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "setting" }, [
              _c("div", { staticClass: "uk-grid" }, [
                _c("div", { staticClass: "uk-width-expand" }, [
                  _c(
                    "label",
                    { class: _vm.labelClass, attrs: { for: "description" } },
                    [_vm._v("Description")]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-auto" }, [
                  _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.form.description,
                        expression: "form.description",
                      },
                    ],
                    staticClass: "settings-input",
                    attrs: {
                      id: "description",
                      rows: "5",
                      placeholder: "Description",
                    },
                    domProps: { value: _vm.form.description },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.form, "description", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "setting display-flex" },
              [
                _c("PButton", {
                  staticClass: "flex-right",
                  attrs: { text: "Save", "in-process": _vm.inProcess },
                  on: { clicked: _vm.save },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "widget-title" }, [
      _c("h4", { staticClass: "f-s-13" }, [_vm._v("Contact Us")]),
      _vm._v(" "),
      _c("h6", { staticClass: "f-s-090" }, [_vm._v("contact")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }