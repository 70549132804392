var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "video-slider-item",
      class: _vm.options.subclass,
      on: {
        mouseover: function ($event) {
          return _vm.mouseOver()
        },
        mouseleave: function ($event) {
          return _vm.mouseLeave()
        },
      },
    },
    [
      _vm.item.id !== undefined
        ? _c("div", { staticClass: "video-slider-item-inner" }, [
            _c(
              "div",
              {
                staticClass: "video-slider-item-cover",
                class: { "screenshots-in-load": !_vm.screenshotLoaded },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "video-slider-item-cover-inner",
                    on: { click: _vm.closeSearch },
                  },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/" +
                            _vm.item.user.username +
                            "/video/" +
                            _vm.item.slug,
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            ref: "coverInner",
                            staticClass: "video-slider-screenshots",
                          },
                          [
                            _vm._l(
                              _vm.screenshots,
                              function (screenshot, scindex) {
                                return _c(
                                  "div",
                                  {
                                    key: scindex,
                                    staticClass: "video-slider-screenshot",
                                    class: {
                                      "active-sc":
                                        _vm.activeScreenshot >= scindex,
                                    },
                                    style: {
                                      zIndex: scindex === 0 ? 0 : scindex,
                                    },
                                  },
                                  [
                                    _c("img", {
                                      attrs: { src: screenshot },
                                      on: {
                                        load: function ($event) {
                                          return _vm.screenshotLoadedIndex(
                                            scindex
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                )
                              }
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "video-slider-duration f-s-070" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("minutes")(
                                        _vm.item.metas.attachment.duration
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "video-slider-item-details" }, [
              _c(
                "div",
                { staticClass: "uk-grid uk-grid-10 align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "uk-width-auto" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "opacity-hover",
                          attrs: { to: "/" + _vm.item.user.username },
                        },
                        [
                          _c("image-placeholder", {
                            attrs: {
                              radius: "50%",
                              width: "30px",
                              height: "30px",
                              "data-src": _vm._f("attach")(
                                _vm.item.user.photos.avatar,
                                "xs"
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "uk-width-expand uk-text-truncate" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "video-slider-item-title f-s-090 uk-text-truncate",
                          on: { click: _vm.closeSearch },
                        },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to:
                                  "/" +
                                  _vm.item.user.username +
                                  "/video/" +
                                  _vm.item.slug,
                              },
                            },
                            [_vm._v(_vm._s(_vm.item.title))]
                          ),
                          _vm._v(" "),
                          _vm.item.metas.explicit
                            ? _c("span", { staticClass: "explicit" }, [
                                _vm._v("🅴"),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "video-slider-item-artist f-s-080",
                          on: { click: _vm.closeSearch },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "artist" },
                            [
                              _c(
                                "router-link",
                                { attrs: { to: "/" + _vm.item.user.username } },
                                [_vm._v(_vm._s(_vm.item.user.fullname))]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.item.users, function (teammate, idx) {
                            return _c(
                              "span",
                              { key: idx, staticClass: "teammate" },
                              [
                                _vm._v(",\n              "),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/" + teammate.username } },
                                  [_vm._v(_vm._s(teammate.fullname))]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-auto" }, [
                    _c(
                      "span",
                      {
                        staticClass:
                          "v-post-item-ellipsis disable-click f-s-15 disable-click more-menu-parent",
                        on: {
                          click: function ($event) {
                            return _vm.moreMenu(_vm.item, $event)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fal fa-ellipsis-h disable-click",
                        }),
                      ]
                    ),
                  ]),
                ]
              ),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }