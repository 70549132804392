<template>
    <div class="v-post-med-item video">
        <div class="uk-flex uk-flex-middle uk-grid-20" uk-grid>
            <div class="uk-width-expand main-col">
                <div class="artists uk-flex-middle f-s-085 flex-truncate">
                    <div>
                        <router-link class="display-flex" :to="'/' + item.user.username">
                            <img :src="item.user.photos.avatar | attach('xs')"/>
                        </router-link>
                    </div>
                    <div class="title-and-sub">
                        <div class="title f-s-090">
                            <router-link class="display-flex video-title" :to="helper.permalink(item)">
                                <span class="flex-truncate">
                                    {{item.title}}
                                </span>
                            </router-link>
                        </div>
                        <div class="names display-flex flex-truncate f-s-080">
                            <router-link :to="'/' + item.user.username">{{item.user.username}}</router-link>
                            <span v-for="teammate in item.users" :key="teammate.id">, <router-link
                                    :to="'/' + teammate.username">{{teammate.username}}</router-link></span>
                        </div>
                    </div>

                </div>
            </div>
            <div class="uk-width-auto">
                <div class="last-col">
                    <div class="genre-and-time f-s-075">
                        <div v-if="item.genres!=undefined && item.genres.length">
                            <router-link class="display-flex" :to="'/genre/' + item.genres[0].id">{{item.genres[0].name}}</router-link>
                        </div>
                        <div>
                            {{item.releaseDateTimeago}}
                        </div>
                    </div>
                    <div class="actions">
                        <span class="v-post-item-heart disable-click f-s-1" @click="$parent.like(item)">
                            <i class="far fa-heart disable-click"
                               :class="{'far' : !item.is.liked, 'fas active': item.is.liked}"></i>
                        </span>
                        <span class="v-post-item-retweet disable-click f-s-1" @click="$parent.repost(item)">
                            <i class="far fa-retweet disable-click" :class="{'active active-gold': item.is.reposted}"></i>
                        </span>
                    </div>
                    <div>
                        <span @click="$parent.moreMenu(item, $event)"
                              class="v-post-item-ellipsis disable-click f-s-2 disable-click more-menu-parent">
                            <i class="fal fa-ellipsis-v disable-click"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="screen-shots">
            <div class="uk-grid-10" uk-grid>
                <div class="uk-width-auto">
                    <div class="image">
                        <router-link :to="helper.permalink(item)">
                            <img :src="item.metas.cover | attach('s')"/>
                        </router-link>
                    </div>
                </div>
                <div class="uk-width-expand">
                    <ScreenshotsSlider v-if="screenshots.length" class="screenshots-con" :items="screenshots" :localkey="'screenshots' + item.realId"/>
                </div>
            </div>
        </div>
        <div class="counters f-s-075">
            <div class="counter">
                <i class="fas fa-heart"></i> {{item.stats.likes | numformat}}
            </div>
            <div class="counter">
                <i class="fas fa-comment"></i> {{item.stats.comments | numformat}}
            </div>
            <div class="counter">
                <i class="fas fa-retweet"></i> {{item.stats.reposts | numformat}}
            </div>
            <div class="counter">
                <i class="fas fa-eye"></i> {{item.stats.views | numformat}}
            </div>
        </div>
    </div>
</template>
<script>
    import helper from '../../helpers/helper'
    import ScreenshotsSlider from "@/components/Tools/ScreenshotsSlider";

    export default {
        components: {ScreenshotsSlider},
        data () {
            return {
                helper: helper
            }
        },
        props: {
            item: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            index: {
                type: Number,
                default: 0
            }
        },
        methods: {},
        computed: {
            currentTrack () {
                return this.$store.getters.getCurrent.realId != undefined ? this.$store.getters.getCurrent.realId : null
            },
            playerStatus () {
                return this.$store.getters.playerStatus
            },
            scs() {
                return this.$store.getters.settings.imagesSize.screenshot.s
            },
            screenshots() {
                let screenshots = []
                if (this.item === undefined || this.item.metas === undefined) return []
                let screenshotsUri = this.item.metas.attachment.screenshotsUri
                for (let i = 0; i < 15; i++) {
                    screenshots.push(screenshotsUri.replace('{scidx}', i).replace('{w}', this.scs.w))
                }
                return screenshots

            }
        }
    }
</script>