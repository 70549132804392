var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mainContainer",
      staticClass: "widget padding-x uk-margin-small-top claim-widget",
    },
    [
      _c("div", { staticClass: "uk-grid" }, [
        _c("div", { staticClass: "uk-width-1-3@l" }, [
          !_vm.isComplete
            ? _c("div", { staticClass: "widget-title" }, [
                _c("h4", { staticClass: "f-s-14" }, [
                  _vm._v(_vm._s(_vm._f("lang")("claim_title"))),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "f-s-090" }, [
                  _vm._v(_vm._s(_vm._f("lang")("claim_subtitle"))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isComplete
            ? _c("div", { staticClass: "widget-title" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("h4", { staticClass: "f-s-14" }, [_vm._v(_vm._s(_vm.tit))]),
                _vm._v(" "),
                _c("h6", { staticClass: "f-s-090" }, [_vm._v(_vm._s(_vm.msg))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.isComplete
            ? _c(
                "div",
                { staticClass: "widget-content claim-widget" },
                [
                  _c(
                    "div",
                    { staticClass: "uk-margin-top ninp-par" },
                    [
                      _c("label", { staticClass: "subtitle f-s-080" }, [
                        _vm._v("Search for a profile"),
                      ]),
                      _vm._v(" "),
                      _c("vow-select", {
                        attrs: {
                          fields: ["username"],
                          itemText: "username",
                          itemValue: "username",
                          url: `/search/users`,
                        },
                        on: { upd: _vm.changeSelectUser },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.fields, function (field, i) {
                    return _c(
                      "div",
                      {
                        key: i,
                        staticClass: "uk-margin-top ninp-par",
                        class: {
                          err: _vm.valid[field.name] === false,
                          suc: _vm.valid[field.name] === true,
                        },
                      },
                      [
                        _c(
                          "label",
                          {
                            staticClass: "subtitle f-s-080",
                            attrs: { for: field.name + "Input" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("lang")(field.name + "_claim")) +
                                "\n            "
                            ),
                            _c(
                              "span",
                              { staticClass: "uk-margin-left show-hide-err" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("lang")(
                                        field.placeholder + "_error_claim"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        field.type === undefined
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.value,
                                  expression: "field.value",
                                },
                              ],
                              staticClass: "ninp",
                              class: {
                                err: _vm.valid[field.name] === false,
                                suc: _vm.valid[field.name] === true,
                              },
                              attrs: {
                                id: field.name + "Input",
                                type: "text",
                                placeholder: _vm._f("lang")(
                                  field.placeholder + "_placeholder_claim"
                                ),
                              },
                              domProps: { value: field.value },
                              on: {
                                focus: function ($event) {
                                  return _vm.focused(field)
                                },
                                focusout: function ($event) {
                                  return _vm.focusout(field)
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(field, "value", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        field.type === "textarea"
                          ? _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: field.value,
                                  expression: "field.value",
                                },
                              ],
                              staticClass: "ninp",
                              attrs: {
                                rows: "4",
                                id: field.name + "Input",
                                type: "text",
                                placeholder: _vm._f("lang")(
                                  field.placeholder + "_placeholder_claim"
                                ),
                              },
                              domProps: { value: field.value },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(field, "value", $event.target.value)
                                },
                              },
                            })
                          : _vm._e(),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("DragAndDrop", {
                        attrs: {
                          "upload-url": _vm.uploadUrl,
                          "select-text": "",
                          text: _vm._f("lang")("attach_title_claim"),
                        },
                        on: { files: _vm.filesChanged },
                      }),
                      _vm._v(" "),
                      _c("progress", {
                        staticClass: "uk-progress",
                        attrs: {
                          id: "js-progressbar",
                          value: "0",
                          max: "100",
                          hidden: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-margin-top" }, [
                    _c(
                      "button",
                      {
                        staticClass: "uk-button uk-button-default",
                        attrs: { type: "button", disabled: _vm.isLoading },
                        on: { click: _vm.submit },
                      },
                      [_vm._v("\n            Submit\n          ")]
                    ),
                  ]),
                ],
                2
              )
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "success-icon" }, [
      _c("i", { staticClass: "fas fa-check-circle" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }