<template>
    <div ref="moreMenuParent">
        <div @click="onClickMoreMenu">
            <i class="fal fa-ellipsis-h disable-click"></i>
        </div>
    </div>
</template>

<script>
  import ClickOutside from 'vue-click-outside'
  export default {
    directives: {
      ClickOutside
    },
    data() {
      return {
        pos: '',
        currentPage: 'home',
        tdivHeight: 'auto',
        tdivWidth: 'auto',
        innerPosition: 'relative',
        list: [
          { id: 1, name: 'Chicago Bulls', desc: '芝加哥公牛', abbr: 'CHI' },
          { id: 2, name: 'Cleveland Cavaliers', desc: '克里夫兰骑士', abbr: 'CLE' },
        ],
        menu: [
          { content: 'Sports', children: [
            { content:'Fivb', url : 'http://www.fivb.com/'},
            { content:'Fifa', url : 'http://www.fifa.com/'},
            { content:'NBA', children: [
              { content:'NBA official site', url : 'http://www.nba.com'},
              { content:'Chicago Bulls', url : 'http://www.nba.com/bulls/'},
            ]}
          ]},
          { content: 'sm-divider' }
        ]
      }
    },
    props: {
      show: {
        type: Boolean,
        default: false,
        validator: function (value) {
          if(value===undefined) return false
          return true
        }
      },
      item: {}
    },
    watch: {
      show: {
        handler: function(val) {
          if(val===true) this.calcMenuPosition()
        },
        deep: true
      }
    },
    methods: {
      onClickMoreMenu(e) {
        bus.$emit('clickMoreMenu', this.item, e)
      },
      rowFormat (row) {
        if (row) {
          return row.name + ' (' + row.abbr + ')'
        }
      },
      setPage(e, name) {
        let element = this.$refs.tdiv
        requestAnimationFrame(() => {
          this.tdivHeight = this.$refs[this.currentPage].getBoundingClientRect().height + 'px'
          this.tdivWidth = this.$refs[this.currentPage].getBoundingClientRect().width + 'px'
          // console.log('this.tdivHeight', 'this.tdivWidth', this.tdivWidth, this.tdivHeight)
          this.currentPage = name
          this.innerPosition = 'absolute'
          requestAnimationFrame(() => {
            this.innerPosition = 'relative'
            this.tdivHeight = this.$refs[this.currentPage].getBoundingClientRect().height + 'px'
            this.tdivWidth = this.$refs[this.currentPage].getBoundingClientRect().width + 'px'
            // console.log('this.tdivHeight', 'this.tdivWidth', this.tdivWidth, this.tdivHeight)
          })
        })
        e.preventDefault()
      },
      calcMenuPosition() {
        let el = this.$refs.moreMenuParent
        if (el !== undefined) {
          this.pos = ''
          let rect = el.getBoundingClientRect()
          if (window.innerHeight - rect.bottom > rect.top)
            this.pos = 'bottom'
        }
      },
      callback() {
        alert('hellow')
      },
      toggleMenu(open) {
        // console.log('toggle menu')
        if(open===undefined) open = true
        this.tdivHeight = 'auto'
        this.tdivWidth = 'auto'
        if(open===0) {
          if(this.show)
            this.show = false
          return
        }
        this.currentPage = 'home'
        this.show = !this.show
        if(this.show) {
          this.item.showMoreMenu = true
        } else {
          this.item.showMoreMenu = false
        }
        this.$emit('update', this.item)
      }
    }
  }
</script>