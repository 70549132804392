<template>
  <div :style="{ minWidth: '100%' }" ref="main" v-click-outside="clickOutside">
    <div
      class="no-svg vp-container"
      :class="[
        { current: isCurrent },
        `status-${currentStatus}`,
        { desktop: !isTouchDevice },
      ]"
      :style="{ maxHeight: mh }"
    >
      <div
        class="vp-screenshot"
        v-show="
          screenshot !== null &&
          (!isCurrent || (isCurrent && !canAutoPlay && duration === 0))
        "
      >
        <img @load="screenshotLoaded" :src="screenshot" ref="screenshot" />
      </div>
      <video
        id="vid2"
        ref="vid2"
        :playsinline="!fullscreen"
        :poster="screenshot"
      ></video>
      <transition name="nfade">
        <div class="vp-gradient" v-show="showActions"></div>
      </transition>
      <transition name="nfade">
        <div class="vp-actions-container" v-show="showActions">
          <div class="vp-actions-overly" @click="playPauseToggle"></div>
          <div class="vp-top-actions" v-if="isCurrent">
            <div
              class="opacity-hover"
              @click="settingsModal = true"
              v-if="isTouchDevice && levels.length"
            >
              <i class="fal fa-cog"></i>
            </div>
          </div>
          <div class="vp-center-actions" v-show="isTouchDevice">
            <div @click="playPauseToggle" class="vp-play-pause-btn">
              <svg class="vp-pp-svg" viewBox="0 0 104 104" id="pause">
                <circle
                  :class="{ play: currentStatus === 'play' }"
                  id="circle"
                  cx="51"
                  cy="51"
                  r="50"
                  stroke-dasharray="314"
                  stroke-dashoffset="0"
                  style="stroke-width: 2px; stroke: white"
                />
                <line
                  id="line1"
                  x1="38"
                  y1="30"
                  x2="38"
                  y2="70"
                  style="
                    stroke-width: 4px;
                    stroke: white;
                    stroke-linecap: round;
                  "
                />
                <path
                  id="line2"
                  d="M 66 30 L 66 50 L 66 70"
                  rx="10"
                  ry="10"
                  style="
                    stroke-width: 4px;
                    stroke: white;
                    fill: white;
                    stroke-linejoin: round;
                    stroke-linecap: round;
                  "
                >
                  <animate
                    attributeName="d"
                    dur="150ms"
                    from="M 66 30 L 66 50 L 66 70"
                    to="M 38 30 L 70 50 L 38 70"
                    begin="indefinite"
                    fill="freeze"
                    id="from_pause_to_play"
                  />
                </path>
                <animate
                  xlink:href="#line2"
                  attributeName="d"
                  dur="150ms"
                  from="M 38 30 L 70 50 L 38 70"
                  to="M 66 30 L 66 50 L 66 70"
                  fill="freeze"
                  id="from_play_to_pause"
                  begin="indefinite"
                />
              </svg>
            </div>
          </div>
          <div class="vp-actions">
            <div class="bottom">
              <div class="uk-grid uk-grid-10 align-items-center">
                <div class="uk-width-expand">
                  <div
                    class="uk-grid uk-grid-10 align-items-center uk-flex"
                    v-if="isTouchDevice"
                  >
                    <div class="uk-width-auto fs-075">
                      <span class="txt-dur" v-if="isCurrent"
                        >{{ (tmpDur || duration) | minutes }}
                      </span>
                      <span class="txt-dur" v-else>{{ 0 | minutes }}</span>
                    </div>
                    <div class="uk-width-auto">/</div>
                    <div class="uk-width-auto">
                      <span class="txt-dur">{{
                        (tmpRemaning || videoDuration) | minutes
                      }}</span>
                    </div>
                    <div
                      class="
                        uk-width-expand
                        justify-content-right
                        uk-flex
                        align-items-center
                      "
                    >
                      <div class="vp-action-btn" @click="fullScreen">
                        <i class="fal fa-expand"></i>
                      </div>
                    </div>
                  </div>
                  <!-- progress bar -->
                  <div class="uk-grid uk-grid-10">
                    <div class="uk-width-expand">
                      <div class="progres-bar-parent">
                        <div
                          class="progres-bar"
                          :class="['mouse-' + progressMouseStatus]"
                        >
                          <div
                            ref="progress"
                            class="progress-helper"
                            id="progressHelper"
                          ></div>
                          <div
                            class="progress-bar-v"
                            :style="{ width: (tmpPercent || progress) + '%' }"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="vp-actions-desktop" v-if="!isTouchDevice">
                    <div class="left-side">
                      <div
                        class="vpabtnc opacity-hover"
                        id="playPauseToggle"
                        @click="playPauseToggle"
                      >
                        <svg viewBox="0 0 36 36">
                          <defs>
                            <path
                              id="pause-icon"
                              data-state="playing"
                              ref="animationPause"
                              data-next-state="paused"
                              d="M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26"
                            />
                            <path
                              id="play-icon"
                              data-state="paused"
                              ref="animationPlay"
                              data-next-state="playing"
                              d="M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28"
                            />
                          </defs>
                          <use xlink:href="#play-icon" />
                        </svg>
                      </div>
                      <div ref="volume" class="vp-volume-parent">
                        <div @click="muteToggle" class="vpabtnc cursor-pointer volume-icon" id="volumeIcon">
                          <!-- <i v-if="!mute" class="far fa-volume"></i>
                          <i v-else class="fas fa-volume-mute"></i> -->
                          <svg viewBox="0 0 36 36">
                            <defs>
                              <path
                                id="volume-full-icon"
                                ref="animationVolumeFull"
                                d="M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 ZM19,11.29 C21.89,12.15 24,14.83 24,18 C24,21.17 21.89,23.85 19,24.71 L19,26.77 C23.01,25.86 26,22.28 26,18 C26,13.72 23.01,10.14 19,9.23 L19,11.29 Z"
                              />
                              <path
                                id="volume-half-icon"
                                ref="animationVolumeHalf"
                                d="M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 Z"
                              />
                              <path
                              id="volume-empty-icon"
                              ref="animationVolumeEmpty"
                              d="m 21.48,17.98 c 0,-1.77 -1.02,-3.29 -2.5,-4.03 v 2.21 l 2.45,2.45 c .03,-0.2 .05,-0.41 .05,-0.63 z m 2.5,0 c 0,.94 -0.2,1.82 -0.54,2.64 l 1.51,1.51 c .66,-1.24 1.03,-2.65 1.03,-4.15 0,-4.28 -2.99,-7.86 -7,-8.76 v 2.05 c 2.89,.86 5,3.54 5,6.71 z M 9.25,8.98 l -1.27,1.26 4.72,4.73 H 7.98 v 6 H 11.98 l 5,5 v -6.73 l 4.25,4.25 c -0.67,.52 -1.42,.93 -2.25,1.18 v 2.06 c 1.38,-0.31 2.63,-0.95 3.69,-1.81 l 2.04,2.05 1.27,-1.27 -9,-9 -7.72,-7.72 z m 7.72,.99 -2.09,2.08 2.09,2.09 V 9.98 z"
                              />
                            </defs>
                            <use xlink:href="#volume-full-icon" />
                          </svg>
                        </div>
                        <div
                          class="vp-volumebar-container"
                          :class="{ active: volumeModel }"
                        >
                          <div class="vp-volumebar">
                            <div
                              class="vp-volumebar-val"
                              :style="{ width: volumeBarPosition + 'px' }"
                            ></div>
                            <div
                              class="bil"
                              :style="{ left: volumePosition + 'px' }"
                            ></div>
                            <div class="vbhelper" ref="volumehelper"></div>
                          </div>
                        </div>
                      </div>
                      <div class="vp-duration">
                        <div>
                          <span class="txt-dur" v-if="isCurrent"
                            >{{ (tmpDur || duration) | minutes }}
                          </span>
                          <span class="txt-dur" v-else>{{ 0 | minutes }}</span>
                        </div>
                        <div>
                          <span class="txt-dur slash-space">/</span>
                        </div>
                        <div>
                          <span class="txt-dur">{{
                            (tmpRemaning || videoDuration) | minutes
                          }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="right-side">
                      <div
                        class="vpabtnc opacity-hover"
                        @click="settingsModal = true"
                        v-html="icons.cog"
                      >
                      </div>
                      <div class="vpabtnc opacity-hover" @click="fullScreen" v-html="icons.expand">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- <div><pre>
      {{'videoDuration:' + videoDuration}} 
      </pre>
    </div> -->
    <div class="wobgmodal">
      <modal v-if="isCurrent" v-model="settingsModal" @close="settingsModal = false">
        <h3 slot="header">{{ "playback_settings" | lang }}</h3>
        <div slot="body">
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-select">{{
              "quality" | lang
            }}</label>
            <div class="uk-form-controls">
              <select class="uk-select" v-model="customLevel">
                <option
                  v-for="(lvl, index) in levelsWithAuto"
                  :key="index"
                  :value="lvl"
                  v-if="lvl !== null && lvl !== undefined"
                >
                  {{
                    lvl === "auto"
                      ? autoLang
                      : lvl + (lvl.substr(-1, 1) === "p" ? "" : "p")
                  }}
                  {{
                    lvl === "auto" && customLevel === "auto" && level !== null
                      ? " – " + level + "p"
                      : ""
                  }}
                </option>
              </select>
            </div>
          </div>
          <div class="uk-margin">
            <label class="uk-form-label" for="form-stacked-select">{{
              "speed" | lang
            }}</label>
            <div class="uk-form-controls">
              <select class="uk-select" v-model="customSpeed">
                <option
                  v-for="(speed, index) in speeds"
                  :key="index"
                  :value="speed"
                >
                  {{ speed }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div slot="footer">
          <button
            class="uk-button uk-button-default"
            @click="settingsModal = false"
          >
            OK
          </button>
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import Hls from "hls.js";
import ClickOutside from "vue-click-outside";
// require("@/helpers/hls.js");
// require("@/assets/css/me.css");
class PlayPauseButton {
  constructor(el) {
    this.el = el;
    this.animationDuration = 350;
    this.replaceUseWithPath();
    this.el.addEventListener("click", this.goToNextState.bind(this));
  }
  replaceUseWithPath() {
    var useEl = this.el.querySelector("use");
    var iconId = useEl.getAttribute("xlink:href");
    var iconEl = this.el.querySelector(iconId);
    var nextState = iconEl.getAttribute("data-next-state");
    var iconPath = iconEl.getAttribute("d");
    this.pathEl = document.createElementNS(
      "http://www.w3.org/2000/svg",
      "path"
    );
    this.pathEl.setAttribute("data-next-state", nextState);
    this.pathEl.setAttribute("d", iconPath);
    var svgEl = this.el.querySelector("svg");
    svgEl.replaceChild(this.pathEl, useEl);
  }
  goToNextState() {
    var nextIconEl = this.getNextIcon();
    var iconPath = nextIconEl.getAttribute("d");
    var nextState = nextIconEl.getAttribute("data-next-state");
    d3.select(this.pathEl)
      .attr("data-next-state", nextState)
      .transition()
      .duration(this.animationDuration)
      .attr("d", iconPath);
  }
  getNextIcon() {
    var nextState = this.pathEl.getAttribute("data-next-state");
    return this.el.querySelector(`[data-state="${nextState}"]`);
  }
}

export default {
  directives: { ClickOutside },
  props: ["item"],
  data() {
    return {
      progressMouseStatus: "out",
      volumeLock: false,
      volume: 100,
      tmpVolume: null,
      volumeModel: false,
      log: "empty",
      hls: null,
      hls2: null,
      playerEl: null,
      player: null,
      media: null,
      status: "",
      width: "auto",
      height: "auto",
      mh: "auto",
      isDragging: false,
      tmpPercent: undefined,
      tmpDur: undefined,
      tmpRemaning: undefined,
      duration: 0,
      speeds: ["0.25", "0.5", "0.75", "1", "1.25", "1.5", "1.75", "2"],
      levels: [],
      level: null,
      customLevel: "auto",
      customSpeed: "1",
      settingsModal: false,
      hlsSupport: false,
      fullscreen: false,
      hasiOSFullScreen: false,
      canAutoPlay: true,
      lastActive: null,
      isTouchDevice: false,
      now: 0,
      icons: {
        expand: '<svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><g class="ytp-fullscreen-button-corner-0"><use class="ytp-svg-shadow" xlink:href="#ytp-id-6"></use><path class="ytp-svg-fill" d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z" id="ytp-id-6"></path></g><g class="ytp-fullscreen-button-corner-1"><use class="ytp-svg-shadow" xlink:href="#ytp-id-7"></use><path class="ytp-svg-fill" d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z" id="ytp-id-7"></path></g><g class="ytp-fullscreen-button-corner-2"><use class="ytp-svg-shadow" xlink:href="#ytp-id-8"></use><path class="ytp-svg-fill" d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z" id="ytp-id-8"></path></g><g class="ytp-fullscreen-button-corner-3"><use class="ytp-svg-shadow" xlink:href="#ytp-id-9"></use><path class="ytp-svg-fill" d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z" id="ytp-id-9"></path></g></svg>',
        cog: '<svg height="100%" version="1.1" viewBox="0 0 36 36" width="100%"><use class="ytp-svg-shadow" xlink:href="#ytp-id-18"></use><path d="m 23.94,18.78 c .03,-0.25 .05,-0.51 .05,-0.78 0,-0.27 -0.02,-0.52 -0.05,-0.78 l 1.68,-1.32 c .15,-0.12 .19,-0.33 .09,-0.51 l -1.6,-2.76 c -0.09,-0.17 -0.31,-0.24 -0.48,-0.17 l -1.99,.8 c -0.41,-0.32 -0.86,-0.58 -1.35,-0.78 l -0.30,-2.12 c -0.02,-0.19 -0.19,-0.33 -0.39,-0.33 l -3.2,0 c -0.2,0 -0.36,.14 -0.39,.33 l -0.30,2.12 c -0.48,.2 -0.93,.47 -1.35,.78 l -1.99,-0.8 c -0.18,-0.07 -0.39,0 -0.48,.17 l -1.6,2.76 c -0.10,.17 -0.05,.39 .09,.51 l 1.68,1.32 c -0.03,.25 -0.05,.52 -0.05,.78 0,.26 .02,.52 .05,.78 l -1.68,1.32 c -0.15,.12 -0.19,.33 -0.09,.51 l 1.6,2.76 c .09,.17 .31,.24 .48,.17 l 1.99,-0.8 c .41,.32 .86,.58 1.35,.78 l .30,2.12 c .02,.19 .19,.33 .39,.33 l 3.2,0 c .2,0 .36,-0.14 .39,-0.33 l .30,-2.12 c .48,-0.2 .93,-0.47 1.35,-0.78 l 1.99,.8 c .18,.07 .39,0 .48,-0.17 l 1.6,-2.76 c .09,-0.17 .05,-0.39 -0.09,-0.51 l -1.68,-1.32 0,0 z m -5.94,2.01 c -1.54,0 -2.8,-1.25 -2.8,-2.8 0,-1.54 1.25,-2.8 2.8,-2.8 1.54,0 2.8,1.25 2.8,2.8 0,1.54 -1.25,2.8 -2.8,2.8 l 0,0 z" fill="#fff" id="ytp-id-18"></path></svg>'
      }
    };
  },
  mounted() {
    // bus.$on('playerTimeUpdate', (duration) => {
    //   if(this.$route.name === 'video' && this.currentMedia.slug === this.$route.params.slug) {
    //     this.duration = Math.floor(duration)
    //   }
    // })
    if (!Hls.isSupported()) {
      this.hlsSupport = true;
    }
    this.bindEventHandlers();
    document.addEventListener("DOMContentLoaded", (e) => {
      let body = document.querySelector("body").getBoundingClientRect();
      if (body.width > 1024) {
        let s = document.querySelector(".top-menu").getBoundingClientRect();
        let calw = (s.width / 4) * 3;
        this.mh = (calw * 9) / 16 + "px";
      }
    });

    this.$refs.vid2.addEventListener("playing", (e) => {
      this.status = "play";
      this.$store.dispatch("setPlayerStatus", this.status);
    });
    this.$refs.vid2.addEventListener("error", (e) => {
      this.status = "error";
      this.$store.dispatch("setPlayerStatus", this.status);
    });
    this.$refs.vid2.addEventListener("waiting", (e) => {
      this.status = "wait";
      this.$store.dispatch("setPlayerStatus", this.status);
    });
    this.$refs.vid2.addEventListener("pause", (e) => {
      this.status = "pause";
      this.$store.dispatch("setPlayerStatus", this.status);
    });
    this.$refs.vid2.addEventListener("durationchange", (event) => {
      // let du = this.player.media.duration
      // let cu = this.currentMedia;
      // cu.metas.attachment.duration = du
      // this.$store.dispatch('setCurrent', cu)
    });
    this.$refs.vid2.addEventListener("ended", (e) => {
      const repeatStatus = this.repeatStatus;
      if (repeatStatus === 1) {
        this.player.currentTime = 0;
        this.$refs.vid2.play();
      } else if (repeatStatus === 2) {
      } else {
        this.status = "wait";
        this.$store.dispatch("playNext");
      }
    });
    this.$refs.vid2.addEventListener("timeupdate", (e) => {
      const ct = this.$refs.vid2.currentTime;
      if (this.isCurrent) {
        this.duration = ct;
      }
      if (this.hlsSupport) {
        this.levels = this.currentMedia.metas.attachment.qualities;
        this.level = null;
      } else {
        const currentHls = this.hls || this.hls2;
        this.levels = currentHls.levels.map((e) => {
          if (e.attrs.RESOLUTION !== undefined) {
            return e.attrs.RESOLUTION.split("x")[1];
          } else {
            return parseInt(e.attrs.BANDWIDTH) / 1000;
          }
        });
        this.level = this.levels[currentHls.currentLevel] || null;
      }
      this.$store.dispatch("updateTime", ct);
      bus.$emit("playerTimeUpdate", ct);
    });
    const vm = this;
    this.$refs.vid2.addEventListener("play", function (e) {
      vm.status = "play";
      vm.$store.dispatch("setPlayerStatus", vm.status);
      if (
        vm.media.postType === "VID" &&
        document.getElementById("videoMirror") !== null
      ) {
        const inputCtx = document
          .getElementById("videoMirror")
          .getContext("2d");
        (function loop() {
          // window.requestAnimationFrame(drawToCanvas);
          if (!vm.$refs.vid2.paused && !vm.$refs.vid2.ended) {
            inputCtx.drawImage(vm.$refs.vid2, 0, 0, 300, 168);
            window.requestAnimationFrame(loop);
          }
        })();
      }
    });

    // this.$refs.vid2 = document.getElementById("vp");
    // this.player = new Hls({
    //   // disable preload
    //   autoStartLoad: false,
    // });

    // this.player.attachMedia(this.$refs.vid2);
    // this.player.on(Hls.Events.MEDIA_ATTACHED, () => {
    //   this.player.on(Hls.Events.MANIFEST_PARSED, function (event, data) {
    //     console.log(
    //       "manifest loaded, found " + data.levels.length + " quality level"
    //     );
    //   });
    // });
    // setTimeout(() => {
    // }, 300)
    // console.log('this.player', this.player)

    // pause playing progress seeking timeupdate waiting

    bus.$on("doSeek", (dur) => {
      this.$refs.vid2.currentTime = dur;
    });
    bus.$on("playerPause", () => {
      this.pause();
    });
    bus.$on("playerPlay", (item) => {
      if (item === undefined || item.realId === this.media.realId) {
        try {
          this.play();
        } catch (e) {}
      }
    });

    // bus.$on('playMedia', (media) => {
    //     if(media.fileType === 'm3u8') {
    //         setTimeout(() => {
    //             this.$refs.vid2.play()
    //         }, 200)
    //     } else {

    //     }
    // })
    const video = document.getElementById("vid2");
    var NAV = window.navigator;
    var UA = NAV.userAgent.toLowerCase();
    var IS_CHROME = /chrome/i.test(UA);
    var hasiOSFullScreen = video.webkitEnterFullscreen !== undefined;

    var hasNativeFullscreen = video.requestFullscreen !== undefined;

    if (hasiOSFullScreen && /mac os x 10_5/i.test(UA)) {
      hasNativeFullscreen = false;
      hasiOSFullScreen = false;
    }

    var hasWebkitNativeFullScreen = video.webkitRequestFullScreen !== undefined;
    var hasMozNativeFullScreen = video.mozRequestFullScreen !== undefined;
    var hasMsNativeFullScreen = video.msRequestFullscreen !== undefined;
    var hasTrueNativeFullScreen =
      hasWebkitNativeFullScreen ||
      hasMozNativeFullScreen ||
      hasMsNativeFullScreen;
    var nativeFullScreenEnabled = hasTrueNativeFullScreen;
    var fullScreenEventName = "";
    var isFullScreen = void 0,
      requestFullScreen = void 0,
      cancelFullScreen = void 0;

    if (hasMozNativeFullScreen) {
      nativeFullScreenEnabled = document.mozFullScreenEnabled;
    } else if (hasMsNativeFullScreen) {
      nativeFullScreenEnabled = document.msFullscreenEnabled;
    }

    if (IS_CHROME) {
      hasiOSFullScreen = false;
    }

    if (hasTrueNativeFullScreen) {
      if (hasWebkitNativeFullScreen) {
        fullScreenEventName = "webkitfullscreenchange";
      } else if (hasMozNativeFullScreen) {
        fullScreenEventName = "fullscreenchange";
      } else if (hasMsNativeFullScreen) {
        fullScreenEventName = "MSFullscreenChange";
      }
    }

    this.hasiOSFullScreen = hasiOSFullScreen;
  },
  computed: {
    volumeBarPosition() {
      const vp = this.volumePosition;
      const vbp = vp - 2 > 0 ? vp + 3 : 0;
      return vbp;
    },
    volumePosition() {
      const full = 80 - 12;
      const v = (this.volume / 100) * full;
      return v;
    },
    mute() {
      return this.volume === 0;
    },
    showActions() {
      const target = this.isTouchDevice ? 2 : 1;
      const ct = this.now;
      if (this.isCurrent && this.playerStatus === "pause") {
        return true;
      } else if (!this.isCurrent) {
        return true;
      } else {
        if (this.lastActive !== null && ct - this.lastActive <= target) {
          return true;
        }
      }
      return false;
    },
    accessToken() {
      return this.$store.getters.accessToken;
    },
    playerStatus() {
      return this.$store.getters.playerStatus;
    },
    autoLang() {
      return this.$options.filters.lang("auto");
    },
    levelsWithAuto() {
      return ["auto"].concat(this.levels);
    },
    isCurrent() {
      if (
        this.$route.name === "video" &&
        this.currentMedia &&
        this.currentMedia.slug === this.$route.params.slug &&
        this.currentMedia.postType === "VID" &&
        this.currentMedia.user.username === this.$route.params.username
      ) {
        return true;
      }
      return false;
    },
    currentStatus() {
      if (this.isCurrent) {
        return this.playerStatus === "pause" ? "pause" : "play";
      } else {
        return "pause";
      }
    },
    scs() {
      try {
        return this.$store.getters.settings.imagesSize.screenshot.l;
      } catch (e) {
        return null;
      }
    },

    screenshots() {
      if (this.scs === null) {
        return [];
      }
      let screenshots = [];
      if (this.item === undefined || this.item.metas === undefined) return [];
      let screenshotsUri = this.item.metas.attachment.screenshotsUri;
      for (let i = 0; i < 15; i++) {
        screenshots.push(
          screenshotsUri.replace("{scidx}", i).replace("{w}", this.scs.w)
        );
      }
      return screenshots;
    },
    screenshot() {
      return this.screenshots[0] || null;
    },
    currentMedia() {
      return this.$store.getters.getCurrent;
    },
    currentId() {
      return this.currentMedia && this.currentMedia.realId;
    },
    repeatStatus() {
      return this.$store.getters.getRepeat;
    },
    getDuration() {
      if (
        this.currentMedia !== null &&
        this.currentMedia.slug === this.$route.params.slug
      ) {
        return this.$store.getters.getDuration;
      }
      return 0;
    },
    videoDuration() {
      return (
        (this.item && this.item.metas && this.item.metas.attachment.duration) ||
        0
      );
    },
    progress() {
      if (this.videoDuration === 0 || this.duration === 0) return 0;
      return (this.duration / this.videoDuration) * 100;
    },
  },
  watch: {
    volumeLock(n) {
      // if(!n) {
      //   this.volumeModel = false
      // }
    },
    volume(n) {
      const vol = n;
      this.$refs.vid2.volume = vol / 100;
      // if(vol) {
      //   this.mute = false
      // } else {
      //   this.mute = true
      // }

      const volBtn = window.volumeAnimationEl;
      if (volBtn !== null) {
        let el = this.$refs.animationVolumeFull;
        if (n < 1) {
          el = this.$refs.animationVolumeEmpty
        } else if(n<50) {
          el = this.$refs.animationVolumeHalf;
        }
        var iconPath = el.getAttribute("d");
        d3.select(volBtn)
          .transition()
          .duration(0)
          .attr("d", iconPath);
      }

    },
    mute(n) {},
    customLevel(nv) {
      if (this.hlsSupport) {
        const accessToken = this.accessToken;
        let src = this.media.metas.attachment.stream + "&_token=" + accessToken;
        if (nv !== "auto") {
          src += "&q=" + nv;
        }
        const dur = this.$refs.vid2.currentTime;
        this.$refs.vid2.pause();
        this.$refs.vid2.src = src;
        this.$refs.vid2.currentTime = dur;
        this.$refs.vid2.play();

        // accessToken
        // let src = this.
      } else {
        const currentHls = this.hls || this.hls2;
        let i = 0;
        if (nv === "auto") {
          currentHls.currentLevel = -1;
          return "auto";
        }
        const levels = currentHls.levels.map((e) => {
          const lvl = e.attrs.RESOLUTION.split("x")[1];
          if (nv === lvl) {
            currentHls.currentLevel = i;
          }
          i++;
          return lvl;
        });
      }
    },
    customSpeed(nv) {
      this.$refs.vid2.playbackRate = nv;
    },
    currentStatus(cs) {
      if (document.getElementById("from_play_to_pause") !== null) {
        if (cs === "play") {
          document.getElementById("from_play_to_pause").beginElement();
        } else {
          document.getElementById("from_pause_to_play").beginElement();
        }
      }
      const plyBtn = window.playPauseToggleEl;
      if (plyBtn !== null) {
        let el = this.$refs.animationPlay;
        if (cs === "play") {
          el = this.$refs.animationPause;
        }
        var iconPath = el.getAttribute("d");
        var nextState = el.getAttribute("data-next-state");
        d3.select(plyBtn)
          .attr("data-next-state", nextState)
          .transition()
          .duration(200)
          .attr("d", iconPath);
      }
    },
    currentMedia(n, o) {
      let wd = false;
      n = n || undefined;
      o = o || undefined;
      if (
        o !== undefined &&
        o.realId !== undefined &&
        n !== undefined &&
        n.realId !== undefined &&
        n.realId !== o.realId
      ) {
        // this.$refs.vid2.pause()
        wd = true;
      }
      this.media = n;
      this.playMedia(wd);
    },
    isDragging(nv) {
      if (!nv) {
        this.tmpPercent = null;
      }
    },
  },
  beforeMount() {
    let isTouchDevice = "ontouchstart" in document.documentElement;
    this.isTouchDevice = isTouchDevice;
  },
  methods: {
    clickOutside() {
      this.lastActive = 0;
    },
    muteToggle() {
      if (this.volume > 0) {
        this.volume = 0;
      } else {
        this.volume = this.tmpVolume || 100;
      }
    },
    reqFullScreen(element) {
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen(); // Firefox
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen(); // Safari
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen(); // IE/Edge
      } else if (element.webkitEnterFullscreen) {
        element.webkitEnterFullscreen(); // Safari IOS
      }
    },
    exitFullScreen() {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    },
    fullScreen() {
      this.reqFullScreen(document.getElementById("vid2"));
    },
    playPauseToggle() {
      if (this.isCurrent) {
        if (this.status === "play") {
          this.pause();
        } else {
          this.play();
        }
      } else {
        this.$store.dispatch("setCurrent", this.item);
      }
    },
    play() {
      this.$refs.vid2.play();
    },
    pause() {
      this.$refs.vid2.pause();
    },
    screenshotLoaded() {
      let vm = this;
      if (
        vm.media.postType === "VID" &&
        document.getElementById("videoMirror") !== null
      ) {
        const inputCtx = document
          .getElementById("videoMirror")
          .getContext("2d");
        // window.requestAnimationFrame(drawToCanvas);
        // if (!vm.$refs.vid2.paused && !vm.$refs.vid2.ended) {
        inputCtx.drawImage(vm.$refs.screenshot, 0, 0, 300, 168);
        // }
      }
    },
    setScreenshotToDesktopPlayer() {
      const vm = this;
      if (vm.media.postType === "VID") {
        const inputCtx = document
          .getElementById("videoMirror")
          .getContext("2d");
        // window.requestAnimationFrame(drawToCanvas);
        // if (!vm.$refs.vid2.paused && !vm.$refs.vid2.ended) {
        inputCtx.drawImage(vm.$refs.vid2, 0, 0, 300, 168);
        // }
      }
    },
    bindEventHandlers() {
      setInterval(() => {
        this.now = Math.floor(new Date().getTime() / 1000);
      }, 1000);

      let isTouchDevice = this.isTouchDevice;
      // const progressHelper = document.getElementById('progressHelper');
      const progressHelper = this.$refs.progress;
      setTimeout(
        () => {
          progressHelper.addEventListener(
            isTouchDevice ? "touchstart" : "mousedown",
            this.seek
          );
          this.$refs.main.addEventListener(
            isTouchDevice ? "touchmove" : "mousemove",
            this.activePlayer
          );
          this.$refs.main.addEventListener(
            isTouchDevice ? "touchstart" : "mousedown",
            this.activePlayer
          );
          if (!isTouchDevice) {
            this.$refs.main.addEventListener(
              "mouseout",
              this.videoMouseOut
            )
            // play animation
            let playAnimationEl = document.getElementById("playPauseToggle");
            let useEl = playAnimationEl.querySelector("use");
            let iconId = useEl.getAttribute("xlink:href");
            let iconEl = playAnimationEl.querySelector(iconId);
            let nextState = iconEl.getAttribute("data-next-state");
            let iconPath = iconEl.getAttribute("d");
            window.playPauseToggleEl = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            window.playPauseToggleEl.setAttribute("data-next-state", nextState);
            window.playPauseToggleEl.setAttribute("d", iconPath);
            var svgEl = playAnimationEl.querySelector("svg");
            svgEl.replaceChild(window.playPauseToggleEl, useEl);


            // volume animation
            let volumeAnimationEl = document.getElementById("volumeIcon");
            useEl = volumeAnimationEl.querySelector("use");
            iconId = useEl.getAttribute("xlink:href");
            iconEl = volumeAnimationEl.querySelector(iconId);
            iconPath = iconEl.getAttribute("d");
            window.volumeAnimationEl = document.createElementNS(
              "http://www.w3.org/2000/svg",
              "path"
            );
            window.volumeAnimationEl.setAttribute("d", iconPath);
            var svgEl = volumeAnimationEl.querySelector("svg");
            svgEl.replaceChild(window.volumeAnimationEl, useEl);
            

            // new PlayPauseButton(document.getElementById("playPauseToggle"));
            this.$refs.progress.addEventListener(
              "mouseenter",
              this.progressMouseEnter
            );
            this.$refs.progress.addEventListener(
              "mouseout",
              this.progressMouseOut
            );
            this.$refs.volume.addEventListener(
              "mousemove",
              this.mouseMoveOnVolume
            );
            this.$refs.volume.addEventListener("mouseout", this.mouseOutVolume);
            this.$refs.volumehelper.addEventListener(
              "mousedown",
              this.volumeStart
            );
          }
          // console.log("events set", progressHelper);
        },
        isTouchDevice ? 0 : 0
      );

      // let isTouchDevice = 'ontouchstart' in document.documentElement
      // this.$refs.progress.addEventListener(isTouchDevice ? "touchstart" : "mousedown", this.onMouseDown)
      // this.$refs.progress.addEventListener(isTouchDevice ? "touchmove" : "mousemove", this.onMouseOver)
      // this.$refs.progress.addEventListener("mouseout", this.onMouseOut)
      // this.$refs.progress.addEventListener(isTouchDevice ? "touchend" : "mouseup", this.onMouseUp)
    },
    videoMouseOut() {
      this.lastActive = 0
    },
    progressMouseEnter() {
      this.progressMouseStatus = "in";
    },
    progressMouseOut() {
      this.progressMouseStatus = "out";
    },
    volumeStart(e) {
      window.addEventListener("mousemove", this.volumeMove);
      window.addEventListener("mouseup", this.volumeEnd);
      this.volumeLock = true;
      let parentOffset = this.$refs.volumehelper.getBoundingClientRect();
      let width = parentOffset.width;
      let ex = e.x || e.changedTouches[0].clientX;
      let x = ex - parentOffset.left;
      let pos = x / width;
      pos = pos > 1 ? 1 : pos < 0 ? 0 : pos;
      this.volume = Math.floor(pos * 100);
    },
    volumeMove(e) {
      let parentOffset = this.$refs.volumehelper.getBoundingClientRect();
      let width = parentOffset.width;
      let ex = e.x || e.changedTouches[0].clientX;
      let x = ex - parentOffset.left;
      let pos = x / width;
      pos = pos > 1 ? 1 : pos < 0 ? 0 : pos;
      this.volume = Math.floor(pos * 100);
    },
    volumeEnd(e) {
      this.volumeLock = false;
      this.tmpVolume = parseInt(this.volume);
      window.removeEventListener("mousemove", this.volumeMove);
      window.removeEventListener("mouseup", this.volumeEnd);
    },
    mouseOutVolume() {
      if (!this.volumeLock) {
        this.volumeModel = false;
      }
    },
    mouseMoveOnVolume() {
      this.volumeModel = true;
    },
    activePlayer() {
      this.lastActive = new Date().getTime() / 1000;
    },
    seek(e) {
      // console.log("enter seek");
      if (this.item !== null && this.item.realId !== this.currentId) {
        // console.log("not cur");
        return;
      }
      if (
        this.currentMedia.metas === undefined &&
        this.currentMedia.metas.attachment.duration === undefined
      ) {
        // console.log("err2");
        return;
      }
      if (this.playerStatus === "play") {
        // this.pause()
      }
      let parentOffset = e.target.getBoundingClientRect();
      // this.seekSta rt = e.pageX
      //var width = $(this).width();
      let isTouchDevice = "ontouchstart" in document.documentElement;
      window.addEventListener(
        isTouchDevice ? "touchmove" : "mousemove",
        this.seekMove
      );
      window.addEventListener(
        isTouchDevice ? "touchend" : "mouseup",
        this.seekEnd
      );
    },
    seekMove(e) {
      if (this.item !== null && this.item.realId !== this.currentId) {
        return;
      }

      // this.seekStart = e.pageX
      let parentOffset = document
        .getElementById("progressHelper")
        .getBoundingClientRect();
      let width = parentOffset.width;
      let ex = e.x || e.changedTouches[0].clientX;
      let x = ex - parentOffset.left;
      let pos = x / width;
      pos = pos > 1 ? 1 : pos < 0 ? 0 : pos;
      this.tmpPercent = pos * 100;
      this.tmpDur = Math.floor(
        (this.tmpPercent * this.currentMedia.metas.attachment.duration) / 100
      );
      this.tmpRemaning = Math.abs(
        this.tmpDur - Math.floor(this.currentMedia.metas.attachment.duration)
      );
      // this.controls.Player.duration = (pos * this.current.metas.attachment.duration === 0) ? 1 : pos * this.current.metas.attachment.duration
      //this.controls.Player.duration = pos*this.controls.current.metas.attachment.duration
    },
    seekEnd(e) {
      if (this.item !== null && this.item.realId !== this.currentId) {
        return;
      }
      let parentOffset = document
        .getElementById("progressHelper")
        .getBoundingClientRect();
      let width = parentOffset.width;
      let ex = e.x || e.changedTouches[0].clientX;
      let x = ex - parentOffset.left;
      let pos = x / width;
      pos = pos > 1 ? 1 : pos < 0 ? 0 : pos;
      this.tmpPercent = pos * 100;
      const seekValue =
        pos * this.currentMedia.metas.attachment.duration === 0
          ? 1
          : pos * this.currentMedia.metas.attachment.duration;
      try {
        this.$refs.vid2.currentTime = seekValue;
      } catch (e) {
        this.$refs.vid2.currentTime = parseInt(seekValue);
      }
      this.duration = seekValue;
      this.tmpDur = undefined;
      // this.controls.Player.play()
      this.tmpPercent = undefined;
      this.tmpRemaning = undefined;
      //this.controls.Player.duration = undefined
      let isTouchDevice = "ontouchstart" in document.documentElement;
      window.removeEventListener(
        isTouchDevice ? "touchmove" : "mousemove",
        this.seekMove
      );
      window.removeEventListener(
        isTouchDevice ? "touchend" : "mouseup",
        this.seekEnd
      );
    },
    getCurrentDuration(pos) {
      let w = document
        .getElementById("progressHelper")
        .getBoundingClientRect().width;
      if (pos < 0) {
        return 0;
      }
      let dur = (pos / w) * this.item.metas.attachment.duration;
      this.tmpPercent = (dur / this.item.metas.attachment.duration) * 100;
      return dur;
    },
    fireEvent(name) {
      if (!this.events[name]) return;
      var args = [].splice.call(arguments, 0);
      args[0] = this;
      this.events[name].e.forEach(function (event) {
        event.apply(null, args);
      });
    },
    onMouseDown(e) {
      if (this.item !== null && this.item.realId !== this.currentId) {
        return;
      }
      let canvasPosition = this.$refs.progress.getBoundingClientRect();
      this.isDragging = true;
      let ex = e.x || e.touches[0].clientX;
      var x = ex - canvasPosition.left;
      // var waveClicked = this.getCurrentBar(x)
    },

    getMinutes(dur) {
      let value = parseFloat(dur);
      if (!value || typeof value !== "number") return "00:00";
      let min = parseInt(value / 60),
        sec = parseInt(value % 60);
      min = min < 10 ? "0" + min : min;
      sec = sec < 10 ? "0" + sec : sec;
      value = min + ":" + sec;
      return value;
    },
    removeEvents() {
      this.$refs.vid2.removeEventListener("play", 0);
      this.$refs.vid2.removeEventListener("playing");
      this.$refs.vid2.removeEventListener("waiting");
      this.$refs.vid2.removeEventListener("pause");
      this.$refs.vid2.removeEventListener("timeupdate");
      this.$refs.vid2.removeEventListener("end");
      this.$refs.vid2.removeEventListener("durationchange");
    },
    changeQuality() {
      let src =
        this.media.metas.attachment.stream +
        "&_token=" +
        localStorage.getItem("user-token");
      this.$refs.vid2.src = src;
    },
    playMedia(wd) {
      this.duration = 0;
      if (wd) {
        this.$refs.vid2.pause();
        // this.removeEvents();
      }

      let src =
        this.media.metas.attachment.stream +
        "&_token=" +
        localStorage.getItem("user-token");

      if (Hls.isSupported()) {
        this.log = "supported";
        const th = this.hls === null ? "hls" : "hls2";
        const td = th === "hls2" ? "hls" : "hls2";
        if (this[td] !== null) {
          this[td].destroy();
          this[td] = null;
        }
        this[th] = new Hls();
        this[th].attachMedia(this.$refs.vid2);
        this[th].loadSource(src);
        let vm = this;
        this[th].on(Hls.Events.MANIFEST_PARSED, () => {
          const ply = vm.$refs.vid2.play();
          ply.catch((e) => {
            vm.status = "pause";
            vm.$store.dispatch("setPlayerStatus", vm.status);
            setTimeout(() => {
              this.$refs.vid2.play();
            }, 2000);
          });
        });
      } else {
        let canPlay = this.$refs.vid2.canPlayType(
          "application/vnd.apple.mpegurl"
        );
        this.$refs.vid2.src = src;
        const ply = this.$refs.vid2.play();
        ply.then(() => {});
        ply.catch((e) => {
          this.canAutoPlay = false;
          this.status = "pause";
          this.$store.dispatch("setPlayerStatus", this.status);
          setTimeout(() => {
            this.$refs.vid2.play();
          }, 1000);
        });
      }

      // this.player.loadSource(src);

      //   fetch(src)
      //     .then((response) => response.blob())
      //     .then((blob) => {
      //       console.log("blob", blob);
      //       const url = URL.createObjectURL(blob);
      //       this.player.loadSource(url);
      //                     if(this.blb !== null) {
      //                         console.log('this.blb', this.blb)
      //                         console.log(this.blb, URL.revokeObjectURL(this.blb))
      //                     }
      //                     console.log(url)
      //                     this.blb = url;
      //                     this.player.setSrc(
      //    { src:url, type:blob.type }
      // );
      //                     this.player.load();
      //                     this.player.play();
      // });

      //   if (window.mirrorTimeout !== undefined) {
      //     clearTimeout(window.mirrorTimeout);
      //   }
      // this.player.load();
    },
  },
};
</script>