<template>
    <div>
        <Adsense
                class="adsbygoogle"
                data-adtest="on"
                data-ad-client="ca-pub-8118624680281970"
                data-ad-slot="7392234444">
        </Adsense>
    </div>
</template>
<script>
    export default {}
</script>