<template>
    <div>
        <div v-show="!isComplete" class="upload-status-bar">
        </div>
        <div v-if="isComplete" class="upload-status-bar-on-complete uk-animation-fade">
            <div class="uppy-Root uppy-StatusBar is-complete">
                <div class="uppy-StatusBar-progress" role="progressbar" aria-valuemin="0" aria-valuemax="100"
                     aria-valuenow="0" style="width: 100%;">

                </div>
                <div class="uppy-StatusBar-content" title="Complete" role="status">
                    <div class="uppy-StatusBar-status">
                        <div class="uppy-StatusBar-statusPrimary">
                            <svg aria-hidden="true" focusable="false" class="uppy-StatusBar-statusIndicator UppyIcon"
                                 width="15" height="11" viewBox="0 0 15 11">
                                <path d="M.414 5.843L1.627 4.63l3.472 3.472L13.202 0l1.212 1.213L5.1 10.528z"></path>
                            </svg>
                            Complete
                        </div>
                    </div>
                </div>
                <div v-if="!inProcess" class="uppy-StatusBar-actions">
                    <button @click="clickOnCancel" class="status-bar-btn">
                        Cancel
                    </button>
                    <button @click="clickOnSave" class="status-bar-btn save">
                        Save
                    </button>
                </div>
                <div v-if="inProcess" class="uppy-StatusBar-actions">
                    <i uk-spinner="ratio: 0.5"></i>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import '@uppy/core/dist/style.css'
    import '@uppy/status-bar/dist/style.css'

    export default {
        data() {
            return {
                show: false,
                percent: 0,
                isComplete: false,
                inProcess: false
            }
        },
        mounted() {
            this.initStatusBar()
        },
        methods: {
            clickOnSave() {
                bus.$emit('saveUpload')
                this.inProcess = true
            },
            clickOnCancel() {
                bus.$emit('doCancelUpload')
                this.isComplete = false
            },
            initStatusBar() {
                bus.$on('uploadStarted', () => {
                    this.show = true
                })
                bus.$on('uploadProgress', (progress) => {
                    this.percent = progress
                })
                bus.$on('uploadComplete', (res) => {
                    if (!res.failed.length) {
                        this.isComplete = true
                    }
                })
                bus.$on('saveComplete', () => {
                    this.isComplete = false
                    this.percent = 0
                    this.show = false
                    this.inProcess = false
                })
                bus.$on('stopSaveProcess', () => {
                    this.inProcess = false
                })
            }
        }
    }
</script>