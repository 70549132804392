<template>
    <div class="sidebar-container">
        <router-link to="/">
            <div class="sidebar-logo-top"></div>
        </router-link>
        <MainMenu :items="mainItems"/>
        <h4 v-if="auth" class="sidebar-menu-title f-f-light f-s-11">Collection</h4>
        <MainMenu v-if="auth" :items="secondItems"/>
        <div class="dark-switcher">
            <div class="uk-text-center">
                <span @click="darkmode" class="dark-mode-switcher" :class="{'dark-mode-active': isDark}">
                    <span class="f-s-080">Dark Mode</span>
                    <i class="fa-moon f-s-1" :class="{'fas': isDark, 'fal': !isDark}"></i>
                </span>
            </div>
        </div>
    </div>
</template>
<style>
    .sidebar-menu-title {
        margin-bottom: 10px;
        color: #b5b5b5 !important;
    }
    .sidebar-logo-top {
        margin-bottom: 30px;
    }
</style>
<script>
    import MainMenu from "@/components/Menu/MainMenu";
    export default {
        components: {
            MainMenu
        },
        methods: {
            darkmode() {
                bus.$emit('darkModeToggle')
                this.isDark = !this.isDark
            }
        },
        computed: {
            auth() {
                return this.$store.getters.isAuthenticated
            }
        },
        data() {
            return {
                isDark: localStorage.getItem('darkMode')===undefined ? true : (localStorage.getItem('darkMode')==='false' ? false : true),
                mainItems: [
                    {
                        icon: 'fas fa-fire-alt',
                        label: 'Explore',
                        uri: '/',
                        name: 'explore',
                        auth: false
                    },
                    {
                        icon: 'fas fa-chart-bar',
                        label: 'Top Charts',
                        uri: '/charts',
                        name: 'charts',
                        auth: false
                    },
                    {
                        icon: 'far fa-compass',
                        label: 'Discover',
                        uri: '/discover',
                        name: 'discover',
                        auth: false
                    }
                ],
                secondItems: [
                    {
                        icon: 'fal fa-home',
                        label: 'Feed',
                        uri: '/feed',
                        name: 'feed',
                        auth: true
                    },
                    {
                        icon: 'fal fa-heart',
                        label: 'Likes',
                        uri: '/likes',
                        name: 'likes',
                        auth: true
                    },
                    {
                        icon: 'fal fa-arrow-circle-down',
                        label: 'Downloads',
                        uri: '/downloads',
                        name: 'downloads',
                        auth: true
                    },
                    {
                        icon: 'fal fa-history',
                        label: 'History',
                        uri: '/history',
                        name: 'history',
                        auth: true
                    },

                ]
            }
        }
    }
</script>
