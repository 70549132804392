<template>
    <div class="promote-item">
        <div class="uk-grid uk-grid-5 uk-flex uk-flex-middle">
            <div class="uk-width-auto">
                <image-placeholder width="40px" :radius="radius" :data-src="cover | attach('xs')" />
            </div>
            <div class="uk-width-expand f-f-reg">
                <div class="promote-item-title f-s-080">
                    {{item.title}}
                </div>
                <div class="promote-item-description f-s-070">
                    {{item.description.join(' – ')}}
                </div>
            </div>
            <div class="uk-width-auto" v-if="status!=null">
                <span :class="'promote-item-status promote-item-' + status"></span>
            </div>
        </div>
    </div>
</template>

<script>
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    export default {
        components: {ImagePlaceholder},
        props: {
            item: {
                type: Object,
                default: () => {}
            },
            index: {
                type: Number,
                default: 0
            }
        },
        data() {
            return {

            }
        },
        computed: {
            auth() {
                return this.$store.getters.getProfile
            },
            cover() {
                switch (this.item.type) {
                    case 'USER':
                        return this.auth.photos.avatar;
                        break;
                    default:
                        return this.item.cover
                }
            },
            radius() {
                switch (this.item.type) {
                    case 'USER':
                        return '50%';
                        break;
                    default:
                        return '5px';
                        break;
                }
            },
            status() {
                try {
                    return this.item.status.toLowerCase()
                } catch (e) {
                    return null
                }
            }
        }
    }
</script>