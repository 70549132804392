<template>
  <div class="widget padding-x">
    <div>
      <div class="widget-title">
        <div class="uk-flex uk-flex-middle" uk-grid>
          <div class="uk-width-expand">
            <h4 class="f-s-13">{{ options.title | lang }}</h4>
            <h6 class="f-s-090">{{ options.subtitle | lang }}</h6>
          </div>
        </div>
      </div>
      <div class="widget-content app-store-slider-widget">
        <div class="uk-grid uk-grid-10">
          <div class="uk-width-1-2">
            <a :href="settings.stores.appstore.url" target="_blank">
              <img class="w-100" :src="settings.stores.appstore.image" />
            </a>
          </div>
          <div class="uk-width-1-2">
            <a
              :href="settings.stores.googleplay.url"
              target="_blank"
            >
              <img class="w-100" :src="settings.stores.googleplay.image" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["options"],
  data() {
    return {};
  },
  computed: {
    settings() {
      return this.$store.getters["settings"] || {};
    },
  },
};
</script>
