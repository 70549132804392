var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "TabbedModal",
    {
      attrs: { tabs: _vm.tabs, show: _vm.show },
      on: { setTab: _vm.setTab, close: _vm.close },
    },
    [
      _c("div", { attrs: { slot: "title0" }, slot: "title0" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("numformat")(_vm.data.followers)) +
            " Followers\n    "
        ),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "title1" }, slot: "title1" }, [
        _vm._v(
          "\n        " +
            _vm._s(_vm._f("numformat")(_vm.data.following)) +
            " Followings\n    "
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-h-100", attrs: { slot: "body0" }, slot: "body0" },
        [
          _c(
            "div",
            {
              ref: "followersRef",
              staticClass: "flex-h-100 uk-animation-fade flex-direction-column",
              attrs: { id: "container-of-followers" },
            },
            [
              _vm.followers.items.length
                ? _c("Items", {
                    attrs: { type: "VUserItem", items: _vm.followers.items },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.followers.hasMore && this.current === 0
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "is-visibility",
                          rawName: "v-is-visibility",
                          value: _vm.visibleFollowers,
                          expression: "visibleFollowers",
                        },
                      ],
                      staticClass: "uk-text-center flex-1-1",
                    },
                    [_c("i", { attrs: { "uk-spinner": "" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.followers.hasMore && !_vm.followers.items.length
                ? _c("div", { staticClass: "no-followers-parent" }, [
                    _c("div", { staticClass: "no-followers" }, [
                      _c("div", [
                        _c("i", { staticClass: "fal fa-user" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "f-s-080" }, [
                          _vm._v(
                            "\n                            No one follows this user.\n                        "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "flex-1-1", attrs: { slot: "body1" }, slot: "body1" },
        [
          _c(
            "div",
            {
              ref: "followingRef",
              staticClass: "flex-h-100 uk-animation-fade flex-direction-column",
              attrs: { id: "container-of-following" },
            },
            [
              _vm.following.items.length
                ? _c("Items", {
                    attrs: { type: "VUserItem", items: _vm.following.items },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.following.hasMore && this.current === 1
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "is-visibility",
                          rawName: "v-is-visibility",
                          value: _vm.visibleFollowing,
                          expression: "visibleFollowing",
                        },
                      ],
                      staticClass: "uk-text-center flex-1-1",
                    },
                    [_c("i", { attrs: { "uk-spinner": "" } })]
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm.following.hasMore && !_vm.following.items.length
                ? _c("div", { staticClass: "no-followers-parent" }, [
                    _c("div", { staticClass: "no-followers" }, [
                      _c("div", [
                        _c("i", { staticClass: "fal fa-user" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "f-s-080" }, [
                          _vm._v(
                            "\n                            This user follows no one.\n                        "
                          ),
                        ]),
                      ]),
                    ]),
                  ])
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }