var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c("div", { staticClass: "modal-mask", on: { click: _vm.doClose } }, [
          _c("div", { staticClass: "modal-wrapper stepped" }, [
            _c("div", { staticClass: "modal-container" }, [
              _c("div", {
                staticClass: "png-ico close small",
                on: { click: _vm.close },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-header" },
                [
                  _vm._t("header", function () {
                    return [
                      _vm._v(
                        "\n                        default header\n                    "
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm._t("body", function () {
                    return [
                      _vm._v(
                        "\n                        default body\n                    "
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-footer" },
                [
                  _vm._t("footer", function () {
                    return [
                      _vm._v(
                        "\n                        default footer\n                        "
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "modal-default-button",
                          on: {
                            click: function ($event) {
                              return _vm.$emit("close")
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            OK\n                        "
                          ),
                        ]
                      ),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.background !== ""
                ? _c("div", {
                    staticClass: "modal-background",
                    style: { backgroundImage: "url(" + _vm.background + ")" },
                  })
                : _vm._e(),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }