/* eslint-disable promise/param-names */
import { SETTINGS_REQUEST, SETTINGS_SUCCESS, SETTINGS_ERROR } from '../actions/settings'
import { USER_REQUEST } from '../actions/user'
import axios from 'axios'
import Vue from 'vue'
import { openDB } from 'idb';
import API from "@/api";
import helper from '../../helpers/helper';
const state = { status: '', hasLoadedOnce: false, settings: null, lang: null, langv: null, lango: null, langt: 'VOWAVE_LANG10' }
state.lang = window.localStorage.getItem('lang') || 'EN'
state.langv = window.localStorage.getItem('langv') || null
const getters = {
    isLoaded: state => !!state.hasLoadedOnce,
    settings: state => state.settings,
    lang: state => state.lang,
    langv: state => state.langv,
    lango: state => state.lango,
    langt: state => state.langt
}

const actions = {
    [SETTINGS_REQUEST]: ({ commit, dispatch, getters }, user) => {
        return new Promise((resolve, reject) => {
            const lang = getters.lang
            const langv = getters.langv
            commit(SETTINGS_REQUEST)
            axios.defaults.headers.common['Client-Id'] = 1
            axios.defaults.baseURL = API.baseURL
            axios({ url: API.getSettings, method: 'GET' })
                .then(async resp => {
                    const langs = resp.data.langs;
                    let d, u, l;
                    d = null;
                    u = null;
                    l = null;
                    for (let li = 0; li < langs.length; li++) {
                        d = langs[li].isDefault ? langs[li] : d
                        u = langs[li].code === lang ? langs[li] : u;
                    }
                    l = u === null ? d : u;
                    // console.log('language', langv, l.version, langv !== l.version)
                    if (langv !== l.version) {
                        const rsp = await axios({ url: API.base + l.url, method: 'GET' })
                        const dt = rsp.data;
                        await commit('setLang', { lang: l, lango: dt })
                    } else {
                        let lobj = JSON.parse(window.localStorage.getItem('lang'+l.code))
                        await commit('setLango', lobj)
                    }
                    const tok = localStorage.getItem('user-token')
                    if (!!tok) {
                        axios.defaults.headers.common['Authorization'] = tok
                        axios.defaults.crossDomain = false
                        await dispatch(USER_REQUEST)
                    }
                    commit(SETTINGS_SUCCESS, resp)
                    bus.$emit('baseGetted')
                    bus.$emit('settingsGetted', resp.data)
                    resolve(resp)
                })
                .catch(err => {
                    // console.log(err)
                    commit(SETTINGS_ERROR, err)
                    setTimeout(() => {
                        bus.$emit('error', {
                            title: 'Error',
                            message: 'Connection lost...'
                        })
                        dispatch(SETTINGS_REQUEST)
                    }, 3000)
                })

        })
    }
}

const mutations = {
    [SETTINGS_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [SETTINGS_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.settings = resp.data
        state.hasLoadedOnce = true
        // Vue.set(state, 'settings', resp.data)
    },
    [SETTINGS_ERROR]: (state) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    setLango: (state, o) => {
        state.lango = o
    },
    setLang: async (state, { lang, lango }) => {
        let table = ('lang' + lang.code).toLowerCase()
        // state.lango = lango
        // let langkeys = Object.keys(lango)
        // let db = await openDB(state.langt, 1, {
        //     upgrade(db) {
        //         let store = db.createObjectStore(table, {
        //             autoIncrement: false,
        //         })
        //     }
        // })
        // // try {
        //     let trdb = db.transaction(table, "readwrite");
        //     let arr = []
        //     for (let li = 0; li < langkeys.length; li++) {
        //         await trdb.objectStore(table).add(lango[langkeys[li]], langkeys[li])
        //         console.log('newAdded')
        //     }
        // state.lang = lang.code;
        // state.langv = lang.version;
        state.lango = lango;
        state.lang = lang.code;
        state.langv = lang.version;
        window.localStorage.setItem('lang'+state.lang, JSON.stringify(lango))
        window.localStorage.setItem('lang', lang.code)
        window.localStorage.setItem('langv', lang.version)
        // } catch (e) {
        //     console.log(e)
        // }
    }
}

export default {
    state,
    getters,
    actions,
    mutations,
}
