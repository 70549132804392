<template>
    <div class="dy-placeholder-parent" :class="{set: set}">
        <div :style="{borderRadius: radius, width: width, height: height, paddingBottom: (height=='0' ? '100%' : '0')}" class="dy-canvas-placeholer" :class="{loaded: loaded}"></div>
        <img class="dy-image-with-placeholder" :style="{borderRadius: radius, width: width}" @load="loaded = true" :src="dataSrc"/>
        <div v-if="backgroundColor!=null" class="dy-image-with-placeholder dy-overly uk-animation-fade uk-animation-fast" :style="{backgroundColor: backgroundColor}"></div>
        <div v-if="loaded" class="dy-image-with-placeholder dy-overly uk-animation-fade uk-animation-fast" :class="{loaded: loaded}" :style="{backgroundImage: 'url(' + dataSrc + ')', borderRadius: radius}">
        </div>
    </div>
</template>
<style>
    .dy-placeholder-parent {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
    }
    .dy-placeholder-parent .dy-overly {
        position: absolute !important;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        z-index: 0;
        background-position: center center;
        background-size: cover;
    }
    .dy-canvas-placeholer {
        width: 100%;
        height: 0;
        background-image: var(--profile-cover-loading);
        background-size: 600px;
        animation: shine-lines 1.6s infinite linear;
    }
    .dy-image-with-placeholder, .dy-canvas-placeholer.loaded {
        opacity: 0;
        position: absolute;
        left: 0;
        transition: opacity 0.5s;
    }
    .dy-canvas-placeholer.loaded {
        opacity: 1;
    }
    .dy-image-with-placeholder.loaded {
        opacity: 1;
        position: relative;
    }
    img.dy-image-with-placeholder.loaded {
        opacity: 0;
    }
</style>
<script>
    import resize from 'vue-resize-directive'
    export default {
        directives: {
            resize,
        },
        data() {
            return {
                loaded: false,
                inited: false
            }
        },
        props: {
            dataSrc: {
                type: String,

            },
            set: {
                type: Boolean,
                default: false
            },
            radius: {
                type: String,
                default: '0px'
            },
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '0'
            },
            backgroundColor: {
                type: String,
                default: null
            }
        },
        methods: {
            visible(type) {
                if(type && !this.inited) {
                    this.inited = true
                    // console.log('this.$refs.placeholder.clientWidth', this.$refs.placeholder.clientWidth)
                    this.$refs.placeholder.style.height = this.$refs.placeholder.clientWidth + 'px'
                }
            },
            onResize() {
                this.canv.style.height = this.canv.getBoundingClientRect().width + 'px'
            }
        },
        mounted() {
            this.canv = this.$refs.placeholder
            let canv = this.canv
            if(canv===undefined) return
            let ctx = canv.getContext("2d");
            function gradient(color0, color2) {
                let fillColor = ctx.createLinearGradient(0, 0, 300, 150);
                fillColor.addColorStop(0, color0);
                fillColor.addColorStop(1, color2);
                ctx.fillStyle = fillColor;
            }

            let colors = ['#290005', '#000', '#333'];
            let randomNumber = Math.floor(Math.random() * colors.length);
            let randomNumber2 = Math.floor(Math.random() * colors.length);
            if (randomNumber === randomNumber2) {
                randomNumber2 = randomNumber + 1;
            } else if (randomNumber === 7 && randomNumber2 === 7) {
                randomNumber2 = randomNumber - 1;
            }
            gradient(colors[randomNumber], colors[randomNumber2]);
            ctx.fillRect(0, 0, 600, 600);
        }
    }
</script>