<template>
    <div @click="clickUpload" ref="file" class="js-upload uk-placeholder uk-text-center">
        <span v-show="!files.length" uk-icon="icon: cloud-upload"></span>
        <span v-show="!files.length" class="uk-text-middle">{{text}}</span>
        <div uk-form-custom v-show="!files.length">
            <input ref="attach" name="img" type="file">
            <span class="uk-link">{{selectText}}</span>
        </div>
        <div v-if="files.length" class="uploader-files">
            <div v-for="(file, index) in files" :key="index" class="uploader-file">
                {{file.name}} — {{file.size}} KB
                <div class="delete" @click="deleteFile(index)"><i class="fa fa-times"></i></div>
            </div>
            <div @click="$refs.attach.click()" class="uploader-file" v-if="files.length < 5">
                Add file
            </div>
        </div>
        <div v-if="isLoading" class="js-uploader-progress">
            <div class="uk-text-center">
                <i uk-spinner="ratio: 1.5; fill: #fff"></i>
                <div class="abs-center">{{percent}}%</div>
            </div>
        </div>
    </div>
</template>
<style>
    .js-upload {
        position: relative;
    }
</style>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'Attach binaries by dropping them here or'
            },
            selectText: {
                type: String,
                default: 'selecting one'
            },
            uploadUrl: {
                type: String,
                default: ''
            }
        },

        data() {
            return {
                percent: 0,
                isLoading: false,
                files: []
            }
        },

        methods: {
            deleteFile(index) {
                this.files.splice(index, 1)
            },
            clickUpload() {
                if(!this.files.length) {
                    this.$refs.attach.click();
                }
            }  
        },
        watch: {
            files(newVal) {
                this.$emit('files', newVal)
            }
        },

        mounted() {
            let vm = this
            UIkit.upload(this.$refs.file, {

                url: this.uploadUrl,
                multiple: false,
                name: 'attachment',

                beforeSend: e => e.headers = {
                    'Authorization': localStorage.getItem('user-token'),
                    'Client-Id': '1',
                    'Accept': 'application/json, text/plain, */*'
                },
                beforeAll: function () {
                    vm.isLoading = true
                },
                load: function () {
                },
                error: function () {
                    // console.log('error', arguments);
                    // if(arguments.length) {
                    //     if(arguments[0].xhr!=undefined) {
                    //         var json = JSON.parse(arguments[0].xhr.response)
                    //     }
                    // }
                    vm.isLoading = false
                    bus.$emit('error', {
                        title: '',
                        message: 'It seems something went wrong while processing upload. Try again!'
                    })
                },
                complete: function () {
                    // console.log('complete', arguments);
                    vm.isLoading = false
                    bus.$emit('success', {
                        title: '',
                        message: 'Your attachment has been uploaded successfully.'
                    })
                },

                loadStart: function (e) {
                    // console.log('loadStart', arguments);

                    // bar.removeAttribute('hidden');
                    // bar.max = e.total;
                    // bar.value = e.loaded;
                },

                progress: function (e) {
                    // console.log('progress', arguments);
                    vm.percent = Math.floor(e.loaded/e.total * 100)
                    // bar.max = e.total;
                    // bar.value = e.loaded;
                },

                loadEnd: function (e) {
                    // console.log('loadEnd', arguments);

                    // bar.max = e.total;
                    // bar.value = e.loaded;
                },

                completeAll: function () {
                    // console.log('completeAll', arguments);
                    let res = JSON.parse(arguments[0].response);
                    if(res.attachmentId !== undefined) {
                        let tmp = {
                            id: res.attachmentId
                        };
                        if(res.attachmentFileName !== undefined) {
                            tmp['name'] = res.attachmentFileName;
                        }
                        if(res.attachmentFileSize !== undefined) {
                            tmp['size'] = Math.floor(res.attachmentFileSize / 1000);
                        }
                        vm.files.push(tmp);
                    }
                    vm.$emit('upload-complete', res)
                }

            });
        }
    }
</script>