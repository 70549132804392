var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-post-item" }, [
    _c("div", { staticClass: "v-post-move" }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "uk-grid-none uk-flex uk-flex-middle",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "v-post-track-num f-s-1 f-f-light" }, [
            _vm._v(
              "\n                    " +
                _vm._s(
                  _vm.index +
                    1 +
                    (_vm.options.startIndex != undefined
                      ? _vm.options.startIndex
                      : 0)
                ) +
                "\n                "
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.item.id === undefined
          ? _c("div", { staticClass: "uk-width-auto" }, [
              _c(
                "div",
                { staticClass: "v-post-item-cover-inner" },
                [
                  _c("ImagePlaceholder", {
                    attrs: { width: "45px", height: "0", radius: "0" },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.id === undefined
          ? _c("div", { staticClass: "uk-width-expand" }, [
              _c("div", { staticClass: "v-post-item-container" }, [
                _c(
                  "div",
                  { staticClass: "v-post-item-title" },
                  [_c("text-skeleton")],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "v-post-item-artist mt-1" },
                  [_c("text-skeleton")],
                  1
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.id !== undefined
          ? _c("div", { staticClass: "v-post-item-cover uk-width-auto" }, [
              _c(
                "div",
                {
                  staticClass: "v-post-item-cover-inner",
                  on: { click: _vm.play },
                },
                [
                  _c("ImagePlaceholder", {
                    attrs: {
                      width: "45px",
                      height: "0",
                      radius: "0",
                      "data-src": _vm._f("attach")(_vm.item.metas.cover, "s"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "v-post-item-player-actions" }, [
                    _vm.currentTrack != null &&
                    _vm.currentTrack == _vm.item.realId &&
                    _vm.playerStatus === "play"
                      ? _c("i", { staticClass: "fas fa-grip-lines-vertical" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentTrack != null &&
                    _vm.currentTrack == _vm.item.realId &&
                    _vm.playerStatus === "wait"
                      ? _c("i", { staticClass: "fas fa-spinner-third fa-spin" })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentTrack === null ||
                    _vm.currentTrack !== _vm.item.realId ||
                    (_vm.currentTrack === _vm.item.realId &&
                      _vm.playerStatus === "pause")
                      ? _c("i", { staticClass: "fas fa-play" })
                      : _vm._e(),
                  ]),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.id !== undefined
          ? _c("div", { staticClass: "uk-width-expand" }, [
              _c("div", { staticClass: "v-post-item-container" }, [
                _c(
                  "div",
                  {
                    staticClass: "v-post-item-title f-s-090 uk-text-truncate",
                    on: {
                      click: function ($event) {
                        return _vm.closeSearch()
                      },
                    },
                  },
                  [
                    _c(
                      "router-link",
                      { attrs: { to: _vm.helper.permalink(_vm.item) } },
                      [_vm._v(_vm._s(_vm.item.title))]
                    ),
                    _vm._v(" "),
                    _vm.item.metas.explicit
                      ? _c("span", { staticClass: "explicit" }, [_vm._v("🅴")])
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "v-post-item-artist f-s-080 uk-text-truncate",
                    on: {
                      click: function ($event) {
                        return _vm.closeSearch()
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "artist" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "disable-click",
                            attrs: { to: "/" + _vm.item.user.username },
                          },
                          [_vm._v(_vm._s(_vm.item.user.fullname))]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.item.users, function (teammate) {
                      return _c(
                        "span",
                        { staticClass: "teammate" },
                        [
                          _vm._v(", "),
                          _c(
                            "router-link",
                            {
                              staticClass: "disable-click",
                              attrs: { to: "/" + teammate.username },
                            },
                            [_vm._v(_vm._s(teammate.fullname))]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.id !== undefined
          ? _c("div", { staticClass: "uk-width-auto" }, [
              _c(
                "div",
                {
                  staticClass: "v-post-delete",
                  on: { click: _vm.removeFromSet },
                },
                [_c("i", { staticClass: "fas fa-trash-alt" })]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "v-post-views f-s-075 uk-flex uk-flex-middle disable-click",
                },
                [
                  _vm.options.showViews != undefined && _vm.options.showViews
                    ? _c("span", { staticClass: "v-post-item-views" }, [
                        _c("i", { staticClass: "far fa-eye" }),
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm._f("numformat")(_vm.item.stats.views)) +
                            "\n                    "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "v-post-item-heart disable-click f-s-1",
                      on: {
                        click: function ($event) {
                          return _vm.like(_vm.item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-heart disable-click",
                        class: {
                          far: !_vm.item.is.liked,
                          "fas active": _vm.item.is.liked,
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass: "v-post-item-retweet disable-click f-s-1",
                      on: {
                        click: function ($event) {
                          return _vm.repost(_vm.item)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "far fa-retweet disable-click",
                        class: { "active active-gold": _vm.item.is.reposted },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    {
                      staticClass:
                        "v-post-item-ellipsis disable-click f-s-15 disable-click more-menu-parent",
                      on: {
                        click: function ($event) {
                          return _vm.$parent.moreMenu(_vm.item, $event)
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fal fa-ellipsis-h disable-click",
                      }),
                    ]
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }