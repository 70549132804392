var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "padding-x uk-margin-top notification-widget" },
    [
      _c("NotificationsDropdown", {
        staticClass: "uk-margin-top",
        attrs: {
          header: true,
          count: 30,
          options: { suppressScrollY: true },
          maxHeight: "auto",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }