import { SET_ACTIVE_ITEM, DO_SET_ACTIVE_ITEM } from '../actions/moremenu'
const state = { activeItem: null }

const getters = {
  getActiveItem: state => state.activeItem,
}

const actions = {
  [SET_ACTIVE_ITEM]: ({commit, dispatch}, activeItem) => {
    commit(DO_SET_ACTIVE_ITEM, activeItem)
  }
}

const mutations = {
  [DO_SET_ACTIVE_ITEM]: (state, activeItem) => {
    state.activeItem = activeItem
  }
}
export default {
  state,
  getters,
  actions,
  mutations,
}
