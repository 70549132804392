<template>
    <div class="grid-user-item" :class="(options!=undefined && options.subclass!=undefined) ? options.subclass : ''">
        <div>
            <div class="image" @click="closeSearch()">
                <router-link :to="'/' + item.username">
                    <ImagePlaceholder :data-src="item.photos.avatar | attach('m')"/>
                </router-link>
            </div>
            <div class="user-bottom">
                <div class="user-bottom-fullname uk-text-truncate f-s-085" @click="closeSearch()">
                    
                    <div class="uk-flex uk-flex-inline align-items-center">
                        <div>
                            <router-link class="a-with-png-ico" :to="'/' + item.username">
                                {{item.fullname}}
                            </router-link>
                        </div>
<i
                    v-if="
                      item !== null && item.is != undefined && item.is.verified
                    "
                    class="verified png-ico small ml-p5"
                  ></i>
                    </div>
                </div>
                <div class="user-bottom-username uk-text-truncate f-s-070">
                    @{{item.username}}
                </div>
                <div class="user-bottom-action">
                    <button @click="$parent.follow(item)" v-if="item.is.following===undefined || !item.is.following" class="app-button f-s-080">{{'follow' | lang}}
                    </button>
                    <button @click="$parent.follow(item)" v-if="item.is.following" class="app-button active f-s-080">{{'unfollow' | lang}}
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";

    export default {
        props: ['item', 'index', 'options'],
        methods: {
            closeSearch() {
                bus.$emit('closeSearch')
            }
        },
        components: {
            ImagePlaceholder
        }
    }
</script>