var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("div", { staticClass: "widget padding-x livestats-side" }, [
        _vm.options != undefined && _vm.options.title != undefined
          ? _c("div", { staticClass: "widget-title" }, [
              _vm.options.title != null
                ? _c("h4", [_vm._v(_vm._s(_vm._f("lang")(_vm.options.title)))])
                : _vm._e(),
              _vm._v(" "),
              _vm.options.subtitle != null
                ? _c("h6", [
                    _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content" }, [
          _c("div", { staticClass: "live-listeners uk-text-center" }, [
            _c("div", { staticClass: "f-s-25" }, [
              _vm.auth.is != undefined && _vm.auth.is.pro
                ? _c("span", [_vm._v(_vm._s(_vm.liveStats.liveListeners))])
                : _vm._e(),
              _vm._v(" "),
              _vm.auth.is === undefined || !_vm.auth.is.pro
                ? _c("span", [_c("i", { staticClass: "fal fa-ban" })])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "f-s-1" }, [_vm._v("Live Listeners")]),
            _vm._v(" "),
            _c("div", { staticClass: "f-s-085" }, [
              _vm._v("Available for pro users only"),
            ]),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "estimated-stream-revenue" }, [
            _c("div", { staticClass: "stats-box" }, [
              _c(
                "div",
                { staticClass: "uk-text-center f-s-075 stats-box-subtitle" },
                [
                  _vm._v(
                    "\n                    Past 7 days stream revenue\n                "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c("estimated-stream-revenue", {
                    attrs: {
                      "stream-chart": _vm.liveStats.revenues.streamChart,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-grid uk-grid-5 uk-margin-small-top f-s-090" },
              [
                _c("div", { staticClass: "uk-width-1-2" }, [
                  _c(
                    "div",
                    { staticClass: "stats-box uk-text-center f-f-reg" },
                    [
                      _vm.liveStats.revenues.stream != undefined
                        ? _c("div", [
                            _vm._v(
                              "\n                            $" +
                                _vm._s(
                                  _vm.liveStats.revenues.stream.week || 0
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("small", [_vm._v("Week")]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-2" }, [
                  _c(
                    "div",
                    { staticClass: "stats-box uk-text-center f-f-reg" },
                    [
                      _vm.liveStats.revenues.stream != undefined
                        ? _c("div", [
                            _vm._v(
                              "\n                            $" +
                                _vm._s(
                                  _vm.liveStats.revenues.stream.month || 0
                                ) +
                                " "
                            ),
                            _c("br"),
                            _vm._v(" "),
                            _c("small", [_vm._v("Month")]),
                          ])
                        : _vm._e(),
                    ]
                  ),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "posts-and-profile-views" }, [
            _c("div", { staticClass: "f-s-085 live-stats-title" }, [
              _vm._v("Posts & Profile Views"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass:
                  "uk-grid uk-grid-5 uk-text-center f-f-reg f-s-090 uk-margin-small-top",
              },
              [
                _c("div", { staticClass: "uk-width-1-3" }, [
                  _c("div", { staticClass: "stats-box" }, [
                    _c("div", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm._f("numformat")(_vm.liveStats.views.today)
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [_vm._v("Today")]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-3" }, [
                  _c("div", { staticClass: "stats-box" }, [
                    _c("div", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm._f("numformat")(_vm.liveStats.views.week)
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [_vm._v("Week")]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "uk-width-1-3" }, [
                  _c("div", { staticClass: "stats-box" }, [
                    _c("div", [
                      _vm._v(
                        "\n                            " +
                          _vm._s(
                            _vm._f("numformat")(_vm.liveStats.views.total)
                          ) +
                          " "
                      ),
                      _c("br"),
                      _vm._v(" "),
                      _c("small", [_vm._v("Total")]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "sales-revenue" }, [
            _c(
              "div",
              {
                staticClass: "f-s-085 live-stats-title uk-flex uk-flex-middle",
              },
              [
                _c("div", { staticClass: "flex-left" }, [
                  _vm._v(
                    "\n                    Sales Revenue\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "flex-right f-s-070" },
                  [
                    _c("SwitchTab", {
                      attrs: {
                        padding: "2px 5px",
                        "current-tab": _vm.currencyIndex,
                        tabs: _vm.currencies,
                      },
                      on: { setTab: _vm.tabChanged },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _vm.liveStats.revenues.sale
              ? _c(
                  "div",
                  {
                    staticClass:
                      "uk-grid uk-grid-5 f-f-reg f-s-090 uk-text-center uk-margin-small-top",
                  },
                  [
                    _c("div", { staticClass: "uk-width-1-3" }, [
                      _c("div", { staticClass: "stats-box" }, [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.currency === "dollars" ? "$" : "") +
                              _vm._s(
                                _vm._f("numformat")(
                                  _vm.liveStats.revenues.sale.today[
                                    _vm.currency
                                  ]
                                )
                              ) +
                              _vm._s(_vm.currency === "dollars" ? "" : " T") +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [_vm._v("Today")]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-3" }, [
                      _c("div", { staticClass: "stats-box" }, [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.currency === "dollars" ? "$" : "") +
                              _vm._s(
                                _vm._f("numformat")(
                                  _vm.liveStats.revenues.sale.week[_vm.currency]
                                )
                              ) +
                              _vm._s(_vm.currency === "dollars" ? "" : " T") +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [_vm._v("Week")]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-1-3" }, [
                      _c("div", { staticClass: "stats-box" }, [
                        _c("div", [
                          _vm._v(
                            "\n                            " +
                              _vm._s(_vm.currency === "dollars" ? "$" : "") +
                              _vm._s(
                                _vm._f("numformat")(
                                  _vm.liveStats.revenues.sale.total[
                                    _vm.currency
                                  ]
                                )
                              ) +
                              _vm._s(_vm.currency === "dollars" ? "" : " T") +
                              " "
                          ),
                          _c("br"),
                          _vm._v(" "),
                          _c("small", [_vm._v("Total")]),
                        ]),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c("div", { staticClass: "popular-locations" }, [
            _c(
              "div",
              { staticClass: "stats-box" },
              [
                _c(
                  "div",
                  { staticClass: "uk-text-center f-s-075 stats-box-subtitle" },
                  [
                    _vm._v(
                      "\n                    Popular Locations\n                "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("popular-locations", {
                  attrs: { data: _vm.liveStats.popularLocations },
                }),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }