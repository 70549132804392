<template>
    <div class="mini-player-desktop-container-parent" :class="[{mini: hidePlayer}, {active: active}]"
         ref="parentContainer">
        <div>
            <audio id="audioPlayer" style="display: none"></audio>
        </div>
        <div @scroll="scrollParent" @mouseup="endScrollParent" @touchend="endScrollParent"
             v-if="current!=undefined && current.title!=undefined" ref="container"
             class="mini-player-desktop-container">
            <div class="mini-player-desktop" :style="{width: '100%'}" ref="miniPlayerDesktop">
                <button @click="unminimizeBtn" class="mini-player-unminimize" type="button"></button>
                <div @dblclick="togglePlayer('playerCoverContainer')" class="mini-player-desktop-cover"
                     ref="playerCoverContainer">
                    <span v-if="liveStatsAvailable" @click="togglePlayer('mini-player-minimize')" class="mini-player-minimize">
                        <i class="fal fa-chevron-circle-down"></i>
                    </span>
                    <canvas id="videoMirror" width="300px" height="168px" v-show="current.postType && current.postType === 'VID'"></canvas>
                    <image-placeholder v-show="current && current.postType !== 'VID'" width="300px" height="300px" :dataSrc="current.metas.cover | attach('m')" :style="{maxWidth: '100%'}" />
                </div>
                <div class="mini-player-desktop-title-container">
                    <div class="mini-player-desktop-title f-s-085">
                        <router-link :to="helper.permalink(current)">
                            {{current.title}}
                        </router-link>
                    </div>
                    <div class="mini-player-desktop-artist f-s-075">
                        <router-link :to="'/' + current.user.username">{{current.user.fullname}}</router-link>
                        <span v-for="user in current.users" :key="user.username">
                            , <router-link
                                :to="'/' + user.username">{{user.fullname}}</router-link>
                        </span>
                    </div>
                </div>
                <div class="mini-player-desktop-seekbar-container uk-grid-small uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-auto uk-flex uk-flex-middle">
                        <span class="mini-player-desktop-duration-left f-s-070">{{(tmpDur||duration) | minutes}}</span>
                    </div>
                    <div class="uk-width-expand">
                        <div class="mini-player-desktop-seek-container" ref="seekbar" @mousedown="seek">
                            <div class="mini-player-desktop-seek">
                                <span class="mini-player-desktop-seek-value"
                                      :style="{'width': (tmpPercent||percentSeek) + '%'}"></span>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-auto uk-flex uk-flex-middle">
                        <span class="mini-player-desktop-duration-right f-s-070">-{{ (tmpRemaning||remaining) | minutes}}</span>
                    </div>
                </div>
                <div class="mini-player-desktop-actions-container uk-grid-small uk-flex uk-flex-middle uk-text-center f-s-085"
                     uk-grid>
                    <div class="uk-width-1-1">
                        <div class="uk-position-relative">
                            <div class="volume-bar" v-if="showVolume">
                                <div class="uk-grid-small" uk-grid>
                                    <div class="uk-width-auto">
                                        <span class="mpda" @click="volumeToggle">
                                            <i class="fal fa-times volume-times"></i>
                                        </span>
                                    </div>
                                    <div class="uk-width-expand">
                                        <div ref="volumebar" @mousedown="volume" class="volume-parent">
                                            <div class="volume-slider">
                                                <div ref="volumevalue" :style="{width: volPercent+'%'}"
                                                     :class="[{'volume-30': (volPercent>30&&volPercent<60) ? true : false},{'volume-60': (volPercent>=60&&volPercent<80)}, {'volume-80': (volPercent>=80 ? true : false)}]"
                                                     class="volume-slider-value"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="uk-width-auto">
                                        <span class="mpda mpda-volume">
                                            <i @click="muteToggle" v-if="!mute" class="far fa-volume-up"></i>
                                            <i @click="muteToggle" v-if="mute" class="far fa-volume-mute"></i>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-auto mpdas">
                        <span @click="like" class="mpda mpda-like">
                            <i class="fa-heart"
                               :class="{'far' : (ct.is===undefined || !ct.is.liked), 'fas active': (ct.is!=undefined&&ct.is.liked)}"></i>
                        </span>
                        <span class="mpda">
                            <i @click="shuffle" class="far fa-random" :class="{active: getShuffle}"></i>
                        </span>
                    </div>
                    <div class="uk-width-expand">
                        <div class="uk-flex uk-grid-small uk-flex-middle" uk-grid>
                            <div class="uk-width-1-3">
                                <span class="mpdnp mpdnp-prev f-s-090">
                                    <i class="fas fa-backward" @click="prev"></i>
                                </span>
                            </div>
                            <div class="uk-width-1-3">
                                <span class="mpdp f-s-13">
                                    <i v-if="playerStatus==='pause'" @click="play()" class="fas fa-play"></i>
                                    <i v-if="playerStatus==='play'" @click="pause" class="fas fa-pause"></i>
                                    <i v-if="playerStatus==='wait'" class="fas fa-spinner-third fa-spin"></i>
                                </span>
                            </div>
                            <div class="uk-width-1-3">
                                <span class="mpdnp mpdnp-next f-s-090">
                                    <i class="fas fa-forward" @click="next"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-auto mpdas">
                        <span class="mpda">
                            <i @click="repeat" v-if="getRepeat===false || getRepeat===2" class="far fa-repeat"
                               :class="{active: getRepeat!=false}"></i>
                            <i @click="repeat" v-if="getRepeat===1" class="far fa-repeat-1-alt active"></i>
                        </span>
                        <span class="mpda mpda-volume">
                            <i @click="volumeToggle" v-if="!mute" class="far fa-volume-up"
                               :class="{'deactive': isIos}"></i>
                            <i @click="volumeToggle" v-if="mute" class="far fa-volume-mute"></i>
                        </span>
                    </div>
                </div>
            </div>

            <div class="player-bottom" :style="{top: paddingTop + 'px', width: coverWidth}">
                <div v-if="!hidePlayer" class="mini-player-desktop-tabs padding-x-15" ref="miniPlayerTabs"
                     v-bind:key="'related'+key">
                    <div class="uk-grid-small uk-text-center f-s-075" uk-grid>
                        <div class="uk-width-1-2">
                            <div class="mini-player-desktop-tab" @click="tabTo('nextUp')"
                                 :class="{active: currentTab==='nextUp'}">
                                {{'player_up_next' | lang}}
                            </div>
                        </div>
                        <div class="uk-width-1-2">
                            <div class="mini-player-desktop-tab" @click="tabTo('lyrics')"
                                 :class="{active: currentTab==='lyrics'}">
                                {{'player_lyrics' | lang}}
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="!hidePlayer && currentTab==='lyrics'" class="next-up-body">
                    <div class="player-lyrics-body" :style="{height: tabHeightVal + 'px'}">
                        <perfect-scrollbar :options="{
                            wheelPropagation: true
                        }">
                            <div class="f-s-080" v-html="helper.ln2br(texts.lyrics)"></div>
                        </perfect-scrollbar>
                        <div class="uk-text-center" v-if="texts.lyrics===''">
                            <div class="f-s-15">
                                <i class="fal fa-align-center"></i>
                            </div>
                            <div class="uk-margin-small-top f-s-075">
                                {{'no_lyrics_found' | lang}}
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!hidePlayer && currentTab==='nextUp'" class="next-up-body">
                    <div class="uk-text-center padding-top-40 padding-x-20" v-if="playlist && !playlist.length">
                        <div class="nosnd-icon"></div>
                        {{'player_empty_playlist' | lang}}
                    </div>
                    <div class="next-up-parent" id="nextUpUlParent" :style="{height: tabHeightVal + 'px'}">
                        <ul class="vertical-audio-list" ref="nextUpUl" v-if="playlist && playlist.length">
                            <li @click="playPlaylist(item)" v-for="item in playlist" :data-id="item.realId"
                                v-bind:key="item.realId"
                                :data-active="item.realId===current.realId ? '1' : '0'">
                                <div class="uk-grid-small uk-flex uk-flex-middle vertical-audio-list-li" uk-grid>
                                    <div class="uk-width-auto">
                                        <div class="vertical-audio-img">
                                            <img :src="item.metas.cover | attach('xs')">
                                        </div>
                                    </div>
                                    <div class="uk-width-expand vertical-audio-txt">
                                        <div class="uk-grid-small" uk-grid>
                                            <div class="uk-width-expand">
                                                <div class="vertical-audio-top-txt f-s-075 uk-text-truncate">
                                                    {{item.title}}
                                                </div>
                                                <div class="vertical-audio-btm-txt f-s-075 uk-text-truncate">
                                                    {{item.user.fullname}}
                                                </div>
                                            </div>
                                            <div class="uk-width-auto">
                                                <div class="vertical-audio-more">
                                                    <span class="vertical-audio-more-item vertical-audio-more-item-duration f-s-070" v-if="item && item.metas && item.metas.attachment">
                                                        {{(item.metas.attachment.duration) | minutes}}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    /* eslint-disable */
    import PerfectScrollbar from 'perfect-scrollbar';
    // import resize from 'vue-resize-directive'
    import helper from '../helpers/helper'
    import {DO_SET_PLAYER_STATUS, DO_SET_TRACK, SET_CURRENT_TRACK, SET_PLAYER_STATUS} from '../store/actions/player'
    import Sortable, {AutoScroll} from 'sortablejs/modular/sortable.core.esm.js'
import ImagePlaceholder from './Tools/ImagePlaceholder.vue';

    export default {
  components: { ImagePlaceholder },
        data() {
            return {
                duration: 0,
                liveStatsAvailable: false,
                startScroll: false,
                ct: null,
                active: false,
                paddingTop: 0,
                tabHeightVal: 0,
                coverWidth: 'auto',
                hidePlayer: false,
                key: 123,
                tmpPercent: undefined,
                tmpDur: undefined,
                lock: false,
                lastTouch: 0,
                coverIsLoaded: false,
                helper: helper,
                lastCover: '',
                endAnimation: true,
                showVolume: false,
                mute: false,
                volPercent: null,
                tmpRemaning: null,
                texts: '',
                lastTogglePlayer: null,
                currentTab: 'nextUp',
                isIos: !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform),
                sort: {}
            }
        },
        props: [
            'nextUpItems',
            'controls'
        ],
        directives: {
            //mini-player-desktop
            // resize
        },
        watch: {
            auth() {
                setTimeout(() => {
                    this.liveStatsAvailableChecker();
                }, 100);
            },
            isAuth(isAuth) {
                if(!isAuth) {
                    setTimeout(() => {
                        this.liveStatsAvailableChecker();
                    }, 100);
                }
            },
            liveStatsAvailable(lsa) {
                console.debug('lsa', lsa)
            },
            currentTab: function (ct) {
                this.getTexts()
            },
            volPercent: function (newVal) {
                
            },
            playerStatus: function (newVal) {
                // this.$store.commit(DO_SET_PLAYER_STATUS, newVal)
            },
            current: function (newVal, oldVal) {
                this.ct = newVal
                // this.$store.dispatch(SET_CURRENT_TRACK, newVal)
                // this.$store.commit(DO_SET_TRACK, newVal)
                // if (newVal.realId === undefined) return
                this.texts = {
                    lyrics: null,
                    description: null
                }
                this.getTexts()
                // if (this.dragabled === undefined && newVal.realId != undefined) {
                    setTimeout(() => {
                        this.initResize()
                        this.initSortable()
                        this.initScrollbar()
                    }, 500)
                // }
                // if (this.active === false) this.active = true
                // if (newVal.realId != oldVal.realId) {
                //     this.scrollToCurrent()
                // }
                // if (oldVal === undefined ||
                //     newVal === undefined ||
                //     oldVal.metas === undefined ||
                //     newVal.metas === undefined ||
                //     newVal.metas.cover.uri != oldVal.metas.cover.uri) {
                //     window.dispatchEvent(new Event('resize'))
                //     this.coverIsLoaded = false
                // }
            },
            active: function () {

            },
            hidePlayer: function (newVal) {
                let style = ''
                if (newVal === true) {
                    style = ''
                } else {
                    style = 'position: fixed; overflow: hidden'
                    if (this.$refs.container != undefined)
                        this.$refs.container.scrollTop = 0
                }
                if (window.innerWidth < 1000) {
                    //document.body.style = style
                }
                this.initResize()
            }
        },
        methods: {
            scrollParent(e) {
                if (!this.startScroll) {
                    this.startScroll = true
                    this.tmpDur = parseInt(this.duration, 10)
                    this.tmpRemaning = parseInt(this.remaining, 10)
                    this.tmpPercent = this.percentSeek
                }
            },
            endScrollParent() {
                if (this.startScroll) {
                    this.tmpDur = undefined
                    this.tmpRemaning = undefined
                    this.tmpPercent = undefined
                    this.startScroll = false
                    if (this.$refs.container !== undefined) {
                        if (this.$refs.container.scrollTop < -100) {
                            this.togglePlayer()
                        }
                    }
                }
            },
            async getTexts() {
                this.texts = await this.helper.getTexts(this.current.realId)
            },
            tabTo(tab) {
                this.currentTab = tab
            },
            unminimizeBtn() {
                this.togglePlayer('unminimizeBtn')
            },
            muteToggle() {
                this.mute = !this.mute
                if (this.mute) {
                    this.volPercent = 0
                } else {
                    this.volPercent = 100
                }
            },
            volumeToggle() {
                if (this.isIos) return
                this.showVolume = !this.showVolume
            },
            onTouch() {
                let currentTime = new Date().getTime() / 1000
                if (this.lastTouch + 0.5 > currentTime) {
                    this.togglePlayer('onTouch')
                }
                this.lastTouch = currentTime
            },
            scrollToCurrent() {
                setTimeout(() => {
                    if (document.querySelector('.next-up-parent li[data-active="1"]') === null) {
                        return
                    }
                    let top = document.querySelector('.next-up-parent li[data-active="1"]').getBoundingClientRect().top
                    let topParent = document.querySelector('.next-up-parent').getBoundingClientRect().top
                    let diff = top - topParent + document.querySelector('.next-up-parent').scrollTop
                    document.querySelector('.next-up-parent').scrollTop = diff
                }, 1)
            },
            playPlaylist(item) {
                this.$store.dispatch('play', item)
            },
            play(item) {
                bus.$emit('playerPlay', item)
            },
            next() {
                this.$store.dispatch('playNext')
            },
            prev() {
                this.$store.dispatch('playPrev')
            },
            pause() {
                bus.$emit('playerPause')
            },
            repeat() {
                if (this.getRepeat === false) {
                    this.$store.dispatch('repeat', 1)
                } else if (this.getRepeat === 1) {
                    this.$store.dispatch('repeat', 2)
                } else {
                    this.$store.dispatch('repeat', false)
                }
            },
            like() {
                let item = this.ct
                if (item.realId === undefined) return
                let res = this.helper.like(item)
                if (res === null) {
                    bus.$emit('showLogin')
                }
            },
            shuffle() {
                this.$store.dispatch('shuffle')
                // this.scrollToCurrent()
            },
            seek(e) {
                if (
                    (this.current.metas === undefined && this.current.metas.attachment.duration === undefined)
                ) return
                if (this.playerStatus === 'play') {
                    // this.pause()
                }
                let parentOffset = e.target.getBoundingClientRect()
                this.seekStart = e.pageX
                //var width = $(this).width();
                window.addEventListener('mousemove', this.seekMove)
                window.addEventListener('mouseup', this.seekEnd)
            },
            seekMove(e) {
                this.seekStart = e.pageX
                let parentOffset = this.$refs.seekbar.getBoundingClientRect()
                let width = parentOffset.width
                let x = e.pageX - parentOffset.left
                let pos = x / width
                pos = pos > 1 ? 1 : (pos < 0 ? 0 : pos)
                this.tmpPercent = pos * 100
                this.tmpDur = Math.floor(this.tmpPercent * this.current.metas.attachment.duration / 100)
                this.tmpRemaning = Math.abs(this.tmpDur - Math.floor(this.current.metas.attachment.duration))
                // this.controls.Player.duration = (pos * this.current.metas.attachment.duration === 0) ? 1 : pos * this.current.metas.attachment.duration
                //this.controls.Player.duration = pos*this.controls.current.metas.attachment.duration
            },
            seekEnd(e) {
                let parentOffset = this.$refs.seekbar.getBoundingClientRect()
                let width = parentOffset.width
                let x = e.pageX - parentOffset.left
                let pos = x / width
                pos = pos > 1 ? 1 : (pos < 0 ? 0 : pos)
                this.tmpPercent = pos * 100
                const seekValue = (pos * this.current.metas.attachment.duration === 0) ? 1 : pos * this.current.metas.attachment.duration
                bus.$emit('doSeek', seekValue)
                this.duration = seekValue
                this.tmpDur = undefined
                // this.controls.Player.play()
                this.tmpPercent = undefined
                this.tmpRemaning = undefined
                //this.controls.Player.duration = undefined
                window.removeEventListener('mousemove', this.seekMove)
                window.removeEventListener('mouseup', this.seekEnd)
            },
            volume(e) {
                var parentOffset = e.target.getBoundingClientRect()
                this.volumeStart = e.pageX
                window.addEventListener('mousemove', this.volumeMove)
                window.addEventListener('mouseup', this.volumeEnd)
            },
            volumeMove(e) {
                this.volumeStart = e.pageX
                var parentOffset = this.$refs.volumebar.getBoundingClientRect()
                var width = parentOffset.width
                var x = e.pageX - parentOffset.left
                var pos = x / width
                pos = pos > 1 ? 1 : (pos < 0 ? 0 : pos)
                this.volPercent = pos * 100
            },
            volumeEnd(e) {
                var parentOffset = this.$refs.volumebar.getBoundingClientRect()
                var width = parentOffset.width
                var x = e.pageX - parentOffset.left
                var pos = x / width
                pos = pos > 1 ? 1 : (pos < 0 ? 0 : pos)
                this.volPercent = pos * 100
                window.removeEventListener('mousemove', this.volumeMove)
                window.removeEventListener('mouseup', this.volumeEnd)
            },
            coverLoaded() {
                let vm = this
                this.coverIsLoaded = true
                this.initResize()
            },
            calcPlayerHeight() {
            },
            togglePlayer(test) {
                // console.log('togglePlayer')
                if(!this.liveStatsAvailable) {
                    return;
                }
                let currentTime = Math.floor(new Date().getTime())
                if (this.lastTogglePlayer != null && this.lastTogglePlayer + 50 > currentTime) {
                    return
                }
                this.lastTogglePlayer = currentTime
                let vm = this
                vm.endAnimation = false
                this.hidePlayer = !this.hidePlayer
                setTimeout(() => {
                    this.initSortable()
                    this.initScrollbar()
                }, 500)
                setTimeout(function () {
                    if (vm.$refs.miniPlayerDesktop != undefined) {
                        vm.paddingTop = vm.$refs.miniPlayerDesktop.clientHeight
                        vm.endAnimation = true
                    }
                }, 500)
                window.timeoutNextUpHeight = setTimeout(function () {
                    vm.ps = undefined
                    vm.tabHeight()
                }, 500)
            },
            initResize() {
                // console.log('resized')
//        if(window.innerWidth<1000 && !this.hidePlayer) {
//          this.playerStatusChanged = true
//          this.togglePlayer('initResize')
//        }
                let vm = this
                if (vm.$refs.parentContainer != undefined) {
                    vm.coverWidth = vm.$refs.parentContainer.clientWidth + 'px'
                }
                if (window.timeoutNextUpHeight != undefined) {
                    clearTimeout(window.timeoutNextUpHeight)
                }
                vm.tabHeight()
            },
            tabHeight() {
                if (this.$refs.miniPlayerTabs === undefined) return
                if (window.innerWidth < 1000) {
//          if(this.ps===undefined) {
//            this.ps = new PerfectScrollbar('.mini-player-desktop-container-parent', {
//              handlers: ['wheel', 'touch'],
//              wheelSpeed: 1,
//              wheelPropagation: true,
//              minScrollbarLength: 20,
//              suppressScrollX: true
//            })
//          } else {
//            this.ps.update();
//          }
                    return
                }
                let tabs = this.$refs.miniPlayerTabs.getBoundingClientRect()
                let player = this.$refs.miniPlayerDesktop.getBoundingClientRect()
                let parent = this.$refs.parentContainer.getBoundingClientRect().height
                this.paddingTop = player.height
                let tabHeight = parent - this.paddingTop - 60
                if (tabHeight < 0) tabHeight = 0
                this.tabHeightVal = tabHeight
                this.initScrollbar()
            },
            initScrollbar() {
                let el = document.getElementById('nextUpUlParent')
                if (el === undefined || el === null) return
                if (el.className.indexOf('ps') === -1) {
                    this.ps = new PerfectScrollbar('#nextUpUlParent', {
                        handlers: ['wheel', 'touch'],
                        wheelSpeed: 1,
                        wheelPropagation: true,
                        minScrollbarLength: 20,
                        suppressScrollX: true
                    })
                } else {
                    this.ps.update()
                }
            },
            initSortable() {
                if(this.isTouch) return;
                // if (this.dragabled === true) return

                this.dragabled = true
                let vm = this
                let el = this.$refs.nextUpUl
                if (el === undefined) return
                let sort = this.sort

                sort.store = {
//          get: function (sortable) {
//            var order = localStorage.getItem(sortable.options.group.name);
//            return order ? order.split('|') : [];
//          },
                    set: function (sortable) {
                        let tmpArr = []
                        var order = sortable.toArray()
                        bus.$emit('resortPlaylist', order)
                    }
                }
                var sortable = new Sortable(el, sort)
            },
            navigator() {
                let vm = this
            },
            onWindowResize() {
                if (window.playerResizeTimeout != undefined) {
                    clearTimeout(window.playerResizeTimeout)
                }
                window.playerResizeTimeout = setTimeout(() => {
                    this.initResize()
                }, 500)
            },
            liveStatsAvailableChecker() {
                if(this.auth!==null && this.auth!==undefined && this.auth.stats!==undefined) {
                    console.debug('this.auth.stats', this.auth.stats)
                    let cnt = this.auth.stats.posts - this.auth.stats.reposts;
                    if (cnt > 0) {
                        this.liveStatsAvailable = true;
                    } else {
                        this.liveStatsAvailable = false;
                    }
                } else {
                    this.liveStatsAvailable = false;
                }
                console.debug('this.liveStatsAvailable', this.liveStatsAvailable)
            },
        },

        mounted() {
            bus.$on('playerTimeUpdate', (duration) => {
                this.duration = duration
            })
            // this.liveStatsAvailableChecker();
            if (window.innerWidth < 1000) {
                this.hidePlayer = true
            }
            window.addEventListener('resize', this.onWindowResize)
            // setTimeout(() => {
            //     this.$refs.nextUpUl
            // }, 500)

            // this.calcPlayerHeight()
            // this.navigator()
            // window.addEventListener('resize', this.onWindowResize)
            // bus.$on('muteToggle', () => {
            //     this.muteToggle()
            // })

            // setInterval(() => {
            //     if (this.active) {
            //         this.initResize()
            //     }
            // }, 1500)
        },
        created() {
            if (localStorage.getItem('vol') !== null && !this.isIos) {
                this.volPercent = parseInt(localStorage.getItem('vol'), 10)
            } else {
                this.volPercent = 100
            }
        },
        beforeDestroy() {
        },
        computed: {
                        scs() {
                try {
                    return this.$store.getters.settings.imagesSize.screenshot.s
                } catch(e) {
                    return null;
                }
            },
            screenshot() {
                if(this.current && this.current.metas && this.current.metas.attachment && this.current.metas.attachment.screenshotsUri && this.scs) {
                    return this.current.metas.attachment.screenshotsUri.replace('{scidx}', '0').replace('{w}', this.scs.w)
                }
            }, 
            getRepeat() {
                return this.$store.getters.getRepeat
            },
            auth() {
                return this.$store.getters.getProfile
            },
            isAuth() {
                return this.$store.getters.isAuthenticated
            },
            isTouch() {
                var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
                var mq = function (query) {
                    return window.matchMedia(query).matches;
                };
                if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
                    return true;
                }
                // include the 'heartz' as a way to have a non matching MQ to help terminate the join
                // https://git.io/vznFH
                var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
                return mq(query);
            },
            remaining() {
                return (this.current.metas != undefined ? Math.floor(this.current.metas.attachment.duration) : 0) - Math.floor(this.duration)
            },
            percentSeek() {
                if (this.current.metas === undefined) return 0
                return this.duration / this.current.metas.attachment.duration * 100
            },
            playerStatus() {
                return (this.$store.getters['playerStatus'] != undefined ? this.$store.getters['playerStatus'] : null)
            },
            playlist() {
                return this.$store.getters.getPlaylist
            },
            getShuffle() {
                return this.$store.getters.getShuffle
            },
            current() {

                return this.$store.getters.getCurrent

                if (playlist != undefined && playlist.length) {
                    for (var i = 0; i < playlist.length; i++) {
                        if (playlist[i].realId === this.controls.Player.lastId) {
                            return playlist[i]
                        }
                    }
                }
                return {
                    title: 'null'
                }
            }
        }
    }
</script>