<template>
    <div class="padding-x uk-margin-top notification-widget">
        <NotificationsDropdown class="uk-margin-top" :header="true" :count="30" :options="{suppressScrollY: true}" maxHeight="auto"/>
    </div>
</template>
<style>

</style>
<script>
    import NotificationsDropdown from "@/components/DropDown/NotificationsDropdown";
    export default {
        components: {
            NotificationsDropdown
        },
        data() {
            return {
            }
        }
    }
</script>