var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { ref: "tabs", staticClass: "horizontal-tabs" },
    [
      _vm._l(_vm.tabs, function (tab, index) {
        return _c(
          "li",
          {
            ref: "tab",
            refInFor: true,
            class: { active: _vm.current === index },
            on: {
              click: function ($event) {
                return _vm.setTab(index)
              },
            },
          },
          [_vm._v("\n        " + _vm._s(tab) + "\n    ")]
        )
      }),
      _vm._v(" "),
      _c("div", {
        ref: "circle",
        staticClass: "horizontal-tabs-circle",
        style: { left: _vm.circleLeft },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }