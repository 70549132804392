var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length
    ? _c("div", { staticClass: "widget padding-x" }, [
        _c("div", { staticClass: "widget-title" }, [
          _c(
            "div",
            { staticClass: "uk-flex uk-flex-middle", attrs: { "uk-grid": "" } },
            [
              _c("div", { staticClass: "uk-width-expand" }, [
                _c("h4", { staticClass: "f-s-13" }, [
                  _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "f-s-090" }, [
                  _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "uk-width-auto" },
                [
                  _c(
                    "router-link",
                    { staticClass: "see-all-right", attrs: { to: _vm.seeAll } },
                    [_vm._v("See all")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content" }, [
          _c(
            "div",
            { staticClass: "hb-post-items uk-animation-fade uk-animation-10" },
            [
              _c("Items", {
                staticClass: "uk-grid-12",
                attrs: {
                  options: {
                    subclass: "uk-width-1-2 uk-width-1-4@m",
                    mobileHidden: 3,
                  },
                  "uk-grid": "",
                  items: _vm.items,
                  type: "GridPostItem",
                },
              }),
            ],
            1
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }