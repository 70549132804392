<template>
    <div>

    </div>
</template>
<script>
  import VueIziToast from 'vue-izitoast'
  export default {
    data() {
      return {
      }
    },
    methods: {
      alert() {
        this.$toast.show('test', 'Hey',{
          color: 'dark',
          icon: 'fa fa-user',
          position: 'bottomCenter',
          progressBarColor: 'rgb(0, 255, 184)',
          buttons: [
            [
              '<button>Ok</button>',
              function (instance, toast) {
                alert("Hello world!");
              }
            ],
            [
              '<button>Close</button>',
              function (instance, toast) {
                instance.hide({
                  transitionOut: 'fadeOutUp'
                }, toast);
              }
            ]
          ]
        })
      },
      success(opt) {
        this.$toast.show(opt.message, opt.title,{
          color: 'dark',
          icon: 'fa fa-check',
          position: 'bottomCenter',
          progressBarColor: 'rgb(0, 255, 184)',
          buttons: [
            [
              '<button>Close</button>',
              function (instance, toast) {
                instance.hide({
                  transitionOut: 'fadeOutUp'
                }, toast);
              }
            ]
          ]
        })
      },
      error(opt) {
        this.$toast.show(opt.message, opt.title,{
          color: 'dark',
          icon: 'fa fa-times',
          position: 'bottomCenter',
          progressBarColor: 'rgb(0, 255, 184)',
          buttons: [
            [
              '<button>Close</button>',
              function (instance, toast) {
                instance.hide({
                  transitionOut: 'fadeOutUp'
                }, toast);
              }
            ]
          ]
        })
      },
      confirm() {
        this.$toast.show('test', 'Hey',{
          color: 'dark',
          icon: 'fa fa-user',
          position: 'bottomCenter',
          progressBarColor: 'rgb(0, 255, 184)',
          buttons: [
            [
              '<button>Ok</button>',
              function (instance, toast) {
                alert("Hello world!");
              }
            ],
            [
              '<button>Close</button>',
              function (instance, toast) {
                instance.hide({
                  transitionOut: 'fadeOutUp'
                }, toast);
              }
            ]
          ]
        })
      }
    },
    mounted() {
      bus.$on('success', (data) => {
        this.success(data)
      })
      bus.$on('error', (data) => {
        this.error(data)
      })
    }
  }
</script>