<template>
    <div class="widget padding-x uk-margin-small-top" ref="mainContainer">
        <div>
            <div class="widget-title">
                <h4 class="f-s-13">{{options.title | lang}}</h4>
                <h6 class="f-s-090">{{options.subtitle | lang}}</h6>
            </div>
            <div class="widget-content user-slider-widget">
                <div class="user-slider">
                    <!-- <slider type="UserWithoutButtonItem" :options="{subclass: 'splide__slide mw-1'}" :breakpoints="breakpoints" :items="items"></slider> -->
                    <flicking :options="{ renderOnlyVisible: false, align: 'prev', preventClickOnDrag: true, bound: true, circularFallback: 'bound'}">
                        <div class="flicking-panel" v-for="(item, index) in items" :key="index">
                            <UserWithoutButtonItem :item="item"/>
                        </div>
                    </flicking>
<!--                    <div class="user-slider-items uk-text-center" ref="userSliderContents"-->
<!--                         :style="{height: height}">-->
<!--                        <div class="swiper-main promoted-user-slider">-->
<!--                            <Items subclass="swiper-slide" class="swiper-wrapper" :type="'UserWithoutButtonItem'"-->
<!--                                   :items.sync="items"/>-->
<!--                        </div>-->
<!--                        <div class="uk-clearfix"></div>-->
<!--                    </div>-->
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import axios from 'axios'
    import Vue from 'vue'
    import resize from 'vue-resize-directive'
    import UserWithoutButtonItem from "@/components/Items/UserWithoutButtonItem"
    export default {
        props: ['options', 'value'],
        components: {
            UserWithoutButtonItem, ImagePlaceholder
        },
        directives: {
            resize
        },
        data() {
            return {
                isLoading: false,
                staticItem: [
                    {
                        id: 0,
                        fullname: 'Promote'
                    }
                ],
                imagesLoadedCnt: 0,
                imagesLoaded: false,
                itemsLoaded: false,
                items: [{},{},{},{},{}],
                sliderInit: false,
                itemsCnt: 0,
                initialized: false,
                emptyCount: [...Array(20).keys()],
                key: 'staticKey',
                height: 'auto',
                width: 0,
                currentSlide: 0,
                coversLoadedStatus: [],
                mouse: {
                    startedAt: 0,
                    endAt: 0,
                    lastPos: 0
                },
                lastStatus: false,
                slidesPerView: 14.5,
                spaceBetween: 20,
                // breakpoints: {
                //     640: {
                //         slidesPerView: 4.5,
                //         spaceBetween: 12,
                //         freeMode: true,
                //         freeModeMomentum: true,
                //         freeModeMomentumRatio: '0.5',
                //     },
                //     1080: {
                //         slidesPerView: 6.5,
                //         spaceBetween: 12
                //     },
                //     1600: {
                //         slidesPerView: 9.5,
                //         spaceBetween: 12
                //     },
                //     2000: {
                //         slidesPerView: 11.5,
                //         spaceBetween: 20
                //     },
                //     10000: {
                //         slidesPerView: 15.5,
                //         spaceBetween: 20
                //     }
                // },
                breakpoints: {
                    640: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                        freeMode: true,
                        freeModeMomentum: true,
                        freeModeMomentumRatio: '0.5',
                    },
                    1080: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    1600: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    2000: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    10000: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    }
                }
            }
        },
        created() {

        },

        beforeDestroy() {
        },
        methods: {
            destroy() {
            },
            parseData() {
                axios.get(API.users + '?sections[]=Promoted&count=20')
                    .then((res) => {
                        let item = this.staticItem
                        let items = res.data
                        this.items = item.concat(items)
                    })
            },
            defaultItems() {
                let tmp = []
                for(let i = 0; i<12; i++) {
                    tmp.push({})
                }
                this.items = tmp
            }
        },
        mounted() {
            // setTimeout(() => {
            //     this.items.push({some: 1})
            // }, 2000)
            // this.items[0] = {hellow: 1}
            // this.defaultItems()
            // this.init()
            // if(this.authStatus!=='loading') {
            //     this.parseData()
            // }
            this.parseData()
        },
        watch: {
            '$route': function (route) {
                // this.destroy()
                // this.init()
                // this.items = []
                // this.parseData()
            },
            items() {
                // console.log('this.userSlider', this.userSlider)
                // if(this.userSlider===undefined) {
                //     this.init()
                // } else {
                //     this.userSlider.update()
                // }
                // if(this.userSlider===undefined) {
                //     this.destroy()
                //     setTimeout(() => {
                //         this.init()
                //     }, 0)
                // } else {
                //     this.userSlider.destroy(true, false)
                //     setTimeout(() => {
                //         this.init()
                //     }, 100)
                // }

            },
            auth() {
                // this.defaultItems()
                // this.parseData()
            }
        },
        computed: {
            auth() {
                return this.$store.getters.getProfile
            },
            authStatus() {
                return this.$store.getters.getProfileStatus
            }
        }
    }
</script>