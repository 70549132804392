<template>
    <div>
        <component @clicked="clickAds(item)" :options="options" :data-id="'ads' + item.id" v-for="(item, index) in items"
                   :item.sync="item" :index="index"
                   :class="{'loading': (inLoads.indexOf(item.id)!=-1)}"
                   v-bind:is="type" :key="type + '_' + item.id"></component>
    </div>
</template>
<script>
    import AdsBillboardItem from "@/components/Items/AdsBillboardItem";
    import helper from '../../helpers/helper'
    import axios from 'axios'
    export default {
        data () {
            return {
                helper: helper,
                inLoads: []
            }
        },
        computed: {

        },
        components: {
            AdsBillboardItem
        },
        watch: {
        },
        props: {
            type: {
                type: String
            },
            items: {
                type: Array
            },
            sort: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            enableSort: {
                type: Boolean,
                default: false
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            subclass: {
                type: String,
                default: ''
            }
        },
        methods: {
            clickAds(ads) {
                switch (ads.operation) {
                    case 'OPENWEBSITE':
                        if(ads.opens.indexOf('https://vowave.com')!=-1) {
                            this.$router.push(ads.opens);
                        } else {
                            window.open(ads.opens, '_blank');
                        }
                        break
                    case 'OPENPLAYER':
                        this.inLoads.push(ads.id)
                        axios.get(API.posts + '?id=' + ads.opens)
                            .then((res) => {
                                this.inLoads = []
                                this.$router.push(helper.permalink(res.data))
                                //bus.$emit('play', res.data, [res.data])
                            })
                            .catch(() => {
                                bus.$emit('error', 'Post not found!')
                            })
                        break
                    case 'OPENPROFILE':
                        this.inLoads.push(ads.id)
                        axios.get(API.users + '?id=' + ads.opens)
                            .then((res) => {
                                this.inLoads = []
                                this.$router.push(helper.permalink(res.data))
                            })
                            .catch(() => {
                                this.inLoads = []
                                bus.$emit('error', 'User not found!')
                            })
                        break
                }
            }
        },
        mounted () {
        }
    }
</script>
