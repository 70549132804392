<template>
    <div class="page-main">
        <div v-html="html"></div>
        <div v-if="isLoading" class="uk-text-center">
            <text-skeleton width="60px" height="8px"></text-skeleton>
            <br>
            <text-skeleton width="100%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="90%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="100%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="40%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="100%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="80%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="100%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="100%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="40%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="100%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <text-skeleton width="10%" height="4px" :style="{marginTop: marginTop}"></text-skeleton>
            <br>
<!--            <i uk-spinner></i>-->
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import TextSkeleton from "@/components/Tools/TextSkeleton";
    export default {
        components: {
            TextSkeleton
        },
        data() {
            return {
                html: '',
                isLoading: false,
                marginTop: '15px'
            }
        },
        mounted() {
            this.isLoading = true
            axios.get(API.page + '/privacy')
                .then((resp) => {
                    this.isLoading = false
                    this.html = resp.data.contents
                })
                .catch(() => {
                    this.isLoading = false
                })
        }
    }
</script>