let En = [
    "For you",
    "Home",
    "Explore",
    "Charts",
    "Discover",
    "Search for artists, sounds, videos and more...",
    "Up Next",
    "Lyrics",
    "Promoted Users",
    "Artists that promoted themselves",
    "Artists You Should Know",
    "Lyrics",
    "Login",
    "Email or Username",
    "Password",
    "Forgot Password?",
    "Remember Me",
    "login",
    "Username Or Password is incorrect.",
    "Please fill the fields.",
    "Connection lost. Please check your network",
    "Popular Sounds",
    "Most streamed & liked sounds of this artist",
    "Recent Releases",
    "Latest releases of {artist}",
    "Photos",
    "Like",
    "Share",
    "More",
    "Download",
    "Please Login to write a comment...",
    "Write a comment...",
    "SUBMIT",
    "Info",
    "Ads",
    "Lyrics",
    "Info",
    "Recent Likes",
    "Latest Videos",
    "Recently released videos by {artist}",
    "Write a comment at {time}",
    "Register",
    "Continue",
    "Username",
    "Full Name",
    "Email",
    "Password",
    "OR",
    "Follow",
    "Unfollow",
    "Views",
    "Followers",
    "Posts",
    "Info",
    "Photos",
    "Recent Likes",
    "Account Activation",
    "Your account has been successfully activated with this email address ({email}), Please login to continue.",
    "Please login to continue.",
    "This account has already been activated.",
    "Sorry, We couldn\'t find any account with this information to activate it. Please try registering a new account.",
    "Activation code is incorrect!",
    "Activation code has expired!",
    "We have sent a verification code to your email address, Please use it to verify your account.",
    "By using our services you agree to our use of cookies and Terms of services.",
    "You can find more information by reading our Privacy Policy.",
    "Submit",
    "Reposts",
    "Albums",
    "Latest albums",
    "Save",
    "Add To Album",
    "Add To Playlist",
    "Edit",
    "Promote This Post",
    "Block Countries",
    "Embed",
    "Create New Album",
    "Create New Playlist",
    "Back",
    "Delete",
    "Album Name",
    "Playlist Name",
    "Public",
    "Similar Users",
    "People like {artist} based on their activities",
    "Remove From Playlist",
    "Remove From Album",
    "{artist}\'s Playlists",
    "Success",
    "Error",
    "\'{title}\' removed from {type} successfully.",
    "\'{title}\' was added successfully to your {type}.",
    "Playlist",
    "Album",
    "Sound",
    "Video",
    "Blog",
    "Something went wrong, Please try again.",
    "\'{title}\' was removed successfully.",
    "Overview",
    "Sounds",
    "Videos",
    "Albums",
    "Playlists",
    "Reposts",
    "Photos",
    "Info",
    "Manage",
    "Delete Album",
    "Delete Album & Tracks",
    "Delete Playlist",
    "Type to search..",
    "Users",
    "Sounds",
    "Playlists",
    "Albums",
    "Videos",
    "Search results for",
    "No results found!",
    "Likes & Reposts",
    "Report",
    "Now Trending",
    "Most important contents",
    "VOWAVE Music Chart!",
    "This month\’s top releases",
    "New Releases",
    "Fresh singles, albums & remixes",
    "New Videos",
    "Hot new hit videos",
    "Who To Follow",
    "Playlists",
    "Lastest playlists of {user}",
    "Square Ads",
    " ",
    "Promoted Posts",
    "Posts which are promoted by artists"
]
export default En