<template>
  <div class="widget padding-x uk-margin-small-top" ref="mainContainer">
    <div>
      <div class="widget-title">
        <h4 class="f-s-13">{{ options.title | lang }}</h4>
        <h6 class="f-s-090">{{ options.subtitle | lang }}</h6>
      </div>
      <div class="widget-content">
        <div class="music-ads-slider">
          <flicking
            :options="{
              renderOnlyVisible: false,
              align: 'prev',
              preventClickOnDrag: true,
              bound: true,
              circularFallback: 'bound',
              panelsPerView: slidesPerView,
            }"
          >
            <div
              class="flicking-panel"
              v-for="(item, index) in items"
              :key="index"
            >
              <AdsBillboardItem
                @clicked="clickAds(item)"
                :options="{ subclass: (inLoads.indexOf(item.id)!=-1) ? 'loading' : '' }"
                :item="item"
              />
            </div>
          </flicking>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import AdsBillboardItem from "@/components/Items/AdsBillboardItem";
import helper from '@/helpers/helper'
export default {
  components: {
    AdsBillboardItem,
  },
  data() {
    return {
        inLoads: [],
      items: [{}, {}, {}, {}, {}],
      musicAdsSliderParentKey: "defultKey",
      isLoading: false,
      dimentions: {},
      helper: helper,
      breakpoints: {
        640: {
          slidesPerView: 1.1,
          spaceBetween: 10,
        },
        1000: {
          slidesPerView: 1.3,
          spaceBetween: 10,
        },
        1080: {
          slidesPerView: 2.1,
          spaceBetween: 10,
        },
        1400: {
          slidesPerView: 2.8,
          spaceBetween: 15,
        },
        2000: {
          slidesPerView: 2.8,
          spaceBetween: 15,
        },
        3000: {
          slidesPerView: 3.8,
          spaceBetween: 15,
        },
        4000: {
          slidesPerView: 6.2,
          spaceBetween: 25,
        },
      },
    };
  },
  props: {
    options: {},
  },
  methods: {
    clickAds(ads) {
      switch (ads.operation) {
        case "OPENWEBSITE":
          if (ads.opens.indexOf("https://vowave.com") != -1) {
            this.$router.push(ads.opens);
          } else {
            window.open(ads.opens, "_blank");
          }
          break;
        case "OPENPLAYER":
          this.inLoads.push(ads.id);
          axios
            .get(API.posts + "?id=" + ads.opens)
            .then((res) => {
              this.inLoads = [];
              this.$router.push(helper.permalink(res.data));
              //bus.$emit('play', res.data, [res.data])
            })
            .catch((e) => {
              bus.$emit("error", "Post not found!");
            });
          break;
        case "OPENPROFILE":
          this.inLoads.push(ads.id);
          axios
            .get(API.users + "?id=" + ads.opens)
            .then((res) => {
              this.inLoads = [];
              this.$router.push(helper.permalink(res.data));
            })
            .catch((e) => {
              this.inLoads = [];
              bus.$emit("error", "User not found!");
            });
          break;
      }
    },
    getData() {
      this.isLoading = true;
      axios
        .get(this.options.url)
        .then((res) => {
          this.items = res.data;
          this.isLoading = false;
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.getData();
    }, Math.floor(Math.random() * 1000));
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    slidesPerView() {
      try {
        const keys = Object.keys(this.breakpoints);
        let br = null;
        for (const k of keys) {
          if (k >= window.screen.width) {
            br = this.breakpoints[k];
            break;
          }
        }
        return br.slidesPerView;
      } catch (e) {
        return 1.8;
      }
    },
    screenWidth() {
      return window.screen.width;
    },
  },
};
</script>