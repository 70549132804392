<template>
    <ul class="sidebar-menu-ul">
        <li v-for="item in items" v-if="!item.auth || auth" class="f-s-085" :key="item.name" :class="{'active': currentRouteName===item.name}">
            <router-link :to="item.uri">
                <i :class="item.icon"></i>
                <span>{{item.label}}</span>
            </router-link>
        </li>
    </ul>
</template>
<style>
    .sidebar-menu-ul {
        display: flex;
        flex-direction: column;
        margin: 0 0 15px !important;
    }
    .sidebar-menu-ul > li {
        padding-left: 25px !important;
        display: flex;
        vertical-align: center;
        justify-items: center;
    }
    .sidebar-menu-ul > li, .sidebar-menu-ul > li a {
        color: #ccc;
    }
    .sidebar-menu-ul > li span {
        display: inline-flex;
        line-height: 36px;
        padding-left: 30px;
    }
    .sidebar-menu-ul > li.active, .sidebar-menu-ul > li.active a {
        color: #fff
    }
    .sidebar-menu-ul a:hover {
        text-decoration: none;
    }
    .sidebar-menu-ul > li i {
        width: 20px;
        height: 20px;
        line-height: 20px;
        font-size: 18px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        text-align: center;
    }
</style>
<script>
    import helper from "@/helpers/helper";

    export default {
        data() {
            return {

            }
        },
        props: {
            items: {
                type: Array,
                default: () => {
                    return {}
                }
            }
        },
        methods: {
            subIsActive(input) {
                const paths = Array.isArray(input) ? input : [input]
                return paths.some(path => {
                    return this.$route.path.indexOf(path) === 0 // current path starts with this path string
                })
            }
        },
        computed: {
            currentRouteName() {
                return this.$route.name
            },
            auth() {
                return this.$store.getters.isAuthenticated
            }
        }
    }
</script>