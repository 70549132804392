var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "sound-large-player-cover-container",
        class: { "coming-soon": _vm.comingSoon },
        style: { "background-image": "url(" + _vm.blur + ")" },
      },
      [
        _c(
          "div",
          {
            staticClass: "sound-large-player-cover",
            style: { "background-color": _vm.background },
          },
          [
            _c("div", { staticClass: "sound-large-player-cover-content" }, [
              _c(
                "div",
                { staticClass: "uk-grid-10", attrs: { "uk-grid": "" } },
                [
                  _c("div", { staticClass: "uk-width-1-1" }),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-1-1 uk-width-auto@m" }, [
                    _c(
                      "div",
                      {
                        staticClass: "sound-image",
                        on: {
                          click: function ($event) {
                            return _vm.playerAction()
                          },
                        },
                      },
                      [
                        _c("ImagePlaceholder", {
                          attrs: {
                            radius: "0.2rem",
                            width: "100%",
                            "data-src": _vm._f("attach")(_vm.cover, "m"),
                          },
                        }),
                        _vm._v(" "),
                        !_vm.comingSoon && _vm.sound.realId != undefined
                          ? _c(
                              "div",
                              { staticClass: "play-action-container" },
                              [
                                _c("div", { staticClass: "play-action" }),
                                _vm._v(" "),
                                _vm.current != null &&
                                _vm.current.realId == _vm.sound.realId &&
                                _vm.status === "play"
                                  ? _c("i", {
                                      staticClass: "fas fa-grip-lines-vertical",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.current != null &&
                                _vm.current.realId == _vm.sound.realId &&
                                _vm.status === "wait"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-spinner-third fa-spin",
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.current === null ||
                                _vm.current.realId !== _vm.sound.realId ||
                                (_vm.current.realId === _vm.sound.realId &&
                                  _vm.status === "pause")
                                  ? _c("i", { staticClass: "fas fa-play" })
                                  : _vm._e(),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.comingSoon
                          ? _c(
                              "div",
                              { staticClass: "coming-soon-container f-f-reg" },
                              [
                                _c("div", { staticClass: "coming-soon-main" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-grid-none uk-child-width-auto uk-text-center",
                                      attrs: {
                                        "uk-grid": "",
                                        "uk-countdown":
                                          "date: " + _vm.sound.releaseDate,
                                      },
                                    },
                                    [
                                      _vm._m(0),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "uk-countdown-separator",
                                        },
                                        [_vm._v(":")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(1),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "uk-countdown-separator",
                                        },
                                        [_vm._v(":")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(2),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "uk-countdown-separator",
                                        },
                                        [_vm._v(":")]
                                      ),
                                      _vm._v(" "),
                                      _vm._m(3),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "coming-soon-text" }, [
                                  _vm._v("UNTIL RELEASE"),
                                ]),
                              ]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c("div", { staticClass: "sound-content" }, [
                      _c("div", { staticClass: "sound-content-top" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-grid-10 uk-flex uk-flex-middle",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-width-expand" }, [
                              _vm.sound.realId === undefined
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "sound-title f-s-2 uk-text-truncate",
                                    },
                                    [
                                      _c("TextSkeleton", {
                                        style: { margin: "10px 3px" },
                                        attrs: {
                                          height: "20px",
                                          width: "100px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sound.realId === undefined
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "artist-name f-s-2 uk-text-truncate",
                                    },
                                    [
                                      _c("TextSkeleton", {
                                        style: { margin: "10px 3px" },
                                        attrs: {
                                          height: "12px",
                                          width: "200px",
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sound.realId != undefined
                                ? _c(
                                    "div",
                                    {
                                      directives: [
                                        {
                                          name: "is-visibility",
                                          rawName: "v-is-visibility",
                                          value: (isVisible, entry) =>
                                            _vm.helper.isVisible(
                                              isVisible,
                                              entry,
                                              _vm.sound
                                            ),
                                          expression:
                                            "\n                        (isVisible, entry) =>\n                          helper.isVisible(isVisible, entry, sound)\n                      ",
                                        },
                                      ],
                                      staticClass:
                                        "sound-title f-s-2 uk-text-truncate",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to:
                                              "/" +
                                              _vm.sound.user.username +
                                              "/" +
                                              _vm.sound.slug,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.sound.title) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.sound.realId != undefined
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "artist-name f-s-10 uk-text-truncate",
                                    },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          staticClass: "artist teammate",
                                          attrs: {
                                            to: "/" + _vm.sound.user.username,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(_vm.sound.user.fullname) +
                                              "\n                      "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm._l(
                                        _vm.sound.users,
                                        function (teammate, index) {
                                          return _c(
                                            "router-link",
                                            {
                                              key: index,
                                              staticClass: "artist teammate",
                                              attrs: {
                                                to: "/" + teammate.username,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                ", " +
                                                  _vm._s(teammate.fullname) +
                                                  "\n                      "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                    ],
                                    2
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm.sound.realId != undefined
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-width-auto uk-text-right f-s-090",
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "genres" },
                                      _vm._l(
                                        _vm.sound.genres,
                                        function (genre, index) {
                                          return _c(
                                            "router-link",
                                            {
                                              key: index,
                                              attrs: {
                                                to: "/genre/" + genre.slug,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                        " +
                                                  _vm._s(genre.name) +
                                                  "\n                      "
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "time f-s-080" }, [
                                      _vm._v(
                                        "\n                      " +
                                          _vm._s(
                                            _vm._f("minutes")(
                                              _vm.sound.metas.attachment
                                                .duration
                                            )
                                          ) +
                                          "\n                    "
                                      ),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.sound.realId != undefined
                              ? _c("div", { staticClass: "uk-width-1-1" }, [
                                  _vm.$route.name !== "sound"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "playlist-name f-s-090 uk-text-truncate",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-compact-disc",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "router-link",
                                            {
                                              attrs: {
                                                to: _vm.helper.permalink(
                                                  _vm.playlist
                                                ),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.playlist.title) +
                                                  "\n                      "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.$route.name !== "sound"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "playlist-tracks-count f-s-090 uk-text-truncate",
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-stream",
                                          }),
                                          _vm._v(
                                            "\n                      " +
                                              _vm._s(
                                                _vm.playlist.tracks.length
                                              ) +
                                              " Tracks\n                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.sound.realId != undefined
                        ? _c("div", { staticClass: "sound-content-bottom" }, [
                            _c("div", { staticClass: "f-s-090" }, [
                              _c(
                                "button",
                                { staticClass: "btn-action btn-trans" },
                                [
                                  _c("i", {
                                    staticClass: "fa-heart",
                                    class: {
                                      far: !_vm.sound.is.liked,
                                      "fas active": _vm.sound.is.liked,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.like(_vm.sound)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.sound.stats.likes === 0
                                    ? _c("span", [_vm._v(" Like ")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.sound.stats.likes != 0
                                    ? _c("span", [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm._f("numformat")(
                                                _vm.sound.stats.likes
                                              )
                                            ) +
                                            "\n                    "
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action btn-trans",
                                  class: {
                                    "active active-gold": _vm.sound.is.reposted,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.repost(_vm.sound)
                                    },
                                  },
                                },
                                [
                                  _c("i", { staticClass: "far fa-retweet" }),
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(
                                        _vm._f("numformat")(
                                          _vm.sound.stats.reposts
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action btn-trans",
                                  on: { click: _vm.copyLink },
                                },
                                [
                                  _c("i", { staticClass: "far fa-link" }),
                                  _vm._v(" "),
                                  _c("span", [
                                    _vm._v(_vm._s(_vm._f("lang")("copy_link"))),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _vm.sound.metas.sale && !_vm.sound.is.bought
                                ? _c(
                                    "button",
                                    {
                                      staticClass: "btn-action btn-trans",
                                      on: { click: _vm.buy },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-cloud-download",
                                      }),
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Buy")]),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn-action btn-trans",
                                  on: { click: _vm.moreMenu },
                                },
                                [
                                  _c("i", { staticClass: "far fa-ellipsis-h" }),
                                  _vm._v(" "),
                                  _c("span", [_vm._v("More")]),
                                ]
                              ),
                            ]),
                          ])
                        : _vm._e(),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.sound.id !== undefined
                    ? _c(
                        "div",
                        { staticClass: "uk-width-1-1 canvas-container" },
                        [
                          _c("Waveform", {
                            key: "waveform_" + _vm.sound.id,
                            attrs: { sound: _vm.sound },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sound.id != undefined
                    ? _c("div", { staticClass: "uk-width-1-1" }, [
                        _c("div", { staticClass: "new-comment-container" }, [
                          !_vm.disableCm
                            ? _c(
                                "div",
                                {
                                  staticClass: "uk-grid-5",
                                  attrs: { "uk-grid": "" },
                                },
                                [
                                  _vm._m(4),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "uk-width-expand" },
                                    [
                                      _c("div", { staticClass: "right" }, [
                                        _vm.auth.id === undefined
                                          ? _c("input", {
                                              ref: "comment-txt",
                                              staticClass:
                                                "new-comment-text f-s-085",
                                              attrs: {
                                                type: "text",
                                                readonly: "true",
                                                placeholder: _vm._f("lang")(
                                                  "comment_placeholder_guest"
                                                ),
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.$parent.showLogin = true
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.auth.id != undefined &&
                                        _vm.duration === 0
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.comment,
                                                  expression: "comment",
                                                },
                                              ],
                                              ref: "comment-txt",
                                              staticClass:
                                                "new-comment-text f-s-085",
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm._f("lang")(
                                                  "comment_placeholder_user"
                                                ),
                                              },
                                              domProps: { value: _vm.comment },
                                              on: {
                                                keyup: _vm.keyupComment,
                                                focus: function ($event) {
                                                  _vm.commentAt = _vm.minutesDur
                                                  _vm.commentAtSec =
                                                    _vm.duration
                                                },
                                                focusout: function ($event) {
                                                  _vm.commentAt = null
                                                  _vm.commentAtSec = null
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.comment =
                                                    $event.target.value
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.auth.id != undefined &&
                                        _vm.duration > 0
                                          ? _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.comment,
                                                  expression: "comment",
                                                },
                                              ],
                                              ref: "comment-txt",
                                              staticClass:
                                                "new-comment-text f-s-085",
                                              attrs: {
                                                type: "text",
                                                placeholder: _vm._f("lang")(
                                                  "comment_placeholder_at_time",
                                                  {
                                                    time:
                                                      _vm.commentAt != null
                                                        ? _vm.commentAt
                                                        : _vm.minutesDur,
                                                  }
                                                ),
                                              },
                                              domProps: { value: _vm.comment },
                                              on: {
                                                keyup: _vm.keyupComment,
                                                focus: function ($event) {
                                                  _vm.commentAt = _vm.minutesDur
                                                  _vm.commentAtSec =
                                                    _vm.duration
                                                },
                                                focusout: function ($event) {
                                                  _vm.commentAt = null
                                                  _vm.commentAtSec = null
                                                },
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.comment =
                                                    $event.target.value
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-width-auto uk-flex uk-flex-middle f-s-1",
                                    },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass: "send-btn",
                                          on: { click: _vm.sendComment },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm._f("lang")("submit")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.sound.id != undefined
                    ? _c("div", { staticClass: "uk-width-1-1" }, [
                        _c("div", { staticClass: "uk-flex" }, [
                          _c("div", { staticClass: "f-s-080 flex-right" }, [
                            _c("div", { staticClass: "info-box" }, [
                              _c("i", { staticClass: "far fa-eye" }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(
                                    _vm._f("numformat")(
                                      _vm.sound.stats.views +
                                        _vm.sound.stats.downloads +
                                        _vm.sound.stats.plays
                                    )
                                  ) +
                                  "\n                "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.helper.can("play", _vm.sound)
                              ? _c("div", { staticClass: "info-box" }, [
                                  _c("i", { staticClass: "far fa-play" }),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("numformat")(
                                          _vm.sound.stats.plays
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.helper.can("download", _vm.sound)
                              ? _c("div", { staticClass: "info-box" }, [
                                  _c("i", {
                                    staticClass: "far fa-cloud-download",
                                  }),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("numformat")(
                                          _vm.sound.stats.downloads
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("div", { staticClass: "info-box" }, [
                              _c("i", { staticClass: "far fa-clock" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.sound.releaseDateTimeago) +
                                  "\n                "
                              ),
                            ]),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "uk-countdown-number uk-countdown-days" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "uk-countdown-label uk-margin-small uk-text-center" },
        [_vm._v("\n                        Days\n                      ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "uk-countdown-number uk-countdown-hours" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "uk-countdown-label uk-margin-small uk-text-center" },
        [_vm._v("\n                        Hours\n                      ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "uk-countdown-number uk-countdown-minutes" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "uk-countdown-label uk-margin-small uk-text-center" },
        [_vm._v("\n                        Minutes\n                      ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "uk-countdown-number uk-countdown-seconds" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "uk-countdown-label uk-margin-small uk-text-center" },
        [_vm._v("\n                        Seconds\n                      ")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-width-auto" }, [
      _c("div", { staticClass: "left" }, [
        _c("i", { staticClass: "fas fa-quote-right" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }