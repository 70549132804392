var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "billboard-ads",
        class: _vm.options.subclass,
        style: {
          backgroundColor: _vm.item.backgroundColor,
          backgroundImage: "url(" + _vm.item.background + ")",
        },
        on: { click: _vm.callback },
      },
      [
        _c("image-placeholder-bg", {
          attrs: {
            "background-color": _vm.item.backgroundColor,
            "data-src": _vm.item.background,
          },
        }),
        _vm._v(" "),
        _vm._m(0),
        _vm._v(" "),
        _vm.item.label != null && _vm.item.label != ""
          ? _c("div", { staticClass: "label" }, [
              _c("span", [_vm._v(_vm._s(_vm.item.label))]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.title != null && _vm.item.title != ""
          ? _c("div", { staticClass: "bottom-bar" }, [
              _c("div", { staticClass: "left-side" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.item.title) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.item.subtitle) +
                      "\n                "
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "right-side" }, [
                _vm.item.square != null
                  ? _c("div", [_c("img", { attrs: { src: _vm.item.square } })])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "loading" }, [
      _c("i", { attrs: { "uk-spinner": "ratio: 2" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }