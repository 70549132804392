var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "padding-x widget uk-margin-top not-found-page" },
    [
      _c("h2", [_vm._v("404 error")]),
      _vm._v(" "),
      _c(
        "h6",
        [
          _vm._v("\n        This page doesn't exist."),
          _c("br"),
          _c("br"),
          _vm._v(
            "\n        This probably happened because of a typo or the wrong keyboard layout."
          ),
          _c("br"),
          _c("br"),
          _vm._v("\n        If you got here from a link, please "),
          _c("router-link", { attrs: { to: "/explore/sounds" } }, [
            _vm._v("visit our explore"),
          ]),
          _vm._v(".\n    "),
        ],
        1
      ),
      _vm._v(" "),
      _c("h4", [_vm._v("Find music you'll like")]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "genres-list" },
        _vm._l(_vm.settings.genres, function (genre, index) {
          return _c(
            "span",
            [
              _vm._v(_vm._s(index !== 0 ? ", " : "")),
              _c(
                "router-link",
                { attrs: { to: "/explore/sounds/?genres=" + genre.id } },
                [_vm._v(_vm._s(genre.name))]
              ),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }