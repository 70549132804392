<template>
    <div class="padding-x padding-y">
        <Items :type="template" :options="{subclass: tsubclass}" :class="tclass" :items="items"></Items>
        <div class="uk-text-center" v-if="loading">
            <i uk-spinner></i>
        </div>
        <Empty v-if="!loading && !items.length"/>
        <div class="uk-text-center" v-is-visibility="(visible) => tryLoadMore(visible)" v-if="!loading && !end">
            <i uk-spinner></i>
        </div>
    </div>
</template>
<script>
    import Items from "@/components/Items/Items";
    import axios from 'axios'
    import Empty from "@/components/Tools/Empty";
    export default {
        components: {
            Empty,
            Items,
        },
        data() {
            return {
                items: [],
                end: false,
                loading: false,
                offset: 0
            }
        },
        mounted() {
            this.offset = 0
            this.parseData()
        },
        computed: {
            template() {
                if(this.$route.params.type==='users') {
                    return 'GridUserItem'
                }
                return 'VPostMedItem'
            },
            tclass() {
                if(this.$route.params.type==='users') {
                    return 'uk-grid uk-grid-20'
                }
                return ''
            },
            tsubclass() {
                if(this.$route.params.type==='users') {
                    return 'uk-width-1-5@m uk-width-1-3'
                }
                return ''
            },
            count() {
                let params = {...this.$route.query, ...this.$route.params}
                if(params.type==='users') {
                    return 40
                }
                return 20
            }
        },
        watch: {
            '$route': function(newVal) {
                this.offset = 0
                this.items = [];
                this.end = false;
                this.parseData()
            }
        },
        methods: {
            tryLoadMore(visible) {
                if(visible) {
                    this.parseData(true)
                }
            },
            parseData(type) {
                if(this.loading) return;
                let params = {...this.$route.query, ...this.$route.params};
                let url = null;
                let rp = {
                    count: this.count
                }
                if(params.type!==undefined) {
                    if(params.moods!==undefined) {
                        rp.moods = params.moods;
                    }
                    if(params.q!==undefined) {
                        rp.q = params.q;
                    }
                    if(params.genres!==undefined) {
                        rp.genres = params.genres;
                    }
                    if(params.languages!==undefined) {
                        rp.languages = params.languages;
                    }
                    if(params.username!==undefined) {
                        rp.username = params.username;
                    }
                    if(params.tags!==undefined) {
                        rp.tags = params.tags
                    }
                    if(params.recordlabels!==undefined) {
                        rp.recordLabels = params.recordlabels
                    }
                    if(['all', 'sounds', 'videos', 'albums', 'playlists'].indexOf(params.type)!==-1) {
                        url = API.posts
                        let postTypes = {
                            sounds: 'SND',
                            videos: 'VID',
                            albums: 'ALB',
                            playlists: 'PLY'
                        }
                        rp.postType = postTypes[params.type]
                    } else if(params.type==='users') {
                        url = API.users
                        if(params.countries!=undefined) {
                            rp.countries = params.countries
                        }
                    }
                }
                if(url===null) {
                    this.end = true
                    return
                }
                if(type!==undefined && type) {
                    rp.skip = this.offset
                } else if(this.$route.name === 'search') {
                    rp.skip = this.offset
                }
                this.loading = true
                axios.get(url, {
                    params: rp
                })
                    .then((resp) => {
                        this.offset += this.count
                        // if(localStorage.getItem('autoInitializePlayer')!==null) {
                        //     bus.$emit('managePlaylist', resp.data, resp.data[0], true)
                        // }
                        this.loading = false
                        bus.$emit('seeAllLoaded')
                        if(resp.data.length < 1) this.end = true
                        this.items = this.items.concat(resp.data)
                    })
                    .catch(() => {
                        this.loading = false
                        bus.$emit('seeAllLoaded')
                    })
            }
        }
    }
</script>