var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("Adsense", {
        staticClass: "adsbygoogle",
        attrs: {
          "data-adtest": "on",
          "data-ad-client": "ca-pub-8118624680281970",
          "data-ad-slot": "7392234444",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }