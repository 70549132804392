<template>
  <div class="widget" ref="soundTabs" v-if="vars.realId != null">
    <div class="post-tabs-container">
      <div class="center-tabs">
        <a
          v-for="(tab, index) in tabs"
          @click="setTab(index)"
          :class="{ selected: currentTab === tab }"
          v-bind:key="'tab_' + index"
          href="javascript:void(0)"
        >
          <span v-if="tab != 'comments'">{{ tabnames[tab] | ucfirst }}</span>
          <span v-if="tab === 'comments'"
            ><i class="fal fa-comment-alt-lines"></i
            >{{
              vars.commentsCnt === "0" ? "" : "&nbsp;&nbsp;" + vars.commentsCnt
            }}</span
          >
        </a>
      </div>
      <div class="center-tabs-contents">
        <div v-if="currentTab === 'info'">
          <div class="info-tab-box f-s-085">
            <div class="info-tab-box-item">
              <span class="tit">Release Type:</span>
              {{ settings.releaseTypes[vars.releaseType] }}
            </div>
            <div v-if="vars.languages.length" class="info-tab-box-item">
              <span class="tit">Language(s):</span>
              <span v-for="(language, index) in vars.languages" :key="index"
                >{{ index !== 0 ? ", " : ""
                }}<router-link
                  :to="
                    '/explore/' + postTypeFull + '/?languages=' + language.id
                  "
                  >{{ language.name }}</router-link
                ></span
              >
            </div>
            <div v-if="vars.recordLabels.length" class="info-tab-box-item">
              <span class="tit">RecordLabel(s):</span>
              <span v-for="(label, index) in vars.recordLabels" :key="index"
                >{{ index !== 0 ? ", " : ""
                }}<router-link
                  :to="
                    '/explore/' + postTypeFull + '/?recordlabels=' + label.id
                  "
                  >{{ label.name }}</router-link
                ></span
              >
            </div>
            <div v-if="vars.tags.length" class="info-tab-box-item">
              <span class="tit">Tag(s):</span>
              <span v-for="(tag, index) in vars.tags" :key="index"
                >{{ index !== 0 ? ", " : ""
                }}<router-link
                  :to="'/explore/' + postTypeFull + '/?tags=' + tag.slug"
                  >{{ tag.name }}</router-link
                ></span
              >
            </div>
            <div class="info-tab-box-item">
              <span class="tit">Release Date:</span>
              {{ releaseDate }}
            </div>
            <div v-if="vars.licenseType != null" class="info-tab-box-item">
              <span class="tit">License:</span>
              {{ $parent.settings.licenseTypes[vars.licenseType] }}
            </div>
          </div>
          <div class="post-description f-s-085">
            <div uk-grid class="uk-grid-10 uk-flex uk-flex-middle">
              <div class="uk-width-auto">
                <div class="width-30 uk-text-center">
                  <i class="fal fa-info"></i>
                </div>
              </div>
              <div
                class="uk-width-expand"
                v-is-visibility="(visible) => visibleDescription(visible)"
              >
                <div v-if="vars.description === null">
                  <text-skeleton width="200px" height="10px"></text-skeleton>
                  <text-skeleton
                    width="120px"
                    height="10px"
                    :style="{ marginTop: '5px' }"
                  ></text-skeleton>
                </div>
                <div v-if="vars.description === ''">No description!</div>
                <div class="whitespace-pre" v-html="vars.description"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="currentTab === 'lyrics'">
          <div
            class="uk-text-center info-tab-box"
            v-if="vars.lyrics === null"
            v-is-visibility="(visible) => visibleDescription(visible)"
          >
            <text-skeleton-shimmer
              v-for="index in lyricsSkeleton"
              :key="index"
              :width="generateRandomWidth() + '%'"
              height="10px"
              :style="{ margin: '10px auto' }"
            ></text-skeleton-shimmer>
          </div>
          <div class="f-s-085 uk-margin-small-top" v-if="vars.lyrics === ''">
            <div uk-grid class="uk-grid-10 uk-flex uk-flex-middle">
              <div class="uk-width-auto">
                <div class="width-30 uk-text-center">
                  <i class="fal fa-align-center"></i>
                </div>
              </div>
              <div class="uk-width-expand">
                <div>No lyrics found for this post!</div>
              </div>
            </div>
          </div>
          <div
            class="info-tab-box f-s-090 lyrics"
            v-if="vars.lyrics !== null && vars.lyrics !== ''"
          >
            {{ vars.lyrics }}
          </div>
        </div>
        <div class="f-s-085" v-if="currentTab === 'comments'">
          <Comments
            @cmAdded="cmAdded"
            :can-comment="vars.canComment"
            :comments-cnt="vars.commentsCnt"
            :real-comments-cnt="vars.mainCommentsCnt"
            :real-id="vars.realId"
          ></Comments>
        </div>
        <div class="f-s-085" v-if="currentTab === 'tracks'">
          <div
            class="manage-playlist"
            ref="manageMode"
            v-if="auth.id === vars.userId"
          >
            <div class="uk-text-right uk-position-relative">
              <button
                @click="manageMode = !manageMode"
                class="manage-playlist-btn"
                :class="{ active: manageMode }"
              >
                <span v-if="!manageMode">{{ "manage" | lang }}</span>
                <span v-if="manageMode">{{ "save" | lang }}</span>
              </button>
              <button
                @click="deleteSet(0)"
                class="manage-playlist-btn other-opts btn-a"
                :class="{ active: manageMode }"
              >
                {{ "delete_album" | lang }}
              </button>
              <button
                @click="deleteSet(1)"
                class="manage-playlist-btn other-opts btn-b"
                :class="{ active: manageMode }"
              >
                {{ "delete_album_with_tracks" | lang }}
              </button>
            </div>
          </div>
          <div class="uk-margin" v-if="manageMode">
            <div class="uk-grid-10 uk-flex align-items-center">
              <div class="uk-width-auto">
                <div>
                  <div class="select-image-full-parent" @click="modals.cover.show = true">
                    <image-placeholder
                      :width="'150px'"
                      :height="'150px'"
                      :data-src="vars.setCover"
                    />
                    <span class="select-image-full">
                      <i class="fal fa-camera"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="uk-width-expand">
                <div class="uk-margin-small">
                  <input
                    type="text"
                    v-model="vars.setTitle"
                    class="uk-input"
                    placeholder="Title"
                  />
                </div>
                <div class="">
                  <textarea
                    type="text"
                    v-model="vars.setDescription"
                    class="uk-textarea"
                    rows="4"
                    placeholder="Description"
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div
            class="v-post-items display-track-num"
            v-if="items.length"
            :class="{ 'manage-mode': manageMode }"
          >
            <div class="v-post-items-container">
              <Items
                @updateItems="updateItems"
                @doRemoveItemFromSet="doRemoveItemFromSet"
                v-if="rItems"
                :items="items"
                type="VPostItem"
                :enableSort="manageMode"
                :sort="sort"
              />
              <!--<draggable @end="saveContent" v-bind="dragOptions" v-model="value.tracks" draggable=".v-post-item" handle=".v-post-move">-->
              <!--<transition-group type="transition" :name="'flip-list'">-->
              <!--<VPostItem @click="clickOnItem(item)" v-for="(item, index) in value.tracks" :item.sync="item" :index="index" v-is-visibility="(isVisible, entry) => helper.isVisible(isVisible, entry, item)" :class="{active: activeItem===item.realId, deleting: deletings.indexOf(item.realId)!==-1}"/>-->
              <!--</transition-group>-->
              <!--</draggable>-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <my-upload
      v-if="manageMode"
      field="cover"
      @crop-upload-success="cropUploadSuccess('cover', $event)"
      @crop-upload-fail="cropUploadFail('cover', $event)"
      v-model="modals.cover.show"
      :width="600"
      :height="600"
      :fullsize="1200"
      method="POST"
      :url="modals.cover.url"
      langType="en"
      :params="modals.cover.params"
      :headers="modals.cover.headers"
      no-square
      :ref="'coverUpload'"
      img-format="jpg"
    ></my-upload>
  </div>
</template>
<style>
.sound-slider-items-container-explore {
  overflow-x: hidden;
}
</style>
<script>
import helper from "../../helpers/helper";
import draggable from "vuedraggable";
import MoreMenu from "../MoreMenu";
import VPostItem from "../Items/VPostItem";
import Comments from "../Comments";
import axios from "axios";
import Items from "../Items/Items";
import ImagePlaceholder from "../Tools/ImagePlaceholder.vue";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import API from "@/api";

const VueScrollTo = require("vue-scrollto");

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
export default {
  props: ["value", "options", "player", "auth"],
  components: {
    Comments,
    draggable,
    MoreMenu,
    Items,
    ImagePlaceholder,
    myUpload,
  },
  data() {
    return {
      scrollToManage: false,
      activeItem: null,
      tabs: ["comments", "info", "lyrics"],
      currentTab: "comments",
      post: null,
      helper: helper,
      items: [],
      vars: {
        commentsCnt: 0,
        realCommentsCnt: 0,
        postType: null,
        realId: null,
        releaseDate: null,
        userId: null,
        language: null,
        releaseType: null,
        languages: [],
        tags: [],
        licenseType: null,
        recordLabels: [],
        lyrics: null,
        description: null,
        setId: null,
        setType: null,
        setTitle: null,
        setDescription: null,
        canComment: null,
        mainCommentsCnt: 0,
        setCover: null,
      },
      lyricsSkeleton: [...Array(20).keys()],
      deletings: [],
      tabnames: {
        info: "info",
        tracks: "tracks",
        lyrics: "lyrics",
      },
      rItems: true,
      manageMode: false,
      sort: {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "v-post-ghost",
        handler: ".v-post-move",
        draggable: ".v-post-item",
        easing: "cubic-bezier(1, 0, 0, 1)",
      },
      modals: {
        cover: {
          show: false,
          url: API.v2.posts,
          headers: {
            Authorization: localStorage.getItem("user-token"),
            Accept: "application/json, text/plain, */*",
          },
          params: {
            fields: "cover",
          },
        },
      },
      hoverTrack: null,
    };
  },
  methods: {
    cropUploadSuccess(type, e) {
      let msg = "Your avatar has been uploaded successfully.";
      this.vars.setCover = this.$options.filters.attach(
            e.attachment,
            "m"
        );
      this.profileLoadedStatus = false;
      this.$refs[type + "Upload"].setStep(1);
      this.modals[type].show = false;
      bus.$emit("success", {
        title: "",
        message: msg,
      });
    },
    cropUploadFail(type, e) {
      // console.log("crop failed");
    },
    cmAdded() {
      this.vars.commentsCnt++;
      // this.vars.mainCommentsCnt++;
    },
    doRemoveItemFromSet(id, index) {
      let type = this.vars.setType;
      this.items.splice(index, 1);
      axios
        .put(API.posts, {
          postType: type,
          id: this.vars.setId,
          tracks: this.$_.pluck(this.items, "realId"),
        })
        .then((res) => {
          bus.$emit("success", {
            title: this.$parent.$options.filters.lang("success"),
            message: this.$parent.$options.filters.lang(
              "title_removed_successfully_type",
              {
                title: this.item.title,
                type: this.$parent.$options.filters.lang(type),
              }
            ),
          });
        });
    },
    generateRandomWidth() {
      let min = 10;
      let rand = Math.floor(Math.random() * 30);
      if (rand < min) return min;
      return rand;
    },
    visibleDescription(visible) {
      // console.log("visible", visible);
      if (visible && this.vars.description === null) {
        this.getPostTexts();
      }
    },
    getPostTexts() {
      axios.get(API.postTexts + "?id=" + this.vars.realId).then((resp) => {
        this.vars.description =
          resp.data.description === null ? "" : resp.data.description;
        this.vars.lyrics = resp.data.lyrics === null ? "" : resp.data.lyrics;
      });
    },
    deleteSet(withTracks) {
      axios
        .delete(API.posts, {
          data: {
            withTracks: withTracks,
            id: this.value.realId,
          },
        })
        .then((res) => {
          bus.$emit("success", {
            title: "title",
            message: "message",
          });
          this.$router.push({
            name: "profileArtist",
            params: { username: this.value.user.username },
          });
        })
        .catch((e) => {
          bus.$emit("error", {
            title: main.$options.filters.lang("error"),
            message: main.$options.filters.lang("something_went_wrong"),
          });
        });
    },
    clickOnItem() {},
    noaction() {},
    saveContent() {
      this.$emit("update", this.value);
    },
    updateItems(items) {
      this.items = [];
      this.items = items;
      let type = this.vars.setType;
      axios
        .put(API.posts, {
          postType: type,
          id: this.vars.setId,
          tracks: this.$_.pluck(this.items, "realId"),
        })
        .then((res) => {});
      // this.$emit('update', this.value)
    },
    playerAction(e, item) {
      this.hoverTrack = item.realId;
      if (this.manageMode) return;
      if (e.target.className.indexOf("disable-click") !== -1) {
        return;
      }
      let currentTrack = this.currentTrack;
      if (
        currentTrack != null &&
        currentTrack == item.realId &&
        this.player.Player.status === "play"
      ) {
        this.player.Player.pause();
      } else if (
        currentTrack != null &&
        currentTrack == item.realId &&
        this.player.Player.status === "wait"
      ) {
      } else if (
        currentTrack === null ||
        currentTrack !== item.realId ||
        (currentTrack === item.realId && this.player.Player.status === "pause")
      ) {
        this.$parent.play(item, this.value.tracks);
      }
    },
    setTab(index) {
      this.currentTab = this.tabs[index];
      this.manageMode = false;
    },
    like(item) {
      let res = this.helper.like(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    repost(item) {
      let res = this.helper.repost(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    doSave() {
      let data = {
        id: this.vars.setId,
        title: this.vars.setTitle,
        description: this.vars.setDescription,
        postType: this.vars.setType,
      };
      axios.put(API.posts, data).then((res) => {
        bus.$emit("success", {
          title: "",
          message: "Edits has been saved successfully.",
        });
      });
    },
    getDescription() {
      if (this.vars.setDescription !== null) return;
      axios.get(API.postTexts + "?id=" + this.vars.setId).then((resp) => {
        try {
          this.vars.setDescription = resp.data.description;
        } catch (e) {}
      });
    },
  },
  watch: {
    manageMode(manageMode) {
      if (!manageMode) {
        this.doSave();
      } else {
        this.getDescription();
      }
    },
    value: {
      handler: function (val, oldVal) {
        // if(oldVal!=undefined && val.realId===oldVal.realId) {
        //     if(val.tracks!==undefined && val.tracks.length) {
        //
        //     }
        //     return;
        // }
        // if (val.postType === undefined) return;
        // this.tabs = [
        //     'comments', 'info'
        // ];
        // if (val.postType === 'SND') {
        //     this.post = val;
        //     this.currentTab = 'comments'
        //     // if (val.texts != undefined && val.texts.lyrics !== null) {
        //     //     this.tabs.push('lyrics')
        //     // }
        // } else {
        //     this.tabnames.tracks = val.postType === 'ALB' ? 'tracks' : 'track list'
        //     this.currentTab = 'comments'
        //     this.post = val.tracks[val.currentTrack]
        //     // if (this.post.texts != undefined && this.post.texts.lyrics !== null) {
        //     //     this.tabs.push('lyrics')
        //     // }
        //     if (val.tracks.length) {
        //         console.log('oldVal===undefined', oldVal===undefined)
        //         console.log('oldVal.realId !== val.realId', oldVal.realId !== val.realId)
        //         console.log('!this.items.length', !this.items.length)
        //         if(oldVal===undefined || oldVal.realId !== val.realId || !this.items.length) {
        //             this.items = [];
        //             this.tabs.unshift('tracks')
        //             this.currentTab = 'tracks'
        //             val.tracks.map((e) => {
        //                 this.items.push(e)
        //             })
        //         }
        //
        //     }
        //     this.helper.getTexts(this.post.realId)
        // }
      },
      deep: true,
    },
  },
  computed: {
    postTypeFull() {
      try {
        let pt = {
          SND: "sounds",
          VID: "videos",
        };
        return pt[this.vars.postType];
      } catch (e) {}
      return null;
    },
    currentTrack() {
      if (this.player.Player.current != null)
        return this.player.Player.current.realId;
      return null;
    },
    current() {
      if (this.player.Player.current != null) return this.player.Player.current;
      return null;
    },
    releaseDate() {
      if (this.vars.releaseDate != undefined) {
        if (this.vars.releaseDate.length < 17) this.vars.releaseDate += ":00";
        let date = new Date(this.vars.releaseDate.replace(" ", "T"));
        return (
          date.getDate() +
          " " +
          monthNames[date.getMonth()] +
          " " +
          date.getFullYear()
        );
      }
    },
    settings() {
      return this.$store.getters.settings;
    },
  },
  mounted() {
    let pageAction = localStorage.getItem("pageAction");
    if (pageAction === "edit") {
      localStorage.removeItem("pageAction");
      this.scrollToManage = true;
    }
    bus.$on("soundTabData", (data) => {
        console.log('data', data)
      this.modals.cover.url = API.v2.posts + '?id=' + data.setId;
      this.vars.commentsCnt = data.commentsCnt;
      this.vars.mainCommentsCnt = data.mainCommentsCnt;
      this.vars.realId = data.realId;
      this.vars.releaseType = data.releaseTypeId;
      this.vars.languages = data.languages;
      this.vars.releaseDate = data.releaseDate;
      this.vars.tags = data.tags;
      this.vars.licenseType = data.licenseTypeId;
      this.vars.recordLabels = data.recordLabels;
      this.vars.postType = data.postType;
      this.vars.lyrics = null;
      this.vars.description = null;
      this.vars.userId = data.userId;
      this.vars.setId = data.setId;
      this.vars.setType = data.setType;
      this.vars.canComment = data.canComment;
      this.vars.setTitle = data.setTitle;
      this.vars.setCover = data.setCover;
      this.vars.setDescription = data.setDescription;
    });
    bus.$on("trackList", (data) => {
      if (this.tabs.indexOf("tracks") === -1) {
        this.tabs.unshift("tracks");
        this.currentTab = "tracks";
        this.items = data;
        if (this.scrollToManage) {
          setTimeout(() => {
            this.manageMode = true;
            VueScrollTo.scrollTo(this.$refs.soundTabs, 1000, {
              container: "#site-main-col",
            });
          }, 300);
        }
      }
    });
    bus.$on("submitComment" + this.vars.realId, (data) => {});
  },
  beforeDestroy() {
    bus.$off("soundTabData");
    bus.$off("trackList");
    bus.$off("submitComment" + this.vars.realId);
  },
};
</script>