<template>
    <div>
        <div @click="callback" class="billboard-ads" :class="options.subclass" :style="{backgroundColor: item.backgroundColor, backgroundImage: 'url(' + item.background + ')'}">
            <image-placeholder-bg :background-color="item.backgroundColor" :data-src="item.background"/>
            <div class="loading">
                <i uk-spinner="ratio: 2"></i>
            </div>
            <div v-if="item.label!=null && item.label!=''" class="label">
                <span>{{item.label}}</span>
            </div>
            <div class="bottom-bar" v-if="item.title!=null && item.title!=''">
                <div class="left-side">
                    <div class="title">
                        {{item.title}}
                    </div>
                    <div class="subtitle">
                        {{item.subtitle}}
                    </div>
                </div>
                <div class="right-side">
                    <div v-if="item.square!=null">
                        <img :src="item.square"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ImagePlaceholderBg from "@/components/Tools/ImagePlaceholderBg";
    export default {
        components: {ImagePlaceholderBg},
        data() {
            return {
                height: 200,
            }
        },
        props: {
            item: {},
            index: {
                type: Number,
                default: 0
            },
            options: {}
        },
        methods: {
            callback() {
                this.$emit('clicked', this.item)
            }
        }
    }
</script>