var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "parentContainer",
      staticClass: "mini-player-desktop-container-parent",
      class: [{ mini: _vm.hidePlayer }, { active: _vm.active }],
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _vm.current != undefined && _vm.current.title != undefined
        ? _c(
            "div",
            {
              ref: "container",
              staticClass: "mini-player-desktop-container",
              on: {
                scroll: _vm.scrollParent,
                mouseup: _vm.endScrollParent,
                touchend: _vm.endScrollParent,
              },
            },
            [
              _c(
                "div",
                {
                  ref: "miniPlayerDesktop",
                  staticClass: "mini-player-desktop",
                  style: { width: "100%" },
                },
                [
                  _c("button", {
                    staticClass: "mini-player-unminimize",
                    attrs: { type: "button" },
                    on: { click: _vm.unminimizeBtn },
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "playerCoverContainer",
                      staticClass: "mini-player-desktop-cover",
                      on: {
                        dblclick: function ($event) {
                          return _vm.togglePlayer("playerCoverContainer")
                        },
                      },
                    },
                    [
                      _vm.liveStatsAvailable
                        ? _c(
                            "span",
                            {
                              staticClass: "mini-player-minimize",
                              on: {
                                click: function ($event) {
                                  return _vm.togglePlayer(
                                    "mini-player-minimize"
                                  )
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-chevron-circle-down",
                              }),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c("canvas", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.current.postType &&
                              _vm.current.postType === "VID",
                            expression:
                              "current.postType && current.postType === 'VID'",
                          },
                        ],
                        attrs: {
                          id: "videoMirror",
                          width: "300px",
                          height: "168px",
                        },
                      }),
                      _vm._v(" "),
                      _c("image-placeholder", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.current && _vm.current.postType !== "VID",
                            expression: "current && current.postType !== 'VID'",
                          },
                        ],
                        style: { maxWidth: "100%" },
                        attrs: {
                          width: "300px",
                          height: "300px",
                          dataSrc: _vm._f("attach")(
                            _vm.current.metas.cover,
                            "m"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mini-player-desktop-title-container" },
                    [
                      _c(
                        "div",
                        { staticClass: "mini-player-desktop-title f-s-085" },
                        [
                          _c(
                            "router-link",
                            {
                              attrs: { to: _vm.helper.permalink(_vm.current) },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_vm.current.title) +
                                  "\n                    "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mini-player-desktop-artist f-s-075" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: "/" + _vm.current.user.username } },
                            [_vm._v(_vm._s(_vm.current.user.fullname))]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.current.users, function (user) {
                            return _c(
                              "span",
                              { key: user.username },
                              [
                                _vm._v("\n                        , "),
                                _c(
                                  "router-link",
                                  { attrs: { to: "/" + user.username } },
                                  [_vm._v(_vm._s(user.fullname))]
                                ),
                              ],
                              1
                            )
                          }),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mini-player-desktop-seekbar-container uk-grid-small uk-flex uk-flex-middle",
                      attrs: { "uk-grid": "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "uk-width-auto uk-flex uk-flex-middle" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "mini-player-desktop-duration-left f-s-070",
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm._f("minutes")(_vm.tmpDur || _vm.duration)
                                )
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-expand" }, [
                        _c(
                          "div",
                          {
                            ref: "seekbar",
                            staticClass: "mini-player-desktop-seek-container",
                            on: { mousedown: _vm.seek },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "mini-player-desktop-seek" },
                              [
                                _c("span", {
                                  staticClass: "mini-player-desktop-seek-value",
                                  style: {
                                    width:
                                      (_vm.tmpPercent || _vm.percentSeek) + "%",
                                  },
                                }),
                              ]
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "uk-width-auto uk-flex uk-flex-middle" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "mini-player-desktop-duration-right f-s-070",
                            },
                            [
                              _vm._v(
                                "-" +
                                  _vm._s(
                                    _vm._f("minutes")(
                                      _vm.tmpRemaning || _vm.remaining
                                    )
                                  )
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "mini-player-desktop-actions-container uk-grid-small uk-flex uk-flex-middle uk-text-center f-s-085",
                      attrs: { "uk-grid": "" },
                    },
                    [
                      _c("div", { staticClass: "uk-width-1-1" }, [
                        _c("div", { staticClass: "uk-position-relative" }, [
                          _vm.showVolume
                            ? _c("div", { staticClass: "volume-bar" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "uk-grid-small",
                                    attrs: { "uk-grid": "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-auto" },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "mpda",
                                            on: { click: _vm.volumeToggle },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fal fa-times volume-times",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-expand" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            ref: "volumebar",
                                            staticClass: "volume-parent",
                                            on: { mousedown: _vm.volume },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "volume-slider" },
                                              [
                                                _c("div", {
                                                  ref: "volumevalue",
                                                  staticClass:
                                                    "volume-slider-value",
                                                  class: [
                                                    {
                                                      "volume-30":
                                                        _vm.volPercent > 30 &&
                                                        _vm.volPercent < 60
                                                          ? true
                                                          : false,
                                                    },
                                                    {
                                                      "volume-60":
                                                        _vm.volPercent >= 60 &&
                                                        _vm.volPercent < 80,
                                                    },
                                                    {
                                                      "volume-80":
                                                        _vm.volPercent >= 80
                                                          ? true
                                                          : false,
                                                    },
                                                  ],
                                                  style: {
                                                    width: _vm.volPercent + "%",
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-auto" },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "mpda mpda-volume" },
                                          [
                                            !_vm.mute
                                              ? _c("i", {
                                                  staticClass:
                                                    "far fa-volume-up",
                                                  on: { click: _vm.muteToggle },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.mute
                                              ? _c("i", {
                                                  staticClass:
                                                    "far fa-volume-mute",
                                                  on: { click: _vm.muteToggle },
                                                })
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-auto mpdas" }, [
                        _c(
                          "span",
                          {
                            staticClass: "mpda mpda-like",
                            on: { click: _vm.like },
                          },
                          [
                            _c("i", {
                              staticClass: "fa-heart",
                              class: {
                                far:
                                  _vm.ct.is === undefined || !_vm.ct.is.liked,
                                "fas active":
                                  _vm.ct.is != undefined && _vm.ct.is.liked,
                              },
                            }),
                          ]
                        ),
                        _vm._v(" "),
                        _c("span", { staticClass: "mpda" }, [
                          _c("i", {
                            staticClass: "far fa-random",
                            class: { active: _vm.getShuffle },
                            on: { click: _vm.shuffle },
                          }),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-expand" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex uk-grid-small uk-flex-middle",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-width-1-3" }, [
                              _c(
                                "span",
                                { staticClass: "mpdnp mpdnp-prev f-s-090" },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-backward",
                                    on: { click: _vm.prev },
                                  }),
                                ]
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uk-width-1-3" }, [
                              _c("span", { staticClass: "mpdp f-s-13" }, [
                                _vm.playerStatus === "pause"
                                  ? _c("i", {
                                      staticClass: "fas fa-play",
                                      on: {
                                        click: function ($event) {
                                          return _vm.play()
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.playerStatus === "play"
                                  ? _c("i", {
                                      staticClass: "fas fa-pause",
                                      on: { click: _vm.pause },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.playerStatus === "wait"
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-spinner-third fa-spin",
                                    })
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uk-width-1-3" }, [
                              _c(
                                "span",
                                { staticClass: "mpdnp mpdnp-next f-s-090" },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-forward",
                                    on: { click: _vm.next },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-width-auto mpdas" }, [
                        _c("span", { staticClass: "mpda" }, [
                          _vm.getRepeat === false || _vm.getRepeat === 2
                            ? _c("i", {
                                staticClass: "far fa-repeat",
                                class: { active: _vm.getRepeat != false },
                                on: { click: _vm.repeat },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getRepeat === 1
                            ? _c("i", {
                                staticClass: "far fa-repeat-1-alt active",
                                on: { click: _vm.repeat },
                              })
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("span", { staticClass: "mpda mpda-volume" }, [
                          !_vm.mute
                            ? _c("i", {
                                staticClass: "far fa-volume-up",
                                class: { deactive: _vm.isIos },
                                on: { click: _vm.volumeToggle },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.mute
                            ? _c("i", {
                                staticClass: "far fa-volume-mute",
                                on: { click: _vm.volumeToggle },
                              })
                            : _vm._e(),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "player-bottom",
                  style: { top: _vm.paddingTop + "px", width: _vm.coverWidth },
                },
                [
                  !_vm.hidePlayer
                    ? _c(
                        "div",
                        {
                          key: "related" + _vm.key,
                          ref: "miniPlayerTabs",
                          staticClass: "mini-player-desktop-tabs padding-x-15",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-grid-small uk-text-center f-s-075",
                              attrs: { "uk-grid": "" },
                            },
                            [
                              _c("div", { staticClass: "uk-width-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mini-player-desktop-tab",
                                    class: {
                                      active: _vm.currentTab === "nextUp",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tabTo("nextUp")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("lang")("player_up_next")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "uk-width-1-2" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mini-player-desktop-tab",
                                    class: {
                                      active: _vm.currentTab === "lyrics",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.tabTo("lyrics")
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm._f("lang")("player_lyrics")
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidePlayer && _vm.currentTab === "lyrics"
                    ? _c("div", { staticClass: "next-up-body" }, [
                        _c(
                          "div",
                          {
                            staticClass: "player-lyrics-body",
                            style: { height: _vm.tabHeightVal + "px" },
                          },
                          [
                            _c(
                              "perfect-scrollbar",
                              {
                                attrs: {
                                  options: {
                                    wheelPropagation: true,
                                  },
                                },
                              },
                              [
                                _c("div", {
                                  staticClass: "f-s-080",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.helper.ln2br(_vm.texts.lyrics)
                                    ),
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.texts.lyrics === ""
                              ? _c("div", { staticClass: "uk-text-center" }, [
                                  _vm._m(1),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-margin-small-top f-s-075",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(
                                            _vm._f("lang")("no_lyrics_found")
                                          ) +
                                          "\n                        "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidePlayer && _vm.currentTab === "nextUp"
                    ? _c("div", { staticClass: "next-up-body" }, [
                        _vm.playlist && !_vm.playlist.length
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "uk-text-center padding-top-40 padding-x-20",
                              },
                              [
                                _c("div", { staticClass: "nosnd-icon" }),
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      _vm._f("lang")("player_empty_playlist")
                                    ) +
                                    "\n                "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "next-up-parent",
                            style: { height: _vm.tabHeightVal + "px" },
                            attrs: { id: "nextUpUlParent" },
                          },
                          [
                            _vm.playlist && _vm.playlist.length
                              ? _c(
                                  "ul",
                                  {
                                    ref: "nextUpUl",
                                    staticClass: "vertical-audio-list",
                                  },
                                  _vm._l(_vm.playlist, function (item) {
                                    return _c(
                                      "li",
                                      {
                                        key: item.realId,
                                        attrs: {
                                          "data-id": item.realId,
                                          "data-active":
                                            item.realId === _vm.current.realId
                                              ? "1"
                                              : "0",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.playPlaylist(item)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "uk-grid-small uk-flex uk-flex-middle vertical-audio-list-li",
                                            attrs: { "uk-grid": "" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "uk-width-auto" },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "vertical-audio-img",
                                                  },
                                                  [
                                                    _c("img", {
                                                      attrs: {
                                                        src: _vm._f("attach")(
                                                          item.metas.cover,
                                                          "xs"
                                                        ),
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-expand vertical-audio-txt",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "uk-grid-small",
                                                    attrs: { "uk-grid": "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "uk-width-expand",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "vertical-audio-top-txt f-s-075 uk-text-truncate",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  item.title
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "vertical-audio-btm-txt f-s-075 uk-text-truncate",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                " +
                                                                _vm._s(
                                                                  item.user
                                                                    .fullname
                                                                ) +
                                                                "\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "uk-width-auto",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "vertical-audio-more",
                                                          },
                                                          [
                                                            item &&
                                                            item.metas &&
                                                            item.metas
                                                              .attachment
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "vertical-audio-more-item vertical-audio-more-item-duration f-s-070",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    " +
                                                                        _vm._s(
                                                                          _vm._f(
                                                                            "minutes"
                                                                          )(
                                                                            item
                                                                              .metas
                                                                              .attachment
                                                                              .duration
                                                                          )
                                                                        ) +
                                                                        "\n                                                "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  }),
                                  0
                                )
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("audio", {
        staticStyle: { display: "none" },
        attrs: { id: "audioPlayer" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "f-s-15" }, [
      _c("i", { staticClass: "fal fa-align-center" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }