<template>
  <div>
    <div v-show="!uploadStarted">
      <div
        id="UppyDragDrop"
        ref="uppy"
        class="drag-and-drop uk-flex uk-flex-middle uk-text-center"
      >
        <div class="uk-flex uk-flex-column margin-0-auto">
          <div>
            <i class="fas fa-cloud-upload upload-icon"></i>
          </div>
          <div class="f-f-reg uk-margin-bottom">
            Select sound or video file to upload
            <br />
            or drag and drop a file
          </div>
          <div @mousemove="onMouseMove" class="upload-buttons uk-margin-top">
            <div
              ref="uploadBtns"
              class="upload-buttons-container uk-flex uk-flex-middle"
            >
              <button
                @click="selectFile"
                ref="selectFileBtn"
                class="f-s-15 f-s-1@xs btn-a"
                :class="{ active: activeBtn === 'a' }"
              >
                Select Files
              </button>
              <span ref="or">Or</span>
              <button
                @click="uploadFromUrl.show = true"
                ref="uploadFromUrlBtn"
                class="f-s-15 f-s-1@xs btn-b"
                :class="{ active: activeBtn === 'b' }"
              >
                Upload From URL
              </button>
              <div
                :style="uploadBtnBackgroundStyle"
                class="upload-button-background"
              ></div>
            </div>
          </div>
          <div
            class="
              uk-text-center
              f-s-080 f-f-reg
              uk-margin-top uk-position-relative
              upload-nt
            "
          >
            By submitting your sound or videos to vowave, you agree to our
            <a target="_blank" href="/terms">Terms of Service</a>.<br /><br />
            Please be sure not to violate other's copyright or privacy
            rights.<br />
            By uploading other artists original sound or videos you will
            immediately get blocked from using our applications.<br />
          </div>
        </div>
      </div>
    </div>
    <div class="padding-x-15" v-if="uploadStarted">
      <my-upload
        field="img"
        @crop-success="cropSuccess($event)"
        @crop-upload-success="cropUploadSuccess($event)"
        @crop-upload-fail="cropUploadFail($event)"
        v-model="imgUpload.show"
        :url="imgUpload.url"
        :height="600"
        :width="600"
        :fullsize="1200"
        langType="en"
        :params="imgUpload.params"
        :headers="imgUpload.headers"
        no-circle
        :ref="'imgUpload'"
        img-format="jpg"
      ></my-upload>
      <div class="uploading-file" v-for="(file, index) in files" :key="index">
        <div class="file-progress-bar">
          <div
            v-if="
              fileDatas[index] != undefined && fileDatas[index].error === true
            "
            class="file-progress-bar-val err"
            style="width: 100%"
          ></div>
          <div
            v-if="
              fileDatas[index] != undefined && fileDatas[index].error === false
            "
            class="file-progress-bar-val"
            :style="{ width: (file.uploadedSize / file.size) * 100 + '%' }"
          ></div>
        </div>
        <div class="file-ellapsed" @click="collapseToggle(index, $event)">
          <div>
            {{ file.fileName }}
          </div>
          <div class="flex-right">
            <i
              v-if="saving !== index && fileDatas[index].saved === false"
              class="fas fa-trash-alt"
              @click="removeFile(index)"
            ></i>
            <i
              v-if="
                fileDatas[index] != undefined &&
                fileDatas[index].error === false &&
                fileDatas[index].saved === false
              "
              class="fas fa-chevron-down"
            ></i>
            <i v-if="fileDatas[index].saved" class="fas fa-check"></i>
          </div>
        </div>
        <div
          v-if="fileDatas[index] != undefined"
          v-show="fileDatas[index].collapsed"
          class="file-collapsed submit-post"
        >
          <div class="processing" v-if="index === saving">
            <div>
              <i uk-spinner="ratio: 3"></i>
            </div>
          </div>
          <div class="uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-1-1 uk-width-1-4@m">
              <div class="post-cover">
                <img
                  :src="
                    fileDatas[index].post.base64Cover !== null
                      ? fileDatas[index].post.base64Cover
                      : defaultImage
                  "
                />
                <div class="post-cover-container">
                  <a
                    class="
                      uk-button uk-button-default
                      button-top-menu button-bg
                    "
                    v-if="fileDatas[index].post.base64Cover !== null"
                    @click="toggleCoverUploader(index, true)"
                  >
                    <i class="fal fa-pen"></i>
                  </a>
                  <a
                    class="
                      uk-button uk-button-default
                      button-top-menu button-bg
                    "
                    @click="toggleCoverUploader(index, false)"
                  >
                    <i class="fal fa-cloud-upload"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-3-4@m">
              <div class="uk-margin">
                <label class="label"> Title </label>
                <input
                  class="uk-input uk-form-width-medium uk-form-small title"
                  type="text"
                  @keyup="onKeyUpTitle(index)"
                  placeholder="Title"
                  v-model="fileDatas[index].post.title"
                />
                <div
                  class="
                    uk-grid-none uk-flex uk-flex-middle uk-margin-small-top
                    f-s-080
                  "
                  uk-grid
                >
                  <div class="uk-width-auto">
                    {{ host }}/{{ auth.username }}/
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-position-relative">
                      <input
                        class="slug-input"
                        type="text"
                        :readonly="readOnlySlug"
                        placeholder="Title"
                        @change="onKeyUpTitle(index, 1)"
                        v-model="fileDatas[index].post.slug"
                      />
                      <div v-if="readOnlySlug" class="slug-loading">
                        <div uk-spinner></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin">
                <div uk-grid>
                  <div class="uk-width-1-1 uk-width-1-2@m">
                    <label class="selectpage-label">Post Languages *</label>
                    <v-selectpage
                      v-if="postLanguagesArr.length"
                      :data="postLanguagesArr"
                      :multiple="true"
                      :language="'en'"
                      :placeholder="'Select language'"
                      :title="'Languages'"
                      :max-select-limit="3"
                      ref="languages"
                      v-model="fileDatas[index].post.languages"
                    >
                    </v-selectpage>
                  </div>
                  <div class="uk-width-1-1 uk-width-1-2@m">
                    <label class="selectpage-label"> Record Labels </label>
                    <v-selectpage
                      :data="' '"
                      :result-format="typedValueResultFormat"
                      :params="{ type: 'recordLabels' }"
                      :multiple="true"
                      :language="'en'"
                      :placeholder="'Select Record Labels'"
                      :title="'Record Labels'"
                      :max-select-limit="2"
                      v-model="fileDatas[index].post.recordLabels"
                    >
                    </v-selectpage>
                  </div>
                </div>
              </div>
              <div class="uk-margin">
                <label class="selectpage-label"> Hashtags </label>
                <v-selectpage
                  :data="' '"
                  :result-format="typedValueResultFormat"
                  :params="{ type: 'tags' }"
                  :multiple="true"
                  :language="'en'"
                  search-field="name"
                  :show-field="renderHashtagsRow"
                  :placeholder="'Select hashtags'"
                  :title="'Hashtags'"
                  :max-select-limit="10"
                  v-model="fileDatas[index].post.hashtags"
                >
                </v-selectpage>
              </div>
              <div class="uk-margin">
                <div class="uk-width-1-1">
                    <label class="selectpage-label"> Genres * </label>
                    <v-selectpage
                      :data="' '"
                      :result-format="tagsResultFormat"
                      :params="{ type: 'genres' }"
                      :multiple="true"
                      :language="'en'"
                      :placeholder="'Select genres'"
                      :title="'Genres'"
                      :max-select-limit="3"
                      v-model="fileDatas[index].post.genres"
                    >
                    </v-selectpage>
                </div>
              </div>
            </div>
          </div>
          <screenshot-scrapper
            :upload-complete="file.uploadedSize === file.size"
            v-if="fileDatas[index].post.postType === 'VID'"
            @pass="setCover"
            @screenshots="setScreenshots($event, index)"
            :file="file.file"
            :filename="file.fileName"
          />
          <ul uk-accordion="collapsible: true">
            <li>
              <a
                class="uk-accordion-title"
                @click="clickUkTab($event, index, 0)"
                href="#"
                >Description & Lyrics</a
              >
              <div class="uk-accordion-content">
                <div class="uk-margin">
                  <div class="big-placeholder-container">
                    <label for="dDescription">
                      {{ "description_lable" | lang }}
                    </label>
                    <textarea
                      rows="4"
                      id="dDescription"
                      v-model="fileDatas[index].post.description"
                      :placeholder="'description_placeholder' | lang"
                      class="uk-textarea uk-form-small uk-text-center"
                    ></textarea>
                    <!-- <div v-if="fileDatas[index].showDescriptionPlaceholder"
                                             @click="focusFirstParentInput" class="big-placeholder small">
                                            Description
                                        </div> -->
                  </div>
                </div>
                <div class="uk-margin">
                  <div class="big-placeholder-container">
                    <label for="tLyrics">
                      {{ "lyrics_lable" | lang }}
                    </label>
                    <textarea
                      rows="10"
                      id="tLyrics"
                      v-model="fileDatas[index].post.lyrics"
                      :placeholder="'lyrics_placeholder' | lang"
                      class="uk-textarea uk-form-small uk-text-center"
                    ></textarea>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a
                class="uk-accordion-title"
                @click="clickUkTab($event, index, 1)"
                href="#"
                >Privacy & Metadata</a
              >
              <div class="uk-accordion-content">
                <div class="uk-margin">
                  <div uk-grid>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Privacy Settings</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="fileDatas[index].post.public"
                        :reduce="(pub) => pub.value"
                        :options="[
                          { label: 'Public', value: 1 },
                          { label: 'Private', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Download</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="fileDatas[index].post.download"
                        :reduce="(download) => download.value"
                        :options="[
                          { label: 'Allow', value: 1 },
                          { label: 'Not Allow', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Comments</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="fileDatas[index].post.comment"
                        :reduce="(comment) => comment.value"
                        :options="[
                          { label: 'Allow', value: 1 },
                          { label: 'Not Allow', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <div uk-grid>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Type</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="fileDatas[index].post.releaseTypeId"
                        :reduce="(releaseType) => releaseType.value"
                        :options="releaseTypesArr"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Explicit 🅴</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="fileDatas[index].post.explicit"
                        :reduce="(explicit) => explicit.value"
                        :options="[
                          { label: 'Yes', value: '1' },
                          { label: 'No', value: '0' },
                        ]"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>License</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="fileDatas[index].post.licenseTypeId"
                        :reduce="(licenseType) => licenseType.value"
                        :options="licenseTypesArr"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <div uk-grid>
                    <div
                      class="
                        uk-width-1-1 uk-width-1-2@m
                        select-teammates-container
                      "
                    >
                      <label>&nbsp;</label>
                      <v-selectpage
                        :data="' '"
                        :result-format="usersResultFormat"
                        :params="{ type: 'users' }"
                        :multiple="true"
                        :language="'en'"
                        search-field="username"
                        :show-field="renderUsersRow"
                        :placeholder="'Select Teammates'"
                        :title="'Users'"
                        :max-select-limit="5"
                        v-model="fileDatas[index].post.users"
                      >
                      </v-selectpage>
                      <small>
                        You can use ‘teammate’ to add other artists who have
                        collabrated with you to your post.
                      </small>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-2@m">
                      <label>&nbsp;</label>
                      <VueCtkDateTimePicker
                        locale="en"
                        v-on:change="onChangeReleaseDate"
                        :dark="true"
                        format="YYYY-MM-DD HH:mm"
                        v-model="fileDatas[index].post.releaseDate"
                      />
                      <small>
                        If you select time from future this post will be
                        scheduled and will get automatically posted at that
                        time.
                      </small>
                    </div>
                  </div>
                </div>
                <!-- <div class="uk-margin">
                                    <div class="moods">
                                        <div class="mood-container" v-for="mood in moods">
                                            <div @click="selectMood(mood.id)" class="mood"
                                                 :class="{active: fileDatas[index].post.moods.indexOf(mood.id)!=-1}">
                                                <div class="mood-image-container">
                                                    <img :src="mood.attach | attach('xs', 'assets')"/>
                                                </div>
                                                <div class="uk-text-truncate f-s-080">
                                                    {{mood.name}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> -->
              </div>
            </li>

            <!-- <li>
                            <a class="uk-accordion-title" href="#" @click="clickUkTab($event, index, 2)">Sale</a>
                            <div class="uk-accordion-content">
                                <div class="smooth-picker-title f-s-075">
                                    <div>For Sale?</div>
                                    <div>Price (Dollars)</div>
                                    <div>Price (Tomans)</div>
                                    <div>Playback</div>
                                </div>
                                <smooth-picker ref="smoothPicker" :data="fileDatas[index].packages"
                                               :change="onChangePackage"/>
                                <ul class="buy-doc f-s-080">
                                    <li>You must have a Pro account to unlock this feature, click here to go Pro.</li>
                                    <li>Please remember to fill your bank account information in your profile
                                        settings.
                                    </li>
                                    <li>If you select ‘Preview’ users can only listen to 30 seconds of the track before
                                        buying it.
                                    </li>
                                    <li>If you have schedule your post to get published anytime in future and also
                                        enabled sale option<br> for your post, users will be able to pre-buy this post
                                        and access it when it gets released.
                                    </li>
                                </ul>
                            </div>
                        </li> -->
          </ul>
        </div>
      </div>
    </div>
    <modal @close="closeUploadFromUrl" v-model="uploadFromUrl.show">
      <h3 slot="header">Upload From URL</h3>
      <div slot="body">
        <input
          type="text"
          :disabled="uploadFromUrl.inProcess"
          placeholder="Enter URL"
          class="uk-input"
          v-model="uploadFromUrl.url"
        />
        <div class="f-s-080 uk-margin-small-top">
          You can upload your sounds or videos to your account on vowave by
          entering their direct download URL.<br />
          Maximum Upload Size: {{ settings && settings.maxUploadSize }}MB<br />
          Allowed Formats:
          {{
            !!(settings && settings.allowedExtensions)
              ? settings.allowedExtensions.join(", ")
              : ""
          }}
        </div>
      </div>
      <div slot="footer" class="display-flex">
        <PButton
          class="flex-right"
          @clicked="remoteUpload"
          :in-process="uploadFromUrl.inProcess"
          text="Upload"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import helper from "../../helpers/helper";
import axios from "axios";
import defaultImage from "../../assets/img/noimage-artwork.jpg";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import ScreenshotScrapper from "@/components/Tools/ScreenshotScrapper";
import PButton from "@/components/Tools/PButton";

export default {
  components: {
    PButton,
    "my-upload": myUpload,
    ScreenshotScrapper,
  },
  watch: {
    $route: function (route) {
      if (route.name === "upload") {
        this.calcWidths();
      }
    },
  },
  data() {
    return {
      uploadWithUrl: false,
      saveIndex: 0,
      saving: null,
      test: true,
      defaultImage,
      uploadBtnBackgroundStyle: {},
      activeBtn: "a",
      activeIndex: 0,
      uploadStarted: false,
      files: [],
      readOnlySlug: false,
      fileDatas: [],
      host: window.location.host,
      uploadFromUrl: {
        show: false,
        url: "",
        inProcess: false,
      },
      imgUpload: {
        url: "",
        show: false,
        index: 0,
        params: {},
        headers: {},
      },
      tagsResultFormat: (res) => {
        return {
          totalRow: res.data.meta.total,
          list: res.data.data,
        };
      },
      typedValueResultFormat: (res) => {
        let json = JSON.parse(res.config.data);
        if (res.data.data.length) {
          let finded = false;
          let response = res.data.data;
          if (json.name != undefined) {
            for (let i = 0; i < response.length; i++) {
              if (response[i].name.toLowerCase() === json.name.toLowerCase()) {
                finded = true;
              }
            }
          }
          if (!finded && json.name != undefined) {
            response.unshift({
              id: json.name,
              name: json.name,
            });
          }
          return {
            totalRow: res.data.meta.total,
            list: response,
          };
        } else {
          return {
            totalRow: 1,
            list: [
              {
                id: json.name,
                name: json.name,
              },
            ],
          };
        }
      },
      usersResultFormat: (res) => {
        return {
          totalRow: res.data.length,
          list: res.data,
        };
      },
      renderUsersRow: (row) => {
        let result = "";
        if (row && Object.keys(row).length)
          result = `<img width="20" height="20" src="${helper.image_url(
            row.photos.avatar,
            75
          )}"/> @${row.username}`;
        return result;
      },
      renderHashtagsRow: (row) => {
        let result = "";
        if (row && Object.keys(row).length) result = `#${row.name}`;
        return result;
      },
      packages: [
        {
          currentIndex: 0,
          flex: 3,
          list: ["Free", "For Sale"],
          onClick: this.clickOnPlan,
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex: 0,
          flex: 3,
          list: ["0.00$"],
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex: 0,
          flex: 3,
          list: ["0T"],
          textAlign: "center",
          className: "item-group",
        },
      ],
    };
  },
  props: [
    "auth",
    "postLanguages",
    "licenseTypes",
    "releaseTypes",
    "pricings",
    "moods",
  ],
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    licenseTypesArr() {
      let arr = [];
      let keys = Object.keys(this.licenseTypes);
      for (let i = 0; i < keys.length; i++) {
        arr.push({
          value: parseInt(keys[i], 10),
          label: this.licenseTypes[keys[i]],
        });
      }
      // console.log("arr", arr);
      return arr;
    },
    releaseTypesArr() {
      let arr = [];
      let keys = Object.keys(this.releaseTypes);
      for (let i = 0; i < keys.length; i++) {
        arr.push({
          value: parseInt(keys[i], 10),
          label: this.releaseTypes[keys[i]],
        });
      }
      return arr;
    },
    postLanguagesArr() {
      let arr = [];
      for (let i = 0; i < this.postLanguages.length; i++) {
        arr.push({
          name: this.settings.allPostLanguages[i].name,
          id: this.settings.allPostLanguages[i].id,
        });
      }
      return arr;
    },
  },
  methods: {
    selectFile() {
      document.getElementsByClassName("uppy-DragDrop-input")[0].click();
    },
    setCover(base64) {
      let splitted = base64.split(",");
      let fileType = splitted[0].replace("data:", "").replace(";base64", "");
      this.$refs.imgUpload.setSourceImg(
        helper.b64toBlob(splitted[1], fileType, 512)
      );
      // this.$refs.imgUpload.reset();
      this.$refs.imgUpload.setStep(2);
      this.imgUpload.index = this.activeIndex;
      setTimeout(() => {
        this.imgUpload.show = true;
      }, 500);
    },
    closeUploadFromUrl() {
      if (!this.uploadFromUrl.inProcess) {
        this.uploadFromUrl.show = false;
      }
    },
    remoteUpload() {
      this.uploadFromUrl.inProcess = true;
      axios
        .post(API.remoteUpload, {
          url: this.uploadFromUrl.url,
        })
        .then((resp) => {
          this.uploadFromUrl.inProcess = false;
          this.uploadStarted = true;
          this.uploadFromUrl.show = false;
          bus.$emit("uploadFileAdded", resp.data);
          bus.$emit("uploadComplete", {
            failed: [],
          });
        })
        .catch((e) => {
          this.uploadFromUrl.inProcess = false;
          let response = e.response;
          if (response.data.errors != undefined) {
            bus.$emit("error", {
              title: "",
              message:
                response.data.errors[Object.keys(response.data.errors)[0]][0],
            });
          } else if (response.data.message != undefined) {
            bus.$emit("error", {
              title: "",
              message: response.data.message,
            });
          }
        });
    },
    setScreenshots(screenshots, index) {
      this.fileDatas[index].post.screenshots = screenshots;
    },
    addScreenshot(sc, index) {
      if (this.fileDatas[index].post.screenshots === undefined)
        this.fileDatas[index].post.screenshots = [];
      this.fileDatas[index].post.screenshots.push(sc);
    },
    onLoadedMetaData(e) {
      let vm = this;
      this.duration = e.srcElement.duration;
      let cnt = 6;
      this.durationStep = Math.floor(this.duration / cnt);
      this.durations = [];
      for (let i = 0; i < cnt; i++) {
        this.durations.push(this.durationStep / 2 + this.durationStep * i);
      }
      let videoPlayer = this.$refs.videoplayer;
      let canvas = this.$refs.videocanvas;
      canvas.width = 820;
      canvas.height = 460;
      let canvasCtx = canvas.getContext("2d");
      let screenshots = [];
      var ii = 0;
      let intVal = setInterval(function () {
        if (ii >= vm.durations.length - 1) {
          setTimeout(function () {
            vm.sreenshotLoadedEnd = true;
          }, 500);
          clearInterval(intVal);
        }
        videoPlayer.currentTime = parseInt(vm.durations[ii], 10);
        setTimeout(function () {
          if (canvas.toDataURL() === "data:,") {
            vm.screenshotsAreLoading = true;
            clearInterval(intVal);
            return;
          }
          canvasCtx.drawImage(videoPlayer, 0, 0, 820, 460);
          screenshots.push(canvas.toDataURL("image/jpeg"));
        }, 500);
        ii++;
      }, 1000);
      this.screenshots = screenshots;
    },
    selectMood(moodId) {
      let indexOfMood =
        this.fileDatas[this.activeIndex].post.moods.indexOf(moodId);
      if (indexOfMood === -1) {
        this.fileDatas[this.activeIndex].post.moods.push(moodId);
      } else {
        this.fileDatas[this.activeIndex].post.moods.splice(indexOfMood, 1);
      }
    },
    removeFile(index) {
      bus.$emit("removeFile", this.files[index].id);
      this.fileDatas.splice(index, 1);
      this.files.splice(index, 1);
      if (!this.files.length) {
        this.resetUpload();
      }
    },
    focusFirstParentInput(e) {
      let el = e.path[1];
      let textarea = el.getElementsByTagName("textarea");
      for (let i = 0; i < textarea.length; i++) {
        textarea[i].focus();
        break;
      }
    },
    clickUkTab(e, index, tabIndex) {
      if (tabIndex === 2) {
        this.dispatchResize();
      }
      setTimeout(() => {
        let openedUk = e.path[2].getElementsByClassName("uk-opened");
        if (openedUk.length) {
          for (let i = 0; i < openedUk.length; i++) {
            openedUk[i].setAttribute("class", "");
          }
        }
        if (e.path[1].getAttribute("class") === "uk-open") {
          this.fileDatas[index].tabIndex = tabIndex;
          e.path[1].setAttribute("class", "uk-open uk-opened");
        } else {
          this.fileDatas[index].tabIndex = null;
        }
      }, 250);
    },
    dispatchResize() {
      window.dispatchEvent(new Event("resize"));
    },
    onChangePackage(gIndex, iIndex) {
      var tomans = ["0T"];
      var dollars = ["0.00$"];
      let smoothPicker = this.$refs.smoothPicker[this.activeIndex];
      if (gIndex === 0) {
        this.fileDatas[this.activeIndex].post.sale = iIndex;
        let currentIndex = 0;
        let typeIndex = 0;
        var list;
        switch (iIndex) {
          case 1:
            dollars = this.$_.pluck(
              this.pricings[this.fileDatas[this.activeIndex].post.postType],
              "price"
            );
            tomans = this.$_.pluck(
              this.pricings[this.fileDatas[this.activeIndex].post.postType],
              "priceTomans"
            );
            typeIndex = 1;
            break;
        }
        list = dollars;
        smoothPicker.setGroupData(
          1,
          Object.assign({}, this.fileDatas[this.activeIndex].packages[1], {
            currentIndex,
            list,
          })
        );
        list = tomans;
        smoothPicker.setGroupData(
          2,
          Object.assign({}, this.fileDatas[this.activeIndex].packages[2], {
            currentIndex,
            list,
          })
        );
        list = this.fileDatas[this.activeIndex].packages[3].list;

        this.fileDatas[this.activeIndex].post.playbackType = !typeIndex;
        currentIndex = typeIndex;
        smoothPicker.setGroupData(
          3,
          Object.assign({}, this.fileDatas[this.activeIndex].packages[3], {
            currentIndex,
            list,
          })
        );

        if (iIndex === 1) {
          this.fileDatas[this.activeIndex].post.package =
            this.pricings[this.fileDatas[this.activeIndex].post.postType][0].id;
        } else {
          this.fileDatas[this.activeIndex].post.package = null;
        }
      } else if (gIndex === 1 || gIndex === 2) {
        let ii = gIndex === 1 ? 2 : 1;
        let target = this.fileDatas[this.activeIndex].packages[ii];
        target.currentIndex = iIndex;
        smoothPicker.setGroupData(ii, target);
        this.fileDatas[this.activeIndex].post.package =
          this.pricings[this.fileDatas[this.activeIndex].post.postType][
            iIndex
          ].id;
      } else if (gIndex === 3) {
        this.fileDatas[this.activeIndex].post.playbackType = !iIndex;
      }
    },

    onChangeReleaseDate(date) {},
    toggleCoverUploader(index, type) {
      if (this.fileDatas[index].post.base64Cover !== null && type) {
        let splitted = this.fileDatas[index].post.base64Cover.split(",");
        let fileType = splitted[0].replace("data:", "").replace(";base64", "");
        this.$refs.imgUpload.setSourceImg(
          helper.b64toBlob(splitted[1], fileType)
        );
      } else {
        this.$refs.imgUpload.reset();
        this.$refs.imgUpload.setStep(1);
      }
      this.imgUpload.index = index;
      setTimeout(() => {
        this.imgUpload.show = true;
      }, 200);
    },
    cropSuccess(e) {
      this.fileDatas[this.imgUpload.index].post.base64Cover = e;
    },
    cropUploadSuccess(e, index) {
      // console.log(e, "cropUploadSuccess");
    },
    cropUploadFail(e, index) {
      // console.log(e, "cropUploadFail");
    },
    onFocusLyrics(index, type) {
      let val = this.fileDatas[index].post.lyrics;
      if (type) {
        this.fileDatas[index].showLyricsPlaceholder = false;
      } else {
        if (val === "") {
          this.fileDatas[index].showLyricsPlaceholder = true;
        }
      }
    },
    onFocusDescription(index, type) {
      let val = this.fileDatas[index].post.description;
      if (type) {
        this.fileDatas[index].showDescriptionPlaceholder = false;
      } else {
        if (val === "") {
          this.fileDatas[index].showDescriptionPlaceholder = true;
        }
      }
    },
    onKeyUpTitle(index, type) {
      this.readOnlySlug = true;
      let val =
        type === undefined
          ? this.fileDatas[index].post.title
          : this.fileDatas[index].post.slug;
      if (window["slugify" + index] != null) window["slugify" + index].cancel();
      let CancelToken = axios.CancelToken;
      window["slugify" + index] = CancelToken.source();
      axios
        .get(API.slugify, {
          cancelToken: window["slugify" + index].token,
          params: {
            slug: val,
            postType: this.fileDatas[index].post.postType,
          },
        })
        .then((res) => {
          this.readOnlySlug = false;
          this.fileDatas[index].post.slug = res.data.slug;
        })
        .catch(() => {});
    },
    onMouseMove(e) {
      this.calcWidths(e.clientX);
    },
    calcWidths(x) {
      let selectFileBtn = this.$refs.selectFileBtn;
      let uploadFromUrlBtn = this.$refs.uploadFromUrlBtn;
      let uploadBtns = this.$refs.uploadBtns;
      let or = this.$refs.or;
      let orBounding = or.getBoundingClientRect();
      let center = orBounding.x + orBounding.width / 2;
      if (x > center) {
        let uploadFromUrlBtnBounding = uploadFromUrlBtn.getBoundingClientRect();
        let left =
          uploadFromUrlBtnBounding.x - uploadBtns.getBoundingClientRect().x;
        this.uploadBtnBackgroundStyle = {
          transform: "translateX(" + left + "px)",
          width: uploadFromUrlBtnBounding.width + "px",
        };
        this.activeBtn = "b";
      } else {
        let selectFileBtnBounding = selectFileBtn.getBoundingClientRect();
        let left = 0;
        this.uploadBtnBackgroundStyle = {
          transform: "translateX(" + left + "px)",
          width: selectFileBtnBounding.width + "px",
        };
        this.activeBtn = "a";
      }
    },
    collapseToggle(index, e) {
      if (e != undefined && e.target.className.indexOf("trash") != -1) {
        return;
      }
      if (this.fileDatas[index].saved) {
        return;
      }
      if (this.files.length) {
        for (let i = 0; i < this.fileDatas.length; i++) {
          this.fileDatas[i].collapsed = false;
        }
      }
      if (this.fileDatas[index].error === false)
        this.fileDatas[index].collapsed = true;
      this.activeIndex = index;
      window.dispatchEvent(new Event("resize"));
    },
    resetUpload() {
      this.saving = null;
      bus.$emit("stopSaveProcess");
      this.uploadStarted = false;
      this.files = [];
      this.saveIndex = 0;
      this.fileDatas = [];
      bus.$emit("saveComplete");
    },
    saveFile() {
      if (this.fileDatas[this.saveIndex] === undefined) {
        this.resetUpload();
      }
      this.collapseToggle(this.saveIndex);
      this.saving = this.saveIndex;
      axios
        .post(API.posts, this.fileDatas[this.saveIndex].post)
        .then(() => {
          this.saving = null;
          this.fileDatas[this.saveIndex].saved = true;
          bus.$emit("success", {
            title: "",
            message:
              this.fileDatas[this.saveIndex].post.title +
              " uploaded successfully and will get published in a few minutes after processing has finished.",
          });

          this.saveIndex++;
          this.saveFile();
        })
        .catch((e) => {
          this.saving = null;
          bus.$emit("stopSaveProcess");
          bus.$emit("error", {
            title: this.fileDatas[this.saveIndex].post.title,
            message:
              e.response.data.errors[Object.keys(e.response.data.errors)[0]][0],
          });
        });
    },
    str2ab(str) {
      let buf = new ArrayBuffer(str.length * 2);
      let bufView = new Uint16Array(buf);
      for (let i = 0, strLen = str.length; i < strLen; i++) {
        bufView[i] = str.charCodeAt(i);
      }
      let int16Array = new Int16Array(buf, 0, Math.floor(buf.byteLength / 2));
      return int16Array;
    },
  },
  mounted() {
    helper.mbdInit();
    this.calcWidths();
    bus.$on("saveUpload", () => {
      if (this.fileDatas.length) {
        this.saveFile();
      }
    });
    bus.$on("uploadComplete", (result) => {
      if (result.successful !== undefined && result.successful.length) {
        for (let o = 0; o < result.successful.length; o++) {
          let tmp = result.successful[o];
          for (let i = 0; i < this.fileDatas.length; i++) {
            if (this.fileDatas[i].post.filename === tmp.name) {
              let x = tmp.uploadURL.split("/");
              let x2 = x[x.length - 1];
              this.fileDatas[i].post.filename = x2;
              break;
            }
          }
        }
      }
    });
    bus.$on("upload", (file) => {
      if (this.fileDatas.length) {
        for (let i = 0; i < this.fileDatas.length; i++) {
          this.fileDatas[i].error = false;
        }
      }
      //        this.fileDatas = []
      //        this.files = []
      //        if(this.files.length) {
      //          for(let i = 0; i<this.files.length; i++) {
      //            this.fileDatas.push({
      //              collapsed: (i===0) ? true : false,
      //              post: {}
      //            })
      //          }
      //        }
      this.uploadStarted = true;
    });
    bus.$on("updateUploadStatus", (data) => {
      let filenames = this.$_.pluck(this.files, "fileName");
      let detectedFile = filenames.indexOf(data.file.name);
      if (detectedFile !== -1) {
        this.files[detectedFile].uploadedSize = data.progress.bytesUploaded;
      }
    });
    bus.$on("cancelUpload", () => {
      this.files = [];
      this.fileDatas = [];
      this.uploadStarted = false;
    });
    bus.$on("uploadError", (fileId) => {
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].id === fileId) {
          this.fileDatas[i].error = true;
          this.fileDatas[i].collapsed = false;
          break;
        }
      }
    });
    bus.$on("uploadRetry", (fileId) => {
      // console.log("uploadRetry", fileId);
      for (let i = 0; i < this.files.length; i++) {
        if (this.files[i].id === fileId) {
          if (this.files[i].id === fileId) {
            this.fileDatas[i].error = false;
            break;
          }
        }
      }
    });
    bus.$on("uploadFileAdded", (data) => {
      // console.log("data", data);
      let collapsed = this.fileDatas.length ? false : true;
      this.files.push(data);
      let base64 = null;

      if (
        data.id3 != null &&
        data.id3.APIC != undefined &&
        data.id3.APIC != null
      ) {
        let image =
          data.id3.APIC[0] === undefined ? data.id3.APIC : data.id3.APIC[0];
        image = image.data;
        if (typeof image.data === "string") {
          image.data = this.str2ab(image.data);
        }
        var base64String = "";
        for (var i = 0; i < image.data.length; i++) {
          base64String += String.fromCharCode(image.data[i]);
        }
        // console.log({
        //   base64String: base64String,
        //   format: image.format,
        // })
        if(base64String !== "") {
          base64 =
            "data:" + image.format + ";base64," + window.btoa(base64String);
        }
      }
      let title = "";
      if (data.id3 != null && data.id3.title != null) {
        let splited = data.id3.title.split("");
        for (let index in splited) {
          if (splited[index].charCodeAt(0) !== 0) {
            title += splited[index];
          }
        }
      }

      this.fileDatas.push({
        collapsed: collapsed,
        showLyricsPlaceholder: true,
        showDescriptionPlaceholder: true,
        imgUpload: false,
        error: false,
        isLoading: false,
        saved: false,
        packages: [
          {
            currentIndex: 0,
            flex: 3,
            list: ["Free", "For Sale"],
            textAlign: "center",
            className: "row-group",
          },
          {
            currentIndex: 0,
            flex: 3,
            list: ["0.00$"],
            textAlign: "center",
            className: "row-group",
          },
          {
            currentIndex: 0,
            flex: 3,
            list: ["0T"],
            textAlign: "center",
            className: "item-group",
          },
          {
            currentIndex: 0,
            flex: 3,
            list: ["Original", "Preview"],
            textAlign: "center",
            className: "item-group",
          },
        ],
        tabIndex: null,
        post: {
          title: title,
          base64Cover: base64,
          slug: "",
          postType: data.pt,
          description: "",
          lyrics: "",
          genres: "",
          hashtags: "",
          recordLabels: "",
          public: 1,
          download: 1,
          comment: 1,
          offline: 1,
          releaseTypeId: 1,
          languages: "",
          explicit: "0",
          licenseTypeId: 1,
          users: "",
          releaseDate: "",
          moods: [],
          package: null,
          playbackType: 1,
          filename: data.fileName,
        },
      });
      this.onKeyUpTitle(this.fileDatas.length - 1);
    });
  },
};
</script>