<template>
  <div v-if="items.length" class="widget padding-x" ref="mainContainer">
    <div>
      <div class="widget-title" v-if="options.title != undefined">
        <div class="uk-flex uk-flex-middle" uk-grid>
          <div class="uk-width-expand">
            <h4 class="f-s-13">{{ options.title | lang({artist: value && value.fullname}) }}</h4>
            <h6 class="f-s-090">{{ options.subtitle | lang({artist: value && value.fullname}) }}</h6>
          </div>
          <div class="uk-width-auto">
            <router-link :to="seeAllUrl" class="see-all-right"
              >See all</router-link
            >
          </div>
        </div>
      </div>
      <div class="widget-content video-slider-widget">
        <div class="video-slider">
          <flicking
            :options="{
              renderOnlyVisible: false,
              align: 'prev',
              preventClickOnDrag: true,
              bound: true,
              circularFallback: 'bound',
            }"
          >
            <div
              class="flicking-panel"
              v-for="(item, index) in items"
              :key="index"
            >
              <VideoSliderItem :options="{ subclass: '' }" :item="item" />
            </div>
          </flicking>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import VideoSliderItem from "@/components/Items/VideoSliderItem";

export default {
  components: { VideoSliderItem },
  props: {
    options: {},
    value: {},
    staticItems: {},
    subclass: {},
    breakpoints: {
      type: Object,
      default: () => {
        return {
          640: {
            slidesPerView: "auto",
            spaceBetween: 0,
            freeMode: true,
            freeModeMomentum: true,
            freeModeMomentumRatio: "0.5",
          },
          1080: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
          1600: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
          2000: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
          10000: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },

          // 320: {
          //     slidesPerView: 1.2,
          //     spaceBetween: 12
          // },
          // 480: {
          //     slidesPerView: 1.4,
          //     spaceBetween: 12
          // },
          // 640: {
          //     slidesPerView: 2.2,
          //     spaceBetween: 12
          // },
          // 1080: {
          //     slidesPerView: 3,
          //     spaceBetween: 10
          // },
          // 2000: {
          //     slidesPerView: 4.3,
          //     spaceBetween: 10
          // },
          // 10000: {
          //     slidesPerView: 6.3,
          //     spaceBetween: 10
          // }
        };
      },
    },
  },
  computed: {
    itemsLength: function () {
      if (
        this.items === undefined ||
        this.items === null ||
        this.items.length < 1
      )
        return "0";
      return this.items.length;
    },
    seeAllUrl() {
      let routeName = this.$route.name;
      let uri = "";
      switch (routeName) {
        case "explore":
          uri = "/explore/videos";
          break;
        default:
          if (this.$route.params.username !== undefined) {
            uri = "/" + this.$route.params.username + "/videos";
          }
          break;
      }
      return uri;
    },
  },
  watch: {
    $route: function () {
      // this.destroy()
      this.parseData();
    },
    items: function (local) {
      // console.debug('local', local)
    },
    staticItems: function() {
      this.parseData()
    }
  },
  directives: {},
  data() {
    return {
      routeKey: "",
      isLoading: false,
      items: [],
      sliderInit: false,
      itemsToShow: 2.5,
      initialized: false,
      emptyCount: [...Array(20).keys()],
      key: Math.floor(Math.random() * 1000),
      parentKey: Math.random(),
      height: "auto",
      width: 0,
      coverHeight: null,
      currentSlide: 0,
      coversLoadedStatus: [],
      mouse: {
        startedAt: 0,
        endAt: 0,
        lastPos: 0,
      },
    };
  },
  created() {},
  beforeDestroy() {},

  methods: {
    destroy() {
      this.sliderInit = false;
      if (typeof this.videoSlider === "object")
        this.videoSlider.destroy(true, true);
    },
    closeSearch() {
      bus.$emit("closeSearch");
    },
    play(item) {
      this.dispatch("play", item);
    },
    parseData() {
      if(this.staticItems && this.staticItems.length) {
        this.items = this.staticItems;
        return;
      }
      let routeName = this.$route.name;
      if (routeName === "profileArtist") {
        routeName += "_" + this.$route.params.username;
      }
      if (routeName === this.routeKey) {
        return;
      } else {
        this.items = [];
        this.coversLoadedStatus = [];
      }
      // console.log("video slider", routeName);
      this.routeKey = routeName;
      if (this.$route.name === "profileArtist") {
        axios
          .get(
            `/posts?count=15&username=${this.$route.params.username}&postType=VID&popular=true`
          )
          .then((res) => {
            const dt = res.data;
            this.items = dt;
            bus.$emit('profileArtistWidgets', {
                items: this.items.length,
                username: this.$route.params.username
            })
          });
      } else {
        axios.get(this.options.url).then((res) => {
          const dt = res.data;
          this.items = dt;
        });
      }
    },
  },
  mounted() {
    this.parseData();
  },

  beforeDestroy() {
    // if (this.options.url.indexOf("/") === -1) {
    //   bus.$off(
    //     (this.$route.params.username !== undefined
    //       ? this.$route.params.username
    //       : "") +
    //       "g" +
    //       this.options.url
    //   );
    // }
  },
};
</script>
