<template>
    <div class="widget padding-x">
        <div class="widget-content">
            <div>
                <div class="f-f-reg f-s-095 sale-and-promotions-title">
                    Active Promotions
                </div>
                <div v-for="(promote, index) in promotes">
                    <promote-item :item="promote" :index="index"/>
                </div>
            </div>
            <div>
                <div class="f-f-reg f-s-095 sale-and-promotions-title">
                    Posts Sale
                </div>
                <div v-for="(sale, index) in sales">
                    <promote-item :item="sale" :index="index"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import PromoteItem from "@/components/Items/PromoteItem";
    export default {
        components: {
            PromoteItem
        },
        data() {
            return {
                promotes: [],
                sales: []
            }
        },
        mounted() {
            this.getData()
        },
        methods: {
            getData() {
                axios.get(API.liveStats)
                    .then((resp) => {
                        this.promotes = resp.data.promotes
                        this.sales = resp.data.sales
                    })
            }
        }
    }
</script>