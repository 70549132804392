var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      ref: "confirmModal",
      on: {
        close: function ($event) {
          return _vm.confirmCancel()
        },
      },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
        _vm._v(_vm._s(_vm._f("lang")(_vm.title))),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "body" }, slot: "body" }, [
        _vm._v(_vm._s(_vm._f("lang")(_vm.desc))),
      ]),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c(
          "button",
          {
            staticClass: "uk-button uk-button-default",
            on: { click: _vm.confirmAction },
          },
          [
            _vm._v(
              "\n          " + _vm._s(_vm._f("lang")(_vm.btn)) + "\n        "
            ),
          ]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }