var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget padding-x" }, [
    _c("div", { staticClass: "widget-title" }, [
      _c("h4", { staticClass: "f-s-13" }, [
        _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
      ]),
      _vm._v(" "),
      _c("h6", { staticClass: "f-s-090" }, [
        _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "widget-content capsule-slider-widget" }, [
      _c("div", { staticClass: "capsule-slider" }, [
        _c(
          "div",
          { staticClass: "capsule-slider-items" },
          [
            _c(
              "flicking",
              {
                attrs: {
                  options: {
                    renderOnlyVisible: false,
                    align: "prev",
                    preventClickOnDrag: true,
                    bound: true,
                    circularFallback: "bound",
                  },
                },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flicking-panel" },
                  [
                    _c("CapsuleItem", {
                      attrs: {
                        item: item,
                        options: { subclass: "capsule-slider-item" },
                      },
                      on: { dop: _vm.dop, moreMenu: _vm.moreMenu },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }