<template>
    <transition name="modal" v-if="show">
        <div class="modal-mask" @click="clickOnMask">
            <div class="modal-wrapper tabbed">
                <div class="modal-container">
                    <div @click="close" class="png-ico close small">
                    </div>
                    <div class="modal-tab-titles">
                        <div v-for="index in tabsArr" @click="setCurrentTab(index)" class="modal-tab-title f-s-085">
                            <slot :name="'title' + index">
                            </slot>
                        </div>
                    </div>
                    <div ref="modalTabBorder" class="modal-tab-title-border" :style="{width: tabBorderWidth}"></div>
                    <div class="modal-tabs">
                        <div class="modal-tabs-inner" ref="containerTabsInner" :style="{width: containerWidth}">
                            <div v-for="index in tabsArr" class="modal-tab">
                                <div class="modal-header">
                                    <slot :name="'header'+index">
                                    </slot>
                                </div>
                                <div class="modal-body  flex-h-100">
                                    <slot :name="'body'+index">
                                    </slot>
                                </div>
                                <div class="modal-footer">
                                    <slot :name="'footer'+index">
                                    </slot>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            tabs: {
                type: Number,
                default: 5
            }
        },
        data() {
            return {
                current: 0,
                containerKey: Math.random()
            }
        },
        computed: {
            tabsArr() {
                return [...Array(this.tabs).keys()]
            },
            containerTransform() {
                let percent = 0
                if (this.current != 0) {
                    let sW = 100 / this.tabs
                    percent = this.current * sW * -1
                }
                return 'translateX(' + percent + '%)'
            },
            tabBorderTransform() {
                let percent = 0
                if (this.current != 0) {
                    percent = 100 * this.current
                }
                return 'translateX(' + percent + '%)'
            },
            containerWidth() {
                return this.tabs * 100 + '%';
            },
            tabBorderWidth() {
                return 100 / this.tabs + '%'
            }
        },
        watch: {
            show(newVal) {
                if (newVal) {
                    document.body.setAttribute('data-modal', '1')
                } else {
                    document.body.removeAttribute('data-modal')
                }
            }
        },
        methods: {
            setCurrentTab(index) {
                this.$emit('setTab', index)
                this.current = index
                this.$refs.containerTabsInner.style.transform = this.containerTransform
                this.$refs.modalTabBorder.style.transform = this.tabBorderTransform
            },
            close() {
                this.$emit('close')
            },
            clickOnMask(e) {
                if (e.target.className === "modal-wrapper tabbed") {
                    this.close()
                }
            }
        }
    }
</script>