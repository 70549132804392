<template>
  <transition name="modal" v-if="show">
    <div class="more-menu-bg" @click="clickOnBg">
      <div
        v-if="show"
        class="more-menu-container"
        :style="position"
        :data="data"
        :class="this.class"
      >
        <div
          ref="tdiv"
          v-if="item.username != undefined"
          :style="{ height: tdivHeight, width: tdivWidth }"
        >
          <div
            class="home"
            :style="{ position: innerPosition }"
            ref="home"
            v-bind:hidden="currentPage !== 'home'"
          >
            <div role="menu">
              <button
                v-if="
                  this.auth.id === undefined || this.auth.id != this.item.id
                "
                @click="showReportModal()"
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span><i class="fal fa-flag"></i>{{ "reports" | lang }}</span>
              </button>
              <button
                @click="showProfileModal('profile')"
                <button
                @click="showProfileModal('profile')"
                v-if="
                  this.auth.id != undefined &&
                  (this.auth.id === this.item.id ||
                    canChangeProfileRoles.indexOf(this.auth.role) !== -1)
                "
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-image-polaroid"></i
                  >{{ "change_avatar" | lang }}</span
                >
              </button>
              <button
                @click="showProfileModal('cover')"
                v-if="
                  this.auth.id != undefined &&
                  (this.auth.id === this.item.id ||
                    canChangeProfileRoles.indexOf(this.auth.role) !== -1)
                "
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-image"></i>{{ "change_cover" | lang }}</span
                >
              </button>
              <button
                @click="logout"
                v-if="
                  this.auth.id != undefined && this.auth.id === this.item.id
                "
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-sign-out"></i>{{ "logout" | lang }}</span
                >
              </button>
            </div>
          </div>
        </div>
        <div
          ref="tdiv"
          v-if="item.files != undefined"
          :style="{ height: tdivHeight, width: tdivWidth }"
        >
          <div
            class="home"
            :style="{ position: innerPosition }"
            ref="home"
            v-bind:hidden="currentPage !== 'home'"
          >
            <div role="menu">
              <button
                v-for="(file, i) in item.files"
                :key="i"
                type="button"
                role="menuitem"
                aria-haspopup="true"
                @click="doDownload(file.url)"
                :disabled="!!(qualities[file.label].forPro && !auth.is.pro)"
              >
                <span
                  ><i class="fal fa-circle"></i> {{ file.label }}
                  <span v-if="qualities[file.label].forPro">
                    - For Pro</span
                  ></span
                >
              </button>
            </div>
          </div>
        </div>
        <div
          ref="tdiv"
          v-if="item.username === undefined && item.files === undefined"
          :style="{ height: tdivHeight, width: tdivWidth }"
        >
          <div
            class="home"
            :style="{ position: innerPosition }"
            ref="home"
            v-bind:hidden="currentPage !== 'home'"
          >
            <div role="menu">
              <button
                v-if="
                  auth != null &&
                  item.user.id === auth.id &&
                  item.postType === 'SND'
                "
                @click="setPage($event, 'album')"
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-album-collection"></i
                  >{{ "add_to_album" | lang }}</span
                >
              </button>
              <button
                @click="setPage($event, 'playlist')"
                v-if="item.postType === 'SND'"
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-list-music"></i
                  >{{ "add_to_playlist" | lang }}</span
                >
              </button>
              <button
                @click="likesModal"
                v-if="item.postType === 'SND' || item.postType === 'VID'"
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-heartbeat"></i
                  >{{ "likes_and_reposts" | lang }}</span
                >
              </button>
              <button
                @click="doEdit"
                type="button"
                v-if="auth != null && item.user.id === auth.id"
                role="menuitem"
                aria-haspopup="true"
              >
                <span><i class="fal fa-edit"></i>{{ "edit" | lang }}</span>
              </button>
              <button
                @click="setPage($event, 'removeFromPlaylist')"
                v-if="removeFrom.PLY.length"
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-layer-minus"></i
                  >{{ "remove_from_playlist" | lang }}</span
                >
              </button>
              <button
                @click="setPage($event, 'removeFromAlbum')"
                v-if="
                  auth != null &&
                  ['PLY', 'ALB'].indexOf(item.postType) === -1 &&
                  item.user.id === auth.id &&
                  removeFrom.ALB.length
                "
                type="button"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-album"></i
                  >{{ "remove_from_album" | lang }}</span
                >
              </button>
              <button
                type="button"
                role="menuitem"
                @click="deletePost()"
                v-if="auth != null && item.user.id === auth.id"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-trash-alt"></i>{{ "delete" | lang }}</span
                >
              </button>
              <button
                type="button"
                role="menuitem"
                aria-haspopup="true"
                @click="promotePost"
                v-if="auth != null && item.user.id === auth.id"
              >
                <span
                  ><i class="fal fa-rocket"></i
                  >{{ "promote_this_post" | lang }}</span
                >
              </button>
              <!--                    <button type="button" role="menuitem" aria-haspopup="true" @click="blockCountries"-->
              <!--                            v-if="auth!=null && item.user.id===auth.id">-->
              <!--                        <span><i class="fal fa-map-marker-slash"></i>{{77 | lang}}</span>-->
              <!--                    </button>-->
              <button
                type="button"
                @click="showReportModal('post')"
                role="menuitem"
                aria-haspopup="true"
              >
                <span><i class="fal fa-flag"></i>{{ "report" | lang }}</span>
              </button>
              <!--                    <button type="button" role="menuitem" aria-haspopup="true" @click="postEmbed">-->
              <!--                        <span><i class="fal fa-code"></i>{{78 | lang}}</span>-->
              <!--                    </button>-->
              <!--                    <button type="button" role="menuitem" aria-haspopup="true" @click="postShare">-->
              <!--                        <span><i class="fal fa-share-alt"></i>{{29 | lang}}</span>-->
              <!--                    </button>-->
            </div>
          </div>
          <div
            class="album"
            :style="{ position: innerPosition }"
            ref="album"
            v-bind:hidden="currentPage !== 'album'"
          >
            <div role="menu">
              <div class="back-menu-item" @click="setPage($event, 'home')">
                <span
                  ><i class="fal fa-long-arrow-left"></i>
                  {{ "back" | lang }}</span
                >
              </div>
              <button
                class="create-button"
                type="button"
                @click="setPage($event, 'newAlbum')"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-album-collection"></i
                  >{{ "create_new_album" | lang }}</span
                >
              </button>
              <button
                type="button"
                @click="addToSet(album, 'ALB')"
                v-for="(album, i) in sets.ALB"
                :key="i"
                role="menuitem"
                aria-haspopup="true"
              >
                <span>{{ album.title }}</span>
              </button>
            </div>
          </div>
          <div
            class="new-album"
            :style="{ position: innerPosition }"
            ref="newAlbum"
            v-bind:hidden="currentPage !== 'newAlbum'"
          >
            <div role="menu">
              <div class="back-menu-item" @click="setPage($event, 'album')">
                <span
                  ><i class="fal fa-long-arrow-left"></i>
                  {{ "back" | lang }}</span
                >
              </div>
              <div>
                <input
                  type="text"
                  :placeholder="'album_name' | lang"
                  v-model="album.title"
                />
              </div>
              <div class="uk-text-right">
                <button
                  class="uk-button uk-button-default button-top-menu button-bg"
                  @click="saveSet('ALB')"
                >
                  {{ "save" | lang }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="playlist"
            :style="{ position: innerPosition }"
            ref="playlist"
            v-bind:hidden="currentPage !== 'playlist'"
          >
            <div role="menu">
              <div class="back-menu-item" @click="setPage($event, 'home')">
                <span
                  ><i class="fal fa-long-arrow-left"></i>
                  {{ "back" | lang }}</span
                >
              </div>
              <button
                class="create-button"
                type="button"
                @click="setPage($event, 'newPlaylist')"
                role="menuitem"
                aria-haspopup="true"
              >
                <span
                  ><i class="fal fa-list-music"></i
                  >{{ "create_new_playlist" | lang }}</span
                >
              </button>
              <button
                type="button"
                @click="addToSet(playlist, 'PLY')"
                v-for="(playlist, i) in sets.PLY"
                :key="i"
                v-if="addTo.PLY.indexOf(playlist.realId) !== -1"
                role="menuitem"
                aria-haspopup="true"
              >
                <span>{{ playlist.title }}</span>
              </button>
            </div>
          </div>
          <div
            class="new-playlist"
            :style="{ position: innerPosition }"
            ref="newPlaylist"
            v-bind:hidden="currentPage !== 'newPlaylist'"
          >
            <div role="menu">
              <div class="uk-flex uk-flex-middle" uk-grid>
                <div class="uk-width-expand">
                  <div
                    class="back-menu-item"
                    @click="setPage($event, 'playlist')"
                  >
                    <span
                      ><i class="fal fa-long-arrow-left"></i>
                      {{ "back" | lang }}</span
                    >
                  </div>
                </div>
                <div class="uk-width-auto">
                  <label
                    @click="playlist.public = !playlist.public"
                    class="switch-title f-s-075"
                  >
                    {{ "public" | lang }}
                  </label>
                  <p-check
                    class="p-switch p-fill"
                    ref="playlist-public"
                    v-model="playlist.public"
                    color="success"
                  ></p-check>
                </div>
              </div>
              <div>
                <input
                  type="text"
                  :placeholder="'playlist_name' | lang"
                  v-model="playlist.title"
                />
              </div>
              <div v-if="moods.length">
                <div class="uk-grid-none" uk-grid>
                  <div v-for="(mood, i) in moods" :key="i" class="uk-width-1-4">
                    <div
                      class="mood"
                      @click="selectMood(mood)"
                      :class="{
                        active: playlist.moods.indexOf(mood.id) !== -1,
                      }"
                    >
                      <div class="img">
                        <img :src="mood.attach | attach('xs', 'assets')" />
                      </div>
                      <div class="uk-text-truncate f-s-080">
                        {{ mood.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-text-right">
                <button
                  class="uk-button uk-button-default button-top-menu button-bg"
                  @click="saveSet('PLY')"
                  :disabled="playlist.title === ''"
                >
                  {{ "save" | lang }}
                </button>
              </div>
            </div>
          </div>
          <div
            class="remove-from-playlist"
            :style="{ position: innerPosition }"
            ref="removeFromPlaylist"
            v-bind:hidden="currentPage !== 'removeFromPlaylist'"
          >
            <div role="menu">
              <div class="back-menu-item" @click="setPage($event, 'home')">
                <span
                  ><i class="fal fa-long-arrow-left"></i>
                  {{ "back" | lang }}</span
                >
              </div>
              <button
                type="button"
                @click="removeFromSet(playlist, 'PLY')"
                v-for="(playlist, index) in sets.PLY"
                :key="index"
                v-if="removeFrom.PLY.indexOf(playlist.realId) !== -1"
                role="menuitem"
                aria-haspopup="true"
              >
                <span>{{ playlist.title }}</span>
              </button>
            </div>
          </div>
          <div
            class="remove-from-album"
            :style="{ position: innerPosition }"
            ref="removeFromAlbum"
            v-bind:hidden="currentPage !== 'removeFromAlbum'"
          >
            <div role="menu">
              <div class="back-menu-item" @click="setPage($event, 'home')">
                <span
                  ><i class="fal fa-long-arrow-left"></i>
                  {{ "back" | lang }}</span
                >
              </div>
              <button
                type="button"
                @click="removeFromSet(album, 'ALB')"
                v-for="(album, i) in sets.ALB"
                :key="i"
                v-if="removeFrom.ALB.indexOf(album.realId) !== -1"
                role="menuitem"
                aria-haspopup="true"
              >
                <span>{{ album.title }}</span>
              </button>
            </div>
          </div>
        </div>
        <div ref="triangle" :style="triangleStyle" class="triangle"></div>
      </div>
      <modal @close="closeConfirm()" ref="confirmModal" v-model="confirmModal">
        <h3 slot="header">{{ "delete" | lang }}</h3>
        <div slot="body" v-html="confirmText">{{ confirmText }}</div>
        <div slot="footer">
          <button @click="deleteAction" class="uk-button uk-button-default">
            {{ "delete" | lang }}
          </button>
        </div>
      </modal>
    </div>
  </transition>
</template>

<script>
import ClickOutside from "vue-click-outside";
import axios from "axios";
import { DO_SET_ACTIVE_ITEM, SET_ACTIVE_ITEM } from "../store/actions/moremenu";
import helper from "@/helpers/helper";
import { AUTH_LOGOUT } from "@/store/actions/auth";

export default {
  directives: {
    ClickOutside,
  },
  data() {
    return {
      confirmText: "",
      confirmModal: false,
      confirmAction: "",
      canChangeProfileRoles: ["owner", "developer", "admin"],
      download: false,
      parentKey: 122,
      currentPage: "home",
      position: {
        top: 0,
        left: 0,
      },
      triangleStyle: {
        left: 0,
        right: 0,
      },
      lastTry: 0,
      show: false,
      tdivHeight: "auto",
      tdivWidth: "auto",
      marginX: 28,
      marginY: 15,
      class: "",
      target: null,
      moods: [],
      album: {
        title: "",
        postType: "ALB",
        tracks: [],
      },
      playlist: {
        title: "",
        moods: [],
        public: true,
        postType: "PLY",
        tracks: [],
      },
      maxMoods: 2,
      sets: {
        PLY: [],
        ALB: [],
      },
      inAlbums: [],
      inPlaylists: [],
      removeFrom: {
        ALB: [],
        PLY: [],
      },
      addTo: {
        ALB: [],
        PLY: [],
      },
    };
  },
  props: ["data"],
  methods: {
    clickOnBg(e) {
      if (e.target.className === "more-menu-bg") {
        this.show = false;
      }
    },
    doDownload(url) {
      window.open(url, "_blank");
    },
    likesModal() {
      this.show = false;
      bus.$emit("likesModal", {
        id: this.item.realId,
        likes: this.item.stats.likes,
        reposts: this.item.stats.reposts,
      });
    },
    logout() {
      this.show = false;
      this.$store.dispatch(AUTH_LOGOUT);
    },
    doEdit() {
      this.show = false;
      if (["ALB", "PLY"].indexOf(this.item.postType) != -1) {
        this.$router.push(helper.permalink(this.item));
        localStorage.setItem("pageAction", "edit");
      } else if (["SND", "VID"].indexOf(this.item.postType) != -1) {
        this.$router.push("/edit/" + this.item.id);
      }
    },
    showProfileModal(type) {
      this.show = false;
      bus.$emit("profileOpenModal", type);
    },
    showReportModal(type) {
      type = type === undefined ? "user" : "post";
      switch (type) {
        case "user":
          bus.$emit("ReportModal", {
            profileId: this.item.id,
            photo: this.item.photos.avatar,
            title: this.item.username,
            type: "user",
          });
          break;
        case "post":
          bus.$emit("ReportModal", {
            postId: this.item.id,
            photo: this.item.metas.cover,
            title: this.item.title,
            type: "post",
          });
          break;
      }
      this.show = false;
    },
    postShare() {},
    postEmbed() {},
    blockCountries() {},
    deletePost(isConfirm) {
      if (isConfirm === undefined) {
        bus.$emit("confirmReq", {
          title: "modal_delete_post_title",
          btn: "modal_delete_post_btn",
          desc: this.$parent.$options.filters.lang(
            "modal_delete_post_desc",
            {
              title: this.item.title,
            },
            true
          ),
          action: {
            name: 'moreMenuBusCall',
            a0: 'deletePost',
            a1: 1,
          }
        });
        return;
      }
      //$options.filters.lang('delete_modal_desc')
      this.show = false;
      bus.$emit("deletingPost", this.item.realId);
      axios
        .delete(API.posts, {
          data: {
            id: this.item.realId,
          },
        })
        .then((res) => {
          bus.$emit("success", {
            title: this.$parent.$options.filters.lang("success"),
            message: this.$parent.$options.filters.lang(
              "title_removed_successfully",
              {
                title: this.item.title,
              }
            ),
          });
          bus.$emit("disableDeletingPost", this.item.realId);
          bus.$emit("deletePost", this.item.realId);
        })
        .catch((e) => {
          bus.$emit("error", {
            title: this.$parent.$options.filters.lang("error"),
            message: this.$parent.$options.filters.lang("something_went_wrong"),
          });
          bus.$emit("disableDeletingPost", this.item.realId);
        });
    },
    promotePost() {
      this.show = false;
      bus.$emit("promotedPostModal", this.item);
    },
    addToSet(set, type) {
      this.show = false;
      let ids = this.$_.pluck(this.sets[type], "realId");
      let index = ids.indexOf(set.realId);
      this.sets[type][index].tracks.push(this.item);
      axios
        .put(API.setTrack, {
          type: type,
          setId: set.realId,
          tracks: [this.item.realId],
        })
        .then((res) => {
          bus.$emit("success", {
            title: this.$parent.$options.filters.lang("success"),
            message: this.$parent.$options.filters.lang(
              "title_added_successfully_type",
              {
                title: this.item.title,
                type: this.$parent.$options.filters.lang(type),
              }
            ),
          });
        });
    },
    closeConfirm() {
      this.confirmModal = false;
    },
    removeFromSet(set, type, isConfirm) {
      let ids = this.$_.pluck(this.sets[type], "realId");
      let index = ids.indexOf(set.realId);
      let tracks = this.$_.pluck(this.sets[type][index].tracks, "realId");
      let trackIndex = tracks.indexOf(this.item.realId);
      if (isConfirm === undefined) {
        bus.$emit("confirmReq", {
          title: "modal_delete_from_set_title",
          btn: "modal_delete_from_set_btn",
          desc: this.$parent.$options.filters.lang(
            "modal_delete_from_set_desc",
            {
              title: this.item.title,
              set: this.sets[type][index].title,
            },
            true
          ),
          action: {
            name: 'moreMenuBusCall',
            a0: 'removeFromSet',
            a1: set,
            a2: type,
            a3: 1
          }
        });
        return;
      }
      this.sets[type][index].tracks.splice(trackIndex, 1);
      this.show = false;
      axios
        .delete(API.setTrack, {
          data: {
            type: type,
            setId: set.realId,
            tracks: [this.item.realId],
          },
        })
        .then((res) => {
          bus.$emit("success", {
            title: this.$parent.$options.filters.lang("success"),
            message: this.$parent.$options.filters.lang(
              "title_removed_successfully_type",
              {
                title: this.item.title,
                type: this.$parent.$options.filters.lang(type),
              }
            ),
          });
        });
    },
    saveSet(type) {
      this.show = false;
      let data = type === "ALB" ? this.album : this.playlist;
      axios
        .post(API.posts, data)
        .then((res) => {
          bus.$emit("success", {
            title: "",
            // title_type_created_successfully
            message: this.$parent.$options.filters.lang(
              "title_type_created_successfully",
              {
                title: this.item.title,
                type: this.$parent.$options.filters.lang(type),
              }
            ),
          });
          this.sets[type].push(res.data.post);
        })
        .catch((e) => {
          let msg = "";
          if (e.response.status === 422) {
            msg =
              e.response.data.errors[Object.keys(e.response.data.errors)[0]][0];
          } else {
            msg = this.$parent.$options.filters.lang("something_went_wrong");
          }
          bus.$emit("error", {
            title: "",
            message: msg,
          });
        });
    },
    selectMood(mood) {
      if (this.playlist.moods.indexOf(mood.id) === -1) {
        if (this.maxMoods <= this.playlist.moods.length) {
          this.playlist.moods.splice(this.playlist.moods.length - 1, 1);
        }
        this.playlist.moods.push(mood.id);
      } else {
        this.playlist.moods.splice(this.playlist.moods.indexOf(mood.id), 1);
      }
    },
    setPage(e, name) {
      if (name === "album" || name === "playlist") {
        if (this.auth.username === undefined) {
          this.show = false;
          bus.$emit("showLogin");
          return;
        }
      }
      if (this.$refs.tdiv === undefined) {
        this.tdivWidth = "auto";
        this.tdivHeight = "auto";
        return;
      }
      let element = this.$refs.tdiv;
      requestAnimationFrame(() => {
        this.tdivHeight =
          this.$refs[this.currentPage].getBoundingClientRect().height + "px";
        this.tdivWidth =
          this.$refs[this.currentPage].getBoundingClientRect().width + "px";
        this.currentPage = name;
        this.innerPosition = "absolute";
        requestAnimationFrame(() => {
          this.innerPosition = "relative";
          this.tdivHeight =
            this.$refs[this.currentPage].getBoundingClientRect().height + "px";
          this.tdivWidth =
            this.$refs[this.currentPage].getBoundingClientRect().width + "px";
        });
      });
      e.preventDefault();
    },
    calcPos() {
      let triangle = { width: 8 };
      let clientRect = this.target.getBoundingClientRect();
      let winHeight = window.innerHeight;
      let winWidth = document.body.clientWidth;
      let yPos = "top";
      let xPos = "left";
      if (winHeight / 2 >= clientRect.y) {
        yPos = "bottom";
      }
      if (winWidth / 2 >= clientRect.x) {
        xPos = "right";
      }
      this.class = yPos + " " + xPos;
      if (yPos === "top") {
        this.position = {
          bottom:
            winHeight -
            clientRect.y -
            clientRect.height / 2 +
            this.marginY +
            "px",
          top: "auto",
        };
      } else {
        this.position = {
          bottom: "auto",
          top: clientRect.y + clientRect.height / 2 + this.marginY + "px",
        };
      }
      if (xPos === "left") {
        this.position.right =
          winWidth - clientRect.x - this.target.offsetWidth + "px";
        this.position.left = "auto";
        this.triangleStyle = {
          right: this.target.offsetWidth / 2 - triangle.width / 2 + "px",
          left: "auto",
        };
      } else {
        this.position.right = "auto";
        this.position.left = clientRect.x + "px";
        this.triangleStyle = {
          left: this.target.offsetWidth / 2 - triangle.width / 2 + "px",
          right: "auto",
        };
      }
    },
    outsideClicked(e) {
      if (new Date().getTime() > this.lastTry + 100) {
        this.show = false;
        //          bus.$emit('outSideMoremenuClicked', true)
        this.$store.commit(DO_SET_ACTIVE_ITEM, null);
      }
    },
    MoreMenuOpopupOnScroll() {
      if (this.show === true) {
        this.calcPos();
      }
    },
  },
  watch: {
    data: {
      handler: function (data) {},
      deep: true,
    },
    show(newVal) {
      if (newVal) {
        document.body.setAttribute("data-moremenu", "1");
      } else {
        document.body.removeAttribute("data-moremenu");
        this.$store.commit(DO_SET_ACTIVE_ITEM, null);
      }
    },
  },
  computed: {
    auth() {
      return this.$store.getters.getProfile;
    },
    isMe() {
      if (this.item.username != undefined) {
        return this.auth.id != undefined && this.auth.id === this.item.id;
      }
    },
    settings() {
      return this.$store.getters.settings;
    },
    qualities() {
      try {
        let obj = {};
        let qualities = this.settings.qualities.filter((e) => {
          return e.type === "video";
        });
        qualities.map((e) => {
          obj[e.name] = e;
        });
        return obj;
      } catch (e) {
        return {};
      }
    },
  },
  mounted() {
    let vm = this;
    document
      .getElementById("site-main-col")
      .addEventListener("scroll", this.MoreMenuOpopupOnScroll);
    bus.$on("settingsGetted", function (settings) {
      vm.moods = settings.moods;
    });
    bus.$on("mySets", (data) => {
      this.sets = data;
    });
    bus.$on("closeMoreMenu", () => {
      this.show = false;
      this.$store.commit(DO_SET_ACTIVE_ITEM, null);
    });
    bus.$on('moreMenuBusCall', (a0, a1, a2, a3) => {
      this[a0](a1, a2, a3)
    })
    bus.$on("clickMoreMenu", (data, e, type) => {
      this.parentKey = Math.floor(Math.random() * 10000);
      let types = ["PLY", "ALB"];
      let thisSetTracks = [];
      this.item = data;
      this.lastTry = new Date().getTime();
      this.show = false;
      this.tdivWidth = "auto";
      this.tdivHeight = "auto";
      this.currentPage = "home";
      this.settings = null;
      this.playlist.tracks = [data.realId];
      this.album.tracks = [data.realId];
      setTimeout(() => {
        //if (type === undefined || type === 'VPostItem') {
        if (this.item.username === undefined) {
          for (let o = 0; o < types.length; o++) {
            this.removeFrom[types[o]] = [];
            this.addTo[types[o]] = [];
            if (this.sets[types[o]].length) {
              for (let i = 0; i < this.sets[types[o]].length; i++) {
                let tracks = this.$_.pluck(
                  this.sets[types[o]][i].tracks,
                  "realId"
                );
                if (tracks.indexOf(data.realId) !== -1) {
                  this.removeFrom[types[o]].push(this.sets[types[o]][i].realId);
                } else {
                  this.addTo[types[o]].push(this.sets[types[o]][i].realId);
                }
              }
            }
          }
        }
        //}
        this.$store.commit(DO_SET_ACTIVE_ITEM, type + "_" + data.realId);
        //          bus.$emit('activeState', type+'_'+data.realId)
        this.show = true;
      }, 0);
      this.target = e.target;
      this.calcPos();
    });
  },
  beforeDestroy() {
    document
      .getElementById("site-main-col")
      .removeEventListener("scroll", this.MoreMenuOpopupOnScroll);
  },
};
</script>