var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "vow-select" }, [
    _c(
      "div",
      { ref: "main", staticClass: "ninp tp", on: { click: _vm.clickOnMain } },
      [
        _vm._l(_vm.selectedItems, function (si, i) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isFocus,
                  expression: "!isFocus",
                },
              ],
              key: i,
              staticClass: "itm",
            },
            [
              _c("div", { staticClass: "l" }, [
                _c("img", { attrs: { src: _vm.findImage(si) } }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "r" }, [
                _vm._v(
                  "\n                " +
                    _vm._s(si[_vm.itemText]) +
                    "\n            "
                ),
              ]),
            ]
          )
        }),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.search,
              expression: "search",
            },
          ],
          ref: "inp",
          staticClass: "inp",
          attrs: { type: "text" },
          domProps: { value: _vm.search },
          on: {
            click: _vm.clickOnMain,
            focusout: function ($event) {
              _vm.isFocus = false
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.search = $event.target.value
            },
          },
        }),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.items.length,
            expression: "items.length",
          },
        ],
        ref: "sub",
        staticClass: "ts",
        style: _vm.cPos,
      },
      _vm._l(_vm.items, function (itm, i) {
        return _c(
          "div",
          {
            key: i,
            staticClass: "itm",
            on: {
              click: function ($event) {
                return _vm.doSelectItem(itm)
              },
            },
          },
          [
            _c("div", { staticClass: "l" }, [
              _c("img", { attrs: { src: _vm.findImage(itm) } }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "r" }, [
              _vm._v(
                "\n                " +
                  _vm._s(itm[_vm.itemText]) +
                  "\n            "
              ),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }