var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uk-margin-top" }, [
    _c(
      "video",
      {
        ref: "videoplayer",
        staticStyle: { display: "none" },
        attrs: {
          width: "" + _vm.width + "",
          height: "" + _vm.height + "",
          controls: "",
        },
        on: { error: _vm.onErrorVideo, loadedmetadata: _vm.onLoadedMetaData },
      },
      [_c("source", { attrs: { type: "video/mp4" } })]
    ),
    _vm._v(" "),
    _c("canvas", { ref: "videocanvas", staticStyle: { display: "none" } }),
    _vm._v(" "),
    _c("div", { staticClass: "screenshots" }, [
      _c(
        "div",
        { staticClass: "swiper-wrapper" },
        _vm._l(_vm.screenshots, function (screenshot, index) {
          return _c(
            "div",
            { staticClass: "screenshot swiper-slide uk-animation-fade" },
            [
              _c("img", {
                staticClass: "screenshot-img",
                attrs: { src: screenshot },
              }),
              _vm._v(" "),
              _vm.screenshots.length === _vm.cnt
                ? _c("div", { staticClass: "screenshot-icons" }, [
                    _vm.static === undefined
                      ? _c(
                          "span",
                          {
                            staticClass: "video-upload-screenshot-icon",
                            on: {
                              click: function ($event) {
                                return _vm.getScreenshot(index)
                              },
                            },
                          },
                          [_c("i", { staticClass: "fal fa-redo-alt" })]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "span",
                      {
                        staticClass: "video-upload-screenshot-icon",
                        on: {
                          click: function ($event) {
                            return _vm.passScreenshot(index)
                          },
                        },
                      },
                      [_c("i", { staticClass: "fal fa-image" })]
                    ),
                  ])
                : _vm._e(),
            ]
          )
        }),
        0
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }