var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.metas != null &&
    _vm.metas != undefined &&
    (_vm.metas.about != null || _vm.metas.socials.length)
    ? _c(
        "div",
        { staticClass: "widget padding-x uk-animation-fade uk-animation-10" },
        [
          _c("div", { staticClass: "widget-title" }, [
            _c("h4", { staticClass: "f-s-13" }, [
              _c("i", { staticClass: "fal fa-info-circle" }),
              _vm._v(" " + _vm._s(_vm._f("lang")(_vm.options.title))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget-content" }, [
            _vm.metas.about != undefined
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "resize",
                        rawName: "v-resize",
                        value: _vm.onResize,
                        expression: "onResize",
                      },
                    ],
                    ref: "about",
                    staticClass: "user-about-text",
                    class: {
                      expand: _vm.expandAbout,
                      "disable-expand": _vm.expandDisable,
                    },
                  },
                  [
                    _c("div", { domProps: { innerHTML: _vm._s(_vm.bio) } }),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "show-more",
                        on: { click: _vm.toggleAbout },
                      },
                      [
                        !_vm.expandAbout
                          ? _c("div", [
                              _c("i", { staticClass: "fa fa-angle-down" }),
                              _vm._v(" Show More"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.expandAbout
                          ? _c("div", [
                              _c("i", { staticClass: "fa fa-angle-up" }),
                              _vm._v(" Show Less"),
                            ])
                          : _vm._e(),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.metas.socials !== null
              ? _c("div", { staticClass: "user-social-items" }, [
                  _c(
                    "div",
                    { staticClass: "uk-grid-5", attrs: { "uk-grid": "" } },
                    _vm._l(_vm.metas.socials, function (social, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "uk-width-1-5" },
                        [
                          index < 5
                            ? _c(
                                "a",
                                {
                                  staticClass: "user-social-item",
                                  attrs: {
                                    href: social.href,
                                    target: "_blank",
                                  },
                                },
                                [
                                  _c("i", {
                                    class:
                                      _vm.socialIcons[social.type] !== undefined
                                        ? _vm.socialIcons[social.type]
                                        : _vm.socialIcons.official,
                                  }),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ])
              : _vm._e(),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }