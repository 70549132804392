<template>
    <div class="notifications">
        <div v-if="header" class="header f-s-080">
            <div>
                Notifications
            </div>
            <div @click="close()">
                <router-link to="/settings/notifications">Settings</router-link>
            </div>
        </div>
        <div class="contents">
            <div class="empty" v-if="end && !notifications.length">
                <div>
                    <i class="fal fa-bell-slash f-s-25"></i>
                </div>
                <div class="f-s-075 uk-margin-small-top">
                    You didn't receive any notifications yet.
                </div>
            </div>
            <div>
                <div class="scrollbar uk-nav uk-dropdown-nav" :style="{maxHeight: (maxHeight==='auto' ? 'inherit' : (maxHeight + 'px')), height: (maxHeight==='auto' ? 'auto' : (maxHeight + 'px'))}">

                    <li class="notification" v-for="(notification, index) in notifications">
                        <div class="uk-grid-10 uk-flex uk-flex-middle" uk-grid>
                            <div class="uk-width-auto">
                                <div class="uk-position-relative">
                                    <span class="is-new" v-if="notification.isNew">
                                        <i class="fas fa-circle"></i>
                                    </span>
                                    <img class="profile-image" @click="goTo(notification.photos.user)"
                                         :src="notification.photos.user.uri"/>
                                </div>
                            </div>
                            <div class="uk-width-expand">
                                <div class="description f-s-085">
                                    <span :class="{'is-dot': description.text==='.', 'is-link': description.id!=null, 'is-bold': description.bold}"
                                          @click="goTo(description)" v-for="(description, index) in notification.description">{{(index!=0 && description.text!='.') ? '&nbsp;' : ''}}{{description.text}}</span>
                                    <span class="timeago f-s-075">{{notification.createdAtAgo}}</span>
                                </div>
                            </div>
                            <div v-if="notification.photos.post!=undefined && notification.photos.post.uri!=undefined"
                                 class="uk-width-auto">
                                <img @click="goTo(notification.photos.post)" :src="notification.photos.post.uri"/>
                            </div>
                            <div v-if="notification.type==='TEAMMATE_REQUEST'"
                                 class="uk-width-auto">
                                <button @click="teammateAccept(notification)">ACCEPT</button>
                                <button @click="teammateReject(notification)">REJECT</button>
                            </div>
                        </div>
                    </li>
                    <div class="loading" v-if="!end">
                        <i v-is-visibility="(isVisible) => loadingVisible(isVisible)" uk-spinner="ratio: 1"></i>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="!end && maxHeight!='auto'" class="notifications-footer f-s-080">
            <div @click="close">
                <router-link to="/notifications">
                    Show All
                </router-link>
            </div>
        </div>
    </div>
</template>
<style>
    .notifications .header {
        min-width: 300px;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        border-bottom: 2px groove #33333359;
    }

    .notifications .header, .notifications .header a {
        color: #fff;
    }

    .notifications .header > div {
        margin-left: auto;
    }

    .notifications .header > div:first-child {
        margin-left: 0;
    }

    .notifications .contents {
        padding: 10px;
    }

    .notifications .notifications-footer {
        border-top: 2px groove #33333359;
        text-align: center;
    }

    .notifications .notifications-footer a {
        color: #fff;
        padding: 7px 0 10px;
        display: inline-flex;
    }

    .notifications .empty {
        text-align: center;
    }


    .notifications .loading {
        text-align: center;
    }

    .notification {
        margin-bottom: 10px;
        border-bottom: 1px solid #00000026;
        padding-bottom: 10px;
    }

    .notification .is-new {
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 3;
        color: #ff5722;
        font-size: 7px;
    }

    .notification .is-bold {
        font-weight: bold;
    }

    .notification img {
        width: 35px;
        height: 35px;
        cursor: pointer;
    }

    .notification img:hover {
        opacity: 0.8;
    }

    .notification .description {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
    }

    .notification .description > span {
        display: contents;
        margin-left: 3px;
        white-space: normal;
        word-wrap:break-word;
    }

    .notification .description > span.is-dot {
        margin-left: 0;
    }

    .notification .description > span.is-link {
        color: #949494;
        cursor: pointer;
    }

    .notification .description > span.is-link:hover {
        text-decoration: underline;
    }

    .profile-image {
        border-radius: 50%;
    }
    .notification .timeago {
        color: #424242;
    }
</style>
<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                notifications: [],
                loading: false,
                end: false,
                skip: 0,
                hasUnread: false,
                initialized: false
            }
        },
        props: {
            header: {
                type: Boolean,
                default: true
            },
            maxHeight: {
                type: String,
                default: '250'
            },
            options: {
                type: Object,
                default: () => {
                    return {
                        wheelPropagation: false
                    }
                }
            },
            count: {
                type: Number,
                default: 10
            }
        },
        methods: {
            teammateAccept(notification) {
                axios.get(API.action.teammateAccept + notification.button.id)
                    .then((resp) => {
                        alert('success accepted')
                    })
                    .catch(e => {
                        let resp = e.response
                        alert('err' + resp.status)
                    })
            },
            teammateReject(notification) {
                axios.get(API.action.teammateReject + notification.button.id)
                    .then((res) => {
                        alert('success rejected')
                    })
                    .catch(e => {
                        let resp = e.response
                        alert('err' + resp.status)
                    })
            },
            goTo(obj) {
                let url = obj.url || obj.id
                if(url===undefined || url===null) return
                this.$emit('close')
                url = '/' + url.replace(/^(?:\/\/|[^\/]+)*\//, "")
                this.$router.push(url)
            },
            close() {
                this.$emit('close')
            },
            getNotifications() {
                axios.get(API.notifications + '?count=' + this.count + '&skip=' + this.notifications.length)
                    .then(res => {
                        if(!this.notifications.length) {
                            this.setBadge(0)
                        }
                        if (res.data.length < this.count) {
                            this.end = true
                        }
                        this.notifications = this.notifications.concat(res.data)
                    })
            },
            setBadge(cnt) {
                if(!!cnt) {
                    this.notifications = []
                    this.end = false
                }
                this.hasUnread = cnt
                this.$emit('setBadge', cnt)
            },
            loadingVisible(isVisible) {
                if (isVisible) {
                    this.getNotifications()
                }
            },
            getUnreadCnt(type) {
                axios.get(API.notificationsCount)
                    .then((resp) => {
                        this.setBadge(resp.data.count)
                        if(type) {
                            setTimeout(() => {
                                this.getUnreadCnt(true)
                            }, 10000)
                        }
                    })
                    .catch(() => {

                    })
            }
        },
        mounted() {
            if(this.initialized) return;
            this.initialized = true;
            this.getUnreadCnt(true)
        }
    }
</script>
