var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "page-main" }, [
    _c("div", { domProps: { innerHTML: _vm._s(_vm.html) } }),
    _vm._v(" "),
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "uk-text-center" },
          [
            _c("text-skeleton", { attrs: { width: "60px", height: "8px" } }),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "100%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "90%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "100%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "40%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "100%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "80%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "100%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "100%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "40%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "100%", height: "4px" },
            }),
            _vm._v(" "),
            _c("text-skeleton", {
              style: { marginTop: _vm.marginTop },
              attrs: { width: "10%", height: "4px" },
            }),
            _vm._v(" "),
            _c("br"),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }