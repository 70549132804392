var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "v-user-item" }, [
    _c(
      "div",
      {
        staticClass: "uk-flex uk-flex-middle uk-grid-5",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "v-user-image-container" }, [
            _c("img", {
              attrs: { src: _vm._f("attach")(_vm.item.photos.avatar, "xs") },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand" }, [
          _c(
            "div",
            { staticClass: "v-user-fullname f-s-085" },
            [
              _c(
                "router-link",
                {
                  staticClass: "a-with-png-ico",
                  attrs: { to: "/" + _vm.item.username },
                },
                [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.item.fullname) +
                      "\n                    "
                  ),
                  _vm.item.is.verified
                    ? _c("span", { staticClass: "png-ico verified small" })
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "v-user-username f-s-075" }, [
            _vm._v(
              "\n                @" +
                _vm._s(_vm.item.username) +
                "\n            "
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-auto" }, [
          _vm.auth.id !== undefined && _vm.auth.id !== _vm.item.id
            ? _c("div", { staticClass: "v-user-actions" }, [
                !_vm.item.is.following
                  ? _c(
                      "button",
                      {
                        staticClass: "app-button f-s-080",
                        on: {
                          click: function ($event) {
                            return _vm.$parent.follow(_vm.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          _vm._s(_vm._f("lang")("follow")) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.is.following
                  ? _c(
                      "button",
                      {
                        staticClass: "app-button active f-s-080",
                        on: {
                          click: function ($event) {
                            return _vm.$parent.follow(_vm.item)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(_vm._f("lang")("unfollow")) +
                            "\n                "
                        ),
                      ]
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }