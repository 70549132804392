var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dy-placeholder-parent", class: { set: _vm.set } },
    [
      _c("div", {
        staticClass: "dy-canvas-placeholer",
        class: { loaded: _vm.loaded },
        style: {
          borderRadius: _vm.radius,
          width: _vm.width,
          height: _vm.height,
          paddingBottom: _vm.height == "0" ? "100%" : "0",
        },
      }),
      _vm._v(" "),
      _c("img", {
        staticClass: "dy-image-with-placeholder",
        style: { borderRadius: _vm.radius, width: _vm.width },
        attrs: { src: _vm.dataSrc },
        on: {
          load: function ($event) {
            _vm.loaded = true
          },
        },
      }),
      _vm._v(" "),
      _vm.backgroundColor != null
        ? _c("div", {
            staticClass:
              "dy-image-with-placeholder dy-overly uk-animation-fade uk-animation-fast",
            style: { backgroundColor: _vm.backgroundColor },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.loaded
        ? _c("div", {
            staticClass:
              "dy-image-with-placeholder dy-overly uk-animation-fade uk-animation-fast",
            class: { loaded: _vm.loaded },
            style: {
              backgroundImage: "url(" + _vm.dataSrc + ")",
              borderRadius: _vm.radius,
            },
          })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }