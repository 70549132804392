var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "switchTabs",
      staticClass: "switch-tabs",
      class: {
        vertical: _vm.vertical,
        bordered: _vm.activeMode === "bordered",
      },
    },
    [
      _vm._l(_vm.tabs, function (tab, index) {
        return _vm.disabledTabs.indexOf(tab) === -1
          ? _c(
              "div",
              {
                directives: [
                  {
                    name: "resize",
                    rawName: "v-resize",
                    value: _vm.onResize,
                    expression: "onResize",
                  },
                  {
                    name: "key",
                    rawName: "v-key",
                    value: index,
                    expression: "index",
                  },
                ],
                ref: "switchTab",
                refInFor: true,
                staticClass: "switch-tab",
                class: { active: _vm.currentTab === index },
                style: { padding: _vm.padding },
                on: {
                  click: function ($event) {
                    return _vm.setTab(index)
                  },
                },
              },
              [
                _vm.icons[index] != undefined
                  ? _c("i", { class: _vm.icons[index] })
                  : _vm._e(),
                _c(
                  "span",
                  {
                    class: {
                      "with-icon": _vm.icons[index] != undefined,
                      "mobile-hidden": _vm.hideTextInMobile,
                    },
                  },
                  [_vm._v(_vm._s(tab))]
                ),
              ]
            )
          : _vm._e()
      }),
      _vm._v(" "),
      _c("div", { ref: "bg", staticClass: "switch-tab-background" }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }