var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "mainContainer", staticClass: "widget padding-x" }, [
    _c("div", [
      _c("div", { staticClass: "widget-title" }, [
        _c(
          "div",
          { staticClass: "uk-flex uk-flex-middle", attrs: { "uk-grid": "" } },
          [
            _c("div", { staticClass: "uk-width-expand" }, [
              _c("h4", { staticClass: "f-s-13" }, [
                _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
              ]),
              _vm._v(" "),
              _c("h6", { staticClass: "f-s-090" }, [
                _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-width-auto" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "see-all-right",
                    attrs: { to: _vm.seeAllUrl },
                  },
                  [_vm._v("See all")]
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "widget-content sound-slider-widget" }, [
        _c(
          "div",
          { staticClass: "sound-slider" },
          [
            _c(
              "flicking",
              {
                attrs: {
                  options: {
                    renderOnlyVisible: false,
                    align: "prev",
                    preventClickOnDrag: true,
                    bound: true,
                    circularFallback: "bound",
                  },
                },
              },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "flicking-panel" },
                  [
                    _c("SoundSliderItem", {
                      class: {
                        active:
                          item.realId != undefined &&
                          (_vm.activeItem === item.realId ||
                            (item.postType === "SND" &&
                              _vm.currentTrack != null &&
                              _vm.currentTrack == item.realId) ||
                            (item.postType === "VID" &&
                              _vm.currentVideo != null &&
                              _vm.currentVideo == item.realId)),
                        deleting: _vm.deletings.indexOf(item.id) !== -1,
                      },
                      attrs: { item: item, options: { showType: true } },
                      on: { dop: _vm.dop },
                    }),
                  ],
                  1
                )
              }),
              0
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }