<template>
    <div class="widget padding-x livestats-side" v-if="show">
        <div class="widget-title" v-if="options!=undefined && options.title!=undefined">
            <h4 v-if="options.title!=null">{{options.title | lang}}</h4>
            <h6 v-if="options.subtitle!=null">{{options.subtitle | lang}}</h6>
        </div>
        <div class="widget-content">
            <div class="live-listeners uk-text-center">
                <div class="f-s-25">
                    <span v-if="auth.is != undefined && auth.is.pro">{{liveStats.liveListeners}}</span>
                    <span v-if="auth.is===undefined || !auth.is.pro"><i class="fal fa-ban"></i></span>
                </div>
                <div class="f-s-1">Live Listeners</div>
                <div class="f-s-085">Available for pro users only</div>
            </div>
            <hr/>
            <div class="estimated-stream-revenue">

                <div class="stats-box">
                    <div class="uk-text-center f-s-075 stats-box-subtitle">
                        Past 7 days stream revenue
                    </div>
                    <div>
                        <estimated-stream-revenue
                                :stream-chart="liveStats.revenues.streamChart"></estimated-stream-revenue>
                    </div>
                </div>
                <div class="uk-grid uk-grid-5 uk-margin-small-top f-s-090">
                    <div class="uk-width-1-2">
                        <div class="stats-box uk-text-center f-f-reg">
                            <div v-if="liveStats.revenues.stream!=undefined">
                                ${{liveStats.revenues.stream.week || 0}} <br> <small>Week</small>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-2">
                        <div class="stats-box uk-text-center f-f-reg">
                            <div v-if="liveStats.revenues.stream!=undefined">
                                ${{liveStats.revenues.stream.month || 0}} <br> <small>Month</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="posts-and-profile-views">
                <div class="f-s-085 live-stats-title">Posts & Profile Views</div>
                <div class="uk-grid uk-grid-5 uk-text-center f-f-reg f-s-090 uk-margin-small-top">
                    <div class="uk-width-1-3">
                        <div class="stats-box">
                            <div>
                                {{liveStats.views.today | numformat}} <br> <small>Today</small>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-3">
                        <div class="stats-box">
                            <div>
                                {{liveStats.views.week | numformat}} <br> <small>Week</small>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-3">
                        <div class="stats-box">
                            <div>
                                {{liveStats.views.total | numformat}} <br> <small>Total</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="sales-revenue">
                <div class="f-s-085 live-stats-title uk-flex uk-flex-middle">
                    <div class="flex-left">
                        Sales Revenue
                    </div>
                    <div class="flex-right f-s-070">
                        <SwitchTab @setTab="tabChanged" padding="2px 5px" :current-tab="currencyIndex"
                                   :tabs="currencies"></SwitchTab>
                    </div>
                </div>
                <div class="uk-grid uk-grid-5 f-f-reg f-s-090 uk-text-center uk-margin-small-top" v-if="liveStats.revenues.sale">
                    <div class="uk-width-1-3">
                        <div class="stats-box">
                            <div>
                                {{currency==='dollars' ? '$' : ''}}{{liveStats.revenues.sale.today[currency] |
                                numformat}}{{currency==='dollars' ? '' : ' T'}} <br> <small>Today</small>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-3">
                        <div class="stats-box">
                            <div>
                                {{currency==='dollars' ? '$' : ''}}{{liveStats.revenues.sale.week[currency] |
                                numformat}}{{currency==='dollars' ? '' : ' T'}} <br> <small>Week</small>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-3">
                        <div class="stats-box">
                            <div>
                                {{currency==='dollars' ? '$' : ''}}{{liveStats.revenues.sale.total[currency] |
                                numformat}}{{currency==='dollars' ? '' : ' T'}} <br> <small>Total</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr/>
            <div class="popular-locations">
                <div class="stats-box">
                    <div class="uk-text-center f-s-075 stats-box-subtitle">
                        Popular Locations
                    </div>
                    <popular-locations :data="liveStats.popularLocations"></popular-locations>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
    import EstimatedStreamRevenue from "@/components/Charts/EstimatedStreamRevenue";
    import SwitchTab from "@/components/SwitchTab";
    import PopularLocations from "@/components/Charts/PopularLocations";

    export default {
        props: ['options'],
        computed: {
            isAuth() {
                return this.$store.getters.isAuthenticated
            },
            auth() {
                return this.$store.getters.getProfile
            },
            authStatus() {
                return this.$store.getters.getProfileStatus
            },
            realPostsCnt() {
                if(this.auth.username!=undefined) {
                    return this.auth.stats.posts - this.auth.stats.reposts
                }
                return 0
            },
            show() {
                return true;
                if(!this.isAuth) return false
                return !!this.realPostsCnt
            }
        },
        components: {
            PopularLocations,
            EstimatedStreamRevenue, SwitchTab
        },
        data() {
            return {
                liveStats: {
                    liveListeners: 0,
                    revenues: {
                        streamChart: []
                    },
                    views: {},
                    popularLocations: [],
                    promotes: [],
                    sales: []
                },
                currencies: ['Dollars', 'Tomans'],
                currencyIndex: 0,
                currency: 'dollars'
            }
        },
        mounted() {
            if (this.auth.username != undefined) {
                this.getData()
            }
            if(!this.isAuth) {
                this.doPlayer()
            }
        },
        watch: {
            auth(auth) {
                if (auth.username != undefined) {
                    this.getData()
                }
            },
            authStatus(authStatus) {
                if(['loading', 'success'].indexOf(authStatus)===-1) {
                    this.doPlayer()
                } else if(authStatus==='success' && this.realPostsCnt<1) {
                    this.doPlayer()
                }
            },
            show: {
                handler: function(show) {
                    localStorage.setItem('live_stats_available', show)
                },
                deep: true
            }
        },
        methods: {
            doPlayer() {
                localStorage.setItem('autoInitializePlayer', Math.random()*1000);
                bus.$emit('autoInitializePlayer', 1)
            },
            tabChanged(e) {
                this.currencyIndex = e
                this.currency = this.currencies[e].toLowerCase()
            },
            getData() {
                axios.get(API.liveStats)
                    .then((resp) => {
                        this.liveStats = resp.data
                        setTimeout(() => {
                            this.getData()
                        }, 5000)
                    })
                    .catch((err) => {

                    })
            }
        }
    }
</script>