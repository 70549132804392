var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.uploadStarted,
              expression: "!uploadStarted",
            },
          ],
        },
        [
          _c(
            "div",
            {
              ref: "uppy",
              staticClass:
                "drag-and-drop uk-flex uk-flex-middle uk-text-center",
              attrs: { id: "UppyDragDrop" },
            },
            [
              _c(
                "div",
                { staticClass: "uk-flex uk-flex-column margin-0-auto" },
                [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "upload-buttons uk-margin-top",
                      on: { mousemove: _vm.onMouseMove },
                    },
                    [
                      _c(
                        "div",
                        {
                          ref: "uploadBtns",
                          staticClass:
                            "upload-buttons-container uk-flex uk-flex-middle",
                        },
                        [
                          _c(
                            "button",
                            {
                              ref: "selectFileBtn",
                              staticClass: "f-s-15 f-s-1@xs btn-a",
                              class: { active: _vm.activeBtn === "a" },
                              on: { click: _vm.selectFile },
                            },
                            [
                              _vm._v(
                                "\n              Select Files\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("span", { ref: "or" }, [_vm._v("Or")]),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              ref: "uploadFromUrlBtn",
                              staticClass: "f-s-15 f-s-1@xs btn-b",
                              class: { active: _vm.activeBtn === "b" },
                              on: {
                                click: function ($event) {
                                  _vm.uploadFromUrl.show = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              Upload From URL\n            "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", {
                            staticClass: "upload-button-background",
                            style: _vm.uploadBtnBackgroundStyle,
                          }),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm._m(2),
                ]
              ),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.uploadStarted
        ? _c(
            "div",
            { staticClass: "padding-x-15" },
            [
              _c("my-upload", {
                ref: "imgUpload",
                attrs: {
                  field: "img",
                  url: _vm.imgUpload.url,
                  height: 600,
                  width: 600,
                  fullsize: 1200,
                  langType: "en",
                  params: _vm.imgUpload.params,
                  headers: _vm.imgUpload.headers,
                  "no-circle": "",
                  "img-format": "jpg",
                },
                on: {
                  "crop-success": function ($event) {
                    return _vm.cropSuccess($event)
                  },
                  "crop-upload-success": function ($event) {
                    return _vm.cropUploadSuccess($event)
                  },
                  "crop-upload-fail": function ($event) {
                    return _vm.cropUploadFail($event)
                  },
                },
                model: {
                  value: _vm.imgUpload.show,
                  callback: function ($$v) {
                    _vm.$set(_vm.imgUpload, "show", $$v)
                  },
                  expression: "imgUpload.show",
                },
              }),
              _vm._v(" "),
              _vm._l(_vm.files, function (file, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "uploading-file" },
                  [
                    _c("div", { staticClass: "file-progress-bar" }, [
                      _vm.fileDatas[index] != undefined &&
                      _vm.fileDatas[index].error === true
                        ? _c("div", {
                            staticClass: "file-progress-bar-val err",
                            staticStyle: { width: "100%" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.fileDatas[index] != undefined &&
                      _vm.fileDatas[index].error === false
                        ? _c("div", {
                            staticClass: "file-progress-bar-val",
                            style: {
                              width:
                                (file.uploadedSize / file.size) * 100 + "%",
                            },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "file-ellapsed",
                        on: {
                          click: function ($event) {
                            return _vm.collapseToggle(index, $event)
                          },
                        },
                      },
                      [
                        _c("div", [
                          _vm._v(
                            "\n          " +
                              _vm._s(file.fileName) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "flex-right" }, [
                          _vm.saving !== index &&
                          _vm.fileDatas[index].saved === false
                            ? _c("i", {
                                staticClass: "fas fa-trash-alt",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeFile(index)
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.fileDatas[index] != undefined &&
                          _vm.fileDatas[index].error === false &&
                          _vm.fileDatas[index].saved === false
                            ? _c("i", { staticClass: "fas fa-chevron-down" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.fileDatas[index].saved
                            ? _c("i", { staticClass: "fas fa-check" })
                            : _vm._e(),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.fileDatas[index] != undefined
                      ? _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.fileDatas[index].collapsed,
                                expression: "fileDatas[index].collapsed",
                              },
                            ],
                            staticClass: "file-collapsed submit-post",
                          },
                          [
                            index === _vm.saving
                              ? _c("div", { staticClass: "processing" }, [
                                  _vm._m(3, true),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "uk-flex uk-flex-middle",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "uk-width-1-1 uk-width-1-4@m",
                                  },
                                  [
                                    _c("div", { staticClass: "post-cover" }, [
                                      _c("img", {
                                        attrs: {
                                          src:
                                            _vm.fileDatas[index].post
                                              .base64Cover !== null
                                              ? _vm.fileDatas[index].post
                                                  .base64Cover
                                              : _vm.defaultImage,
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "post-cover-container" },
                                        [
                                          _vm.fileDatas[index].post
                                            .base64Cover !== null
                                            ? _c(
                                                "a",
                                                {
                                                  staticClass:
                                                    "uk-button uk-button-default button-top-menu button-bg",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.toggleCoverUploader(
                                                        index,
                                                        true
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass: "fal fa-pen",
                                                  }),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "uk-button uk-button-default button-top-menu button-bg",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleCoverUploader(
                                                    index,
                                                    false
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fal fa-cloud-upload",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass: "uk-width-1-1 uk-width-3-4@m",
                                  },
                                  [
                                    _c("div", { staticClass: "uk-margin" }, [
                                      _c("label", { staticClass: "label" }, [
                                        _vm._v(" Title "),
                                      ]),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.fileDatas[index].post.title,
                                            expression:
                                              "fileDatas[index].post.title",
                                          },
                                        ],
                                        staticClass:
                                          "uk-input uk-form-width-medium uk-form-small title",
                                        attrs: {
                                          type: "text",
                                          placeholder: "Title",
                                        },
                                        domProps: {
                                          value:
                                            _vm.fileDatas[index].post.title,
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.onKeyUpTitle(index)
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.fileDatas[index].post,
                                              "title",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "uk-grid-none uk-flex uk-flex-middle uk-margin-small-top f-s-080",
                                          attrs: { "uk-grid": "" },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "uk-width-auto" },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(_vm.host) +
                                                  "/" +
                                                  _vm._s(_vm.auth.username) +
                                                  "/\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "uk-width-expand" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "uk-position-relative",
                                                },
                                                [
                                                  _c("input", {
                                                    directives: [
                                                      {
                                                        name: "model",
                                                        rawName: "v-model",
                                                        value:
                                                          _vm.fileDatas[index]
                                                            .post.slug,
                                                        expression:
                                                          "fileDatas[index].post.slug",
                                                      },
                                                    ],
                                                    staticClass: "slug-input",
                                                    attrs: {
                                                      type: "text",
                                                      readonly:
                                                        _vm.readOnlySlug,
                                                      placeholder: "Title",
                                                    },
                                                    domProps: {
                                                      value:
                                                        _vm.fileDatas[index]
                                                          .post.slug,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.onKeyUpTitle(
                                                          index,
                                                          1
                                                        )
                                                      },
                                                      input: function ($event) {
                                                        if (
                                                          $event.target
                                                            .composing
                                                        )
                                                          return
                                                        _vm.$set(
                                                          _vm.fileDatas[index]
                                                            .post,
                                                          "slug",
                                                          $event.target.value
                                                        )
                                                      },
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _vm.readOnlySlug
                                                    ? _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "slug-loading",
                                                        },
                                                        [
                                                          _c("div", {
                                                            attrs: {
                                                              "uk-spinner": "",
                                                            },
                                                          }),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "uk-margin" }, [
                                      _c("div", { attrs: { "uk-grid": "" } }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "uk-width-1-1 uk-width-1-2@m",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "selectpage-label",
                                              },
                                              [_vm._v("Post Languages *")]
                                            ),
                                            _vm._v(" "),
                                            _vm.postLanguagesArr.length
                                              ? _c("v-selectpage", {
                                                  ref: "languages",
                                                  refInFor: true,
                                                  attrs: {
                                                    data: _vm.postLanguagesArr,
                                                    multiple: true,
                                                    language: "en",
                                                    placeholder:
                                                      "Select language",
                                                    title: "Languages",
                                                    "max-select-limit": 3,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .languages,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "languages",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.languages",
                                                  },
                                                })
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "uk-width-1-1 uk-width-1-2@m",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "selectpage-label",
                                              },
                                              [_vm._v(" Record Labels ")]
                                            ),
                                            _vm._v(" "),
                                            _c("v-selectpage", {
                                              attrs: {
                                                data: " ",
                                                "result-format":
                                                  _vm.typedValueResultFormat,
                                                params: {
                                                  type: "recordLabels",
                                                },
                                                multiple: true,
                                                language: "en",
                                                placeholder:
                                                  "Select Record Labels",
                                                title: "Record Labels",
                                                "max-select-limit": 2,
                                              },
                                              model: {
                                                value:
                                                  _vm.fileDatas[index].post
                                                    .recordLabels,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.fileDatas[index].post,
                                                    "recordLabels",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "fileDatas[index].post.recordLabels",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "uk-margin" },
                                      [
                                        _c(
                                          "label",
                                          { staticClass: "selectpage-label" },
                                          [_vm._v(" Hashtags ")]
                                        ),
                                        _vm._v(" "),
                                        _c("v-selectpage", {
                                          attrs: {
                                            data: " ",
                                            "result-format":
                                              _vm.typedValueResultFormat,
                                            params: { type: "tags" },
                                            multiple: true,
                                            language: "en",
                                            "search-field": "name",
                                            "show-field": _vm.renderHashtagsRow,
                                            placeholder: "Select hashtags",
                                            title: "Hashtags",
                                            "max-select-limit": 10,
                                          },
                                          model: {
                                            value:
                                              _vm.fileDatas[index].post
                                                .hashtags,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.fileDatas[index].post,
                                                "hashtags",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "fileDatas[index].post.hashtags",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "uk-margin" }, [
                                      _c(
                                        "div",
                                        { staticClass: "uk-width-1-1" },
                                        [
                                          _c(
                                            "label",
                                            { staticClass: "selectpage-label" },
                                            [_vm._v(" Genres * ")]
                                          ),
                                          _vm._v(" "),
                                          _c("v-selectpage", {
                                            attrs: {
                                              data: " ",
                                              "result-format":
                                                _vm.tagsResultFormat,
                                              params: { type: "genres" },
                                              multiple: true,
                                              language: "en",
                                              placeholder: "Select genres",
                                              title: "Genres",
                                              "max-select-limit": 3,
                                            },
                                            model: {
                                              value:
                                                _vm.fileDatas[index].post
                                                  .genres,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.fileDatas[index].post,
                                                  "genres",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "fileDatas[index].post.genres",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.fileDatas[index].post.postType === "VID"
                              ? _c("screenshot-scrapper", {
                                  attrs: {
                                    "upload-complete":
                                      file.uploadedSize === file.size,
                                    file: file.file,
                                    filename: file.fileName,
                                  },
                                  on: {
                                    pass: _vm.setCover,
                                    screenshots: function ($event) {
                                      return _vm.setScreenshots($event, index)
                                    },
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "ul",
                              {
                                attrs: { "uk-accordion": "collapsible: true" },
                              },
                              [
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "uk-accordion-title",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickUkTab(
                                            $event,
                                            index,
                                            0
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Description & Lyrics")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "uk-accordion-content" },
                                    [
                                      _c("div", { staticClass: "uk-margin" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "big-placeholder-container",
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                attrs: { for: "dDescription" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("lang")(
                                                        "description_lable"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.fileDatas[index].post
                                                      .description,
                                                  expression:
                                                    "fileDatas[index].post.description",
                                                },
                                              ],
                                              staticClass:
                                                "uk-textarea uk-form-small uk-text-center",
                                              attrs: {
                                                rows: "4",
                                                id: "dDescription",
                                                placeholder: _vm._f("lang")(
                                                  "description_placeholder"
                                                ),
                                              },
                                              domProps: {
                                                value:
                                                  _vm.fileDatas[index].post
                                                    .description,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.fileDatas[index].post,
                                                    "description",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "uk-margin" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "big-placeholder-container",
                                          },
                                          [
                                            _c(
                                              "label",
                                              { attrs: { for: "tLyrics" } },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm._f("lang")(
                                                        "lyrics_lable"
                                                      )
                                                    ) +
                                                    "\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c("textarea", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.fileDatas[index].post
                                                      .lyrics,
                                                  expression:
                                                    "fileDatas[index].post.lyrics",
                                                },
                                              ],
                                              staticClass:
                                                "uk-textarea uk-form-small uk-text-center",
                                              attrs: {
                                                rows: "10",
                                                id: "tLyrics",
                                                placeholder:
                                                  _vm._f("lang")(
                                                    "lyrics_placeholder"
                                                  ),
                                              },
                                              domProps: {
                                                value:
                                                  _vm.fileDatas[index].post
                                                    .lyrics,
                                              },
                                              on: {
                                                input: function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    _vm.fileDatas[index].post,
                                                    "lyrics",
                                                    $event.target.value
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("li", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "uk-accordion-title",
                                      attrs: { href: "#" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clickUkTab(
                                            $event,
                                            index,
                                            1
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("Privacy & Metadata")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "uk-accordion-content" },
                                    [
                                      _c("div", { staticClass: "uk-margin" }, [
                                        _c(
                                          "div",
                                          { attrs: { "uk-grid": "" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-3@m",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("Privacy Settings"),
                                                ]),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    searchable: false,
                                                    reduce: (pub) => pub.value,
                                                    options: [
                                                      {
                                                        label: "Public",
                                                        value: 1,
                                                      },
                                                      {
                                                        label: "Private",
                                                        value: 0,
                                                      },
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .public,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "public",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.public",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-3@m",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("Download"),
                                                ]),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    searchable: false,
                                                    reduce: (download) =>
                                                      download.value,
                                                    options: [
                                                      {
                                                        label: "Allow",
                                                        value: 1,
                                                      },
                                                      {
                                                        label: "Not Allow",
                                                        value: 0,
                                                      },
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .download,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "download",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.download",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-3@m",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("Comments"),
                                                ]),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    searchable: false,
                                                    reduce: (comment) =>
                                                      comment.value,
                                                    options: [
                                                      {
                                                        label: "Allow",
                                                        value: 1,
                                                      },
                                                      {
                                                        label: "Not Allow",
                                                        value: 0,
                                                      },
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .comment,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "comment",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.comment",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "uk-margin" }, [
                                        _c(
                                          "div",
                                          { attrs: { "uk-grid": "" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-3@m",
                                              },
                                              [
                                                _c("label", [_vm._v("Type")]),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    searchable: false,
                                                    reduce: (releaseType) =>
                                                      releaseType.value,
                                                    options:
                                                      _vm.releaseTypesArr,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .releaseTypeId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "releaseTypeId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.releaseTypeId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-3@m",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("Explicit 🅴"),
                                                ]),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    searchable: false,
                                                    reduce: (explicit) =>
                                                      explicit.value,
                                                    options: [
                                                      {
                                                        label: "Yes",
                                                        value: "1",
                                                      },
                                                      {
                                                        label: "No",
                                                        value: "0",
                                                      },
                                                    ],
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .explicit,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "explicit",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.explicit",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-3@m",
                                              },
                                              [
                                                _c("label", [
                                                  _vm._v("License"),
                                                ]),
                                                _vm._v(" "),
                                                _c("v-select", {
                                                  attrs: {
                                                    clearable: false,
                                                    searchable: false,
                                                    reduce: (licenseType) =>
                                                      licenseType.value,
                                                    options:
                                                      _vm.licenseTypesArr,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .licenseTypeId,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "licenseTypeId",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.licenseTypeId",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "uk-margin" }, [
                                        _c(
                                          "div",
                                          { attrs: { "uk-grid": "" } },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-2@m select-teammates-container",
                                              },
                                              [
                                                _c("label", [_vm._v(" ")]),
                                                _vm._v(" "),
                                                _c("v-selectpage", {
                                                  attrs: {
                                                    data: " ",
                                                    "result-format":
                                                      _vm.usersResultFormat,
                                                    params: { type: "users" },
                                                    multiple: true,
                                                    language: "en",
                                                    "search-field": "username",
                                                    "show-field":
                                                      _vm.renderUsersRow,
                                                    placeholder:
                                                      "Select Teammates",
                                                    title: "Users",
                                                    "max-select-limit": 5,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .users,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "users",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.users",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                      You can use ‘teammate’ to add other artists who have\n                      collabrated with you to your post.\n                    "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "uk-width-1-1 uk-width-1-2@m",
                                              },
                                              [
                                                _c("label", [_vm._v(" ")]),
                                                _vm._v(" "),
                                                _c("VueCtkDateTimePicker", {
                                                  attrs: {
                                                    locale: "en",
                                                    dark: true,
                                                    format: "YYYY-MM-DD HH:mm",
                                                  },
                                                  on: {
                                                    change:
                                                      _vm.onChangeReleaseDate,
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.fileDatas[index].post
                                                        .releaseDate,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.fileDatas[index]
                                                          .post,
                                                        "releaseDate",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "fileDatas[index].post.releaseDate",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("small", [
                                                  _vm._v(
                                                    "\n                      If you select time from future this post will be\n                      scheduled and will get automatically posted at that\n                      time.\n                    "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "modal",
        {
          on: { close: _vm.closeUploadFromUrl },
          model: {
            value: _vm.uploadFromUrl.show,
            callback: function ($$v) {
              _vm.$set(_vm.uploadFromUrl, "show", $$v)
            },
            expression: "uploadFromUrl.show",
          },
        },
        [
          _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
            _vm._v("Upload From URL"),
          ]),
          _vm._v(" "),
          _c("div", { attrs: { slot: "body" }, slot: "body" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.uploadFromUrl.url,
                  expression: "uploadFromUrl.url",
                },
              ],
              staticClass: "uk-input",
              attrs: {
                type: "text",
                disabled: _vm.uploadFromUrl.inProcess,
                placeholder: "Enter URL",
              },
              domProps: { value: _vm.uploadFromUrl.url },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.uploadFromUrl, "url", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "f-s-080 uk-margin-small-top" }, [
              _vm._v(
                "\n        You can upload your sounds or videos to your account on vowave by\n        entering their direct download URL."
              ),
              _c("br"),
              _vm._v(
                "\n        Maximum Upload Size: " +
                  _vm._s(_vm.settings && _vm.settings.maxUploadSize) +
                  "MB"
              ),
              _c("br"),
              _vm._v(
                "\n        Allowed Formats:\n        " +
                  _vm._s(
                    !!(_vm.settings && _vm.settings.allowedExtensions)
                      ? _vm.settings.allowedExtensions.join(", ")
                      : ""
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "display-flex",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("PButton", {
                staticClass: "flex-right",
                attrs: {
                  "in-process": _vm.uploadFromUrl.inProcess,
                  text: "Upload",
                },
                on: { clicked: _vm.remoteUpload },
              }),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("i", { staticClass: "fas fa-cloud-upload upload-icon" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "f-f-reg uk-margin-bottom" }, [
      _vm._v("\n          Select sound or video file to upload\n          "),
      _c("br"),
      _vm._v("\n          or drag and drop a file\n        "),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "uk-text-center f-s-080 f-f-reg uk-margin-top uk-position-relative upload-nt",
      },
      [
        _vm._v(
          "\n          By submitting your sound or videos to vowave, you agree to our\n          "
        ),
        _c("a", { attrs: { target: "_blank", href: "/terms" } }, [
          _vm._v("Terms of Service"),
        ]),
        _vm._v("."),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n          Please be sure not to violate other's copyright or privacy\n          rights."
        ),
        _c("br"),
        _vm._v(
          "\n          By uploading other artists original sound or videos you will\n          immediately get blocked from using our applications."
        ),
        _c("br"),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [_c("i", { attrs: { "uk-spinner": "ratio: 3" } })])
  },
]
render._withStripped = true

export { render, staticRenderFns }