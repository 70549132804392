var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [
        _vm.options.subclass,
        {
          "mobile-hidden":
            _vm.options.mobileHidden != undefined &&
            _vm.index > _vm.options.mobileHidden,
        },
      ],
    },
    [
      _c("div", { staticClass: "hb-post-item" }, [
        _vm.item.id === undefined
          ? _c("div", { staticClass: "hb-post-item-cover" }, [
              _c(
                "div",
                { staticClass: "hb-post-item-cover-inner" },
                [_c("ImagePlaceholder", { attrs: { radius: "0" } })],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.id !== undefined
          ? _c("div", { staticClass: "hb-post-item-cover" }, [
              _c(
                "div",
                { staticClass: "hb-post-item-cover-inner" },
                [
                  _c("ImagePlaceholder", {
                    attrs: {
                      radius: "0",
                      "data-src": _vm._f("attach")(_vm.item.metas.cover, "m"),
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "hb-post-item-actions" }, [
                    _c("div", { staticClass: "uk-float-left" }, [
                      _c("span", { staticClass: "hb-post-item-action-play" }, [
                        _c("i", {
                          staticClass: "fas fa-play",
                          on: {
                            click: function ($event) {
                              return _vm.play(_vm.item)
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-float-right" }, [
                      _c(
                        "span",
                        {
                          staticClass: "hb-post-item-action-ellipsis",
                          on: {
                            click: function ($event) {
                              return _vm.$parent.moreMenu(_vm.item, $event)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-ellipsis-h" })]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _vm.item.postType === "ALB" || _vm.item.postType === "PLY"
                    ? _c("div", { staticClass: "hb-post-center" }, [
                        _vm.item.postType === "PLY"
                          ? _c("div", [
                              _c("div", { staticClass: "png-ico duration" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "f-s-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("minutes")(_vm.item.tracksDuration)
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _c("div", { staticClass: "f-s-075 tracks-cnt" }, [
                          _vm.item.postType === "ALB"
                            ? _c("div", { staticClass: "png-ico album" })
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.item.tracks.length) + " Tracks"),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.item.id !== undefined
          ? _c("div", { staticClass: "hb-post-item-details" }, [
              _c(
                "div",
                { staticClass: "hb-post-item-title f-s-090 uk-text-truncate" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: _vm.helper.permalink(_vm.item) } },
                    [_vm._v(_vm._s(_vm.item.title))]
                  ),
                  _vm._v(" "),
                  _vm.item.metas.explicit
                    ? _c("span", { staticClass: "explicit" }, [_vm._v("🅴")])
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "hb-post-item-artist f-s-080" },
                [
                  _c(
                    "span",
                    { staticClass: "artist" },
                    [
                      _c(
                        "router-link",
                        { attrs: { to: _vm.item.user.username } },
                        [_vm._v(_vm._s(_vm.item.user.fullname))]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.item.users, function (teammate) {
                    return _c(
                      "span",
                      { staticClass: "teammate" },
                      [
                        _vm._v(",\n          "),
                        _c(
                          "router-link",
                          { attrs: { to: teammate.username } },
                          [_vm._v(_vm._s(teammate.fullname))]
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }