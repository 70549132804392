var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video-player-main" }, [
    _c("div", { staticClass: "uk-grid-none", attrs: { "uk-grid": "" } }, [
      _c("div", { staticClass: "uk-width-3-4@l" }, [
        _c(
          "div",
          {},
          [
            _c("hls", {
              attrs: { item: _vm.video },
              on: {
                "update:item": function ($event) {
                  _vm.video = $event
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-4@l" }, [
        _c("div", { staticClass: "video-related-container" }, [
          _c("div", { staticClass: "video-related-absolute" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "v-post-items" },
              [
                _vm.relatedPosts.length
                  ? _c("items", {
                      staticClass: "v-post-items-container",
                      attrs: { type: "VPostItem", items: _vm.relatedPosts },
                    })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.relatedPosts.length
                  ? _c(
                      "div",
                      { staticClass: "v-post-items-container" },
                      _vm._l(_vm.emptyCount, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "v-post-item empty" },
                          [_vm._m(1, true)]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1" }, [
        _c("div", { staticClass: "video-info-container" }, [
          !_vm.inLoading && _vm.video.user != undefined
            ? _c("div", { staticClass: "video-info-main" }, [
                _c(
                  "div",
                  { staticClass: "video-title f-s-2 uk-text-truncate" },
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to:
                            "/" +
                            _vm.video.user.username +
                            "/video/" +
                            _vm.video.slug,
                        },
                      },
                      [_vm._v(_vm._s(_vm.video.title) + "\n            ")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "artist-name f-s-10 uk-text-truncate" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "artist teammate",
                        attrs: { to: "/" + _vm.video.user.username },
                      },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.video.user.fullname) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.video.users, function (teammate, index) {
                      return _c(
                        "router-link",
                        {
                          key: index,
                          staticClass: "artist teammate",
                          attrs: { to: "/" + teammate.username },
                        },
                        [
                          _vm._v(
                            ", " + _vm._s(teammate.fullname) + "\n            "
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.inLoading
            ? _c("div", { staticClass: "video-info-main" }, [_vm._m(2)])
            : _vm._e(),
          _vm._v(" "),
          _vm.video.is != undefined
            ? _c(
                "div",
                { staticClass: "video-actions uk-flex uk-flex-middle" },
                [
                  _c("div", { staticClass: "f-s-090 video-actions-inner" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn-action btn-trans",
                        on: {
                          click: function ($event) {
                            return _vm.like(_vm.video)
                          },
                        },
                      },
                      [
                        _c("i", {
                          staticClass: "fa-heart",
                          class: {
                            far: !_vm.video.is.liked,
                            "fas active": _vm.video.is.liked,
                          },
                        }),
                        _vm._v(" "),
                        _vm.video.stats.likes === 0
                          ? _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm._f("lang")("like")) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.video.stats.likes != 0
                          ? _c("span", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm._f("numformat")(_vm.video.stats.likes)
                                  ) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-action btn-trans",
                        class: { "active active-gold": _vm.video.is.reposted },
                        on: {
                          click: function ($event) {
                            return _vm.repost(_vm.video)
                          },
                        },
                      },
                      [
                        _c("i", { staticClass: "far fa-retweet" }),
                        _vm._v(
                          "\n              " +
                            _vm._s(
                              _vm._f("numformat")(_vm.video.stats.reposts)
                            ) +
                            "\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-action btn-trans",
                        on: { click: _vm.copyLink },
                      },
                      [
                        _c("i", { staticClass: "far fa-link" }),
                        _vm._v(" "),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("lang")("copy_link"))),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.video.metas.sale && !_vm.video.is.bought
                      ? _c(
                          "button",
                          {
                            staticClass: "btn-action btn-trans",
                            on: { click: _vm.buy },
                          },
                          [
                            _c("i", { staticClass: "far fa-cloud-download" }),
                            _vm._v(" "),
                            _c("span", [_vm._v(_vm._s(_vm._f("lang")("buy")))]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-action btn-trans",
                        on: { click: _vm.moreMenu },
                      },
                      [
                        _c("i", { staticClass: "far fa-ellipsis-h" }),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(_vm._f("lang")("more")))]),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "video-stats f-s-080" }, [
                    _c("div", { staticClass: "info-box" }, [
                      _c("i", { staticClass: "far fa-eye" }),
                      _vm._v(
                        "\n              " +
                          _vm._s(
                            _vm._f("numformat")(
                              _vm.video.stats.views +
                                _vm.video.stats.plays +
                                _vm.video.stats.downloads
                            )
                          ) +
                          "\n            "
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.helper.can("play", _vm.video)
                      ? _c("div", { staticClass: "info-box" }, [
                          _c("i", { staticClass: "far fa-play" }),
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm._f("numformat")(_vm.video.stats.plays)
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.helper.can("download", _vm.video)
                      ? _c("div", { staticClass: "info-box" }, [
                          _c("i", { staticClass: "far fa-cloud-download" }),
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("numformat")(_vm.video.stats.downloads)
                              ) +
                              "\n            "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "info-box" }, [
                      _c("i", { staticClass: "fal fa-clock" }),
                      _vm._v(
                        " " +
                          _vm._s(_vm.video.releaseDateTimeago) +
                          "\n            "
                      ),
                    ]),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "new-comment-container" }, [
            !_vm.disableCm
              ? _c(
                  "div",
                  { staticClass: "uk-grid-5", attrs: { "uk-grid": "" } },
                  [
                    _vm._m(3),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-width-expand" }, [
                      _c("div", { staticClass: "right" }, [
                        _vm.auth.id === undefined
                          ? _c("input", {
                              ref: "comment-txt",
                              staticClass: "new-comment-text f-s-085",
                              attrs: {
                                type: "text",
                                placeholder: _vm._f("lang")(
                                  "comment_placeholder_guest"
                                ),
                              },
                              on: {
                                focus: function ($event) {
                                  _vm.$parent.showLogin = true
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auth.id != undefined && _vm.duration === 0
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.comment,
                                  expression: "comment",
                                },
                              ],
                              ref: "comment-txt",
                              staticClass: "new-comment-text f-s-085",
                              attrs: {
                                type: "text",
                                placeholder: _vm._f("lang")(
                                  "comment_placeholder_user"
                                ),
                              },
                              domProps: { value: _vm.comment },
                              on: {
                                keyup: _vm.keyupComment,
                                focus: function ($event) {
                                  _vm.commentAt = _vm.minutesDur
                                  _vm.commentAtSec = _vm.duration
                                },
                                focusout: function ($event) {
                                  _vm.commentAt = null
                                  _vm.commentAtSec = null
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.comment = $event.target.value
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.auth.id != undefined && _vm.duration > 0
                          ? _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.comment,
                                  expression: "comment",
                                },
                              ],
                              ref: "comment-txt",
                              staticClass: "new-comment-text f-s-085",
                              attrs: {
                                type: "text",
                                placeholder: _vm._f("lang")(
                                  "comment_placeholder_at_time",
                                  {
                                    time:
                                      _vm.commentAt != null
                                        ? _vm.commentAt
                                        : _vm.minutesDur,
                                  }
                                ),
                              },
                              domProps: { value: _vm.comment },
                              on: {
                                keyup: _vm.keyupComment,
                                focus: function ($event) {
                                  _vm.commentAt = _vm.minutesDur
                                  _vm.commentAtSec = _vm.duration
                                },
                                focusout: function ($event) {
                                  _vm.commentAt = null
                                  _vm.commentAtSec = null
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.comment = $event.target.value
                                },
                              },
                            })
                          : _vm._e(),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "uk-width-auto uk-flex uk-flex-middle f-s-1",
                      },
                      [
                        _c(
                          "button",
                          {
                            staticClass: "send-btn",
                            on: { click: _vm.sendComment },
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm._f("lang")("submit")) +
                                "\n              "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "video-related-header" }, [
      _c("h5", { staticClass: "f-s-12" }, [_vm._v("Up Next")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "uk-grid-12 uk-flex uk-flex-middle",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "v-post-item-cover uk-width-auto" }, [
          _c("div", { staticClass: "v-post-item-cover-inner" }, [
            _c("div", { staticClass: "cover" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand" }, [
          _c("div", { staticClass: "line" }),
          _vm._v(" "),
          _c("div", { staticClass: "line" }),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty" }, [
      _c("div", { staticClass: "line" }),
      _vm._v(" "),
      _c("div", { staticClass: "line" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "uk-width-auto" }, [
      _c("div", { staticClass: "left" }, [
        _c("i", { staticClass: "fas fa-quote-right" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }