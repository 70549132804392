var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "file",
      staticClass: "js-upload uk-placeholder uk-text-center",
      on: { click: _vm.clickUpload },
    },
    [
      _c("span", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.files.length,
            expression: "!files.length",
          },
        ],
        attrs: { "uk-icon": "icon: cloud-upload" },
      }),
      _vm._v(" "),
      _c(
        "span",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.files.length,
              expression: "!files.length",
            },
          ],
          staticClass: "uk-text-middle",
        },
        [_vm._v(_vm._s(_vm.text))]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.files.length,
              expression: "!files.length",
            },
          ],
          attrs: { "uk-form-custom": "" },
        },
        [
          _c("input", { ref: "attach", attrs: { name: "img", type: "file" } }),
          _vm._v(" "),
          _c("span", { staticClass: "uk-link" }, [
            _vm._v(_vm._s(_vm.selectText)),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm.files.length
        ? _c(
            "div",
            { staticClass: "uploader-files" },
            [
              _vm._l(_vm.files, function (file, index) {
                return _c("div", { key: index, staticClass: "uploader-file" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(file.name) +
                      " — " +
                      _vm._s(file.size) +
                      " KB\n            "
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "delete",
                      on: {
                        click: function ($event) {
                          return _vm.deleteFile(index)
                        },
                      },
                    },
                    [_c("i", { staticClass: "fa fa-times" })]
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.files.length < 5
                ? _c(
                    "div",
                    {
                      staticClass: "uploader-file",
                      on: {
                        click: function ($event) {
                          return _vm.$refs.attach.click()
                        },
                      },
                    },
                    [_vm._v("\n            Add file\n        ")]
                  )
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? _c("div", { staticClass: "js-uploader-progress" }, [
            _c("div", { staticClass: "uk-text-center" }, [
              _c("i", { attrs: { "uk-spinner": "ratio: 1.5; fill: #fff" } }),
              _vm._v(" "),
              _c("div", { staticClass: "abs-center" }, [
                _vm._v(_vm._s(_vm.percent) + "%"),
              ]),
            ]),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }