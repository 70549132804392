<template>
    <div class="widget padding-x">
        <div class="widget-title" v-if="options!=undefined && options.title!=undefined">
            <h4 v-if="options.title!=null">{{options.title | lang}}</h4>
            <h6 v-if="options.subtitle!=null">{{options.subtitle | lang}}</h6>
        </div>
        <div class="widget-content">
            <div>
                <Items :items="items" type="GridUserItem" :options="{subclass: 'uk-width-1-2@m uk-width-1-3 uk-width-1-4@l uk-width-1-5@xl'}" class="uk-grid uk-grid-20" />
                <div v-if="!end" class="uk-margin-top uk-text-center">
                    <i v-is-visibility="(isVisible) => visibleMore(isVisible)" uk-spinner></i>
                </div>
            </div>
            <Empty v-if="end && !items.length" text="No users found in this section!" icon="fal fa-users"></Empty>
        </div>
    </div>
</template>
<script>
    import Items from "@/components/Items/Items";
    import axios from "axios"
    import Empty from "@/components/Tools/Empty";
    export default {
        props: ['options'],
        components: {
            Empty,
            Items
        },
        data() {
            return {
                inProcess: false,
                request: {},
                lastId: null,
                end: false,
                items: [],
                count: 30,
            }
        },
        methods: {
            visibleMore(visible) {
                if(visible && !this.loading) {
                    this.getData()
                }
            },
            getData() {
                if (window['discoverRequest'] != null)
                    window['discoverRequest'].cancel()
                let CancelToken = axios.CancelToken
                window['discoverRequest'] = CancelToken.source()
                let rp = {
                    count: this.count,
                    skip: this.items.length,
                    orderBy: 'score',
                };
                this.loading = true;
                axios.get(API.users, {
                    params: {...rp, ...this.request},
                    cancelToken: window['discoverRequest'].token
                })
                    .then((resp) => {
                        this.loading = false;
                        if(resp.data.length < this.count) this.end = true;
                        this.items = this.items.concat(resp.data)
                    })
                    .catch(() => {
                        this.loading = false
                    })
            }
        },
        mounted() {
            this.getData();
            bus.$on('discoverFilter', (request) => {
                this.items = [];
                this.end = false;
                this.request = request;
                this.getData()
            })
        }
    }
</script>