<template>
  <!--    <div>-->
  <!--        {{value.recentPlaylists}}-->
  <!--    </div>-->
  <div v-if="items.length || options.url != undefined" class="widget padding-x">
    <div class="widget-title">
      <div class="uk-flex uk-flex-middle" uk-grid>
        <div class="uk-width-expand">
          <h4 class="f-s-13">{{ options.title | lang({artist: value && value.fullname}) }}</h4>
          <h6 class="f-s-090">{{ options.subtitle | lang({artist: value && value.fullname}) }}</h6>
        </div>
        <div class="uk-width-auto">
          <router-link class="see-all-right" :to="seeAllUrl"
            >See all</router-link
          >
        </div>
      </div>
    </div>
    <div class="widget-content">
      <div class="hb-post-items uk-animation-fade uk-animation-10">
        <Items
          :options="{
            subclass: 'uk-width-1-2 uk-width-1-4@m',
            mobileHidden: 3,
          }"
          class="uk-grid-12"
          uk-grid
          :items="items"
          :type="'GridPostItem'"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Items from "../Items/Items";
import axios from "axios";
export default {
  components: { Items },
  props: ["value", "options"],
  data() {
    return {
      items: [],
    };
  },
  methods: {
    parseData() {
      let routeName = this.$route.name;
      if (routeName === "profileArtist") {
        routeName += "_" + this.$route.params.username;
      }
      if (routeName === this.routeKey) {
        return;
      } else {
        this.items = [{}, {}, {}, {}];
        this.coversLoadedStatus = [];
      }
      this.routeKey = routeName;
      if (this.$route.name === "profileArtist") {
        axios
          .get(
            `/posts?count=4&username=${this.$route.params.username}&postType=PLY&noText=true`
          )
          .then((res) => {
            const dt = res.data;
            this.items = dt;
            bus.$emit("profileArtistWidgets", {
              items: this.items.length,
              username: this.$route.params.username,
            });
          });
      }
    },
  },
  computed: {
    seeAllUrl() {
      return "/" + this.$route.params.username + "/playlists";
    },
  },
  watch: {
    $route: function () {
      this.parseData();
    },
  },
  mounted() {
    this.parseData();
  },
  beforeDestroy() {
    bus.$off(
      (this.$route.params.username != undefined
        ? this.$route.params.username
        : "") + "grecentPlaylists"
    );
  },
};
</script>