<template>
    <div class="uk-margin-top">
        <video @error="onErrorVideo" @loadedmetadata="onLoadedMetaData" :width="''+width+''" :height="''+height+''" controls ref="videoplayer" style="display: none"
               >
               <source type="video/mp4">
        </video>
        <canvas style="display: none" ref="videocanvas"></canvas>
        <div class="screenshots">
            <div class="swiper-wrapper">
                <div v-for="(screenshot, index) in screenshots" class="screenshot swiper-slide uk-animation-fade">
                    <img class="screenshot-img" :src="screenshot">
                    <div class="screenshot-icons" v-if="screenshots.length===cnt">
                        <span
                                @click="getScreenshot(index)"
                                v-if="static===undefined"
                                class="video-upload-screenshot-icon">
                            <i class="fal fa-redo-alt"></i>
                        </span>
                        <span
                                @click="passScreenshot(index)"
                                class="video-upload-screenshot-icon">
                            <i class="fal fa-image"></i>
                        </span>
<!--                        <span v-if="ajaxScreenshot" @click="ajaxAttachmentScreenshot(screenshotIndex)"-->
<!--                              class="video-upload-screenshot-icon">-->
<!--                            <font-awesome-icon icon="redo-alt"/>-->
<!--                        </span>-->
<!--                        <span class="video-upload-screenshot-icon">-->
<!--                            <font-awesome-icon icon="upload"/>-->
<!--                            <input type="file"-->
<!--                                   @change="changeScreenshotInput($event, screenshotIndex)"-->
<!--                                   ref="uploadscreenshot"/>-->
<!--                        </span>-->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .screenshots .screenshot {
        width: 250px;
        padding-right: 10px;
    }

    .screenshots .screenshot img {
        border-radius: .6rem;
    }
    .screenshots .screenshot-icons {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
    .screenshots .screenshot-icons i {
        background: #8484845c;
        color: #ffffff;
        padding: 5px;
        border-radius: 50%;
        backdrop-filter: blur(5px);
        cursor: pointer;
    }
    .screenshots .screenshot-icons i:hover {
        opacity: 0.8;
    }
</style>
<script>
    import Swiper from 'swiper'
    import axios from 'axios'
    export default {
        data() {
            return {
                screenshots: [],
                error: false,
                cnt: 20,
                width: 1247,
                height: 700
            }
        },
        props: ['file', 'uploadComplete', 'filename', 'static'],
        methods: {
            staticScreenshots() {
                this.getStaticScreenshot(0)
            },
            getStaticScreenshot(index) {
                let url = this.static;
                let vm = this;
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url + index + '_' + this.width + '.jpg', true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function(e) {
                    if (this.status == 200) {
                        let uInt8Array = new Uint8Array(this.response);
                        let i = uInt8Array.length;
                        let biStr = new Array(i);
                        while (i--) {
                            biStr[i] = String.fromCharCode(uInt8Array[i]);
                        }
                        let data = biStr.join('');
                        let base64 = window.btoa(data);
                        let image = 'data:image/jpeg;base64,' + base64
                        vm.screenshots.push(image)
                        if(index<vm.cnt-1) {
                            vm.getStaticScreenshot(index+1, true)
                        }
                    }
                };
                xhr.send();
            },
            passScreenshot(index) {
                this.$emit('pass', this.screenshots[index])
            },
            initSlider() {
                this.slider = new Swiper('.screenshots', {
                    speed: 200,
                    spaceBetween: 0,
                    slidesPerView: 'auto',
                    init: true
                    // breakpoints: {
                    //     320: {
                    //         slidesPerView: 1,
                    //         spaceBetween: 10
                    //     },
                    //     480: {
                    //         slidesPerView: 1.1,
                    //         spaceBetween: 5
                    //     },
                    //     640: {
                    //         slidesPerView: 2.1,
                    //         spaceBetween: 10
                    //     },
                    //     1200: {
                    //         slidesPerView: 1.1,
                    //         spaceBetween: 10
                    //     },
                    //     2000: {
                    //         slidesPerView: 2.1,
                    //         spaceBetween: 10
                    //     }
                    // }
                })
            },
            init() {
                if(this.file!==undefined && (typeof this.file !='string')) {
                    this.$refs.videoplayer.querySelector('source').setAttribute('src', this.createObjectURL(this.file))
                    this.$refs.videoplayer.load()
                } else if(this.static !== undefined) {
                    this.staticScreenshots()
                } else {
                    this.ajaxAttachmentScreenshot(0, true)
                }
                this.initSlider()
            },
            createObjectURL(i) {
                var URL = window.URL || window.webkitURL || window.mozURL || window.msURL;
                return URL.createObjectURL(i);
            },
            onErrorVideo(e) {
                this.error = true
            },
            onLoadedMetaData(e) {
                let vm = this;
                this.duration = e.srcElement.duration;
                let cnt = this.cnt;
                this.durationStep = Math.floor(this.duration / cnt);
                this.durations = [];
                for (var i = 0; i < cnt; i++) {
                    this.durations.push(this.durationStep / 2 + this.durationStep * i)
                }
                let videoPlayer = this.$refs.videoplayer;
                let canvas = this.$refs.videocanvas;
                canvas.width = this.width;
                canvas.height = this.height;
                let canvasCtx = canvas.getContext("2d");
                let screenshots = [];
                var ii = 0;
                let intVal = setInterval(function () {
                    if (ii >= vm.durations.length - 1) {
                        setTimeout(function () {
                            vm.sreenshotLoadedEnd = true
                        }, 1000);
                        clearInterval(intVal)
                    }
                    videoPlayer.currentTime = parseInt(vm.durations[ii], 10);
                    setTimeout(function () {
                        if (canvas.toDataURL() === 'data:,') {
                            vm.screenshotsAreLoading = true;
                            clearInterval(intVal);
                            return
                        }
                        canvasCtx.drawImage(videoPlayer, 0, 0, vm.width, vm.height);
                        vm.screenshots.push(canvas.toDataURL("image/jpeg"))
                    }, 1000);
                    ii++
                }, 1000)
                // this.screenshots = screenshots
            },
            getScreenshot(oo) {
                if(this.error) {
                    this.ajaxAttachmentScreenshot(oo)
                    return
                }
                if(this.filename!==undefined) {
                    this.ajaxAttachmentScreenshot(oo)
                    return
                }
                let vm = this
                vm.screenshots[oo] = ''
                let videoPlayer = this.$refs.videoplayer
                let canvas = this.$refs.videocanvas
                canvas.width = this.width
                canvas.height = this.height
                let canvasCtx = canvas.getContext("2d")
                let cTime = parseInt((this.durationStep * oo) + (Math.random() * this.durationStep), 10)
                videoPlayer.currentTime = parseInt(cTime, 10)
                setTimeout(function () {
                    if (canvas.toDataURL() === 'data:,') {
                        vm.screenshotsAreLoading = true
                        return
                    }
                    canvasCtx.drawImage(videoPlayer, 0, 0, vm.width, vm.height)
                    vm.screenshots[oo] = canvas.toDataURL("image/jpeg")
                    document.getElementsByClassName('screenshot-img')[oo].setAttribute('src', canvas.toDataURL("image/jpeg"))
                }, 500)
            },
            ajaxAttachmentScreenshot(index, next) {
                let vm = this
                axios.post(API.screenshot.get,{
                    index: index,
                    filename: this.filename
                }, {
                    responseType: 'arraybuffer'
                })
                    .then(function (res) {
                        let image = btoa(
                            new Uint8Array(res.data)
                                .reduce((data, byte) => data + String.fromCharCode(byte), '')
                        )
                        let base64img = `data:${res.headers['content-type'].toLowerCase()};base64,${image}`
                        if(next===undefined) {
                            vm.screenshots[index] = base64img
                            document.getElementsByClassName('screenshot-img')[index].setAttribute('src', base64img)
                        } else {
                            vm.screenshots.push(base64img)
                        }
                        if(next!=undefined && index<7) {
                            vm.ajaxAttachmentScreenshot(index+1, true)
                        }
                    })
                    .catch(() => {
                        setTimeout(() => {
                            vm.ajaxAttachmentScreenshot(index, next)
                        }, 1000)
                    })
            },
        },
        mounted() {
            setTimeout(() => {
                this.init()
            }, 100)
        },
        watch: {
            screenshots(newVal) {
                if (newVal.length === this.cnt) {
                    this.slider.destroy(true, true)
                    setTimeout(() => {
                        this.$emit('screenshots', newVal)
                        this.initSlider()
                    }, 10)
                }
            },
            uploadComplete(newVal) {
                if(newVal && this.error) {
                    this.ajaxAttachmentScreenshot(0, true)
                }
            }
        }

    }
</script>