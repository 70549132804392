<template>
    <SteppedModal v-if="auth.photos!=undefined"
                  ref="steppedModal"
                  :disableClose="disableClose"
                  :steps="steps"
                  :stepsArr="stepsArr"
                  :currentStep="currentStep"
                  :background="background"
                  @close="close"
                  :show="show">
        <div slot="header0">
            <div class="currency-tabs border-bottom">
                <div class="currency-tab f-s-090" @click="setCurrencyTab('dollars')"
                     :class="{active : request.currency==='dollars'}">
                    Charge Balance
                </div>
                <div class="currency-tab f-s-090" @click="setCurrencyTab('tomans')"
                     :class="{active : request.currency==='tomans'}">
                    شارژ حساب کاربری
                </div>
                <div class="currency-tab-border"
                     :style="{transform: 'translateX('+currencyTabBorderTranslate+')'}"></div>
            </div>
        </div>
        <div slot="body0" class="margin-top-0">
            <div class="uk-text-center" :class="{'rtl farsi-font': request.currency==='tomans'}">
                <div class="uk-grid-none" uk-grid>
                    <div v-for="(pack, index) in packages[request.currency]" class="uk-width-1-2 prices-radio">
                        <label v-if="request.currency==='tomans'"><input class="uk-radio" type="radio" :value="index "
                                                                         v-model="selectedPackage"> {{pack | numformat}} تومان</label>
                        <label v-if="request.currency==='dollars'"><input class="uk-radio" type="radio" :value="index"
                                                                          v-model="selectedPackage">
                            ${{pack}} USD</label>
                    </div>
                </div>
                <div class="uk-margin-small-top">
                    <label><input class="uk-radio" type="radio" value="custom" v-model="selectedPackage"> </label>
                    <input class="uk-input uk-form-width-medium uk-text-center" @keyup="keyup" @keydown="keydown"
                           :placeholder="request.currency==='tomans' ? 'مبلغ دلخواه (تومان)' : 'Custom (USD)'"
                           :disabled="selectedPackage!='custom'" v-model="request.amount"/>
                </div>
            </div>
        </div>
        <div slot="body1" :class="{rtl: request.currency==='tomans'}">
            <div v-if="request.status">
                <div class="status success payment">
                    <div>
                        <i class="fal fa-check-circle"></i>
                    </div>
                    <div class="title f-s-12">
                        {{request.currency === 'tomans' ? 'پرداخت با موفقیت !'
                      : 'Congratulations!'
                        }}
                    </div>
                </div>
                <div class="status description farsi-font f-s-090" v-if="request.currency==='tomans'">
                    شما با موفقیت {{amount | numformat}} تومان پرداخت کردید و حساب کاربری شما به صورت اتوماتیک شارژ خواهد شد.<br>
                    در صورتی که سوالی دارید میتوانید از صفحه
                    <router-link to="/contact-us">تماس با ما</router-link>
                    استفاده کنید.
                    <span v-if="request.transactionId!=null"><br>
                    کد پیگیری این تراکنش :
                    {{request.transactionId}}</span>
                </div>
                <div class="status description f-s-090" v-if="request.currency==='dollars'">
                    You paid ${{amount}} successfully and your request will get processed automatically. <br>
                    If you have any questions you can
                    <router-link to="/contact-us">contact us</router-link>
                    .<br>
                    <span v-if="request.transactionId!=null">This Transaction Id :
                        {{request.transactionId}}</span>
                </div>
            </div>
            <div v-if="!request.status">
                <div class="status error payment">
                    <div>
                        <i class="fal fa-exclamation-circle"></i>
                    </div>
                    <div class="title f-s-12">
                        {{request.currency === 'tomans' ? 'خطا در پرداخت !'
                      : 'Something went wrong!'
                        }}
                    </div>
                </div>
                <div class="status description f-s-090" v-if="request.currency==='tomans'">
                    متاسفانه در مراحل پرداخت شما مشکلی به وجود آمد و این عملیات با موفقیت انجام نشد.<br>
                    لطفا دوباره سعی کنید و اگر خطا برطرف نشد از طریق صفحه
                    <router-link to="/contact-us">تماس با ما</router-link>
                    آن را اطلاع دهید.<br>
                    کد پیگیری این تراکنش :
                    {{request.transactionId}}
                </div>
                <div class="status description f-s-090" v-if="request.currency==='dollars'">
                    It seems there was an issue with your payment.<br>
                    Please try again and if the issue still persist
                    <router-link to="/contact-us">contact us</router-link>
                    .<br>
                    This Transaction Id :
                    {{request.transactionId}}
                </div>
            </div>
        </div>
        <div slot="footer" class="uk-text-center">
            <button v-if="currentStep===0" @click="paymentRequest"
                    class="uk-button button-top-menu button-bg uk-button-default" :disabled="request.inProcess">
                <span v-if="!request.inProcess">
                    {{request.currency === 'tomans' ? 'پرداخت با کارت های عضو شتاب' : 'Pay'}}
                </span>
                <span v-if="request.inProcess">
                    <i uk-spinner="ratio: 0.5"></i>
                </span>
            </button>
            <button @click="backToHome()" v-if="currentStep===1 && !request.status" class="uk-button button-top-menu button-bg uk-button-default">
                {{request.currency === 'tomans' ? 'بازگشت' : 'Back'}}
            </button>
        </div>
    </SteppedModal>
</template>
<script>
  import SteppedModal from './SteppedModal'
  import background from '../../assets/img/pay-bg.jpg'
  import axios from 'axios'
  import helper from '../../helpers/helper'
  import { USER_REQUEST } from '../../store/actions/user'
  export default {
    components: {
      SteppedModal
    },
    computed: {
      disableClose() {
        return this.request.inProcess
      },
      amount() {
        return (this.selectedPackage !== 'custom' ? (this.packages[this.request.currency][this.selectedPackage]) : this.request.amount).replace(/\D/g, '')
      },
      auth () {
        return this.$store.getters.getProfile
      },
      defaultCurrency () {
        return this.auth.geo === 'IR' ? 'tomans' : 'dollars'
      }
    },
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        background: background,
        request: {
          currency: 'tomans',
          amount: '',
          realAmount: 0,
          inProcess: false,
          transactionId: null,
          status: false
        },
        currentStep: 0,
        stepsArr: [0, 1],
        currencyTabBorderTranslate: 0,
        selectedPackage: 0,
        packages: {
          dollars: [
            '3', '50', '10', '100', '25', '200'
          ],
          tomans: [
            '10000', '100000', '30000', '150000', '50000', '200000'
          ]
        }
      }
    },
    methods: {
      close () {
        if (this.disableClose) return
        this.$emit('close')
        this.show = false
      },
      sanitizeTomans () {
        let outputArr = []
        let val = this.request.amount
        let amount = val.replace(/\D/g, '')
//        let amount = parseInt(removeChars, 10)
//        console.log('amount', amount, removeChars)
//        amount = removeChars
        let splitted = amount.split('').reverse()
        for (let i = 1; i <= splitted.length; i++) {
          let currentChar = splitted[i - 1]
          outputArr.push(currentChar)
          if (i % 3 === 0 && i != splitted.length) {
            outputArr.push(',')
          }
        }
        let output = outputArr.reverse().join('')
        this.request.amount = output
      },
      sanitizeDollars () {
        let val = this.request.amount
        let amount = val.replace(/\D/g, '')
        this.request.amount = amount
      },
      sanitizeAmount () {
        switch (this.request.currency) {
          case 'tomans':
            this.sanitizeTomans()
            break
          case 'dollars':
            this.sanitizeDollars()
            break
        }
      },
      keydown (e) {
        this.sanitizeAmount()
      },
      keyup (e) {
        this.sanitizeAmount()
      },
      setCurrencyTab (currency) {
        if (this.disableClose) return
        this.request.amount = ''
        this.request.currency = currency
        switch (currency) {
          case 'tomans':
            this.currencyTabBorderTranslate = '100%'
            break
          case 'dollars':
            this.currencyTabBorderTranslate = 0
            break
        }
      },
      receivePaymentMessage (ev) {
        if (window.paymentRequestInterval != undefined) {
          clearInterval(window.paymentRequestInterval)
        }
        window.paymentWindow.close()
        this.request.inProcess = false
        this.request.transactionId = ev.data.transactionId
        if (ev.data.success) {
          this.request.status = true
          this.currentStep = 1
          this.$store.dispatch(USER_REQUEST)
        } else {
          this.request.status = false
          this.currentStep = 1
        }
      },
      backToHome() {
        this.currentStep = 0
        this.request.status = false
      },
      paymentRequest () {
        let amount = this.amount
        this.request.inProcess = true
        axios.post(API.payment.request, {
          type: 'chargeAccount',
          amount: amount,
          currency: this.request.currency
        })
          .then((res) => {
            if (res.data.success) {
              this.request.inProcess = false
            } else {
              if (res.data.redirect != undefined) {
                window.paymentWindow = helper.openWindow(res.data.redirect, 'Pay')
                window.removeEventListener('message', this.receivePaymentMessage)
                window.addEventListener('message', this.receivePaymentMessage)
                window.paymentRequestInterval = setInterval(() => {
                  if (window.paymentWindow.closed) {
                    this.request.inProcess = false
                    clearInterval(window.paymentRequestInterval)
                    return
                  }
                  try {
                    window.paymentWindow.postMessage({message: 'requestResult'}, '*')
                  }
                  catch (e) {
                  }
                }, 500)
                if (window.paymentWindow.closed) {
                  this.request.inProcess = false
                  clearInterval(window.paymentRequestInterval)
                  return
                }
              } else {
                this.request.inProcess = false
                bus.$emit('error', {
                  title: 'Error',
                  message: 'Something went wrong. Please try again.'
                })
              }
            }
          })
          .catch((e) => {
            this.request.inProcess = false
            bus.$emit('error', {
              title: 'Error',
              message: 'Something went wrong. Please try again.'
            })
          })
      }
    },
    watch: {
      'selectedPackage': function (newVal) {
        if (newVal != 'custom') {
          this.request.amount = ''
        }
      },
      show (newVal) {
        if (!newVal) {
          this.request.amount = ''
          this.request.transactionId = null
          this.request.inProcess = false
          this.request.status = false
        }
      },
      auth () {
        if(window.defaultUserCurrency===undefined) {
          this.request.currency = this.defaultCurrency
          window.defaultUserCurrency = true
          this.setCurrencyTab(this.request.currency)
        }
      }
    },
    mounted () {
      this.setCurrencyTab(this.request.currency)
    }

  }
</script>