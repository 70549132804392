<template>
    <div v-if="realId != null && realId != undefined">
        <div class="disabled-comment" v-if="!canComment">
            <div uk-grid class="uk-grid-10 uk-flex uk-flex-middle">
                <div class="uk-width-auto">
                    <div class="width-30 uk-text-center">
                        <i class="fal fa-comment-alt-slash"></i>
                    </div>
                </div>
                <div class="uk-width-expand">Comments for this post have been limited.</div>
            </div>
        </div>
        <div class="disabled-comment" v-if="canComment && commentsCnt === 0">
            <div uk-grid class="uk-grid-10 uk-flex uk-flex-middle">
                <div class="uk-width-auto">
                    <div class="width-30 uk-text-center">
                        <i class="fal fa-comment-alt"></i>
                    </div>
                </div>
                <div class="uk-width-expand">No one has commented yet.</div>
            </div>
        </div>
        <div class="post-comments" v-if="canComment && realCommentsCnt > 0">
            <div class="comments-container">
                <ul id="comments-list" class="comments-list" v-if="comments.length">
                    <li v-for="(comment, index) in comments" :key="index"
                        :class="{ pending: (comment.pending != undefined && comment.pending) }">
                        <div class="comment-main-level">
                            <div class="comment-avatar"><img :src="comment.user.photos.avatar | attach('s')" alt="">
                            </div>
                            <div class="comment-box">
                                <div class="comment-head">
                                    <h6 class="comment-name f-s-085">
                                        <router-link :to="'/' + comment.user.username">{{ comment.user.fullname }}
                                        </router-link>
                                    </h6>
                                    <span>&nbsp;•&nbsp;</span>
                                    <span class="f-s-080">{{ comment.createdAtAgo }}</span>
                                    <i v-if="comment.id !== undefined" @click="replyCm(comment)" class="fa fa-reply"></i>
                                </div>
                                <div class="comment-content f-s-085 f-f-reg"
                                    v-html="$options.filters.link(comment.comment)">
                                </div>
                                <div class="comment-new-reply f-s-085" v-if="currentReply === comment.id">
                                    <input type="text" @keyup="keyupReply" ref="repInput" autofocus class="f-s-080" v-focus
                                        v-model="commentTxt" :placeholder="'Reply to @' + comment.user.username">
                                </div>
                            </div>
                        </div>
                        <ul class="comments-list reply-list" v-if="comment.replies != undefined && comment.replies.length">
                            <div>
                                <div class="load-more-spinner" v-if="repliesInLoad.indexOf(comment.id) != -1">
                                    <div uk-spinner></div>
                                </div>
                                <div class="load-more-replies" @click="loadMoreReplies(comment)"
                                    v-if="repliesInLoad.indexOf(comment.id) === -1 && comment.replies.length < comment.repliesCount">
                                    Show More Replies…
                                </div>
                            </div>
                            <li v-for="reply in comment.replies" :key="reply.id || 100">
                                <div class="comment-avatar"><img :src="reply.user.photos.avatar | attach('s')"></div>
                                <div class="comment-box">
                                    <div class="comment-head">
                                        <h6 class="comment-name f-s-085">
                                            <router-link :to="'/' + reply.user.username">{{ reply.user.fullname }}
                                            </router-link>
                                        </h6>
                                        <span>&nbsp;•&nbsp;</span>
                                        <span class="f-s-080">{{ reply.createdAtAgo }}</span>
                                        <i v-if="reply.id !== undefined" @click="replyCm(comment, reply)"
                                            class="fa fa-reply"></i>
                                    </div>
                                    <div class="comment-content f-s-085 f-f-reg"
                                        v-html="$options.filters.link(reply.comment)">
                                    </div>
                                    <div class="comment-new-reply f-s-085" v-if="currentReply === reply.id">
                                        <input type="text" @keyup="keyupReply" ref="repInput" autofocus class="f-s-080"
                                            v-focus v-model="commentTxt"
                                            :placeholder="'Reply to @' + comment.user.username">
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div v-if="comments.length < realCommentsCnt">
                    <div class="load-more-spinner" v-if="commentsInLoad">
                        <div uk-spinner></div>
                    </div>
                    <div class="load-more-replies" @click="getComments(true)" v-if="!commentsInLoad">
                        Show More…
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    props: ['post', 'canComment', 'commentsCnt', 'realId', 'realCommentsCnt'],
    data() {
        return {
            comments: [],
            commentTxt: '',
            currentReply: null,
            repliesInLoad: [],
            commentsInLoad: true,
            vars: {
                comment: null
            }
        }
    },
    watch: {
        post: {
            handler: function (val, oldVal) {
                if (val.id !== undefined) {
                    if (oldVal === undefined || (oldVal.id !== undefined && oldVal.id !== val.id)) {
                        this.getComments()
                    } else {
                        if (val.newComments != undefined && val.newComments.length) {
                            let comment = val.newComments[0]
                            comment.createdAtAgo = 'just now'
                            comment.user = this.getMiniUser()
                            this.comments.unshift(comment)
                            val.newComments = []
                        }
                    }
                }
            },
            deep: true
        }
    },
    directives: {
        focus: {
            inserted: function (el) {
                el.focus()
            }
        }
    },
    methods: {
        initData() {

        },
        loadMoreReplies(comment) {
            let vm = this
            this.repliesInLoad.push(comment.id)
            axios.get(API.comments + '?commentId=' + comment.id + '&lastReplyId=' + comment.replies[0].id)
                .then((res) => {
                    let newReplies = res.data.replies
                    if (newReplies.length) {
                        for (let i = newReplies.length - 1; i >= 0; i--) {
                            let rep = newReplies[i]
                            comment.replies.unshift(rep)
                        }
                    }
                    let indexOfInLoad = this.repliesInLoad.indexOf(comment.id)
                    this.repliesInLoad.splice(indexOfInLoad, 1)
                })
        },
        getMiniUser() {
            let cmUser = JSON.parse(localStorage.getItem('user'))
            return {
                id: cmUser.id,
                username: cmUser.username,
                fullname: cmUser.fullname,
                photos: cmUser.photos,
                is: cmUser.is
            }
        },
        getComments(more) {
            this.commentsInLoad = true
            if (more === undefined)
                this.comments = []
            let vm = this
            axios.get(API.comments + '?postId=' + this.realId + (more != undefined ? '&lastId=' + this.comments[this.comments.length - 1].id : ''))
                .then((res) => {
                    if (more === undefined) {
                        vm.comments = res.data
                    } else {
                        for (let i = 0; i < res.data.length; i++) {
                            vm.comments.push(res.data[i])
                        }
                    }
                    vm.commentsInLoad = false
                    vm.comments.map((e) => {
                        if (e.replies === undefined) {
                            e.replies = []
                        }
                    })
                })
        },
        replyCm(comment, reply) {
            this.currentCm = comment
            let cc = reply || comment
            this.currentReply = cc.id
            this.commentTxt = '@' + cc.user.username + ' '
        },
        sendComment() {
            if (localStorage.getItem('user-token') === null) {
                this.$parent.$parent.showLogin = true
                return
            }
            if (this.commentTxt === '' || this.commentTxt.trim() === '') return
            if (this.disableCm) return
            let comment = {
                position: 0,
                postId: this.realId,
                comment: this.commentTxt,
                commentId: this.currentCm.id
            }

            axios.post(API.comments, comment)
                .then((res) => {
                    this.$emit('cmAdded')
                })
            comment.user = this.getMiniUser()
            this.currentCm.replies.push(comment)
            this.currentCm.repliesCount += 1
            this.commentTxt = ''
            this.currentCm = null
            this.currentReply = null
        },
        keyupReply(e) {
            if (e.keyCode === 13) {
                this.sendComment()
            }
        }
    },
    mounted() {
        this.initData()
        this.getComments()
        bus.$on('submitComment' + this.realId, (data) => {
            let comment = data;
            comment.createdAtAgo = 'just now';
            comment.user = this.getMiniUser();
            comment.pending = true;
            this.comments.unshift(comment)
        })
        bus.$on('commentSent' + this.realId, (data) => {
            this.comments.map((cm) => {
                if (cm.pending !== undefined && cm.pending === true && data.comment.comment === cm.comment) {
                    cm.pending = false;
                    cm.replies = [];
                    cm.id = data.comment.id;
                    cm.createdAtAgo = data.comment.createdAtAgo
                    this.$emit('cmAdded')
                }
            })
        })
    }
}
</script>