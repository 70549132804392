<template>
    <div ref="switchTabs" :class="{vertical: vertical, 'bordered': (activeMode==='bordered')}" class="switch-tabs">
        <div v-resize="onResize"
             @click="setTab(index)"
             ref="switchTab"
             class="switch-tab"
             v-key="index"
             v-for="(tab,index) in tabs"
             v-if="disabledTabs.indexOf(tab)===-1"
             :class="{'active': currentTab===index}"
             :style="{padding: padding}">
            <i v-if="icons[index]!=undefined" :class="icons[index]"></i><span :class="{'with-icon': icons[index]!=undefined, 'mobile-hidden': hideTextInMobile}">{{tab}}</span>
        </div>
        <div ref="bg" class="switch-tab-background"></div>
    </div>
</template>
<script>
    import resize from 'vue-resize-directive'

    export default {
        data() {
            return {}
        },
        methods: {
            onResize() {
                this.setTab(this.currentTab)
            },
            setTab(index) {
                if (this.$refs.switchTabs === undefined) {
                    setTimeout(() => {
                        this.setTab(this.currentTab)
                    }, 300)
                }
                let startAttr = (this.vertical) ? ['y', 'top'] : ['x', 'left']

                let parentEl = this.$refs.switchTabs
                let parentBoundingRect = parentEl.getBoundingClientRect()
                let el = this.$refs.switchTab[index]
                let boundingRect = el.getBoundingClientRect()
                let start = boundingRect[startAttr[0]] - parentBoundingRect[startAttr[0]]
                this.$refs.bg.style.width = el.clientWidth + 'px'
                this.$refs.bg.style.height = el.clientHeight + 'px'
                this.$refs.bg.style[startAttr[1]] = start + 'px'

                this.$emit('setTab', index)
            }
        },
        props: {
            hideTextInMobile: {
                type: Boolean,
                default: false
            },
            tabs: {
                type: Array,
                default: () => {
                    return []
                }
            },
            icons: {
                type: Array,
                default: () => {
                    return []
                }
            },
            currentTab: {
                type: Number,
                default: 0
            },
            padding: {
                type: String,
                default: "5px 10px"
            },
            vertical: {
                type: Boolean,
                default: false
            },
            activeMode: {
                type: String,
                default: 'background'
            },
            disabledTabs: {
                type: Array,
                default: () => {
                    return []
                }
            },
        },
        directives: {
            resize
        },
        watch: {
            currentTab(index) {
                let startAttr = (this.vertical) ? ['y', 'top'] : ['x', 'left']

                let parentEl = this.$refs.switchTabs
                let parentBoundingRect = parentEl.getBoundingClientRect()
                let el = this.$refs.switchTab[index]
                let boundingRect = el.getBoundingClientRect()
                let start = boundingRect[startAttr[0]] - parentBoundingRect[startAttr[0]]
                this.$refs.bg.style.width = el.clientWidth + 'px'
                this.$refs.bg.style.height = el.clientHeight + 'px'
                this.$refs.bg.style[startAttr[1]] = start + 'px'
            }
        },
        computed: {},
        mounted() {
            this.setTab(this.currentTab)
        }
    }
</script>
