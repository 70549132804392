<template>
    <div class="padding-x widget uk-margin-top not-found-page">
        <h2>404 error</h2>
        <h6>
            This page doesn't exist.<br><br>
            This probably happened because of a typo or the wrong keyboard layout.<br><br>
            If you got here from a link, please <router-link to="/explore/sounds">visit our explore</router-link>.
        </h6>
        <h4>Find music you'll like</h4>
        <div class="genres-list">
            <span v-for="(genre, index) in settings.genres">{{index!==0 ? ', ' : ''}}<router-link :to="'/explore/sounds/?genres=' + genre.id">{{genre.name}}</router-link></span>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {

            }
        },
        computed: {
            settings() {
                return this.$store.getters.settings
            }
        }
    }
</script>