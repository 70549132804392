var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "plus-minus" }, [
    _c(
      "div",
      {
        staticClass: "uk-grid-5 uk-text-center uk-flex uk-flex-middle",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { ref: "minus", staticClass: "minus" }, [
            _c("i", { staticClass: "fal fa-minus-circle" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand" }, [
          _c("div", { staticClass: "value uk-flex uk-flex-middle" }, [
            _c("span", { style: { "font-size": _vm.fontSize + "px" } }, [
              _vm._v(_vm._s(_vm._f("numformat")(_vm.value))),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { ref: "plus", staticClass: "plus" }, [
            _c("i", { staticClass: "fal fa-plus-circle" }),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }