var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.users.length
      ? _c("div", { staticClass: "mini-chat-title" }, [
          _c("h5", [_vm._v("Contacts")]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "chat-users-list" },
      _vm._l(_vm.users, function (user) {
        return _c(
          "div",
          { staticClass: "chat-user" },
          [
            _c("router-link", { attrs: { to: "/chat/" + user.username } }, [
              _c(
                "div",
                {
                  staticClass: "uk-grid-5 uk-flex uk-flex-middle",
                  attrs: { "uk-grid": "" },
                },
                [
                  _c("div", { staticClass: "uk-width-auto" }, [
                    _c("img", {
                      attrs: {
                        width: "40",
                        height: "40",
                        src:
                          user.profile_uri === ""
                            ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHUcNmf-luvb2nFRc4TIG8iT1oaqtygWWgPqxs5LOxO-Htc7-6"
                            : user.profile_uri + "_150.jpg",
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-width-expand" }, [
                    _c("div", { staticClass: "chat-name" }, [
                      _vm._v(
                        "\n                            " +
                          _vm._s(user.fullname) +
                          "\n                        "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }