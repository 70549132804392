<template>
    <div>
        <VueApexCharts :key="parentKey" type="bar" :options="options" :series="series"></VueApexCharts>
    </div>
</template>
<script>
    import VueApexCharts from 'vue-apexcharts'
    export default {
        components: {
            VueApexCharts
        },
        props: {
            streamChart: {
                type: Array,
            }
        },
        watch: {
            streamChart(data, oldData) {
                let revenues = this.$_.pluck(data, 'amount')
                // let dates = this.$_.pluck(data, 'date')
                let i = 0
                let tmpData = revenues.map((e) => {
                    return parseFloat(e)
                })
                if(this.series[0].data[tmpData.length-1]===undefined || tmpData[tmpData.length-1]!=this.series[0].data[tmpData.length-1]) {
                    this.series = [
                        {
                            data: tmpData,
                            name: 'Revenue'
                        }
                    ];
                    let categories = this.$_.pluck(data, 'label')
                    this.options.xaxis.categories = categories
                    this.parentKey = Math.random()
                }
            },
        },
        data() {
            return {
                parentKey: 'first',
                options: {
                    tooltip: {
                        enabled: false,
                    },
                    plotOptions: {
                        bar: {
                            dataLabels: {
                                position: 'top', // top, center, bottom
                            },
                        }
                    },
                    dataLabels: {
                        enabled: true,
                        formatter: function (val) {
                            return "$" + val;
                        },
                        offsetY: -20,
                        style: {
                            fontSize: '12px',
                            colors: ["#304758"]
                        }
                    },
                    grid: {
                        show: true,
                        borderColor: '#e0e0e05c'
                    },
                    xaxis: {
                        categories: ["", "", "", "", "", "", ""],
                        position: 'bottom',
                        labels: {
                            offsetY: 0,
                            formatter: function (val) {
                                return val
                            },
                            datetimeFormatter: {
                                year: 'yyyy',
                                month: "MMM 'yy",
                                day: 'dd MMM',
                                hour: 'HH:mm',
                            },
                        },
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false
                        },
                        crosshairs: {
                            fill: {
                                type: 'gradient',
                                gradient: {
                                    colorFrom: '#D8E3F0',
                                    colorTo: '#BED1E6',
                                    stops: [0, 100],
                                    opacityFrom: 0.4,
                                    opacityTo: 0.5,
                                }
                            }
                        },
                        tooltip: {
                            enabled: false,
                            offsetY: -35,
                        }
                    },
                    fill: {
                        gradient: {
                            shade: 'light',
                            type: "horizontal",
                            shadeIntensity: 0.25,
                            gradientToColors: undefined,
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [50, 0, 100, 100]
                        },
                    },
                    yaxis: {
                        axisBorder: {
                            show: false
                        },
                        axisTicks: {
                            show: false,
                        },
                        labels: {
                            show: false,
                            formatter: function (val) {
                                return "$" + val;
                            }
                        }
                    }
                },
                series: [{
                    name: 'Revenue',
                    data: [0, 0, 0, 0, 0, 0, 0],
                }],
            }
        },
    }
</script>