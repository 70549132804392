var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.items, function (item, index) {
      return _c(_vm.type, {
        key: _vm.type + "_" + item.id,
        tag: "component",
        class: { loading: _vm.inLoads.indexOf(item.id) != -1 },
        attrs: {
          options: _vm.options,
          "data-id": "ads" + item.id,
          item: item,
          index: index,
        },
        on: {
          clicked: function ($event) {
            return _vm.clickAds(item)
          },
          "update:item": function ($event) {
            item = $event
          },
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }