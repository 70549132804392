<template>
    <div>
        <div class="sound-slider-item " v-if="item.realId === undefined" href="javascript:void(0)">
            <div class="image">
                <ImagePlaceholder radius="0" width="200px" height="200px"/>
            </div>
            <div class="sub uk-text-truncate uk-text-center f-s-075">
                <TextSkeleton :style="{margin: '10px auto 0'}" width="80%" height="10px"/>
                <TextSkeleton :style="{margin: '10px auto 0'}" width="80%" height="10px"/>
                <TextSkeleton :style="{margin: '10px auto 0'}" width="80%" height="10px"/>
            </div>
        </div>
        <div v-else class="sound-slider-item">
            <div class="sound-slider-item-inner">
                <div class="sound-slider-item-cover">
                    <div class="sound-slider-item-cover-inner">
                        <ImagePlaceholder radius="0" :data-src="cover | attach('m')" width="100%" height="100%"/>
                        <div class="sound-slider-item-actions">
                            <div class="uk-float-left">
                                <span v-if="item.realId!=undefined" class="sound-slider-item-action-play" @click="play">
                                    <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='play'"
                                    class="fas fa-grip-lines-vertical"></i>
                                    <i v-if="currentTrack!=null && currentTrack==item.realId && playerStatus==='wait'"
                                    class="fas fa-spinner-third fa-spin"></i>
                                    <i v-if="currentTrack===null || currentTrack!==item.realId || (currentTrack===item.realId && playerStatus==='pause')"
                                    class="fas fa-play"></i>
                                </span>
                            </div>
                            <div class="uk-float-right">
                                <span v-if="item.realId!=undefined" @click="moreMenu(item, $event)"
                                    class="sound-slider-item-action-ellipsis f-s-15">
                                    <i class="fal fa-ellipsis-h disable-click"></i>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="sound-slider-item-details">
                    <div v-if="item.realId!=undefined" class="sound-slider-item-title f-s-090 uk-text-truncate">
                        <router-link :to="permalink">{{item.title}}</router-link>
                        <span class="explicit" v-if="item.metas.explicit">🅴</span>
                    </div>
                    <div v-if="item.realId!=undefined" class="sound-slider-item-artist f-s-080">
                        <span class="artist"><router-link
                                :to="item.user.username">{{item.user.fullname}}</router-link></span>
                        <span class="teammate" v-for="teammate in item.users" :key="teammate.username">, <router-link :to="teammate.username">{{teammate.fullname}}</router-link></span>
                    </div>
                    <div v-if="item.realId!=undefined && (options.showType!=undefined && options.showType)"
                        class="sound-slider-pt">
                        {{item.postType | postType}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import TextSkeleton from "@/components/Tools/TextSkeleton";

    export default {
        props: [
            'item',
            'index',
            'options'
        ],
        data() {
            return {
                initialized: false
            }
        },
        methods: {
            moreMenu(item, e) {
                bus.$emit('clickMoreMenu', item, e, 'SoundSliderItem')
            },
            like(item) {
                bus.$emit('like', item)
            },
            repost(item) {
                bus.$emit('repost', item)
            },
            play() {
                this.$emit('dop', this.item)
                //bus.$emit('play', this.item)
            }
        },
        computed: {
            currentTrack() {
                return (this.$store.getters.getCurrent !== null && this.$store.getters.getCurrent.realId != undefined) ? this.$store.getters.getCurrent.realId : null
            },
            playerStatus() {
                return this.$store.getters.playerStatus
            },
            permalink() {
                let itm = this.item
                let prfx = itm.postType === 'SND' ? '/' : (itm.postType==='VID' ? '/video/' : (itm.postType==='PLY' ? '/playlist/' : (itm.postType==='ALB' ? '/album/' : '/')))
                return `/${itm.user.username}${prfx}${itm.slug}`
            },
            cover() {
                let cover;
                try {
                    cover = this.item.metas.cover
                } catch (e) {
                    cover = ''
                }
                return cover
            }
        },
        components: {
            ImagePlaceholder,
            TextSkeleton
        }
    }
</script>