<template>
    <transition name="modal" v-if="show">
        <div class="modal-mask" @click="clickOnMask">
            <div class="modal-wrapper stepped">
                <div class="modal-container">
                    <div @click="close" class="png-ico close small">
                    </div>
                    <div class="modal-steps">
                        <div class="modal-steps-inner" ref="containerStepsInner" :style="{width: steps*100 + '%'}">
                            <div v-for="index in stepsArr" class="modal-step">
                                <div class="modal-header">
                                    <slot :name="'header'+index">
                                    </slot>
                                </div>
                                <div class="modal-body">
                                    <slot :name="'body'+index">
                                    </slot>
                                </div>
                                <div class="modal-footer">
                                    <slot :name="'footer'+index">
                                    </slot>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer">
                            <slot name="footer">

                            </slot>
                        </div>
                    </div>
                    <div v-if="background!==''" class="modal-background" :style="{backgroundImage: 'url(' + background + ')'}"></div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
  export default {
    props: {
      show: {
        type: Boolean,
        default: false
      },
      currentStep: {
        type: Number,
        default: 0
      },
      stepsArr: {
        type: Array,
        default: () => {
          return []
        }
      },
      background: {
        type: String,
        default: ''
      },
      disableClose: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      steps() {
        return this.stepsArr.length
      },
      containerTransform () {
        let percent = 0
        if (this.currentStep != 0) {
          let sW = 100 / this.steps
          percent = this.stepsArr.indexOf(this.currentStep) * sW * -1
        }
        return 'translateX(' + percent + '%)'
      },
    },
    watch: {
      show (newVal) {
        if (newVal) {
          document.body.setAttribute('data-modal', '1')
        } else {
          document.body.removeAttribute('data-modal')
        }
      },
      currentStep(newVal) {
        //containerTransform
        this.$refs.containerStepsInner.style.transform = this.containerTransform
      }
    },
    methods: {
      close () {
        if(this.disableClose) return
        this.$emit('close')
      },
      clickOnMask (e) {
        if (e.target.className === 'modal-wrapper stepped') {
          this.close()
        }
      }
    },
    mounted() {
    }
  }
</script>