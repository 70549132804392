<template>
    <!--:currentStep="currentStep"-->
    <SteppedModal ref="steppedModal" :disableClose="disableClose" :steps="steps" :stepsArr="stepsArr"
                  :currentStep="currentStep" :background="cover | attach('blur')" @close="close" :show="show">
        <div slot="header0" v-show="currentStep===0">
            Promote {{data.title}}
        </div>
        <div slot="body0" v-show="currentStep===0">
            <div class="promote-post-body">
                <div class="uk-text-center">
                    <img v-if="data.metas!=undefined && data.metas.cover!=undefined"
                         :src="data.metas.cover | attach('m')"/>
                </div>
                <div class="modal-radio-container">
                    <div>
                        <label>
                            <input class="uk-radio" v-model="promote.type" type="radio" value="global" checked> Global
                        </label>
                        <div class="small-description f-s-075">
                            VOWAVE will target and randomize all locations and users.
                        </div>
                    </div>
                    <div>
                        <label>
                            <input class="uk-radio" v-model="promote.type" type="radio" value="custom">
                            Custom
                        </label>
                        <div class="small-description f-s-075">
                            You can select custom locations & fans and genres regarding your needs.
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div slot="header1" v-show="currentStep===1">
            Customize Your Audience
        </div>
        <div class="modal-selectpages" slot="body1" v-show="currentStep===1">
            <div class="uk-text-center">
                <span class="png-ico target-ad x2"></span>
            </div>
            <div>
                <label class="f-s-090">
                    Prefered Locations
                </label>
                <v-selectpage
                        :data="' '"
                        :result-format="countriesResFormat"
                        :params="{type: 'country'}"
                        :multiple="true"
                        :language="'en'"
                        :placeholder="'Select countries'"
                        :title="'Countries'"
                        :max-select-limit="8"
                        v-model="promote.countries">
                </v-selectpage>
                <div class="small-description f-s-075">
                    Select locations that you want your {{data.postType | postType(true)}} to show up there.
                </div>
            </div>
            <div>
                <label class="f-s-090">
                    Prefered Artists Audience
                </label>
                <v-selectpage
                        :data="' '"
                        :result-format="tagsResultFormat"
                        :params="{type: 'miniUsers'}"
                        :multiple="true"
                        :language="'en'"
                        :show-field="renderUsersRow"
                        search-field="name"
                        :placeholder="'Select users'"
                        :title="'Users'"
                        :max-select-limit="8"
                        v-model="promote.users">
                </v-selectpage>
                <div class="small-description f-s-075">
                    You can select some users so your {{data.postType | postType(true)}} will get promoted among those
                    artists audience.
                </div>
            </div>
            <div>
                <label class="f-s-090">
                    Prefered Genres Listeners
                </label>
                <v-selectpage
                        :data="' '"
                        :result-format="tagsResultFormat"
                        :params="{type: 'genres'}"
                        :multiple="true"
                        :language="'en'"
                        :placeholder="'Select genres'"
                        :title="'Genres'"
                        :max-select-limit="8"
                        v-model="promote.genres">
                </v-selectpage>
                <div class="small-description f-s-075">
                    Select genres, so your {{data.postType | postType(true)}} will get promoted within that genres
                    listeners.
                </div>
            </div>

        </div>

        <div slot="header2" v-show="currentStep===2">
            <i @click="setStep(false)" class="fal fa-arrow-left"></i> Views
        </div>
        <div slot="body2" v-if="currentStep===2 && settings===null">
            <div class="uk-text-center">
                <i uk-spinner></i>
            </div>
        </div>
        <div slot="body2" v-if="currentStep===2 && settings!==null">
            <div class="uk-text-center f-s-2 promote-post-price" :class="{rtl: promote.currency==='tomans'}">
                <span class="farsi-font" v-if="promote.currency==='tomans'">
                    {{price | numformat}} <span class="f-s-075">تومان</span>
                </span>
                <span v-if="promote.currency==='dollars'">
                    <span>$</span>{{price}}
                </span>
            </div>
            <div class="currency-tabs">
                <div class="currency-tab-border"
                     :style="{transform: 'translateX('+currencyTabBorderTranslate+')'}"></div>
                <div class="currency-tab" @click="setCurrencyTab('dollars')"
                     :class="{active : promote.currency==='dollars'}">
                    Dollars
                </div>
                <div class="currency-tab" @click="setCurrencyTab('tomans')"
                     :class="{active : promote.currency==='tomans'}">
                    تومان
                </div>
            </div>
            <div class="small-description f-s-080">
                How many impressions do you want for this post?
            </div>
            <div class="v-num-parent">
                <PlusMinusNumbers
                        @update="updateViews"
                        v-model="promote.views"
                        :min="settings.minimumViews"
                        :max="settings.maximumViews"
                        :range="settings.minimumViews"
                        :disabled="disableClose"
                        text="Views"
                        :fs="60"
                        :fsRange="5"
                />
            </div>
            <div class="shetab-img">
                <img :src="PaymentImage"/>
            </div>
        </div>

        <div slot="header3">
        </div>
        <div slot="body3" :class="{rtl: promote.currency==='tomans'}">
            <div v-if="promote.status">
                <div class="status success payment">
                    <div>
                        <i class="fal fa-check-circle"></i>
                    </div>
                    <div class="title f-s-12">
                        {{promote.currency==='tomans' ?
                        'پرداخت با موفقیت !'
                        :
                        'Congratulations!'
                        }}
                    </div>
                </div>
                <div class="status description farsi-font f-s-090" v-if="promote.currency==='tomans'">
                    شما با موفقیت {{price | numformat}} تومان پرداخت کردید و درخواستتان به صورت اتوماتیک پردازش خواهد
                    شد.<br>
                    در صورتی که سوالی دارید میتوانید از صفحه
                    <router-link to="/contact-us">تماس با ما</router-link>
                    استفاده کنید.<br>
                    <span v-if="promote.transactionId!=null"><br>
                    کد پیگیری این تراکنش :
                    {{promote.transactionId}}</span>
                </div>
                <div class="status description f-s-090" v-if="promote.currency==='dollars'">
                    You paid ${{price}} successfully and your request will get processed automatically. <br>
                    If you have any questions you can
                    <router-link to="/contact-us">contact us</router-link>
                    .<br>
                    <span v-if="promote.transactionId!=null">This Transaction Id :
                        {{promote.transactionId}}</span>
                </div>
            </div>
            <div v-if="!promote.status">
                <div class="status error payment">
                    <div>
                        <i class="fal fa-exclamation-circle"></i>
                    </div>
                    <div class="title f-s-12">
                        {{promote.currency==='tomans' ?
                        'خطا در پرداخت !'
                        :
                        'Something went wrong!'
                        }}
                    </div>
                </div>
                <div class="status description f-s-090" v-if="promote.currency==='tomans'">
                    متاسفانه در مراحل پرداخت شما مشکلی به وجود آمد و این عملیات با موفقیت انجام نشد.<br>
                    لطفا دوباره سعی کنید و اگر خطا برطرف نشد از طریق صفحه
                    <router-link to="/contact-us">تماس با ما</router-link>
                    آن را اطلاع دهید.<br>
                    کد پیگیری این تراکنش :
                    {{promote.transactionId}}
                </div>
                <div class="status description f-s-090" v-if="promote.currency==='dollars'">
                    It seems there was an issue with your payment.<br>
                    Please try again and if the issue still persist
                    <router-link to="/contact-us">contact us</router-link>
                    .<br>
                    This Transaction Id :
                    {{promote.transactionId}}
                </div>
            </div>
        </div>


        <div slot="footer" class="uk-flex">
            <div class="line-height-30 f-s-070" v-if="currentStep===2">
                Your Balance:
                <span v-if="promote.currency==='dollars'">${{balance}} USD</span>
                <span v-if="promote.currency==='tomans'">{{balance | numformat}} Tomans</span>
            </div>
            <button v-if="hasBack && currentStep!=2" class="uk-button button-top-menu hover-opacity"
                    @click="setStep(false)">Back
            </button>
            <button v-if="hasNext && currentStep!=2"
                    class="uk-button button-top-menu button-bg uk-button-default flex-right" @click="setStep(true)">Next
            </button>
            <button v-if="currentStep===2"
                    class="uk-button button-top-menu button-bg uk-button-default flex-right uk-span-flex-inline"
                    @click="paymentRequest" :disabled="promote.inProcess">
                <span v-if="!promote.inProcess">
                    {{promote.currency==='tomans' ? 'خرید' : 'Buy'}}
                </span>
                <span v-if="promote.inProcess">
                    <i uk-spinner="ratio: 0.5"></i>
                </span>
            </button>
        </div>
    </SteppedModal>
</template>
<script>
    import SteppedModal from './SteppedModal'
    import axios from 'axios'
    import PlusMinusNumbers from '../PlusMinusNumbers'
    import PaymentImage from '../../assets/img/payments-with-shetab.png'
    import helper from '../../helpers/helper'
    import {USER_REQUEST} from '../../store/actions/user'

    export default {
        components: {
            SteppedModal,
            PlusMinusNumbers
        },
        data() {
            return {
                PaymentImage: PaymentImage,
                promote: {
                    inProcess: false,
                    type: 'global',
                    genres: '',
                    countries: '',
                    users: '',
                    price: 0,
                    currency: 'tomans',
                    views: 0,
                    status: false,
                    transactionId: null
                },
                currencyTabBorderTranslate: 0,
                settings: null,
                stepsArr: [
                    0, 1, 2, 3
                ],
                countriesResFormat: (res) => {
                    return {
                        totalRow: res.data.meta.total,
                        list: res.data.data
                    }
                },
                tagsResultFormat: (res) => {
                    return {
                        totalRow: res.data.meta.total,
                        list: res.data.data
                    }
                },
                renderUsersRow: (row) => {
                    let result = '';
                    if (row && Object.keys(row).length)
                        result = `@${row.username}`;
                    return result;
                },
            }
        },
        computed: {
            cover() {
                if (this.data.metas != undefined && this.data.metas.cover != undefined) {
                    return this.data.metas.cover
                }
                return ''
            },
            disableClose() {
                return this.promote.inProcess
            },
            balance() {
                let balance = this.promote.currency === 'tomans' ? this.auth.stats.balance.tomans : this.auth.stats.balance.dollars
                if (this.promote.currency === 'dollars') {
                    balance = parseFloat(balance).toFixed(2)
                }
                return balance
            },
            auth() {
                return this.$store.getters.getProfile
            },
            price() {
                if (this.settings === null) return
                let realType = ((this.promote.users === '' && this.promote.genres === '' && this.promote.countries === '') || this.promote.type === 'global') ? 'global' : 'custom'
                let price = 0
                let eachPrice
                switch (realType) {
                    case 'custom':
                        if (this.promote.currency === 'tomans') {
                            eachPrice = this.settings.pricePerViewPostCustomTomans
                        } else {
                            eachPrice = this.settings.pricePerViewPostCustom
                        }
                        break
                    case 'global':
                        if (this.promote.currency === 'tomans') {
                            eachPrice = this.settings.pricePerViewPostGlobalTomans
                        } else {
                            eachPrice = this.settings.pricePerViewPostGlobal
                        }
                        break
                }
                let tot = eachPrice * this.promote.views
                if (this.promote.currency === 'dollars') {
                    return tot.toFixed(2)
                }
                return tot
            },
            hasBack() {
                if (this.currentStep === 3 && this.promote.status) return false
                if (this.currentStep != 0) {
                    return true
                }
                return false
            },
            hasNext() {
                if (this.currentStep === 3) return false
                if (this.currentStep === 2 && this.settings === null) {
                    return false
                }
                if (this.currentStep === this.steps) {
                    return false
                } else {
                    return true
                }
            }
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            currentStep: {
                type: Number,
                default: 0
            }
        },
        methods: {
            receivePaymentMessage(ev) {
                // console.log('ev', ev)
                if (window.paymentRequestInterval != undefined) {
                    clearInterval(window.paymentRequestInterval)
                }
                window.paymentWindow.close()
                this.promote.inProcess = false
                this.promote.transactionId = ev.data.transactionId
                if (ev.data.success) {
                    this.promote.status = true
                    this.currentStep = 3
                    this.$store.dispatch(USER_REQUEST)
                } else {
                    this.promote.status = false
                    this.currentStep = 3
                }
            },
            paymentRequest() {
                this.promote.inProcess = true
                axios.post(API.payment.request, {
                    type: 'buyPostViews',
                    currency: this.promote.currency,
                    views: this.promote.views,
                    postId: this.data.realId,
                    users: this.promote.users,
                    genres: this.promote.genres,
                    countries: this.promote.countries
                })
                    .then((res) => {
                        if (res.data.success) {
                            this.promote.inProcess = false
                            this.promote.status = true
                            this.currentStep = 3
                            bus.$emit('success', {
                                title: 'Success',
                                message: 'You paid this invoice from your account’s balance.'
                            })
                            this.$store.dispatch(USER_REQUEST)
                        } else {
                            if (res.data.redirect != undefined) {
                                window.paymentWindow = helper.openWindow(res.data.redirect, 'Pay')
                                window.removeEventListener('message', this.receivePaymentMessage)
                                window.addEventListener('message', this.receivePaymentMessage)
                                window.paymentRequestInterval = setInterval(() => {
                                    if (window.paymentWindow.closed) {
                                        this.promote.inProcess = false
                                        clearInterval(window.paymentRequestInterval)
                                        return
                                    }
                                    try {
                                        window.paymentWindow.postMessage({message: 'requestResult'}, '*')
                                    } catch (e) {
                                    }
                                }, 500)
                                if (window.paymentWindow.closed) {
                                    // console.log('window.paymentWindowClosed')
                                    this.promote.inProcess = false
                                    clearInterval(window.paymentRequestInterval)
                                    return
                                }
                            } else {
                                this.promote.inProcess = false
                                bus.$emit('error', {
                                    title: 'Error',
                                    message: 'Something went wrong. Please try again.'
                                })
                            }
                        }
                    })
                    .catch((e) => {
                        this.promote.inProcess = false
                        bus.$emit('error', {
                            title: 'Error',
                            message: 'Something went wrong. Please try again.'
                        })
                    })
            },
            updateViews(views) {
                this.promote.views = views
            },
            setCurrencyTab(currency) {
                if (this.disableClose) return
                this.promote.currency = currency
                switch (currency) {
                    case 'tomans':
                        this.currencyTabBorderTranslate = '100%'
                        break
                    case 'dollars':
                        this.currencyTabBorderTranslate = 0
                        break
                }
            },
            getSettings() {
                axios.get(API.payment.settings)
                    .then(res => {
                        this.settings = res.data
                        this.setCurrencyTab(this.auth.geo === 'IR' ? 'tomans' : 'dollars')
                        if (this.promote.views === 0) {
                            this.promote.views = this.settings.minimumViews
                        }
                    })
                    .catch(() => {
                        this.getSettings()
                    })
            },
            close() {
                if (this.disableClose) return
                this.$emit('close')
                this.show = false
            },
            setStep(type) {
                if (this.disableClose) return
                let step = 0
                switch (this.currentStep) {
                    case 0:
                        if (type) {
                            if (this.promote.type === 'global') {
                                step = 2
                            } else {
                                step = 1
                            }
                        }
                        break
                    case 1:
                        if (type) {
                            step = 2
                        } else {
                            step = 0
                        }
                        break
                    case 2:
                        if (type) {
//              if(this.promote.type==='global') {
//                step = 2
//              } else {
//                step = 1
//              }
                        } else {
                            if (this.promote.type === 'global') {
                                step = 0
                            } else {
                                step = 1
                            }
                        }
                        break
                    case 3:
                        step = 2
                }
                this.currentStep = step
            }
        },
        watch: {
            show(newVal) {
                if (!newVal) {
                    this.currentStep = 0
                    this.promote.inProcess = false
                    this.promote.type = 'global'
                    this.promote.genres = ''
                    this.promote.countries = ''
                    this.promote.users = ''
                    this.promote.status = false
                }
                if (newVal && this.settings === null) {
                    this.getSettings()
                }
            }
        }
    }
</script>