<template>
  <div class="widget padding-x" v-if="items.length > 0" ref="mainContainer">
    <div>
      <div class="widget-title">
        <div class="uk-flex uk-flex-middle" uk-grid>
          <div class="uk-width-expand">
            <h4 class="f-s-13">{{ options.title | lang }}</h4>
            <!-- <h6 class="f-s-090">{{ options.subtitle | lang }}</h6> -->
            <h6 class="f-s-090">{{options.subtitle | lang({artist: value && value.fullname})}}</h6>
          </div>
          <div
            class="uk-width-auto"
            v-if="$route.params.username !== undefined"
          >
            <router-link
              class="see-all-right"
              :to="'/' + $route.params.username + '/sounds'"
              >See all</router-link
            >
          </div>
        </div>
      </div>
      <div class="widget-content sound-slider-widget">
        <div class="sound-slider">
          <div class="sound-slider-items" :style="{ height: height }">
            <flicking
              :options="{
                renderOnlyVisible: false,
                align: 'prev',
                preventClickOnDrag: true,
                bound: true,
                circularFallback: 'bound',
              }"
            >
              <div
                class="flicking-panel"
                v-for="(item, index) in items"
                :key="index"
              >
                <SoundSliderItem
                  @dop="dop"
                  :item="item"
                  @moreMenu="moreMenu"
                  :options="{ showType: false }"
                  :class="{
                    active:
                      item.realId != undefined &&
                      (activeItem === item.realId ||
                        (item.postType === 'SND' &&
                          currentTrack != null &&
                          currentTrack == item.realId) ||
                        (item.postType === 'VID' &&
                          currentVideo != null &&
                          currentVideo == item.realId)),
                    deleting: deletings.indexOf(item.id) !== -1,
                  }"
                />
              </div>
            </flicking>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../helpers/helper.js";
import SoundSliderItem from "@/components/Items/SoundSliderItem";
import axios from "axios";
export default {
  props: ["options", "value"],
  components: {
    SoundSliderItem,
  },
  computed: {
    currentTrack() {
      try {
        return this.$store.getters.getCurrent.realId != undefined
          ? this.$store.getters.getCurrent.realId
          : null;
      } catch (e) {}
      return null;
    },
    playerStatus() {
      return this.$store.getters.playerStatus;
    },
    activeItem() {
      let ai = this.$store.getters.getActiveItem;
      if (ai != null) {
        return parseInt(this.$store.getters.getActiveItem.split("_")[1], 10);
      }
      return null;
    },
    itemsLength: function () {
      if (
        this.items === undefined ||
        this.items === null ||
        this.items.length < 1
      )
        return "0";
      return this.items.length;
    },
  },
  watch: {
    // value: function (newVal) {
    //     this.items = []
    //     this.parseData()
    // },
    $route: function (newVal) {
      this.parseData();
    },
    items() {
      setTimeout(() => {
        if (this.$refs.slider === undefined) return;
        this.$refs.slider.watchItems();
      }, 200);
    },
  },

  data() {
    return {
      routeKey: "",
      deletings: [],
      imagesLoadedCnt: 0,
      imagesLoaded: false,
      itemsLoaded: false,
      items: [{}, {}, {}, {}],
      sliderInit: false,
      itemsCnt: 0,
      itemsToShow: 2.5,
      initialized: false,
      emptyCount: [...Array(20).keys()],
      key: Math.random(),
      parentKey: Math.random(),
      height: "auto",
      helper: helper,
      width: 0,
      currentSlide: 0,
      coversLoadedStatus: [],
      mouse: {
        startedAt: 0,
        endAt: 0,
        lastPos: 0,
      },
      // breakpoints: {
      //     320: {
      //         slidesPerView: 1,
      //         spaceBetween: 10
      //     },
      //     480: {
      //         slidesPerView: 2.2,
      //         spaceBetween: 12,
      //         freeMode: true,
      //         freeModeMomentum: true,
      //         freeModeMomentumRatio: '0.5',
      //     },
      //     640: {
      //         slidesPerView: 3.2,
      //         spaceBetween: 12
      //     },
      //     1080: {
      //         slidesPerView: 3,
      //         spaceBetween: 12
      //     },
      //     1600: {
      //         slidesPerView: 3.1,
      //         spaceBetween: 12
      //     },
      //     2000: {
      //         slidesPerView: 5.2,
      //         spaceBetween: 12
      //     },
      //     10000: {
      //         slidesPerView: 7.5,
      //         spaceBetween: 20,
      //     }
      // }
      breakpoints: {
        640: {
          slidesPerView: "auto",
          spaceBetween: 0,
          freeMode: true,
          freeModeMomentum: true,
          freeModeMomentumRatio: "0.5",
        },
        1080: {
          slidesPerView: "auto",
          spaceBetween: 0,
        },
        1600: {
          slidesPerView: "auto",
          spaceBetween: 0,
        },
        2000: {
          slidesPerView: "auto",
          spaceBetween: 0,
        },
        10000: {
          slidesPerView: "auto",
          spaceBetween: 0,
        },
      },
    };
  },
  created() {},

  methods: {
    dop(e) {
      // console.log("e", e);
      if (e.tracks !== undefined && e.tracks.length) {
        bus.$emit("play", e.tracks[0], e.tracks);
      } else {
        bus.$emit("play", e, this.items);
      }
    },
    moreMenu(item, e) {
      bus.$emit("clickMoreMenu", item, e, "SoundSliderItem");
    },
    play(item, setItems) {
      if (item.realId === this.currentTrack) {
        if (this.playerStatus === "play") {
          bus.$emit("playerPause");
        } else {
          bus.$emit("playerPlay");
        }
        return;
      }

      const getCurrentPlaylistIds = this.$store.getters.getPlaylistItemsIds;
      if (
        item.tracks === undefined &&
        getCurrentPlaylistIds.length &&
        getCurrentPlaylistIds.indexOf(item.realId) > -1
      ) {
        this.$store.dispatch("setCurrent", item);
      }
      let items = [];
      if (setItems === undefined) {
        items = this.items;
      } else {
        items = setItems;
      }

      let sI = null,
        sP = null;

      if (item.tracks != undefined && item.tracks.length) {
        sI = item.tracks[0];
        sP = item.tracks;
      } else if (item.postType === "SND") {
        let realItems = [];
        items = items.filter((e) => {
          return ["SND", "VID"].indexOf(e.postType) > -1;
        });
        let itemsIds = this.$_.pluck(items, "realId");
        let itemIndex = itemsIds.indexOf(item.realId);
        for (let i = itemIndex; i < items.length; i++) {
          realItems.push(items[i]);
          if (realItems.length >= cnt) break;
        }
        if (realItems.length < cnt) {
          for (let i = itemIndex - 1; i >= 0; i--) {
            realItems.unshift(items[i]);
            if (realItems.length >= cnt) break;
          }
        }
        sI = item;
        sP = realItems;
      }
      this.$store.dispatch("setCurrent", sI);
      setTimeout(() => {
        this.$store.dispatch("setPlaylist", sP);
      }, 300);
    },
    parseData() {
      let routeName = this.$route.name;
      if (routeName === "profileArtist") {
        routeName += "_" + this.$route.params.username;
      }
      if (routeName === this.routeKey) {
        return;
      } else {
        this.items = [{}, {}, {}, {}];
        this.coversLoadedStatus = [];
      }
      this.routeKey = routeName;
      if (this.$route.name === "profileArtist") {
        axios
          .get(
            `/posts?count=15&username=${this.$route.params.username}&postType=SND&noText=true`
          )
          .then((res) => {
            const dt = res.data;
            this.items = dt;
            bus.$emit("profileArtistWidgets", {
              items: this.items.length,
              username: this.$route.params.username,
            });
          });
      }
      return;
    },
  },
  mounted() {
    // this.items = []
    // bus.$on((this.$route.params.username!==undefined ? this.$route.params.username : '') + 'g' + this.options.url, (data) => {
    //     this.items = data
    // })
    this.parseData();
  },
  beforeDestroy() {
    // bus.$off((this.$route.params.username!==undefined ? this.$route.params.username : '') + 'g' + this.options.url)
  },
};
</script>