<template>
    <div ref="mainContainer">
        <flicking :options="{ renderOnlyVisible: false, align: 'prev', preventClickOnDrag: true, bound: true, circularFallback: 'bound'}">
            <div class="flicking-panel" v-for="(item, index) in items" :key="index">
                <ScreenshotItem
                    class="swiper-slide screenshot-slider-item"
                    :item="item"
                    :index="index"
                />
            </div>
        </flicking>
    </div>
</template>
<style>
    .screenshot-slider-item, .screenshot-slider-item img {
        height: 80px !important;
    }
    .screenshot-slider-widget .swiper-slide {
        width: auto !important;
    }
</style>
<script>
    import Items from '../Items/Items'
    import Swiper from 'swiper'
    import resize from 'vue-resize-directive'
    import ScreenshotItem from '@/components/Items/ScreenshotItem'

    export default {
        props: {
            items: {},
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            localkey: {},
        },
        directives: {
            resize
        },
        components: {ScreenshotItem},
        data() {
            return {
                run: false
            }
        },
        watch: {
            items: {
                handler: function () {
                    this.miniScreenshotSliderParentKey = Math.floor(Math.random() * 1000)
                    this.screenshotSlider.update()
                },
                deep: true
            }
        },
        methods: {
            onResize() {
                this.init()
            },
            init() {
                this.miniScreenshotSliderParentKey = Math.floor(Math.random() * 1000)
                let vm = this
                this.run = true
                if (this.items.length) {
                    if (vm.screenshotSlider != undefined) vm.screenshotSlider.destroy(true, true)
                    vm.screenshotSlider = new Swiper('.screenshot-slider-items-container-' + this.localkey, {
                        speed: 400,
                        slidesPerView: 'auto',
                        spaceBetween: 1,
                        init: true,
                        initialSlide: 0,
                        height: 80,
                        on: {
                            init: function () {
                                vm.run = true
                            }
                        }
                    })
                }
            }
        }
    }
</script>