<template>
    <TabbedModal @setTab="setTab" @close="close" :tabs="tabs" :show="show">
        <div slot="title0">
            {{data.followers | numformat}} Followers
        </div>
        <div slot="title1">
            {{data.following | numformat}} Followings
        </div>
        <div class="flex-h-100" slot="body0">
            <div id="container-of-followers" class="flex-h-100 uk-animation-fade flex-direction-column"
                 ref="followersRef">
                <Items type="VUserItem" :items="followers.items" v-if="followers.items.length"></Items>
                <div v-if="followers.hasMore && this.current===0" v-is-visibility="visibleFollowers"
                     class="uk-text-center flex-1-1">
                    <i uk-spinner></i>
                </div>
                <div class="no-followers-parent" v-if="!followers.hasMore && !followers.items.length">
                    <div class="no-followers">
                        <div>
                            <i class="fal fa-user"></i>
                            <div class="f-s-080">
                                No one follows this user.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1-1" slot="body1">
            <div id="container-of-following" class="flex-h-100 uk-animation-fade flex-direction-column"
                 ref="followingRef">
                <Items type="VUserItem" :items="following.items" v-if="following.items.length"></Items>
                <div v-if="following.hasMore && this.current===1" v-is-visibility="visibleFollowing"
                     class="uk-text-center flex-1-1">
                    <i uk-spinner></i>
                </div>
                <div class="no-followers-parent" v-if="!following.hasMore && !following.items.length">
                    <div class="no-followers">
                        <div>
                            <i class="fal fa-user"></i>
                            <div class="f-s-080">
                                This user follows no one.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TabbedModal>
</template>
<script>
    import PerfectScrollbar from 'perfect-scrollbar';
    import TabbedModal from './TabbedModal'
    import Items from '../Items/Items'
    import axios from 'axios'

    export default {
        components: {
            TabbedModal,
            Items
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            tabs: {
                type: Number,
                default: 5
            },
            current: {
                type: Number,
                default: 0
            },
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                followers: {
                    isLoading: false,
                    items: [],
                    ps: null,
                    hasMore: true
                },
                following: {
                    isLoading: false,
                    items: [],
                    ps: null,
                    hasMore: true
                },
                count: 50
            }
        },
        methods: {
            visibleFollowing(a, b) {
                if (a) {
                    this.getItems('following')
                }
            },
            visibleFollowers(a, b) {
                if (a) {
                    this.getItems('followers')
                }
            },
            recived() {
            },
            setTab(index) {
                this.current = index
                // console.log('index', index)
                if (index === 1 && !this.following.items.length) {
                    this.getItems('following')
                }
            },
            close() {
                this.$emit('close')
                this.show = false
            },
            getItems(type) {
                if (this[type].isLoading) return
                let url = type === 'followers' ? API.getFollowers : API.getFollowing
                this[type].isLoading = true
                let itemsLen = this[type].items.length
                axios.get(url + '?id=' + this.data.id + '&skip=' + itemsLen + '&count=' + this.count)
                    .then(res => {
                        this[type].isLoading = false
                        if (!res.data.length) {
                            this[type].hasMore = false
                            return
                        }
                        if (this[type].items.length) {
                            this[type].items = this[type].items.concat(res.data)
                        } else {
                            this[type].items = res.data
                        }
                        if (res.data.length < this.count) {
                            this[type].hasMore = false
                        }
                        if (itemsLen === 0) {
                            setTimeout(() => {
                                let rect = this.$refs[type + 'Ref'].getBoundingClientRect()
                                this[type].ps = new PerfectScrollbar('#container-of-' + type + '', {
                                    handlers: ['wheel', 'touch'],
                                    wheelSpeed: 1,
                                    wheelPropagation: true,
                                    minScrollbarLength: 20,
                                    suppressScrollX: true
                                })
                            }, 0)
                        } else {
                            this[type].ps.update();
                        }
                    })
            }
        },
        mounted() {

        },
        watch: {
            show(newVal) {
                if (newVal) {
                    this.followers.hasMore = true
                    this.following.hasMore = true
                    this.following.ps = null
                    this.followers.ps = null
                    this.followers.items = []
                    this.following.items = []
                } else {
                    this.current = 0
                }
            },
            current(index) {
            }
        }
    }
</script>