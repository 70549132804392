<template>
  <div
    :class="[
      options.subclass,
      {
        'mobile-hidden':
          options.mobileHidden != undefined && index > options.mobileHidden,
      },
    ]"
  >
    <div class="hb-post-item">
      <div class="hb-post-item-cover" v-if="item.id === undefined">
        <div class="hb-post-item-cover-inner">
          <ImagePlaceholder
            radius="0"
          />
        </div>
      </div>
      <div class="hb-post-item-cover" v-if="item.id !== undefined">
        <div class="hb-post-item-cover-inner">
          <ImagePlaceholder
            radius="0"
            :data-src="item.metas.cover | attach('m')"
          />
          <!--                    <img  class="uk-animation-fade" :src="item.metas.cover.uri | img(300)"/>-->
          <div class="hb-post-item-actions">
            <div class="uk-float-left">
              <span class="hb-post-item-action-play">
                <i @click="play(item)" class="fas fa-play"></i>
              </span>
            </div>
            <div class="uk-float-right">
              <span
                @click="$parent.moreMenu(item, $event)"
                class="hb-post-item-action-ellipsis"
              >
                <i class="fas fa-ellipsis-h"></i>
              </span>
            </div>
          </div>
          <div
            class="hb-post-center"
            v-if="item.postType === 'ALB' || item.postType === 'PLY'"
          >
            <div v-if="item.postType === 'PLY'">
              <div class="png-ico duration"></div>
              <div class="f-s-2">
                {{ item.tracksDuration | minutes }}
              </div>
            </div>
            <div class="f-s-075 tracks-cnt">
              <div v-if="item.postType === 'ALB'" class="png-ico album"></div>
              <div>{{ item.tracks.length }} Tracks</div>
            </div>
          </div>
        </div>
      </div>
      <div class="hb-post-item-details" v-if="item.id !== undefined">
        <div class="hb-post-item-title f-s-090 uk-text-truncate">
          <router-link :to="helper.permalink(item)">{{
            item.title
          }}</router-link>
          <span class="explicit" v-if="item.metas.explicit">🅴</span>
        </div>
        <div class="hb-post-item-artist f-s-080">
          <span class="artist"
            ><router-link :to="item.user.username">{{
              item.user.fullname
            }}</router-link></span
          >
          <span class="teammate" v-for="teammate in item.users"
            >,
            <router-link :to="teammate.username">{{
              teammate.fullname
            }}</router-link></span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import helper from "../../helpers/helper";
import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";

export default {
  data() {
    return {
      helper: helper,
    };
  },
  props: ["item", "index", "options"],
  methods: {
    like(item) {
      this.$parent.like(item);
    },
    repost(item) {
      this.$parent.repost(item);
    },
    play(item) {
      this.$parent.play(item);
    },
  },
  components: {
    ImagePlaceholder,
  },
};
</script>