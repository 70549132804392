var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    { staticClass: "sidebar-menu-ul" },
    _vm._l(_vm.items, function (item) {
      return !item.auth || _vm.auth
        ? _c(
            "li",
            {
              key: item.name,
              staticClass: "f-s-085",
              class: { active: _vm.currentRouteName === item.name },
            },
            [
              _c("router-link", { attrs: { to: item.uri } }, [
                _c("i", { class: item.icon }),
                _vm._v(" "),
                _c("span", [_vm._v(_vm._s(item.label))]),
              ]),
            ],
            1
          )
        : _vm._e()
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }