<template>
  <div :key="username">
    <div class="profile-cover">
      <div class="profile-cover-img">
        <div
          ref="cover"
          :key="coverKey"
          :style="coverStyle"
          :class="{ 'uk-animation-fade': coverLoadedStatus }"
        ></div>
      </div>
      <div class="profile-cover-inner">
        <div class="uk-grid-small uk-flex uk-flex-middle" uk-grid>
          <div class="uk-width-auto@m">
            <div class="profile-cover-container">
              <div class="empty">
                <div class="avatar"></div>
              </div>
              <img
                v-if="user.photos != undefined"
                ref="profileImage"
                @load="profileLoaded"
                id="profileImage"
                @click="openImageGallery"
                class="profile-avatar"
                :class="{ 'uk-animation-fade': profileLoadedStatus }"
                :src="user.photos.avatar | attach('m')"
              />
              <div
                @click="changeProfilePhoto"
                class="change-profile"
                v-if="
                  auth.id != undefined &&
                  user.id != undefined &&
                  auth.id === user.id
                "
              >
                <i class="fal fa-camera"></i>
              </div>
            </div>
          </div>
          <div
            class="
              uk-width-expand@m uk-flex-stretch uk-text-center uk-text-left@m
            "
          >
            <div class="profile-avatar-contents">
              <div class="uk-flex uk-flex-top top-section">
                <div class="role-name f-s-080">{{ user.role }}</div>
                <div
                  class="user-name f-s-13 uk-flex uk-flex-middle"
                  v-is-visibility="
                    (isVisible, entry) =>
                      helper.isVisible(isVisible, entry, user, 'user')
                  "
                >
                  {{ user.fullname }}
                  <i
                    v-if="
                      user !== null && user.is != undefined && user.is.verified
                    "
                    class="verified png-ico small-medium"
                  ></i>
                  <i
                    v-if="user !== null && user.is != undefined && user.is.pro"
                    class="fal fa-gem f-s-14-px pro-icon"
                  ></i>
                </div>
                <div class="user-location" v-if="user.location != null">
                  <i class="far fa-map-marker-alt"></i> {{ user.location }}
                </div>
                <!--<div class="similar-users" v-if="user.similars!=undefined && user.similars.length">-->
                <!--<span class="similars-title">Similar to: </span>-->
                <!--<router-link v-if="index<3" v-for="(similar, index) in user.similars"-->
                <!--:to="'/' + similar.username"> {{similar.fullname}}<span-->
                <!--v-if="index<2&&index+1<user.similars.length"-->
                <!--class="similar-spliter">,</span></router-link>-->
                <!--</div>-->
              </div>
              <div class="uk-flex uk-flex-bottom bottom-section">
                <div class="height-100 uk-flex uk-width-1-1">
                  <div
                    class="
                      uk-flex uk-flex-bottom
                      margin-0-auto@m
                      profile-banner-bottom-container-parent
                    "
                    v-if="user.is !== undefined"
                  >
                    <div class="profile-banner-bottom-container">
                      <div class="user-stats">
                        <div class="user-stats-views">
                          {{ user.stats.views | numberFormat }}
                          <span>Views</span>
                        </div>
                        <div
                          @click="showFollowersModal(0)"
                          class="user-stats-followers"
                        >
                          {{ user.stats.followers | numberFormat }}
                          <span>Followers</span>
                        </div>
                        <div class="user-stats-posts">
                          {{ user.stats.posts | numberFormat }}
                          <span>Posts</span>
                        </div>
                      </div>
                      <div class="profile-buttons uk-flex">
                        <button
                          v-if="realPostsCnt > 0"
                          @click="getShuffle"
                          class="
                            uk-button uk-button-default
                            button-profile button-profile-shuffle
                            btn-spinner
                          "
                        >
                          <i class="fas fa-random"></i>
                          <span
                            class="uk-visible@s"
                            v-if="shuffleStatus === null"
                            >Shuffle</span
                          >
                          <i
                            uk-spinner="ratio: 0.8"
                            v-if="shuffleStatus != null"
                          ></i>
                        </button>
                        <router-link
                          v-if="auth.id === undefined || auth.id != user.id"
                          class="
                            uk-button uk-button-default
                            button-profile
                            profile-button-message
                          "
                          :to="'/chat/' + user.username"
                        >
                          <i class="far fa-envelope"></i>
                          <span class="uk-visible@s">Message</span>
                        </router-link>
                        <button
                          class="
                            uk-button uk-button-default
                            button-profile
                            profile-button-right
                          "
                          v-if="auth.id === undefined || auth.id != user.id"
                          @click="follow()"
                        >
                          <i
                            v-if="!user.is.following"
                            class="fas fa-user-plus"
                          ></i>
                          <i
                            v-if="user.is.following"
                            class="fas fa-user-minus"
                          ></i>
                          {{ user.is.following ? "Unfollow" : "Follow" }}
                        </button>
                        <button
                          @click="clickMoreMenu"
                          class="
                            uk-button uk-button-default
                            button-profile
                            profile-button-ellipsis
                          "
                        >
                          <i class="fas fa-ellipsis-v"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="menu">
        <horizontal-menu-circle
          v-if="currentTab != null && user.username !== undefined"
          @tabChanged="setTab"
          :current="currentTab"
          :tabs="tabs"
        />
      </div>
    </div>
    <FollowersModal
      @close="closeFollowersModal"
      v-if="user.stats != undefined"
      :tabs="2"
      :show="modals.followers.show"
      :data="{
        following: user.stats.following,
        followers: user.stats.followers,
        id: user.id,
      }"
    ></FollowersModal>
    <my-upload
    v-if="isMe"
      field="img"
      @crop-upload-success="cropUploadSuccess('profile', $event)"
      @crop-upload-fail="cropUploadFail('profile', $event)"
      v-model="modals.profile.show"
      :width="600"
      :height="600"
      :fullsize="1200"
      :url="modals.profile.url"
      langType="en"
      :params="modals.profile.params"
      :headers="modals.profile.headers"
      no-square
      :ref="'profileUpload'"
      img-format="jpg"
    ></my-upload>
    <my-upload
    v-if="isMe"
      field="img"
      @crop-upload-success="cropUploadSuccess('cover', $event)"
      @crop-upload-fail="cropUploadFail('cover', $event)"
      v-model="modals.cover.show"
      :width="2480"
      :height="520"
      :fullsize="3500"
      :url="modals.cover.url"
      langType="en"
      :params="modals.cover.params"
      :headers="modals.cover.headers"
      no-square
      no-circle
      :ref="'coverUpload'"
      img-format="jpg"
    ></my-upload>
  </div>
</template>
<script>
import imagesLoaded from "vue-images-loaded";
import axios from "axios";
import helper from "../../helpers/helper";
import ColorThief from "colorthief";
import FollowersModal from "../Modals/FollowersModal";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import { USER_REQUEST } from "@/store/actions/user";
import HorizontalMenuCircle from "@/components/Tools/HorizontalMenuCircle";

export default {
  computed: {
    realPostsCnt() {
      try {
        return this.user.stats.posts - this.user.stats.reposts;
      } catch (e) {
        return 0;
      }
      return 0;
    },
    auth() {
      return this.$store.getters.getProfile;
    },
    isMe() {
      return this.$route.params.username === this.auth.username
    },
    authStatus() {
      return this.$store.getters.getProfileStatus;
    },
  },
  components: { HorizontalMenuCircle, FollowersModal, myUpload },
  data() {
    return {
      emptyCount: 0,
      noPostsYet: false,
      user: {},
      username: this.$route.params.username,
      profileLoadedStatus: false,
      coverLoadedStatus: false,
      profileKey: Math.random(),
      coverKey: Math.random(),
      helper: helper,
      coverStyle: {},
      avatar: null,
      shuffleStatus: null,
      tabs: ["Overview", "Sounds", "Videos", "Albums", "Playlists"],
      currentTab: null,
      store: {},
      modals: {
        followers: {
          show: false,
        },
        profile: {
          show: false,
          url: API.baseURL + API.meUploadImage,
          headers: {
            Authorization: localStorage.getItem("user-token"),
            Accept: "application/json, text/plain, */*",
          },
          params: {
            type: "avatar",
          },
        },
        cover: {
          show: false,
          url: API.baseURL + API.meUploadImage,
          headers: {
            Authorization: localStorage.getItem("user-token"),
            Accept: "application/json, text/plain, */*",
          },
          params: {
            type: "cover",
          },
        },
      },
    };
  },
  directives: {
    imagesLoaded,
  },
  props: ["options", "value"],
  watch: {
    $route: function (route, old) {
      this.emptyCount = 0;
      try {
        let rs = ["profileArtist", "profileSeeAll"];
        this.setDefaultTab();
        if (
          rs.indexOf(route.name) !== -1 &&
          rs.indexOf(old.name) !== -1 &&
          route.params.username === old.params.username
        ) {
          this.getData(true);
          return;
        }
      } catch (e) {}
      this.modals.followers.show = false;
      if (route.name === "profileArtist") {
        this.getData();
      } else {
        this.user = {};
      }
    },
    auth() {
      //        this.getData()
    },
  },
  mounted() {
    this.modals.profile.show = false;
    this.modals.cover.show = false;
    this.emptyCount = 0;
    bus.$on("profileArtistWidgets", ({ username, items }) => {
      if (!items) {
        this.emptyCount += 1;
      }
      if (this.emptyCount >= 5) {
        this.noPostsYet = true;
        bus.$emit("gnoPosts", true);
      } else {
        this.noPostsYet = false;
      }
      //['popularSounds', 'latestReleases', 'recentVideos', 'recentAlbums', 'recentPlaylists']
    });
    if (this.authStatus != "loading") {
      this.getData();
    } else {
      bus.$on("me", () => {
        if (window.selfId === undefined || window.selfId !== this.auth.id) {
          window.selfId = this.auth.id;
          this.getData();
        }
      });
      bus.$on("logout", () => {
        window.selfId = null;
        this.getData();
      });
    }
    bus.$on("profileOpenModal", (e) => {
      this.modals[e].show = true;
    });
    let tabsLowerCase = this.tabs.map((e) => {
      return e.toLowerCase();
    });
    if (
      this.$route.params.type !== undefined &&
      tabsLowerCase.indexOf(this.$route.params.type) !== -1
    ) {
      this.currentTab = tabsLowerCase.indexOf(this.$route.params.type);
    } else {
      this.currentTab = 0;
    }
  },
  beforeDestroy() {
    bus.$off("me");
    bus.$off("logout");
    bus.$off("profileOpenModal");
    bus.$off("profileArtistWidgets");
  },
  methods: {
    setDefaultTab() {
      this.currentTab = -1;
      if (this.$route.params.type !== undefined) {
        let tabsLowerCase = this.tabs.map((e) => {
          return e.toLowerCase();
        });
        for (let i = 0; i < tabsLowerCase.length; i++) {
          if (tabsLowerCase[i] === this.$route.params.type) {
            setTimeout(() => {
              this.currentTab = i;
            }, 100);
          }
        }
      } else {
        setTimeout(() => {
          this.currentTab = 0;
        }, 100);
      }
    },
    setTab(index) {
      this.currentTab = index;
      if (!index) {
        this.$router.push("/" + this.$route.params.username);
      } else if (this.tabs[index] !== undefined) {
        this.$router.push(
          "/" +
            this.$route.params.username +
            "/" +
            this.tabs[index].toLowerCase()
        );
      }
      if (index === 0 && this.noPostsYet) {
        setTimeout(() => {
          bus.$emit("gnoPosts", true);
        }, 200);
      }
    },
    getShuffle() {
      if (this.shuffleStatus === "loading") return;
      this.shuffleStatus = "loading";
      axios
        .get(
          API.posts +
            "?userId=" +
            this.user.id +
            "&count=20&orderBy=random&postType=SND"
        )
        .then((resp) => {
          this.shuffleStatus = null;
          this.$store.dispatch('setCurrent', resp.data[0])
          setTimeout(() => {
              this.$store.dispatch('setPlaylist', resp.data)
          }, 300)
        })
        .catch(() => {
          this.shuffleStatus = null;
        });
    },
    cropUploadSuccess(type, e) {
      let msg = "Your avatar has been uploaded successfully.";
      if (type === "profile") {
        this.user.photos.avatar = e.attachment;
        this.profileLoadedStatus = false;
      } else {
        msg = "Your cover image has been uploaded successfully.";
        this.user.photos.cover = e.attachment;
        this.setCover(e.attachment);
      }
      this.$refs[type + "Upload"].setStep(1);
      this.$store.dispatch(USER_REQUEST);
      this.modals[type].show = false;
      bus.$emit("success", {
        title: "",
        message: msg,
      });
    },
    cropUploadFail(type, e) {
      let msg =
        "Something went wrong while uploading your avatar. Plase try again!";
      if (type === "cover") {
        msg =
          "Something went wrong while uploading your cover image. Plase try again!";
      }
      this.$refs[type + "Upload"].setStep(1);
      this.modals[type].show = false;
      bus.$emit("error", {
        title: "",
        message: msg,
      });
    },
    changeProfilePhoto() {
      this.modals.profile.show = true;
    },
    clickMoreMenu(e) {
      let item = this.user;
      bus.$emit("clickMoreMenu", item, e, true);
    },
    closeFollowersModal() {
      this.modals.followers.show = false;
    },
    showFollowersModal(currentTab) {
      this.modals.followers.show = true;
    },
    profileLoaded() {
      this.profileLoadedStatus = true;
    },
    follow() {
      let url;
      if (this.user.is.following) {
        url = API.action.unfollow;
      } else {
        url = API.action.follow;
      }
      if (this.auth.id === undefined) {
        this.$parent.showLogin = true;
      } else {
        this.user.stats.followers = this.user.is.following
          ? this.user.stats.followers - 1
          : this.user.stats.followers + 1;
        this.user.is.following = !this.user.is.following;
        if (this.followRequestManager != null)
          this.followRequestManager.cancel();
        let CancelToken = axios.CancelToken;
        this.followRequestManager = CancelToken.source();
        axios
          .get(url + this.user.id + "/?rand=" + Math.random(), {
            cancelToken: this.followRequestManager.token,
          })
          .then(() => {});
      }
    },
    imageProgress(instance, image) {
      const result = image.isLoaded ? "loaded" : "broken";
    },
    getMainColor(url) {
      //PaOBkPqUKD2V8hqWUi_300.jpg
      //        const result = await analyze(url, { ignore: [ 'rgb(255,255,255)', 'rgb(0,0,0)' ], scale: 0.6 }) // also supports base64 encoded image strings
      //        console.log(`The dominant color is ${result[0].color} with ${result[0].count} occurrence(s)`)
    },
    openImageGallery() {
      if (!this.profileLoadedStatus) return;
      let cv = document.querySelector(
        '.user-image-gallery-widget .uk-grid img[src*="' + this.avatar + '"]'
      );
      if (cv === null) {
        if (document.querySelector("#openGalleryImportant") !== null) {
          document.querySelector("#openGalleryImportant").click();
        }
      } else {
        cv.click();
      }
    },
    defaultData() {
      this.coverStyle = {};
      this.user = {
        fullname: "",
        photos: {
          avatar: {
            uri: "",
          },
          cover: {
            uri: "",
          },
        },
      };
    },
    getData(type) {
      if (
        this.store.username !== undefined &&
        this.store.username === this.$route.params.username
      ) {
        this.value = {
          username: this.store.username,
          fullname: this.store.fullname,
          id: this.store.id,
        };
        // this.$emit('update', this.value)
        this.store.emit.map((e, index) => {
          if (this.$route.params.type !== undefined) {
            if (
              ["gmetas", "gphotos", "grecentLikes", "grecentReposts"].indexOf(
                e.name
              ) !== -1
            ) {
              setTimeout(() => {
                bus.$emit(e.name, e.value);
              }, 200);
            }
          } else {
            setTimeout(() => {
              bus.$emit(e.name, e.value);
            }, 200);
          }
        });
        return;
      }
      this.noPostsYet = false;
      this.profileLoadedStatus = false;
      // this.$emit('update', {})
      this.value = {};

      let URL = window.URL;
      this.defaultData();
      let vm = this;
      axios
        .get(
          API.users +
            "?username=" +
            this.$route.params.username +
            "&withLikes=true&withReposts=true"
        )
        .then((res) => {
          vm.user = res.data;
          //const profileImg = res.data.photos.avatar.uri
          vm.username = vm.user.username;
          //if(vm.user.photos.cover!=null) {
          // vm.value = vm.user
          vm.$emit("update", {
            username: vm.user.username,
            fullname: vm.user.fullname,
            id: vm.user.id,
          });
          this.store = {
            username: vm.user.username,
            fullname: vm.user.fullname,
            id: vm.user.id,
            emit: [],
          };
          this.modals.profile.params.userId = this.user.id;
          this.modals.cover.params.userId = this.user.id;
          this.setDefaultTab();
          let gnoPosts = true;
          let passedData = [
            "metas",
            "photos",
            "recentLikes",
            "popularSounds",
            "latestReleases",
            "recentReposts",
            "recentVideos",
            "recentAlbums",
            "recentPlaylists",
            "similars",
          ];
          let checkData = [
            "popularSounds",
            "latestReleases",
            "recentVideos",
            "recentAlbums",
            "recentPlaylists",
          ];
          for (let i = 0; i < passedData.length; i++) {
            // delay for compile profile
            // setTimeout(() => {
            //   if (
            //     checkData.indexOf(passedData[i]) !== -1 &&
            //     res.data[passedData[i]] !== undefined &&
            //     res.data[passedData[i]].length
            //   ) {
            //     gnoPosts = false;
            //   }
              this.store.emit.push({
                name: this.$route.params.username + "g" + passedData[i],
                value: res.data[passedData[i]],
              });
              if (
                localStorage.getItem("autoInitializePlayer") !== null &&
                passedData[i] === "latestReleases" &&
                res.data[passedData[i]] !== undefined &&
                res.data[passedData[i]].length
              ) {
                bus.$emit(
                  "managePlaylist",
                  res.data[passedData[i]],
                  res.data[passedData[i]][0],
                  true
                );
              }
              bus.$emit(
                "g" + passedData[i],
                res.data[passedData[i]]
              );
              if (passedData.length - 1 === i && gnoPosts) {
                // no post handler
                // this.noPostsYet = true
                // bus.$emit(this.$route.params.username + 'gnoPosts', true)
              }
            // }, i * 200);
          }
          this.setCover(vm.user.photos.cover);
        })
        .catch((e) => {
          try {
            switch (e.response.status) {
              case 404:
                this.$router.push("/404");
            }
          } catch (e) {
            setTimeout(() => {
              this.getData();
            }, 2000);
          }
        });
    },
    setCover(cv) {
      let vm = this;
      vm.coverLoadedStatus = false;
      let xhr = new XMLHttpRequest();
      let coverUrl = this.$options.filters.attach(cv, "l", "profileCover");
      xhr.open("get", coverUrl, true);
      xhr.responseType = "blob";
      xhr.onload = function (res) {
        let ur_l = URL.createObjectURL(this.response);
        vm.coverStyle = { "background-image": "url(" + ur_l + ")" };
        vm.coverLoadedStatus = true;
        vm.coverKey = Math.random();
      };
      xhr.send();
    },
  },
};
</script>