import axios from 'axios'
import {openDB, deleteDB, wrap, unwrap, DBSchema} from 'idb';

var viewedByUser = []
var viewedByUserTotally = []
var promotedViewedByUser = []
var promotedViewedByUserTotally = []
const secondsToView = 10
let viewedByUserInterval = null
const _ = require("underscore")
export default {
    mbdInit() {
        if(window.mbdIntetval !== undefined) {
            clearInterval(window.mbdIntetval)
        }
        this.mbdCheck()
        window.mbdIntetval = setInterval(() => {
            this.mbdCheck()
        }, 60000)
    },
    mbdCheck() {
        const name = 'mbd', storage = window.localStorage
        let mbd = storage.getItem(name)
        if (mbd === null) {
            mbd = []
        } else {
            mbd = JSON.parse(mbd)
        }
        if(mbd.length > 0) {
            for (let i = 0; i < mbd.length; i++) {
                if (mbd[i].expires < new Date().getTime()) {
                    const deleted = mbd.splice(i, 1)
                    const keys = Object.keys(localStorage)
                    for (let i = 0; i < keys.length; i++) {
                        if (keys[i].indexOf(deleted[0].id) !== -1) {
                            localStorage.removeItem(keys[i])
                        }
                    }
                    i--
                }
            }
        }
        storage.setItem(name, JSON.stringify(mbd))
    },
    mbdAdd(id) {
        this.mbdRemove(id, true)
        const name = 'mbd', storage = window.localStorage, expireTime = 1000 * 60 * 60 * 24
        let mbd = storage.getItem(name)
        if (mbd === null) {
            mbd = []
        } else {
            mbd = JSON.parse(mbd)
        }

        // for current session
        setTimeout(() => {
            this.mbdRemove(id)
        }, expireTime)

        mbd.push({
            'id': id,
            expires: new Date().getTime() + (expireTime)
        })
        storage.setItem(name, JSON.stringify(mbd))
    },
    mbdRemove(id, doNotRemoveFromStorage) {
        doNotRemoveFromStorage = doNotRemoveFromStorage || false
        const name = 'mbd', storage = window.localStorage
        let mdb = storage.getItem(name)
        if (mdb !== null) {
            mdb = JSON.parse(mdb)
            mdb = _.without(mdb, _.findWhere(mdb, {id: id}))
            storage.setItem(name, JSON.stringify(mdb))
            if(!doNotRemoveFromStorage) {
                const keys = Object.keys(localStorage)
                for (let i = 0; i < keys.length; i++) {
                    if (keys[i].indexOf(id) !== -1) {
                        localStorage.removeItem(keys[i])
                    }
                }
            }
        }
    },
    database() {
        window.indexedDB = window.indexedDB || window.mozIndexedDB || window.webkitIndexedDB || window.msIndexedDB;
        if (!window.indexedDB) {
            return window.indexedDB
        }
        return false
    },
    openWindow(url, title) {
        let w = 400
        let h = 500
        let left = (screen.width / 2) - (w / 2)
        let top = (screen.height / 2) - (h / 2)
        return window.open(url, title, 'toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,resizable=no,copyhistory=no,width=' + w + ',left=' + left + ',height=' + h + ',top=' + top)
    },
    db(type, table, data) {
        let db = this.database()
        switch (type) {
            case 'insert':
                let request = db.transaction([table], "readwrite").objectStore(table).add(data)
                break
            case 'select':
                break
        }
    },
    ln2br(value, is_xhtml) {
        is_xhtml = is_xhtml || true
        if (typeof value === 'undefined' || value === null) {
            return '';
        }
        var breakTag = (!is_xhtml) ? '<br />' : '<br>';
        return (value + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    },
    async getTexts(postId) {
        if (postId === undefined) return
        let data = false
        try {
            data = await this.getData('texts', 'post_id', postId)
        } catch (e) {
        }
        if (data === undefined || data === false) {
            try {
                let getted = null
                try {
                    getted = await this.asyncAxios(API.baseURL + API.postTexts + '?id=' + postId)
                } catch (e) {
                }

                if (getted.lyrics !== undefined && getted.lyrics !== null) {
                    try {
                        await this.putData('texts', getted)
                    } catch (e) {
                    }
                }
                if (getted.lyrics === null) {
                    getted.lyrics = ''
                }
                return getted
            } catch (e) {
                return false
            }
        }
        return data
    },
    getData(table, key, val) {
        return new Promise(async (resolve, reject) => {
            let db = await openDB('VOWAVE', 1, {
                upgrade(db) {
                    let store = db.createObjectStore(table, {
                        keyPath: 'id',
                        autoIncrement: true,
                    })
                    store.createIndex('post_id', 'post_id');
                }
            })
            try {
                let value = await db.getFromIndex(table, key, val)
                resolve(value);
            } catch (e) {
                reject(false)
            }
        })
    },
    asyncAxios(url) {
        return new Promise(async (resolve, reject) => {
            try {
                let geted = await axios.get(url)
                resolve(geted.data);
            } catch (e) {
                reject(false)
            }
        })
    },
    putData(table, data) {
        return new Promise(async (resolve, reject) => {
            try {
                let db = await openDB('VOWAVE', 1, {
                    upgrade(db) {
                        let store = db.createObjectStore(table, {
                            keyPath: 'id',
                            autoIncrement: true,
                        })
                        store.createIndex('post_id', 'post_id');
                    }
                })
                await db.add(table, data)
                resolve(true)
            } catch (e) {
                reject(false)
            }
        })


    },
    can(see, item) {
        let user = this.currentUser()
        switch (see) {
            case 'play':
            case 'download':
                if (user !== null && (item.user.id === user.id || user.role === 'owner' || user.role === 'developer' || user.role === 'partner')) {
                    return true
                }
                break
        }
        return false
    },
    numberFormat(number) {
        return number
    },
    permalink(item) {
        let postTypes = {ALB: 'album', SND: 'sound', PLY: 'playlist', VID: 'video', PST: 'post'}
        if (item.postType != undefined) {
            return '/' + item.user.username + (item.postType === 'SND' ? '/' : '/' + postTypes[item.postType] + '/') + item.slug
        } else if (item.username != undefined) {
            return '/' + item.username
        }
    },
    currentUser() {
        let user = localStorage.getItem('user')
        if (user !== null) {
            return JSON.parse(user)
        }
        return null
    },
    isVisible(isVisible, entry, item, type) {
        let itemId = (item.username != undefined) ? item.username : item.realId
        let viewType = (item.renderReason === undefined) ? 'auto' : item.renderReason
        if (itemId === undefined) return
        itemId = {
            id: itemId,
            renderReason: viewType
        }

        let indexOf = _.findIndex(viewedByUserTotally, function (itm) {
            return (itm.id === itemId.id && itm.renderReason === itemId.renderReason)
        })
        if (isVisible && indexOf === -1) {
            viewedByUser.push(itemId)
            viewedByUserTotally.push(itemId)
            if (viewedByUserInterval === null) {
                viewedByUserInterval = setInterval(() => {
                    if (viewedByUser.length) {
                        this.sendEvent('stats', viewedByUser)
                        viewedByUser = []
                    }
                }, secondsToView * 1000)
            }
        }
    },
    sendEvent(type, data) {
        let event = {}
        event[type] = data
        axios.post(API.events, event)
            .then(() => {

            })
    },
    sendEvents(events) {
        let event = {}
        axios.post(API.events, events)
            .then(() => {

            })
    },
    isLogin() {
        return localStorage.getItem('user-token') === null ? false : true
    },
    timestamp() {
        return Math.floor(new Date().getTime() / 1000)
    },
    removeElements(elms) {
        elms.forEach(el => el.remove())
    },
    moreMenu(item) {
        if (item.showMore === undefined) item.showMore = false
        item.showMore = !item.showMore
    },
    b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, {type: contentType});
        return blob;
    },
    base64ArrayBuffer(arrayBuffer) {
        var base64 = ''
        var encodings = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/'

        var bytes = new Uint8Array(arrayBuffer)
        var byteLength = bytes.byteLength
        var byteRemainder = byteLength % 3
        var mainLength = byteLength - byteRemainder

        var a, b, c, d
        var chunk

        // Main loop deals with bytes in chunks of 3
        for (var i = 0; i < mainLength; i = i + 3) {
            // Combine the three bytes into a single integer
            chunk = (bytes[i] << 16) | (bytes[i + 1] << 8) | bytes[i + 2]

            // Use bitmasks to extract 6-bit segments from the triplet
            a = (chunk & 16515072) >> 18 // 16515072 = (2^6 - 1) << 18
            b = (chunk & 258048) >> 12 // 258048   = (2^6 - 1) << 12
            c = (chunk & 4032) >> 6 // 4032     = (2^6 - 1) << 6
            d = chunk & 63               // 63       = 2^6 - 1

            // Convert the raw binary segments to the appropriate ASCII encoding
            base64 += encodings[a] + encodings[b] + encodings[c] + encodings[d]
        }

        // Deal with the remaining bytes and padding
        if (byteRemainder == 1) {
            chunk = bytes[mainLength]

            a = (chunk & 252) >> 2 // 252 = (2^6 - 1) << 2

            // Set the 4 least significant bits to zero
            b = (chunk & 3) << 4 // 3   = 2^2 - 1

            base64 += encodings[a] + encodings[b] + '=='
        } else if (byteRemainder == 2) {
            chunk = (bytes[mainLength] << 8) | bytes[mainLength + 1]

            a = (chunk & 64512) >> 10 // 64512 = (2^6 - 1) << 10
            b = (chunk & 1008) >> 4 // 1008  = (2^6 - 1) << 4

            // Set the 2 least significant bits to zero
            c = (chunk & 15) << 2 // 15    = 2^4 - 1

            base64 += encodings[a] + encodings[b] + encodings[c] + '='
        }

        return base64
    },
    repost: function (post) {
        let url
        if (post.is.reposted) {
            url = API.action.unrepost
        } else {
            url = API.action.repost
        }
        if (!this.isLogin()) {
            return null
        } else {
            post.stats.reposts = (post.is.reposted) ? post.stats.reposts - 1 : post.stats.reposts + 1
            post.is.reposted = !post.is.reposted
            if (window['repost' + post.realId] != null)
                window['repost' + post.realId].cancel()
            let CancelToken = axios.CancelToken
            window['repost' + post.realId] = CancelToken.source()
            axios.get(url + post.realId /*+ '/?rand=' + Math.random()*/, {cancelToken: window['repost' + post.realId].token})
                .then(() => {

                })
            return true
        }
    },
    like: function (post) {
        let url
        if (post.is.liked) {
            url = API.action.unlike
        } else {
            url = API.action.like
        }
        if (!this.isLogin()) {
            return null
        } else {
            post.stats.likes = (post.is.liked) ? post.stats.likes - 1 : post.stats.likes + 1
            post.is.liked = !post.is.liked
            if (window['like' + post.realId] != null)
                window['like' + post.realId].cancel()
            let CancelToken = axios.CancelToken
            window['like' + post.realId] = CancelToken.source()
            axios.get(url + post.realId /*+ '/?rand=' + Math.random()*/, {cancelToken: window['like' + post.realId].token})
                .then(() => {

                })
            return true
        }
    },
    follow: function (user) {
        let url
        if (user.is.following) {
            url = API.action.unfollow
        } else {
            url = API.action.follow
        }
        if (!this.isLogin()) {
            bus.$emit('showLogin')
        } else {
            user.stats.followers = (user.is.following) ? user.stats.followers - 1 : user.stats.followers + 1
            user.is.following = !user.is.following
            if (window.followRequestManager != null)
                window.followRequestManager.cancel()
            let CancelToken = axios.CancelToken
            window.followRequestManager = CancelToken.source()
            axios.get(url + user.id + '/?rand=' + Math.random(), {cancelToken: window.followRequestManager.token})
                .then(() => {
                })
        }
    },
    image_url: function (attachment, size) {
        if (attachment === null) return ''
        return attachment.uri + '_' + size + '.jpg'
    },
    get_screenshots(attachment, size) {
        if (size === undefined) size = 445
        let res = []
        let splitted = attachment.split('/')
        splitted.splice(4, 2)
        splitted = splitted.join('/').replace('videos', 'screenshots')
        for (let i = 0; i < 6; i++) {
            res.push(splitted + '/screenshot' + i + '_' + size + '.jpg')
        }
        return res
    },
    blob_images: function (arr) {
        let res = []
        if (!Array.isArray(arr)) {
            arr = [arr]
        }
        for (var i = 0; i < arr.length; i++) {

        }
    },
    checkImage: function (path) {
        return new Promise(resolve => {
            const img = new Image();
            img.onload = () => resolve({path, status: 'ok'});
            img.onerror = () => resolve({path, status: 'error'});

            img.src = path;
        })
    },
    loadImg: function (paths) {
        return Promise.all(paths.map(this.checkImage))
    },
    loadImage: async function (image) {
        const data = await axios.get("/some_url_endpoint");
        return data;
    },
    loadImages: async function (images) {
        let tmp = []
        if (images.length) {
            for (let i = 0; i < images.length; i++) {
                tmp.push(await axios.get(images[i]))
            }
        }
        return tmp
    },
    getAverageRGB: function (imgEl) {
        var blockSize = 5, // only visit every 5 pixels
            defaultRGB = {r: 0, g: 0, b: 0}, // for non-supporting envs
            canvas = document.createElement('canvas'),
            context = canvas.getContext && canvas.getContext('2d'),
            data, width, height,
            i = -4,
            length,
            rgb = {r: 0, g: 0, b: 0},
            count = 0;
        if (!context) {
            return defaultRGB;
        }
        height = canvas.height = imgEl.naturalHeight || imgEl.offsetHeight || imgEl.height;
        width = canvas.width = imgEl.naturalWidth || imgEl.offsetWidth || imgEl.width;
        context.drawImage(imgEl, 0, 0);
        try {
            data = context.getImageData(0, 0, width, height);
        } catch (e) {
            return defaultRGB;
        }
        length = data.data.length;
        while ((i += blockSize * 4) < length) {
            ++count;
            rgb.r += data.data[i];
            rgb.g += data.data[i + 1];
            rgb.b += data.data[i + 2];
        }
        // ~~ used to floor values
        rgb.r = ~~(rgb.r / count);
        rgb.g = ~~(rgb.g / count);
        rgb.b = ~~(rgb.b / count);
        return rgb;
    }
}