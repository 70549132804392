var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "uk-text-center padding-top-50" }, [
    _c("div", [_c("i", { staticClass: "f-s-4", class: _vm.icon })]),
    _vm._v(" "),
    _c("div", { staticClass: "f-f-reg padding-y-20" }, [
      _vm._v("\n        " + _vm._s(_vm.text) + "\n    "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }