var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.active
      ? _c("div", { staticClass: "mobile-player" }, [
          _c("div", { staticClass: "uk-grid uk-grid-5" }, [
            _c(
              "div",
              { staticClass: "uk-width-auto" },
              [
                _c("image-placeholder", {
                  attrs: {
                    width: "60px",
                    "data-src": _vm._f("attach")(_vm.current.metas.cover, "xs"),
                    radius: "0",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-width-expand uk-flex uk-flex-middle" },
              [
                _c(
                  "div",
                  { staticClass: "mobile-player-info uk-text-truncate" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "title uk-text-truncate f-s-085",
                        attrs: { to: _vm.helper.permalink(_vm.current) },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.current.title) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-clearfix" }),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "artist uk-text-truncate f-s-075",
                        attrs: { to: _vm.helper.permalink(_vm.current.user) },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.current.user.fullname) +
                            "\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "uk-width-auto uk-flex uk-flex-middle" }, [
              _c("div", { staticClass: "mobile-player-actions" }, [
                _c("span", [
                  _c("i", {
                    staticClass: "fas fa-backward",
                    on: {
                      click: function ($event) {
                        return _vm.prev()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", [
                  _vm.playerStatus === "pause"
                    ? _c("i", {
                        staticClass: "fas fa-play",
                        on: {
                          click: function ($event) {
                            return _vm.play()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.playerStatus === "play"
                    ? _c("i", {
                        staticClass: "fas fa-pause",
                        on: {
                          click: function ($event) {
                            return _vm.pause()
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.playerStatus === "wait"
                    ? _c("i", { staticClass: "fas fa-spinner-third fa-spin" })
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("span", [
                  _c("i", {
                    staticClass: "fas fa-forward",
                    on: {
                      click: function ($event) {
                        return _vm.next()
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("span", [
                  _c("i", {
                    staticClass: "fas fa-bars",
                    on: {
                      click: function ($event) {
                        _vm.fullPlayer = true
                      },
                    },
                  }),
                ]),
              ]),
            ]),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "full-mobile-player-layer",
        on: {
          click: function ($event) {
            _vm.fullPlayer = false
          },
        },
      },
      [_c("span", { staticClass: "f-s-25 f-f-reg" }, [_vm._v("﹀")])]
    ),
    _vm._v(" "),
    _vm.fullPlayer
      ? _c(
          "div",
          {
            ref: "fmp",
            staticClass: "full-mobile-player",
            class: { active: _vm.fullPlayer },
          },
          [
            _c(
              "perfect-scrollbar",
              {
                ref: "scrollbar",
                staticClass: "full-mobile-player-container",
                attrs: { id: "fmpc" },
              },
              [
                _vm.playlist.length
                  ? _c(
                      "ul",
                      { ref: "nextUpUl", staticClass: "vertical-audio-list" },
                      _vm._l(_vm.playlist, function (item) {
                        return _c(
                          "li",
                          {
                            key: item.realId,
                            attrs: {
                              "data-id": item.realId,
                              "data-active":
                                item.realId === _vm.current.realId ? "1" : "0",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.playPlaylist(item)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "uk-grid-small uk-flex uk-flex-middle vertical-audio-list-li",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c("div", { staticClass: "uk-width-auto" }, [
                                  _c(
                                    "div",
                                    { staticClass: "vertical-audio-img" },
                                    [
                                      _c("img", {
                                        attrs: {
                                          src: _vm._f("attach")(
                                            item.metas.cover,
                                            "xs"
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-width-expand vertical-audio-txt",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "uk-grid-small",
                                        attrs: { "uk-grid": "" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "uk-width-expand" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vertical-audio-top-txt f-s-075 uk-text-truncate",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(item.title) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vertical-audio-btm-txt f-s-075 uk-text-truncate",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(item.user.fullname) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "uk-width-auto" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "vertical-audio-more",
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "vertical-audio-more-item vertical-audio-more-item-duration f-s-070",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            " +
                                                        _vm._s(
                                                          _vm._f("minutes")(
                                                            item.metas
                                                              .attachment
                                                              .duration
                                                          )
                                                        ) +
                                                        "\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }