<template>
    <ul class="horizontal-tabs" ref="tabs">
        <li v-for="(tab, index) in tabs" ref="tab" @click="setTab(index)" :class="{active: (current===index)}">
            {{tab}}
        </li>
        <div ref="circle" class="horizontal-tabs-circle" :style="{left: circleLeft}"></div>
    </ul>
</template>
<script>
    export default {
        props: {
            tabs: {
                type: Array,
                default: () => {
                    return []
                }
            },
            current: {
                type: Number,
                default: -1
            }
        },
        data() {
            return {
                circleLeft: 0
            }
        },
        methods: {
            setTab(tabIndex) {
                this.$emit('tabChanged', tabIndex)
            }
        },
        watch: {
            current: function(tabIndex) {
                let circle = this.$refs.circle.getBoundingClientRect()
                let mcr = this.$refs.tabs.getBoundingClientRect();
                if(this.$refs.tab[tabIndex]===undefined) return;
                let bcr = this.$refs.tab[tabIndex].getBoundingClientRect();
                let margin = this.$refs.tabs.scrollLeft + bcr.x - mcr.x
                let pos = margin + (bcr.width/2)
                this.circleLeft = pos - (circle.width/2) + 'px'
            }
        },
        mounted() {
            this.setTab(this.current)
        }
    }
</script>