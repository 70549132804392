var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length && _vm.items[0].username !== undefined
    ? _c(
        "div",
        {
          ref: "mainContainer",
          staticClass: "widget padding-x user-list-items",
        },
        [
          _c("div", [
            _vm.options.title != undefined
              ? _c("div", { staticClass: "widget-title" }, [
                  _c("h4", { staticClass: "f-s-13" }, [
                    _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
                  ]),
                  _vm._v(" "),
                  _c("h6", { staticClass: "f-s-090" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("lang")(_vm.options.subtitle, {
                            artist:
                              _vm.value != undefined &&
                              _vm.value.fullname != undefined
                                ? _vm.value.fullname
                                : "artist",
                          })
                        ) +
                        "\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "widget-content user-slider-widget" }, [
              _c("div", { staticClass: "user-slider" }, [
                _c(
                  "div",
                  {
                    ref: "userSliderContents",
                    staticClass: "user-slider-items",
                  },
                  [
                    _c(
                      "flicking",
                      {
                        attrs: {
                          options: {
                            renderOnlyVisible: false,
                            align: "prev",
                            preventClickOnDrag: true,
                            bound: true,
                            circularFallback: "bound",
                          },
                        },
                      },
                      _vm._l(_vm.items, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "flicking-panel" },
                          [
                            _c("CircleUserItem", {
                              attrs: {
                                options: { subclass: "user-slider-item" },
                                item: item,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.isLoading
                ? _c("div", { staticClass: "uk-text-center" }, [
                    _c("i", { attrs: { "uk-spinner": "" } }),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }