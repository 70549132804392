var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length > 0
    ? _c("div", { ref: "mainContainer", staticClass: "widget padding-x" }, [
        _c("div", [
          _c("div", { staticClass: "widget-title" }, [
            _c(
              "div",
              {
                staticClass: "uk-flex uk-flex-middle",
                attrs: { "uk-grid": "" },
              },
              [
                _c("div", { staticClass: "uk-width-expand" }, [
                  _c("h4", { staticClass: "f-s-13" }, [
                    _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
                  ]),
                  _vm._v(" "),
                  _c("h6", { staticClass: "f-s-090" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("lang")(_vm.options.subtitle, {
                          artist: _vm.value && _vm.value.fullname,
                        })
                      )
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _vm.$route.params.username !== undefined
                  ? _c(
                      "div",
                      { staticClass: "uk-width-auto" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "see-all-right",
                            attrs: {
                              to: "/" + _vm.$route.params.username + "/sounds",
                            },
                          },
                          [_vm._v("See all")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget-content sound-slider-widget" }, [
            _c("div", { staticClass: "sound-slider" }, [
              _c(
                "div",
                {
                  staticClass: "sound-slider-items",
                  style: { height: _vm.height },
                },
                [
                  _c(
                    "flicking",
                    {
                      attrs: {
                        options: {
                          renderOnlyVisible: false,
                          align: "prev",
                          preventClickOnDrag: true,
                          bound: true,
                          circularFallback: "bound",
                        },
                      },
                    },
                    _vm._l(_vm.items, function (item, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "flicking-panel" },
                        [
                          _c("SoundSliderItem", {
                            class: {
                              active:
                                item.realId != undefined &&
                                (_vm.activeItem === item.realId ||
                                  (item.postType === "SND" &&
                                    _vm.currentTrack != null &&
                                    _vm.currentTrack == item.realId) ||
                                  (item.postType === "VID" &&
                                    _vm.currentVideo != null &&
                                    _vm.currentVideo == item.realId)),
                              deleting: _vm.deletings.indexOf(item.id) !== -1,
                            },
                            attrs: { item: item, options: { showType: false } },
                            on: { dop: _vm.dop, moreMenu: _vm.moreMenu },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ],
                1
              ),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }