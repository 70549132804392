var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mainContainer",
      staticClass: "widget padding-x uk-margin-small-top",
    },
    [
      _c("div", [
        _c("div", { staticClass: "widget-title" }, [
          _c("h4", { staticClass: "f-s-13" }, [
            _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
          ]),
          _vm._v(" "),
          _c("h6", { staticClass: "f-s-090" }, [
            _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content user-slider-widget" }, [
          _c(
            "div",
            { staticClass: "user-slider" },
            [
              _c(
                "flicking",
                {
                  attrs: {
                    options: {
                      renderOnlyVisible: false,
                      align: "prev",
                      preventClickOnDrag: true,
                      bound: true,
                      circularFallback: "bound",
                    },
                  },
                },
                _vm._l(_vm.items, function (item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "flicking-panel" },
                    [_c("UserWithoutButtonItem", { attrs: { item: item } })],
                    1
                  )
                }),
                0
              ),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }