<template>
  <div
    class="video-slider-item"
    :class="options.subclass"
    @mouseover="mouseOver()"
    @mouseleave="mouseLeave()"
  >
    <div v-if="item.id !== undefined" class="video-slider-item-inner">
      <div
        class="video-slider-item-cover"
        :class="{ 'screenshots-in-load': !screenshotLoaded }"
      >
        <div @click="closeSearch" class="video-slider-item-cover-inner">
          <!--<div class="cover-placeholder" v-show="!item.imageLoaded"></div>-->
          <router-link :to="'/' + item.user.username + '/video/' + item.slug">
            <div class="video-slider-screenshots" ref="coverInner">
              <div
                class="video-slider-screenshot"
                v-for="(screenshot, scindex) in screenshots"
                :key="scindex"
                :style="{ zIndex: scindex === 0 ? 0 : scindex }"
                :class="{ 'active-sc': activeScreenshot >= scindex }"
              >
                <!--v-if="item.activeScreenshot===scindex || item.prevScreenshot===scindex"-->
                <img @load="screenshotLoadedIndex(scindex)" :src="screenshot" />
              </div>
              <div class="video-slider-duration f-s-070">
                {{ item.metas.attachment.duration | minutes }}
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <div class="video-slider-item-details">
        <div class="uk-grid uk-grid-10 align-items-center">
          <div class="uk-width-auto">
            <router-link
                :to="'/' + item.user.username"
                class="opacity-hover"
                >
            <image-placeholder
              :radius="'50%'"
              width="30px"
              height="30px"
              :data-src="item.user.photos.avatar | attach('xs')"
            />
            </router-link>
          </div>
          <div class="uk-width-expand uk-text-truncate">
            <div
              @click="closeSearch"
              class="video-slider-item-title f-s-090 uk-text-truncate"
            >
              <router-link
                :to="'/' + item.user.username + '/video/' + item.slug"
                >{{ item.title }}</router-link
              >
              <span class="explicit" v-if="item.metas.explicit">🅴</span>
            </div>
            <div @click="closeSearch" class="video-slider-item-artist f-s-080">
              <span class="artist"
                ><router-link :to="'/' + item.user.username">{{
                  item.user.fullname
                }}</router-link></span
              >
              <span
                class="teammate"
                v-for="(teammate, idx) in item.users"
                :key="idx"
                >,
                <router-link :to="'/' + teammate.username">{{
                  teammate.fullname
                }}</router-link></span
              >
            </div>
          </div>
          <div class="uk-width-auto">
            <span
              @click="moreMenu(item, $event)"
              class="
                v-post-item-ellipsis
                disable-click
                f-s-15
                disable-click
                more-menu-parent
              "
            >
              <i class="fal fa-ellipsis-h disable-click"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ImagePlaceholder from "../Tools/ImagePlaceholder.vue";
export default {
  components: { ImagePlaceholder },
  data() {
    return {
      activeScreenshot: 0,
      screenshotLoaded: false,
    };
  },
  props: {
    item: {},
    index: {},
    options: {
      type: Object,
      default: () => {
        return {
          subclass: "",
        };
      },
    },
    subclass: {
      type: String,
      default: "",
    },
  },
  methods: {
    moreMenu(item, e) {
      bus.$emit("clickMoreMenu", item, e, "VideoSliderItem");
    },
    screenshotLoadedIndex(index) {
      if (index === 0) {
        this.screenshotLoaded = true;
      }
    },
    mouseOver() {
      if (window.actScIntval != undefined) {
        clearInterval(window.actScIntval);
      }
      window.actScIntval = setInterval(() => {
        this.activeScreenshot += 1;
        if (this.activeScreenshot > 7) {
          this.activeScreenshot = 0;
        }
      }, 700);
    },
    mouseLeave() {
      if (window.actScIntval != undefined) {
        clearInterval(window.actScIntval);
      }
    },
    closeSearch() {
      bus.$emit("closeSearch");
    },
  },
  computed: {
    scs() {
      return this.$store.getters.settings.imagesSize.screenshot.s;
    },
    screenshots() {
      let screenshots = [];
      if (this.item === undefined || this.item.metas === undefined) return [];
      let screenshotsUri = this.item.metas.attachment.screenshotsUri;
      for (let i = 0; i < 15; i++) {
        screenshots.push(
          screenshotsUri.replace("{scidx}", i).replace("{w}", this.scs.w)
        );
      }
      return screenshots;
    },
  },
};
</script>