<template>
    <div class="widget padding-x">
        <div class="widget-content">
            <div>
                <Items :items="items" type="VPostMedItem"/>
                <div v-if="!end" class="uk-margin-top uk-text-center">
                    <i v-is-visibility="(isVisible) => visibleMore(isVisible)" uk-spinner></i>
                </div>
                <Empty v-if="end && !items.length"></Empty>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import Items from '../Items/Items'
    import Empty from "@/components/Tools/Empty";

    export default {
        components: {
            Empty,
            Items
        },
        data() {
            return {
                inProcess: false,
                request: {},
                lastId: null,
                end: false,
                items: [],
                count: 10,
            }
        },
        mounted() {
            if(this.$route.params.type!=undefined) {
                this.request.postType = (this.$route.params.type==='video') ? 'video' : 'sound'
            }
            bus.$on('chartFilter', (res) => {
                this.end = false
                this.items = []
                this.lastId = null
                this.request = res
            })
        },
        computed: {
            auth() {
                return this.$store.getters.getProfile
            }
        },
        watch: {
            auth() {
                this.items = []
                this.end = false
                this.getData()
            }
        },
        methods: {
            visibleMore(isVisible) {
                if(isVisible) {
                    if(this.items.length) {
                        this.lastId = this.items[this.items.length - 1].realId
                    }
                    this.getData()
                }
            },
            getData() {
                this.inProcess = true
                if (window['chartRequest'] != null)
                    window['chartRequest'].cancel()
                let CancelToken = axios.CancelToken
                window['chartRequest'] = CancelToken.source()
                axios.get(API.charts + '?count=' + this.count + (this.lastId!=null ? ('&lastId=' + this.lastId) : ''), {
                    params: this.request,
                    cancelToken: window['chartRequest'].token
                })
                    .then((res) => {
                        this.inProcess = false
                        let items = this.items
                        items = items.concat(res.data)
                        this.items = items
                        if(res.data.length < this.count) {
                            this.end = true
                        }
                    })
                    .catch((e) => {
                        this.inProcess = false
                        let response = e.response
                    })
            }
        }
    }
</script>