<template>
    <div :class="options.subclass">
        <div @click="open" class="capsule-item" :class="{loaded: item.id!=undefined}">
            <div class="bg"></div>
            <div class="main uk-grid uk-flex uk-flex-middle uk-grid-10">
                <div class="uk-width-auto">
                    <div class="image">
                        <ImagePlaceholder :data-src="cover | attach('xs')" width="75px" height="75px" radius="50%"/>
                    </div>
                </div>
                <div class="uk-width-expand">
                    <div class="title">
                        <TextSkeleton height="20px" width="60px" v-if="item.title===undefined"/>
                        <span class="f-s-095" v-if="item.title!=undefined">{{item.title}}</span>
                    </div>
                    <div class="information">
                        <TextSkeleton height="12px" width="120px" v-if="item.user===undefined"/>
                        <span class="f-s-085" v-if="item.title!=undefined"><span v-if="moods!=null">{{moods}} - </span><span class="dur">{{item.tracksDuration | minutes}}</span> </span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import TextSkeleton from "@/components/Tools/TextSkeleton";
    import helper from "@/helpers/helper";
    export default {
        components: {ImagePlaceholder, TextSkeleton},
        props: {
            item: {
                type: Object,
                default: () => {
                    return {

                    }
                }
            },
            subclass: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
                default: () => {
                    return {
                        subclass: ''
                    }
                }
            }
        },
        data() {
            return {

            }
        },
        methods: {
            open() {
                this.$router.push(helper.permalink(this.item))
            }
        },
        computed: {
            cover() {
                if(this.item.metas!=undefined) {
                    return this.item.metas.cover
                }
                return {
                    uri: ''
                }
            },
            moods() {
                let moods = []
                if(this.item.moods!=undefined) {
                    this.item.moods.map((e) => {
                        moods.push(e.name)
                    })
                    return moods.join(', ')
                }
                return null
            }
        }
    }
</script>