<template>
    <div class="padding-x">
        <!-- 300*250vowave -->
        <Adsense
                data-ad-test="on"
                data-ad-client="ca-pub-8118624680281970"
                data-ad-slot="7392234444">
        </Adsense>
    </div>
</template>
<script>
    export default {
        data() {
            return {}
        },
        mounted() {
        }
    }
</script>