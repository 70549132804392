var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.id != 0 || _vm.auth.username != undefined
    ? _c(
        "div",
        { staticClass: "user-without-buttons", class: _vm.options.subclass },
        [
          _vm.item.id > 0
            ? _c("router-link", { attrs: { to: "/" + _vm.item.username } }, [
                _c(
                  "div",
                  { staticClass: "image" },
                  [
                    _vm.item.is.verified
                      ? _c("div", { staticClass: "png-ico verified small" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("ImagePlaceholder", {
                      attrs: {
                        width: "100%",
                        radius: "50%",
                        "data-src": _vm._f("attach")(
                          _vm.item.photos.avatar,
                          "s"
                        ),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "sub uk-text-center uk-text-truncate f-s-075",
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.item.fullname) +
                        "\n        "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.item.id === 0
            ? _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: { click: _vm.promoteNow },
                },
                [
                  _c(
                    "div",
                    { staticClass: "image promote" },
                    [
                      _c("ImagePlaceholder", {
                        attrs: {
                          radius: "50%",
                          "data-src": _vm._f("attach")(
                            _vm.auth.photos.avatar,
                            "s"
                          ),
                        },
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "fal fa-plus" }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "sub uk-text-truncate uk-text-center f-s-075",
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.item.fullname) +
                          "\n        "
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.item.id === undefined
            ? _c("a", { attrs: { href: "javascript:void(0)" } }, [
                _c(
                  "div",
                  { staticClass: "image" },
                  [_c("ImagePlaceholder", { attrs: { radius: "50%" } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "sub uk-text-truncate uk-text-center f-s-075",
                  },
                  [
                    _c("TextSkeleton", {
                      style: { margin: "0 auto" },
                      attrs: { width: "80%", height: "10px" },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }