<template>
    <div class="placeholder-parent" :class="{set: set}">
        <div v-if="!loaded" :style="{borderRadius: radius, width: width, height: height, paddingBottom: (height=='0' ? '100%' : '0')}" class="canvas-placeholer" :class="{loaded: loaded}"></div>
        <img class="image-with-placeholder" :style="{borderRadius: radius, width: width}" :class="{loaded: loaded}" @load="loaded = true" @error="loaded = false" :src="dataSrc"/>
    </div>
</template>
<style>
    .placeholder-parent {
        position: relative;
    }
    .canvas-placeholer {
        width: 100%;
        height: 0;
        background-color: var(--shimmer-bg);
        /* background-size: 6000px; */
    }
    @supports (-webkit-overflow-scrolling: touch) {
        /* .canvas-placeholer {
            animation: shine-lines 1.6s infinite linear;
        } */
    }
    @media only screen and (min-width: 1024px) {
        /* .canvas-placeholer {
            animation: shine-lines 1.6s infinite linear;
        } */
    }
    .image-with-placeholder, .canvas-placeholer.loaded {
        opacity: 0;
        position: absolute;
        left: 0;
        transition: opacity 0.5s;
        background: #fff;
    }
    .canvas-placeholer.loaded {
        opacity: 1;
    }
    .image-with-placeholder.loaded {
        opacity: 1;
        position: relative;
    }
</style>
<script>
    import resize from 'vue-resize-directive'
    export default {
        directives: {
            resize,
        },
        data() {
            return {
                loaded: false,
                inited: false
            }
        },
        props: {
            dataSrc: {
                type: String,

            },
            set: {
                type: Boolean,
                default: false
            },
            radius: {
                type: String,
                default: '0px'
            },
            width: {
                type: String,
                default: '100%'
            },
            height: {
                type: String,
                default: '0'
            }
        },
        methods: {
            visible(type) {
                if(type && !this.inited) {
                    this.inited = true
                    // console.log('this.$refs.placeholder.clientWidth', this.$refs.placeholder.clientWidth)
                    this.$refs.placeholder.style.height = this.$refs.placeholder.clientWidth + 'px'
                }
            },
            onResize() {
                this.canv.style.height = this.canv.getBoundingClientRect().width + 'px'
            }
        },
        mounted() {
            this.canv = this.$refs.placeholder
            let canv = this.canv
            if(canv===undefined) return
            let ctx = canv.getContext("2d");
            function gradient(color0, color2) {
                let fillColor = ctx.createLinearGradient(0, 0, 300, 150);
                fillColor.addColorStop(0, color0);
                fillColor.addColorStop(1, color2);
                ctx.fillStyle = fillColor;
            }

            let colors = ['#290005', '#000', '#333'];
            let randomNumber = Math.floor(Math.random() * colors.length);
            let randomNumber2 = Math.floor(Math.random() * colors.length);
            if (randomNumber === randomNumber2) {
                randomNumber2 = randomNumber + 1;
            } else if (randomNumber === 7 && randomNumber2 === 7) {
                randomNumber2 = randomNumber - 1;
            }
            gradient(colors[randomNumber], colors[randomNumber2]);
            ctx.fillRect(0, 0, 600, 600);
        }
    }
</script>