var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c("transition", { attrs: { name: "modal" } }, [
        _c(
          "div",
          { staticClass: "modal-mask", on: { click: _vm.clickOnMask } },
          [
            _c("div", { staticClass: "modal-wrapper stepped" }, [
              _c("div", { staticClass: "modal-container" }, [
                _c("div", {
                  staticClass: "png-ico close small",
                  on: { click: _vm.close },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "modal-steps" }, [
                  _c(
                    "div",
                    {
                      ref: "containerStepsInner",
                      staticClass: "modal-steps-inner",
                      style: { width: _vm.steps * 100 + "%" },
                    },
                    _vm._l(_vm.stepsArr, function (index) {
                      return _c("div", { staticClass: "modal-step" }, [
                        _c(
                          "div",
                          { staticClass: "modal-header" },
                          [_vm._t("header" + index)],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-body" },
                          [_vm._t("body" + index)],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "modal-footer" },
                          [_vm._t("footer" + index)],
                          2
                        ),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "modal-footer" },
                    [_vm._t("footer")],
                    2
                  ),
                ]),
                _vm._v(" "),
                _vm.background !== ""
                  ? _c("div", {
                      staticClass: "modal-background",
                      style: { backgroundImage: "url(" + _vm.background + ")" },
                    })
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }