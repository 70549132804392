import { render, staticRenderFns } from "./TextSkeletonShimmer.vue?vue&type=template&id=38de9a62&"
import script from "./TextSkeletonShimmer.vue?vue&type=script&lang=js&"
export * from "./TextSkeletonShimmer.vue?vue&type=script&lang=js&"
import style0 from "./TextSkeletonShimmer.vue?vue&type=style&index=0&id=38de9a62&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports