var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "chat" }, [
      _c("div", { staticClass: "uk-grid-none", attrs: { "uk-grid": "" } }, [
        _c("div", { staticClass: "uk-width-1-4 max-height-100" }, [
          _c("div", { staticClass: "chat-left-container" }, [
            _vm._m(0),
            _vm._v(" "),
            _vm.conversations.length && _vm.users.length
              ? _c(
                  "div",
                  { staticClass: "conversation-list" },
                  [
                    _c(
                      "perfect-scrollbar",
                      {
                        key: _vm.conversationScrollbarKey,
                        ref: "chatConversations",
                        staticClass: "chat-messages f-s-085",
                        style: { "max-height": "100%" },
                        on: { scroll: _vm.conversationScrollHandler },
                      },
                      _vm._l(_vm.conversations, function (conversation, index) {
                        return conversation._id != null
                          ? _c(
                              "div",
                              {
                                staticClass: "conversation",
                                class: {
                                  active:
                                    _vm.getUserByConversation(index).id ===
                                    _vm.currentUserId,
                                },
                              },
                              [
                                _c(
                                  "router-link",
                                  {
                                    attrs: {
                                      to:
                                        "/chat/" +
                                        _vm.getUserByConversation(index)
                                          .username,
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uk-grid-10 uk-flex uk-flex-middle",
                                        attrs: { "uk-grid": "" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "uk-width-auto" },
                                          [
                                            _c("img", {
                                              attrs: {
                                                width: "40",
                                                height: "40",
                                                src:
                                                  _vm.getUserByConversation(
                                                    index
                                                  ).profile_uri === ""
                                                    ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTHUcNmf-luvb2nFRc4TIG8iT1oaqtygWWgPqxs5LOxO-Htc7-6"
                                                    : _vm.getUserByConversation(
                                                        index
                                                      ).profile_uri +
                                                      "_150.jpg",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "uk-width-expand" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "conversation-details",
                                                class: {
                                                  "has-new":
                                                    conversation.hasNew !==
                                                      false &&
                                                    conversation.hasNew !=
                                                      undefined,
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "conversation-main-details",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "chat-name f-s-095 uk-text-truncate",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                        " +
                                                            _vm._s(
                                                              _vm.getUserByConversation(
                                                                index
                                                              ).fullname
                                                            ) +
                                                            "\n                                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "last-chat",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "uk-text-truncate f-s-085",
                                                          },
                                                          [
                                                            _vm.isTypings.indexOf(
                                                              _vm.getUserByConversation(
                                                                index
                                                              ).id
                                                            ) !== -1
                                                              ? _c("span", [
                                                                  _c("span", {
                                                                    attrs: {
                                                                      id: "typing-loader",
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    "     typing\n                                                            "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                            _vm._v(" "),
                                                            _vm.isTypings.indexOf(
                                                              _vm.getUserByConversation(
                                                                index
                                                              ).id
                                                            ) === -1
                                                              ? _c("span", [
                                                                  _vm._v(
                                                                    "\n                                                                " +
                                                                      _vm._s(
                                                                        (conversation
                                                                          .messages[0]
                                                                          .from ===
                                                                        _vm.user
                                                                          .id
                                                                          ? "You: "
                                                                          : "") +
                                                                          _vm.htmlToText.fromString(
                                                                            conversation
                                                                              .messages[0]
                                                                              .message
                                                                          )
                                                                      ) +
                                                                      "\n                                                            "
                                                                  ),
                                                                ])
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                conversation.hasNew !== false &&
                                                conversation.hasNew !==
                                                  undefined
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "conversation-new uk-flex uk-flex-middle",
                                                      },
                                                      [
                                                        _c("span", [
                                                          _vm._v(
                                                            "\n                                                        " +
                                                              _vm._s(
                                                                conversation.hasNew
                                                              ) +
                                                              "\n                                                    "
                                                          ),
                                                        ]),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand max-height-100" }, [
          _c("div", { staticClass: "chat-right-container" }, [
            _c("div", { staticClass: "chat-status" }, [
              _vm.currentUserId === undefined || _vm.status !== "Online"
                ? _c("div", { staticClass: "status" }, [
                    _c("i", {
                      staticClass: "fas fa-circle",
                      class: [
                        { "chat-status-online": _vm.status === "Online" },
                        { "chat-status-offline": _vm.status !== "Online" },
                      ],
                    }),
                    _vm._v(
                      "\n                            " +
                        _vm._s(_vm.status) +
                        "\n                        "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.currentUserId != undefined && _vm.status === "Online"
                ? _c("div", { staticClass: "user-status" }, [
                    _c("img", {
                      attrs: {
                        src: _vm._f("attach")(
                          _vm.currentUser.profile_uri,
                          "xs"
                        ),
                      },
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "current-user-name" }, [
                      _vm._v(
                        "\n                                " +
                          _vm._s(_vm.currentUser.fullname) +
                          "\n                            "
                      ),
                    ]),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { ref: "chatBody", staticClass: "chat-body" },
              [
                _vm.currentConversation === null
                  ? _c("div", { staticClass: "no-active-chat" }, [
                      _c("i", { staticClass: "fal fa-comment-alt-dots" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "no-active-chat-text" }, [
                        _vm._v(
                          "\n                                Select someone to start a conversation.\n                            "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.currentConversation !== null &&
                _vm.currentConversation !== undefined
                  ? _c(
                      "perfect-scrollbar",
                      {
                        key: _vm.chatScrollbarKey,
                        ref: "chatMessages",
                        staticClass: "chat-messages f-s-085",
                        style: { "max-height": _vm.chatMaxHeight },
                        on: { scroll: _vm.chatScrollHandler },
                      },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "resize",
                                rawName: "v-resize",
                                value: _vm.onResizeChat,
                                expression: "onResizeChat",
                              },
                            ],
                            key: _vm.chatInnerKey,
                            ref: "chatMessageInner",
                          },
                          _vm._l(
                            _vm.currentConversation.messages,
                            function (message, index) {
                              return _c(
                                "div",
                                {
                                  ref: "message" + message._id,
                                  refInFor: true,
                                  staticClass: "message-container",
                                  class: [
                                    { sended: message.status === 0 },
                                    { me: message.from === _vm.user.id },
                                    {
                                      "margin-control":
                                        _vm.currentConversation.messages[
                                          index + 1
                                        ] === undefined ||
                                        _vm.currentConversation.messages[
                                          index + 1
                                        ] === null ||
                                        _vm.currentConversation.messages[
                                          index + 1
                                        ].from != message.from,
                                    },
                                  ],
                                  attrs: { id: "message" + message._id },
                                },
                                [
                                  _vm.currentConversation.messages[
                                    index + 1
                                  ] === undefined ||
                                  _vm.getDate(message.createdAt) !==
                                    _vm.getDate(
                                      _vm.currentConversation.messages[
                                        index + 1
                                      ].createdAt
                                    )
                                    ? _c(
                                        "div",
                                        { staticClass: "message-date" },
                                        [
                                          _vm._v(
                                            "\n                                        " +
                                              _vm._s(
                                                _vm.getDate(message.createdAt)
                                              ) +
                                              "\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "message-container-inner" },
                                    [
                                      message.from !== _vm.user.id
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "message-user-avatar",
                                            },
                                            [
                                              _vm.currentConversation.messages[
                                                index + 1
                                              ] === undefined ||
                                              _vm.currentConversation.messages[
                                                index + 1
                                              ] === null ||
                                              _vm.currentConversation.messages[
                                                index + 1
                                              ].from != message.from
                                                ? _c("img", {
                                                    attrs: {
                                                      width: "40",
                                                      height: "40",
                                                      src: _vm._f("attach")(
                                                        _vm.currentUser
                                                          .profile_uri,
                                                        "xs"
                                                      ),
                                                    },
                                                  })
                                                : _vm._e(),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "message",
                                          class: [
                                            {
                                              new:
                                                message.new != undefined &&
                                                message.new === true,
                                            },
                                          ],
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "message-body",
                                            domProps: {
                                              innerHTML: _vm._s(
                                                message.message
                                              ),
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "message-time" },
                                            [
                                              _vm._v(
                                                "\n                                                " +
                                                  _vm._s(
                                                    _vm.getTime(
                                                      message.createdAt
                                                    )
                                                  ) +
                                                  "\n                                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.currentConversation[
                                    _vm.currentConversationSeenKey
                                  ] == message._id
                                    ? _c(
                                        "div",
                                        {
                                          key: "seen" + message._id,
                                          staticClass: "message-seen",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                        Seen\n                                    "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    )
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.currentUserId !== undefined
              ? _c("div", { staticClass: "chat-message" }, [
                  _c("div", { staticClass: "input-container" }, [
                    _c("div", { staticClass: "chat-message-container" }, [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.chatMsg,
                            expression: "chatMsg",
                          },
                        ],
                        ref: "chat-msg",
                        attrs: {
                          placeholder: "Write a message...",
                          type: "text",
                        },
                        domProps: { value: _vm.chatMsg },
                        on: {
                          keyup: _vm.onTyping,
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.chatMsg = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "chat-message-send" }, [
                      _c("div", {
                        staticClass: "chat-message-send-ico",
                        on: {
                          click: function ($event) {
                            return _vm.sendMsg()
                          },
                        },
                      }),
                    ]),
                  ]),
                ])
              : _vm._e(),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "chat-search" }, [
      _c("input", {
        staticClass: "f-s-085",
        attrs: { placeholder: "Search", type: "text" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }