var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget padding-x" }, [
    _c("div", [
      _c("div", { staticClass: "widget-title" }, [
        _c(
          "div",
          { staticClass: "uk-flex uk-flex-middle", attrs: { "uk-grid": "" } },
          [
            _c("div", { staticClass: "uk-width-expand" }, [
              _c("h4", { staticClass: "f-s-13" }, [
                _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
              ]),
              _vm._v(" "),
              _c("h6", { staticClass: "f-s-090" }, [
                _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
              ]),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "widget-content app-store-slider-widget" }, [
        _c("div", { staticClass: "uk-grid uk-grid-10" }, [
          _c("div", { staticClass: "uk-width-1-2" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.settings.stores.appstore.url,
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  staticClass: "w-100",
                  attrs: { src: _vm.settings.stores.appstore.image },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "uk-width-1-2" }, [
            _c(
              "a",
              {
                attrs: {
                  href: _vm.settings.stores.googleplay.url,
                  target: "_blank",
                },
              },
              [
                _c("img", {
                  staticClass: "w-100",
                  attrs: { src: _vm.settings.stores.googleplay.image },
                }),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }