<template>
  <SteppedModal ref="steppedModal" :disableClose="disableClose" :stepsArr="stepsArr" :currentStep="currentStep"
                :background="avatar | attach('blur')" @close="close"
                :show="show">
    <div slot="header0" v-show="currentStep===0">
      <div>Go Pro!</div>
      <small>
        <span>What will you get with Pro account?</span>
      </small>
    </div>
    <div slot="body0" v-show="currentStep===0">
      <ul class="features f-s-085">
        <li>Pro Badge <i class="far fa-gem"></i></li>
        <li>Remove Ads</li>
        <li>Listen in HD</li>
        <li>Tools for Engaging Users</li>
        <li>Access to All Sounds & Videos</li>
        <li>And Many More Features</li>
      </ul>
      <div>

        <div class="uk-text-center f-s-2 request-post-price" :class="{rtl: request.currency==='tomans'}">
          <span class="farsi-font" v-if="request.currency==='tomans'">
              {{price | numformat}} <span class="f-s-075">تومان</span>
          </span>
          <span v-if="request.currency==='dollars'">
            <span>$</span>{{price}}
          </span>
        </div>
        <div class="currency-tabs">
          <div class="currency-tab-border" :style="{transform: 'translateX('+currencyTabBorderTranslate+')'}"></div>
          <div class="currency-tab" @click="setCurrencyTab('dollars')" :class="{active : request.currency==='dollars'}">
            Dollars
          </div>
          <div class="currency-tab" @click="setCurrencyTab('tomans')" :class="{active : request.currency==='tomans'}">
            تومان
          </div>
        </div>

        <div class="uk-text-center" v-if="!packagesLoaded">
          <i uk-spinner></i>
        </div>
        <div v-if="packagesLoaded">
          <v-select :clearable="false" placeholder="Select package" :searchable="false" v-model="request.package"
                    index="id" label="name" :reduce="pack => pack.id" :options="packages"></v-select>
        </div>

        <div class="uk-margin-top">
          <img :src="PaymentImage"/>
        </div>
      </div>
    </div>
    <div slot="body1" :class="{rtl: request.currency==='tomans'}">
      <div v-if="request.status">
        <div class="status success payment">
          <div>
            <i class="fal fa-check-circle"></i>
          </div>
          <div class="title f-s-12">
            {{request.currency==='tomans' ?
            'پرداخت با موفقیت !'
            :
            'Congratulations!'
            }}
          </div>
        </div>
        <div class="status description farsi-font f-s-090" v-if="request.currency==='tomans'">
          شما با موفقیت {{price | numformat}} تومان پرداخت کردید و درخواستتان به صورت اتوماتیک پردازش خواهد شد.<br>
          در صورتی که سوالی دارید میتوانید از صفحه
          <router-link to="/contact-us">تماس با ما</router-link>
          استفاده کنید.<br>
          <span v-if="request.transactionId!=null"><br>
                    کد پیگیری این تراکنش :
                    {{request.transactionId}}</span>
        </div>
        <div class="status description f-s-090" v-if="request.currency==='dollars'">
          You paid ${{price}} successfully and your request will get processed automatically. <br>
          If you have any questions you can <router-link to="/contact-us">contact us</router-link>.<br>
          <span v-if="request.transactionId!=null">This Transaction Id :
                        {{request.transactionId}}</span>
        </div>
      </div>
      <div v-if="!request.status">
        <div class="status error payment">
          <div>
            <i class="fal fa-exclamation-circle"></i>
          </div>
          <div class="title f-s-12">
            {{request.currency==='tomans' ?
            'خطا در پرداخت !'
            :
            'Something went wrong!'
            }}
          </div>
        </div>
        <div class="status description f-s-090" v-if="request.currency==='tomans'">
          متاسفانه در مراحل پرداخت شما مشکلی به وجود آمد و این عملیات با موفقیت انجام نشد.<br>
          لطفا دوباره سعی کنید و اگر خطا برطرف نشد از طریق صفحه
          <router-link to="/contact-us">تماس با ما</router-link>
          آن را اطلاع دهید.
          <span v-if="request.transactionId!=null"><br>
          کد پیگیری این تراکنش :
          {{request.transactionId}}
          </span>
        </div>
        <div class="status description f-s-090" v-if="request.currency==='dollars'">
          It seems there was an issue with your payment.<br>
          Please try again and if the issue still persist <router-link to="/contact-us">contact us</router-link>.
          <span v-if="request.transactionId!=null"><br>
          This Transaction Id :
          {{request.transactionId}}
          </span>
        </div>
      </div>
    </div>
    <div slot="footer" class="uk-flex">
      <div class="line-height-30 f-s-070" v-if="currentStep===0">
        Your Balance:
        <span v-if="request.currency==='dollars'">${{balance}} USD</span>
        <span v-if="request.currency==='tomans'">{{balance | numformat}} Tomans</span>
      </div>
      <button v-if="currentStep===0"
              class="uk-button button-top-menu button-bg uk-button-default flex-right uk-span-flex-inline"
              @click="paymentRequest" :disabled="inProcess">
                <span v-if="!inProcess">
                    {{request.currency==='tomans' ? 'خرید' : 'Buy'}}
                </span>
                <span v-if="inProcess">
                    <i uk-spinner="ratio: 0.5"></i>
                </span>
      </button>
    </div>
  </SteppedModal>
</template>
<script>
  import SteppedModal from './SteppedModal'
  import axios from 'axios'
  import PaymentImage from '../../assets/img/payments-with-shetab.png'
  import { USER_REQUEST } from '../../store/actions/user'
  import helper from '../../helpers/helper'

  export default {
    components: {
      SteppedModal
    },
    data () {
      return {
        PaymentImage: PaymentImage,
        inProcess: false,
        stepsArr: [0, 1],
        currencyTabBorderTranslate: 0,
        packagesLoaded: false,
        helper: helper,
        packages: [],
        currentStep: 0,
        request: {
          currency: 'dollars',
          package: 1,
          status: false,
          transactionId: null
        }
      }
    },
    props: {
      show: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      avatar() {
        if(this.auth.photos!=undefined && this.auth.photos.avatar!=undefined)
          return this.auth.photos.avatar
        return ''
      },
      disableClose () {
        return this.inProcess
      },
      auth () {
        return this.$store.getters.getProfile
      },
      price () {
        if (!this.packages.length)
          return 0
        if (this.request.package != '') {
          for (let i = 0; i < this.packages.length; i++) {
            if (this.request.package === this.packages[i].id) {
              if (this.request.currency === 'tomans') {
                return this.packages[i].priceTomans
              } else {
                return this.packages[i].price
              }
            }
          }
        }
        return 0
      },
      balance () {
        if(this.auth.stats===undefined) return 0
        let balance = this.request.currency === 'tomans' ? this.auth.stats.balance.tomans : this.auth.stats.balance.dollars
        if (this.request.currency === 'dollars') {
          balance = parseFloat(balance).toFixed(2)
        }
        return balance
      }
    },
    watch: {
      show (newVal) {
        if (newVal && !this.packages.length) {
          this.getSettings()
        }
        if(!newVal) {
          this.currentStep = 0
        }
      },
      auth (newVal) {
        if(window.buyProModalCurrencySetted!=undefined && window.buyProModalCurrencySetted===true) return
        if (newVal.geo === 'IR') {
          this.setCurrencyTab('tomans')
        } else {
          this.setCurrencyTab('dollars')
        }
        window.buyProModalCurrencySetted = true
      },
      'request.package': function (newVal) {
        // console.log('request', newVal)
      }
    },
    methods: {
      receivePaymentMessage (ev) {
        // console.log('ev', ev)
        if (window.paymentRequestInterval != undefined) {
          clearInterval(window.paymentRequestInterval)
        }
        window.paymentWindow.close()
        this.inProcess = false
        this.request.transactionId = ev.data.transactionId
        if(ev.data.success) {
          this.request.status = true
          this.currentStep = 1
          this.$store.dispatch(USER_REQUEST)
        } else {
          this.request.status = false
          this.currentStep = 1
        }
      },
      paymentRequest() {
        this.inProcess = true
        axios.post(API.payment.request, {
          type: 'buyPro',
          currency: this.request.currency,
          package: this.request.package,
        })
          .then((res) => {
            if(res.data.success) {
              this.inProcess = false
              this.request.status = true
              this.currentStep = 1
              bus.$emit('success', {
                title: 'Success',
                message: 'You paid this invoice from your account’s balance.'
              })
              this.$store.dispatch(USER_REQUEST)
            } else {
              if(res.data.redirect!=undefined) {
                window.paymentWindow = helper.openWindow(res.data.redirect, 'Pay')
                window.removeEventListener('message', this.receivePaymentMessage)
                window.addEventListener('message', this.receivePaymentMessage)
                window.paymentRequestInterval = setInterval( () => {
                  if (window.paymentWindow.closed) {
                    this.inProcess = false
                    clearInterval(window.paymentRequestInterval)
                    return
                  }
                  try {
                    window.paymentWindow.postMessage({message: 'requestResult'}, '*')
                  }
                  catch (e) {
                  }
                }, 500)
                if (window.paymentWindow.closed) {
                  // console.log('window.paymentWindowClosed')
                  this.inProcess = false
                  clearInterval(window.paymentRequestInterval)
                  return
                }
              } else {
                this.inProcess = false
                bus.$emit('error', {
                  title: 'Error',
                  message: 'Something went wrong. Please try again.'
                })
              }
            }
          })
          .catch((e) => {
            this.inProcess = false
            bus.$emit('error', {
              title: 'Error',
              message: 'Something went wrong. Please try again.'
            })
          })
      },
      setCurrencyTab (currency) {
        if (this.disableClose) return
        this.request.currency = currency
        switch (currency) {
          case 'tomans':
            this.currencyTabBorderTranslate = '100%'
            break
          case 'dollars':
            this.currencyTabBorderTranslate = 0
            break
        }
      },
      close () {
        this.show = false
        this.$emit('close')
      },
      getSettings () {
        axios.get(API.payment.proPackages)
          .then(res => {
            this.packagesLoaded = true
            this.packages = res.data
          })
      }
    },
    mounted () {
    }
  }
</script>
