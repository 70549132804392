var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.clickOutside,
          expression: "clickOutside",
        },
      ],
      ref: "main",
      style: { minWidth: "100%" },
    },
    [
      _c(
        "div",
        {
          staticClass: "no-svg vp-container",
          class: [
            { current: _vm.isCurrent },
            `status-${_vm.currentStatus}`,
            { desktop: !_vm.isTouchDevice },
          ],
          style: { maxHeight: _vm.mh },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value:
                    _vm.screenshot !== null &&
                    (!_vm.isCurrent ||
                      (_vm.isCurrent &&
                        !_vm.canAutoPlay &&
                        _vm.duration === 0)),
                  expression:
                    "\n        screenshot !== null &&\n        (!isCurrent || (isCurrent && !canAutoPlay && duration === 0))\n      ",
                },
              ],
              staticClass: "vp-screenshot",
            },
            [
              _c("img", {
                ref: "screenshot",
                attrs: { src: _vm.screenshot },
                on: { load: _vm.screenshotLoaded },
              }),
            ]
          ),
          _vm._v(" "),
          _c("video", {
            ref: "vid2",
            attrs: {
              id: "vid2",
              playsinline: !_vm.fullscreen,
              poster: _vm.screenshot,
            },
          }),
          _vm._v(" "),
          _c("transition", { attrs: { name: "nfade" } }, [
            _c("div", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showActions,
                  expression: "showActions",
                },
              ],
              staticClass: "vp-gradient",
            }),
          ]),
          _vm._v(" "),
          _c("transition", { attrs: { name: "nfade" } }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.showActions,
                    expression: "showActions",
                  },
                ],
                staticClass: "vp-actions-container",
              },
              [
                _c("div", {
                  staticClass: "vp-actions-overly",
                  on: { click: _vm.playPauseToggle },
                }),
                _vm._v(" "),
                _vm.isCurrent
                  ? _c("div", { staticClass: "vp-top-actions" }, [
                      _vm.isTouchDevice && _vm.levels.length
                        ? _c(
                            "div",
                            {
                              staticClass: "opacity-hover",
                              on: {
                                click: function ($event) {
                                  _vm.settingsModal = true
                                },
                              },
                            },
                            [_c("i", { staticClass: "fal fa-cog" })]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.isTouchDevice,
                        expression: "isTouchDevice",
                      },
                    ],
                    staticClass: "vp-center-actions",
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "vp-play-pause-btn",
                        on: { click: _vm.playPauseToggle },
                      },
                      [
                        _c(
                          "svg",
                          {
                            staticClass: "vp-pp-svg",
                            attrs: { viewBox: "0 0 104 104", id: "pause" },
                          },
                          [
                            _c("circle", {
                              class: { play: _vm.currentStatus === "play" },
                              staticStyle: {
                                "stroke-width": "2px",
                                stroke: "white",
                              },
                              attrs: {
                                id: "circle",
                                cx: "51",
                                cy: "51",
                                r: "50",
                                "stroke-dasharray": "314",
                                "stroke-dashoffset": "0",
                              },
                            }),
                            _vm._v(" "),
                            _c("line", {
                              staticStyle: {
                                "stroke-width": "4px",
                                stroke: "white",
                                "stroke-linecap": "round",
                              },
                              attrs: {
                                id: "line1",
                                x1: "38",
                                y1: "30",
                                x2: "38",
                                y2: "70",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "path",
                              {
                                staticStyle: {
                                  "stroke-width": "4px",
                                  stroke: "white",
                                  fill: "white",
                                  "stroke-linejoin": "round",
                                  "stroke-linecap": "round",
                                },
                                attrs: {
                                  id: "line2",
                                  d: "M 66 30 L 66 50 L 66 70",
                                  rx: "10",
                                  ry: "10",
                                },
                              },
                              [
                                _c("animate", {
                                  attrs: {
                                    attributeName: "d",
                                    dur: "150ms",
                                    from: "M 66 30 L 66 50 L 66 70",
                                    to: "M 38 30 L 70 50 L 38 70",
                                    begin: "indefinite",
                                    fill: "freeze",
                                    id: "from_pause_to_play",
                                  },
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("animate", {
                              attrs: {
                                "xlink:href": "#line2",
                                attributeName: "d",
                                dur: "150ms",
                                from: "M 38 30 L 70 50 L 38 70",
                                to: "M 66 30 L 66 50 L 66 70",
                                fill: "freeze",
                                id: "from_play_to_pause",
                                begin: "indefinite",
                              },
                            }),
                          ]
                        ),
                      ]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "vp-actions" }, [
                  _c("div", { staticClass: "bottom" }, [
                    _c(
                      "div",
                      { staticClass: "uk-grid uk-grid-10 align-items-center" },
                      [
                        _c("div", { staticClass: "uk-width-expand" }, [
                          _vm.isTouchDevice
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-grid uk-grid-10 align-items-center uk-flex",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "uk-width-auto fs-075" },
                                    [
                                      _vm.isCurrent
                                        ? _c(
                                            "span",
                                            { staticClass: "txt-dur" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("minutes")(
                                                    _vm.tmpDur || _vm.duration
                                                  )
                                                ) + "\n                    "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "txt-dur" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm._f("minutes")(0))
                                              ),
                                            ]
                                          ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "uk-width-auto" }, [
                                    _vm._v("/"),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "uk-width-auto" }, [
                                    _c("span", { staticClass: "txt-dur" }, [
                                      _vm._v(
                                        _vm._s(
                                          _vm._f("minutes")(
                                            _vm.tmpRemaning || _vm.videoDuration
                                          )
                                        )
                                      ),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "uk-width-expand justify-content-right uk-flex align-items-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "vp-action-btn",
                                          on: { click: _vm.fullScreen },
                                        },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-expand",
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "uk-grid uk-grid-10" }, [
                            _c("div", { staticClass: "uk-width-expand" }, [
                              _c("div", { staticClass: "progres-bar-parent" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "progres-bar",
                                    class: ["mouse-" + _vm.progressMouseStatus],
                                  },
                                  [
                                    _c("div", {
                                      ref: "progress",
                                      staticClass: "progress-helper",
                                      attrs: { id: "progressHelper" },
                                    }),
                                    _vm._v(" "),
                                    _c("div", {
                                      staticClass: "progress-bar-v",
                                      style: {
                                        width:
                                          (_vm.tmpPercent || _vm.progress) +
                                          "%",
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          !_vm.isTouchDevice
                            ? _c("div", { staticClass: "vp-actions-desktop" }, [
                                _c("div", { staticClass: "left-side" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "vpabtnc opacity-hover",
                                      attrs: { id: "playPauseToggle" },
                                      on: { click: _vm.playPauseToggle },
                                    },
                                    [
                                      _c(
                                        "svg",
                                        { attrs: { viewBox: "0 0 36 36" } },
                                        [
                                          _c("defs", [
                                            _c("path", {
                                              ref: "animationPause",
                                              attrs: {
                                                id: "pause-icon",
                                                "data-state": "playing",
                                                "data-next-state": "paused",
                                                d: "M11,10 L17,10 17,26 11,26 M20,10 L26,10 26,26 20,26",
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("path", {
                                              ref: "animationPlay",
                                              attrs: {
                                                id: "play-icon",
                                                "data-state": "paused",
                                                "data-next-state": "playing",
                                                d: "M11,10 L18,13.74 18,22.28 11,26 M18,13.74 L26,18 26,18 18,22.28",
                                              },
                                            }),
                                          ]),
                                          _vm._v(" "),
                                          _c("use", {
                                            attrs: {
                                              "xlink:href": "#play-icon",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      ref: "volume",
                                      staticClass: "vp-volume-parent",
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "vpabtnc cursor-pointer volume-icon",
                                          attrs: { id: "volumeIcon" },
                                          on: { click: _vm.muteToggle },
                                        },
                                        [
                                          _c(
                                            "svg",
                                            { attrs: { viewBox: "0 0 36 36" } },
                                            [
                                              _c("defs", [
                                                _c("path", {
                                                  ref: "animationVolumeFull",
                                                  attrs: {
                                                    id: "volume-full-icon",
                                                    d: "M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 ZM19,11.29 C21.89,12.15 24,14.83 24,18 C24,21.17 21.89,23.85 19,24.71 L19,26.77 C23.01,25.86 26,22.28 26,18 C26,13.72 23.01,10.14 19,9.23 L19,11.29 Z",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  ref: "animationVolumeHalf",
                                                  attrs: {
                                                    id: "volume-half-icon",
                                                    d: "M8,21 L12,21 L17,26 L17,10 L12,15 L8,15 L8,21 Z M19,14 L19,22 C20.48,21.32 21.5,19.77 21.5,18 C21.5,16.26 20.48,14.74 19,14 Z",
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("path", {
                                                  ref: "animationVolumeEmpty",
                                                  attrs: {
                                                    id: "volume-empty-icon",
                                                    d: "m 21.48,17.98 c 0,-1.77 -1.02,-3.29 -2.5,-4.03 v 2.21 l 2.45,2.45 c .03,-0.2 .05,-0.41 .05,-0.63 z m 2.5,0 c 0,.94 -0.2,1.82 -0.54,2.64 l 1.51,1.51 c .66,-1.24 1.03,-2.65 1.03,-4.15 0,-4.28 -2.99,-7.86 -7,-8.76 v 2.05 c 2.89,.86 5,3.54 5,6.71 z M 9.25,8.98 l -1.27,1.26 4.72,4.73 H 7.98 v 6 H 11.98 l 5,5 v -6.73 l 4.25,4.25 c -0.67,.52 -1.42,.93 -2.25,1.18 v 2.06 c 1.38,-0.31 2.63,-0.95 3.69,-1.81 l 2.04,2.05 1.27,-1.27 -9,-9 -7.72,-7.72 z m 7.72,.99 -2.09,2.08 2.09,2.09 V 9.98 z",
                                                  },
                                                }),
                                              ]),
                                              _vm._v(" "),
                                              _c("use", {
                                                attrs: {
                                                  "xlink:href":
                                                    "#volume-full-icon",
                                                },
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "vp-volumebar-container",
                                          class: { active: _vm.volumeModel },
                                        },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "vp-volumebar" },
                                            [
                                              _c("div", {
                                                staticClass: "vp-volumebar-val",
                                                style: {
                                                  width:
                                                    _vm.volumeBarPosition +
                                                    "px",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass: "bil",
                                                style: {
                                                  left:
                                                    _vm.volumePosition + "px",
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("div", {
                                                ref: "volumehelper",
                                                staticClass: "vbhelper",
                                              }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "vp-duration" }, [
                                    _c("div", [
                                      _vm.isCurrent
                                        ? _c(
                                            "span",
                                            { staticClass: "txt-dur" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("minutes")(
                                                    _vm.tmpDur || _vm.duration
                                                  )
                                                ) + "\n                        "
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "span",
                                            { staticClass: "txt-dur" },
                                            [
                                              _vm._v(
                                                _vm._s(_vm._f("minutes")(0))
                                              ),
                                            ]
                                          ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c(
                                        "span",
                                        { staticClass: "txt-dur slash-space" },
                                        [_vm._v("/")]
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", [
                                      _c("span", { staticClass: "txt-dur" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm._f("minutes")(
                                              _vm.tmpRemaning ||
                                                _vm.videoDuration
                                            )
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "right-side" }, [
                                  _c("div", {
                                    staticClass: "vpabtnc opacity-hover",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.icons.cog),
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.settingsModal = true
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "vpabtnc opacity-hover",
                                    domProps: {
                                      innerHTML: _vm._s(_vm.icons.expand),
                                    },
                                    on: { click: _vm.fullScreen },
                                  }),
                                ]),
                              ])
                            : _vm._e(),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ]
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "wobgmodal" },
        [
          _vm.isCurrent
            ? _c(
                "modal",
                {
                  on: {
                    close: function ($event) {
                      _vm.settingsModal = false
                    },
                  },
                  model: {
                    value: _vm.settingsModal,
                    callback: function ($$v) {
                      _vm.settingsModal = $$v
                    },
                    expression: "settingsModal",
                  },
                },
                [
                  _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                    _vm._v(_vm._s(_vm._f("lang")("playback_settings"))),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-select" },
                        },
                        [_vm._v(_vm._s(_vm._f("lang")("quality")))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customLevel,
                                expression: "customLevel",
                              },
                            ],
                            staticClass: "uk-select",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.customLevel = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.levelsWithAuto, function (lvl, index) {
                            return lvl !== null && lvl !== undefined
                              ? _c(
                                  "option",
                                  { key: index, domProps: { value: lvl } },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          lvl === "auto"
                                            ? _vm.autoLang
                                            : lvl +
                                                (lvl.substr(-1, 1) === "p"
                                                  ? ""
                                                  : "p")
                                        ) +
                                        "\n                " +
                                        _vm._s(
                                          lvl === "auto" &&
                                            _vm.customLevel === "auto" &&
                                            _vm.level !== null
                                            ? " – " + _vm.level + "p"
                                            : ""
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              : _vm._e()
                          }),
                          0
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin" }, [
                      _c(
                        "label",
                        {
                          staticClass: "uk-form-label",
                          attrs: { for: "form-stacked-select" },
                        },
                        [_vm._v(_vm._s(_vm._f("lang")("speed")))]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-form-controls" }, [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.customSpeed,
                                expression: "customSpeed",
                              },
                            ],
                            staticClass: "uk-select",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.customSpeed = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.speeds, function (speed, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: speed } },
                              [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(speed) +
                                    "\n              "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                    _c(
                      "button",
                      {
                        staticClass: "uk-button uk-button-default",
                        on: {
                          click: function ($event) {
                            _vm.settingsModal = false
                          },
                        },
                      },
                      [_vm._v("\n          OK\n        ")]
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }