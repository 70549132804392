import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import auth from './modules/auth'
import player from './modules/player'
import settings from './modules/settings'
import moremenu from './modules/moremenu'
Vue.use(Vuex)
export const strict = false

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    user,
    auth,
    settings,
    player,
    moremenu
  },
  strict: debug,
})
