var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget padding-x" }, [
    _c("div", { staticClass: "widget-content" }, [
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "f-f-reg f-s-095 sale-and-promotions-title" },
            [_vm._v("\n                Active Promotions\n            ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.promotes, function (promote, index) {
            return _c(
              "div",
              [_c("promote-item", { attrs: { item: promote, index: index } })],
              1
            )
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "div",
            { staticClass: "f-f-reg f-s-095 sale-and-promotions-title" },
            [_vm._v("\n                Posts Sale\n            ")]
          ),
          _vm._v(" "),
          _vm._l(_vm.sales, function (sale, index) {
            return _c(
              "div",
              [_c("promote-item", { attrs: { item: sale, index: index } })],
              1
            )
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }