var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sidebar-container" },
    [
      _c("router-link", { attrs: { to: "/" } }, [
        _c("div", { staticClass: "sidebar-logo-top" }),
      ]),
      _vm._v(" "),
      _c("MainMenu", { attrs: { items: _vm.mainItems } }),
      _vm._v(" "),
      _vm.auth
        ? _c("h4", { staticClass: "sidebar-menu-title f-f-light f-s-11" }, [
            _vm._v("Collection"),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.auth
        ? _c("MainMenu", { attrs: { items: _vm.secondItems } })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "dark-switcher" }, [
        _c("div", { staticClass: "uk-text-center" }, [
          _c(
            "span",
            {
              staticClass: "dark-mode-switcher",
              class: { "dark-mode-active": _vm.isDark },
              on: { click: _vm.darkmode },
            },
            [
              _c("span", { staticClass: "f-s-080" }, [_vm._v("Dark Mode")]),
              _vm._v(" "),
              _c("i", {
                staticClass: "fa-moon f-s-1",
                class: { fas: _vm.isDark, fal: !_vm.isDark },
              }),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }