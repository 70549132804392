<template>
    <div ref="container" class="waveform-container">
        <canvas :key="key" ref="canvas" height="100">
        </canvas>
    </div>
</template>
<script>
    var LogoMemory = function (src, callback) {
        this.src = src;
        this.callback = callback;
        this.init();
        return this;
    }

    LogoMemory.prototype = {
        src: null,
        colors: [],
        samples: [],
        callback: null,
        init: function () {
            var _this = this;
            var data = new Image();
            data.crossOrigin = 'Anonymous'
            data.onload = function (e) {
                var data = e.target;
                _this.parse(data);
                if (_this.callback) _this.callback();
            }
            data.src = this.src;
        },
        data: function (img) {
            var canvas = document.createElement('canvas');
            canvas.width = img.naturalWidth
            canvas.height = img.naturalHeight
            var ctx = canvas.getContext('2d')
            ctx.drawImage(img, 0, 0);
            return ctx.getImageData(0, 0, canvas.width, canvas.height).data;
        },
        parse: function (data) {
            this.colors = [];
            this.samples = [];
            var data = this.data(data);
            var i = data.length / 4;
            while (i--) {
                if (this.samples.length < 3000) {
                    this.samples.push(data[i * 4], data[i * 4 + 1], data[i * 4 + 2]);
                }
            }
        }
    }

    export default {
        data() {
            return {
                containerWidth: 0,
                width: 3000,
                height: 100,
                zero: 120,
                mouseStatus: null,
                key: Math.random(),
                peaks: [],
                barCnt: 0,
                selected: -1,
                options: {
                    barWidth: 2,
                    barMargin: 1,
                    radius: 0,
                    color: {
                        r: 255,
                        g: 255,
                        b: 255,
                        a: 1
                    },
                    highlight: {
                        r: 179,
                        g: 0,
                        b: 0,
                        a: 1
                    }
                },
                sampleData: [],
                ctx: null,
            }
        },
        props: [
            'sound', 'player'
        ],
        watch: {
            'sampleData': function (val) {
                this.generatePeaks()
            },
            'currentBar': function () {
                this.drawBar()
            },
            'selected': function (selected) {
                if (selected !== -1) {
                    if (this.interval != undefined) {
                        clearInterval(this.interval)
                    }
                }
            },
            'duration': function (duration, last) {
                let dif = duration - last
                if (dif < 0) dif *= -1
                if (dif > 1) {
                    this.drawBars(null, true)
                }
            }
        },
        computed: {
            sndDuration: function () {
                return this.sound.metas.attachment.duration
            },
            currentId() {
                return this.$store.getters.getCurrent && this.$store.getters.getCurrent.realId
            },
            duration: function () {
                if (this.sound !== null && this.sound.realId !== this.currentId) return 0
                return this.$store.getters.getDuration
            },
            currentBar: function () {
                if (this.sound !== null && this.sound.realId != this.currentId) return 0
                let cb = this.duration / this.sound.metas.attachment.duration * this.barCnt
                return Math.ceil(cb)
                if (cb / Math.floor(cb) === 1) return cb
                return Math.floor(cb) + 1
            }
        },
        methods: {
            bindEventHandlers() {
                let isTouchDevice = 'ontouchstart' in document.documentElement
                this.$refs.canvas.addEventListener(isTouchDevice ? "touchstart" : "mousedown", this.onMouseDown)
                this.$refs.canvas.addEventListener(isTouchDevice ? "touchmove" : "mousemove", this.onMouseOver)
                this.$refs.canvas.addEventListener("mouseout", this.onMouseOut)
                this.$refs.canvas.addEventListener(isTouchDevice ? "touchend" : "mouseup", this.onMouseUp)
            },
            onResize() {
                this.key = Math.random()
            },
            onMouseOut(e) {
                if(this.sound !== null && this.sound.realId !== this.currentId) {
                    return;
                }
                this.mouseStatus = 'out'
                if (this.isDragging === true) {
                    this.isDragging = false
                    this.player.play()
                }
                this.selected = -1;
                this.drawBars(null, true)
            },
            onMouseUp(e) {
                if(this.sound !== null && this.sound.realId !== this.currentId) {
                    return;
                }
                if (this.isDragging) {
                    let canvasPosition = this.$refs.canvas.getBoundingClientRect();
                    let ex = e.x || e.changedTouches[0].clientX
                    var x = ex - canvasPosition.left;
                    this.isDragging = false;
                    bus.$emit('doSeek', this.getCurrentDuration(x))
                    // this.player.play()
                }
            },
            onMouseOver(e) {
                if(this.sound !== null && this.sound.realId !== this.currentId) {
                    return;
                }
                this.mouseStatus = 'in'
                let canvasPosition = this.$refs.canvas.getBoundingClientRect()
                let ex = e.x || e.touches[0].clientX
                let x = ex - canvasPosition.left;
                var waveClicked = this.getCurrentBar(x);
                if (this.isDragging === true) {
                    this.selected = -1
                    bus.$emit('doSeek', this.getCurrentDuration(x))
                } else {
                    this.selected = waveClicked
                }
                this.drawBars(null, true)
            },
            getCurrentBar(pos) {
                let posPercent = pos / this.barRealWidth
                //console.log( pos, this.realWidth, this.barRealWidth)
                return Math.ceil(posPercent)
            },
            getCurrentDuration(pos) {
                let dur = (pos / this.realWidth) * this.sound.metas.attachment.duration
                return dur
            },
            fireEvent(name) {
                if (!this.events[name]) return;
                var args = [].splice.call(arguments, 0);
                args[0] = this;
                this.events[name].e.forEach(function (event) {
                    event.apply(null, args)
                })
            },
            onMouseDown(e) {
                if(this.sound !== null && this.sound.realId !== this.currentId) {
                    return;
                }
                let canvasPosition = this.$refs.canvas.getBoundingClientRect()
                this.isDragging = true;
                let ex = e.x || e.touches[0].clientX
                var x = ex - canvasPosition.left;
                var waveClicked = this.getCurrentBar(x)
            },
            calculate() {
                this.barRealWidth = this.options.barWidth + this.options.barMargin
                this.barCnt = this.containerWidth / this.barRealWidth
                if (this.barCnt / Math.floor(this.barCnt) != 1) {
                    this.barCnt = Math.floor(this.barCnt)
                }
                this.barSeconds = this.sndDuration / this.barCnt
                // console.log('this.barSeconds', this.barSeconds)
                this.dataPerBar = 3000 / this.barCnt
                this.realWidth = this.barCnt * this.barRealWidth
                this.makeSampleData()
            },
            makeSampleData() {
                let clearedPeaks = []
                for (var i = 0; i < this.barCnt; i++) {
                    clearedPeaks.push(0.05)
                }
                this.peaks = clearedPeaks
                this.drawBars()
            },
            generatePeaks() {
                let tmp = []
                let tmpInner = 0
                let cnt = 0
                for (var i = 0; i < this.barCnt; i++) {
                    tmpInner = 0
                    cnt = 0
                    for (let o = i * this.dataPerBar; o < this.dataPerBar * (i + 1); o++) {
                        cnt++
                        tmpInner += this.sampleData[Math.floor(o)]
                    }
                    tmp.push((((tmpInner / cnt) - 120) / 135))
                }
                this.peaks = tmp
                this.drawBars(null, true)
            },
            drawBars(callback, clear) {
                clear = true;
                if (clear)
                    this.clear()
                for (let i = 0; i < this.peaks.length; i++) {
                    this.drawBar(i, true)
                }
                if (callback) callback()
            },
            drawBar(x, anim) {
                let force = false
                if (x === undefined) {
                    x = this.currentBar - 1
                    force = true
                }
                let vm = this
                let hh = this.height * this.peaks[Math.ceil(x)]
                let yy = this.height - hh
                let ww = this.options.barWidth
                let xx = x * ww === 0 ? 0 : (x * ww) + (x * this.options.barMargin)
                let opacity = 1
                if (vm.interval != undefined) {
                    clearInterval(vm.interval)
                }
                if (this.currentBar > x || force) {
                    if (this.selected != -1) {
                        if (this.selected > x) {
                            opacity = 1
                        } else {
                            opacity = 0.5
                        }
                    }
                    vm.ctx.fillStyle = 'rgba(' + vm.options.highlight.r + ',' + vm.options.highlight.g + ',' + vm.options.highlight.b + ',' + opacity + ')'
                    vm.ctx.clearRect(xx, yy, ww, hh)
                    if (force && this.selected === -1) {
                        let opacityRate = opacity / 5
                        vm.ctx.fillStyle = 'rgba(' + vm.options.highlight.r + ',' + vm.options.highlight.g + ',' + vm.options.highlight.b + ',' + opacity + ')';
                        vm.ctx.clearRect(xx, yy, ww, hh);
                        vm.ctx.fillRect(xx, yy, ww, hh);
                        let animatedOpacity = 0
                        // var wainterval = setInterval(function () {
                        //     vm.ctx.fillStyle = 'rgba(' + vm.options.highlight.r + ',' + vm.options.highlight.g + ',' + vm.options.highlight.b + ',' + animatedOpacity + ')'
                        //     vm.ctx.clearRect(xx, yy, ww, hh)
                        //     //vm.roundRect(vm.ctx, xx, yy, ww, hh, vm.options.radius, true, false)
                        //     vm.ctx.fillRect(xx, yy, ww, hh)
                        //     if (animatedOpacity >= opacity) {
                        //         clearInterval(wainterval)
                        //     }
                        //     animatedOpacity += opacityRate
                        // }, this.barSeconds * (opacityRate * 1000))
                        // vm.interval = wainterval
                    } else {
//            if(anim) {
//              let yyy = 0.05
//              var animinterval = setInterval(function () {
//                yy += 0.05
//                if(yyy>yy) {
//                  yyy = yy
//                  clearInterval(animinterval)
//                }
//                vm.roundRect(vm.ctx, xx, yy, ww, yyy, vm.options.radius, true, false)
//              }, 10)
//            } else {
                        //vm.roundRect(vm.ctx, xx, yy, ww, hh, vm.options.radius, true, false)
                        this.ctx.fillRect(xx, yy, ww, hh)
//            }
                    }
                } else {
                    if (this.selected > x) {
                        this.ctx.fillStyle = 'rgba(' + this.options.highlight.r + ',' + this.options.highlight.g + ',' + this.options.highlight.b + ',0.5)'
                        opacity = this.options.color.a
                    } else {
                        this.ctx.fillStyle = 'rgba(' + this.options.color.r + ',' + this.options.color.g + ',' + this.options.color.b + ',' + this.options.color.a + ')'
                        opacity = 0.5
                    }

                    this.ctx.fillRect(xx, yy, ww, hh)
                    //this.roundRect(this.ctx, xx, yy, ww, hh, this.options.radius, true, false)
                }

                //this.ctx.fillRect(xx, yy, ww, hh)
            },
            show() {
                // nothing
            },
            clear: function () {
                this.ctx.clearRect(0, 0, this.width, this.height);
            },
            loadSamples() {
                let url = this.sound.metas.attachment.waveform;
                var waveform = this;
                var samples = new Image();
                samples.crossOrigin = 'Anonymous';
                samples.onload = function () {
                    new LogoMemory(this.src, function () {
                        waveform.sampleData = this.samples;
                        waveform.drawBars(function () {
                            waveform.show();
                        })
                    })
                };
                samples.src = url;
            },
            /**
             * Draws a rounded rectangle using the current state of the canvas.
             * If you omit the last three params, it will draw a rectangle
             * outline with a 5 pixel border radius
             * @param {CanvasRenderingContext2D} ctx
             * @param {Number} x The top left x coordinate
             * @param {Number} y The top left y coordinate
             * @param {Number} width The width of the rectangle
             * @param {Number} height The height of the rectangle
             * @param {Number} [radius = 5] The corner radius; It can also be an object
             *                 to specify different radii for corners
             * @param {Number} [radius.tl = 0] Top left
             * @param {Number} [radius.tr = 0] Top right
             * @param {Number} [radius.br = 0] Bottom right
             * @param {Number} [radius.bl = 0] Bottom left
             * @param {Boolean} [fill = false] Whether to fill the rectangle.
             * @param {Boolean} [stroke = true] Whether to stroke the rectangle.
             */
            roundRect(ctx, x, y, width, height, radius, fill, stroke) {
                if (typeof stroke == 'undefined') {
                    stroke = true;
                }
                if (typeof radius === 'undefined') {
                    radius = 5;
                }
                if (typeof radius === 'number') {
                    radius = {tl: radius, tr: radius, br: radius, bl: radius};
                } else {
                    var defaultRadius = {tl: 0, tr: 0, br: 0, bl: 0};
                    for (var side in defaultRadius) {
                        radius[side] = radius[side] || defaultRadius[side];
                    }
                }
                ctx.beginPath();
                ctx.moveTo(x + radius.tl, y);
                ctx.lineTo(x + width - radius.tr, y);
                ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
                ctx.lineTo(x + width, y + height - radius.br);
                ctx.quadraticCurveTo(x + width, y + height, x + width - radius.br, y + height);
                ctx.lineTo(x + radius.bl, y + height);
                ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
                ctx.lineTo(x, y + radius.tl);
                ctx.quadraticCurveTo(x, y, x + radius.tl, y);
                ctx.closePath();
                if (fill) {
                    ctx.fill();
                }
                if (stroke) {
                    ctx.stroke();
                }

            }
        },
        mounted() {
            this.containerWidth = this.$refs.container.getBoundingClientRect().width;
            this.$refs.canvas.setAttribute('width', this.containerWidth);
            this.ctx = this.$refs.canvas.getContext('2d');
            this.calculate();
            this.loadSamples();
            this.bindEventHandlers();
            // bus.$on('soundSeek', () => {
            //     this.selected = -1;
            //     this.drawBar()
            // })
        },
        beforeDestroy() {

        }
    }
</script>