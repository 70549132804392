var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "mainContainer" },
    [
      _c(
        "flicking",
        {
          attrs: {
            options: {
              renderOnlyVisible: false,
              align: "prev",
              preventClickOnDrag: true,
              bound: true,
              circularFallback: "bound",
            },
          },
        },
        _vm._l(_vm.items, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "flicking-panel" },
            [
              _c("ScreenshotItem", {
                staticClass: "swiper-slide screenshot-slider-item",
                attrs: { item: item, index: index },
              }),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }