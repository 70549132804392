<template>
    <div class="widget padding-x uk-margin-small-top" ref="mainContainer">
        <div>
            <div class="widget-title">
                <div uk-grid class="uk-flex uk-flex-middle">
                    <div class="uk-width-expand">
                        <h4 class="f-s-13 uk-flex uk-flex-middle"><span>{{options.title | lang}}</span> <img :src="flag"/></h4>
                        <h6 class="f-s-090">{{options.subtitle | lang}}</h6>
                    </div>
                    <div class="uk-width-auto">
                        <router-link :to="seeAllUrl" class="see-all-right">See all</router-link>
                    </div>
                </div>
            </div>
            <div class="widget-content user-slider-widget">
                <div uk-grid>
                    <div class="uk-width-1-1 uk-width-1-2@m">
                        <Items class="v-post-items display-track-num" type="VPostItem" :items="itemsA" :options="{startIndex: 0, showViews: true}"/>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-2@m mobile-hidden">
                        <Items class="v-post-items display-track-num" type="VPostItem" :items="itemsB" :options="{startIndex: eachRowsCnt, showViews: true}"/>
                    </div>
                </div>
                <div v-if="isLoading" class="uk-text-center">
                    <i uk-spinner></i>
                </div>
            </div>
        </div>
    </div>
</template>
<style>
    .widget-title img {
        width: 20px;
        height: 20px;
        margin-left: 10px;
    }
</style>
<script>
    import Items from "@/components/Items/Items";
    import axios from 'axios'
    export default {
        data() {
            return {
                itemsA: [],
                itemsB: [],
                eachRowsCnt: 5,
                isLoading: false,
                itemsTmp: []
            }
        },
        components: {
            Items
        },
        props: {
            options: {}
        },
        methods: {
            getData() {
                this.itemsTmp = []
                this.isLoading = true
                axios.get(this.options.url)
                    .then(res => {
                        this.isLoading = false
                        if(res.data.length) {
                            for(let i = 0; i<res.data.length; i++) {
                                this.itemsTmp.push(res.data[i])
                                let target = (i<this.eachRowsCnt) ? 'itemsA' : 'itemsB'
                                this[target].push(res.data[i])
                            }
                        }
                    })
            }
        },
        computed: {
            geo() {
                return this.$store.getters.settings.geo
            },
            flag() {
                return API.base + '/assets/img/countries/' + this.geo + '.png'
            },
            seeAllUrl() {
                let url = '/charts'
                return url
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>