var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "footer" }, [
    _c(
      "div",
      [
        _vm._v("\n        Copyright © " + _vm._s(_vm.year) + " "),
        _c("router-link", { attrs: { to: "/" } }, [_vm._v("VOWAVE LTD")]),
        _vm._v(". All rights reserved.\n    "),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "footer-links" },
      [
        _c("router-link", { attrs: { to: "/terms" } }, [_vm._v("Terms")]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/privacy" } }, [_vm._v("Privacy")]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/about-us" } }, [_vm._v("About Us")]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/contact-us" } }, [
          _vm._v("Contact Us"),
        ]),
        _vm._v(" "),
        _c("router-link", { attrs: { to: "/claim" } }, [
          _vm._v("Claim Profile"),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "uk-clearfix" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }