var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "mainContainer",
      staticClass: "widget padding-x uk-margin-small-top",
    },
    [
      _c("div", [
        _c("div", { staticClass: "widget-title" }, [
          _c(
            "div",
            { staticClass: "uk-flex uk-flex-middle", attrs: { "uk-grid": "" } },
            [
              _c("div", { staticClass: "uk-width-expand" }, [
                _c("h4", { staticClass: "f-s-13 uk-flex uk-flex-middle" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
                  ]),
                  _vm._v(" "),
                  _c("img", { attrs: { src: _vm.flag } }),
                ]),
                _vm._v(" "),
                _c("h6", { staticClass: "f-s-090" }, [
                  _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "uk-width-auto" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "see-all-right",
                      attrs: { to: _vm.seeAllUrl },
                    },
                    [_vm._v("See all")]
                  ),
                ],
                1
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "widget-content user-slider-widget" }, [
          _c("div", { attrs: { "uk-grid": "" } }, [
            _c(
              "div",
              { staticClass: "uk-width-1-1 uk-width-1-2@m" },
              [
                _c("Items", {
                  staticClass: "v-post-items display-track-num",
                  attrs: {
                    type: "VPostItem",
                    items: _vm.itemsA,
                    options: { startIndex: 0, showViews: true },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "uk-width-1-1 uk-width-1-2@m mobile-hidden" },
              [
                _c("Items", {
                  staticClass: "v-post-items display-track-num",
                  attrs: {
                    type: "VPostItem",
                    items: _vm.itemsB,
                    options: { startIndex: _vm.eachRowsCnt, showViews: true },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _vm.isLoading
            ? _c("div", { staticClass: "uk-text-center" }, [
                _c("i", { attrs: { "uk-spinner": "" } }),
              ])
            : _vm._e(),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }