<template>
    <div class="uk-text-center padding-top-50">
        <div>
            <i class="f-s-4" :class="icon"></i>
        </div>
        <div class="f-f-reg padding-y-20">
            {{text}}
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            text: {
                type: String,
                default: 'This section is empty!'
            },
            icon: {
                type: String,
                default: 'fal fa-waveform'
            }
        }
    }
</script>