<template>
    <div class="user-item vxp" :class="(options!=undefined && options.subclass!=undefined) ? options.subclass : ''">
        <div v-if="item.id === undefined">
            <div class="image" @click="closeSearch()">
                <div class="image">
                    <ImagePlaceholder radius="50%"/>
                </div>
            </div>
        </div>
        <div v-else>
            <div class="image" @click="closeSearch()">
                <router-link :to="'/' + item.username">
                    <div class="image">
                        <div class="png-ico verified small" v-if="item.is && item.is.verified"></div>
                        <ImagePlaceholder radius="50%" :data-src="item.photos.avatar | attach('m')"/>
                    </div>
                </router-link>
            </div>
            <div class="user-bottom">
                <div class="user-bottom-fullname uk-text-truncate f-s-085" @click="closeSearch()">
                    <router-link class="a-with-png-ico" :to="'/' + item.username">
                        <div class="uk-text-truncate user-top-fullname">
                            {{item.fullname}}
                        </div>
                    </router-link>
                </div>
                <div class="user-bottom-username uk-text-truncate f-s-070">
                    @{{item.username}}
                </div>
                <div class="user-bottom-action">
                    <button @click="follow(item)" v-if="!item.is || (item.is && !item.is.following)" class="app-button f-s-080">{{'follow' | lang}}</button>
                    <button @click="follow(item)" v-if="item.is && item.is.following" class="app-button active f-s-080">{{'unfollow' | lang}}</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import ImagePlaceholder from "@/components/Tools/ImagePlaceholder";
    import helper from "@/helpers/helper"
    export default {
        data() {
            return {
                helper: helper
            }
        },
        props: ['item', 'index', 'options'],
        methods: {
            closeSearch() {
                bus.$emit('closeSearch')
            },
            follow(item) {
                this.helper.follow(item)
            }
        },
        components: {
            ImagePlaceholder
        },
    }
</script>