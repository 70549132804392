<template>
    <div class="vow-select">
        <div ref="main" class="ninp tp" @click="clickOnMain">
            <div v-for="(si, i) in selectedItems" :key="i" class="itm" v-show="!isFocus">
                <div class="l">
                    <img :src="findImage(si)"/>
                </div>
                <div class="r">
                    {{si[itemText]}}
                </div>
            </div>
            <input type="text" ref="inp" class="inp" @click="clickOnMain" v-model="search" @focusout="isFocus = false"/>
        </div>
        <div ref="sub" class="ts" :style="cPos" v-show="items.length">
            <div class="itm" v-for="(itm, i) in items" :key="i" @click="doSelectItem(itm)">
                <div class="l">
                    <img :src="findImage(itm)"/>
                </div>
                <div class="r">
                    {{itm[itemText]}}
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios'
export default {
    props: {
        fields: {type: Array},
        itemValue: {type: String, default: 'value'},
        itemText: {type: String, default: 'title'},
        url: {type: String, default: ''}
    },
    data() {
        return {
            pos: 'down',
            cPos: {
                left: 0,
                top: '100%',
            },
            items: [],
            getCancelToken: null,
            search: '',
            selectedItems: [],
            isFocus: false
        }
    },
    computed: {
        img() {

        }
    },
    methods: {
        doSelectItem(itm) {
            this.selectedItems = [itm];
            this.items = []
            this.search = ''
        },
        clickOnMain() {
            this.isFocus = true;
            let pos = 'down'
            const t = this.$refs.main.getBoundingClientRect();
            const a = (t.height / 2) + t.y
            const b = window.innerHeight / 2
            if(a > b) {
                pos = 'up';
            }
            this.pos = pos;
            this.getPos()
        },
        getPos() {
            const a = this.$refs.main.getBoundingClientRect();
            let l = 0;
            let t = 0;
            if(this.pos === 'down') {
                this.cPos = {
                    left: '0',
                    top: '100%'
                }
            } else {
                this.cPos = {
                    left: '0',
                    bottom: '100%'
                }
            }
            // todo: rtl fix
        },
        findImage(itm) {
            if(itm.photos !== undefined && itm.photos.avatar !== undefined) {
                return this.$options.filters.attach(itm.photos.avatar, 's')
            }
        },
        get() {
            if(this.getCancelToken !== null) {
                this.getCancelToken.cancel();
            }
            this.getCancelToken = axios.CancelToken.source()
            axios.get(this.url, {
                cancelToken: this.getCancelToken.token,
                params: {
                    q: this.search,
                    count: 3
                }
            })
            .then((res) => {
                this.items = res.data
            })
        }
    },
    watch: {
        search() {
            this.get()
        },
        selectedItems(v) {
            this.$emit('upd', v)
        }
    },
    mounted: {
    },

}
</script>