<template>
  <div v-if="show" class="uk-animation-fade uk-animation-10">
    <Empty text="This user have had no activity yet." />
  </div>
</template>
<script>
import Empty from "@/components/Tools/Empty";
export default {
  components: { Empty },
  data() {
    return {
      show: false,
    };
  },
  watch: {
    '$route': function () {
      this.show = false;
    },
  },
  mounted() {
    bus.$off("gnoPosts")
    bus.$on(
      "gnoPosts",
      () => {
        this.show = true;
      }
    );
  },
  beforeDestroy() {
    // bus.$off(
    //   (this.$route.params.username != undefined
    //     ? this.$route.params.username
    //     : "") + "gnoPosts"
    // );
  },
};
</script>