<template>
  <div
    class="widget padding-x user-list-items"
    v-if="items.length && items[0].username !== undefined"
    ref="mainContainer"
  >
    <div>
      <div class="widget-title" v-if="options.title != undefined">
        <h4 class="f-s-13">{{ options.title | lang }}</h4>
        <h6 class="f-s-090">
          {{
            options.subtitle
              | lang({
                artist:
                  value != undefined && value.fullname != undefined
                    ? value.fullname
                    : "artist",
              })
          }}
        </h6>
      </div>
      <div class="widget-content user-slider-widget">
        <div class="user-slider">
          <div class="user-slider-items" ref="userSliderContents">
            <flicking
              :options="{
                renderOnlyVisible: false,
                align: 'prev',
                preventClickOnDrag: true,
                bound: true,
                circularFallback: 'bound',
              }"
            >
              <div
                class="flicking-panel"
                v-for="(item, index) in items"
                :key="index"
              >
                <CircleUserItem
                  :options="{ subclass: 'user-slider-item' }"
                  :item="item"
                />
              </div>
            </flicking>
          </div>
        </div>
        <div v-if="isLoading" class="uk-text-center">
          <i uk-spinner></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircleUserItem from "@/components/Items/CircleUserItem";
import axios from "axios";
export default {
  props: {
    staticItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    static: {
      type: Boolean,
      default: false,
    },
    value: {},
    options: {
      type: Object,
      default: () => {
        return {};
      },
    },
    breakpoints: {
      type: Object,
      default: () => {
        return {
          640: {
            slidesPerView: "auto",
            spaceBetween: 0,
            freeMode: true,
            freeModeMomentum: true,
            freeModeMomentumRatio: "0.5",
          },
          1080: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
          1600: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
          2000: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
          10000: {
            slidesPerView: "auto",
            spaceBetween: 0,
          },
        };
        // return {
        //     640: {
        //         slidesPerView: 3.2,
        //         spaceBetween: 12,
        //         freeMode: true,
        //         freeModeMomentum: true,
        //         freeModeMomentumRatio: '0.5',
        //     },
        //     1080: {
        //         slidesPerView: 4.5,
        //         spaceBetween: 10
        //     },
        //     1400: {
        //         slidesPerView: 6.5,
        //         spaceBetween: 20
        //     },
        //     1500: {
        //         slidesPerView: 8.5,
        //         spaceBetween: 20
        //     },
        //     2000: {
        //         slidesPerView: 7.5,
        //         spaceBetween: 30
        //     },
        //     10000: {
        //         slidesPerView: 10.5,
        //         spaceBetween: 30
        //     }
        // }
      },
    },
  },
  directives: {},
  components: { CircleUserItem },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  data() {
    return {
      run: false,
      localkey: Math.floor(Math.random() * 1000),
      items: [],
      isLoading: false,
    };
  },
  watch: {
    $route: function () {
      this.parseDara();
    },
    staticItems: function () {
      this.parseDara();
    },
    // value: function() {
    // this.getData()
    // }
  },
  beforeDestroy() {},

  methods: {
    parseDara() {
      if (
        this.staticItems !== undefined &&
        this.staticItems &&
        this.staticItems.length
      ) {
        this.items = this.staticItems;
        return;
      }
      let routeName = this.$route.name;
      if (routeName === "profileArtist") {
        routeName += "_" + this.$route.params.username;
      }
      if (routeName === this.routeKey) {
        return;
      } else {
        this.items = [];
        this.coversLoadedStatus = [];
      }
      this.routeKey = routeName;
      if (this.$route.name === "profileArtist") {
        axios
          .get(
            `/users/related?count=15&username=${this.$route.params.username}`
          )
          .then((res) => {
            const dt = res.data;
            this.items = dt;
          });
      } else {
        axios.get(this.options.url).then((res) => {
          const dt = res.data;
          this.items = dt;
        });
      }
    },
  },
  mounted() {
    this.parseDara();
  },
  beforeDestroy() {},
};
</script>