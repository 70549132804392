<template>
    <SteppedModal
            ref="steppedModal"
            :disableClose="disableClose"
            :stepsArr="stepsArr"
            :currentStep="currentStep"
            :background="data.photo | attach('blur')"
            @close="close"
            :show="show">
        <div slot="header0">
            Report: {{data.type === 'user' ? ('@' + data.title) : data.title }}
        </div>
        <div slot="body0" v-if="settings!=null" class="modal-with-image">
            <div class="uk-text-center">
                <img :class="{'rounded': data.type==='user'}" :src="data.photo | attach('s')"/>
            </div>
            <div class="modal-radio-container">
                <div v-for="(k, v) in settings.reportReasons[data.type]" class="f-s-080">
                    <label><input v-model="data.reason" class="uk-radio" type="radio" :value="k"> {{v}}</label>
                </div>
            </div>
            <div class="uk-margin">
                <textarea v-model="data.description" rows="4" class="uk-textarea uk-form-small" placeholder="Description"></textarea>
            </div>
        </div>
        <div slot="footer">
            <div class="uk-flex">
                <button @click="submit()" class="uk-button button-top-menu button-bg uk-button-default flex-right">
                    <span v-if="!inProcess">
                        Submit
                    </span>
                    <span v-if="inProcess">
                        <i uk-spinner="ratio: 0.5"></i>
                    </span>
                </button>
            </div>
        </div>
    </SteppedModal>
</template>
<script>
  import SteppedModal from './SteppedModal'
  import axios from 'axios'
  export default {
    components: {
      SteppedModal
    },
    data () {
      return {
        stepsArr: [0, 1],
        inProcess: false
      }
    },
    props: {
      show: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default: () => {
          return {}
        }
      },
      currentStep: {
        type: Number,
        default: 0
      }
    },
    computed: {
      auth () {
        return this.$store.getters.getProfile
      },
      settings () {
        return this.$store.getters.settings
      },
      disableClose () {
        return this.inProcess
      }
    },
    methods: {
      close () {
        this.$emit('close')
        this.show = false
      },
      submit() {
        let vm = this
        this.inProcess = true
        axios.post(API.report, this.data)
          .then((res) => {
            vm.inProcess = false
            bus.$emit('success', {
              title: '',
              message: res.data.message
            })
            vm.close()
          })
          .catch((e) => {
            vm.inProcess = false
            let msg = e.response.data.message
            if(e.response.status===422) {
              let err = e.response.data.errors
              let errKeys = Object.keys(err)
              msg = err[errKeys[0]][0]
            }
            bus.$emit('error', {
              title: 'Error',
              message: msg
            })
          })
      }
    }
  }
</script>