var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "SteppedModal",
    {
      ref: "steppedModal",
      attrs: {
        disableClose: _vm.disableClose,
        stepsArr: _vm.stepsArr,
        currentStep: _vm.currentStep,
        background: _vm._f("attach")(_vm.data.photo, "blur"),
        show: _vm.show,
      },
      on: { close: _vm.close },
    },
    [
      _c("div", { attrs: { slot: "header0" }, slot: "header0" }, [
        _vm._v(
          "\n        Report: " +
            _vm._s(
              _vm.data.type === "user" ? "@" + _vm.data.title : _vm.data.title
            ) +
            "\n    "
        ),
      ]),
      _vm._v(" "),
      _vm.settings != null
        ? _c(
            "div",
            {
              staticClass: "modal-with-image",
              attrs: { slot: "body0" },
              slot: "body0",
            },
            [
              _c("div", { staticClass: "uk-text-center" }, [
                _c("img", {
                  class: { rounded: _vm.data.type === "user" },
                  attrs: { src: _vm._f("attach")(_vm.data.photo, "s") },
                }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "modal-radio-container" },
                _vm._l(
                  _vm.settings.reportReasons[_vm.data.type],
                  function (k, v) {
                    return _c("div", { staticClass: "f-s-080" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.data.reason,
                              expression: "data.reason",
                            },
                          ],
                          staticClass: "uk-radio",
                          attrs: { type: "radio" },
                          domProps: {
                            value: k,
                            checked: _vm._q(_vm.data.reason, k),
                          },
                          on: {
                            change: function ($event) {
                              return _vm.$set(_vm.data, "reason", k)
                            },
                          },
                        }),
                        _vm._v(" " + _vm._s(v)),
                      ]),
                    ])
                  }
                ),
                0
              ),
              _vm._v(" "),
              _c("div", { staticClass: "uk-margin" }, [
                _c("textarea", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.data.description,
                      expression: "data.description",
                    },
                  ],
                  staticClass: "uk-textarea uk-form-small",
                  attrs: { rows: "4", placeholder: "Description" },
                  domProps: { value: _vm.data.description },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(_vm.data, "description", $event.target.value)
                    },
                  },
                }),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
        _c("div", { staticClass: "uk-flex" }, [
          _c(
            "button",
            {
              staticClass:
                "uk-button button-top-menu button-bg uk-button-default flex-right",
              on: {
                click: function ($event) {
                  return _vm.submit()
                },
              },
            },
            [
              !_vm.inProcess
                ? _c("span", [
                    _vm._v("\n                    Submit\n                "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.inProcess
                ? _c("span", [
                    _c("i", { attrs: { "uk-spinner": "ratio: 0.5" } }),
                  ])
                : _vm._e(),
            ]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }