var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.realId != null && _vm.realId != undefined
    ? _c("div", [
        !_vm.canComment
          ? _c("div", { staticClass: "disabled-comment" }, [_vm._m(0)])
          : _vm._e(),
        _vm._v(" "),
        _vm.canComment && _vm.commentsCnt === 0
          ? _c("div", { staticClass: "disabled-comment" }, [_vm._m(1)])
          : _vm._e(),
        _vm._v(" "),
        _vm.canComment && _vm.realCommentsCnt > 0
          ? _c("div", { staticClass: "post-comments" }, [
              _c("div", { staticClass: "comments-container" }, [
                _vm.comments.length
                  ? _c(
                      "ul",
                      {
                        staticClass: "comments-list",
                        attrs: { id: "comments-list" },
                      },
                      _vm._l(_vm.comments, function (comment, index) {
                        return _c(
                          "li",
                          {
                            key: index,
                            class: {
                              pending:
                                comment.pending != undefined && comment.pending,
                            },
                          },
                          [
                            _c("div", { staticClass: "comment-main-level" }, [
                              _c("div", { staticClass: "comment-avatar" }, [
                                _c("img", {
                                  attrs: {
                                    src: _vm._f("attach")(
                                      comment.user.photos.avatar,
                                      "s"
                                    ),
                                    alt: "",
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "comment-box" }, [
                                _c("div", { staticClass: "comment-head" }, [
                                  _c(
                                    "h6",
                                    { staticClass: "comment-name f-s-085" },
                                    [
                                      _c(
                                        "router-link",
                                        {
                                          attrs: {
                                            to: "/" + comment.user.username,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(comment.user.fullname) +
                                              "\n                                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("span", [_vm._v(" • ")]),
                                  _vm._v(" "),
                                  _c("span", { staticClass: "f-s-080" }, [
                                    _vm._v(_vm._s(comment.createdAtAgo)),
                                  ]),
                                  _vm._v(" "),
                                  comment.id !== undefined
                                    ? _c("i", {
                                        staticClass: "fa fa-reply",
                                        on: {
                                          click: function ($event) {
                                            return _vm.replyCm(comment)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ]),
                                _vm._v(" "),
                                _c("div", {
                                  staticClass:
                                    "comment-content f-s-085 f-f-reg",
                                  domProps: {
                                    innerHTML: _vm._s(
                                      _vm.$options.filters.link(comment.comment)
                                    ),
                                  },
                                }),
                                _vm._v(" "),
                                _vm.currentReply === comment.id
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "comment-new-reply f-s-085",
                                      },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "focus",
                                              rawName: "v-focus",
                                            },
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.commentTxt,
                                              expression: "commentTxt",
                                            },
                                          ],
                                          ref: "repInput",
                                          refInFor: true,
                                          staticClass: "f-s-080",
                                          attrs: {
                                            type: "text",
                                            autofocus: "",
                                            placeholder:
                                              "Reply to @" +
                                              comment.user.username,
                                          },
                                          domProps: { value: _vm.commentTxt },
                                          on: {
                                            keyup: _vm.keyupReply,
                                            input: function ($event) {
                                              if ($event.target.composing)
                                                return
                                              _vm.commentTxt =
                                                $event.target.value
                                            },
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                              ]),
                            ]),
                            _vm._v(" "),
                            comment.replies != undefined &&
                            comment.replies.length
                              ? _c(
                                  "ul",
                                  { staticClass: "comments-list reply-list" },
                                  [
                                    _c("div", [
                                      _vm.repliesInLoad.indexOf(comment.id) !=
                                      -1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "load-more-spinner",
                                            },
                                            [
                                              _c("div", {
                                                attrs: { "uk-spinner": "" },
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.repliesInLoad.indexOf(comment.id) ===
                                        -1 &&
                                      comment.replies.length <
                                        comment.repliesCount
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "load-more-replies",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.loadMoreReplies(
                                                    comment
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                Show More Replies…\n                            "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(comment.replies, function (reply) {
                                      return _c(
                                        "li",
                                        { key: reply.id || 100 },
                                        [
                                          _c(
                                            "div",
                                            { staticClass: "comment-avatar" },
                                            [
                                              _c("img", {
                                                attrs: {
                                                  src: _vm._f("attach")(
                                                    reply.user.photos.avatar,
                                                    "s"
                                                  ),
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "comment-box" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "comment-head" },
                                                [
                                                  _c(
                                                    "h6",
                                                    {
                                                      staticClass:
                                                        "comment-name f-s-085",
                                                    },
                                                    [
                                                      _c(
                                                        "router-link",
                                                        {
                                                          attrs: {
                                                            to:
                                                              "/" +
                                                              reply.user
                                                                .username,
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              reply.user
                                                                .fullname
                                                            ) +
                                                              "\n                                        "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c("span", [_vm._v(" • ")]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "f-s-080" },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          reply.createdAtAgo
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  reply.id !== undefined
                                                    ? _c("i", {
                                                        staticClass:
                                                          "fa fa-reply",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.replyCm(
                                                              comment,
                                                              reply
                                                            )
                                                          },
                                                        },
                                                      })
                                                    : _vm._e(),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                staticClass:
                                                  "comment-content f-s-085 f-f-reg",
                                                domProps: {
                                                  innerHTML: _vm._s(
                                                    _vm.$options.filters.link(
                                                      reply.comment
                                                    )
                                                  ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.currentReply === reply.id
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "comment-new-reply f-s-085",
                                                    },
                                                    [
                                                      _c("input", {
                                                        directives: [
                                                          {
                                                            name: "focus",
                                                            rawName: "v-focus",
                                                          },
                                                          {
                                                            name: "model",
                                                            rawName: "v-model",
                                                            value:
                                                              _vm.commentTxt,
                                                            expression:
                                                              "commentTxt",
                                                          },
                                                        ],
                                                        ref: "repInput",
                                                        refInFor: true,
                                                        staticClass: "f-s-080",
                                                        attrs: {
                                                          type: "text",
                                                          autofocus: "",
                                                          placeholder:
                                                            "Reply to @" +
                                                            comment.user
                                                              .username,
                                                        },
                                                        domProps: {
                                                          value: _vm.commentTxt,
                                                        },
                                                        on: {
                                                          keyup: _vm.keyupReply,
                                                          input: function (
                                                            $event
                                                          ) {
                                                            if (
                                                              $event.target
                                                                .composing
                                                            )
                                                              return
                                                            _vm.commentTxt =
                                                              $event.target.value
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                        ]
                                      )
                                    }),
                                  ],
                                  2
                                )
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.comments.length < _vm.realCommentsCnt
                  ? _c("div", [
                      _vm.commentsInLoad
                        ? _c("div", { staticClass: "load-more-spinner" }, [
                            _c("div", { attrs: { "uk-spinner": "" } }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.commentsInLoad
                        ? _c(
                            "div",
                            {
                              staticClass: "load-more-replies",
                              on: {
                                click: function ($event) {
                                  return _vm.getComments(true)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Show More…\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ])
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "uk-grid-10 uk-flex uk-flex-middle",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "width-30 uk-text-center" }, [
            _c("i", { staticClass: "fal fa-comment-alt-slash" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand" }, [
          _vm._v("Comments for this post have been limited."),
        ]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "uk-grid-10 uk-flex uk-flex-middle",
        attrs: { "uk-grid": "" },
      },
      [
        _c("div", { staticClass: "uk-width-auto" }, [
          _c("div", { staticClass: "width-30 uk-text-center" }, [
            _c("i", { staticClass: "fal fa-comment-alt" }),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "uk-width-expand" }, [
          _vm._v("No one has commented yet."),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }