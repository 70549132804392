<template>
    <div>
    </div>
</template>

<script>
import helper from '@/helpers/helper'
import { Core, StatusBar, DragDrop, Tus } from 'uppy'
import ID3 from '@/helpers/id3.minimized'

export default {
    data() {
        return {
            options: {
                target: API.baseURL + API.posts,
                testChunks: false
            },
            attrs: {
                accept: 'image/*'
            },
            upload: {
                files: [],
                size: 0,
                uploadedSize: 0,
                progress: 0
            },
            initialized: false
        }
    },
    computed: {
        token() {
            return localStorage.getItem('user-token')
        },
        allowedFileTypes() {
            let fileTypes = this.$store.getters.settings.allowedExtensions
            return fileTypes.map((e) => {
                return '.' + e
            })
        }
    },
    methods: {
        calcUploadProgress() {
            if (!this.upload.files.length || this.upload.size === 0) return '0'
            let progress = 0
            let uploadedSize = 0
            for (let i = 0; i < this.upload.files.length; i++) {
                uploadedSize += this.upload.files[i].uploadedSize
            }
            progress = Math.floor((uploadedSize / this.upload.size) * 100)
            bus.$emit('uploadProgress', { uploadedSize: uploadedSize, size: this.upload.size })
            this.upload.progress = progress
        },
        initUploader() {
            if (!this.initialized) {
                let uploaderInterval = setInterval(() => {
                    if (document.getElementById('UppyDragDrop') !== null && !!this.$store.getters.settings) {
                        this.initUppy()
                        clearInterval(uploaderInterval)
                    }
                }, 500)
            }
        },
        initUppy() {
            this.initialized = true
            let uppy = new Core({
                debug: true, autoProceed: true, restrictions: {
                    maxFileSize: this.$store.getters.settings.maxUploadSize * 1024 * 1024,
                    maxNumberOfFiles: 10,
                    allowedFileTypes: this.allowedFileTypes
                }
            });
            uppy.use(StatusBar, {
                target: '.upload-status-bar',
                hideUploadButton: true,
                hideAfterFinish: true,
                showProgressDetails: true,
            })
            uppy.use(DragDrop, { target: '#UppyDragDrop' })
            //        .use(Uppy.ProgressBar, {
            //          target: 'body',
            //          fixed: true,
            //          hideAfterFinish: true
            //        })
            uppy.use(Tus, {
                endpoint: 'https://processing.vowave.com/uploads',
                retryDelays: [0, 1000, 3000, 5000],
                chunkSize: 1024 * 1024,
                headers: {
                    Authorization: this.token
                }
            })
            uppy.on('upload-progress', (file, progress) => {
                // file: { id, name, type, ... }
                // progress: { uploader, bytesUploaded, bytesTotal }
                //let filenames = this.$_.pluck(this.upload.files, 'fileName');
                //this.upload.files[filenames.indexOf(file.name)].uploadedSize = progress.bytesUploaded
                //this.calcUploadProgress()
                //let percent = Math.floor(progress.bytesUploaded/progress.bytesTotal*100)
                //          console.log(file.id, percent)
                bus.$emit('updateUploadStatus', {
                    file: file,
                    progress: progress
                })
            })
                .on('restriction-failed', (file, error) => {
                    bus.$emit('error', {
                        title: '',
                        message: error.toString()
                    })
                })
                .on('cancel-all', () => {
                    bus.$emit('cancelUpload', true)
                })
                .on('upload-error', (file, error, response) => {
                    bus.$emit('uploadError', file.id)
                })
                .on('upload-retry', (fileID) => {
                    bus.$emit('uploadRetry', fileID)
                })
                .on('file-added', async (file) => {
                    helper.mbdAdd(file.id)
                    if (file.type.indexOf('video') !== -1 || file.extension === 'flv') {
                        let f = {
                            uploadedSize: 0,
                            fileName: file.name,
                            size: file.size,
                            percent: 0,
                            file: file.data,
                            id3: {},
                            id: file.id,
                            pt: 'VID'
                        }
                        this.upload.size += file.size
                        bus.$emit('uploadFileAdded', f)
                        this.upload.files.push(f)
                    } else {
                        ID3.loadTags(file.data, () => {
                            let id3 = ID3.getAllTags(file.data);
                            let f = {
                                uploadedSize: 0,
                                fileName: file.name,
                                size: file.size,
                                percent: 0,
                                id3: id3,
                                id: file.id,
                                pt: 'SND'
                            }
                            this.upload.size += file.size
                            bus.$emit('uploadFileAdded', f)
                            this.upload.files.push(f)
                        }, {
                            tags: ["title", "artist", "album", "picture"],
                            dataReader: ID3.FileAPIReader(file.data)
                        })
                    }

                })
                .on('upload', (data) => {
                    bus.$emit('upload')
                })

            uppy.on('complete', (result) => {
                // console.log('result', result)
                bus.$emit('uploadComplete', result)
            })
            bus.$on('removeFile', (fileId) => {
                uppy.removeFile(fileId)
                uppy.uppy.retryAll()
            })
            bus.$on('doCancelUpload', () => {
                uppy.cancelAll()
            })
        },
        getId3(file) {
            return new Promise(async (resolve, reject) => {
                try {
                    let id3 = await id3.fromFile(file)
                    resolve(id3)
                } catch (e) {
                    reject(null)
                }
            })
        }
    },
    mounted() {
        if (this.$route.name === 'upload') {
            this.initUploader()
        }
    },
    watch: {
        '$route': function (val) {
            if (val.name === 'upload') {
                this.initUploader()
            }
        }
    }

}
</script>