var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "padding-x padding-y" },
    [
      _c("Items", {
        class: _vm.tclass,
        attrs: {
          type: _vm.template,
          options: { subclass: _vm.tsubclass },
          items: _vm.items,
        },
      }),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "uk-text-center" }, [
            _c("i", { attrs: { "uk-spinner": "" } }),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading && !_vm.items.length ? _c("Empty") : _vm._e(),
      _vm._v(" "),
      !_vm.loading && !_vm.end
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "is-visibility",
                  rawName: "v-is-visibility",
                  value: (visible) => _vm.tryLoadMore(visible),
                  expression: "(visible) => tryLoadMore(visible)",
                },
              ],
              staticClass: "uk-text-center",
            },
            [_c("i", { attrs: { "uk-spinner": "" } })]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }