<template>
  <div v-if="items.length" class="widget padding-x" v-resize="onResize">
    <div class="widget-title">
      <div class="uk-flex uk-flex-middle" uk-grid>
        <div class="uk-width-expand">
          <h4 class="f-s-13">{{ options.title | lang }}</h4>
          <h6 class="f-s-090">{{ options.subtitle | lang }}</h6>
        </div>
      </div>
    </div>
    <div class="widget-content tile-slider-widget">
      <div class="tile-slider">
        <div
          class="tile-slider-items uk-animation-fade uk-animation-10"
          ref="tileSliderContents"
          :style="{ height: height }"
        >
          <flicking
            :options="{
              renderOnlyVisible: false,
              align: 'prev',
              preventClickOnDrag: true,
              bound: true,
              circularFallback: 'bound',
              panelsPerView: slidesPerView,
            }"
          >
            <div
              class="flicking-panel tile-slider-item-pack"
              v-for="(itemPack, indexx) in items"
              :key="'pack_' + indexx"
            >
              <Items
                class="v-post-items"
                :items="itemPack"
                :options="{ showViews: true }"
                :type="'VPostItem'"
              />
            </div>
          </flicking>
          <div class="uk-clearfix"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import helper from "../../helpers/helper.js";
import Items from "../Items/Items";
import axios from "axios";
export default {
  props: ["options", "value"],
  watch: {
    items: function () {
      let vm = this;
    },
    $route: function (newVal) {
      this.parseData();
    },
    // value: function (val) {
    //     this.dataa = []
    //     this.items = []
    //     if (val === null || val[this.options.url] === undefined) {
    //         this.sliderInit = false
    //         this.items = []
    //         return
    //     }
    //     this.dataa = val[this.options.url]
    //     this.parseData()
    // },
    itemsTmp(dataa) {
      let items = [];
      let itemsTmp = [];
      for (let i = 0; i < dataa.length; i++) {
        let itm = dataa[i];
        itemsTmp.push(itm);
        itm.imageLoaded = false;
        if (itemsTmp.length > this.itemsPerCol - 1) {
          console.debug("1");
          items.push(itemsTmp);
          itemsTmp = [];
        } else if (
          dataa.length % this.itemsPerCol != 0 &&
          dataa.length - 1 === i
        ) {
          console.debug("2");
          items.push(itemsTmp);
        }
      }
      this.items = items;
    },
  },
  directives: {},
  components: {
    Items,
  },
  computed: {
    slidesPerView() {
      if (this.items.length <= 1) {
        return 1;
      }
      try {
        const keys = Object.keys(this.breakpoints);
        let br = null;
        for (const k of keys) {
          if (k >= window.screen.width) {
            br = this.breakpoints[k];
            break;
          }
        }
        return br.slidesPerView;
      } catch (e) {
        // console.log("e", e);
        return 1.8;
      }
    },
  },
  data() {
    return {
      routeKey: "",
      itemsPerCol: 3,
      dataa: [],
      helper: helper,
      imagesLoadedCnt: 0,
      imagesLoaded: false,
      items: [
        [{}, {}, {}],
        [{}, {}, {}],
      ],
      itemsCnt: 0,
      itemsToShow: 2.5,
      itemsTmp: [],
      emptyCount: [...Array(20).keys()],
      key: Math.random(),
      parentKey: Math.floor(Math.random() * 10000),
      height: "auto",
      width: 0,
      currentSlide: 0,
      coversLoadedStatus: 0,
      sliderInit: false,
      mouse: {
        startedAt: 0,
        endAt: 0,
        lastPos: 0,
      },
      breakpoints: {
        1200: {
          slidesPerView: 1.1,
          spaceBetween: 10,
        },
        2000: {
          slidesPerView: 2.1,
          spaceBetween: 10,
        },
        3000: {
          slidesPerView: 2.1,
          spaceBetween: 10,
        },
      },
    };
  },
  created() {
    this.items = [];
  },
  methods: {
    like(item) {
      let res = this.helper.like(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    repost(item) {
      let res = this.helper.repost(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    coversLoaded(res) {
      if (res.progressedCount === this.itemsCnt) this.coversLoadedStatus = true;
    },
    parseData(opt) {
      let routeName = this.$route.name;
      if (routeName === "profileArtist") {
        routeName += "_" + this.$route.params.username;
      }
      if (routeName === this.routeKey) {
        return;
      } else {
        this.items = [
          [{}, {}, {}],
          [{}, {}, {}],
        ];
      }
      this.routeKey = routeName;
      if (this.$route.name === "profileArtist") {
        axios
          .get(
            `/posts?count=15&username=${this.$route.params.username}&postType=SND&popular=true&noText=true`
          )
          .then((res) => {
            const dt = res.data;
            this.itemsTmp = [];
            // this.key = Math.random()
            this.itemsCnt = 0;
            let itemsPerCol = 3;
            let itm;
            let items = [];
            let itemsTmp = [];
            this.coversLoadedStatus = false;
            for (let i = 0; i < dt.length; i++) {
              itm = dt[i];
              this.itemsTmp.push(itm);
              itm.imageLoaded = false;
              itemsTmp.push(itm);
              this.itemsCnt++;
              if (itemsTmp.length > itemsPerCol - 1) {
                items.push(itemsTmp);
                itemsTmp = [];
              } else if (dt.length % itemsPerCol != 0 && dt.length - 1 === i) {
                items.push(itemsTmp);
              }
            }

            this.items = items;
            bus.$emit("profileArtistWidgets", {
              items: this.items.length,
              username: this.$route.params.username,
            });
          });
      }
      return;
    },
  },
  mounted() {
    this.parseData();
    // bus.$on((this.$route.params.username!==undefined ? this.$route.params.username : '') + 'g' + this.options.url, (data) => {
    //     this.itemsTmp = data
    // })
    bus.$on("deletePost", (realId) => {
      let ids = this.$_.pluck(this.dataa, "realId");
      let index = ids.indexOf(realId);
      if (index !== -1) {
        this.dataa.splice(index, 1);
        this.parseData();
        this.onResize();
      }
    });
  },
  beforeDestroy() {
    bus.$off(
      (this.$route.params.username !== undefined
        ? this.$route.params.username
        : "") +
        "g" +
        this.options.url
    );
  },
};
</script>