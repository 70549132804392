<template>
    <div class="line" :style="{width: width, height: height, borderRadius: height}"></div>
</template>
<style>
    .line {
        height: 7px;
        margin: 0;
        border-radius: 7px;
        background-image: var(--profile-cover-loading);
        background-size: 300px;
        animation: shine-lines 1.6s infinite linear;
    }
</style>
<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            width: {
                type: String,
                default: '52px'
            },
            height: {
                type: String,
                default: '7px'
            }
        }
    }
</script>