import Vue from 'vue'
import Router from 'vue-router'
import Explore from '@/components/Explore'
import Contact from "@/components/Page/Contact";
import Privacy from "@/components/Page/Privacy";
import About from "@/components/Page/About";
import Terms from "@/components/Page/Terms";
import NotFound from "@/components/Page/404"
import EditPost from "@/components/Widgets/EditPost";
import Claim from "@/components/Page/Claim"
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
};


Vue.use(Router)

let router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            name: 'explore',
            component: Explore
        },
        {
            path: '/claim',
            name: 'claim',
            component: Claim
        },
        {
            path: '/search/:type?',
            name: 'search',
        },
        {
            path: '/404',
            name: 'notfound',
            component: NotFound
        },
        {
            path: '/edit/:id',
            name: 'edit',
            component: EditPost,
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/contact-us',
            name: 'contact',
            component: Contact
        },
        {
            path: '/privacy',
            name: 'privacy',
            component: Privacy
        },
        {
            path: '/about-us',
            name: 'about',
            component: About
        },
        {
            path: '/terms',
            name: 'terms',
            component: Terms
        },
        {
            path: '/explore/:type?',
            name: 'exploreFilter'
        },
        {
            path: '/upload',
            name: 'upload',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/activate/:username/:code',
            name: 'explore',
            meta: {
                guest: true
            }
        },
        {
            path: '/login',
            name: 'explore',
            meta: {
                guest: true
            }
        },
        {
            path: '/register',
            name: 'explore',
            meta: {
                guest: true
            }
        },
        {
            path: '/set-token/:token',
            name: 'explore'
        },
        {
            path: '/forgot-password',
            name: 'explore'
        },
        {
            path: '/reset-password/:token',
            name: 'explore'
        },
        {
            path: '/charts/:type?',
            name: 'charts'
        },
        {
            path: '/notifications',
            name: 'notifications',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/settings/:type?',
            name: 'settings',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/payments',
            name: 'payments',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/discover',
            name: 'discover'
        },
        {
            path: '/chat/:username?',
            name: 'chat',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/feed',
            name: 'feed',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/likes',
            name: 'likes',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/downloads',
            name: 'downloads',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/history',
            name: 'history',
            meta: {
                requiresAuth: true
            }
        },
        {
            path: '/:username',
            name: 'profileArtist',
        },
        {
            path: '/:username/:type(playlists|sounds|albums|videos+)',
            name: 'profileSeeAll'
        },
        {
            path: '/:username/video/:slug',
            name: 'video',
        }
        ,
        {
            path: '/:username/album/:slug',
            name: 'album',
        }
        ,
        {
            path: '/:username/playlist/:slug',
            name: 'playlist',
        }
        ,
        {
            path: '/:username/:slug',
            name: 'sound',
        }
    ]
});
router.beforeEach((to, from, next) => {
    if(to.matched.some(record => record.meta.requiresAuth)) {
        if (localStorage.getItem('user-token') == null) {
            next({
                path: '/login',
                params: { nextUrl: to.fullPath }
            })
        } else {
            next()
        }
    } else if(to.matched.some(record => record.meta.guest)) {
        if(localStorage.getItem('user-token') == null){
            next()
        } else{
            let user = JSON.parse(localStorage.getItem('user'));
            next({
                path: '/' + user.username
            })
        }
    }else {
        next()
    }
});
export default router