var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "moreMenuParent" }, [
    _c("div", { on: { click: _vm.onClickMoreMenu } }, [
      _c("i", { staticClass: "fal fa-ellipsis-h disable-click" }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }