var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.items.length
    ? _c(
        "div",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize",
            },
          ],
          staticClass: "widget padding-x",
        },
        [
          _c("div", { staticClass: "widget-title" }, [
            _c(
              "div",
              {
                staticClass: "uk-flex uk-flex-middle",
                attrs: { "uk-grid": "" },
              },
              [
                _c("div", { staticClass: "uk-width-expand" }, [
                  _c("h4", { staticClass: "f-s-13" }, [
                    _vm._v(_vm._s(_vm._f("lang")(_vm.options.title))),
                  ]),
                  _vm._v(" "),
                  _c("h6", { staticClass: "f-s-090" }, [
                    _vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle))),
                  ]),
                ]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "widget-content tile-slider-widget" }, [
            _c("div", { staticClass: "tile-slider" }, [
              _c(
                "div",
                {
                  ref: "tileSliderContents",
                  staticClass:
                    "tile-slider-items uk-animation-fade uk-animation-10",
                  style: { height: _vm.height },
                },
                [
                  _c(
                    "flicking",
                    {
                      attrs: {
                        options: {
                          renderOnlyVisible: false,
                          align: "prev",
                          preventClickOnDrag: true,
                          bound: true,
                          circularFallback: "bound",
                          panelsPerView: _vm.slidesPerView,
                        },
                      },
                    },
                    _vm._l(_vm.items, function (itemPack, indexx) {
                      return _c(
                        "div",
                        {
                          key: "pack_" + indexx,
                          staticClass: "flicking-panel tile-slider-item-pack",
                        },
                        [
                          _c("Items", {
                            staticClass: "v-post-items",
                            attrs: {
                              items: itemPack,
                              options: { showViews: true },
                              type: "VPostItem",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-clearfix" }),
                ],
                1
              ),
            ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }