var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { key: _vm.username },
    [
      _c("div", { staticClass: "profile-cover" }, [
        _c("div", { staticClass: "profile-cover-img" }, [
          _c("div", {
            key: _vm.coverKey,
            ref: "cover",
            class: { "uk-animation-fade": _vm.coverLoadedStatus },
            style: _vm.coverStyle,
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "profile-cover-inner" }, [
          _c(
            "div",
            {
              staticClass: "uk-grid-small uk-flex uk-flex-middle",
              attrs: { "uk-grid": "" },
            },
            [
              _c("div", { staticClass: "uk-width-auto@m" }, [
                _c("div", { staticClass: "profile-cover-container" }, [
                  _vm._m(0),
                  _vm._v(" "),
                  _vm.user.photos != undefined
                    ? _c("img", {
                        ref: "profileImage",
                        staticClass: "profile-avatar",
                        class: { "uk-animation-fade": _vm.profileLoadedStatus },
                        attrs: {
                          id: "profileImage",
                          src: _vm._f("attach")(_vm.user.photos.avatar, "m"),
                        },
                        on: {
                          load: _vm.profileLoaded,
                          click: _vm.openImageGallery,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.auth.id != undefined &&
                  _vm.user.id != undefined &&
                  _vm.auth.id === _vm.user.id
                    ? _c(
                        "div",
                        {
                          staticClass: "change-profile",
                          on: { click: _vm.changeProfilePhoto },
                        },
                        [_c("i", { staticClass: "fal fa-camera" })]
                      )
                    : _vm._e(),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "uk-width-expand@m uk-flex-stretch uk-text-center uk-text-left@m",
                },
                [
                  _c("div", { staticClass: "profile-avatar-contents" }, [
                    _c(
                      "div",
                      { staticClass: "uk-flex uk-flex-top top-section" },
                      [
                        _c("div", { staticClass: "role-name f-s-080" }, [
                          _vm._v(_vm._s(_vm.user.role)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "is-visibility",
                                rawName: "v-is-visibility",
                                value: (isVisible, entry) =>
                                  _vm.helper.isVisible(
                                    isVisible,
                                    entry,
                                    _vm.user,
                                    "user"
                                  ),
                                expression:
                                  "\n                  (isVisible, entry) =>\n                    helper.isVisible(isVisible, entry, user, 'user')\n                ",
                              },
                            ],
                            staticClass:
                              "user-name f-s-13 uk-flex uk-flex-middle",
                          },
                          [
                            _vm._v(
                              "\n                " +
                                _vm._s(_vm.user.fullname) +
                                "\n                "
                            ),
                            _vm.user !== null &&
                            _vm.user.is != undefined &&
                            _vm.user.is.verified
                              ? _c("i", {
                                  staticClass: "verified png-ico small-medium",
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.user !== null &&
                            _vm.user.is != undefined &&
                            _vm.user.is.pro
                              ? _c("i", {
                                  staticClass: "fal fa-gem f-s-14-px pro-icon",
                                })
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.user.location != null
                          ? _c("div", { staticClass: "user-location" }, [
                              _c("i", { staticClass: "far fa-map-marker-alt" }),
                              _vm._v(
                                " " +
                                  _vm._s(_vm.user.location) +
                                  "\n              "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "uk-flex uk-flex-bottom bottom-section" },
                      [
                        _c(
                          "div",
                          { staticClass: "height-100 uk-flex uk-width-1-1" },
                          [
                            _vm.user.is !== undefined
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "uk-flex uk-flex-bottom margin-0-auto@m profile-banner-bottom-container-parent",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "profile-banner-bottom-container",
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "user-stats" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass: "user-stats-views",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm._f("numberFormat")(
                                                        _vm.user.stats.views
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                                _c("span", [_vm._v("Views")]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "user-stats-followers",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.showFollowersModal(
                                                      0
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm._f("numberFormat")(
                                                        _vm.user.stats.followers
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                                _c("span", [
                                                  _vm._v("Followers"),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "user-stats-posts",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                        " +
                                                    _vm._s(
                                                      _vm._f("numberFormat")(
                                                        _vm.user.stats.posts
                                                      )
                                                    ) +
                                                    "\n                        "
                                                ),
                                                _c("span", [_vm._v("Posts")]),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "profile-buttons uk-flex",
                                          },
                                          [
                                            _vm.realPostsCnt > 0
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "uk-button uk-button-default button-profile button-profile-shuffle btn-spinner",
                                                    on: {
                                                      click: _vm.getShuffle,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-random",
                                                    }),
                                                    _vm._v(" "),
                                                    _vm.shuffleStatus === null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "uk-visible@s",
                                                          },
                                                          [_vm._v("Shuffle")]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.shuffleStatus != null
                                                      ? _c("i", {
                                                          attrs: {
                                                            "uk-spinner":
                                                              "ratio: 0.8",
                                                          },
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.auth.id === undefined ||
                                            _vm.auth.id != _vm.user.id
                                              ? _c(
                                                  "router-link",
                                                  {
                                                    staticClass:
                                                      "uk-button uk-button-default button-profile profile-button-message",
                                                    attrs: {
                                                      to:
                                                        "/chat/" +
                                                        _vm.user.username,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "far fa-envelope",
                                                    }),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "uk-visible@s",
                                                      },
                                                      [_vm._v("Message")]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _vm.auth.id === undefined ||
                                            _vm.auth.id != _vm.user.id
                                              ? _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "uk-button uk-button-default button-profile profile-button-right",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.follow()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !_vm.user.is.following
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fas fa-user-plus",
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.user.is.following
                                                      ? _c("i", {
                                                          staticClass:
                                                            "fas fa-user-minus",
                                                        })
                                                      : _vm._e(),
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.user.is.following
                                                            ? "Unfollow"
                                                            : "Follow"
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm._v(" "),
                                            _c(
                                              "button",
                                              {
                                                staticClass:
                                                  "uk-button uk-button-default button-profile profile-button-ellipsis",
                                                on: {
                                                  click: _vm.clickMoreMenu,
                                                },
                                              },
                                              [
                                                _c("i", {
                                                  staticClass:
                                                    "fas fa-ellipsis-v",
                                                }),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "menu" },
          [
            _vm.currentTab != null && _vm.user.username !== undefined
              ? _c("horizontal-menu-circle", {
                  attrs: { current: _vm.currentTab, tabs: _vm.tabs },
                  on: { tabChanged: _vm.setTab },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.user.stats != undefined
        ? _c("FollowersModal", {
            attrs: {
              tabs: 2,
              show: _vm.modals.followers.show,
              data: {
                following: _vm.user.stats.following,
                followers: _vm.user.stats.followers,
                id: _vm.user.id,
              },
            },
            on: { close: _vm.closeFollowersModal },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMe
        ? _c("my-upload", {
            ref: "profileUpload",
            attrs: {
              field: "img",
              width: 600,
              height: 600,
              fullsize: 1200,
              url: _vm.modals.profile.url,
              langType: "en",
              params: _vm.modals.profile.params,
              headers: _vm.modals.profile.headers,
              "no-square": "",
              "img-format": "jpg",
            },
            on: {
              "crop-upload-success": function ($event) {
                return _vm.cropUploadSuccess("profile", $event)
              },
              "crop-upload-fail": function ($event) {
                return _vm.cropUploadFail("profile", $event)
              },
            },
            model: {
              value: _vm.modals.profile.show,
              callback: function ($$v) {
                _vm.$set(_vm.modals.profile, "show", $$v)
              },
              expression: "modals.profile.show",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isMe
        ? _c("my-upload", {
            ref: "coverUpload",
            attrs: {
              field: "img",
              width: 2480,
              height: 520,
              fullsize: 3500,
              url: _vm.modals.cover.url,
              langType: "en",
              params: _vm.modals.cover.params,
              headers: _vm.modals.cover.headers,
              "no-square": "",
              "no-circle": "",
              "img-format": "jpg",
            },
            on: {
              "crop-upload-success": function ($event) {
                return _vm.cropUploadSuccess("cover", $event)
              },
              "crop-upload-fail": function ($event) {
                return _vm.cropUploadFail("cover", $event)
              },
            },
            model: {
              value: _vm.modals.cover.show,
              callback: function ($$v) {
                _vm.$set(_vm.modals.cover, "show", $$v)
              },
              expression: "modals.cover.show",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "empty" }, [
      _c("div", { staticClass: "avatar" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }