<template>
    <div class="uk-animation-fade uk-animation-10">
        <div :class="'splide slider-' + name">
            <div class="splide__track">
                <component tagName="ul" :is="type.indexOf('Ads')!==-1 ? 'AdsItems' : 'Items'" class="splide__list" :type="type" :options="options" :items="items"></component>
            </div>
        </div>
        <div class="uk-clearfix"></div>
    </div>
</template>
<script>
    import Items from "@/components/Items/Items";
    import Splide from '@splidejs/splide';
    import AdsItems from "@/components/Items/AdsItems";
    export default {
        components: {
            Items, AdsItems
        },
        data() {
            return {
                name: 'random-' + Math.floor(Math.random()*1000),
                times: 0,
                isLoading: false
            }
        },
        props: {
            type: {
                type: String,
                default: ''
            },
            options: {
                type: Object,
                default: () => {
                    return {}
                }
            },
            items: {
                type: Array,
                default: () => {
                    return []
                }
            },
            breakpoints: {
                type: Object,
                default: () => {
                    return {
                        320: {
                            slidesPerView: 1,
                            spaceBetween: 10
                        },
                        480: {
                            slidesPerView: 2.2,
                            spaceBetween: 12,
                            freeMode: true,
                            freeModeMomentum: true,
                            freeModeMomentumRatio: '0.5',
                        },
                        640: {
                            slidesPerView: 3.2,
                            spaceBetween: 12
                        },
                        1080: {
                            slidesPerView: 3,
                            spaceBetween: 12
                        },
                        1600: {
                            slidesPerView: 3.1,
                            spaceBetween: 12
                        },
                        2000: {
                            slidesPerView: 4.2,
                            spaceBetween: 12
                        }
                    }
                }
            }
        },
        computed: {
            sliderOptions() {
                switch(this.type) {
                    case 'UserWithoutButtonItem':
                        return {
                            autoWidth: true,
                            pagination: false,
                            trimSpace: 'move',
                            gap: '1em',
                            arrows: false
                        }
                    case 'SoundSliderItem':
                        return {
                            autoWidth: true,
                            pagination: false,
                            trimSpace: 'move',
                            gap: '1em',
                            arrows: false
                        }
                    default:
                        return {
                            autoWidth: true,
                            pagination: false,
                            trimSpace: 'move',
                            gap: '1em',
                            arrows: false
                        }
                }
            }
        },
        mounted() {
            this.name = this.$route.name.toLowerCase() + '-' + Math.floor(Math.random()*1000)
            this.initSlider()
        },
        watch: {
            '$route.name': function() {
                // this.slider.destroy(true, true)
                // this.slider = undefined
            },
            // 'items': {
            //     handler: function (items) {
            //         this.watchItems()
            //     },
            //     deep: true
            // }
        },
        beforeDestroy() {
            if(this.slider!==undefined) {
                this.slider.destroy(true, true)
            }
        },
        methods: {
            initSlider() {
                this.times++
                // console.log('init slider', this.name)
                setTimeout(() => {
                    // console.log('this.sliderOptions', this.sliderOptions)
                    this.slider = new Splide( '.slider-' + this.name, this.sliderOptions, this.times ).mount();
                }, 50)
            },
            watchItems() {
                if(this.slider===undefined) {
                    setTimeout(() => {
                        this.initSlider()
                    }, 1)
                } else {
                    this.slider.destroy()
                    setTimeout(() => {
                        this.initSlider()
                    }, 1)
                }
            }
        },
    }
</script>
<style>
    .mw-1 {
        max-width: 75px !important;
        width: 75px;
    }
    .mw-2 {
        max-width: 200px !important;
        width: 200px;
    }
</style>