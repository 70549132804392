var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass: "line",
    style: { width: _vm.width, height: _vm.height, borderRadius: _vm.height },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }