<template>
    <div class="screenshot">
        <img :src="item"/>
    </div>
</template>
<style>

</style>
<script>
    export default {
        data() {
            return {

            }
        },
        props: {
            item: {},
            index: {}
        }
    }
</script>