<template>
    <div class="widget padding-x" ref="mainContainer">
        <div>
            <div class="widget-title">
                <div class="uk-flex uk-flex-middle" uk-grid>
                    <div class="uk-width-expand">
                        <h4 class="f-s-13">{{options.title | lang}}</h4>
                        <h6 class="f-s-090">{{options.subtitle | lang}}</h6>
                    </div>
                    <div class="uk-width-auto">
                        <router-link :to="seeAllUrl" class="see-all-right">See all</router-link>
                    </div>
                </div>
            </div>
            <div class="widget-content sound-slider-widget">
                <div class="sound-slider">
                    <flicking :options="{ renderOnlyVisible: false, align: 'prev', preventClickOnDrag: true, bound: true, circularFallback: 'bound'}">
                        <div class="flicking-panel" v-for="(item, index) in items" :key="index">
                            <SoundSliderItem :item="item" :options="{showType: true}" @dop="dop" :class="{active: (item.realId!=undefined&&(activeItem===item.realId || (item.postType==='SND' && currentTrack!=null && currentTrack==item.realId) || (item.postType==='VID' && currentVideo!=null && currentVideo==item.realId))), deleting: deletings.indexOf(item.id)!==-1}"/>
                        </div>
                    </flicking>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    import helper from '../../helpers/helper.js'
    import SoundSliderItem from '@/components/Items/SoundSliderItem'

    export default {
        props: ['options', 'value'],
        components: {
            SoundSliderItem,
        },

        watch: {
            value: function (newVal) {
            },
            profileStatus(status) {
                if(status!=='loading') {
                    // this.parseData()
                }
            }
        },
        directives: {
        },
        data () {
            return {
                deletings: [],
                isLoading: false,
                items: [{},{},{},{},{}],
                sliderInit: false,
                itemsToShow: 2.5,
                initialized: false,
                emptyCount: [...Array(20).keys()],
                height: 'auto',
                helper: helper,
                breakpoints: {
                    640: {
                        slidesPerView: 'auto',
                        spaceBetween: 0,
                        freeMode: true,
                        freeModeMomentum: true,
                        freeModeMomentumRatio: '0.5',
                    },
                    1080: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    1600: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    2000: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    },
                    10000: {
                        slidesPerView: 'auto',
                        spaceBetween: 0
                    }
                },
                // breakpoints: {
                //     320: {
                //         slidesPerView: 1,
                //         spaceBetween: 10
                //     },
                //     480: {
                //         slidesPerView: 2.2,
                //         spaceBetween: 12,
                //         freeMode: true,
                //         freeModeMomentum: true,
                //         freeModeMomentumRatio: '0.5',
                //     },
                //     640: {
                //         slidesPerView: 3.2,
                //         spaceBetween: 12
                //     },
                //     1080: {
                //         slidesPerView: 3,
                //         spaceBetween: 12
                //     },
                //     1600: {
                //         slidesPerView: 3.1,
                //         spaceBetween: 12
                //     },
                //     2000: {
                //         slidesPerView: 5.2,
                //         spaceBetween: 12
                //     },
                //     10000: {
                //         slidesPerView: 8.2,
                //         spaceBetween: 12
                //     }
                // }
            }
        },
        created () {

        },
        methods: {
            dop(e) {
                bus.$emit('play', e, this.items)
            },
            moreMenu(item, e) {
                bus.$emit('clickMoreMenu', item, e, 'SoundSliderItem')
            },
            play(item) {
                if(item.realId === this.currentTrack) {
                    if(this.playerStatus === 'play') {
                        bus.$emit('playerPause')
                    } else {
                        bus.$emit('playerPlay')
                    }
                    return;
                }
                
                const getCurrentPlaylistIds = this.$store.getters.getPlaylistItemsIds;
                if(getCurrentPlaylistIds.length && getCurrentPlaylistIds.indexOf(item.realId) > -1) {

                }
                let items = []
                if(setItems===undefined) {
                    items = this.items;
                } else {
                    items = setItems;
                }

                let sI = null, sP = null;

                if (item.tracks != undefined && item.tracks.length) {
                    sI = item.tracks[0]
                    sP = item.tracks
                } else if (item.postType === 'SND') {
                    let realItems = [];
                    items = items.filter(e => {return ['SND','VID'].indexOf(e.postType)>-1})
                    let itemsIds = this.$_.pluck(items, 'realId');
                    let itemIndex = itemsIds.indexOf(item.realId);
                    for(let i = itemIndex; i<items.length; i++) {
                        realItems.push(items[i]);
                        if(realItems.length>=cnt) break;
                    }
                    if(realItems.length<cnt) {
                        for(let i = itemIndex-1; i>=0; i--) {
                            realItems.unshift(items[i]);
                            if(realItems.length>=cnt) break;
                        }
                    }
                    sI = item
                    sP = realItems
                }
                this.$store.dispatch('setCurrent', sI)
                setTimeout(() => {
                    this.$store.dispatch('setPlaylist', sP)
                }, 300)
            },
            parseData() {
                this.isLoading = true
                let vm = this
                axios.get(this.options.url)
                    .then((resp) => {
                        this.isLoading = false
                        this.items = resp.data
                        // if(localStorage.getItem('autoInitializePlayer')!==null && this.items.length) {
                        //     bus.$emit('managePlaylist', this.items, this.items[0], true)
                        // }
                    })
                    .catch(e => {
                        this.isLoading = false
                    })
            }
        },
        mounted () {
            this.parseData()
            // if(!this.authStatus) {
            //     this.parseData()
            // } else if(this.profileStatus!=='loading') {
                // this.parseData()
            // }
        },
        computed: {
            currentTrack() {
                try {
                    return this.$store.getters.getCurrent.realId != undefined ? this.$store.getters.getCurrent.realId : null
                } catch (e) {

                }
                return null
            },
            playerStatus() {
                return this.$store.getters.playerStatus
            },
            activeItem() {
                let ai = this.$store.getters.getActiveItem
                if (ai != null) {
                    return parseInt(this.$store.getters.getActiveItem.split('_')[1], 10)
                }
                return null
            },
            currentRouteName() {
                return this.$route.name
            },
            itemsLength: function () {
                if(this.items===undefined || this.items===null || this.items.length<1) return '0'
                return this.items.length
            },
            seeAllUrl() {
                return '/explore/sounds'
            },
            authStatus() {
                return this.$store.getters.isAuthenticated
            },
            profileStatus() {
                return this.$store.getters.getProfileStatus
            }
        }
    }
</script>