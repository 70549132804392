/* eslint-disable promise/param-names */
import {AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT} from '../actions/auth'
import {USER_REQUEST} from '../actions/user'
import axios from 'axios'

const state = {access_token: localStorage.getItem('user-token') || '', status: '', hasLoadedOnce: false}

const getters = {
    isAuthenticated: state => !!state.access_token,
    authStatus: state => state.status,
    accessToken: state => state.access_token
}

const actions = {
    [AUTH_REQUEST]: ({commit, dispatch}, user) => {
        return new Promise((resolve, reject) => {
            commit(AUTH_REQUEST)
            axios({url: API.login, data: user, method: 'POST'})
                .then(resp => {
                    localStorage.setItem('user-token', resp.data.access_token)
                    axios.defaults.headers.common['Authorization'] = resp.data.access_token
                    commit(AUTH_SUCCESS, resp)
                    dispatch(USER_REQUEST)
                    resolve(resp)
                })
                .catch(err => {
                    commit(AUTH_ERROR, err)
                    if(axios.isCancel(err)) {
                      reject('internet')
                      return
                    } else {
                        commit(AUTH_LOGOUT)
                        resolve(true)
                    }
                    
                })
        })
    },
    [AUTH_LOGOUT]: ({commit, dispatch}) => {
        return new Promise((resolve, reject) => {
            axios.get(API.logout)
                .then(() => {
                    commit(AUTH_LOGOUT)
                    resolve(true)
                })
        })
    }
}

const mutations = {
    [AUTH_REQUEST]: (state) => {
        state.status = 'loading'
    },
    [AUTH_SUCCESS]: (state, resp) => {
        state.status = 'success'
        state.access_token = resp.data.access_token
        state.hasLoadedOnce = true
    },
    [AUTH_ERROR]: (state) => {
        state.status = 'error'
        state.hasLoadedOnce = true
    },
    [AUTH_LOGOUT]: (state) => {
        bus.$emit('logout')
        state.status = ''
        state.access_token = '';
        let v;
        axios.defaults.headers.common['Authorization'] = ''
        let lo = {...localStorage};
        localStorage.removeItem('user-token')
        localStorage.removeItem('user')
        Object.keys(lo).map((k) => {
            if (k.indexOf('tus-') === 0) {
                localStorage.removeItem(k)
            }
        });
        window.location.href = '/?lt=success'
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
