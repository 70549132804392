<template>
    <div class="parent">
        <div class="switch-contents" ref="container" :style="{width: contents*100 + '%'}">
            <div v-for="index in contentsArr" class="switch-content">
                <transition name="fade" :duration="200">
                    <div class="content-body" v-show="index===currentContent">
                        <slot :name="'content'+index">
                        </slot>
                    </div>
                </transition>
            </div>
        </div>
    </div>
</template>
<style>
    .parent {
        overflow: hidden;
        position: relative;
    }
    .switch-contents {
        display: flex;
        flex-direction: row;
        transition: .35s all cubic-bezier(0.59, 0.12, 0.34, 0.95);;
    }
    .switch-content {
        width: 100%;
    }
</style>
<script>
    export default {
        props: {
            show: {
                type: Boolean,
                default: false
            },
            currentContent: {
                type: Number,
                default: 0
            },
            contentsArr: {
                type: Array,
                default: () => {
                    return []
                }
            }
        },
        computed: {
            contents() {
                return this.contentsArr.length
            },
            containerTransform () {
                let percent = 0
                if (this.currentContent != 0) {
                    let sW = 100 / this.contents
                    percent = this.contentsArr.indexOf(this.currentContent) * sW * -1
                }
                return 'translateX(' + percent + '%)'
            },
        },
        watch: {
            show (newVal) {
                if (newVal) {
                    document.body.setAttribute('data-content', '1')
                } else {
                    document.body.removeAttribute('data-content')
                }
            },
            currentContent(newVal) {
                //containerTransform
                this.$refs.container.style.transform = this.containerTransform
            },
            '$route': function(route) {
                // console.log(route)
            }
        },
        methods: {

        },
        mounted() {
            // let currentContent = this.currentContent
            // this.currentContent = 0
            // setTimeout(() => {
            //     this.currentContent = currentContent
            // }, 100)
        }
    }
</script>