var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "padding-x-15 settings uk-margin-top" }, [
    _c("h2", [_vm._v("Edit Settings")]),
    _vm._v(" "),
    _c("div", { staticClass: "settings", attrs: { "uk-grid": "" } }, [
      _c(
        "div",
        { staticClass: "uk-width-1-1 uk-width-auto@m" },
        [
          _c("SwitchTab", {
            attrs: {
              padding: "12px 10px",
              currentTab: _vm.currentTab,
              tabs: _vm.tabs,
              icons: _vm.tabIcons,
              activeMode: "bordered",
              vertical: true,
            },
            on: { setTab: _vm.tabChanged },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-1-1 uk-width-expand@m" }, [
        _c(
          "div",
          { staticClass: "uk-position-relative" },
          [
            _c(
              "SwitchContent",
              {
                attrs: {
                  "current-content": _vm.currentTab,
                  "contents-arr": _vm.tabIndexes,
                },
              },
              [
                _c(
                  "div",
                  { attrs: { slot: "content0" }, slot: "content0" },
                  [
                    _vm._l(_vm.accountSettingsData, function (data) {
                      return _c("div", [
                        _c("div", [
                          _c("div", { staticClass: "widget-title" }, [
                            _c("h4", { staticClass: "f-s-1" }, [
                              _vm._v(_vm._s(_vm._f("lang")(data.label))),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-parent" },
                          _vm._l(data.items, function (item) {
                            return _c(
                              "div",
                              {
                                key: item.label,
                                staticClass: "setting f-s-080",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "uk-flex uk-flex-middle",
                                    attrs: { "uk-grid": "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-expand" },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(item.label) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-auto" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "uk-position-relative",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "settings-input-input-with-ico",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.value,
                                                      expression: "item.value",
                                                    },
                                                  ],
                                                  staticClass: "settings-input",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: item.label,
                                                  },
                                                  domProps: {
                                                    value: item.value,
                                                  },
                                                  on: {
                                                    keyup: function ($event) {
                                                      return _vm.onKeyUpAccount(
                                                        item
                                                      )
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.available[item.key] != null
                                                  ? _c(
                                                      "div",
                                                      { staticClass: "ico" },
                                                      [
                                                        _vm.available[
                                                          item.key
                                                        ] === -1
                                                          ? _c("i", {
                                                              attrs: {
                                                                "uk-spinner":
                                                                  "ratio: 0.5",
                                                              },
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.available[
                                                          item.key
                                                        ] === false
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-times",
                                                            })
                                                          : _vm._e(),
                                                        _vm._v(" "),
                                                        _vm.available[
                                                          item.key
                                                        ] === true
                                                          ? _c("i", {
                                                              staticClass:
                                                                "fa fa-check",
                                                            })
                                                          : _vm._e(),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                          attrs: {
                            disabled:
                              _vm.inProcessTab.accountSettings ||
                              !_vm.canSaveAccountSettings,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.save(
                                _vm.accountSettingsData,
                                "accountSettings"
                              )
                            },
                          },
                        },
                        [
                          !_vm.inProcessTab.accountSettings
                            ? _c("span", [
                                _c("i", { staticClass: "fal fa-check" }),
                                _vm._v(" Save"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.inProcessTab.accountSettings
                            ? _c("div", {
                                attrs: { "uk-spinner": "ratio: 0.8" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { attrs: { slot: "content1" }, slot: "content1" },
                  [
                    _vm._l(_vm.editProfileData, function (data) {
                      return _c("div", [
                        _c("div", [
                          _c("div", { staticClass: "widget-title" }, [
                            _c("h4", { staticClass: "f-s-1" }, [
                              _vm._v(_vm._s(data.label)),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "setting-parent" },
                          _vm._l(data.items, function (item) {
                            return _c(
                              "div",
                              { key: item.key, staticClass: "setting f-s-080" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "uk-flex uk-flex-middle",
                                    attrs: { "uk-grid": "" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-expand" },
                                      [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(item.label) +
                                            "\n                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "uk-width-auto" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "uk-position-relative",
                                          },
                                          [
                                            item.isTextarea != undefined &&
                                            item.isTextarea
                                              ? _c("textarea", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.value,
                                                      expression: "item.value",
                                                    },
                                                  ],
                                                  staticClass: "settings-input",
                                                  attrs: { rows: "5" },
                                                  domProps: {
                                                    value: item.value,
                                                  },
                                                  on: {
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.isInput != undefined &&
                                            item.isInput
                                              ? _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value: item.value,
                                                      expression: "item.value",
                                                    },
                                                  ],
                                                  staticClass: "settings-input",
                                                  attrs: {
                                                    type: "text",
                                                    placeholder: item.label,
                                                  },
                                                  domProps: {
                                                    value: item.value,
                                                  },
                                                  on: {
                                                    keyup: function ($event) {
                                                      return _vm.keyUpInput(
                                                        item
                                                      )
                                                    },
                                                    focusin: function ($event) {
                                                      return _vm.focusIn(item)
                                                    },
                                                    focusout: function (
                                                      $event
                                                    ) {
                                                      return _vm.focusOut(item)
                                                    },
                                                    input: function ($event) {
                                                      if (
                                                        $event.target.composing
                                                      )
                                                        return
                                                      _vm.$set(
                                                        item,
                                                        "value",
                                                        $event.target.value
                                                      )
                                                    },
                                                  },
                                                })
                                              : _vm._e(),
                                            _vm._v(" "),
                                            item.label === "Location" &&
                                            _vm.locations.show
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "ajax-dropdown",
                                                  },
                                                  [
                                                    _vm.locations.isLoading
                                                      ? _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "uk-text-center",
                                                          },
                                                          [
                                                            _c("i", {
                                                              attrs: {
                                                                "uk-spinner":
                                                                  "ratio: 0.5",
                                                              },
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.locations.items.length
                                                      ? _c(
                                                          "ul",
                                                          _vm._l(
                                                            _vm.locations.items,
                                                            function (loc) {
                                                              return _c(
                                                                "li",
                                                                {
                                                                  class: {
                                                                    active:
                                                                      loc.id ===
                                                                      _vm
                                                                        .locations
                                                                        .selectedId,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.selectLocation(
                                                                          loc,
                                                                          item
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                              " +
                                                                      _vm._s(
                                                                        loc.name
                                                                      ) +
                                                                      "\n                            "
                                                                  ),
                                                                ]
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        )
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    item.value === null ||
                                                    item.value.length < 2
                                                      ? _c("div", [
                                                          _vm._v(
                                                            "\n                            Type to search...\n                          "
                                                          ),
                                                        ])
                                                      : _vm._e(),
                                                    _vm._v(" "),
                                                    _vm.locations.notFound
                                                      ? _c("div", [
                                                          _vm._v("Not found."),
                                                        ])
                                                      : _vm._e(),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ])
                    }),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                          attrs: { disabled: _vm.inProcessTab.editProfile },
                          on: {
                            click: function ($event) {
                              return _vm.save(
                                _vm.editProfileData,
                                "editProfile"
                              )
                            },
                          },
                        },
                        [
                          !_vm.inProcessTab.editProfile
                            ? _c("span", [
                                _c("i", { staticClass: "fal fa-check" }),
                                _vm._v(" Save"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.inProcessTab.editProfile
                            ? _c("div", {
                                attrs: { "uk-spinner": "ratio: 0.8" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("div", { attrs: { slot: "content2" }, slot: "content2" }, [
                  _c("div", [
                    _c("div", [
                      _c("div", { staticClass: "widget-title" }, [
                        _c("h4", { staticClass: "f-s-1" }, [
                          _vm._v("Change Password"),
                        ]),
                        _vm._v(" "),
                        _c("h6", { staticClass: "f-s-085" }, [
                          _c("ul", [
                            _c("li", [
                              _vm._v(
                                "\n                        If you have registered vie Social Networks please\n                        remember to enter a valid email in your profile and\n                        then request for new password with Forget Password\n                        link. Please remind this after changing password all\n                        other devices will get logged out.\n                      "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", [
                              _vm._v(
                                "\n                        Password must be at least 6 characters and it may not\n                        be greater than 32 characters.\n                      "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("li", { staticClass: "important" }, [
                              _vm._v(
                                "\n                        Please Remember after changing your password all\n                        devices will get "
                              ),
                              _c("strong", [_vm._v("logged out")]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-parent" }, [
                      _c("div", { staticClass: "setting f-s-080" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex uk-flex-middle",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-width-expand" }, [
                              _vm._v("Current Password"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uk-width-auto" }, [
                              _c(
                                "div",
                                { staticClass: "password-with-show-btn" },
                                [
                                  _vm.passwords.currentPassword != ""
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "password-show-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickShowPassBtn(
                                                "currentPassword"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.passwordTypes
                                                  .currentPassword === "text"
                                                  ? "Hide"
                                                  : "Show"
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.passwordTypes.currentPassword ===
                                  "checkbox"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.passwords.currentPassword,
                                            expression:
                                              "passwords.currentPassword",
                                          },
                                        ],
                                        staticClass: "settings-input",
                                        attrs: {
                                          placeholder: "Current Password",
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.passwords.currentPassword
                                          )
                                            ? _vm._i(
                                                _vm.passwords.currentPassword,
                                                null
                                              ) > -1
                                            : _vm.passwords.currentPassword,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a =
                                                _vm.passwords.currentPassword,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.passwords,
                                                    "currentPassword",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.passwords,
                                                    "currentPassword",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.passwords,
                                                "currentPassword",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      })
                                    : _vm.passwordTypes.currentPassword ===
                                      "radio"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.passwords.currentPassword,
                                            expression:
                                              "passwords.currentPassword",
                                          },
                                        ],
                                        staticClass: "settings-input",
                                        attrs: {
                                          placeholder: "Current Password",
                                          type: "radio",
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.passwords.currentPassword,
                                            null
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.passwords,
                                              "currentPassword",
                                              null
                                            )
                                          },
                                        },
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.passwords.currentPassword,
                                            expression:
                                              "passwords.currentPassword",
                                          },
                                        ],
                                        staticClass: "settings-input",
                                        attrs: {
                                          placeholder: "Current Password",
                                          type: _vm.passwordTypes
                                            .currentPassword,
                                        },
                                        domProps: {
                                          value: _vm.passwords.currentPassword,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.passwords,
                                              "currentPassword",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting f-s-080" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex uk-flex-middle",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-width-expand" }, [
                              _vm._v("New Password"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uk-width-auto" }, [
                              _c(
                                "div",
                                { staticClass: "password-with-show-btn" },
                                [
                                  _vm.passwords.password != ""
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "password-show-btn",
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickShowPassBtn(
                                                "password"
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                          " +
                                              _vm._s(
                                                _vm.passwordTypes.password ===
                                                  "text"
                                                  ? "Hide"
                                                  : "Show"
                                              ) +
                                              "\n                        "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.passwordTypes.password === "checkbox"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.passwords.password,
                                            expression: "passwords.password",
                                          },
                                        ],
                                        staticClass: "settings-input",
                                        attrs: {
                                          placeholder: "New Password",
                                          type: "checkbox",
                                        },
                                        domProps: {
                                          checked: Array.isArray(
                                            _vm.passwords.password
                                          )
                                            ? _vm._i(
                                                _vm.passwords.password,
                                                null
                                              ) > -1
                                            : _vm.passwords.password,
                                        },
                                        on: {
                                          change: function ($event) {
                                            var $$a = _vm.passwords.password,
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.passwords,
                                                    "password",
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.passwords,
                                                    "password",
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.passwords,
                                                "password",
                                                $$c
                                              )
                                            }
                                          },
                                        },
                                      })
                                    : _vm.passwordTypes.password === "radio"
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.passwords.password,
                                            expression: "passwords.password",
                                          },
                                        ],
                                        staticClass: "settings-input",
                                        attrs: {
                                          placeholder: "New Password",
                                          type: "radio",
                                        },
                                        domProps: {
                                          checked: _vm._q(
                                            _vm.passwords.password,
                                            null
                                          ),
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.$set(
                                              _vm.passwords,
                                              "password",
                                              null
                                            )
                                          },
                                        },
                                      })
                                    : _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.passwords.password,
                                            expression: "passwords.password",
                                          },
                                        ],
                                        staticClass: "settings-input",
                                        attrs: {
                                          placeholder: "New Password",
                                          type: _vm.passwordTypes.password,
                                        },
                                        domProps: {
                                          value: _vm.passwords.password,
                                        },
                                        on: {
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.passwords,
                                              "password",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                ]
                              ),
                            ]),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                        attrs: { disabled: _vm.inProcessTab.changePassword },
                        on: {
                          click: function ($event) {
                            return _vm.save(_vm.passwords, "changePassword")
                          },
                        },
                      },
                      [
                        !_vm.inProcessTab.changePassword
                          ? _c("span", [
                              _c("i", { staticClass: "fal fa-check" }),
                              _vm._v(" Save"),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.inProcessTab.changePassword
                          ? _c("div", { attrs: { "uk-spinner": "ratio: 0.8" } })
                          : _vm._e(),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { attrs: { slot: "content3" }, slot: "content3" }, [
                  _c("div", [
                    _c("div", { staticClass: "widget-title" }, [
                      _c("h4", { staticClass: "f-s-1" }, [
                        _vm._v("Social Connect"),
                      ]),
                      _vm._v(" "),
                      _c("h6", { staticClass: "f-s-085" }, [
                        _vm._v("Social connect description."),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "setting-parent uk-margin-bottom" },
                    [
                      _c("div", { staticClass: "setting" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex uk-flex-middle uk-grid-10",
                            attrs: { "uk-grid": "" },
                          },
                          _vm._l(_vm.providers, function (provider) {
                            return _c(
                              "div",
                              {
                                staticClass: "uk-width-expand",
                                on: {
                                  click: function ($event) {
                                    return _vm.clickOnProvider(provider)
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "provider bg with-hover color-white",
                                    class: [
                                      provider.name,
                                      {
                                        active:
                                          _vm.connectedSocials.indexOf(
                                            provider.name
                                          ) != -1,
                                      },
                                      {
                                        processing:
                                          _vm.inProcessConnect.indexOf(
                                            provider.name
                                          ) != -1,
                                      },
                                    ],
                                  },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "provider-content" },
                                      [
                                        _c("div", { staticClass: "f-s-15" }, [
                                          _c("i", {
                                            class:
                                              _vm.socialIcons[provider.name],
                                          }),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(_vm._s(provider.label)),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "provider-connect" },
                                          [
                                            _vm._v(
                                              "\n                          " +
                                                _vm._s(
                                                  _vm.connectedSocials.indexOf(
                                                    provider.name
                                                  ) != -1
                                                    ? "Disconnect"
                                                    : "Connect"
                                                ) +
                                                "\n                        "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "loading" }, [
                                      _c("i", { attrs: { "uk-spinner": "" } }),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("div", { staticClass: "widget-title" }, [
                      _c("h4", { staticClass: "f-s-1" }, [
                        _vm._v("Social Links"),
                      ]),
                      _vm._v(" "),
                      _c("h6", { staticClass: "f-s-085" }, [
                        _vm._v(
                          "\n                  Add links to your website and social network profiles to\n                  help your audience find you wherever you are.\n                "
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "setting-parent" },
                    [
                      _vm._l(_vm.socials, function (social) {
                        return _c("div", { staticClass: "setting" }, [
                          _c(
                            "div",
                            {
                              staticClass: "uk-flex uk-flex-middle uk-grid-10",
                              attrs: { "uk-grid": "" },
                            },
                            [
                              _c("div", { staticClass: "uk-width-auto" }, [
                                _c(
                                  "div",
                                  { staticClass: "social-placeholder" },
                                  [
                                    _c("i", {
                                      class:
                                        _vm.socialIcons[social.type] !==
                                        undefined
                                          ? _vm.socialIcons[social.type]
                                          : social.href === ""
                                          ? ""
                                          : _vm.socialIcons.official,
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "uk-width-expand" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: social.href,
                                      expression: "social.href",
                                    },
                                  ],
                                  staticClass: "settings-input social-input",
                                  attrs: { type: "text" },
                                  domProps: { value: social.href },
                                  on: {
                                    keyup: function ($event) {
                                      return _vm.onKeyUpSocial(social)
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        social,
                                        "href",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "uk-width-auto" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "social-modify-btn trash-btn",
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteSocial(social)
                                      },
                                    },
                                  },
                                  [_c("i", { staticClass: "fal fa-trash" })]
                                ),
                              ]),
                            ]
                          ),
                        ])
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin-small-top" }, [
                        _c(
                          "button",
                          {
                            staticClass: "social-modify-btn",
                            on: { click: _vm.addSocial },
                          },
                          [
                            _vm._v(
                              "\n                  Add link\n                "
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                            attrs: { disabled: _vm.inProcessTab.socialLinks },
                            on: {
                              click: function ($event) {
                                return _vm.save(_vm.socials, "socialLinks")
                              },
                            },
                          },
                          [
                            !_vm.inProcessTab.socialLinks
                              ? _c("span", [
                                  _c("i", { staticClass: "fal fa-check" }),
                                  _vm._v(" Save"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.inProcessTab.socialLinks
                              ? _c("div", {
                                  attrs: { "uk-spinner": "ratio: 0.8" },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "settings-parent",
                    attrs: { slot: "content4" },
                    slot: "content4",
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "widget-title" }, [
                        _c("h4", { staticClass: "f-s-1" }, [
                          _vm._v("Push Notifications"),
                        ]),
                        _vm._v(" "),
                        _c("h6", { staticClass: "f-s-085" }, [
                          _vm._v(
                            "\n                  Mute the notifications that you dont want to recieve.\n                "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "setting-parent" },
                      _vm._l(_vm.settings, function (setting) {
                        return _c(
                          "div",
                          {
                            key: setting.value,
                            staticClass: "setting f-s-080",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "uk-flex uk-flex-middle",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c("div", { staticClass: "uk-width-expand" }, [
                                  _vm._v(
                                    "\n                    " +
                                      _vm._s(setting.title) +
                                      "\n                  "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "uk-width-auto" },
                                  [
                                    _vm.mySettings[setting.value] == false
                                      ? _c("span", { staticClass: "f-s-070" }, [
                                          _vm._v("Muted "),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("p-check", {
                                      ref: "playlist-public",
                                      refInFor: true,
                                      staticClass: "p-switch p-fill",
                                      attrs: { color: "primary" },
                                      model: {
                                        value: _vm.mySettings[setting.value],
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.mySettings,
                                            setting.value,
                                            $$v
                                          )
                                        },
                                        expression: "mySettings[setting.value]",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                          attrs: { disabled: _vm.inProcessTab.notifications },
                          on: {
                            click: function ($event) {
                              return _vm.save(_vm.mySettings, "notifications")
                            },
                          },
                        },
                        [
                          !_vm.inProcessTab.notifications
                            ? _c("span", [
                                _c("i", { staticClass: "fal fa-check" }),
                                _vm._v(" Save"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.inProcessTab.notifications
                            ? _c("div", {
                                attrs: { "uk-spinner": "ratio: 0.8" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "settings-parent",
                    attrs: { slot: "content5" },
                    slot: "content5",
                  },
                  [
                    _c("div", [
                      _c("div", { staticClass: "widget-title" }, [
                        _c("h4", { staticClass: "f-s-1" }, [
                          _vm._v("Bank Accounts"),
                        ]),
                        _vm._v(" "),
                        _c("h6", { staticClass: "f-s-085" }, [
                          _vm._v(
                            "\n                  You need to have a verified account to start earning from\n                  your account. We will use your bank accounts information to\n                  pay your earnings from your sounds and videos. So please\n                  provide correct information to prevent happening any\n                  mistakes. Please check your information and make sure your\n                  full name is same on your card or paypal account.\n                "
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-parent" }, [
                      _c("div", { staticClass: "setting f-s-080" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex uk-flex-middle",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-width-expand" }, [
                              _vm._v("Paypal"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "uk-width-auto" }, [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.bankAccounts.paypal,
                                    expression: "bankAccounts.paypal",
                                  },
                                ],
                                staticClass: "settings-input",
                                attrs: { type: "text", placeholder: "Paypal" },
                                domProps: { value: _vm.bankAccounts.paypal },
                                on: {
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.bankAccounts,
                                      "paypal",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "setting f-s-080" }, [
                        _c(
                          "div",
                          {
                            staticClass: "uk-flex uk-flex-middle",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c("div", { staticClass: "uk-width-expand" }, [
                              _vm._v("Shetab"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "uk-width-auto" },
                              _vm._l(
                                _vm.bankAccounts.shetabTmp,
                                function (v, index) {
                                  return _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.bankAccounts.shetabTmp[index],
                                        expression:
                                          "bankAccounts.shetabTmp[index]",
                                      },
                                    ],
                                    ref: "shetab",
                                    refInFor: true,
                                    staticClass: "settings-input shetab",
                                    attrs: {
                                      type: "text",
                                      maxlength: "4",
                                      placeholder: index === 0 ? "Shetab" : "",
                                    },
                                    domProps: {
                                      value: _vm.bankAccounts.shetabTmp[index],
                                    },
                                    on: {
                                      paste: _vm.doPaste,
                                      focusin: function ($event) {
                                        return _vm.onFocusShetab(index)
                                      },
                                      keydown: function ($event) {
                                        return _vm.onKeyDownShetab(
                                          index,
                                          $event
                                        )
                                      },
                                      keyup: function ($event) {
                                        return _vm.onKeyUpShetab(index)
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.bankAccounts.shetabTmp,
                                          index,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  })
                                }
                              ),
                              0
                            ),
                          ]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticStyle: { direction: "rtl" } }, [
                        _c("img", {
                          staticClass: "shetab-img",
                          attrs: { src: _vm.shetab },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                      _c(
                        "button",
                        {
                          staticClass:
                            "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                          attrs: { disabled: _vm.inProcessTab.bankAccounts },
                          on: {
                            click: function ($event) {
                              return _vm.save(_vm.bankAccounts, "bankAccounts")
                            },
                          },
                        },
                        [
                          !_vm.inProcessTab.bankAccounts
                            ? _c("span", [
                                _c("i", { staticClass: "fal fa-check" }),
                                _vm._v(" Save"),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.inProcessTab.bankAccounts
                            ? _c("div", {
                                attrs: { "uk-spinner": "ratio: 0.8" },
                              })
                            : _vm._e(),
                        ]
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _vm.profile.is != undefined && _vm.profile.is.verified
                  ? _c(
                      "div",
                      { attrs: { slot: "content6" }, slot: "content6" },
                      [
                        _c(
                          "div",
                          { staticClass: "uk-text-center uk-margin-top" },
                          [
                            _c("div", [
                              _c("i", {
                                staticClass: "fal fa-check-circle f-s-4",
                              }),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "f-f-reg uk-margin-small-top" },
                              [
                                _vm._v(
                                  "\n                Your profile is already verified.\n              "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.profile.is != undefined && !_vm.profile.is.verified
                  ? _c(
                      "div",
                      { attrs: { slot: "content6" }, slot: "content6" },
                      [
                        _c("div", [
                          _c("div", { staticClass: "widget-title" }, [
                            _c("h4", { staticClass: "f-s-1" }, [
                              _vm._v("Verification"),
                            ]),
                            _vm._v(" "),
                            _c("h6", { staticClass: "f-s-085" }, [
                              _vm._v(
                                "\n                  To get your account verified you must send us an image of\n                  your ID card like driving license, passport copy or any\n                  other ID card which informs us about your name and your\n                  photo. The image has to be clear and bright."
                              ),
                              _c("br"),
                              _vm._v(
                                "\n\n                  Before sending request make sure you have connected your\n                  Instagram account to your profile.\n                "
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "setting-parent" }, [
                          _c("div", { staticClass: "setting f-s-080" }, [
                            _c(
                              "div",
                              {
                                staticClass: "uk-flex uk-flex-middle",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c("div", { staticClass: "uk-width-expand" }, [
                                  _vm._v("Description"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "uk-width-auto" }, [
                                  _c("textarea", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.verificationRequest.description,
                                        expression:
                                          "verificationRequest.description",
                                      },
                                    ],
                                    staticClass: "settings-input",
                                    attrs: {
                                      rows: "5",
                                      placeholder: "Description",
                                    },
                                    domProps: {
                                      value:
                                        _vm.verificationRequest.description,
                                    },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.verificationRequest,
                                          "description",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "setting f-s-080" }, [
                            _c(
                              "div",
                              {
                                staticClass: "uk-flex uk-flex-middle",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "uk-width-expand" },
                                  [
                                    _c("DragAndDrop", {
                                      attrs: {
                                        "upload-url": _vm.uploadVerificationUrl,
                                        "select-text": "selecting one",
                                        text: "Attach your ID Card by dropping it here or ",
                                      },
                                      on: {
                                        "upload-complete":
                                          _vm.verificationRequestUploadComplete,
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("progress", {
                                      staticClass: "uk-progress",
                                      attrs: {
                                        id: "js-progressbar",
                                        value: "0",
                                        max: "100",
                                        hidden: "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                              attrs: {
                                disabled: _vm.inProcessTab.verificationRequest,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.save(
                                    _vm.verificationRequest,
                                    "verificationRequest"
                                  )
                                },
                              },
                            },
                            [
                              !_vm.inProcessTab.verificationRequest
                                ? _c("span", [
                                    _c("i", { staticClass: "fal fa-check" }),
                                    _vm._v(" Save"),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.inProcessTab.verificationRequest
                                ? _c("div", {
                                    attrs: { "uk-spinner": "ratio: 0.8" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { attrs: { slot: "content7" }, slot: "content7" }, [
                  _c("div", [
                    _c("div", { staticClass: "widget-title" }, [
                      _c("h4", { staticClass: "f-s-1" }, [
                        _vm._v(_vm._s(_vm._f("lang")("disable_user_title"))),
                      ]),
                      _vm._v(" "),
                      _c("h6", {
                        staticClass: "f-s-085",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.lang(
                              "disable_user_desc",
                              undefined
                            )
                          ),
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "setting-parent" }, [
                      _c("div", { staticClass: "setting f-s-080" }, [
                        _c("div", { staticClass: "uk-margin-top uk-flex" }, [
                          _c(
                            "button",
                            {
                              staticClass:
                                "save-btn uk-button uk-button-default button-top-menu button-bg flex-right",
                              attrs: {
                                disabled: _vm.inProcessTab.disableAccount,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.disableAccount()
                                },
                              },
                            },
                            [
                              !_vm.inProcessTab.disableAccount
                                ? _c("span", [
                                    _c("i", { staticClass: "fal fa-ban" }),
                                    _vm._v(" "),
                                    _c("span", {
                                      domProps: {
                                        innerHTML: _vm._s(
                                          _vm.$options.filters.lang(
                                            "disable_user_btn",
                                            undefined
                                          )
                                        ),
                                      },
                                    }),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.inProcessTab.disableAccount
                                ? _c("div", {
                                    attrs: { "uk-spinner": "ratio: 0.8" },
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }