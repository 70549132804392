var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    _vm.tagName,
    { ref: "parent", tag: "component" },
    _vm._l(_vm.items, function (item, index) {
      return _c(
        item.postType != undefined &&
          _vm.type === "VPostMedItem" &&
          item.postType === "VID"
          ? "VVideoMedItem"
          : item.postType != undefined &&
            _vm.type === "VPostMedItem" &&
            (item.postType === "ALB" || item.postType === "PLY")
          ? "VSetMedItem"
          : _vm.type,
        {
          directives: [
            {
              name: "is-visibility",
              rawName: "v-is-visibility",
              value: (isVisible, entry) =>
                _vm.helper.isVisible(isVisible, entry, item),
              expression:
                "(isVisible, entry) => helper.isVisible(isVisible, entry, item)",
            },
          ],
          key: _vm.type + "_" + item.id,
          tag: "component",
          class: {
            active:
              item.realId != undefined &&
              (_vm.activeItem === item.realId ||
                (item.postType === "SND" &&
                  _vm.currentTrack != null &&
                  _vm.currentTrack == item.realId) ||
                (item.postType === "VID" &&
                  _vm.currentVideo != null &&
                  _vm.currentVideo == item.realId)),
            deleting: _vm.deletings.indexOf(item.id) !== -1,
          },
          attrs: {
            options: _vm.options,
            "data-id": item.id,
            subclass: _vm.subclass,
            item: item,
            index: index,
          },
          on: {
            "update:item": function ($event) {
              item = $event
            },
            clickMoreMenu: _vm.moreMenu,
            dop: _vm.dop,
          },
        }
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }