<template>
  <div>
    <div v-if="post === null" class="uk-text-center uk-margin-top">
      <i uk-spinner="ratio: 2"></i>
    </div>
    <div class="padding-x-15" v-if="post !== null">
      <my-upload
        field="img"
        @crop-success="cropSuccess($event)"
        @crop-upload-success="cropUploadSuccess($event)"
        @crop-upload-fail="cropUploadFail($event)"
        v-model="imgUpload.show"
        :width="600"
        :height="600"
        :fullsize="1200"
        :url="imgUpload.url"
        langType="en"
        :params="imgUpload.params"
        :headers="imgUpload.headers"
        no-circle
        :ref="'imgUpload'"
        img-format="jpg"
      ></my-upload>
      <div class="uploading-file">
        <div class="file-progress-bar">
          <div
            class="file-progress-bar-val"
            :style="{ width: 100 + '%' }"
          ></div>
        </div>
        <div class="file-ellapsed">
          <div>
            {{ post.title || "Untitled Post" }}
          </div>
        </div>
        <div class="file-collapsed submit-post">
          <div class="processing" v-if="saving">
            <div>
              <i uk-spinner="ratio: 3"></i>
            </div>
          </div>
          <div class="uk-flex uk-flex-middle" uk-grid>
            <div class="uk-width-1-1 uk-width-1-4@m">
              <div class="post-cover">
                <img
                  :src="
                    post.base64Cover !== null ? post.base64Cover : defaultImage
                  "
                />
                <div class="post-cover-container">
                  <a
                    class="
                      uk-button uk-button-default
                      button-top-menu button-bg
                    "
                    v-if="post.base64Cover !== null"
                    @click="toggleCoverUploader(true)"
                  >
                    <i class="fal fa-pen"></i>
                  </a>
                  <a
                    class="
                      uk-button uk-button-default
                      button-top-menu button-bg
                    "
                    @click="toggleCoverUploader(false)"
                  >
                    <i class="fal fa-cloud-upload"></i>
                  </a>
                </div>
              </div>
            </div>
            <div class="uk-width-1-1 uk-width-3-4@m">
              <div class="uk-margin">
                <label class="label"> Title </label>
                <input
                  class="uk-input uk-form-width-medium uk-form-small title"
                  type="text"
                  @keyup="onKeyUpTitle()"
                  placeholder="Title"
                  v-model="post.title"
                />
                <div
                  class="
                    uk-grid-none uk-flex uk-flex-middle uk-margin-small-top
                    f-s-080
                  "
                  uk-grid
                >
                  <div class="uk-width-auto">
                    {{ host }}/{{ auth.username }}/
                  </div>
                  <div class="uk-width-expand">
                    <div class="uk-position-relative">
                      <input
                        class="slug-input"
                        type="text"
                        :readonly="readOnlySlug"
                        placeholder="Title"
                        @change="onKeyUpTitle(1)"
                        v-model="post.slug"
                      />
                      <div v-if="readOnlySlug" class="slug-loading">
                        <div uk-spinner></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="uk-margin">
                <div uk-grid>
                  <div class="uk-width-1-1 uk-width-1-2@m">
                    <label class="selectpage-label">Post Languages *</label>
                    <v-selectpage
                      v-if="postLanguagesArr.length"
                      :data="postLanguagesArr"
                      :multiple="true"
                      :language="'en'"
                      :placeholder="'Select language'"
                      :title="'Languages'"
                      :max-select-limit="3"
                      ref="languages"
                      v-model="post.languages"
                    >
                    </v-selectpage>
                  </div>
                  <div class="uk-width-1-1 uk-width-1-2@m">
                    <label class="selectpage-label"> Record Labels </label>
                    <v-selectpage
                      :data="' '"
                      :result-format="typedValueResultFormat"
                      :params="{ type: 'recordLabels' }"
                      :multiple="true"
                      :language="'en'"
                      :placeholder="'Select Record Labels'"
                      :title="'Record Labels'"
                      :max-select-limit="2"
                      v-model="post.recordLabels"
                    >
                    </v-selectpage>
                  </div>
                </div>
              </div>
              <div class="uk-margin">
                <label class="selectpage-label"> Hashtags </label>
                <v-selectpage
                  :data="' '"
                  :result-format="typedValueResultFormat"
                  :params="{ type: 'tags' }"
                  :multiple="true"
                  :language="'en'"
                  search-field="name"
                  :show-field="renderHashtagsRow"
                  :placeholder="'Select hashtags'"
                  :title="'Hashtags'"
                  :max-select-limit="10"
                  ref="hashtags"
                  v-model="post.hashtags"
                >
                </v-selectpage>
              </div>
              <div class="uk-margin">
                <div class="uk-width-1-1">
                  <label class="selectpage-label"> Genres * </label>
                  <v-selectpage
                    :data="' '"
                    :result-format="tagsResultFormat"
                    @eAjaxSuccess="genresAjaxSuccess"
                    :params="{ type: 'genres', selected: post.genres }"
                    :multiple="true"
                    :language="'en'"
                    :placeholder="'Select genres'"
                    :title="'Genres'"
                    :max-select-limit="3"
                    ref="genres"
                    v-model="post.genres"
                  >
                  </v-selectpage>
                </div>
              </div>
            </div>
          </div>
          <screenshot-scrapper
            v-if="post.screenshotsUri != undefined"
            @pass="setCover"
            :static="post.screenshotsUri"
          />
          <ul uk-accordion="collapsible: true">
            <li>
              <a class="uk-accordion-title" @click="clickUkTab($event)" href="#"
                >Description & Lyrics</a
              >
              <div class="uk-accordion-content">
                <div class="uk-margin">
                  <div class="big-placeholder-container">
                    <label for="dDescription">
                      {{ "description_lable" | lang }}
                    </label>
                    <textarea
                      rows="4"
                      id="dDescription"
                      v-model="post.description"
                      :placeholder="'description_placeholder' | lang"
                      class="uk-textarea uk-form-small uk-text-center"
                    ></textarea>
                    <!-- <div v-if="showDescriptionPlaceholder"
                                             @click="focusFirstParentInput" class="big-placeholder small">
                                            Description
                                        </div> -->
                  </div>
                </div>
                <div class="uk-margin">
                  <div
                    class="big-placeholder-container"
                    :key="showLyricsPlaceholder"
                  >
                    <label for="tLyrics">
                      {{ "lyrics_lable" | lang }}
                    </label>
                    <textarea
                      rows="10"
                      id="tLyrics"
                      :placeholder="'lyrics_placeholder' | lang"
                      v-model="post.lyrics"
                      class="uk-textarea uk-form-small uk-text-center"
                    ></textarea>
                    <!-- <div v-if="showLyricsPlaceholder"
                                             @click="focusFirstParentInput" class="big-placeholder">
                                            Lyrics
                                        </div> -->
                  </div>
                </div>
              </div>
            </li>
            <li>
              <a
                class="uk-accordion-title"
                @click="clickUkTab($event, 1)"
                href="#"
                >Privacy & Metadata</a
              >
              <div class="uk-accordion-content">
                <div class="uk-margin">
                  <div uk-grid>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Privacy Settings</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="post.public"
                        :reduce="(pub) => pub.value"
                        :options="[
                          { label: 'Public', value: 1 },
                          { label: 'Private', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Download</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="post.download"
                        :reduce="(download) => download.value"
                        :options="[
                          { label: 'Allow', value: 1 },
                          { label: 'Not Allow', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Comments</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="post.comment"
                        :reduce="(comment) => comment.value"
                        :options="[
                          { label: 'Allow', value: 1 },
                          { label: 'Not Allow', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <div uk-grid>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Type</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="post.releaseTypeId"
                        :reduce="(releaseType) => releaseType.value"
                        :options="releaseTypesArr"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>Explicit 🅴</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="post.explicit"
                        :reduce="(explicit) => explicit.value"
                        :options="[
                          { label: 'Yes', value: 1 },
                          { label: 'No', value: 0 },
                        ]"
                      ></v-select>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-3@m">
                      <label>License</label>
                      <v-select
                        :clearable="false"
                        :searchable="false"
                        v-model="post.licenseTypeId"
                        :reduce="(licenseType) => licenseType.value"
                        :options="licenseTypesArr"
                      ></v-select>
                    </div>
                  </div>
                </div>
                <div class="uk-margin">
                  <div uk-grid>
                    <div
                      class="
                        uk-width-1-1 uk-width-1-2@m
                        select-teammates-container
                      "
                    >
                      <label>&nbsp;</label>
                      <v-selectpage
                        :data="' '"
                        :result-format="usersResultFormat"
                        :params="{ type: 'users', selected: post.users }"
                        :multiple="true"
                        :language="'en'"
                        search-field="username"
                        :show-field="renderUsersRow"
                        :placeholder="'Select Teammates'"
                        :title="'Users'"
                        ref="users"
                        :max-select-limit="5"
                        v-model="post.users"
                      >
                      </v-selectpage>
                      <small>
                        You can use ‘teammate’ to add other artists who have
                        collabrated with you to your post.
                      </small>
                    </div>
                    <div class="uk-width-1-1 uk-width-1-2@m">
                      <label>&nbsp;</label>
                      <VueCtkDateTimePicker
                        locale="en"
                        :dark="true"
                        format="YYYY-MM-DD HH:mm"
                        v-model="post.releaseDate"
                      />
                      <small>
                        If you select time from future this post will be
                        scheduled and will get automatically posted at that
                        time.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <!-- <li>
                            <a class="uk-accordion-title" href="#" @click="clickUkTab($event, 2)">Sale</a>
                            <div class="uk-accordion-content">
                                <div class="smooth-picker-title f-s-075">
                                    <div>For Sale?</div>
                                    <div>Price (Dollars)</div>
                                    <div>Price (Tomans)</div>
                                    <div>Playback</div>
                                </div>
                                <smooth-picker ref="smoothPicker" :data="packages"
                                               :change="onChangePackage"/>
                                <ul class="buy-doc f-s-080">
                                    <li>You must have a Pro account to unlock this feature, click here to go Pro.</li>
                                    <li>Please remember to fill your bank account information in your profile
                                        settings.
                                    </li>
                                    <li>If you select ‘Preview’ users can only listen to 30 seconds of the track before
                                        buying it.
                                    </li>
                                    <li>If you have schedule your post to get published anytime in future and also
                                        enabled sale option<br> for your post, users will be able to pre-buy this post
                                        and access it when it gets released.
                                    </li>
                                </ul>
                            </div>
                        </li> -->
          </ul>
          <div>
            <button class="uk-button uk-button-default" @click="save">
              SAVE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import helper from "@/helpers/helper";
import defaultImage from "@/assets/img/artwork_600.jpg";
import myUpload from "vue-image-crop-upload/upload-2.vue";
import ScreenshotScrapper from "@/components/Tools/ScreenshotScrapper";

export default {
  components: {
    "my-upload": myUpload,
    ScreenshotScrapper,
  },
  data() {
    return {
      waitForUser: false,
      waitForSettings: false,
      host: window.location.host,
      dataLoaded: false,
      post: null,
      saving: false,
      newCover: false,
      vSelectPage: {
        tags: false,
        recordLabels: false,
        genres: false,
        users: false,
      },
      imgUpload: {
        url: "",
        show: false,
        index: 0,
        params: {},
        headers: {},
      },
      showDescriptionPlaceholder: true,
      showLyricsPlaceholder: true,
      defaultImage,
      helper,
      readOnlySlug: false,
      tabIndex: null,
      tagsResultFormat: (res) => {
        return {
          totalRow: res.data.meta.total,
          list: res.data.data,
        };
      },
      typedValueResultFormat: (res) => {
        let json = JSON.parse(res.config.data);
        if (res.data.data.length) {
          let finded = false;
          let response = res.data.data;
          if (json.name != undefined) {
            for (let i = 0; i < response.length; i++) {
              if (response[i].name.toLowerCase() === json.name.toLowerCase()) {
                finded = true;
              }
            }
          }
          if (!finded && json.name != undefined) {
            response.unshift({
              id: json.name,
              name: json.name,
            });
          }
          return {
            totalRow: res.data.meta.total,
            list: response,
          };
        } else {
          return {
            totalRow: 1,
            list: [
              {
                id: json.name,
                name: json.name,
              },
            ],
          };
        }
      },
      usersResultFormat: (res) => {
        return {
          totalRow: res.data.length,
          list: res.data,
        };
      },
      renderUsersRow: (row) => {
        let result = "";
        if (row && Object.keys(row).length)
          result = `<img width="20" height="20" src="${helper.image_url(
            row.photos.avatar,
            75
          )}"/> @${row.username}`;
        return result;
      },
      renderHashtagsRow: (row) => {
        let result = "";
        if (row && Object.keys(row).length) result = `#${row.name}`;
        return result;
      },
      packages: [
        {
          currentIndex: 0,
          flex: 3,
          list: ["Free", "For Sale"],
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex: 0,
          flex: 3,
          list: ["0.00$"],
          textAlign: "center",
          className: "row-group",
        },
        {
          currentIndex: 0,
          flex: 3,
          list: ["0T"],
          textAlign: "center",
          className: "item-group",
        },
        {
          currentIndex: 0,
          flex: 3,
          list: ["Original", "Preview"],
          textAlign: "center",
          className: "item-group",
        },
      ],
    };
  },
  computed: {
    settings() {
      return this.$store.getters.settings;
    },
    auth() {
      return this.$store.getters.getProfile;
    },
    licenseTypesArr() {
      let arr = [];
      let keys = Object.keys(this.settings.licenseTypes);
      for (let i = 0; i < keys.length; i++) {
        arr.push({
          value: parseInt(keys[i], 10),
          label: this.settings.licenseTypes[keys[i]],
        });
      }
      return arr;
    },
    releaseTypesArr() {
      let arr = [];
      let keys = Object.keys(this.settings.releaseTypes);
      for (let i = 0; i < keys.length; i++) {
        arr.push({
          value: parseInt(keys[i], 10),
          label: this.settings.releaseTypes[keys[i]],
        });
      }
      return arr;
    },
    postLanguagesArr() {
      let arr = [];
      for (let i = 0; i < this.settings.allPostLanguages.length; i++) {
        arr.push({
          name: this.settings.allPostLanguages[i].name,
          id: this.settings.allPostLanguages[i].id,
        });
      }
      return arr;
    },
  },
  methods: {
    save() {
      this.saving = true;
      let post = { ...this.post };
      post.cover = null;
      if (!this.newCover) {
        post.base64Cover = null;
      }
      axios
        .put(API.posts, post)
        .then((resp) => {
          this.saving = false;
          bus.$emit("success", {
            title: "",
            message: "The '" + post.title + "' was edited successfully.",
          });
        })
        .catch((e) => {
          this.saving = false;
          let err = e.response.data.errors;
          if (Object.keys(e.response.data.errors).length) {
            bus.$emit("error", {
              title: "",
              message: err[Object.keys(e.response.data.errors)[0]][0],
            });
          }
        });

      console.debug("post", post);
    },
    focusFirstParentInput(e) {
      let el = e.path[1];
      let textarea = el.getElementsByTagName("textarea");
      for (let i = 0; i < textarea.length; i++) {
        textarea[i].focus();
        break;
      }
    },
    selectMood(moodId) {
      let indexOfMood = this.post.moods.indexOf(moodId);
      if (indexOfMood === -1) {
        this.post.moods.push(moodId);
      } else {
        this.post.moods.splice(indexOfMood, 1);
      }
    },
    onChangePackage(gIndex, iIndex, first) {
      let tomans = ["0T"];
      let dollars = ["0.00$"];
      let smoothPicker = this.$refs.smoothPicker;
      if (gIndex === 0) {
        this.post.sale = iIndex;
        let currentIndex = 0;
        let typeIndex = 0;
        let list;
        switch (iIndex) {
          case 1:
            dollars = this.$_.pluck(
              this.settings.pricings[this.post.postType],
              "price"
            );
            tomans = this.$_.pluck(
              this.settings.pricings[this.post.postType],
              "priceTomans"
            );
            typeIndex = 1;
            break;
        }
        list = dollars;
        smoothPicker.setGroupData(
          1,
          Object.assign({}, this.packages[1], {
            currentIndex,
            list,
          })
        );
        list = tomans;
        smoothPicker.setGroupData(
          2,
          Object.assign({}, this.packages[2], {
            currentIndex,
            list,
          })
        );
        list = this.packages[3].list;
        if (first === undefined || !first) {
          this.post.playbackType = !typeIndex;
        } else {
          typeIndex = this.post.playbackType ? 0 : 1;
        }
        console.debug("typeIndex", typeIndex);
        currentIndex = typeIndex;
        smoothPicker.setGroupData(
          3,
          Object.assign({}, this.packages[3], {
            currentIndex,
            list,
          })
        );

        if (iIndex === 1) {
          if (this.post.package === null) {
            this.post.package =
              this.settings.pricings[this.post.postType][0].id;
          }
        } else {
          this.post.package = null;
        }
      } else if (gIndex === 1 || gIndex === 2) {
        let ii = gIndex === 1 ? 2 : 1;
        for (let oo = 1; oo < 3; oo++) {
          let target = this.packages[oo];
          target.currentIndex = iIndex;
          smoothPicker.setGroupData(oo, target);
          this.post.package =
            this.settings.pricings[this.post.postType][iIndex].id;
        }
      } else if (gIndex === 3) {
        this.post.playbackType = iIndex ? 0 : 1;
        console.debug("this.post.playbackType", this.post.playbackType);
      }
    },
    getData() {
      let vm = this;
      let pId = this.$route.params.id;
      axios
        .get(API.editPost, {
          params: {
            id: pId,
          },
        })
        .then((resp) => {
          this.post = resp.data;
          this.post.base64Cover = null;
          if (this.post.cover != null) {
            var xhr = new XMLHttpRequest();
            xhr.open("GET", this.post.cover, true);
            xhr.responseType = "arraybuffer";
            xhr.onload = function (e) {
              if (this.status == 200) {
                let uInt8Array = new Uint8Array(this.response);
                let i = uInt8Array.length;
                let biStr = new Array(i);
                while (i--) {
                  biStr[i] = String.fromCharCode(uInt8Array[i]);
                }
                let data = biStr.join("");
                let base64 = window.btoa(data);
                vm.post.base64Cover = "data:image/jpeg;base64," + base64;
              }
            };
            xhr.send();
          }
          if (
            this.post.description !== null &&
            this.post.description.length > 0
          ) {
            this.showDescriptionPlaceholder = false;
          }
          if (this.post.lyrics !== null && this.post.lyrics.length > 0) {
            this.showLyricsPlaceholder = false;
          }

          this.packages[3].currentIndex = this.post.playbackType ? 0 : 1;
          if (this.post.sale) {
            let pack = this.post.package;
            this.packages[0].currentIndex = 1;
            let packageIds = this.$_.pluck(
              this.settings.pricings[this.post.postType],
              "id"
            );
            this.packages[1].currentIndex = packageIds.indexOf(pack);
            setTimeout(() => {
              this.onChangePackage(0, 1, 1);
              setTimeout(() => {
                this.packages[1].currentIndex = packageIds.indexOf(pack);
                this.onChangePackage(1, packageIds.indexOf(pack), 1);
              }, 200);
            }, 100);
          }
        })
        .catch(() => {
          if (this.auth.username === undefined) {
            this.waitForUser = true;
          } else {
            this.$router.push("/" + this.auth.username);
          }
        });
    },
    cropSuccess(e) {
      this.newCover = true;
      this.post.base64Cover = e;
    },
    cropUploadSuccess(e, index) {
      // console.log(e, "cropUploadSuccess");
    },
    cropUploadFail(e, index) {
      // console.log(e, "cropUploadFail");
    },
    toggleCoverUploader(type) {
      if (this.post.base64Cover !== null && type) {
        let splitted = this.post.base64Cover.split(",");
        let fileType = splitted[0].replace("data:", "").replace(";base64", "");
        this.$refs.imgUpload.setSourceImg(
          helper.b64toBlob(splitted[1], fileType)
        );
      } else {
        this.$refs.imgUpload.reset();
        this.$refs.imgUpload.setStep(1);
      }
      setTimeout(() => {
        this.imgUpload.show = true;
      }, 200);
    },
    onKeyUpTitle(type) {
      let index = 0;
      this.readOnlySlug = true;
      let val = type === undefined ? this.post.title : this.post.slug;
      if (window["slugify" + index] != null) window["slugify" + index].cancel();
      let CancelToken = axios.CancelToken;
      window["slugify" + index] = CancelToken.source();
      axios
        .get(API.slugify, {
          cancelToken: window["slugify" + index].token,
          params: {
            slug: val,
            postType: this.post.postType,
          },
        })
        .then((res) => {
          this.readOnlySlug = false;
          this.post.slug = res.data.slug;
        })
        .catch(() => {});
    },
    setCover(base64) {
      let splitted = base64.split(",");
      let fileType = splitted[0].replace("data:", "").replace(";base64", "");
      console.debug("fileType", fileType);
      this.$refs.imgUpload.setSourceImg(
        helper.b64toBlob(splitted[1], fileType, 512)
      );
      // this.$refs.imgUpload.reset();
      this.$refs.imgUpload.setStep(2);
      setTimeout(() => {
        this.imgUpload.show = true;
      }, 500);
    },
    setScreenshots(screenshots) {
      this.post.screenshots = screenshots;
    },
    dispatchResize() {
      window.dispatchEvent(new Event("resize"));
    },
    clickUkTab(e, tabIndex) {
      // if (tabIndex === 2) {
      //     this.dispatchResize()
      // }
      setTimeout(() => {
        let openedUk = e.path[2].getElementsByClassName("uk-opened");
        if (openedUk.length) {
          for (let i = 0; i < openedUk.length; i++) {
            openedUk[i].setAttribute("class", "");
          }
        }
        if (e.path[1].getAttribute("class") === "uk-open") {
          this.tabIndex = tabIndex;
          e.path[1].setAttribute("class", "uk-open uk-opened");
        } else {
          this.tabIndex = null;
        }
      }, 250);
    },
    onFocusLyrics(index, type) {
      let val = this.post.lyrics;
      if (type) {
        this.showLyricsPlaceholder = false;
      } else {
        if (val === "" || val === null) {
          this.showLyricsPlaceholder = true;
        }
      }
    },
    onFocusDescription(index, type) {
      let val = this.post.description;
      if (type) {
        this.showDescriptionPlaceholder = false;
      } else {
        if (val === "" || val === null) {
          this.showDescriptionPlaceholder = true;
        }
      }
    },
  },
  watch: {
    auth(auth) {
      if (this.waitForUser && auth.username != undefined) {
        this.$router.push("/" + this.auth.username);
        this.waitForUser = false;
      }
    },
    settings(settings) {
      if (this.waitForSettings) {
        this.getData();
      }
    },
  },
  mounted() {
    this.post = null;
    if (this.settings != null) {
      this.getData();
    } else {
      this.waitForSettings = true;
    }
    bus.$on("ajaxComplete", (name) => {
      if (!this.vSelectPage[name]) {
        this.vSelectPage[name] = true;
        if (name === "genres") {
          for (let i = 0; i < this.post.genres.length; i++) {
            this.$refs.genres.selectItem(this.post.genres[i]);
          }
        } else if (name === "tags") {
          for (let i = 0; i < this.post.hashtags.length; i++) {
            this.$refs.hashtags.selectItem(this.post.hashtags[i]);
          }
        } else if (name === "recordLabels") {
          for (let i = 0; i < this.post.recordLabels.length; i++) {
            this.$refs.recordLabels.selectItem(this.post.recordLabels[i]);
          }
        } else if (name === "users") {
          for (let i = 0; i < this.post.users.length; i++) {
            this.$refs.users.selectItem(this.post.users[i]);
          }
        }
      }
    });
  },
};
</script>