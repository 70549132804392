var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "vo-p-btn",
      class: { processing: _vm.inProcess },
      attrs: { disabled: _vm.inProcess },
      on: { click: _vm.clicked },
    },
    [
      _c("span", { staticClass: "btn-text" }, [_vm._v(_vm._s(_vm.text))]),
      _vm._v(" "),
      _vm._m(0),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "abs-center btn-spinner" }, [
      _c("i", { attrs: { "uk-spinner": "ratio: 0.6" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }