var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.item.realId === undefined
      ? _c(
          "div",
          {
            staticClass: "sound-slider-item",
            attrs: { href: "javascript:void(0)" },
          },
          [
            _c(
              "div",
              { staticClass: "image" },
              [
                _c("ImagePlaceholder", {
                  attrs: { radius: "0", width: "200px", height: "200px" },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sub uk-text-truncate uk-text-center f-s-075" },
              [
                _c("TextSkeleton", {
                  style: { margin: "10px auto 0" },
                  attrs: { width: "80%", height: "10px" },
                }),
                _vm._v(" "),
                _c("TextSkeleton", {
                  style: { margin: "10px auto 0" },
                  attrs: { width: "80%", height: "10px" },
                }),
                _vm._v(" "),
                _c("TextSkeleton", {
                  style: { margin: "10px auto 0" },
                  attrs: { width: "80%", height: "10px" },
                }),
              ],
              1
            ),
          ]
        )
      : _c("div", { staticClass: "sound-slider-item" }, [
          _c("div", { staticClass: "sound-slider-item-inner" }, [
            _c("div", { staticClass: "sound-slider-item-cover" }, [
              _c(
                "div",
                { staticClass: "sound-slider-item-cover-inner" },
                [
                  _c("ImagePlaceholder", {
                    attrs: {
                      radius: "0",
                      "data-src": _vm._f("attach")(_vm.cover, "m"),
                      width: "100%",
                      height: "100%",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "sound-slider-item-actions" }, [
                    _c("div", { staticClass: "uk-float-left" }, [
                      _vm.item.realId != undefined
                        ? _c(
                            "span",
                            {
                              staticClass: "sound-slider-item-action-play",
                              on: { click: _vm.play },
                            },
                            [
                              _vm.currentTrack != null &&
                              _vm.currentTrack == _vm.item.realId &&
                              _vm.playerStatus === "play"
                                ? _c("i", {
                                    staticClass: "fas fa-grip-lines-vertical",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.currentTrack != null &&
                              _vm.currentTrack == _vm.item.realId &&
                              _vm.playerStatus === "wait"
                                ? _c("i", {
                                    staticClass: "fas fa-spinner-third fa-spin",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.currentTrack === null ||
                              _vm.currentTrack !== _vm.item.realId ||
                              (_vm.currentTrack === _vm.item.realId &&
                                _vm.playerStatus === "pause")
                                ? _c("i", { staticClass: "fas fa-play" })
                                : _vm._e(),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "uk-float-right" }, [
                      _vm.item.realId != undefined
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "sound-slider-item-action-ellipsis f-s-15",
                              on: {
                                click: function ($event) {
                                  return _vm.moreMenu(_vm.item, $event)
                                },
                              },
                            },
                            [
                              _c("i", {
                                staticClass: "fal fa-ellipsis-h disable-click",
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "sound-slider-item-details" }, [
              _vm.item.realId != undefined
                ? _c(
                    "div",
                    {
                      staticClass:
                        "sound-slider-item-title f-s-090 uk-text-truncate",
                    },
                    [
                      _c("router-link", { attrs: { to: _vm.permalink } }, [
                        _vm._v(_vm._s(_vm.item.title)),
                      ]),
                      _vm._v(" "),
                      _vm.item.metas.explicit
                        ? _c("span", { staticClass: "explicit" }, [_vm._v("🅴")])
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.realId != undefined
                ? _c(
                    "div",
                    { staticClass: "sound-slider-item-artist f-s-080" },
                    [
                      _c(
                        "span",
                        { staticClass: "artist" },
                        [
                          _c(
                            "router-link",
                            { attrs: { to: _vm.item.user.username } },
                            [_vm._v(_vm._s(_vm.item.user.fullname))]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.item.users, function (teammate) {
                        return _c(
                          "span",
                          { key: teammate.username, staticClass: "teammate" },
                          [
                            _vm._v(", "),
                            _c(
                              "router-link",
                              { attrs: { to: teammate.username } },
                              [_vm._v(_vm._s(teammate.fullname))]
                            ),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.item.realId != undefined &&
              _vm.options.showType != undefined &&
              _vm.options.showType
                ? _c("div", { staticClass: "sound-slider-pt" }, [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm._f("postType")(_vm.item.postType)) +
                        "\n                "
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }