var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "promote-item" }, [
    _c("div", { staticClass: "uk-grid uk-grid-5 uk-flex uk-flex-middle" }, [
      _c(
        "div",
        { staticClass: "uk-width-auto" },
        [
          _c("image-placeholder", {
            attrs: {
              width: "40px",
              radius: _vm.radius,
              "data-src": _vm._f("attach")(_vm.cover, "xs"),
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "uk-width-expand f-f-reg" }, [
        _c("div", { staticClass: "promote-item-title f-s-080" }, [
          _vm._v(
            "\n                " + _vm._s(_vm.item.title) + "\n            "
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "promote-item-description f-s-070" }, [
          _vm._v(
            "\n                " +
              _vm._s(_vm.item.description.join(" – ")) +
              "\n            "
          ),
        ]),
      ]),
      _vm._v(" "),
      _vm.status != null
        ? _c("div", { staticClass: "uk-width-auto" }, [
            _c("span", {
              class: "promote-item-status promote-item-" + _vm.status,
            }),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }