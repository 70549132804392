<template>
  <div class="video-player-main">
    <div uk-grid class="uk-grid-none">
      <div class="uk-width-3-4@l">
        <div class="">
          <!-- <video controls ref="video" id="videoPlayer" :style="{minWidth: '100%'}"> -->
          <!--<source v-for="quality in video.metas.attachment.qualities"-->
          <!--:src="video.metas.attachment.uri + '_' + quality + '.mp4'"-->
          <!--type="video/mp4"-->
          <!--:size="qualityNames[quality]"-->
          <!--/>-->
          <!-- </video> -->
          <hls :item.sync="video" />
        </div>
      </div>
      <div class="uk-width-1-4@l">
        <div class="video-related-container">
          <div class="video-related-absolute">
            <div class="video-related-header">
              <h5 class="f-s-12">Up Next</h5>
            </div>
            <div class="v-post-items">
              <items class="v-post-items-container" type="VPostItem" v-if="relatedPosts.length" :items="relatedPosts" />
              <div class="v-post-items-container" v-if="!relatedPosts.length">
                <div class="v-post-item empty" v-for="(item, index) in emptyCount" :key="index">
                  <div class="uk-grid-12 uk-flex uk-flex-middle" uk-grid>
                    <div class="v-post-item-cover uk-width-auto">
                      <div class="v-post-item-cover-inner">
                        <div class="cover"></div>
                      </div>
                    </div>
                    <div class="uk-width-expand">
                      <div class="line"></div>
                      <div class="line"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1">
        <div class="video-info-container">
          <div class="video-info-main" v-if="!inLoading && video.user != undefined">
            <div class="video-title f-s-2 uk-text-truncate">
              <router-link :to="'/' + video.user.username + '/video/' + video.slug">{{ video.title }}
              </router-link>
            </div>
            <div class="artist-name f-s-10 uk-text-truncate">
              <router-link class="artist teammate" :to="'/' + video.user.username">
                {{ video.user.fullname }}
              </router-link>
              <router-link class="artist teammate" v-for="(teammate, index) in video.users" :key="index"
                :to="'/' + teammate.username">, {{ teammate.fullname }}
              </router-link>
            </div>
          </div>
          <div class="video-info-main" v-if="inLoading">
            <div class="empty">
              <div class="line"></div>
              <div class="line"></div>
            </div>
          </div>
          <!--<div class="video-info-main">-->
          <!--<div class="empty">-->
          <!--<div class="line"></div>-->
          <!--<div class="line"></div>-->
          <!--</div>-->
          <!--</div>-->
          <div v-if="video.is != undefined" class="video-actions uk-flex uk-flex-middle">
            <div class="f-s-090 video-actions-inner">
              <button @click="like(video)" class="btn-action btn-trans">
                <i class="fa-heart" :class="{
                  far: !video.is.liked,
                  'fas active': video.is.liked,
                }"></i>
                <span v-if="video.stats.likes === 0">
                  {{ "like" | lang }}
                </span>
                <span v-if="video.stats.likes != 0">
                  {{ video.stats.likes | numformat }}
                </span>
              </button>
              <button @click="repost(video)" class="btn-action btn-trans"
                :class="{ 'active active-gold': video.is.reposted }">
                <i class="far fa-retweet"></i>
                {{ video.stats.reposts | numformat }}
              </button>
              <button class="btn-action btn-trans" @click="copyLink">
                <i class="far fa-link"></i>
                <span>{{ "copy_link" | lang }}</span>
              </button>
              <button @click="buy" class="btn-action btn-trans" v-if="video.metas.sale && !video.is.bought">
                <i class="far fa-cloud-download"></i>
                <span>{{ "buy" | lang }}</span>
              </button>
              <button @click="moreMenu" class="btn-action btn-trans">
                <i class="far fa-ellipsis-h"></i>
                <span>{{ "more" | lang }}</span>
              </button>
            </div>
            <div class="video-stats f-s-080">
              <div class="info-box">
                <i class="far fa-eye"></i>
                {{
                  (video.stats.views +
                    video.stats.plays +
                    video.stats.downloads)
                  | numformat
                }}
              </div>
              <div class="info-box" v-if="helper.can('play', video)">
                <i class="far fa-play"></i> {{ video.stats.plays | numformat }}
              </div>
              <div class="info-box" v-if="helper.can('download', video)">
                <i class="far fa-cloud-download"></i>
                {{ video.stats.downloads | numformat }}
              </div>
              <div class="info-box">
                <i class="fal fa-clock"></i> {{ video.releaseDateTimeago }}
              </div>
            </div>
          </div>
          <div class="new-comment-container">
            <div v-if="!disableCm" class="uk-grid-5" uk-grid>
              <div class="uk-width-auto">
                <div class="left">
                  <i class="fas fa-quote-right"></i>
                </div>
              </div>
              <div class="uk-width-expand">
                <div class="right">
                  <input type="text" @focus="$parent.showLogin = true" v-if="auth.id === undefined" ref="comment-txt"
                    :placeholder="'comment_placeholder_guest' | lang" class="new-comment-text f-s-085" />
                  <input v-model="comment" type="text" @keyup="keyupComment" @focus="
                    commentAt = minutesDur;
                  commentAtSec = duration;
                                                                                " @focusout="
                                                                commentAt = null;
                                                              commentAtSec = null;
                                                                                                                                                        "
                    v-if="auth.id != undefined && duration === 0" ref="comment-txt"
                    :placeholder="'comment_placeholder_user' | lang" class="new-comment-text f-s-085" />
                  <input v-model="comment" type="text" @keyup="keyupComment" @focus="
                    commentAt = minutesDur;
                  commentAtSec = duration;
                                                                                " @focusout="
                                                                commentAt = null;
                                                              commentAtSec = null;
                                                                                                                                                        "
                    v-if="auth.id != undefined && duration > 0" ref="comment-txt" :placeholder="
                      'comment_placeholder_at_time'
                      | lang({
                        time: commentAt != null ? commentAt : minutesDur,
                      })
                    " class="new-comment-text f-s-085" />
                </div>
              </div>
              <div class="uk-width-auto uk-flex uk-flex-middle f-s-1">
                <button class="send-btn" @click="sendComment">
                  {{ "submit" | lang }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import helper from "../../helpers/helper.js";
import { DO_SET_VIDEO, DO_SET_VIDEO_STATUS } from "@/store/actions/player";
import Items from "@/components/Items/Items";
import Hls from "@/components/Hls";
export default {
  components: {
    Items,
    Hls,
  },
  props: ["value", "player", "auth"],
  directives: {},
  data() {
    return {
      video: {},
      qualityNames: {
        "4K": 2160,
        "2K": 1440,
        "1080p": 1080,
        "720p": 720,
        "480p": 480,
        "360p": 360,
      },
      src: "",
      emptyCount: [...Array(10).keys()],
      loadedElement: false,
      helper: helper,
      inLoading: true,
      commentAt: null,
      newComments: [],
      commentAtSec: null,
      comment: "",
      disableCm: false,
      files: [],
      first: true,
      videoTabData: null,
      needData: false
    };
  },
  computed: {
    isCurrent() {
      if (
        this.current &&
        this.current.slug === this.$route.params.slug &&
        this.current.user.username === this.$route.params.username
      ) {
        return true;
      }
      return false;
    },
    current() {
      return this.$store.getters.getCurrent;
    },
    videoStatus() {
      return "";
      return this.player.Player.video.status;
    },
    relatedPosts() {
      return this.$store.getters.getPlaylist || [];
    },
    commingSoon() {
      return false;
    },
    downloadUri() {
      let dlUri = "";
      if (this.video.metas != undefined) {
        let origDlUri = this.video.metas.attachment.download;
        if (this.auth.username != undefined) {
          return (
            origDlUri +
            (origDlUri.indexOf("?") === -1 ? "?" : "&") +
            "_token=" +
            localStorage.getItem("user-token")
          );
        }
        return origDlUri;
      }
    },
    duration() {
      if (
        this.player === undefined ||
        this.player.Player === undefined ||
        this.player.Player.video === undefined ||
        this.player.Player.video.duration === undefined
      )
        return 0;
      return Math.floor(this.player.Player.video.duration);
    },
    minutesDur() {
      return this.getMinutes(this.duration);
    },
    videoTabDataCom() {
      return {
        commentsCnt: this.video && this.video.stats && this.video.stats.comments,
        realId: this.video && this.video.realId,
        releaseDate: this.video && this.video.releaseDate,
        canComment: this.video && this.video.metas && this.video.metas.comment,
        mainCommentsCnt: this.video && this.video.stats && this.video.stats.commentThreads,
        tags: (this.video && this.video.tags) || [],
        releaseTypeId: this.video && this.video.metas && this.video.metas.releaseTypeId,
        licenseTypeId: this.video && this.video.metas && this.video.metas.licenseTypeId,
        languages: (this.video && this.video.languages) || [],
        recordLabels: (this.video && this.video.recordLabels) || [],
        postType: this.video && this.video.postType,
      }
    }
  },
  methods: {
    copySuccessMessage() {
      bus.$emit("success", {
        title: "",
        message: this.$options.filters.lang("copy_success_msg"),
      });
    },
    fallbackCopyTextToClipboard(text) {
      var textArea = document.createElement("textarea");
      textArea.value = text;

      // Avoid scrolling to bottom
      textArea.style.top = "0";
      textArea.style.left = "0";
      textArea.style.position = "fixed";

      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();

      try {
        var successful = document.execCommand("copy");
        if (successful) {
          this.copySuccessMessage();
        }
      } catch (err) { }

      document.body.removeChild(textArea);
    },
    copyLink() {
      let text = "https://vow.im/" + this.video.id;
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text);
        return;
      }
      navigator.clipboard.writeText(text).then(
        () => {
          this.copySuccessMessage();
        },
        (err) => {
        }
      );
    },
    moreMenu(e) {
      bus.$emit("clickMoreMenu", this.video, e);
    },
    moreMenuDownload(e) {
      bus.$emit(
        "clickMoreMenu",
        {
          files: this.files,
        },
        e
      );
    },
    buy() {
      bus.$emit("buyPostModal", this.video);
    },
    like(item) {
      let res = this.helper.like(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    repost(item) {
      let res = this.helper.repost(item);
      if (res === null) {
        this.$parent.showLogin = true;
      }
    },
    sendComment() {
      if (this.auth.id === undefined) {
        this.$parent.showLogin = true;
        return;
      }
      if (this.comment === "" || this.comment.trim() === "") return;
      if (this.disableCm) return;
      this.$refs["comment-txt"].blur();
      let comment = {
        position: this.commentAtSec === null ? 0 : this.commentAtSec,
        postId: this.video.realId,
        comment: this.comment,
      };
      this.video.stats.comments += 1;
      this.video.stats.commentThreads += 1;
      axios.post(API.comments, comment).then((res) => {
        bus.$emit("commentSent" + this.video.realId, res.data);
      });
      bus.$emit("submitComment" + this.video.realId, comment);
      this.comment = "";
      this.commentAt = null;
      this.commentAtSec = null;
    },
    keyupComment(e) {
      if (e.keyCode === 13) {
        this.sendComment();
      }
    },
    getMinutes(dur) {
      let value = parseFloat(dur);
      if (!value || typeof value !== "number") return "00:00";
      let min = parseInt(value / 60),
        sec = parseInt(value % 60);
      min = min < 10 ? "0" + min : min;
      sec = sec < 10 ? "0" + sec : sec;
      value = min + ":" + sec;
      return value;
    },
    parseData() {
      if (
        this.current &&
        this.current.slug === this.$route.params.slug &&
        this.current.user.username === this.$route.params.username
      ) {
        this.video = this.current;
        this.$emit("update", this.current);
        return;
      }
      if (this.$route.name !== "video") return;
      this.comment = "";
      this.disableCm = false;
      this.loadedElement = false;
      let vm = this;
      vm.inLoading = true;
      //        this.$emit('update', {})
      axios
        .get(
          API.posts +
          "?postType=VID&slug=" +
          this.$route.params.username +
          "|" +
          this.$route.params.slug
        )
        .then((res) => {
          this.src =
            res.data.metas.attachment.stream +
            (res.data.metas.attachment.stream.indexOf("?") === -1 ? "?" : "&") +
            "_token=" +
            localStorage.getItem("user-token");
          // this.player.media.src = this.src
          let item = res.data;
          this.$store.dispatch("setCurrent", item);
          this.video = res.data;
          if (!this.video.metas.comment) {
            this.disableCm = true;
          }
          this.getRelated();
          this.$emit("update", this.video);
          this.inLoading = false;
        });
    },
    coverLoaded() {
      const colorThief = new ColorThief();
      const result = colorThief.getColor(this.$refs.videoCover);
      this.background =
        "rgba(" + result[0] + ", " + result[1] + ", " + result[2] + ", 0.9)";
    },
    getRelated() {
      if (
        this.$_.pluck(this.relatedPosts, "realId").indexOf(
          this.video.realId
        ) !== -1
      )
        return;
      axios
        .get(API.posts + "/related?id=" + this.video.realId + "&count=15")
        .then((resp) => {
          this.$store.dispatch("setPlaylist", [this.video].concat(resp.data));
        })
        .catch(() => { });
    },
  },
  watch: {
    duration: function (val) { },
    $route: function (route) {
      if (
        route.name === "video" &&
        this.video !== undefined &&
        this.video !== null &&
        this.video.realId !== undefined &&
        this.video.user.username === route.params.username &&
        this.video.slug === route.params.slug
      ) {
        // console.log("old video opened");
        // nothing
      } else if (route.name === "video") {
        // console.log("new video opened", this.video.slug, route.params.slug);
        this.video = {};
        this.videoTabData = null;
        this.parseData();
      }
    },
    videoStatus(status) {
      this.$store.commit(DO_SET_VIDEO_STATUS, status);
    },
    video: {
      deep: true,
      handler(newVal) {
        // console.log("video new val", newVal);
        // let src;
        // if (newVal.metas !== undefined && this.player.Player.videoPlayer != undefined) {
        //     let sources = []
        //     this.files = []
        //     this.player.Player.video.current = newVal.realId
        //     this.$store.commit(DO_SET_VIDEO, newVal)
        //     for (let i = 0; i < newVal.metas.attachment.qualities.length; i++) {
        //         //newVal.metas.attachment.qualities[i]
        //         src = newVal.metas.attachment.stream + (newVal.metas.attachment.stream.indexOf('?')===-1 ? '?' : '&') + 'q=' + newVal.metas.attachment.qualities[i] + '&_token=' + localStorage.getItem('user-token')
        //         console.debug('src', src)
        //         sources.push({
        //             src: src,
        //             size: this.qualityNames[newVal.metas.attachment.qualities[i]],
        //             type: 'video/mp4',
        //             // type: 'application/x-mpegURL'
        //         })
        //         this.files.push({
        //             label: newVal.metas.attachment.qualities[i],
        //             url: src.replace('/stream/', '/download/')
        //         })
        //     }
        //     // this.player.Player.videoPlayer.src = newVal.metas.attachment.stream + (newVal.metas.attachment.stream.indexOf('?')===-1 ? '?' : '&') + '_token=' + localStorage.getItem('user-token');
        //     // this.src = this.player.Player.videoPlayer.src
        //     // this.player.Player.videoPlayer.source = {
        //     //     type: 'video',
        //     //     title: 'Test',
        //     //     sources: sources,
        //     // }
        //     if(localStorage.getItem('autoPlayVideo')!==null) {
        //         localStorage.removeItem('autoPlayVideo')
        //         this.player.Player.videoPlay()
        //     }
        let data = {};
        let video = newVal;
        if (video.realId != undefined) {
          data = {
            commentsCnt: video.stats.comments,
            realId: video.realId,
            releaseDate: video.releaseDate,
            canComment: video.metas.comment,
            mainCommentsCnt: video.stats.commentThreads,
            tags: video.tags || [],
            releaseTypeId: video.metas.releaseTypeId,
            licenseTypeId: video.metas.licenseTypeId,
            languages: video.languages || [],
            recordLabels: video.recordLabels || [],
            postType: video.postType,
          };
          data.userId = video.user.id;
          data.setId = null;
          data.setType = null;
        }
        this.videoTabData = data
      }
    },
    inLoading: function (val) { },
  },
  mounted() {
    if (this.loadedElement === true) return;
    this.loadedElement = true;
    bus.$on('needVideoTabData', () => {
      if (this.videoTabData === null || this.videoTabData.realId === undefined || this.videoTabData.realId === null) {
        const cIntervalChecker = setInterval(() => {
          console.log('checking...', JSON.stringify(this.videoTabData))
          if (this.videoTabData !== null && this.videoTabData.realId !== undefined && this.videoTabData.realId !== null) {
            bus.$emit("videoTabData", this.videoTabData);
            clearInterval(cIntervalChecker);
          }
        }, 100);
      } else {
        bus.$emit("videoTabData", this.videoTabData);
      }
    })
    // if (this.player.Player.videoPlayer === undefined) {
    // this.player.Player.videoInit()
    // this.player.Player.videoPlayer.on('ready', function () {
    // })
    // }
    this.parseData();
  },
};
</script>