var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "grid-user-item",
      class:
        _vm.options != undefined && _vm.options.subclass != undefined
          ? _vm.options.subclass
          : "",
    },
    [
      _c("div", [
        _c(
          "div",
          {
            staticClass: "image",
            on: {
              click: function ($event) {
                return _vm.closeSearch()
              },
            },
          },
          [
            _c(
              "router-link",
              { attrs: { to: "/" + _vm.item.username } },
              [
                _c("ImagePlaceholder", {
                  attrs: {
                    "data-src": _vm._f("attach")(_vm.item.photos.avatar, "m"),
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "user-bottom" }, [
          _c(
            "div",
            {
              staticClass: "user-bottom-fullname uk-text-truncate f-s-085",
              on: {
                click: function ($event) {
                  return _vm.closeSearch()
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "uk-flex uk-flex-inline align-items-center" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "a-with-png-ico",
                          attrs: { to: "/" + _vm.item.username },
                        },
                        [
                          _vm._v(
                            "\n                                " +
                              _vm._s(_vm.item.fullname) +
                              "\n                            "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.item !== null &&
                  _vm.item.is != undefined &&
                  _vm.item.is.verified
                    ? _c("i", { staticClass: "verified png-ico small ml-p5" })
                    : _vm._e(),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "user-bottom-username uk-text-truncate f-s-070" },
            [
              _vm._v(
                "\n                    @" +
                  _vm._s(_vm.item.username) +
                  "\n                "
              ),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "user-bottom-action" }, [
            _vm.item.is.following === undefined || !_vm.item.is.following
              ? _c(
                  "button",
                  {
                    staticClass: "app-button f-s-080",
                    on: {
                      click: function ($event) {
                        return _vm.$parent.follow(_vm.item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("lang")("follow")) +
                        "\n                    "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.item.is.following
              ? _c(
                  "button",
                  {
                    staticClass: "app-button active f-s-080",
                    on: {
                      click: function ($event) {
                        return _vm.$parent.follow(_vm.item)
                      },
                    },
                  },
                  [
                    _vm._v(
                      _vm._s(_vm._f("lang")("unfollow")) +
                        "\n                    "
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }