var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.mainClassName },
    [
      _c(
        "div",
        { staticClass: "site-main-layout", class: { hmbrgr: _vm.hmbrgr } },
        [
          _c("div", { staticClass: "h100vh sidebar-container-flex" }, [
            _c(
              "div",
              { staticClass: "sidebar-container-parent" },
              [_c("Sidebar")],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "site-main-col uk-overflow-hidden",
              class: `r-${_vm.$route.name}`,
              attrs: { id: "site-main-col" },
            },
            [
              _c(
                "div",
                { staticClass: "top-menu-container" },
                [
                  _c(
                    "div",
                    {
                      staticClass: "top-menu",
                      class: { "search-opened": _vm.showSearch },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "uk-grid-10 uk-flex uk-flex-middle",
                          attrs: { "uk-grid": "" },
                        },
                        [
                          !_vm.showSearch
                            ? _c("div", { staticClass: "mobile-only" }, [
                                _c(
                                  "div",
                                  {
                                    class: { open: _vm.hmbrgr },
                                    attrs: { id: "hmbrgr" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggleHmbrgr()
                                      },
                                    },
                                  },
                                  [
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span"),
                                    _vm._v(" "),
                                    _c("span"),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.showSearch
                            ? _c(
                                "div",
                                { staticClass: "mobile-only uk-width-expand" },
                                [
                                  _c("router-link", { attrs: { to: "/" } }, [
                                    _c("div", { staticClass: "just-logo" }),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "top-menu-search uk-width-expand mobile-hidden",
                            },
                            [
                              !_vm.searchLoading
                                ? _c("i", { staticClass: "fas fa-search" })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.searchLoading
                                ? _c("i", {
                                    attrs: { "uk-spinner": "ratio: 0.65" },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.search,
                                    expression: "search",
                                  },
                                ],
                                ref: "desktopSearch",
                                staticClass: "f-s-090",
                                attrs: {
                                  type: "text",
                                  placeholder: _vm._f("lang")("search_hint"),
                                },
                                domProps: { value: _vm.search },
                                on: {
                                  focus: function ($event) {
                                    return _vm.focusSearch(1)
                                  },
                                  focusout: function ($event) {
                                    return _vm.focusSearch(0)
                                  },
                                  keyup: _vm.onKeyUpSearch,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.search = $event.target.value
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showSearch
                            ? _c(
                                "div",
                                { staticClass: "mobile-only mobile-search" },
                                [
                                  !_vm.searchLoading
                                    ? _c("i", { staticClass: "fas fa-search" })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.searchLoading
                                    ? _c("i", {
                                        attrs: { "uk-spinner": "ratio: 0.65" },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.search,
                                        expression: "search",
                                      },
                                    ],
                                    ref: "mobileSearchInput",
                                    staticClass: "f-s-090",
                                    attrs: {
                                      type: "text",
                                      placeholder:
                                        _vm._f("lang")("search_hint"),
                                    },
                                    domProps: { value: _vm.search },
                                    on: {
                                      focus: function ($event) {
                                        return _vm.focusSearch(1)
                                      },
                                      focusout: function ($event) {
                                        return _vm.focusSearch(0)
                                      },
                                      keyup: _vm.onKeyUpSearch,
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.search = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.isAuthenticated
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-width-auto uk-flex uk-flex-middle f-s-1",
                                  attrs: {
                                    "uk-height-match": "target: .top-menu-item",
                                  },
                                },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "top-menu-item mobile-hidden",
                                      attrs: { to: { name: "upload" } },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-cloud-upload",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    {
                                      staticClass: "top-menu-item mobile-only",
                                      on: { click: _vm.mobileSearch },
                                    },
                                    [_c("i", { staticClass: "far fa-search" })]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "top-menu-item uk-flex uk-flex-middle btn-transparent f-s-1",
                                    },
                                    [
                                      _c("i", { staticClass: "far fa-bell" }),
                                      _vm._v(" "),
                                      _vm.hasNewNotification
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "has-new-notification",
                                            },
                                            [
                                              _c("i", {
                                                staticClass: "fas fa-circle",
                                              }),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      ref: "notificationsDropdown",
                                      staticClass:
                                        "notification-dropdown uk-width-large",
                                      attrs: {
                                        id: "notificationsDropdown",
                                        "uk-dropdown":
                                          "pos: bottom-right; mode: click; animation: uk-animation-slide-bottom-medium; duration: 150; toggle: - *; delay-hide: 0",
                                      },
                                    },
                                    [
                                      _vm.isProfileLoaded
                                        ? _c("NotificationsDropdown", {
                                            on: {
                                              setBadge: (cnt) => {
                                                this.hasNewNotification = !!cnt
                                              },
                                              close:
                                                _vm.closeNotificationsDropdown,
                                            },
                                          })
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "router-link",
                                    {
                                      staticClass:
                                        "top-menu-item mobile-hidden",
                                      attrs: { to: "/chat" },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "far fa-envelope",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm._m(0),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      ref: "moreDropDown",
                                      attrs: {
                                        id: "moreDropDown",
                                        "uk-dropdown":
                                          "pos: bottom-right; mode: click; animation: uk-animation-slide-bottom-medium; duration: 150; toggle: - *; delay-hide: 0",
                                      },
                                    },
                                    [
                                      _c(
                                        "ul",
                                        {
                                          staticClass: "uk-nav uk-dropdown-nav",
                                          on: { click: _vm.closeUserMenu },
                                        },
                                        [
                                          _c(
                                            "li",
                                            { staticClass: "uk-hidden@m" },
                                            [
                                              _c(
                                                "router-link",
                                                { attrs: { to: "/chat" } },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "far fa-envelope",
                                                  }),
                                                  _vm._v(" Messages"),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            { staticClass: "uk-hidden@m" },
                                            [
                                              _c(
                                                "router-link",
                                                { attrs: { to: "/upload" } },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "far fa-cloud-upload",
                                                  }),
                                                  _vm._v(" Upload"),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("li", {
                                            staticClass:
                                              "uk-nav-divider uk-hidden@m",
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                { attrs: { to: "/terms" } },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fal fa-file-alt",
                                                  }),
                                                  _vm._v(" Terms"),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                { attrs: { to: "/privacy" } },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fal fa-shield-check",
                                                  }),
                                                  _vm._v(
                                                    "\n                      Privacy"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                { attrs: { to: "/about-us" } },
                                                [
                                                  _c("i", {
                                                    staticClass: "fal fa-users",
                                                  }),
                                                  _vm._v(" About Us"),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "li",
                                            [
                                              _c(
                                                "router-link",
                                                {
                                                  attrs: { to: "/contact-us" },
                                                },
                                                [
                                                  _c("i", {
                                                    staticClass:
                                                      "fal fa-envelope-square",
                                                  }),
                                                  _vm._v(
                                                    " Contact\n                      Us"
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  !_vm.isProfileLoaded
                                    ? _c("span", [
                                        _c("div", {
                                          attrs: { "uk-spinner": "" },
                                        }),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isProfileLoaded
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "top-menu-item f-s-080 uk-flex uk-flex-middle btn-transparent avatar-menu",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "user-avatar" },
                                            [
                                              _vm.getProfile.photos.avatar !=
                                              null
                                                ? _c("image-placeholder", {
                                                    attrs: {
                                                      width: 35,
                                                      height: 35,
                                                      dataSrc: _vm._f("attach")(
                                                        _vm.getProfile.photos
                                                          .avatar,
                                                        "s"
                                                      ),
                                                      radius: "50%",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("i", {
                                            staticClass: "fas fa-chevron-down",
                                          }),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.isProfileLoaded
                                    ? _c(
                                        "div",
                                        {
                                          ref: "dropDown",
                                          attrs: {
                                            "uk-dropdown":
                                              "pos: bottom-right; mode: click; animation: uk-animation-slide-bottom-medium; duration: 150; toggle: - *; delay-hide: 0",
                                          },
                                        },
                                        [
                                          _c(
                                            "ul",
                                            {
                                              staticClass:
                                                "uk-nav uk-dropdown-nav",
                                              on: { click: _vm.closeUserMenu },
                                            },
                                            [
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to:
                                                          "/" +
                                                          _vm.getProfile
                                                            .username,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-user",
                                                      }),
                                                      _vm._v(" Profile"),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: "/settings",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-cogs",
                                                      }),
                                                      _vm._v(" Settings"),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                {
                                                  staticClass: "uk-nav-header",
                                                },
                                                [_vm._v("Financial")]
                                              ),
                                              _vm._v(" "),
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.buyProModal()
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fal fa-gem",
                                                    }),
                                                    _vm._v(
                                                      " Go Pro\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "li",
                                                [
                                                  _c(
                                                    "router-link",
                                                    {
                                                      attrs: {
                                                        to: "/payments",
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fal fa-credit-card",
                                                      }),
                                                      _vm._v(
                                                        "\n                      Payments"
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  {
                                                    on: {
                                                      click:
                                                        _vm.TopUpBalanceModal,
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-wallet",
                                                    }),
                                                    _vm._v(
                                                      " Charge Account\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("li", {
                                                staticClass: "uk-nav-divider",
                                              }),
                                              _vm._v(" "),
                                              _c("li", [
                                                _c(
                                                  "a",
                                                  { on: { click: _vm.logout } },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fal fa-sign-out-alt",
                                                    }),
                                                    _vm._v(" Logout"),
                                                  ]
                                                ),
                                              ]),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm.isAuthenticated
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "uk-width-auto uk-flex uk-flex-middle",
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "uk-margin-right" },
                                    [
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "f-s-080 uk-button uk-button-default button-top-menu button-transparent",
                                          class: {
                                            "uk-position-absolute":
                                              _vm.isAuthenticated,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openModal("login")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(_vm._f("lang")("login")) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "button",
                                        {
                                          staticClass:
                                            "uk-button uk-button-default button-top-menu button-bg",
                                          on: {
                                            click: function ($event) {
                                              return _vm.openModal("register")
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm._f("lang")("register")
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("UploadStatusBar"),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "main-content scrollbar",
                  attrs: { id: "main-scrollbar" },
                },
                [
                  _c("div", { staticClass: "middle-side" }, [
                    _vm.showSearch
                      ? _c("div", { staticClass: "search-results-container" }, [
                          _vm.search.trim() === ""
                            ? _c("h3", { staticClass: "uk-text-truncate" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("lang")(
                                        "search_top_header_empty_field"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ])
                            : _c("h3", { staticClass: "uk-text-truncate" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("lang")("search_top_header", {
                                        q: _vm.search.trim(),
                                      })
                                    ) +
                                    "\n            "
                                ),
                              ]),
                          _vm._v(" "),
                          _c("i", {
                            staticClass: "fal fa-times close-search",
                            on: {
                              click: function ($event) {
                                return _vm.closeSearch()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _vm.searchResultsCount === 0 &&
                          !_vm.searchLoading &&
                          _vm.search.trim() != ""
                            ? _c("div", { staticClass: "search-no-result" }, [
                                _vm._v(
                                  "\n              No results found! Try another one..\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.searchSuggestions.length &&
                          _vm.searchResultsCount === 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "search-suggestion-items f-s-14",
                                },
                                [
                                  _c("div", { staticClass: "f-s-1" }, [
                                    _vm._v("Popular Searches"),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.searchSuggestions,
                                    function (searchSuggestion, i) {
                                      return _c(
                                        "a",
                                        {
                                          key: i,
                                          attrs: { href: "javascript:void(0)" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.clickOnSuggestion(
                                                searchSuggestion.query
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(searchSuggestion.query) +
                                              "\n              "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.searchResultsCount > 0
                            ? _c("div", { staticClass: "search-results" }, [
                                _vm.searchResults.users.length
                                  ? _c(
                                      "div",
                                      { staticClass: "search-results-sub" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("users"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("UserSuggest", {
                                          staticClass: "user-list-items",
                                          attrs: {
                                            static: true,
                                            localkey: "search",
                                            type: "CircleUserItem",
                                            "static-items":
                                              _vm.searchResults.users,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchResults.sounds.length
                                  ? _c(
                                      "div",
                                      { staticClass: "search-results-sub" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("sounds"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("Items", {
                                          staticClass: "v-post-items",
                                          attrs: {
                                            type: "VPostItem",
                                            items: _vm.searchResults.sounds,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchResults.videos.length
                                  ? _c(
                                      "div",
                                      { staticClass: "search-results-sub" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("videos"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("VideoSlider", {
                                          attrs: {
                                            options: {
                                              subclass: "uk-width-1-4",
                                            },
                                            staticItems:
                                              _vm.searchResults.videos,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchResults.albums.length
                                  ? _c(
                                      "div",
                                      { staticClass: "search-results-sub" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("albums"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("Items", {
                                          staticClass:
                                            "uk-grid-12 hb-post-items",
                                          attrs: {
                                            options: {
                                              showTracks: true,
                                              subclass:
                                                "uk-width-1-3 uk-width-1-6@m",
                                            },
                                            "uk-grid": "",
                                            type: "GridPostItem",
                                            items: _vm.searchResults.albums,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.searchResults.playlists.length
                                  ? _c(
                                      "div",
                                      { staticClass: "search-results-sub" },
                                      [
                                        _c("h4", [
                                          _vm._v(
                                            _vm._s(_vm._f("lang")("playlists"))
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("Items", {
                                          staticClass:
                                            "uk-grid-12 hb-post-items",
                                          attrs: {
                                            options: {
                                              showTracks: true,
                                              showDur: true,
                                              subclass:
                                                "uk-width-1-3 uk-width-1-6@m",
                                            },
                                            "uk-grid": "",
                                            type: "GridPostItem",
                                            items: _vm.searchResults.playlists,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.currentRouteName === "chat"
                      ? _c("div", [
                          _c(
                            "div",
                            { staticClass: "uk-width-1-1" },
                            [
                              _c("Chat", {
                                tag: "component",
                                attrs: { user: _vm.getProfile },
                                on: {
                                  "update:user": function ($event) {
                                    _vm.getProfile = $event
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.settingsLoaded &&
                              _vm.settings.widgets[_vm.currentRouteName] !=
                                undefined,
                            expression:
                              "\n              settingsLoaded &&\n              settings.widgets[currentRouteName] != undefined\n            ",
                          },
                        ],
                        class: { "hidden-height": _vm.showSearch },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "uk-grid-small",
                            attrs: { "uk-grid": "" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "uk-width-1-1" },
                              [
                                _c("LargeUploader", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.currentRouteName === "upload",
                                      expression:
                                        "currentRouteName === 'upload'",
                                    },
                                  ],
                                  tag: "component",
                                  attrs: {
                                    auth: _vm.getProfile,
                                    moods: _vm.settings && _vm.settings.moods,
                                    releaseTypes:
                                      _vm.settings && _vm.settings.releaseTypes,
                                    pricings:
                                      _vm.settings && _vm.settings.pricings,
                                    licenseTypes:
                                      _vm.settings && _vm.settings.licenseTypes,
                                    postLanguages:
                                      _vm.settings &&
                                      _vm.settings.allPostLanguages,
                                  },
                                  on: {
                                    "update:auth": function ($event) {
                                      _vm.getProfile = $event
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("VideoLargePlayer", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.currentRouteName === "video",
                                      expression:
                                        "currentRouteName === 'video'",
                                    },
                                  ],
                                  tag: "component",
                                  attrs: {
                                    player: _vm.playerControls,
                                    auth: _vm.getProfile,
                                  },
                                  on: {
                                    update: _vm.updateCachedData,
                                    "update:player": function ($event) {
                                      _vm.playerControls = $event
                                    },
                                    "update:auth": function ($event) {
                                      _vm.getProfile = $event
                                    },
                                  },
                                  model: {
                                    value: _vm.cachedData["video"],
                                    callback: function ($$v) {
                                      _vm.$set(_vm.cachedData, "video", $$v)
                                    },
                                    expression: "cachedData['video']",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.settingsLoaded &&
                    _vm.settings.widgets[_vm.currentRouteName] != undefined
                      ? _c(
                          "div",
                          { class: { "hidden-height": _vm.showSearch } },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "uk-grid-small",
                                attrs: { "uk-grid": "" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "uk-width-1-1" },
                                  [
                                    _vm.currentRouteName === "settings"
                                      ? _c("Settings", { tag: "component" })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _vm.settings.widgets[_vm.currentRouteName]
                                  .main != undefined
                                  ? _c(
                                      "div",
                                      { staticClass: "uk-width-1-1" },
                                      _vm._l(
                                        _vm.settings.widgets[
                                          _vm.currentRouteName
                                        ].main,
                                        function (widget, index) {
                                          return _c(widget.template, {
                                            key: index,
                                            tag: "component",
                                            attrs: {
                                              player: _vm.playerControls,
                                              auth: _vm.getProfile,
                                              options: widget.options,
                                            },
                                            on: {
                                              update: _vm.updateCachedData,
                                              "update:player": function (
                                                $event
                                              ) {
                                                _vm.playerControls = $event
                                              },
                                              "update:auth": function ($event) {
                                                _vm.getProfile = $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.cachedData[
                                                  _vm.currentRouteName
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.cachedData,
                                                  _vm.currentRouteName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cachedData[currentRouteName]",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.settings.widgets[_vm.currentRouteName]
                                  .left != undefined
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uk-width-expand inner-main-col",
                                      },
                                      _vm._l(
                                        _vm.settings.widgets[
                                          _vm.currentRouteName
                                        ].left,
                                        function (widget, index) {
                                          return _c(widget.template, {
                                            key: index,
                                            tag: "component",
                                            attrs: {
                                              player: _vm.playerControls,
                                              auth: _vm.getProfile,
                                              options: widget.options,
                                            },
                                            on: {
                                              update: _vm.updateCachedData,
                                              "update:player": function (
                                                $event
                                              ) {
                                                _vm.playerControls = $event
                                              },
                                              "update:auth": function ($event) {
                                                _vm.getProfile = $event
                                              },
                                            },
                                            model: {
                                              value:
                                                _vm.cachedData[
                                                  _vm.currentRouteName
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.cachedData,
                                                  _vm.currentRouteName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cachedData[currentRouteName]",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.settings.widgets[_vm.currentRouteName]
                                  .right != undefined
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "uk-width-1-4 sidebar-width inner-siderbar",
                                      },
                                      [
                                        _vm.settings.widgets[
                                          _vm.currentRouteName
                                        ].right.length
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "right-sidebar-section",
                                              },
                                              _vm._l(
                                                _vm.settings.widgets[
                                                  _vm.currentRouteName
                                                ].right,
                                                function (widget, index) {
                                                  return _c(widget.template, {
                                                    key: index,
                                                    tag: "component",
                                                    attrs: {
                                                      auth: _vm.getProfile,
                                                      options: widget.options,
                                                    },
                                                    on: {
                                                      "update:auth": function (
                                                        $event
                                                      ) {
                                                        _vm.getProfile = $event
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.cachedData[
                                                          _vm.currentRouteName
                                                        ],
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.cachedData,
                                                          _vm.currentRouteName,
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "cachedData[currentRouteName]",
                                                    },
                                                  })
                                                }
                                              ),
                                              1
                                            )
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.settings.widgets[_vm.currentRouteName]
                                  .bottom != undefined
                                  ? _c(
                                      "div",
                                      { staticClass: "uk-width-1-1" },
                                      _vm._l(
                                        _vm.settings.widgets[
                                          _vm.currentRouteName
                                        ].bottom,
                                        function (widget, index) {
                                          return _c(widget.template, {
                                            key: index,
                                            tag: "component",
                                            attrs: { options: widget.options },
                                            model: {
                                              value:
                                                _vm.cachedData[
                                                  _vm.currentRouteName
                                                ],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.cachedData,
                                                  _vm.currentRouteName,
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "cachedData[currentRouteName]",
                                            },
                                          })
                                        }
                                      ),
                                      1
                                    )
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      { class: { "hidden-height": _vm.showSearch } },
                      [_c("router-view")],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("Footer"),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _vm.screenWidth > 1024
            ? _c(
                "div",
                { staticClass: "site-right-col" },
                [
                  _c("DesktopPlayer", {
                    key: _vm.playerKey,
                    attrs: { chatUsers: _vm.chatUsers },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.screenWidth <= 1024
            ? _c("mobile-player", {
                attrs: { controls: _vm.playerControls },
                on: {
                  "update:controls": function ($event) {
                    _vm.playerControls = $event
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "modal",
        {
          attrs: { isLoading: _vm.authLoading },
          on: {
            close: function ($event) {
              _vm.showLogin = false
            },
          },
          model: {
            value: _vm.showLogin,
            callback: function ($$v) {
              _vm.showLogin = $$v
            },
            expression: "showLogin",
          },
        },
        [
          _vm.modalType === "login"
            ? _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v(_vm._s(_vm._f("lang")("login"))),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "active"
            ? _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm.modalStep === 2 && _vm.activationCode.length > 0
                  ? _c("i", {
                      staticClass: "fal fa-arrow-left",
                      on: {
                        click: function ($event) {
                          _vm.activationCode = []
                          _vm.modalStep = 1
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm._f("lang")("account_activation")) +
                    "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "register"
            ? _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm.modalStep === 2
                  ? _c("i", {
                      staticClass: "fal fa-arrow-left",
                      on: {
                        click: function ($event) {
                          _vm.modalStep = 1
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(
                  "\n      " + _vm._s(_vm._f("lang")("register")) + "\n    "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "forgot"
            ? _c("h3", { attrs: { slot: "header" }, slot: "header" }, [
                _vm.modalStep === 1
                  ? _c("i", {
                      staticClass: "fal fa-arrow-left",
                      on: {
                        click: function ($event) {
                          _vm.modalType = "login"
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalStep === 2
                  ? _c("i", {
                      staticClass: "fal fa-arrow-left",
                      on: {
                        click: function ($event) {
                          _vm.modalStep = 1
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.forgotPassword.status != "SUCCESS"
                  ? _c("span", [
                      _vm._v(_vm._s(_vm._f("lang")("forgot_password"))),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.forgotPassword.status === "SUCCESS"
                  ? _c("span", [
                      _vm._v(_vm._s(_vm._f("lang")("change_password"))),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "forgot"
            ? _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _vm.modalStep === 1
                  ? _c("form", [
                      _c("fieldset", { staticClass: "uk-fieldset" }, [
                        _c("p", { staticClass: "f-f-reg f-s-085" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm._f("lang")("forgot_password_desc")) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-margin" }, [
                          _c(
                            "div",
                            { staticClass: "uk-inline min-width-100" },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "uk-form-icon",
                                  attrs: { "uk-icon": "icon: user" },
                                },
                                [_c("i", { staticClass: "fal fa-user" })]
                              ),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.forgotPasswordForm.username,
                                    expression: "forgotPasswordForm.username",
                                  },
                                ],
                                staticClass: "uk-input min-width-100",
                                attrs: {
                                  type: "text",
                                  placeholder:
                                    _vm._f("lang")("email_or_username"),
                                },
                                domProps: {
                                  value: _vm.forgotPasswordForm.username,
                                },
                                on: {
                                  keyup: _vm.onKeyupForgot,
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.forgotPasswordForm,
                                      "username",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalStep === 2
                  ? _c("div", [
                      _vm.forgotPassword.status === "LOADING"
                        ? _c("div", { staticClass: "uk-text-center" }, [
                            _c("i", { attrs: { "uk-spinner": "" } }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.forgotPassword.status === "SUCCESS"
                        ? _c("div", [
                            _c("form", [
                              _c("fieldset", { staticClass: "uk-fieldset" }, [
                                _c("div", { staticClass: "form-margin" }, [
                                  _c(
                                    "div",
                                    { staticClass: "uk-inline min-width-100" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "uk-form-icon" },
                                        [
                                          _c("i", {
                                            staticClass: "fal fa-lock-alt",
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      (_vm.resetPasswordType
                                        ? "password"
                                        : "text") === "checkbox"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.forgotPassword.change
                                                    .password,
                                                expression:
                                                  "forgotPassword.change.password",
                                              },
                                            ],
                                            staticClass:
                                              "uk-input min-width-100 p-r-50",
                                            attrs: {
                                              placeholder: "Enter new password",
                                              type: "checkbox",
                                            },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.forgotPassword.change
                                                  .password
                                              )
                                                ? _vm._i(
                                                    _vm.forgotPassword.change
                                                      .password,
                                                    null
                                                  ) > -1
                                                : _vm.forgotPassword.change
                                                    .password,
                                            },
                                            on: {
                                              keyup: _vm.onKeyupReset,
                                              change: function ($event) {
                                                var $$a =
                                                    _vm.forgotPassword.change
                                                      .password,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.forgotPassword
                                                          .change,
                                                        "password",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.forgotPassword
                                                          .change,
                                                        "password",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.forgotPassword.change,
                                                    "password",
                                                    $$c
                                                  )
                                                }
                                              },
                                            },
                                          })
                                        : (_vm.resetPasswordType
                                            ? "password"
                                            : "text") === "radio"
                                        ? _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.forgotPassword.change
                                                    .password,
                                                expression:
                                                  "forgotPassword.change.password",
                                              },
                                            ],
                                            staticClass:
                                              "uk-input min-width-100 p-r-50",
                                            attrs: {
                                              placeholder: "Enter new password",
                                              type: "radio",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                _vm.forgotPassword.change
                                                  .password,
                                                null
                                              ),
                                            },
                                            on: {
                                              keyup: _vm.onKeyupReset,
                                              change: function ($event) {
                                                return _vm.$set(
                                                  _vm.forgotPassword.change,
                                                  "password",
                                                  null
                                                )
                                              },
                                            },
                                          })
                                        : _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.forgotPassword.change
                                                    .password,
                                                expression:
                                                  "forgotPassword.change.password",
                                              },
                                            ],
                                            staticClass:
                                              "uk-input min-width-100 p-r-50",
                                            attrs: {
                                              placeholder: "Enter new password",
                                              type: _vm.resetPasswordType
                                                ? "password"
                                                : "text",
                                            },
                                            domProps: {
                                              value:
                                                _vm.forgotPassword.change
                                                  .password,
                                            },
                                            on: {
                                              keyup: _vm.onKeyupReset,
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.forgotPassword.change,
                                                  "password",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass: "show-password f-f-reg",
                                          on: {
                                            click: _vm.toggleResetPasswordType,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.resetPasswordType
                                                  ? "Show"
                                                  : "Hide"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("p", { staticClass: "f-f-reg f-s-080" }, [
                                    _vm._v(
                                      "\n                  Notice : After changing your password all of your devices\n                  will get logged out and you need to login again.\n                "
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "login"
            ? _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _c("form", [
                  _c("fieldset", { staticClass: "uk-fieldset" }, [
                    _c("div", { staticClass: "form-margin" }, [
                      _c("div", { staticClass: "uk-inline min-width-100" }, [
                        _c(
                          "span",
                          {
                            staticClass: "uk-form-icon",
                            attrs: { "uk-icon": "icon: user" },
                          },
                          [_c("i", { staticClass: "fal fa-user" })]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.loginForm.username,
                              expression: "loginForm.username",
                            },
                          ],
                          staticClass: "uk-input min-width-100",
                          attrs: {
                            type: "text",
                            disabled: _vm.authLoading,
                            placeholder: _vm._f("lang")("email_or_username"),
                          },
                          domProps: { value: _vm.loginForm.username },
                          on: {
                            keyup: _vm.onKeyupLogin,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.loginForm,
                                "username",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "form-margin" }, [
                      _c("div", { staticClass: "uk-inline min-width-100" }, [
                        _c(
                          "span",
                          {
                            staticClass: "uk-form-icon",
                            attrs: { "uk-icon": "icon: user" },
                          },
                          [_c("i", { staticClass: "fal fa-lock-alt" })]
                        ),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.loginForm.password,
                              expression: "loginForm.password",
                            },
                          ],
                          staticClass: "uk-input min-width-100",
                          attrs: {
                            type: "password",
                            disabled: _vm.authLoading,
                            placeholder: _vm._f("lang")("password"),
                          },
                          domProps: { value: _vm.loginForm.password },
                          on: {
                            keyup: _vm.onKeyupLogin,
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.loginForm,
                                "password",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "form-margin uk-margin-top f-s-090" },
                      [
                        _c("div", { attrs: { "uk-grid": "" } }, [
                          _c("div", { staticClass: "uk-width-1-2@m" }, [
                            _c("label", [
                              _c("input", {
                                staticClass: "uk-checkbox",
                                attrs: {
                                  type: "checkbox",
                                  disabled: true,
                                  checked: "",
                                },
                              }),
                              _vm._v(
                                "\n                  " +
                                  _vm._s(_vm._f("lang")("remember_me"))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "uk-width-1-2@m uk-text-right@m" },
                            [
                              _c(
                                "a",
                                {
                                  on: {
                                    click: function ($event) {
                                      return _vm.openModal("forgot")
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm._f("lang")("forgot_password"))
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]),
                      ]
                    ),
                  ]),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "register"
            ? _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _vm.modalStep === 1
                  ? _c("form", { attrs: { autocomplete: "nope" } }, [
                      _c("fieldset", { staticClass: "uk-fieldset" }, [
                        _c("div", { staticClass: "form-margin" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline min-width-100 field-with-status-ico",
                            },
                            [
                              _c("span", { staticClass: "uk-form-icon" }, [
                                _c("i", { staticClass: "fal fa-user" }),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.username,
                                    expression: "registerForm.username",
                                  },
                                ],
                                staticClass: "uk-input min-width-100",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.registerLoading,
                                  placeholder: _vm._f("lang")("username"),
                                },
                                domProps: { value: _vm.registerForm.username },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkAvailable("username")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm,
                                      "username",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.registerValidate.username === "empty"
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-circle error",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registerValidate.username === false
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-times-circle",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registerValidate.username === true
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-check-circle",
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-margin" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline min-width-100 field-with-status-ico",
                            },
                            [
                              _c("span", { staticClass: "uk-form-icon" }, [
                                _c("i", { staticClass: "fal fa-signature" }),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.fullname,
                                    expression: "registerForm.fullname",
                                  },
                                ],
                                staticClass: "uk-input min-width-100",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.registerLoading,
                                  placeholder: _vm._f("lang")("fullname"),
                                },
                                domProps: { value: _vm.registerForm.fullname },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkAvailable("fullname", false)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm,
                                      "fullname",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.registerValidate.fullname === "empty"
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-circle error",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registerValidate.fullname === false
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-times-circle",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registerValidate.fullname === true
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-check-circle",
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalStep === 2
                  ? _c("form", { attrs: { autocomplete: "off" } }, [
                      _c("fieldset", { staticClass: "uk-fieldset" }, [
                        _c("div", { staticClass: "fake-feilds" }, [
                          _c("input", {
                            attrs: {
                              type: "text",
                              placeholder: "fake email",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v(" "),
                          _c("input", {
                            attrs: {
                              type: "password",
                              placeholder: "fake password",
                            },
                          }),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-margin" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline min-width-100 field-with-status-ico",
                            },
                            [
                              _c("span", { staticClass: "uk-form-icon" }, [
                                _c("i", { staticClass: "fal fa-at" }),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.email,
                                    expression: "registerForm.email",
                                  },
                                ],
                                staticClass: "uk-input min-width-100",
                                attrs: {
                                  type: "text",
                                  disabled: _vm.registerLoading,
                                  placeholder: _vm._f("lang")("email"),
                                },
                                domProps: { value: _vm.registerForm.email },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkAvailable("email")
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm,
                                      "email",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.registerValidate.email === "empty"
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-circle error",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registerValidate.email === false
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-times-circle",
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.registerValidate.email === true
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-check-circle",
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "form-margin" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "uk-inline min-width-100 field-with-status-ico",
                            },
                            [
                              _c("span", { staticClass: "uk-form-icon" }, [
                                _c("i", { staticClass: "fal fa-lock-alt" }),
                              ]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.registerForm.password,
                                    expression: "registerForm.password",
                                  },
                                ],
                                staticClass: "uk-input min-width-100",
                                attrs: {
                                  type: "password",
                                  disabled: _vm.registerLoading,
                                  placeholder: _vm._f("lang")("password"),
                                },
                                domProps: { value: _vm.registerForm.password },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.checkAvailable("password", false)
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.registerForm,
                                      "password",
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _vm.registerValidate.password === "empty"
                                ? _c("i", {
                                    staticClass:
                                      "status-ico fas fa-circle error",
                                  })
                                : _vm._e(),
                            ]
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "active"
            ? _c("div", { attrs: { slot: "body" }, slot: "body" }, [
                _vm.modalStep === 1
                  ? _c("form", { attrs: { autocomplete: "nope" } }, [
                      _c("div", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("lang")("verification_sent_success")
                            ) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("br"),
                      _vm._v(" "),
                      _c("fieldset", { staticClass: "uk-fieldset" }, [
                        _c("div", { staticClass: "form-margin" }, [
                          _c(
                            "div",
                            {
                              staticClass: "uk-grid-5",
                              attrs: { "uk-grid": "" },
                            },
                            _vm._l([0, 1, 2, 3, 4], function (index) {
                              return _c(
                                "div",
                                { key: index, staticClass: "uk-width-1-5" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "uk-inline min-width-100" },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.activationCode[index],
                                            expression: "activationCode[index]",
                                          },
                                        ],
                                        ref: "activationCode",
                                        refInFor: true,
                                        staticClass:
                                          "uk-input activation-code-input f-s-15",
                                        attrs: {
                                          type: "text",
                                          maxlength: "1",
                                          "data-index": index,
                                          placeholder: "_",
                                        },
                                        domProps: {
                                          value: _vm.activationCode[index],
                                        },
                                        on: {
                                          keyup: _vm.activationCodeKeyUp,
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.activationCode,
                                              index,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalStep === 2
                  ? _c("form", { attrs: { autocomplete: "off" } }, [
                      _vm.activationStatus === "LOADING"
                        ? _c("div", { staticClass: "uk-text-center" }, [
                            _c("div", { attrs: { "uk-spinner": "" } }),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activationStatus === "INVALID_ACTIVATION_CODE"
                        ? _c("div", { staticClass: "status-error-underline" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("lang")("invalid_activation_code")
                                ) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activationStatus === "ACTIVATED"
                        ? _c(
                            "div",
                            { staticClass: "status-success-underline" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm._f("lang")("verification_success", {
                                      email: _vm.loginForm.username,
                                    })
                                  ) +
                                  "\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activationStatus === "ALREADY_ACTIVATED"
                        ? _c("div", { staticClass: "status-error-underline" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("lang")("account_already_activated")
                                ) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activationStatus === "INVALID_USERNAME"
                        ? _c("div", { staticClass: "status-error-underline" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm._f("lang")("invlid_username")) +
                                "\n        "
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "forgot"
            ? _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _vm.modalStep === 1
                  ? _c("div", [
                      _c("div", { staticClass: "uk-flex uk-flex-middle" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-default button-top-menu button-bg flex-right",
                            on: { click: _vm.resetPasswordRequest },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("lang")("change_password")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalStep === 2 && _vm.forgotPassword.status === "SUCCESS"
                  ? _c("div", [
                      _c("div", { staticClass: "uk-flex uk-flex-middle" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-default button-top-menu button-bg flex-right button-spinner min-width-100",
                            attrs: { disabled: _vm.forgotPassword.processing },
                            on: { click: _vm.changePasswordRequest },
                          },
                          [
                            !_vm.forgotPassword.processing
                              ? _c("span", [
                                  _vm._v(
                                    _vm._s(_vm._f("lang")("reset_password"))
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.forgotPassword.processing
                              ? _c("div", {
                                  attrs: { "uk-spinner": "ratio: 0.8" },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "active"
            ? _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _vm.modalStep === 2
                  ? _c("div", [
                      _vm.activationStatus != "LOADING"
                        ? _c("div", { staticClass: "uk-flex uk-flex-middle" }, [
                            _c(
                              "a",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openModal("forgot")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm._f("lang")("forgot_password"))
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "uk-button uk-button-default button-top-menu button-bg flex-right",
                                on: {
                                  click: function ($event) {
                                    return _vm.openModal("login")
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(_vm._f("lang")("login")) +
                                    "\n          "
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalStep === 1
                  ? _c("div", [
                      _c("div", { staticClass: "uk-flex" }, [
                        _c(
                          "button",
                          {
                            staticClass:
                              "uk-button uk-button-default button-top-menu button-bg flex-right",
                            attrs: { disabled: _vm.activationCode.length < 5 },
                            on: {
                              click: function ($event) {
                                return _vm.checkActivate(
                                  _vm.registerForm.username,
                                  null
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm._f("lang")("submit")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.modalType === "login" || _vm.modalType === "register"
            ? _c("div", { attrs: { slot: "footer" }, slot: "footer" }, [
                _vm.modalType === "login"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "uk-button uk-button-primary button-spinner min-width-100",
                        attrs: { disabled: _vm.authLoading },
                        on: { click: _vm.login },
                      },
                      [
                        !_vm.authLoading
                          ? _c("span", [
                              _vm._v(_vm._s(_vm._f("lang")("login"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.authLoading
                          ? _c("div", { attrs: { "uk-spinner": "" } })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.modalType === "register"
                  ? _c(
                      "button",
                      {
                        staticClass:
                          "uk-button uk-button-primary button-spinner min-width-100 button-ico-right",
                        attrs: { disabled: _vm.authLoading },
                        on: { click: _vm.register },
                      },
                      [
                        !_vm.registerLoading && _vm.modalStep !== 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm._f("lang")("register"))),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm.registerLoading && _vm.modalStep === 1
                          ? _c("span", [
                              _vm._v(_vm._s(_vm._f("lang")("continue")) + " "),
                              _c("i", { staticClass: "fal fa-chevron-right" }),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.registerLoading
                          ? _c("div", { attrs: { "uk-spinner": "" } })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "devider-text-or uk-divider-icon" }, [
                  _vm._v(_vm._s(_vm._f("lang")("or"))),
                ]),
                _vm._v(" "),
                _vm.settings !== null
                  ? _c("div", { staticClass: "uk-text-center f-s-085" }, [
                      _vm.settings.activeSocials.indexOf("apple") !== -1
                        ? _c(
                            "div",
                            {
                              staticClass: "login-with-social apple",
                              on: {
                                click: function ($event) {
                                  return _vm.socialConnect("apple")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ico" }, [
                                _c("i", { staticClass: "fab fa-apple" }),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("lang")(_vm.modalType)) +
                                  " " +
                                  _vm._s(_vm._f("lang")("with")) +
                                  " Apple\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.settings.activeSocials.indexOf("instagram") !== -1
                        ? _c(
                            "div",
                            {
                              staticClass: "login-with-social instagram",
                              on: {
                                click: function ($event) {
                                  return _vm.socialConnect("instagram")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ico" }, [
                                _c("i", { staticClass: "fab fa-instagram" }),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("lang")(_vm.modalType)) +
                                  " " +
                                  _vm._s(_vm._f("lang")("with")) +
                                  " Instagram\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.settings.activeSocials.indexOf("google") !== -1
                        ? _c(
                            "div",
                            {
                              staticClass: "login-with-social google",
                              on: {
                                click: function ($event) {
                                  return _vm.socialConnect("google")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ico" }, [
                                _c("i", { staticClass: "fab fa-google" }),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("lang")(_vm.modalType)) +
                                  " " +
                                  _vm._s(_vm._f("lang")("with")) +
                                  " Google\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.settings.activeSocials.indexOf("facebook") !== -1
                        ? _c(
                            "div",
                            {
                              staticClass: "login-with-social facebook",
                              on: {
                                click: function ($event) {
                                  return _vm.socialConnect("facebook")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ico" }, [
                                _c("i", { staticClass: "fab fa-facebook-f" }),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("lang")(_vm.modalType)) +
                                  " " +
                                  _vm._s(_vm._f("lang")("with")) +
                                  " Facebook\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.settings.activeSocials.indexOf("twitter") !== -1
                        ? _c(
                            "div",
                            {
                              staticClass: "login-with-social twitter",
                              on: {
                                click: function ($event) {
                                  return _vm.socialConnect("twitter")
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "ico" }, [
                                _c("i", { staticClass: "fab fa-twitter" }),
                              ]),
                              _vm._v(
                                "\n          " +
                                  _vm._s(_vm._f("lang")(_vm.modalType)) +
                                  " " +
                                  _vm._s(_vm._f("lang")("with")) +
                                  " Twitter\n        "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _c("PromotePostModal", {
        attrs: {
          data: _vm.modals.promotedPost.data,
          current: 0,
          show: _vm.modals.promotedPost.show,
        },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("promotedPost")
          },
        },
      }),
      _vm._v(" "),
      _c("BuyPostModal", {
        attrs: { data: _vm.modals.buyPost.data, show: _vm.modals.buyPost.show },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("buyPost")
          },
        },
      }),
      _vm._v(" "),
      _c("PromoteUserModal", {
        attrs: { current: 0, show: _vm.modals.promotedUser.show },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("promotedUser")
          },
        },
      }),
      _vm._v(" "),
      _c("TopUpBalanceModal", {
        attrs: { current: 0, show: _vm.modals.topUpBalance.show },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("topUpBalance")
          },
        },
      }),
      _vm._v(" "),
      _c("ReportModal", {
        attrs: {
          currentStep: 0,
          data: _vm.modals.report.data,
          show: _vm.modals.report.show,
        },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("report")
          },
        },
      }),
      _vm._v(" "),
      _c("BuyProModal", {
        attrs: { currentStep: 0, show: _vm.modals.buyPro.show },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("buyPro")
          },
        },
      }),
      _vm._v(" "),
      _c("LikesModal", {
        attrs: {
          tabs: 2,
          data: _vm.modals.likes.data,
          show: _vm.modals.likes.show,
        },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("likes")
          },
        },
      }),
      _vm._v(" "),
      _c("confirm-modal", {
        attrs: {
          show: _vm.modals.confirm.show,
          title: _vm.modals.confirm.title,
          desc: _vm.modals.confirm.desc,
          btn: _vm.modals.confirm.btn,
          action: _vm.modals.confirm.action,
        },
        on: {
          close: function ($event) {
            return _vm.doCloseModal("confirm")
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass:
          "top-menu-item f-s-080 uk-flex uk-flex-middle btn-transparent",
      },
      [_c("i", { staticClass: "far fa-ellipsis-h" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }