var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "widget padding-x" }, [
    _c("div", { staticClass: "widget-title" }, [
      _vm.options.title != null
        ? _c("h4", [_vm._v(_vm._s(_vm._f("lang")(_vm.options.title)))])
        : _vm._e(),
      _vm._v(" "),
      _vm.options.subtitle != null
        ? _c("h6", [_vm._v(_vm._s(_vm._f("lang")(_vm.options.subtitle)))])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "widget-content" },
      [
        _vm.items.length
          ? _c("Items", { attrs: { type: "VPostMedItem", items: _vm.items } })
          : _vm._e(),
        _vm._v(" "),
        !_vm.end
          ? _c("div", { staticClass: "uk-margin-top uk-text-center" }, [
              _c("i", {
                directives: [
                  {
                    name: "is-visibility",
                    rawName: "v-is-visibility",
                    value: (isVisible) => _vm.visibleMore(isVisible),
                    expression: "(isVisible) => visibleMore(isVisible)",
                  },
                ],
                attrs: { "uk-spinner": "" },
              }),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.end && !_vm.items.length ? _c("div", [_c("Empty")], 1) : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }