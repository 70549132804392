var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: !_vm.isComplete,
          expression: "!isComplete",
        },
      ],
      staticClass: "upload-status-bar",
    }),
    _vm._v(" "),
    _vm.isComplete
      ? _c(
          "div",
          { staticClass: "upload-status-bar-on-complete uk-animation-fade" },
          [
            _c("div", { staticClass: "uppy-Root uppy-StatusBar is-complete" }, [
              _c("div", {
                staticClass: "uppy-StatusBar-progress",
                staticStyle: { width: "100%" },
                attrs: {
                  role: "progressbar",
                  "aria-valuemin": "0",
                  "aria-valuemax": "100",
                  "aria-valuenow": "0",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "uppy-StatusBar-content",
                  attrs: { title: "Complete", role: "status" },
                },
                [
                  _c("div", { staticClass: "uppy-StatusBar-status" }, [
                    _c("div", { staticClass: "uppy-StatusBar-statusPrimary" }, [
                      _c(
                        "svg",
                        {
                          staticClass:
                            "uppy-StatusBar-statusIndicator UppyIcon",
                          attrs: {
                            "aria-hidden": "true",
                            focusable: "false",
                            width: "15",
                            height: "11",
                            viewBox: "0 0 15 11",
                          },
                        },
                        [
                          _c("path", {
                            attrs: {
                              d: "M.414 5.843L1.627 4.63l3.472 3.472L13.202 0l1.212 1.213L5.1 10.528z",
                            },
                          }),
                        ]
                      ),
                      _vm._v(
                        "\n                        Complete\n                    "
                      ),
                    ]),
                  ]),
                ]
              ),
              _vm._v(" "),
              !_vm.inProcess
                ? _c("div", { staticClass: "uppy-StatusBar-actions" }, [
                    _c(
                      "button",
                      {
                        staticClass: "status-bar-btn",
                        on: { click: _vm.clickOnCancel },
                      },
                      [_vm._v("\n                    Cancel\n                ")]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "status-bar-btn save",
                        on: { click: _vm.clickOnSave },
                      },
                      [_vm._v("\n                    Save\n                ")]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.inProcess
                ? _c("div", { staticClass: "uppy-StatusBar-actions" }, [
                    _c("i", { attrs: { "uk-spinner": "ratio: 0.5" } }),
                  ])
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }