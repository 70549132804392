<template>
    <TabbedModal @setTab="setTab" @close="close" :tabs="tabs" :show="show">
        <div slot="title0" v-if="data.likes!=undefined">
            {{data.likes | numformat}} Likes
        </div>
        <div slot="title1" v-if="data.reposts!=undefined">
            {{data.reposts | numformat}} Reposts
        </div>
        <div class="flex-h-100" slot="body0">
            <div id="container-of-likes" class="flex-h-100 uk-animation-fade flex-direction-column"
                 ref="likesRef">
                <Items type="VUserItem" :items="likes.items" v-if="likes.items.length"></Items>
                <div v-if="likes.hasMore && this.current===0" v-is-visibility="visibleLikes"
                     class="uk-text-center flex-1-1">
                    <i uk-spinner></i>
                </div>
                <div class="no-likes-parent" v-if="!likes.hasMore && !likes.items.length">
                    <div class="no-likes">
                        <div>
                            <i class="fal fa-heart-broken"></i>
                            <div class="f-s-080">
                                No one has liked this post yet.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex-1-1" slot="body1">
            <div id="container-of-reposts" class="flex-h-100 uk-animation-fade flex-direction-column"
                 ref="repostsRef">
                <Items type="VUserItem" :items="reposts.items" v-if="reposts.items.length"></Items>
                <div v-if="reposts.hasMore && this.current===1" v-is-visibility="visibleReposts"
                     class="uk-text-center flex-1-1">
                    <i uk-spinner></i>
                </div>
                <div class="no-likes-parent" v-if="!reposts.hasMore && !reposts.items.length">
                    <div class="no-likes">
                        <div>
                            <i class="fal fa-retweet"></i>
                            <div class="f-s-080">
                                No one has reposted this post yet.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </TabbedModal>
</template>
<script>
    import PerfectScrollbar from 'perfect-scrollbar';
    import TabbedModal from './TabbedModal'
    import Items from '../Items/Items'
    import axios from 'axios'

    export default {
        components: {
            TabbedModal,
            Items
        },
        props: {
            show: {
                type: Boolean,
                default: false
            },
            tabs: {
                type: Number,
                default: 5
            },
            current: {
                type: Number,
                default: 0
            },
            data: {
                type: Object,
                default: () => {
                    return {}
                }
            }
        },
        data() {
            return {
                likes: {
                    isLoading: false,
                    items: [],
                    ps: null,
                    hasMore: true
                },
                reposts: {
                    isLoading: false,
                    items: [],
                    ps: null,
                    hasMore: true
                },
                count: 50
            }
        },
        methods: {
            visibleReposts(a, b) {
                if (a) {
                    this.getItems('reposts')
                }
            },
            visibleLikes(a, b) {
                if (a) {
                    this.getItems('likes')
                }
            },
            recived() {
            },
            setTab(index) {
                this.current = index
                // console.log('index', index)
                if (index === 1 && !this.reposts.items.length) {
                    this.getItems('reposts')
                }
            },
            close() {
                this.$emit('close')
                this.show = false
            },
            getItems(type) {
                if (this[type].isLoading) return
                let url = type === 'likes' ? API.getLikes : API.getReposts
                this[type].isLoading = true
                let itemsLen = this[type].items.length
                axios.get(url + '?id=' + this.data.id + '&skip=' + itemsLen + '&count=' + this.count)
                    .then(res => {
                        this[type].isLoading = false
                        if (!res.data.length) {
                            this[type].hasMore = false
                            return
                        }
                        if (this[type].items.length) {
                            this[type].items = this[type].items.concat(res.data)
                        } else {
                            this[type].items = res.data
                        }
                        if (res.data.length < this.count) {
                            this[type].hasMore = false
                        }
                        if (itemsLen === 0) {
                            setTimeout(() => {
                                let rect = this.$refs[type + 'Ref'].getBoundingClientRect()
                                this[type].ps = new PerfectScrollbar('#container-of-' + type + '', {
                                    handlers: ['wheel', 'touch'],
                                    wheelSpeed: 1,
                                    wheelPropagation: true,
                                    minScrollbarLength: 20,
                                    suppressScrollX: true
                                })
                            }, 0)
                        } else {
                            this[type].ps.update();
                        }
                    })
            }
        },
        mounted() {

        },
        watch: {
            show(newVal) {
                if (newVal) {
                    this.likes.hasMore = true
                    this.reposts.hasMore = true
                    this.reposts.ps = null
                    this.likes.ps = null
                    this.likes.items = []
                    this.reposts.items = []
                } else {
                    this.current = 0
                }
            },
            current(index) {
            }
        }
    }
</script>